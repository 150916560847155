import { FC } from "react";

export const ChevronDown: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Arrow">
        <path
          id="Union"
          d="M6.29289 9.84315C5.90237 10.2337 5.90237 10.8668 6.29289 11.2574L11.2426 16.2071C11.6332 16.5976 12.2663 16.5976 12.6569 16.2071L17.6066 11.2574C17.9971 10.8668 17.9971 10.2337 17.6066 9.84315C17.2161 9.45262 16.5829 9.45262 16.1924 9.84315L11.9497 14.0858L7.70711 9.84315C7.31658 9.45262 6.68342 9.45262 6.29289 9.84315Z"
          fill="#353B48"
        />
      </g>
    </svg>
  );
};
