import { Box, Flex, HStack, Image, Text, VStack } from "@chakra-ui/react";
import React, { FC, useCallback, useMemo } from "react";
import { useFormStore } from "../form-store";

import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  isBefore,
  subDays,
  subHours,
} from "date-fns";
import { GETCHANNELNAME } from "@/constant";
import { ChannelSelect } from "./_channel-select";
import { useConfigStore } from "@/context/config-store/config-store";
import { DelayedImage } from "@/components/delayed-image";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";

export const StepThree: FC = () => {
  const [
    platforms,
    selectedPlatform,
    setSelectedPlatform,
    forms,
    mainForms,
    scheduleAt,
    setPreview,
  ] = useFormStore((s) => [
    s.platforms,
    s.selectedPlatform,
    s.setSelectedPlatform,
    s.forms,
    s.mainForms,
    s.scheduledAt,
    s.setPreview,
  ]);

  const { t } = useTranslation();
  const { clockNotation, language } = useConfigStore((c) => ({
    clockNotation: c.clockNotation,
    language: c.language,
  }));

  const updateSelected = useCallback(
    (platform: typeof selectedPlatform) => {
      if (typeof platform === "object") {
        const channelName = GETCHANNELNAME(platform?.channel_type);

        if (channelName) {
          setPreview({
            [channelName]: {
              channel: platform,
              formType: "normal",
            },
          });
        }
      }

      setSelectedPlatform(platform);
    },
    [setPreview, setSelectedPlatform]
  );

  const selectedForm = useMemo(() => {
    if (typeof selectedPlatform === "object") {
      return forms[selectedPlatform.id];
    }

    return mainForms.main;
  }, [forms, mainForms, selectedPlatform]);

  const days = differenceInDays(scheduleAt, Date.now());
  const hours = differenceInHours(subDays(scheduleAt, days), Date.now());

  const minutes = differenceInMinutes(
    subHours(subDays(scheduleAt, days), hours),
    Date.now()
  );

  return (
    <Box minH="60vh">
      <Box>
        <Text fontSize="md" fontWeight="semibold" color="#353B48">
          {t("confirm_your_plan")}
        </Text>
        <Text fontSize="sm" fontWeight="light" color="#353B48">
          {t("please-select-an-account-to-see-its-preview")}
        </Text>
      </Box>

      <Flex flexWrap="wrap" mt="10" gap="3">
        <ChannelSelect
          value={selectedPlatform}
          channels={platforms}
          onSelected={updateSelected}
        />
      </Flex>

      <Box mt="8">
        <VStack alignItems="flex-start" gap="6">
          {selectedForm?.content && (
            <>
              <Text fontWeight="semibold" fontSize="md" color="#353B48">
                {t("text")}
              </Text>
              <Text
                fontSize="xs"
                color="#353B48"
                fontWeight="light"
                whiteSpace="pre-wrap"
                wordBreak="break-word"
              >
                {selectedForm?.content}
              </Text>
            </>
          )}
          {selectedForm?.files && selectedForm.files.length > 0 && (
            <>
              <Text fontWeight="semibold" fontSize="md" color="#353B48">
                {t("media")}
              </Text>

              <Flex alignItems="center">
                <HStack
                  h="36"
                  overflowX="auto"
                  alignItems="center"
                  justifyContent="center"
                >
                  {selectedForm.files.map((file, index) => (
                    <React.Fragment key={index}>
                      {file.data.image && (
                        <Image
                          rounded="lg"
                          src={file.data.image.downloadUrl}
                          h="28"
                        />
                      )}
                      {file.data.video && (
                        <DelayedImage
                          src={
                            (file.data.video as { coverUrl?: string })
                              ?.coverUrl ??
                            file.data.video.thumbnail_url.replace(
                              "thumbnail.jpg",
                              "preview.webp"
                            )
                          }
                          fallbackUrl={file.data.video.thumbnail_url}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </HStack>
              </Flex>
            </>
          )}
          {scheduleAt && (
            <>
              <Text fontWeight="semibold" fontSize="md" color="#353B48">
                {t("schedule")}
              </Text>
              <Flex
                w="full"
                border="1px solid"
                borderColor="gray.passive"
                py="2.5"
                px="2"
                rounded="xl"
              >
                <Flex flex="1" gap="10">
                  <Text fontSize="md" color="#353B48">
                    {i18nformat(scheduleAt, "dd, MMMM yyyy ")}

                    <Text as="span">
                      {i18nformat(
                        scheduleAt,
                        clockNotation === "12" ? "hh : mm a" : "HH : mm"
                      )}
                    </Text>
                  </Text>
                </Flex>
                <Flex flex="1" justifyContent="flex-end">
                  <Text color="gray.active" fontSize="md">
                    {isBefore(Date.now(), scheduleAt) ? (
                      <>
                        {days > 0 && (
                          <>
                            <Text
                              as="span"
                              color="yellow.label"
                              fontWeight="bold"
                            >
                              {days}
                            </Text>{" "}
                            {t("date-names.day")}
                            {language === "en" && days > 1 ? "s " : " "}
                          </>
                        )}
                        {hours > 0 && (
                          <>
                            <Text
                              as="span"
                              color="yellow.label"
                              fontWeight="bold"
                            >
                              {hours}
                            </Text>{" "}
                            {t("date-names.hour")}
                            {language === "en" && hours > 1 ? "s " : " "}
                          </>
                        )}
                        {minutes > 0 && (
                          <>
                            <Text
                              as="span"
                              color="yellow.label"
                              fontWeight="bold"
                            >
                              {minutes}
                            </Text>{" "}
                            {t("minute")}
                            {language === "en" && minutes > 1 ? "s " : " "}
                          </>
                        )}
                        <Text
                          as="span"
                          color="yellow.label"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("left")}
                        </Text>
                      </>
                    ) : (
                      <Text as="span" color="yellow.label" fontWeight="bold">
                        {t("now_b")}
                      </Text>
                    )}
                  </Text>
                </Flex>
              </Flex>
            </>
          )}
        </VStack>
      </Box>
    </Box>
  );
};
