import { FC } from "react";

export const PreviewIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      enableBackground="new 0 0 24 24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m15.8 3.8 1.4 1.5-2.2 2.2c-.4.4-.4 1 0 1.4s1 .4 1.4 0l2.2-2.2 1.5 1.4c.4.4.9.1.9-.3v-4.3c0-.3-.2-.5-.5-.5h-4.3c-.4 0-.7.5-.4.8zm-12 4.3 1.4-1.4 2.2 2.2c.4.4 1 .4 1.4 0s.4-1 0-1.4l-2.1-2.2 1.4-1.5c.4-.3.1-.8-.3-.8h-4.3c-.3 0-.5.2-.5.5v4.3c0 .4.5.7.8.3zm4.3 12-1.5-1.4 2.2-2.2c.4-.4.4-1 0-1.4s-1-.4-1.4 0l-2.2 2.2-1.5-1.4c-.2-.4-.7-.1-.7.3v4.3c0 .3.2.5.5.5h4.3c.4 0 .7-.5.3-.9zm12.1-4.3-1.5 1.4-2.2-2.2c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4l2.2 2.2-1.4 1.5c-.3.3-.1.9.4.9h4.3c.3 0 .5-.2.5-.5v-4.3c-.1-.4-.6-.7-.9-.4z" />
    </svg>
  );
};
