import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
} from "@chakra-ui/react";
import { FC, useState, Ref } from "react";
import { useFormContext } from "react-hook-form";
import { LoginSchema } from ".";
import { useTranslation } from "react-i18next";
import { Logo } from "@/assets/logo";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { Link as RouteLink } from "react-router-dom";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { TURNSTILE_TOKEN } from "@/constant";

export const LoginMobile: FC<{
  onSubmit: () => void;
  disabled: boolean;
  turnstileRef: Ref<TurnstileInstance | undefined>;
}> = ({ onSubmit, disabled, turnstileRef }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<boolean>(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<LoginSchema>();

  return (
    <Box pt="9" bg="white" h="100dvh" w="100dvw" px="8">
      <Center>
        <Icon boxSize="24">
          <Logo />
        </Icon>
      </Center>

      <Box textAlign="center" mt="7">
        <Text color="black.active" fontWeight="semibold" fontSize="2xl" mb="4">
          {t("page.login.login")}
        </Text>
        <Text
          color="gray.passive"
          fontSize="13px"
          position="relative"
          bottom="1"
        >
          {t("page.login.message.login")}
        </Text>
      </Box>

      <Flex flexDirection="column" justifyContent="center" alignItems="center">
        <Box as="form" onSubmit={onSubmit} mt="8" w="77dvw">
          <FormControl mb="5" isInvalid={!!errors.email}>
            <FormLabel
              fontSize="md"
              zIndex="9"
              fontWeight="semibold"
              bg="white"
            >
              {t("input.email-address")}
            </FormLabel>
            <Input
              type="text"
              rounded="full"
              {...register("email")}
              placeholder={t("input.placeholder.email-address")}
            />

            <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
          </FormControl>

          <FormControl
            justifyContent="space-between"
            flexDirection="row"
            alignItems="center"
            isInvalid={!!errors.password}
          >
            <FormLabel
              fontWeight="semibold"
              fontSize="md"
              zIndex="9"
              bg="white"
            >
              {t("input.password")}
            </FormLabel>
            <InputGroup>
              <Input
                rounded="full"
                type={passwordVisible ? "text" : "password"}
                placeholder={t("input.placeholder.password")}
                {...register("password")}
              />

              <InputRightElement>
                <IconButton
                  cursor="pointer"
                  size="xs"
                  variant="unstyled"
                  aria-label={
                    passwordVisible ? "hide-password" : "show-password"
                  }
                  onClick={() => setPasswordVisible((p) => !p)}
                  icon={!passwordVisible ? <EyeIcon /> : <EyeIconInvisible />}
                />
              </InputRightElement>
            </InputGroup>

            <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
          </FormControl>

          <Center mt="3">
            <Turnstile
              ref={turnstileRef}
              options={{
                theme: "light",
              }}
              siteKey={TURNSTILE_TOKEN}
              onSuccess={(token) => {
                setCaptcha(true);
                setValue("captchaToken", token);
              }}
            />
          </Center>

          <Flex w="full" flexDir="column" mt="2">
            <Link
              as={RouteLink}
              fontSize="14px"
              color="gray.passive"
              fontWeight="semibold"
              alignSelf="flex-end"
              to="/auth/forgot-password"
            >
              {t("link.forget-password")} ?
            </Link>
          </Flex>
          <Box>
            <Button
              mt="12"
              variant="unstyled"
              type="submit"
              bg="gray.antrasit"
              fontWeight="semibold"
              fontSize="xl"
              w="full"
              color="white.normal"
              isDisabled={!captcha || disabled}
            >
              {t("input.login-button")}
            </Button>
            <Text
              color="gray.passive"
              fontSize="11px"
              mt="4"
              textAlign="center"
            >
              {t("page.login.message.subtitle")}{" "}
              <Link
                ml="1"
                as={RouteLink}
                textDecor="underline"
                color="gray.antrasit"
                to="/auth/signup"
              >
                {t("link.create-an-account")}
              </Link>
            </Text>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
