export const SearchDIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="2.5"
        fill="#F7F8FA"
        stroke="#EBEDF2"
      />
      <path
        d="M11.6781 6.14419C10.1525 4.6186 7.66953 4.6186 6.14394 6.14419C4.61869 7.67011 4.61869 10.1528 6.14394 11.6787C7.50252 13.037 9.61844 13.1825 11.143 12.1219C11.1751 12.2737 11.2485 12.4185 11.3666 12.5366L13.5883 14.7583C13.9121 15.0814 14.4353 15.0814 14.7574 14.7583C15.0809 14.4349 15.0809 13.9117 14.7574 13.5893L12.5357 11.3668C12.4183 11.2498 12.2731 11.176 12.1213 11.1439C13.1826 9.61902 13.037 7.50343 11.6781 6.14419ZM10.9767 10.9773C9.8377 12.1162 7.98404 12.1162 6.84539 10.9773C5.70707 9.83828 5.70707 7.98495 6.84539 6.84597C7.98404 5.70732 9.8377 5.70732 10.9767 6.84597C12.1157 7.98495 12.1157 9.83828 10.9767 10.9773Z"
        fill={props.fill || "#33373D"}
      />
    </svg>
  );
};
