import {
  Box,
  Text,
  Flex,
  Menu,
  MenuButton,
  Icon,
  Divider,
  Button,
  BoxProps,
  Image,
} from "@chakra-ui/react";
import { FC, useMemo, useState } from "react";
import { LogoBlue } from "@/assets/logo/logo-blue";
import { PlannerIcon } from "../../../assets/icons/planner-icon";
import { MembershipIcon } from "../../../assets/icons/membership-icon";
import { NewPostIcon } from "../../../assets/icons/newPost-icon";
import { DashboardIcon } from "@/assets/icons/dashboard-icon";
import { LogoutIcon } from "@/assets/icons/logout-icon";
import { SettingsIcon } from "@/assets/icons/settings-icon";
import { SidebarItem } from "@/pages/dashboard/Sidebar/sidebar-item";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useTranslation } from "react-i18next";
import { useConfigStore } from "@/context/config-store/config-store";
import { LanguageIcon } from "@/assets/icons/language-item";
import { PrivacyIcon } from "@/assets/icons/privacy-icon";
import { TurkishFlagCircle } from "@/assets/icons/turkish-flag-circle";
import { useNavigate } from "react-router-dom";
import { UserAvatarIcon } from "@/assets/icons/user-avatar-icons";
import { AddAccountIcon } from "@/assets/icons/add-account-icon";
import { brandTitle } from "@/constant";

import { UptimeIcon } from "@/assets/icons/uptime-icon";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export const Sidebar: FC<BoxProps> = ({ ...props }) => {
  const [user, logout, activeOrganization] = useAuthStore((a) => [
    a.user,
    a.logout,
    a.activeOrganization,
  ]);
  const [language, changeLanguage] = useConfigStore((c) => [
    c.language,
    c.changeLanguage,
  ]);

  const navigate = useNavigate();

  const sidebar = useConfigStore((state) => state.sidebar);
  const [languageClicked, setLanguageClicked] = useState<boolean>(false);

  const { t } = useTranslation();

  const activeRoleTitle = useMemo(() => {
    for (const role of user?.roles ?? []) {
      if (role.organization === activeOrganization?.id) {
        return role.title;
      }
    }
    return undefined;
  }, [activeOrganization, user]);

  return (
    <Box
      h="100dvh"
      w="full"
      bg="white.alabaster"
      pos="fixed"
      alignSelf="stretch"
      filter="drop-shadow(0px 10px 15.3px rgba(191, 191, 191, 0.62))"
      {...props}
    >
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "leave" },
        }}
        style={{ height: "100%" }}
      >
        {sidebar ? (
          <Flex justifyContent="space-between" flexDir="column" h="full">
            <Box>
              <Flex
                alignItems="center"
                justifyContent="center"
                h="75px"
                w="full"
              >
                <Button
                  variant="unstyled"
                  size="none"
                  cursor="pointer"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <Flex alignItems="center">
                    <LogoBlue width="36px" height="36px" />

                    <Text
                      fontSize="18px"
                      fontWeight="700"
                      ml="3"
                      color="blue.brand"
                    >
                      {brandTitle}
                    </Text>
                  </Flex>
                </Button>
              </Flex>
              <Box
                mt="0px"
                borderRadius="3px"
                h="max-content"
                w="full"
                alignItems="center"
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                {user?.picture ? (
                  <Image
                    w="140px"
                    h="140px"
                    src={user.picture}
                    objectFit="cover"
                    objectPosition="center"
                  />
                ) : (
                  <UserAvatarIcon />
                )}

                <Text
                  color="black.active"
                  fontSize="12px"
                  fontWeight="600"
                  mt="14px"
                  lineHeight="1"
                  textOverflow="ellipsis"
                >
                  {user?.username || user?.email}
                </Text>
                <Text
                  color="black.active"
                  fontSize="16px"
                  fontWeight="400"
                  mt="16px"
                  lineHeight="1"
                  textOverflow="ellipsis"
                >
                  {activeRoleTitle && activeRoleTitle === "Owner"
                    ? t("owner")
                    : t("member")}
                </Text>
              </Box>

              <Box w="full" mt="38px">
                <SidebarItem
                  navIcon={<DashboardIcon fill="black.active" />}
                  title={t("page.dashboard.Dashboard")}
                  navigateTo="/"
                />
                <SidebarItem
                  navIcon={<AddAccountIcon fill="black.active" />}
                  title={t("add-account")}
                  navigateTo="/channels"
                />
                <SidebarItem
                  navIcon={<NewPostIcon fill="black.active" />}
                  title={t("create")}
                  navigateTo="/post"
                />

                <SidebarItem
                  navIcon={<PlannerIcon fill="black.active" />}
                  title={t("schedule")}
                  navigateTo="/planner"
                />
                {/* <SidebarItem
              navIcon={<AnalyticsIcon fill="black.active" />}
              title={t("analytics")}
              navigateTo="/analytics"
            /> */}
                <SidebarItem
                  navIcon={<MembershipIcon fill="black.active" />}
                  title={t("membership")}
                  navigateTo="/membership"
                />
                <SidebarItem
                  navIcon={<SettingsIcon fill="black.active" />}
                  title={t("settings")}
                  navigateTo="/settings"
                />
              </Box>
            </Box>
            <Box w="full">
              <Divider w="full" bg="gray.soft" opacity="0.33" />

              <Box py="20px" cursor="pointer">
                <Box onClick={() => setLanguageClicked(!languageClicked)}>
                  <SidebarItem
                    id="active"
                    navIcon={
                      language === "tr" ? (
                        <TurkishFlagCircle />
                      ) : language === "en" ? (
                        <LanguageIcon />
                      ) : (
                        <LanguageIcon />
                      )
                    }
                    title={t("language")}
                  />
                </Box>

                {languageClicked ? (
                  <Menu>
                    <Flex direction="column" py="1">
                      <MenuButton
                        onClick={() => {
                          changeLanguage("en");
                          setLanguageClicked(!languageClicked);
                        }}
                        w="full"
                        py="1"
                        justifyContent="center"
                        alignItems="center"
                        _hover={{
                          textDecor: "none",
                          backgroundColor: "blue.shiny",
                          color: "white",
                        }}
                        color="black.active"
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Icon boxSize="16px">
                            <LanguageIcon />
                          </Icon>
                          <Text fontSize="15px" fontWeight="500" ml="2">
                            {t("localization.en")}
                          </Text>
                        </Flex>
                      </MenuButton>
                      <MenuButton
                        w="full"
                        onClick={() => {
                          changeLanguage("tr");
                          setLanguageClicked(!languageClicked);
                        }}
                        _hover={{
                          textDecor: "none",
                          backgroundColor: "blue.shiny",
                          color: "white",
                        }}
                        color="black.active"
                        py="1"
                      >
                        <Flex justifyContent="center" alignItems="center">
                          <Icon boxSize="16px">
                            <TurkishFlagCircle />
                          </Icon>
                          <Text ml={2} fontSize="15px" fontWeight="500">
                            {t("localization.tr")}
                          </Text>
                        </Flex>
                      </MenuButton>
                    </Flex>
                  </Menu>
                ) : null}

                <a href="https://status.posteffect.ai" target="_black">
                  <SidebarItem
                    id="active"
                    navIcon={<UptimeIcon fill="black.active" />}
                    title={t("system-status")}
                  />
                </a>

                <SidebarItem
                  id="active"
                  navIcon={<PrivacyIcon fill="black.active" />}
                  title={t("privacy")}
                  navigateTo="/privacy"
                />

                <SidebarItem
                  id="active"
                  navIcon={<LogoutIcon fill="black.active" />}
                  title={t("logout")}
                  onClick={logout}
                />
              </Box>
            </Box>
          </Flex>
        ) : (
          <Box>
            <Flex alignItems="center" justifyContent="center" h="75px" w="full">
              <Button
                variant="unstyled"
                size="none"
                cursor="pointer"
                onClick={() => {
                  navigate("/");
                }}
              >
                <Flex alignItems="center">
                  <LogoBlue width="34px" height="34px" />
                </Flex>
              </Button>
            </Flex>
            <Box
              borderRadius="3px"
              h="max-content"
              w="full"
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              {user?.picture ? (
                <Image
                  w="36px"
                  h="36px"
                  src={user.picture}
                  objectFit="cover"
                  objectPosition="center"
                />
              ) : (
                <UserAvatarIcon width="36px" height="36px" />
              )}
            </Box>

            <Box w="full" mt="24px" justifyContent="center" alignItems="center">
              <SidebarItem
                navIcon={<DashboardIcon fill="black.active" />}
                navigateTo="/"
                boxSize="20px"
              />
              <SidebarItem
                navIcon={<NewPostIcon fill="black.active" />}
                boxSize="20px"
                navigateTo="/post"
              />

              <SidebarItem
                navIcon={<PlannerIcon fill="black.active" />}
                boxSize="20px"
                navigateTo="/planner"
              />
              <SidebarItem
                navIcon={<MembershipIcon fill="black.active" />}
                boxSize="20px"
                navigateTo="/membership"
              />

              <SidebarItem
                navIcon={<SettingsIcon fill="black.active" />}
                boxSize="20px"
                navigateTo="/settings"
              />
            </Box>
            {/* <Divider bg="gray.thin" opacity="0.33" mt="4" /> */}

            <Box pos="absolute" bottom="0" w="full">
              <Divider w="full" bg="gray.soft" opacity="0.33" mt="14" />

              <Box py="20px" cursor="pointer">
                <Box
                  onClick={() => {
                    language === "en"
                      ? changeLanguage("tr")
                      : changeLanguage("en");
                    setLanguageClicked(!languageClicked);
                  }}
                >
                  <SidebarItem
                    id="active"
                    navIcon={
                      language === "tr" ? (
                        <TurkishFlagCircle />
                      ) : language === "en" ? (
                        <LanguageIcon />
                      ) : (
                        <LanguageIcon />
                      )
                    }
                    boxSize="20px"
                  />
                </Box>
                <a href="https://status.posteffect.ai" target="_black">
                  <SidebarItem
                    id="active"
                    navIcon={<UptimeIcon fill="black.active" />}
                    boxSize="20px"
                  />
                </a>

                <SidebarItem
                  id="active"
                  navIcon={<PrivacyIcon fill="black.active" />}
                  boxSize="20px"
                />

                <SidebarItem
                  id="active"
                  navIcon={<LogoutIcon fill="black.active" />}
                  boxSize="20px"
                  onClick={logout}
                />
              </Box>
            </Box>
          </Box>
        )}
      </OverlayScrollbarsComponent>
    </Box>
  );
};
