export const FilterDIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="2.5"
        fill="#F7F8FA"
        stroke="#EBEDF2"
      />
      <path
        d="M15.5704 5H5.42971C5.19236 5 5 5.19235 5 5.42969C5 6.62627 5.51297 7.76864 6.40739 8.56365L8.2893 10.2362C8.61561 10.5263 8.80277 10.943 8.80277 11.3797V15.5698C8.80277 15.9122 9.18548 16.1175 9.47075 15.9273L12.006 14.2373C12.1255 14.1576 12.1973 14.0235 12.1973 13.8798V11.3797C12.1973 10.943 12.3845 10.5263 12.7108 10.2362L14.5926 8.56365C15.487 7.76864 16 6.62627 16 5.42969C16 5.19235 15.8076 5 15.5704 5ZM14.0217 7.92129L12.1398 9.59397C11.6302 10.047 11.3379 10.6978 11.3379 11.3796V13.6498L9.66211 14.7669V11.3797C9.66211 10.6978 9.36979 10.047 8.86018 9.59397L6.97835 7.92138C6.37524 7.38519 5.99026 6.65052 5.8872 5.85929H15.1128C15.0097 6.65052 14.6248 7.38519 14.0217 7.92129Z"
        fill={props.fill || "#33373D"}
      />
    </svg>
  );
};
