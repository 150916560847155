export const FilterBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.464233"
        width="29.8998"
        height="30"
        rx="6"
        fill="#FAFAFA"
        stroke="#EBEDF2"
      />
      <path
        d="M12.7897 14.6775C12.9595 14.8631 13.0528 15.1054 13.0528 15.3562V22.7445C13.0528 23.1892 13.5872 23.4148 13.9035 23.1022L15.9564 20.7403C16.2311 20.4093 16.3827 20.2455 16.3827 19.9179V15.3578C16.3827 15.1071 16.4776 14.8647 16.6457 14.6792L22.5363 8.26203C22.9775 7.78062 22.6379 7 21.9852 7H7.4502C6.79754 7 6.45623 7.77895 6.8991 8.26203L12.7897 14.6775Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
