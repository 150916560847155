import { globalToast } from "@/constant";
import { useInvitationStore } from "@/context/invitation-store/invitation-store";
import { i18n } from "@/i18n";
import { CircularProgress, Flex, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const RejectInviteCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const setInvitation = useInvitationStore((state) => state.setInvitation);

  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (token) {
        setInvitation({ token, action: "reject" });
      }

      globalToast.toast({
        status: "success",
        title: i18n.t("alert.invitation.please-login-to-reject-invitation"),
      });
      navigate(`/auth/login`);
    }, 5000);

    return () => clearTimeout(timer);
  }, [navigate, setInvitation, token]);

  return (
    <Flex
      height="100vh"
      width="100%"
      flexDirection="column"
      justifyContent="center"
    >
      <VStack spacing={4}>
        <CircularProgress isIndeterminate color="green.300" />
        <Text fontSize="xl">{t("invite-rejected-redirect")}...</Text>
      </VStack>
    </Flex>
  );
};

export default RejectInviteCallbackPage;
