import React from "react";

export const MembershipIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86231 11.3467L0.949558 5.643C0.889558 5.26875 1.05456 4.89375 1.37106 4.68525C1.68756 4.47675 2.09631 4.473 2.41656 4.67475L5.27631 6.48075L8.27331 3.04575C8.45631 2.83575 8.72181 2.715 9.00006 2.715C9.27831 2.715 9.54381 2.83575 9.72681 3.04575L12.7238 6.48075L15.5836 4.67475C15.9038 4.473 16.3126 4.47675 16.6291 4.68525C16.9456 4.89375 17.1106 5.26875 17.0506 5.643L16.1378 11.3467H1.86231ZM15.9578 12.4717L15.6916 14.1345C15.5858 14.7975 15.0136 15.285 14.3431 15.285H3.65706C2.98656 15.285 2.41431 14.7975 2.30856 14.1345L2.04231 12.4717H15.9578Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
