import { APISchemas, POST } from "@/api";
import {
  Box,
  Image as ChakraImage,
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Text,
  Tooltip,
  useDisclosure,
  useOutsideClick,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import { useTranslation } from "react-i18next";
import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDropzone } from "react-dropzone";
import { ImageUploadIcon } from "@/assets/icons/image-upload-icon";
import MediaInfoFactory, { TrackType } from "mediainfo.js";
import { PreviewIcon } from "@/assets/icons/preview-icon";
import EditVideoModal from "@/pages/dashboard/Post/components/edit-video-modal";
import { Reorder } from "framer-motion";
import { isEqual } from "lodash";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import * as tus from "tus-js-client";
import uuid4 from "uuid4";
import { DelayedImage } from "../delayed-image/delayed-image";
import { LogoLoader } from "../loading/logo-loader";

interface DropzoneFile {
  data: {
    image?: APISchemas["ImageUploadResponse"];
    video?: APISchemas["VideoUploadResponse"] & { coverUrl?: string | null };
  };
  api: File;
  analyzed?: TrackType[];
  progress?: number;
  id?: string;
  abort?: AbortController | tus.Upload;
}

export interface DropzoneProps {
  value: Array<DropzoneFile>;
  onChange: (files: Array<DropzoneFile>) => void;
  onBusy?: (loading: boolean) => void;
  placeholder?: string;
  mediaType?: "image" | "video";
  limit?: number;
}

export const Dropzone: FC<DropzoneProps> = ({
  value,
  onChange,
  onBusy,
  placeholder,
  mediaType,
  limit,
}) => {
  const toast = useToast();
  const propValue = useRef<Array<DropzoneFile>>(value ?? []);

  const [files, updateFiles] = useState<Array<DropzoneFile>>([]);
  const { t } = useTranslation();
  placeholder ??= t("add_image_or_video");
  const { isOpen, onOpen, onClose } = useDisclosure();

  if (!isEqual(propValue.current, value)) {
    propValue.current = value;
    propValue.current.forEach((v) => {
      if (!v.id) {
        v.id = uuid4();
      }
    });

    updateFiles(propValue.current);
  }

  const removeFile = useCallback((id?: string) => {
    if (!id) {
      return;
    }

    return () => {
      updateFiles((f) => {
        const found = f.findIndex((file) => file.id === id);

        if (found === -1) {
          return f;
        }

        f.splice(found, 1);

        return [...f];
      });
    };
  }, []);

  const onFileDrop = useCallback(
    (item: File[]) => {
      if (limit && files.length >= limit) {
        toast({
          status: "error",
          title: t("page.dropzone.errors.file-limit", { limit }),
        });
        return;
      }

      item.forEach(async (file) => {
        const fileId = uuid4();
        const abortController = new AbortController();

        updateFiles((f) => {
          return [
            ...f,
            {
              id: fileId,
              api: file,
              data: {},
              progress: 0,
              abort: abortController,
            },
          ];
        });
        const mediaInfo = await MediaInfoFactory();

        const info = await mediaInfo.analyzeData(
          () => file.size,
          async (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.error) {
                  reject(event.target.error);
                  return;
                }

                if (event.target?.result) {
                  if (typeof event.target.result === "string") {
                    const encoder = new TextEncoder();
                    resolve(
                      new Uint8Array(encoder.encode(event.target.result).buffer)
                    );
                    return;
                  }
                  resolve(new Uint8Array(event.target.result));
                }

                resolve(new Uint8Array());
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            })
        );

        const file_type = file.type || `video/${file.name.split(".").pop()}`;

        const type: "image" | "video" | null = file_type.startsWith("image")
          ? "image"
          : file_type.startsWith("video")
            ? "video"
            : null;

        if (!type) {
          toast({
            status: "error",
            title: t("page.dropzone.errors.video-type"),
          });

          return;
        }

        const general = info.media?.track.find((t) => t["@type"] === "General");

        if (type === "video") {
          const video = info.media?.track.find((t) => t["@type"] === "Video");

          if (!general || !video || !info.media?.track) {
            toast({
              title: t("page.dropzone.errors.video-info", {
                fileName: file.name,
              }),
              status: "error",
            });
            removeFile(fileId)?.();
            return;
          }

          for (const key of ["Duration", "Format", "FrameRate"]) {
            if (!(key in general)) {
              toast({
                title: t("page.dropzone.errors.video-info", {
                  fileName: file.name,
                }),
                status: "error",
              });
              console.error(`key: ${key} not found in file meta`);
              removeFile(fileId)?.();
              return;
            }
          }

          if (general.Format !== "MPEG-4") {
            toast({
              title: t("page.dropzone.errors.video-not-supported", {
                fileName: file.name,
              }),
              status: "error",
            });
            console.error(`Video Format: ${general.Format} does not supported`);

            removeFile(fileId)?.();
            return;
          }

          for (const key of ["Height", "Width"]) {
            if (!(key in video)) {
              toast({
                title: t("page.dropzone.errors.video-info", {
                  fileName: file.name,
                }),
                status: "error",
              });
              console.error(`key: ${key} not found in file meta`);
              removeFile(fileId)?.();
              return;
            }
          }

          const { data, error } = await POST("/media/upload/video/", {
            body: {
              mimetype: file_type,
              duration: general.Duration?.toString() ?? "",
              format: general.Format ?? "",
              frame_rate: general.FrameRate?.toString() ?? "",
              height: video.Height?.toString() ?? "",
              width: video.Width?.toString() ?? "",
              raw: JSON.parse(JSON.stringify(info.media.track)),
              size: file.size.toString(),
            },
          });

          if (error) {
            toast({
              status: "error",
              title: error.detail,
            });

            removeFile(fileId)?.();

            return;
          }
          if (!data) {
            // how to handle this

            return;
          }

          const upload = new tus.Upload(file, {
            endpoint: "https://video.bunnycdn.com/tusupload",
            retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
            headers: {
              AuthorizationSignature: data?.presigned_signature,
              AuthorizationExpire: data?.expiration.toString(),
              VideoId: data?.video_id,
              LibraryId: data?.library_id.toString(),
            },
            metadata: {
              filetype: file.type,
              title: file.name,
            },
            onError: function (error) {
              console.error(error);
              removeFile(fileId)?.();
            },

            onProgress: function (bytesUploaded, bytesTotal) {
              const percent = Math.round(
                (bytesUploaded * 100) / (bytesTotal ?? 1)
              );

              updateFiles((f) => {
                const index = f.findIndex((file) => file.id === fileId);

                if (index === -1) {
                  return f;
                }

                f[index].progress = percent;

                return [...f];
              });
            },
            onSuccess: function () {
              updateFiles((f) => {
                const index = f.findIndex((fi) => fi.id === fileId);

                if (index === -1) {
                  return f;
                }

                f[index] = {
                  id: fileId,
                  data: {
                    video: {
                      ...data,
                    },
                  },
                  api: file,
                  analyzed: info.media?.track,
                  progress: undefined,
                };

                return [...f];
              });
            },
          });

          upload.findPreviousUploads().then(function (previousUploads) {
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0]);
            }

            updateFiles((f) => {
              const index = f.findIndex((fi) => fi.id === fileId);

              if (index === -1) {
                return f;
              }

              f[index].id = fileId;

              f[index].abort = upload;

              return [...f];
            });

            upload.start();
          });

          return;
        }

        const img = info.media?.track.find((t) => t["@type"] === "Image");

        if (!general || !img || !info.media?.track) {
          toast({
            title: t("page.dropzone.errors.image-info", {
              fileName: file.name,
            }),
            status: "error",
          });
          return;
        }

        for (const key of ["Height", "Width"]) {
          if (!(key in img)) {
            toast({
              title: t("page.dropzone.errors.image-info", {
                fileName: file.name,
              }),
              status: "error",
            });
            console.error(`key: ${key} not found in file meta`);
            return;
          }
        }

        const { data, error } = await POST("/media/upload/image/", {
          body: {
            height: img.Height?.toString() ?? "",
            width: img.Width?.toString() ?? "",
            size: file.size.toString(),
            raw: JSON.parse(JSON.stringify(info.media.track)),
          },
        });

        if (error) {
          toast({
            status: "error",
            title: error.detail,
          });

          removeFile(fileId)?.();

          return;
        }
        if (!data) {
          // how to handle this

          return;
        }
        const formData = new FormData();
        formData.append("file", file);

        axios
          .request({
            url: data.uploadUrl,
            method: "POST",
            data: formData,
            signal: abortController.signal,

            onUploadProgress: (progressEvent) => {
              const percent = Math.round(
                (progressEvent.loaded * 100) / (progressEvent.total ?? 1)
              );

              updateFiles((f) => {
                const index = f.findIndex((file) => file.id === fileId);

                if (index === -1) {
                  return f;
                }

                f[index].progress = percent;

                return [...f];
              });
            },
          })
          .then(() => {
            updateFiles((f) => {
              const index = f.findIndex((fi) => fi.id === fileId);

              if (index === -1) {
                return f;
              }

              f[index] = {
                id: fileId,
                data: {
                  image: data,
                },
                api: file,
                analyzed: info.media?.track,
                progress: undefined,
              };

              return [...f];
            });
          })
          .catch((reason: AxiosError) => {
            if (reason instanceof axios.CanceledError) {
              removeFile(fileId)?.();
              return;
            }

            toast({
              status: "error",
              title:
                reason.cause?.message ??
                reason.message ??
                JSON.stringify(
                  reason.response?.data ??
                    t("page.dropzone.errors.unexpected-error-during-upload")
                ),
            });

            removeFile(fileId)?.();
          });
      });
    },
    [removeFile, toast, limit, files, t]
  );

  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onFileDrop,
    maxFiles: limit,
    accept:
      mediaType === "image"
        ? { "image/jpg": [".png", ".jpg", ".jpeg"] }
        : mediaType === "video"
          ? { "video/mp4": [".mp4", ".wmv", ".mov", ".avi", ".webm"] }
          : {
              "image/jpg": [".png", ".jpg", ".jpeg"],
              "video/mp4": [".mp4", ".wmv", ".mov", ".avi", ".webm"],
            },
  });

  useEffect(() => {
    if (propValue.current === files) {
      return;
    }

    const timer = setTimeout(() => onChange(files), 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [files, onChange]);

  useEffect(() => {
    onBusy?.(!files.every((f) => !f.progress));
  }, [onBusy, files]);

  return (
    <Flex alignItems="center" gap={files.length === 0 ? "0" : "3"}>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            autoHide: "scroll",
          },
        }}
      >
        <HStack
          as={Reorder.Group}
          values={files}
          initial={false}
          axis="x"
          layoutScroll
          onReorder={updateFiles}
          h="36"
        >
          {files.map((file) =>
            file.data.image ? (
              <ClickOverlay
                buttons={[
                  <RemoveButton key="remove" onClick={removeFile(file.id)} />,
                ]}
                value={file}
                key={file.data.image.id}
              >
                <Box
                  pos="relative"
                  flexShrink="0"
                  rounded="lg"
                  overflow="hidden"
                  cursor="pointer"
                >
                  <ChakraImage
                    src={file.data.image.downloadUrl}
                    h="28"
                    draggable="false"
                  />
                </Box>
              </ClickOverlay>
            ) : file.data.video ? (
              <ClickOverlay
                value={file}
                buttons={[
                  <PreviewButton key="preview" href={file.data.video.url} />,
                  <RemoveButton key="remove" onClick={removeFile(file.id)} />,
                  <EditButton key="edit" onClick={onOpen} />,
                ]}
                key={file.data.video.id}
              >
                <Flex
                  pos="relative"
                  width="48"
                  flexShrink="0"
                  rounded="lg"
                  cursor="pointer"
                  justifyContent="center"
                >
                  <DelayedImage
                    src={
                      file.data.video.coverUrl ??
                      file.data.video.thumbnail_url.replace(
                        "thumbnail.jpg",
                        "preview.webp"
                      )
                    }
                    fallbackUrl={file.data.video.thumbnail_url}
                  />
                </Flex>
                {isOpen && !!file.data.video && (
                  <EditVideoModal
                    isOpen={isOpen}
                    close={() => onClose()}
                    data={{ video: { ...file.data.video } }}
                    updateFile={(thumbnail_url?: string) =>
                      updateFiles((prevFiles) => {
                        const fileIndex = prevFiles.findIndex(
                          (fil) => fil.id === file.id
                        );
                        if (fileIndex === -1) {
                          return prevFiles;
                        }

                        const found = prevFiles[fileIndex].data.video;
                        if (!found) {
                          return prevFiles;
                        }

                        const updatedFile = {
                          ...prevFiles[fileIndex],
                          data: {
                            ...prevFiles[fileIndex].data,
                            video: {
                              ...found,
                              coverUrl: thumbnail_url,
                            },
                          },
                        };
                        return [
                          ...prevFiles.slice(0, fileIndex),
                          updatedFile,
                          ...prevFiles.slice(fileIndex + 1),
                        ];
                      })
                    }
                  />
                )}
              </ClickOverlay>
            ) : (
              <ClickOverlay
                key={file?.id ?? Date.now()}
                value={file}
                buttons={[
                  <RemoveButton
                    key="remove"
                    onClick={async () => {
                      if (file.abort instanceof AbortController) {
                        file.abort.abort();
                        return;
                      }

                      if (file.abort) {
                        await file.abort.abort();
                        removeFile(file.id)?.();
                      }
                    }}
                  />,
                ]}
              >
                <Box
                  pos="relative"
                  rounded="lg"
                  flexShrink="0"
                  cursor="pointer"
                  w="28"
                  h="28"
                  bg="black.active"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                >
                  <Icon boxSize="20">
                    <LogoLoader />
                  </Icon>
                  <Text fontSize="8px" color="white" mt="1">
                    {file.progress}%
                  </Text>
                </Box>
              </ClickOverlay>
            )
          )}
        </HStack>
      </OverlayScrollbarsComponent>

      <Box>
        <Flex
          w="28"
          h="28"
          cursor="pointer"
          flexDir="column"
          alignItems="center"
          transition="border-color 0.75s ease-in-out"
          border="1px dashed #353B48"
          rounded="lg"
          flexShrink="0"
          px="1.5"
          pt="2"
          pb="4"
          pos="relative"
          {...fileRoot()}
        >
          <Text
            lineHeight="16.5px"
            fontSize="xs"
            align="center"
            fontWeight="light"
            mb="5"
          >
            {placeholder}
          </Text>

          <ImageUploadIcon />

          <input {...fileInput()} />
        </Flex>
      </Box>
    </Flex>
  );
};

const ClickOverlay: FC<
  PropsWithChildren<{ buttons: Array<ReactNode>; value: DropzoneFile }>
> = ({ children, buttons, value }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState<boolean>(false);
  useOutsideClick({
    ref,
    handler: () => setShow(false),
  });

  return (
    <Box
      ref={ref}
      as={Reorder.Item}
      layoutScroll
      value={value}
      pos="relative"
      flexShrink="0"
      minH="min-content"
      minW="min-content"
      rounded="lg"
      overflow="hidden"
      onHoverStart={() => setShow(true)}
      onHoverEnd={() => setShow(false)}
    >
      {children}
      <Flex
        display={show ? undefined : "none"}
        pos="absolute"
        bottom="0"
        bg="#E2E3E6A8"
        w="full"
        h="10"
        gap="2"
        justifyContent="center"
        alignItems="center"
      >
        {buttons}
      </Flex>
    </Box>
  );
};

const RemoveButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("dropzone.delete")} placement="bottom">
      <IconButton
        aria-label="remove-media"
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant="unstyled"
        bg="#F4F6F8"
        size="none"
        w="30px"
        h="30px"
        rounded="full"
        onClick={onClick}
      >
        <Icon w="16px" h="19px">
          <svg
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.88186 5.24658C7.69454 5.24658 7.54272 5.3984 7.54272 5.58572V11.9954C7.54272 12.1826 7.69454 12.3346 7.88186 12.3346C8.06918 12.3346 8.221 12.1826 8.221 11.9954V5.58572C8.221 5.3984 8.06918 5.24658 7.88186 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M3.87967 5.24658C3.69234 5.24658 3.54053 5.3984 3.54053 5.58572V11.9954C3.54053 12.1826 3.69234 12.3346 3.87967 12.3346C4.06699 12.3346 4.2188 12.1826 4.2188 11.9954V5.58572C4.2188 5.3984 4.06699 5.24658 3.87967 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M0.963207 4.3112V12.6668C0.963207 13.1607 1.1443 13.6245 1.46065 13.9573C1.77555 14.291 2.21378 14.4804 2.67241 14.4812H9.089C9.54776 14.4804 9.98599 14.291 10.3008 13.9573C10.6171 13.6245 10.7982 13.1607 10.7982 12.6668V4.3112C11.4271 4.14428 11.8346 3.53675 11.7504 2.89146C11.6662 2.2463 11.1165 1.76369 10.4658 1.76356H8.72946V1.33964C8.73145 0.983147 8.59049 0.64083 8.33813 0.388993C8.08576 0.137289 7.74291 -0.00286991 7.38642 4.45599e-05H4.37499C4.01849 -0.00286991 3.67565 0.137289 3.42328 0.388993C3.17091 0.64083 3.02996 0.983147 3.03195 1.33964V1.76356H1.29559C0.644867 1.76369 0.0952259 2.2463 0.0109713 2.89146C-0.0731507 3.53675 0.334344 4.14428 0.963207 4.3112ZM9.089 13.803H2.67241C2.09256 13.803 1.64148 13.3048 1.64148 12.6668V4.34101H10.1199V12.6668C10.1199 13.3048 9.66885 13.803 9.089 13.803ZM3.71022 1.33964C3.70797 1.16305 3.77739 0.993082 3.90271 0.868423C4.0279 0.743763 4.19826 0.675273 4.37499 0.67832H7.38642C7.56315 0.675273 7.73351 0.743763 7.8587 0.868423C7.98402 0.99295 8.05344 1.16305 8.05119 1.33964V1.76356H3.71022V1.33964ZM1.29559 2.44184H10.4658C10.803 2.44184 11.0763 2.71513 11.0763 3.05228C11.0763 3.38944 10.803 3.66273 10.4658 3.66273H1.29559C0.958437 3.66273 0.68514 3.38944 0.68514 3.05228C0.68514 2.71513 0.958437 2.44184 1.29559 2.44184Z"
              fill="#353B48"
            />
            <path
              d="M5.88089 5.24658C5.69357 5.24658 5.54175 5.3984 5.54175 5.58572V11.9954C5.54175 12.1826 5.69357 12.3346 5.88089 12.3346C6.06821 12.3346 6.22002 12.1826 6.22002 11.9954V5.58572C6.22002 5.3984 6.06821 5.24658 5.88089 5.24658Z"
              fill="#353B48"
            />
          </svg>
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

const EditButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("dropzone.edit")} placement="bottom">
      <IconButton
        aria-label="remove-media"
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant="unstyled"
        bg="#F4F6F8"
        size="none"
        w="30px"
        h="30px"
        rounded="full"
        onClick={onClick}
      >
        <Icon w="16px" h="19px" name="EditIcon">
          <svg
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.3379 5.83595C11.1276 5.83595 10.9571 6.00648 10.9571 6.21681V11.8028C10.9571 12.1528 10.6723 12.4375 10.3223 12.4375H2.19727C1.84726 12.4375 1.5625 12.1528 1.5625 11.8028V3.67774C1.5625 3.32773 1.84726 3.04297 2.19727 3.04297H7.78324C7.99357 3.04297 8.1641 2.87245 8.1641 2.66211C8.1641 2.45177 7.99357 2.28125 7.78324 2.28125H2.19727C1.42725 2.28125 0.800781 2.90772 0.800781 3.67774V11.8028C0.800781 12.5728 1.42725 13.1993 2.19727 13.1993H10.3223C11.0923 13.1993 11.7188 12.5728 11.7188 11.8028V6.21681C11.7188 6.00648 11.5483 5.83595 11.3379 5.83595Z"
              fill="#33373D"
            />
            <path
              d="M13.5424 1.17726L12.8242 0.45908C12.4778 0.112598 11.914 0.112598 11.5675 0.45908L5.82224 6.20434C5.76907 6.25751 5.73284 6.32523 5.71806 6.39896L5.35896 8.19437C5.334 8.31924 5.37308 8.44833 5.46314 8.53836C5.53527 8.6105 5.63244 8.64993 5.73243 8.64993C5.75729 8.64993 5.78227 8.64749 5.80711 8.64254L7.60251 8.28344C7.67624 8.26869 7.74396 8.23243 7.79713 8.17926L13.5424 2.43402C13.5424 2.43402 13.5424 2.43402 13.5424 2.434C13.8889 2.08754 13.8889 1.52376 13.5424 1.17726ZM7.34007 7.55912L6.21795 7.78357L6.44241 6.66145L11.1186 1.98517L12.0163 2.88288L7.34007 7.55912ZM13.0038 1.89541L12.5549 2.34427L11.6572 1.44655L12.1061 0.997719C12.1556 0.948207 12.2361 0.948182 12.2856 0.997694L13.0038 1.71587C13.0533 1.76536 13.0533 1.84592 13.0038 1.89541Z"
              fill="#33373D"
            />
          </svg>
        </Icon>
      </IconButton>
    </Tooltip>
  );
};

const PreviewButton: FC<{ href: string }> = ({ href }) => {
  const { t } = useTranslation();
  return (
    <Tooltip label={t("dropnoze.preview")} placement="bottom">
      <Link
        aria-label="remove-media"
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant="unstyled"
        bg="#F4F6F8"
        size="none"
        w="30px"
        h="30px"
        rounded="full"
        href={href}
        target="_blank"
        isExternal
      >
        <Icon w="16px" h="19px">
          <PreviewIcon />
        </Icon>
      </Link>
    </Tooltip>
  );
};
