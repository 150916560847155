import { FC } from "react";

export const TelegramIcon: FC<
  React.SVGAttributes<SVGElement> & { inverse?: boolean }
> = ({ inverse = true, ...props }) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z"
        fill={inverse ? "white" : "#039BE5"}
      />
      <path
        d="M19.9556 37.8621L57.2028 23.1797C58.9316 22.5412 60.4414 23.6109 59.8813 26.2834L59.8845 26.2801L53.5425 56.8266C53.0725 58.9922 51.8137 59.5188 50.0528 58.4985L40.3949 51.2215L35.7366 55.8096C35.2215 56.3362 34.7869 56.7805 33.7889 56.7805L34.4746 46.7322L52.3739 30.2C53.1529 29.499 52.2 29.104 51.1731 29.8018L29.0533 44.0399L19.5178 40.9987C17.4478 40.3273 17.4027 38.8824 19.9556 37.8621Z"
        fill={inverse ? "#039BE5" : "white"}
      />
    </svg>
  );
};
