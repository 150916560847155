import { FC } from "react";

export const VisaLogoIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 27 29"
      fill="none"
      {...props}
    >
      <path
        d="M27 22.7853C27 23.7321 26.2854 24.4996 25.404 24.4996H1.59601C0.714551 24.4997 0 23.7321 0 22.7853V6.21429C0 5.26748 0.714551 4.5 1.59601 4.5H25.404C26.2855 4.5 27 5.26754 27 6.21429V22.7853Z"
        fill="white"
      />
      <path
        d="M5.99263 14.6148L5.35866 11.3483C5.25029 10.8978 4.93494 10.7638 4.5409 10.7461H1.63429L1.6084 10.8953C3.87223 11.4848 5.37015 12.9068 5.99263 14.6148Z"
        fill="#F79F1A"
      />
      <path
        d="M12.7277 10.8125H10.8497L9.67676 18.2056H11.5517L12.7277 10.8125Z"
        fill="#059BBF"
      />
      <path
        d="M7.13719 18.1971L10.0908 10.8164H8.10323L6.26581 15.859L6.06969 15.0976C5.70952 14.1817 4.68014 12.8634 3.47168 12.0335L5.15127 18.1996L7.13719 18.1971Z"
        fill="#059BBF"
      />
      <path
        d="M18.084 15.7884C18.091 14.949 17.5892 14.3088 16.508 13.78C15.8508 13.4385 15.4503 13.2107 15.4551 12.8641C15.4551 12.5579 15.7943 12.229 16.5292 12.229C17.1441 12.2189 17.5869 12.3631 17.9332 12.5124L18.1029 12.5959L18.3574 10.9943C17.9852 10.8449 17.4032 10.6855 16.6754 10.6855C14.8215 10.6855 13.5164 11.6876 13.5046 13.1247C13.4928 14.1875 14.4376 14.7795 15.1489 15.1337C15.8792 15.4947 16.1242 15.7258 16.1195 16.0496C16.1148 16.5448 15.5375 16.7707 14.9981 16.7707C14.2467 16.7707 13.8485 16.6594 13.231 16.3831L12.9911 16.2647L12.7275 17.9214C13.1654 18.1269 13.9757 18.3066 14.8168 18.3141C16.7883 18.3141 18.0699 17.3248 18.084 15.7884Z"
        fill="#059BBF"
      />
      <path
        d="M20.3152 17.0977C20.5296 17.0977 22.4447 17.1002 22.7181 17.1002C22.7747 17.3577 22.9466 18.2084 22.9466 18.2084H24.6874L23.168 10.8203H21.7193C21.2693 10.8203 20.9349 10.9519 20.7369 11.4325L17.9502 18.2084H19.922C19.9219 18.2084 20.2422 17.2977 20.3152 17.0977ZM21.6014 13.5174C21.5921 13.5376 21.7569 13.0898 21.8512 12.8115L21.9783 13.4491C21.9783 13.4491 22.3365 15.2152 22.4118 15.5846H20.8546C21.0102 15.1596 21.6014 13.5174 21.6014 13.5174Z"
        fill="#059BBF"
      />
      <path
        d="M25.404 4.5H1.59601C0.714551 4.5 0 5.26748 0 6.21429V7.99348H27V6.21429C27 5.26748 26.2855 4.5 25.404 4.5Z"
        fill="#059BBF"
      />
      <path
        d="M25.404 24.4993H1.59601C0.714551 24.4993 0 23.7318 0 22.7851V21.0059H27V22.7851C27 23.7318 26.2855 24.4993 25.404 24.4993Z"
        fill="#F79F1A"
      />
      <path
        opacity="0.15"
        d="M1.13469 22.7853V6.21429C1.13469 5.26748 1.84929 4.5 2.7308 4.5H1.59595C0.714551 4.5 0 5.26748 0 6.21429V22.7854C0 23.7322 0.714551 24.4997 1.59601 24.4997H2.73085C1.84934 24.4997 1.13469 23.7321 1.13469 22.7853Z"
        fill="#202121"
      />
    </svg>
  );
};
