import React from "react";

export const StatusPendingIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.9403 16.5141C10.7902 16.5515 10.6371 16.5847 10.4852 16.6127C10.0881 16.6865 9.82559 17.0684 9.89904 17.4659C9.93531 17.6614 10.0464 17.8243 10.1971 17.9306C10.3525 18.0399 10.5503 18.0893 10.7518 18.0518C10.9327 18.0183 11.115 17.9788 11.2939 17.9341C11.686 17.8365 11.9249 17.4393 11.8271 17.0474C11.7295 16.6551 11.3326 16.4164 10.9403 16.5141Z"
        fill="#FBC531"
      />
      <path
        d="M16.352 6.70512C16.4033 6.8596 16.5012 6.98572 16.6248 7.07287C16.8081 7.20196 17.0479 7.24539 17.2768 7.16971C17.6605 7.0423 17.8684 6.62854 17.7414 6.24502C17.6836 6.07019 17.6194 5.8948 17.5511 5.72404C17.401 5.34878 16.9753 5.16608 16.5998 5.31618C16.2248 5.46621 16.042 5.89199 16.1922 6.26737C16.2497 6.4109 16.3035 6.55822 16.352 6.70512Z"
        fill="#FBC531"
      />
      <path
        d="M13.3184 15.469C13.1894 15.5543 13.0561 15.6367 12.9219 15.714C12.5717 15.916 12.4518 16.3636 12.6537 16.7136C12.7085 16.8089 12.7815 16.8867 12.8656 16.9462C13.0914 17.105 13.398 17.1288 13.6532 16.9817C13.8128 16.8897 13.9715 16.7918 14.1252 16.6901C14.4623 16.4674 14.555 16.0134 14.3322 15.6762C14.1095 15.3388 13.6556 15.2462 13.3184 15.469Z"
        fill="#FBC531"
      />
      <path
        d="M18.1964 8.74995C18.1805 8.34605 17.8403 8.03167 17.4362 8.04744C17.0327 8.06337 16.7181 8.40369 16.734 8.80743C16.74 8.9618 16.7416 9.11848 16.738 9.27269C16.7324 9.52599 16.8563 9.75177 17.0488 9.88747C17.1635 9.96824 17.3027 10.0171 17.4536 10.0206C17.8575 10.0295 18.1922 9.70916 18.2012 9.30506C18.2052 9.12079 18.2037 8.9341 18.1964 8.74995Z"
        fill="#FBC531"
      />
      <path
        d="M16.2322 13.5431C15.9084 13.3001 15.4501 13.3661 15.2076 13.6894C15.1147 13.8132 15.0168 13.9355 14.9167 14.0532C14.6549 14.3608 14.6919 14.8228 14.9994 15.0847C15.017 15.0996 15.0347 15.1133 15.0531 15.1261C15.3588 15.3417 15.7838 15.2921 16.031 15.002C16.1506 14.8615 16.2673 14.7155 16.3783 14.5676C16.6209 14.2443 16.5552 13.7858 16.2322 13.5431Z"
        fill="#FBC531"
      />
      <path
        d="M17.3085 10.8989C16.9229 10.778 16.5122 10.9927 16.3914 11.3783C16.3451 11.5257 16.2939 11.6737 16.2385 11.8187C16.117 12.1378 16.2334 12.489 16.5003 12.6771C16.5493 12.7115 16.6032 12.7407 16.6618 12.7628C17.0393 12.9069 17.4621 12.7175 17.6061 12.3398C17.6718 12.1674 17.7327 11.9913 17.7879 11.8161C17.9086 11.4304 17.6941 11.0199 17.3085 10.8989Z"
        fill="#FBC531"
      />
      <path
        d="M7.74777 16.6192C7.09351 16.5018 6.4659 16.3017 5.87076 16.0227C5.86372 16.0191 5.85742 16.0149 5.85002 16.0115C5.70978 15.9455 5.56977 15.8748 5.43407 15.8009C5.4336 15.8004 5.43273 15.8 5.43199 15.7997C5.183 15.6626 4.94 15.5109 4.70393 15.3447C1.26155 12.9198 0.433863 8.14634 2.85893 4.704C3.38625 3.95575 4.02439 3.3315 4.73582 2.83673C4.74459 2.83063 4.75335 2.82456 4.76204 2.81842C7.26901 1.09105 10.6731 0.974635 13.3351 2.74967L12.7633 3.57575C12.6044 3.80568 12.7022 3.97324 12.9804 3.94819L15.4639 3.72585C15.7425 3.70081 15.9092 3.45981 15.8342 3.19075L15.1673 0.787904C15.0927 0.51853 14.9015 0.486287 14.7424 0.716178L14.1693 1.54422C12.2158 0.232839 9.87271 -0.267208 7.54707 0.13607C7.31283 0.176609 7.08189 0.226226 6.85411 0.283983C6.85235 0.284296 6.85094 0.284491 6.84953 0.284804C6.84073 0.286957 6.8318 0.289813 6.82323 0.2922C4.8178 0.807195 3.06808 1.97684 1.81955 3.64568C1.80902 3.65816 1.79818 3.67037 1.78825 3.68395C1.74673 3.73986 1.70552 3.79707 1.66518 3.85428C1.59921 3.94804 1.53417 4.04414 1.47195 4.14025C1.46417 4.15183 1.45822 4.16361 1.45141 4.17531C0.421107 5.77187 -0.0758496 7.61765 0.00937646 9.49677C0.00957211 9.50295 0.00921994 9.50917 0.00937646 9.51551C0.017633 9.69907 0.0321895 9.88518 0.0519504 10.0684C0.0530069 10.0802 0.0556286 10.0914 0.0576243 10.1032C0.0780503 10.2874 0.103681 10.4721 0.135807 10.6567C0.462311 12.5404 1.35088 14.2354 2.68238 15.5543C2.68547 15.5574 2.68868 15.5607 2.69181 15.5639C2.6929 15.5652 2.69411 15.5658 2.69517 15.5669C3.0529 15.9198 3.44201 16.2461 3.86098 16.5412C4.95742 17.3138 6.17833 17.8245 7.48951 18.0598C7.88735 18.1312 8.26738 17.8664 8.33875 17.4688C8.41009 17.0709 8.14549 16.6905 7.74777 16.6192Z"
        fill="#FBC531"
      />
      <path
        d="M8.65286 3.18945C8.32562 3.18945 8.06055 3.45476 8.06055 3.78154V9.68033L13.4555 12.4692C13.5423 12.5142 13.6353 12.5353 13.7267 12.5353C13.941 12.5353 14.148 12.4186 14.2532 12.215C14.4033 11.9245 14.2897 11.5675 13.9991 11.4174L9.24452 8.95931V3.78154C9.24448 3.45476 8.97972 3.18945 8.65286 3.18945Z"
        fill="#FBC531"
      />
    </svg>
  );
};
