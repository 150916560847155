import { useConfigStore } from "@/context/config-store/config-store";
import { Box, Card, CardBody, CardHeader, IconButton } from "@chakra-ui/react";
import { TermAndConditionEn } from "../_terms_en";
import { TermAndConditionTr } from "../_terms_tr";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { BackCircleIcon } from "@/assets/icons/back-circle-icon";

export const TermsCard: FC<{ removeBackdrop?: boolean }> = ({
  removeBackdrop = false,
}) => {
  const language = useConfigStore((c) => c.language);
  const navigate = useNavigate();

  return (
    <Card p={3}>
      <OverlayScrollbarsComponent>
        <Box
          border="1px solid"
          borderColor="gray.smooth"
          w="full"
          sx={
            removeBackdrop
              ? undefined
              : {
                  "&::after": {
                    content: '""',
                    position: "absolute",
                    left: 0,
                    bottom: 0,
                    width: "full",
                    height: "70px",

                    backgroundImage:
                      "linear-gradient(to top, white, rgba(255, 255, 255, 0.3))",
                    backdropFilter: "blur(0.1px)",
                  },
                }
          }
        >
          <CardHeader
            fontWeight="700"
            fontSize="24px"
            display="flex"
            flexDir="row"
            alignItems="center"
          >
            <IconButton
              cursor="pointer"
              size="none"
              aria-label="back"
              icon={<BackCircleIcon width="40px" />}
              variant="unstyled"
              onClick={() => navigate("/")}
            />
            <Box w="full" textAlign="center">
              {language === "en" ? "Terms & Conditions" : "Sözleşme"}
            </Box>
          </CardHeader>
          <CardBody>
            {language === "en" ? (
              <TermAndConditionEn />
            ) : (
              <TermAndConditionTr />
            )}
          </CardBody>
        </Box>
      </OverlayScrollbarsComponent>
    </Card>
  );
};
