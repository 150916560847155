import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "@/i18n";
import { useFormStore } from "../form-store";
import { StandartFile } from "../common";
import { APISchemas, PATCH, POST } from "@/api";
import { Box, useToast } from "@chakra-ui/react";
import { InstagramFormPresent } from "../forms-presents";
import { handleError } from "./_handle-errors";
import isEqual from "lodash.isequal";
import { formUpdateMS } from "../contant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18n } from "@/i18n";
import {
  hasMedia,
  checkImageSize,
  checkInstagramVideoSize,
  checkCaruselReelsVideoDuration,
  checkGeneralImageFormat,
  checkContentMention,
  checkContentHashtag,
  checkMPEGVideoFormat,
  checkMaximumMedia,
  checkInstagramVideoAspectRatio,
  checkVideoFrameRate,
} from "../../Post/platform-validations";

export type instagramSchema = z.infer<typeof instagramSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const instagramSchema = z
  .object({
    id: z.number().optional(),
    content: z
      .string()
      .max(2220, i18n.t("post-validations.instagram.character-limit"))
      .optional(),
    files: StandartFile.array(),
  })
  .refine((o) => checkContentHashtag(o, 30), {
    path: ["root"],
    message: i18n.t("post-validations.instagram.hashtag-limit"),
  })
  .refine((o) => checkContentMention(o, 20), {
    path: ["root"],
    message: i18n.t("post-validations.instagram.mentions-limit"),
  })
  .refine((o) => checkMaximumMedia(o, 10), {
    path: ["root"],
    message: i18n.t("post-validations.instagram.max-media"),
  })
  .refine(hasMedia, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.has-media"),
  })
  .refine(checkCaruselReelsVideoDuration, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-carusel-duration"),
  })
  .refine(checkMPEGVideoFormat, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-format"),
  })
  .refine(checkGeneralImageFormat, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.image-format"),
  })

  .refine((o) => checkImageSize(o, 8 * 1024 * 1024), {
    path: ["root"],
    message: i18n.t("post-validations.instagram.image-size"),
  })
  .refine(checkCaruselReelsVideoDuration, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-carusel-duration"),
  })
  .refine(checkInstagramVideoSize, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-size"),
  })
  .refine(checkInstagramVideoAspectRatio, {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-aspectRatio"),
  })
  .refine((o) => checkVideoFrameRate(o, 23, 60), {
    path: ["root"],
    message: i18n.t("post-validations.instagram.video-frameRate"),
  });

export const InstagramFormContainer: FC<{
  channel: APISchemas["Channel"] & { hasError?: boolean };
  defaultValue?: instagramSchema;
}> = ({ channel, defaultValue }) => {
  const defaultValueRef = useRef<typeof defaultValue>();
  const toast = useToast();
  const formSync = useFormStore((f) => f.formSync);
  const activeOrganization = useAuthStore((f) => f.activeOrganization);
  const [formState, setFormState] = useFormStore((f) => [
    f.fromStates[channel.id.toString()] ?? {
      sync: true,
    },
    f.setFormState,
  ]);

  const {
    main,
    scheduledAt,
    addInstagramPost,
    setForm,
    subscribe,
    unsubscribe,
    blockProgress,
    goToStep,
    setPlatformHasError,
    setGlobalError,
  } = useFormStore((s) => ({
    main: s.mainForms.main,
    setForm: s.setForm,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    addInstagramPost: s.addInstagramPost,
    scheduledAt: s.scheduledAt,
    blockProgress: s.setProgressBlock,
    goToStep: s.goToStep,
    setPlatformHasError: s.setPlatformHasError,
    setGlobalError: s.setGlobalError,
  }));

  const methods = useForm<instagramSchema>({
    resolver: zodResolver(instagramSchema),
  });

  const {
    getValues,
    trigger,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset(defaultValue);
    setFormState(channel.id.toString(), {
      sync: false,
    });
    defaultValueRef.current = defaultValue;
  }

  const draftForm = useCallback(async () => {
    const result = await trigger();

    if (!result) {
      setPlatformHasError(channel, true);

      return;
    }

    setPlatformHasError(channel, false);
    setForm(channel.id, getValues());
  }, [channel, getValues, setForm, setPlatformHasError, trigger]);

  const onSubmit: (
    status: APISchemas["StatusCf9Enum"]
  ) => SubmitHandler<instagramSchema> = useCallback(
    (status) => async (data) => {
      if (!activeOrganization) {
        return;
      }
      const coverUrls: Record<string, string> = {};
      data.files?.forEach((file) => {
        if (!file.data.video) {
          return;
        }
        if (!("coverUrl" in file.data.video)) {
          return;
        }
        if (
          !file.data.video.coverUrl ||
          typeof file.data.video.coverUrl !== "string"
        ) {
          return;
        }
        coverUrls[file.data.video.id] = file.data.video.coverUrl;
      });
      const body: APISchemas["InstagramPostCreateRequest"] = {
        body: data.content,
        channel: channel.id,
        status,
        scheduled_at: scheduledAt.toISOString(),
        metadata: {
          cover_url: coverUrls,
        },

        media:
          data.files
            ?.map((f) => f.data.image?.id || f.data.video?.id)
            .filter((m): m is string => !!m)
            .map((m) => Number(m)) ?? [],
      };
      if (!activeOrganization) return;
      const { data: post, error } = defaultValueRef.current?.id
        ? await PATCH("/instagram/{org_pk}/post/{id}/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
                id: defaultValueRef.current.id,
              },
            },
            body,
          })
        : await POST("/instagram/{org_pk}/post/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          });

      if (error) {
        blockProgress(true);
        handleError(channel.id.toString(), error, setGlobalError);
        setPlatformHasError(channel, true);
        goToStep?.(1);
        return;
      }

      if (!post) {
        toast({
          status: "error",
          title: i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }

      setPlatformHasError(channel, false);
      addInstagramPost(post);
    },
    [
      activeOrganization,
      channel,
      scheduledAt,
      setPlatformHasError,
      addInstagramPost,
      blockProgress,
      setGlobalError,
      goToStep,
      toast,
    ]
  );

  useEffect(() => {
    if (main && formState.sync) {
      reset({
        content: main.content,
        files: main.files,
      });
    }
  }, [formState.sync, main, reset]);

  useEffect(() => {
    if (isDirty) {
      setFormState(channel.id.toString(), {
        sync: false,
      });
    }
  }, [channel.id, isDirty, setFormState]);

  useEffect(() => {
    const submitFn = handleSubmit(onSubmit("planned"));
    const draftFn = handleSubmit(onSubmit("draft"));
    subscribe("submit", submitFn);
    subscribe("check", draftForm);
    subscribe("draft", draftFn);

    return () => {
      unsubscribe("submit", submitFn);
      unsubscribe("check", draftForm);
      unsubscribe("draft", draftFn);
    };
  }, [draftForm, handleSubmit, onSubmit, subscribe, unsubscribe]);

  useEffect(() => {
    if (!formSync) {
      return;
    }
    let prev: instagramSchema = { files: [] };

    const interval = setInterval(() => {
      const values = getValues();

      if (isEqual(prev, values)) {
        return;
      }

      if (channel.hasError) {
        draftForm();
      }

      setForm(channel.id, values);
      prev = values;
    }, formUpdateMS);
    return () => {
      clearInterval(interval);
    };
  }, [channel, draftForm, formSync, getValues, setForm]);

  return (
    <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
      <form>
        <FormProvider {...methods}>
          <InstagramFormPresent channel={channel} />
        </FormProvider>
      </form>
    </Box>
  );
};
