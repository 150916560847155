import React from "react";

export const MediaIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 71 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g filter="url(#filter0_d_5969_10751)">
        <rect x="7" y="7" width="57" height="57" rx="28.5" fill="#F7F8FA" />
        <rect x="7.5" y="7.5" width="56" height="56" rx="28" stroke="#EBEDF2" />
      </g>
      <path
        d="M44.8246 25.4414L44.8246 25.4414C44.8069 25.0465 45.1812 24.6931 45.5786 24.6931C45.996 24.6931 46.3139 25.0344 46.3327 25.4526L46.3327 25.4526C46.3504 25.8475 45.976 26.2009 45.5786 26.2009C45.1612 26.2009 44.8433 25.8596 44.8246 25.4414Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M41.3065 25.4414L41.3065 25.4414C41.2888 25.0465 41.6632 24.6931 42.0606 24.6931C42.478 24.6931 42.7959 25.0344 42.8146 25.4526L42.8146 25.4526C42.8323 25.8475 42.458 26.2009 42.0606 26.2009C41.6431 26.2009 41.3253 25.8596 41.3065 25.4414Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M37.7887 25.4414L37.7887 25.4414C37.771 25.0465 38.1453 24.6931 38.5427 24.6931C38.9601 24.6931 39.278 25.0344 39.2968 25.4526L39.2968 25.4526C39.3145 25.8475 38.9401 26.2009 38.5427 26.2009C38.1253 26.2009 37.8074 25.8596 37.7887 25.4414Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M26.2427 33.2493H26.1177L26.1177 33.3743L26.1177 45.7366L26.1177 45.8616H26.2427H30.8853H30.9575L30.9935 45.7991L34.3249 40.0309L34.3249 40.0309C34.4595 39.7977 34.7084 39.654 34.9777 39.654C35.247 39.654 35.4959 39.7977 35.6306 40.0309L36.5485 41.6202L36.6567 41.8077L36.765 41.6202L39.0405 37.6802L39.0405 37.6802C39.1751 37.447 39.424 37.3034 39.6933 37.3034C39.9626 37.3034 40.2114 37.447 40.3461 37.6802L43.6488 43.3988L43.8821 43.8027V43.3363V33.3743V33.2493H43.7571H26.2427ZM38.9619 45.7991L39.1952 46.203V45.8616H43.1134H43.3299L43.2216 45.6741L39.8015 39.7521L39.6932 39.5647L39.585 39.7521L37.5633 43.2526L37.5272 43.3151L37.5633 43.3776L38.9619 45.7991ZM32.8069 45.6741L32.6986 45.8616H32.9152H37.0402H37.2568L37.1485 45.6741L35.0859 42.1028L34.9777 41.9154L34.8694 42.1028L32.8069 45.6741ZM25.3638 31.7415H44.636C45.0523 31.7415 45.3899 32.079 45.3899 32.4954V46.6155C45.3899 47.0319 45.0523 47.3694 44.636 47.3694H25.3638C24.9474 47.3694 24.6099 47.0319 24.6099 46.6155V32.4954C24.6099 32.079 24.9474 31.7415 25.3638 31.7415Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M30.5747 35.1541C31.9596 35.1541 33.0864 36.2809 33.0864 37.6658C33.0864 39.0506 31.9596 40.1775 30.5747 40.1775C29.1899 40.1775 28.063 39.0506 28.063 37.6658C28.063 36.2809 29.1899 35.1541 30.5747 35.1541ZM30.5747 36.6619C30.021 36.6619 29.5708 37.1121 29.5708 37.6658C29.5708 38.2194 30.021 38.6697 30.5747 38.6697C31.1284 38.6697 31.5786 38.2194 31.5786 37.6658C31.5786 37.1121 31.1284 36.6619 30.5747 36.6619Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M48.3672 22.7598V22.6348H48.2422H21.7578H21.6328V22.7598V28.1219V28.2469H21.7578H48.2422H48.3672V28.1219V22.7598ZM21.6328 49.2316V49.3566H21.7578H48.2422H48.3672V49.2316V29.8922V29.7672H48.2422H21.7578H21.6328V29.8922V49.2316ZM21.4648 21.127H48.5352C49.2739 21.127 49.875 21.7281 49.875 22.4668V49.5247C49.875 50.2634 49.2739 50.8645 48.5352 50.8645H21.4648C20.7262 50.8645 20.125 50.2634 20.125 49.5247V22.4668C20.125 21.7281 20.7262 21.127 21.4648 21.127Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <path
        d="M31.4037 26.2009H24.3042C23.8878 26.2009 23.5503 25.8634 23.5503 25.447C23.5503 25.0307 23.8878 24.6931 24.3042 24.6931H31.4037C31.8201 24.6931 32.1576 25.0307 32.1576 25.447C32.1576 25.8634 31.8201 26.2009 31.4037 26.2009Z"
        fill="#33373D"
        stroke="#CDCED0"
        strokeWidth="0.25"
      />
      <defs>
        <filter
          id="filter0_d_5969_10751"
          x="0"
          y="0"
          width="71"
          height="71"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="2"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_5969_10751"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.203922 0 0 0 0 0.352941 0 0 0 0 0.47451 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5969_10751"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5969_10751"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
