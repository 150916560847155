import {
  Card,
  CardBody,
  Flex,
  Text,
  Divider,
  Box,
  Stack,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import { differenceInDays, differenceInHours, isBefore } from "date-fns";
import { useTranslation } from "react-i18next";
import { FC } from "react";
import { i18nformat } from "@/utils/misc";

interface CurrentPackageInfoProps {
  currentPackage?: string;
  estimatedValue?: number;
  start_date?: Date;
  end_date?: Date;
  currency?: string;
  planPeriod?: string;
}

export const CurrentPackageInfo: FC<CurrentPackageInfoProps> = ({
  start_date = new Date(),
  currentPackage,
  estimatedValue,
  end_date,
  currency,
  planPeriod,
}) => {
  const { t } = useTranslation();
  const currentDate = Date.now();
  const daysPassed = differenceInDays(currentDate, start_date);
  const totalDays =
    end_date && Math.ceil(differenceInHours(end_date, start_date) / 24);

  const isEndDateBeforeCurrentDate =
    end_date && isBefore(end_date, currentDate);
  const formattedEndDate = end_date && i18nformat(end_date, "dd MMMM yyyy");

  return (
    <Card
      border-radius="6px"
      border="1px solid"
      borderColor="gray.athens"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
      h="full"
      overflow="hidden"
      w="full"
      bgColor="white.normal"
    >
      <CardBody>
        <Flex w="full" justifyContent="center">
          <CircularProgress
            value={
              isEndDateBeforeCurrentDate
                ? 100
                : (daysPassed * 100) / (totalDays ?? 0)
            }
            thickness="6px"
            size="36"
            color={isEndDateBeforeCurrentDate ? "red" : "blue.shiny"}
            trackColor="#EDF2F6"
            capIsRound
            isIndeterminate={!end_date}
          >
            <CircularProgressLabel fontWeight="bold">
              <Text
                color="black.active"
                fontWeight="600"
                letterSpacing="4.68px"
                fontSize="18px"
              >
                {!end_date
                  ? "∞"
                  : isEndDateBeforeCurrentDate
                    ? `${totalDays} /${totalDays}`
                    : `${daysPassed}/${totalDays}`}
              </Text>
              <Text
                color="black.active"
                fontSize="12px"
                fontWeight="600"
                letterSpacing="1.68px"
              >
                {!end_date
                  ? null
                  : isEndDateBeforeCurrentDate
                    ? t("no-days-left")
                    : t("days-used")}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
        </Flex>

        <Stack mt="3" spacing="3">
          <Divider />
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="16px" color="black.active">
              {t("current_plan")}
            </Text>
            <Text fontWeight="700" fontSize="20px" color="blue.hashtag">
              {currentPackage}
            </Text>
          </Flex>
          <Divider />
          {end_date && (
            <>
              <Flex justifyContent="space-between" alignItems="center">
                <Text fontSize="16px" color="black.active">
                  {t("end_date")}
                </Text>
                <Text fontWeight="700" fontSize="18px" color="black.active">
                  {formattedEndDate}
                </Text>
              </Flex>
              <Divider />
            </>
          )}

          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="16px" color="black.active">
              {t("estimated_value")}
            </Text>
            <Box textAlign="center">
              <Text fontWeight="700" fontSize="18px" color="black.active">
                {estimatedValue} {currency}
              </Text>
              <Text fontWeight="600" fontSize="15px" color="black.active">
                {t(planPeriod)}
              </Text>
            </Box>
          </Flex>
        </Stack>
      </CardBody>
      <Divider />
      {/* <CardFooter justifyContent="center">
        <Button
          borderRadius="3px"
          variant="unstyled"
          bg="blue.shiny"
          color="white"
          w="90%"
          h="40px"
          fontWeight="600"
          fontSize="20px"
          lineHeight="20px"
          _hover={{
            opacity: "0.8",
          }}
          onClick={() => navigate("/membership/upgrade")}
        >
          {t("button.upgrade-plan")}
        </Button>
      </CardFooter> */}
    </Card>
  );
};
