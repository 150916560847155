import React, { useState, FC } from "react";
import {
  Box,
  Button,
  Divider,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Text,
  BoxProps,
} from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";

interface CustomDropdownProps extends BoxProps {
  activeItem?: string;
  info?: string;
  placeholder?: string;
  dropdownList?: string[];
  setActiveItem?: (item: string) => void;
}

export const CustomDropdown: FC<CustomDropdownProps> = ({
  activeItem,
  info,
  placeholder,
  dropdownList,
  setActiveItem,
  ...props
}) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  return (
    <Popover isOpen={popoverOpen} onClose={() => setPopoverOpen(false)}>
      <PopoverTrigger>
        <Button
          px="2"
          width="185px"
          h="25px"
          bg="white"
          border="1px solid #353B48"
          borderRadius="6px"
          onClick={() => setPopoverOpen(!popoverOpen)}
          {...props}
        >
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Text
              as="span"
              textOverflow="ellipsis"
              overflow="hidden"
              fontSize="14px"
              lineHeight="24px"
              fontWeight="400"
              color="black.active"
            >
              {info ? `${info}:` : null}
              <Text
                ml="2"
                as="span"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize="14px"
                lineHeight="24px"
                fontWeight="600"
                color="black.active"
              >
                {activeItem || placeholder}
              </Text>
            </Text>

            {popoverOpen ? (
              <Box>
                <ChevronUpIcon color="blue.main" />
              </Box>
            ) : (
              <Box>
                <ChevronDownIcon color="blue.main" />
              </Box>
            )}
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        bg="white"
        borderRadius="6px"
        border="1px solid #3B5998"
        p="0px"
        width={props.width || "185px"}
        zIndex="99"
      >
        <PopoverBody p="0px">
          {dropdownList?.map((item, index) => (
            <React.Fragment key={item}>
              <Button
                px="2"
                _hover={{ bg: "gray.soft" }}
                variant="unstyled"
                textOverflow="ellipsis"
                overflow="hidden"
                fontSize="14px"
                w="full"
                textAlign="left"
                color="blue.main"
                fontWeight="600"
                onClick={() => {
                  setActiveItem(item);
                  setPopoverOpen(false);
                }}
              >
                {item}
              </Button>
              {index !== dropdownList.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
