import React, { FC, useCallback, useState, useRef, useEffect } from "react";
import { Text, Flex, Box, Button, useToast } from "@chakra-ui/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { NotificationItem } from "./notification-item";
import { APISchemas, DELETE, GET, PATCH } from "@/api";
import { isToday, startOfDay } from "date-fns";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { PostModal } from "@/pages/dashboard/Homepage/post-modal";

interface NotificationContainerProps {
  Notifications?: APISchemas["Notification"][];
}

export const NotificationContainer: FC<NotificationContainerProps> = ({
  Notifications,
}) => {
  const [activeOrganization] = useAuthStore((s) => [s.activeOrganization]);
  const [modalItem, setModalItem] = useState<APISchemas["Compose"]>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation();
  const notificationViewedArray = useRef<APISchemas["Notification"][]>([]);
  const [viewNotification, setViewNotification] = useState<number[]>([]);

  const patchNotificationRead = async () => {
    for (const notification of notificationViewedArray.current) {
      await PATCH("/notifications/{id}/", {
        params: {
          path: {
            id: notification.id,
          },
        },
        body: {
          state: {
            view: true,
            priority: 5,
          },
        },
      });

      notificationViewedArray.current = [];
    }
  };

  const onNotificationEnter = (notification: APISchemas["Notification"]) => {
    if (
      notificationViewedArray.current &&
      !notificationViewedArray.current.some(
        (item) => item.id === notification.id
      )
    ) {
      notificationViewedArray.current.push(notification);
      if (notificationViewedArray.current.length >= 1) {
        setViewNotification((state) => {
          state.push(...notificationViewedArray.current.map((n) => n.id));
          return state;
        });
        patchNotificationRead();
      }
      return;
    }
  };
  const groupItemsByDate = () => {
    const groupedData: { [key: number]: APISchemas["Notification"][] } = {};

    Notifications?.forEach((item) => {
      const createdAtDate = new Date(item.created_at);

      const key = startOfDay(createdAtDate).getTime();

      if (!groupedData[key]) {
        groupedData[key] = [];
      }

      groupedData[key].push(item);
    });
    return groupedData;
  };

  const formattedNofitications = groupItemsByDate();

  const handleDelete = useCallback(
    async (id: number) => {
      if (!activeOrganization) {
        return;
      }
      const { data, error } = await DELETE("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
            id: id,
          },
        },
      });
      if (error?.issues) {
        error.issues.map((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }
      toast({
        title: t("alert.success.post-deleted-successfully"),
        status: "success",
      });

      return data;
    },
    [activeOrganization, toast, t]
  );
  const findClickedCompose = useCallback(
    async (id: string) => {
      if (!activeOrganization) return;

      const { data } = await GET("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
            id: Number(id),
          },
        },
      });
      setModalItem(data);
    },
    [activeOrganization]
  );
  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: {
          autoHide: "scroll",
        },
      }}
    >
      <Flex flexDirection="row" minH="full" w="full">
        <Box minH="max-content" w="30px">
          <Box bg="blue.shiny" w="2px" h="full" ml="14px" />
        </Box>
        <Box height="full" w="full">
          <>
            <Flex flexDirection="column" w="full" height="full" gap="12px">
              {Object.entries(formattedNofitications).map(([date, data]) => {
                return (
                  <React.Fragment key={date}>
                    <Flex position="relative">
                      <Box
                        bg="white"
                        border="2px solid #57AAEC"
                        w="8px"
                        h="8px"
                        rounded="full"
                        pos="absolute"
                        left="-18px"
                      />
                      <Text
                        zIndex="99"
                        pos="relative"
                        bottom="6px"
                        color="black.active"
                        fontSize="16px"
                        fontWeight="500"
                        mb="0px"
                      >
                        {isToday(Number(date))
                          ? t("today_b")
                          : i18nformat(Number(date), "MMM dd,yyyy")}
                      </Text>
                    </Flex>

                    {data?.map((notification) => (
                      <Button
                        key={notification.id}
                        cursor="pointer"
                        size="none"
                        variant="unstyled"
                        borderRadius="3px"
                        onMouseEnter={() => {
                          onNotificationEnter(notification);
                        }}
                        mx="4"
                        _hover={{
                          height: "100px",
                        }}
                        bgColor={
                          !!viewNotification.find(
                            (n) => n === notification.id
                          ) || notification.state.view
                            ? "green.label"
                            : "yellow.label"
                        }
                        onClick={() => {
                          if (notification?.group_id) {
                            findClickedCompose(notification?.group_id);
                          }

                          setModalOpen(true);
                        }}
                      >
                        <NotificationItem data={notification} />
                      </Button>
                    ))}
                    <Box mb="3" />
                  </React.Fragment>
                );
              })}
            </Flex>
            {!!modalItem && (
              <PostModal
                onDelete={(id) => handleDelete(id)}
                data={modalItem}
                isOpen={modalOpen}
                close={() => setModalOpen(false)}
              />
            )}
          </>
        </Box>
      </Flex>
    </OverlayScrollbarsComponent>
  );
};
