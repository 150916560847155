import React, { useMemo } from "react";
import {
  Flex,
  Box,
  Text,
  Image,
  Divider,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { InstagramColorfulIcon } from "@/assets/brands";
import { format, parseISO } from "date-fns";
import { APISchemas } from "@/api";
import { ComposeCircleIcon } from "@/assets/brands/circle/compose-circle-icon";
import { useCallback } from "react";
import { YoutubeCircleIcon } from "@/assets/brands/circle/youtube-circle";
import { LinkedinCircleIcon } from "@/assets/brands/circle/linkedin-circle";
import { FacebookCircleIcon } from "@/assets/brands/circle/facebook-circle";
import { XCircleIcon } from "@/assets/brands/circle/X-circle";
import { useChannel } from "@/hooks/use-channels";
interface NotificationItemProps {
  notification: APISchemas["Notification"];
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  notification,
}) => {
  const hour = notification.created_at
    ? format(parseISO(notification.created_at), "HH:mm")
    : undefined;
  const channels = useChannel();
  const parseChannelName = useCallback((model_type: string | null) => {
    if (!model_type) {
      return;
    }

    if (model_type.includes("instagram")) {
      return "instagram";
    }

    if (model_type.includes("twitter")) {
      return "twitter";
    }

    if (model_type.includes("facebook")) {
      return "facebook";
    }

    if (model_type.includes("linkedin")) {
      return "linkedin";
    }
    if (model_type.includes("youtube")) {
      return "youtube";
    }
  }, []);
  const channel_type = parseChannelName(notification.model_type);

  const channel = channels?.data?.find(
    (channel: { id: number }) =>
      channel.id === notification?.given_object?.channel
  );

  const notificationImage = useMemo(() => {
    const media = Array.isArray(notification?.given_object?.media)
      ? notification?.given_object?.media
      : undefined;

    if (!media) {
      return;
    }

    const firstMedia = media.at(0);

    if (firstMedia?.type === "image") {
      return firstMedia.url;
    }

    return firstMedia?.download_url?.endsWith("original")
      ? firstMedia?.download_url?.replace("original", "preview.webp")
      : undefined;
  }, [notification]);

  return (
    <>
      <Flex py="2">
        <Grid w="full" templateColumns="repeat(8, 1fr)">
          <GridItem colSpan={1} h="55px" w="full">
            <Flex w="full" h="full" alignItems="center" justifyContent="center">
              {channel_type === "instagram" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  height="full"
                  flexDir="column"
                >
                  <InstagramColorfulIcon width="24px" height="24px" />
                  <Text
                    fontSize="11px"
                    color="black.active"
                    lineHeight="15px"
                    position="relative"
                    top="3px"
                    fontWeight="500"
                  >
                    {hour}
                  </Text>
                </Flex>
              )}
              {channel_type === "linkedin" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  height="full"
                  flexDir="column"
                >
                  <LinkedinCircleIcon width="24px" height="24px" />
                  <Text
                    fontSize="11px"
                    color="black.active"
                    lineHeight="15px"
                    position="relative"
                    top="3px"
                    fontWeight="500"
                  >
                    {hour}
                  </Text>
                </Flex>
              )}
              {channel_type === "twitter" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  height="full"
                  flexDir="column"
                >
                  <XCircleIcon width="24px" height="24px" />
                  <Text
                    fontSize="11px"
                    color="black.active"
                    lineHeight="15px"
                    position="relative"
                    top="3px"
                    fontWeight="500"
                  >
                    {hour}
                  </Text>
                </Flex>
              )}

              {channel_type === "facebook" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  height="full"
                  flexDir="column"
                >
                  <FacebookCircleIcon width="24px" height="24px" />
                  <Text
                    fontSize="11px"
                    color="black.active"
                    lineHeight="15px"
                    position="relative"
                    top="3px"
                    fontWeight="500"
                  >
                    {hour}
                  </Text>
                </Flex>
              )}
              {channel_type === "youtube" && (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  w="full"
                  height="full"
                  flexDir="column"
                >
                  <YoutubeCircleIcon width="24px" height="24px" />
                  <Text
                    fontSize="11px"
                    color="black.active"
                    lineHeight="15px"
                    position="relative"
                    top="3px"
                    fontWeight="500"
                  >
                    {hour}
                  </Text>
                </Flex>
              )}
              {channel_type !== "instagram" &&
                channel_type !== "twitter" &&
                channel_type !== "facebook" &&
                channel_type !== "youtube" &&
                channel_type !== "linkedin" && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    w="full"
                    height="full"
                    flexDir="column"
                  >
                    <ComposeCircleIcon width="24px" height="24px" />
                    <Text
                      fontSize="11px"
                      color="black.active"
                      lineHeight="15px"
                      position="relative"
                      top="3px"
                      fontWeight="500"
                    >
                      {hour}
                    </Text>
                  </Flex>
                )}
            </Flex>
          </GridItem>
          <GridItem colSpan={7} h="55px" w="full">
            <Flex h="full">
              <Box
                h="full"
                w="full"
                position="relative"
                justifyContent="center"
                display="flex"
                flexDirection="column"
              >
                <Text
                  color="gray.passive"
                  fontSize="10px"
                  fontWeight="400"
                  noOfLines={2}
                  textOverflow="ellipsis"
                  position="absolute"
                  top="0px"
                >
                  {channel
                    ? channel?.username
                      ? channel.username.startsWith("@")
                        ? channel.username
                        : `@${channel.username}`
                      : channel?.name
                    : "@postEffect"}
                </Text>
                <Text
                  color="black.active"
                  fontSize="12px"
                  fontWeight="600"
                  noOfLines={1}
                  textOverflow="ellipsis"
                  mb="6px"
                >
                  {notification.message}
                </Text>
              </Box>
              {notificationImage && (
                <Box
                  display="flex"
                  h="full"
                  alignItems="center"
                  justifyContent="center"
                  mx="3"
                >
                  <Image
                    w="48px"
                    h="48px"
                    borderRadius="3px"
                    objectFit="cover"
                    src={notificationImage}
                  />
                </Box>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      <Divider borderStyle="dashed" borderColor="gray.light" />
    </>
  );
};
