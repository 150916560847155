import { CustomDropdown } from "@/components/dropdown";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { AnalyticsChart } from "./analytics-chart";

export const AnalyticsChartSection = () => {
  const [analyzeDropdown, setAnalyzeDropdown] = useState<string>(
    analyzeList[0]
  );
  const [periodDropdown, setPeriodDropdown] = useState<string>(periodList[0]);
  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.analytics-chart")}{" "}
          </Text>
        </Box>
        <Flex>
          <CustomDropdown
            info={"Analyze"}
            activeItem={analyzeDropdown}
            dropdownList={analyzeList}
            setActiveItem={(item) => setAnalyzeDropdown(item)}
          />
          <CustomDropdown
            info={"Period"}
            activeItem={periodDropdown}
            dropdownList={periodList}
            setActiveItem={(item) => setPeriodDropdown(item)}
            ml="10px"
            width="170px"
          />
        </Flex>
      </Flex>
      <Box w="full" height="full" py="6">
        <AnalyticsChart data={data} />
      </Box>
    </Box>
  );
};

const analyzeList = [
  "Interaction",
  "Activity",
  "Engagement",
  "Impressions",
  "Reach",
  "Audience",
];
const periodList = ["This Week", "This Month", "This Year"];

const data = [
  {
    name: "19 Feb",
    data: 4000,
  },
  {
    name: "20 Feb",
    data: 3000,
  },
  {
    name: "21 Feb",
    data: 2000,
  },
  {
    name: "22 Feb",
    data: 2780,
  },
  {
    name: "23 Feb",
    data: 1890,
  },
  {
    name: "24 Feb",
    data: 2390,
  },
  {
    name: "25 Feb",
    data: 3490,
  },
  {
    name: "26 Feb",
    data: 2390,
  },
  {
    name: "27 Feb",
    data: 3490,
  },
  {
    name: "28 Feb",
    data: 3490,
  },
];
