import React from "react";
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface CustomAlertDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onAction: () => void;
  title: string;
  description: string;
}

const CustomAlertDialog: React.FC<CustomAlertDialogProps> = ({
  isOpen,
  onClose,
  onCancel,
  onAction,
  title,
  description,
}) => {
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <AlertDialog
      leastDestructiveRef={cancelRef}
      onClose={onClose}
      isOpen={isOpen}
      isCentered
    >
      <AlertDialogOverlay>
        <AlertDialogContent bg="white.bg">
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{description} </AlertDialogBody>

          <AlertDialogFooter>
            <Button onClick={onCancel}>{t("cancel")}</Button>
            <Button
              colorScheme="red"
              onClick={() => {
                onAction();
                onClose();
              }}
              ml={3}
            >
              {t("delete")}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

export default CustomAlertDialog;
