import React from "react";

export const DeleteDocIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="22" height="22" rx="11" stroke="#EBEDF2" />
      <path
        d="M8.55469 9.63672L9.02703 15.2746C9.06087 15.6812 9.41113 16 9.8239 16H14.176C14.5887 16 14.939 15.6812 14.9729 15.2746L15.4452 9.63672H8.55469ZM10.6663 14.9453C10.5268 14.9453 10.4093 14.8379 10.4005 14.6981L10.1338 10.4442C10.1247 10.2987 10.2364 10.1736 10.3833 10.1646C10.5356 10.154 10.6567 10.2658 10.6661 10.4112L10.9327 14.6652C10.9422 14.8158 10.8216 14.9453 10.6663 14.9453ZM12.2666 14.6816C12.2666 14.8274 12.1473 14.9453 11.9999 14.9453C11.8525 14.9453 11.7333 14.8274 11.7333 14.6816V10.4277C11.7333 10.282 11.8525 10.1641 11.9999 10.1641C12.1473 10.1641 12.2666 10.282 12.2666 10.4277V14.6816ZM13.8661 10.4442L13.5994 14.6981C13.5906 14.8365 13.474 14.9519 13.3166 14.9448C13.1697 14.9358 13.058 14.8106 13.0671 14.6652L13.3338 10.4113C13.3429 10.2658 13.4718 10.1599 13.6166 10.1646C13.7635 10.1736 13.8752 10.2987 13.8661 10.4442Z"
        fill="#F13232"
      />
      <path
        d="M15.4667 8.05469H13.8667V7.79102C13.8667 7.35483 13.5078 7 13.0667 7H10.9333C10.4922 7 10.1333 7.35483 10.1333 7.79102V8.05469H8.53333C8.23877 8.05469 8 8.29078 8 8.58203C8 8.87325 8.23877 9.10937 8.53333 9.10937C10.9861 9.10937 13.014 9.10937 15.4667 9.10937C15.7612 9.10937 16 8.87325 16 8.58203C16 8.29078 15.7612 8.05469 15.4667 8.05469ZM13.3333 8.05469H10.6667V7.79102C10.6667 7.64554 10.7862 7.52734 10.9333 7.52734H13.0667C13.2138 7.52734 13.3333 7.64554 13.3333 7.79102V8.05469Z"
        fill="#F13232"
      />
    </svg>
  );
};
