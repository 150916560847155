export const TelegramCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.9999 30.1842C23.2842 30.1842 29.9999 23.4685 29.9999 15.1842C29.9999 6.89993 23.2842 0.184204 14.9999 0.184204C6.71567 0.184204 -6.10352e-05 6.89993 -6.10352e-05 15.1842C-6.10352e-05 23.4685 6.71567 30.1842 14.9999 30.1842Z"
        fill="url(#paint0_linear_8171_4599)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.78979 15.0259C11.1626 13.1207 14.0785 11.8647 15.5374 11.2579C19.7031 9.52528 20.5687 9.22428 21.1328 9.21432C21.2569 9.21215 21.5344 9.24292 21.7141 9.38876C21.9807 9.60508 21.9822 10.0747 21.9526 10.3854C21.7269 12.7573 20.7501 18.5132 20.2532 21.1697C20.0429 22.2937 19.6289 22.6707 19.2281 22.7075C18.357 22.7877 17.6955 22.1318 16.8519 21.5788C15.5317 20.7134 14.7858 20.1747 13.5043 19.3302C12.0234 18.3543 12.9834 17.8179 13.8274 16.9413C14.0483 16.7119 17.8863 13.221 17.9606 12.9043C17.9699 12.8647 17.9785 12.717 17.8908 12.6391C17.8031 12.5611 17.6736 12.5877 17.5802 12.609C17.4478 12.639 15.3388 14.033 11.2532 16.7909C10.6545 17.202 10.1123 17.4023 9.62649 17.3918C9.09088 17.3802 8.06069 17.089 7.29481 16.84C6.35543 16.5347 5.60883 16.3732 5.67387 15.8546C5.70774 15.5845 6.07969 15.3082 6.78979 15.0259Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_8171_4599"
          x1="14.9999"
          y1="0.184204"
          x2="14.9999"
          y2="29.9498"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#2AABEE" />
          <stop offset="1" stopColor="#229ED9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
