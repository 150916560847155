import {
  Box,
  Flex,
  Text,
  Image,
  Grid,
  GridItem,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { TextChevronGroup } from "./components/text-chevron-group";
import { StatsPercentageGroup } from "./components/stats-percentage-group";
import { useChannel } from "@/hooks/use-channels";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import React, { FC, useEffect, useState } from "react";
import { Pagination } from "@/components/pagination";

interface PostTableProps {
  data: any[];
}

export const PostTable: FC<PostTableProps> = ({ data }) => {
  const { t } = useTranslation();
  const [maxAvatars, setMaxAvatars] = useState(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { data: Channels } = useChannel();
  const choosedChannels = Channels?.filter((channel) =>
    channel.channel_type.includes("twitter")
  );
  const avatarsToDisplay = choosedChannels?.slice(0, maxAvatars);
  const hiddenCount = Math.max((choosedChannels?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = choosedChannels?.slice(
    maxAvatars,
    choosedChannels.length
  );

  const postPerPage = 7;

  const totalPages = data ? Math.ceil(data?.length / postPerPage) : 0;
  const startIndex = (currentPage - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  const currentPagePosts = data?.slice(startIndex, endIndex);
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const resize = async () => {
      setMaxAvatars(
        Math.max(2, Math.min(5, Math.floor(window.innerWidth / 400)))
      );
    };

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <Box w="full" height="full" position="relative">
      <Grid
        templateColumns="repeat(13, 1fr)"
        w="full"
        bgColor="gray.athens"
        borderRadius="3px"
      >
        <GridItem
          px="3"
          w="100%"
          colSpan={4}
          h="30px"
          display="flex"
          alignItems="center"
        >
          <Text
            color="gray.smoke"
            fontSize="14px"
            lineHeight="14px"
            fontWeight="600"
          >
            {t("page.analytics.post")}
          </Text>
        </GridItem>

        <GridItem
          px="3"
          w="100%"
          colSpan={2}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <TextChevronGroup
            title={t("page.analytics.accounts")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.like-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.comment-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.share-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.click-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.impression-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.replies-c")}
            chevronUp={true}
          />
        </GridItem>
        <GridItem
          px="3"
          w="100%"
          colSpan={1}
          h="30px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          cursor="pointer"
        >
          <TextChevronGroup
            title={t("page.analytics.video-plays")}
            chevronUp={true}
          />
        </GridItem>
      </Grid>
      {currentPagePosts?.map((item) => (
        <>
          <Grid templateColumns="repeat(13, 1fr)" w="full" h="96px">
            <GridItem w="100%" colSpan={4} h="full" px="3">
              <Flex
                w="full"
                h="full"
                justifyContent="center"
                alignItems="center"
              >
                <Box w="70px" height="70px">
                  {item.image && (
                    <Image
                      w="full"
                      height="full"
                      src={item.image}
                      borderRadius="3px"
                    />
                  )}
                </Box>
                <Text
                  h="70px"
                  ml="3"
                  w="full"
                  color="black.active"
                  fontSize="12px"
                  lineHeight="14px"
                  fontWeight="400"
                  textOverflow="ellipsis"
                  overflow="hidden"
                >
                  {item.content}
                </Text>
              </Flex>
            </GridItem>
            <GridItem w="100%" colSpan={2} h="full">
              <Flex h="full" justifyContent="center" alignItems="center">
                {avatarsToDisplay?.map((avatar, index) => (
                  <Button
                    variant="unstyled"
                    cursor="pointer"
                    key={avatar.id}
                    zIndex={index + 1}
                    position="relative"
                    right={`${index * 10}px`}
                  >
                    <AvatarWithBadge
                      status="neutral"
                      w="36px"
                      h="36px"
                      key={avatar.id}
                      tooltip
                      addCompose={avatar.channel_type !== "facebook_account"}
                      email={
                        avatar.username
                          ? avatar.username.startsWith("@")
                            ? avatar.username
                            : `@${avatar.username}`
                          : avatar.name
                      }
                      picture={avatar.picture ?? undefined}
                      channel={avatar.channel_type}
                      channel_id={avatar.id.toString()}
                    />
                  </Button>
                ))}
                {hiddenCount > 0 && (
                  <Tooltip
                    key="tooltip"
                    shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                    borderRadius="4px"
                    bg="white"
                    color="black"
                    p="4px"
                    label={
                      <ul key="list">
                        {hiddenAvatars?.map((avatar) => (
                          <React.Fragment key={avatar.id}>
                            <Text
                              fontWeight="500"
                              key={avatar.id}
                              fontSize="12px"
                              p="6px"
                              color="black.active"
                            >
                              {avatar.username
                                ? avatar.username.startsWith("@")
                                  ? avatar.username
                                  : `@${avatar.username}`
                                : avatar.name}
                            </Text>
                          </React.Fragment>
                        ))}
                      </ul>
                    }
                  >
                    <Button
                      position="relative"
                      top="1px"
                      cursor="pointer"
                      variant="unstyled"
                      right={`${avatarsToDisplay && avatarsToDisplay?.length * 10}px`}
                      zIndex={avatarsToDisplay && avatarsToDisplay?.length * 10}
                    >
                      <Flex
                        justifyContent="center"
                        alignItems="center"
                        w="36px"
                        h="36px"
                        borderRadius="18px"
                        border="1px solid #EBEDF2
"
                        bgColor="gray.athens"
                      >
                        <Text
                          lineHeight="14px"
                          fontWeight="600"
                          fontSize="13px"
                          color="blue.shiny"
                        >
                          {"+" + hiddenCount}{" "}
                        </Text>
                      </Flex>
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </GridItem>
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.like}
                previousValue={item.previous_like}
              />
            </GridItem>
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.comment}
                previousValue={item.previous_comment}
              />
            </GridItem>
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.share}
                previousValue={item.previous_share}
              />
            </GridItem>
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.click}
                previousValue={item.previous_click}
              />
            </GridItem>
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.impression}
                previousValue={item.previous_impression}
              />
            </GridItem>
            <GridItem w="100%" h="full">
              {" "}
              <StatsPercentageGroup
                currentValue={item.replies}
                previousValue={item.previous_replies}
              />
            </GridItem>{" "}
            <GridItem w="100%" h="full">
              <StatsPercentageGroup
                currentValue={item.video_plays}
                previousValue={item.previous_video_plays}
              />
            </GridItem>
          </Grid>
          <Box w="full" h="1px" bgColor="gray.light" borderRadius="3px" />
        </>
      ))}
      {totalPages > 1 && (
        <Flex
          position="absolute"
          bottom="0"
          alignItems="center"
          w="full"
          justifyContent="center"
        >
          <Box>
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Box>
        </Flex>
      )}
    </Box>
  );
};
