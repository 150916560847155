import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Flex,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import { SessionCloseIcon } from "@/assets/icons/session-close-icon";
import { CrossCircleIcon } from "@/assets/icons/cross-circle-icon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSessionStore } from "@/context/time-store/session-store";

interface SessionModalProps {
  isOpen: boolean;
  close: () => void;
}

export const SessionModal: React.FC<SessionModalProps> = ({
  close,
  isOpen,
}) => {
  const { clickedByUser } = useSessionStore((state) => ({
    clickedByUser: state.clickedByUser,
  }));
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      isCentered
      closeOnOverlayClick={true}
      blockScrollOnMount={false}
      scrollBehavior="outside"
      size="none"
    >
      <ModalOverlay />
      <ModalContent boxShadow="md" width="75%" bgColor="white">
        <Flex justifyContent="flex-end" m="3">
          <CrossCircleIcon
            width="30px"
            height="30px"
            cursor="pointer"
            onClick={() => {
              clickedByUser();
              close();
            }}
          />
        </Flex>
        <ModalBody p="0px">
          <Flex alignItems="center" justifyContent="center" h="full">
            <Flex w="50%" h="100%" px="20">
              <SessionCloseIcon width="100%" height="100%" />
            </Flex>

            <Flex
              px="5"
              w="60%"
              h="80%"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box textAlign="center">
                <Text
                  lineHeight="52px"
                  fontSize="36px"
                  fontWeight="700"
                  color="black.active"
                >
                  {t("page.session-modal.title")}
                </Text>

                <Text
                  mt="70"
                  fontSize="24px"
                  fontWeight="500"
                  color="black.active"
                  lineHeight="34px"
                >
                  {t("page.session-modal.message")}
                </Text>
              </Box>
              <Box textAlign="center">
                <Button
                  my="36px"
                  w="full"
                  height="50px"
                  borderRadius="6px"
                  bgColor="blue.shiny"
                  color="white"
                  fontSize="30px"
                  lineHeight="30px"
                  onClick={() => {
                    navigate("/");
                    close();
                  }}
                >
                  {t("button.back-to-dashboard")}
                </Button>
              </Box>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
