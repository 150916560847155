import { FC } from "react";

export const PlusCircleIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 26 26"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.6667 8.21084V17.1172"
        stroke={props.fill || "white"}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.1199 12.6641H8.21352"
        stroke={props.fill || "white"}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="mask0_2345_13180"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="27"
        height="26"
      >
        <path
          d="M25.3335 -0.00158691H-0.00012207V25.332H25.3335V-0.00158691Z"
          fill={props.fill || "white"}
        />
      </mask>
      <g mask="url(#mask0_2345_13180)">
        <path
          d="M6.62518 22.6614C8.3878 23.7291 10.4555 24.3438 12.6667 24.3438C19.1158 24.3438 24.3439 19.1157 24.3439 12.6665C24.3439 6.21735 19.1158 0.989321 12.6667 0.989321C6.21747 0.989321 0.989443 6.21735 0.989443 12.6665C0.989443 15.0647 1.72882 17.2939 2.96863 19.1484"
          stroke={props.fill || "white"}
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
