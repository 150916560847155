import { APISchemas, DELETE, GET, POST } from "@/api";
import { StatusAcceptIcon } from "@/assets/icons/status-accept-icon";
import { StatusPendingIcon } from "@/assets/icons/status-pending-icon";
import { StatusRejectIcon } from "@/assets/icons/status-reject-icon";
import { TrashIconRed } from "@/assets/icons/trash-icon-red";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Flex,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { z } from "@/i18n";
import { DeleteAlert } from "../members";

interface InviteMemberModalProps {
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  OrganizationList: Array<APISchemas["Organization"]>;
}

const InviteMemberModal: React.FC<InviteMemberModalProps> = ({
  isOpen,
  onClose,
  onOpen,
}) => {
  const { organization } = useAuthStore((state) => ({
    organization: state.activeOrganization,
  }));
  const [email, setEmail] = useState<string>("");
  const { t } = useTranslation();
  const emailSchema = z.string().email();
  const toast = useToast();

  const { data: InviteHistory, refetch: InviteHistoryRefetch } = useQuery(
    ["inviteHistory", organization?.id],
    async () => {
      if (!organization?.id) {
        return;
      }
      const { data } = await GET("/invitation/{org_pk}/list/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
      });
      return data;
    }
  );

  const deleteHistory = useCallback(
    async (id: number) => {
      const { error, response } = await DELETE("/invitation/{id}/", {
        params: {
          path: {
            id: id,
          },
        },
      });

      if (error) {
        console.error("Delete invite error", error);
      }

      setTimeout(() => {
        InviteHistoryRefetch();
      });

      if (response.status === 200) {
        toast({
          status: "success",
          description: t("user_deleted_successfully"),
          duration: 3000,
        });
      }
      if (response.status === 400) {
        toast({
          status: "error",
          description: t("failed_to_delete_user"),
          duration: 3000,
        });
      }
      if (response.status === 403) {
        toast({
          status: "error",
          description: t("you_are_not_authorized_to_delete_users"),
          duration: 3000,
        });
      }
      if (!error) {
        toast({
          status: "success",
          description: t("user_deleted_successfully"),
          duration: 3000,
        });
      }
    },
    [InviteHistoryRefetch, toast, t]
  );

  const postMail = useCallback(
    async (email: string) => {
      if (!emailSchema.safeParse(email).success) {
        toast({
          title: t("invalid_email"),
          description: t("please_enter_a_valid_email"),
          status: "error",
          duration: 3000,
        });
        return;
      }

      if (!organization) {
        return;
      }

      const { data, error } = await POST("/invitation/{org_pk}/send/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
        body: {
          email,
        },
      });
      if (error) {
        toast({
          title: t("invitation_not_sent"),
          description: error?.detail,
          status: "error",
          duration: 3000,
        });
      } else {
        toast({
          title: t("invitation_sent"),
          description: t("invitation_sent_successfully"),
          status: "success",
          duration: 3000,
        });
        InviteHistoryRefetch();
      }

      return data;
    },
    [emailSchema, organization, toast, t, InviteHistoryRefetch]
  );

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent bgColor="white">
        <ModalHeader
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          fontWeight="semibold"
          fontSize={16}
        >
          {t("share_organization")}
          {/* <Flex w="40%" justifyContent="flex-end" h="10">
            <Popover
              isOpen={orgNamePopoverOpen}
              onClose={() => setOrgNamePopoverOpen(false)}
            >
              <PopoverTrigger>
                <Button
                  px="2"
                  width="120px"
                  bg="white"
                  borderRadius="3px"
                  onClick={() => setOrgNamePopoverOpen(!orgNamePopoverOpen)}
                >
                  <Flex justifyContent="space-between" w="full">
                    <Text as="span" textOverflow="ellipsis" overflow="hidden">
                      {organization ? organization.name : t("organization")}
                    </Text>
                    {orgNamePopoverOpen ? (
                      <ChevronUpIcon color="blue.main" />
                    ) : (
                      <ChevronDownIcon color="blue.main" />
                    )}
                  </Flex>
                </Button>
              </PopoverTrigger>
              <PopoverContent bg="white" borderRadius="3px" w="240px">
                {OrganizationList?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Divider />
                    <PopoverBody
                      w="full"
                      sx={{ _hover: { bg: "gray.light" } }}
                      color="blue.main"
                      fontWeight="600"
                      key={index}
                      onClick={() => {
                        setActiveOrg(item);
                        setOrgNamePopoverOpen(false);
                      }}
                    >
                      {item?.name}
                    </PopoverBody>
                  </React.Fragment>
                ))}
              </PopoverContent>
            </Popover>
          </Flex> */}
        </ModalHeader>
        <ModalCloseButton onClick={onClose} />
        <Divider w="90%" ml="5" my="2" />
        <ModalBody mb="4">
          <Stack spacing="4">
            <Flex mb="4" mt="3" align="center" gap={4}>
              <Input
                placeholder={t("email_address")}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <Button
                width="85px"
                height="40px"
                fontWeight="medium"
                fontSize="16px"
                textColor="white"
                bgColor="green.label"
                onClick={() => {
                  setEmail("");
                  postMail(email);
                }}
              >
                {t("send")}
              </Button>
            </Flex>
            <OverlayScrollbarsComponent
              options={{
                overflow: {
                  x: "hidden",
                  y: "scroll",
                },
                scrollbars: {
                  autoHide: "scroll",
                },
              }}
            >
              <Box scrollBehavior="smooth" height="250px">
                {Array.isArray(InviteHistory) &&
                  InviteHistory?.map((invitation, index) => (
                    <Box key={invitation.id}>
                      {index > 0 && (
                        <Box border="dashed 1px #F2F2F2" mb={3} mt={3} />
                      )}
                      <Flex key={invitation.id} align="center">
                        <Flex width="50%" alignItems="center">
                          <Avatar
                            w="35px"
                            h="35px"
                            name={invitation.name ?? undefined}
                            src={invitation.picture ?? undefined}
                          />
                          <Box ml={3}>
                            <Text fontWeight="bold">{invitation.name}</Text>
                            <Text
                              color="black.active"
                              fontSize="sm"
                              fontWeight="medium"
                            >
                              {invitation.email}
                            </Text>
                          </Box>
                        </Flex>
                        <Flex
                          width="50%"
                          gap="20"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          <Flex>
                            <StatusIconText status={invitation.status} />
                          </Flex>

                          <DeleteAlert
                            channel={invitation.email}
                            onDelete={() => {
                              if (organization?.id) {
                                deleteHistory(invitation.id);
                                return;
                              }
                              toast({
                                status: "error",
                                description: t("user-could-not-be-deleted"),
                                duration: 3000,
                              });
                            }}
                            trigger={(onOpen) => (
                              <Button
                                size="none"
                                cursor="pointer"
                                variant="unstyled"
                                onClick={onOpen}
                              >
                                <TrashIconRed />
                              </Button>
                            )}
                          />
                        </Flex>
                      </Flex>
                    </Box>
                  ))}
              </Box>
            </OverlayScrollbarsComponent>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

interface StatusProps {
  status: string;
}

const StatusIconText: React.FC<StatusProps> = ({ status }) => {
  let icon;
  let color;
  let text;
  const { t } = useTranslation();
  switch (status) {
    case "pending":
      icon = <StatusPendingIcon />;
      color = "yellow.500";
      text = t("pending");
      break;
    case "accepted":
      icon = <StatusAcceptIcon />;
      color = "green.500";
      text = t("accepted");
      break;
    case "rejected":
      icon = <StatusRejectIcon />;
      color = "red.500";
      text = t("rejected");
      break;
    default:
      icon = null;
      text = "";
      color = "";
  }

  return (
    <Flex w="100px" justifyContent="flex-end" alignItems="flex-start">
      <Box>
        {icon && (
          <Icon w="6" h="6">
            {icon}
          </Icon>
        )}
      </Box>
      <Box>
        <Text whiteSpace="nowrap" fontWeight="semibold" color={color}>
          {text}
        </Text>
      </Box>
    </Flex>
  );
};

export default InviteMemberModal;
