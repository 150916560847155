import React, { FC } from "react";

export const DashboardIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.13709 0H1.16104C0.520809 0 0 0.523311 0 1.16662V4.16659C0 4.81002 0.520809 5.33333 1.16104 5.33333H6.13709C6.77744 5.33333 7.29825 4.81002 7.29825 4.16659V1.16662C7.29825 0.523311 6.77744 0 6.13709 0Z"
        fill={props.fill || "white"}
      />
      <path
        d="M6.13709 6.73682H1.16104C0.520809 6.73682 0 7.25619 0 7.89479V14.8421C0 15.4806 0.520809 16 1.16104 16H6.13709C6.77744 16 7.29825 15.4806 7.29825 14.8421V7.89479C7.29825 7.25619 6.77744 6.73682 6.13709 6.73682Z"
        fill={props.fill || "white"}
      />
      <path
        d="M14.839 10.6667H9.86291C9.22256 10.6667 8.70176 11.19 8.70176 11.8334V14.8334C8.70176 15.4767 9.22256 16 9.86291 16H14.839C15.4792 16 16 15.4767 16 14.8334V11.8334C16 11.19 15.4792 10.6667 14.839 10.6667Z"
        fill={props.fill || "white"}
      />
      <path
        d="M14.839 0H9.86291C9.22256 0 8.70176 0.519378 8.70176 1.15785V8.10519C8.70176 8.74378 9.22256 9.26316 9.86291 9.26316H14.839C15.4792 9.26316 16 8.74378 16 8.10519V1.15785C16 0.519378 15.4792 0 14.839 0Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
