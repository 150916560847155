import React from "react";

export const PlannerIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.25989 0.640015C4.54091 0.640015 0.679932 4.501 0.679932 9.21997C0.679932 13.9389 4.54091 17.7999 9.25989 17.7999C13.9789 17.7999 17.8398 13.9389 17.8398 9.21997C17.8398 4.501 13.9789 0.640015 9.25989 0.640015ZM9.25989 16.0839C5.48471 16.0839 2.39592 12.9952 2.39592 9.21997C2.39592 5.44479 5.48471 2.35601 9.25989 2.35601C13.0351 2.35601 16.1239 5.44479 16.1239 9.21997C16.1239 12.9952 13.0351 16.0839 9.25989 16.0839Z"
        fill={props.fill || "white"}
        fillOpacity="0.9"
      />
      <path
        d="M9.68885 4.93002H8.40186V10.078L12.8634 12.8236L13.5498 11.7082L9.68885 9.3916V4.93002Z"
        fill={props.fill || "white"}
        fillOpacity="0.9"
      />
    </svg>
  );
};
