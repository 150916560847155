import countriesData from "@/data-storage/countries_cities.json";

type Country = {
  id: number;
  name: string;
  iso3: string;
  iso2: string;
  numeric_code: string;
  phone_code: string;
  capital: string;
  currency: string;
  currency_name: string;
  currency_symbol: string;
  tld: string;
  native: string;
  region: string;
  region_id: string;
  subregion: string;
  subregion_id: string;
  nationality: string;
  timezones: {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
  }[];
  translations: {
    [key: string]: string;
  };
  latitude: string;
  longitude: string;
  emoji: string;
  emojiU: string;
  cities: {
    id: number;
    name: string;
    latitude: string;
    longitude: string;
  }[];
};

export const GetCountryNames = (language: string | undefined) => {
  const countryNames = (countriesData as Country[])?.map((country) => {
    if (language === "tr") {
      return country.translations.tr;
    } else {
      return country.name;
    }
  });

  return countryNames;
};

export const GetCitiesByCountryName = (name: string, language: string) => {
  const country = (countriesData as Country[]).find((country) => {
    if (language === "tr") {
      return country.translations.tr === name;
    } else {
      return country.name === name;
    }
  });
  return country ? country.cities : [];
};
