import React from "react";

export const SearchLeftIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.5085 2.27248C9.04478 -0.19125 5.03488 -0.19125 2.57115 2.27248C0.107956 4.73674 0.107956 8.74611 2.57115 11.2104C4.76517 13.4039 8.18224 13.6389 10.6444 11.926C10.6962 12.1712 10.8147 12.4051 11.0054 12.5958L14.5934 16.1838C15.1162 16.7056 15.9612 16.7056 16.4814 16.1838C17.0037 15.6614 17.0037 14.8165 16.4814 14.2958L12.8934 10.7067C12.7038 10.5177 12.4693 10.3986 12.2242 10.3468C13.9381 7.8841 13.7031 4.46756 11.5085 2.27248ZM10.3757 10.0776C8.53633 11.917 5.54279 11.917 3.70394 10.0776C1.86563 8.2382 1.86563 5.24519 3.70394 3.40581C5.54279 1.56695 8.53633 1.56695 10.3757 3.40581C12.2151 5.24519 12.2151 8.2382 10.3757 10.0776Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
