import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Box,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { TicketInfo } from "../ticket/ticket-info";

export interface HelpCenterAccordionProps {
  children?: ReactElement;
  accordionData?: (FAQ | TicketAnswers)[];
  infoIcon?: ReactElement;
}

type FAQ = {
  title: string;
  question: string;
  answer: string;
};

type TicketAnswers = {
  id: string;
  title: string;
  message: string;
};

export const HelpCenterAccordion: React.FC<HelpCenterAccordionProps> = ({
  children,
  accordionData,
  infoIcon,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Flex w="full">
      <Accordion allowToggle w="full">
        {accordionData?.map((item, index) => (
          <AccordionItem key={index} mb="24px" border="none" bg="white.normal">
            <AccordionButton
              bg="white.normal"
              border="none"
              p="24px"
              borderRadius="3px"
            >
              <Box textAlign="left" w="full">
                <IconButton
                  variant="unstyled"
                  icon={children}
                  aria-label="icon"
                  mr="12px"
                />
                {"question" in item ? item.question : item.title}
              </Box>
              <Flex w="full" justifyContent="flex-end">
                {/* <IconButton
                  variant="unstyled"
                  icon={<FaqLikeIcon />}
                  aria-label="icon"
                />
                <IconButton
                  variant="unstyled"
                  icon={<FaqDislikeIcon />}
                  aria-label="icon"
                  ml="5px"
                /> */}

                <IconButton
                  variant="unstyled"
                  icon={infoIcon}
                  aria-label="Information"
                  onClick={onOpen}
                />
                <TicketInfo isOpen={isOpen} onClose={onClose} />
              </Flex>
            </AccordionButton>
            <AccordionPanel pb={4}>
              {"answer" in item ? item.answer : item.message}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Flex>
  );
};
