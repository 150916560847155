import React from "react";

export const ChevronLeftIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="10"
      height="23"
      viewBox="0 0 10 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.75594 0.306206C10.0813 0.71442 10.0814 1.3764 9.75589 1.78469L2.01183 11.4998L9.75594 21.2153C10.0813 21.6235 10.0814 22.2855 9.75589 22.6938C9.43044 23.1021 8.90283 23.1021 8.57739 22.6938L0.244056 12.239C0.0877782 12.0429 1.33833e-07 11.777 1.37139e-07 11.4998C1.40445e-07 11.2225 0.0878335 10.9565 0.244111 10.7605L8.57744 0.306276C8.90283 -0.102079 9.4305 -0.102079 9.75594 0.306206Z"
        fill={props.fill || "#345A79"}
      />
    </svg>
  );
};
