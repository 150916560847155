import {
  Button,
  Flex,
  Icon,
  Text,
  FormControl,
  FormLabel,
  Box,
  Input,
  Center,
  FormErrorMessage,
  useSteps,
  PinInputField,
  HStack,
  useToast,
  PinInput,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FC, FormEvent, useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { Logo } from "@/assets/logo";
import { useTranslation } from "react-i18next";
import { resetPasswordSchema, setPasswordSchema } from "..";
import { addMinutes, intervalToDuration, isAfter } from "date-fns";
import { i18n } from "@/i18n";

export const ForgotPasswordMobile: FC<{
  handleVerificationCode: (verification_code: string) => Promise<boolean>;
  onSubmitResetPassword: () => void;
  onSubmitSetPassword: () => void;
  resetPasswordForm: UseFormReturn<resetPasswordSchema>;
  setPasswordForm: UseFormReturn<setPasswordSchema>;
}> = ({
  handleVerificationCode,
  onSubmitResetPassword,
  onSubmitSetPassword,
  resetPasswordForm,
  setPasswordForm,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: 3,
  });
  const [pin, setPin] = useState<string>("");
  const toast = useToast();
  const [duration, setDuration] = useState<{
    minutes: number;
    seconds: number;
    endDate: Date;
  }>({ minutes: 3, seconds: 0, endDate: new Date() });

  useEffect(() => {
    if (activeStep !== 2) return;

    const timer = setInterval(() => {
      setDuration((duration) => {
        const difference = intervalToDuration({
          start: Date.now(),
          end: duration.endDate,
        });
        if (isAfter(Date.now(), duration.endDate)) {
          clearInterval(timer);
          return {
            ...duration,
            minutes: 0,
            seconds: 0,
          };
        }
        return {
          ...duration,
          minutes: difference.minutes ?? 0,
          seconds: difference.seconds ?? 0,
        };
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [activeStep, duration]);
  const {
    getValues,
    register: resetPasswordRegister,
    formState: { errors: resetPasswordFormErrors },
  } = resetPasswordForm;
  const {
    register,
    formState: { errors },
  } = setPasswordForm;

  const handleSendButtonClick = useCallback(
    async (pin: string) => {
      if (pin.length < 6) {
        toast({
          description: i18n.t("errors.please-write-6-digits"),
          status: "error",
          isClosable: true,
        });
        return;
      }
      const success = await handleVerificationCode(pin);
      if (!success) return;
      goToNext();
    },

    [goToNext, toast, handleVerificationCode]
  );

  return (
    <Box pt="9" bg="white" h="100dvh" w="100dvw" px="8">
      <Center>
        <Icon boxSize="24">
          <Logo />
        </Icon>
      </Center>

      {activeStep === 1 && (
        <>
          <Center>
            <Box textAlign="center" mt="9" w="65%">
              <Text
                color="black.active"
                fontWeight="semibold"
                fontSize="22px"
                mb="4"
              >
                {t("page.forgot-password.forgot-password")} ?
              </Text>

              <Text
                color="gray.passive"
                fontSize="11px"
                position="relative"
                bottom="2"
              >
                {t("page.forgot-password.subtitle")}
              </Text>
            </Box>
          </Center>
          <Center>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w="80dvw"
            >
              <Box
                onSubmit={(e: FormEvent<HTMLDivElement>) => {
                  onSubmitResetPassword();
                  e.preventDefault();
                  if (!getValues("email")) return;
                  setDuration((duration) => ({
                    ...duration,
                    endDate: addMinutes(new Date(), 3),
                  }));
                  goToNext();
                }}
                as="form"
                w="full"
                mt="3"
              >
                <FormControl isInvalid={!!resetPasswordFormErrors.email}>
                  <FormLabel
                    fontSize="16px"
                    zIndex="9"
                    fontWeight="semibold"
                    bg="white"
                  >
                    {t("input.email-address")}
                  </FormLabel>
                  <Input
                    _placeholder={{ fontSize: "12px", lineHeight: "22px" }}
                    placeholder={t("input.placeholder.email-address")}
                    rounded="full"
                    type="text"
                    {...resetPasswordRegister("email")}
                  />

                  <FormErrorMessage>
                    {resetPasswordFormErrors.email?.message}
                  </FormErrorMessage>
                </FormControl>

                <Center>
                  <Box textAlign="center" mt="4" w="full">
                    <Text color="black.active" fontSize="15px">
                      {t("page.forgot-password.info")}
                    </Text>
                  </Box>
                </Center>

                <Button
                  mt={12}
                  bg="black.active"
                  color="white"
                  fontWeight="700"
                  fontSize="20"
                  type="submit"
                  _hover={{
                    opacity: 0.8,
                  }}
                  w="full"
                >
                  {t("button.send-code")}
                </Button>
                <Button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                  mt={6}
                  bg="#B7BABF"
                  color="black.active"
                  fontWeight="700"
                  fontSize="20"
                  _hover={{
                    opacity: 0.8,
                  }}
                  w="full"
                >
                  {t("back")}
                </Button>
              </Box>
            </Flex>
          </Center>
        </>
      )}

      {activeStep === 2 && (
        <>
          <Center>
            <Box textAlign="center" mt="7" w="60%">
              <Text
                color="black.active"
                fontWeight="semibold"
                fontSize="28px"
                mb="4"
              >
                {t("page.verification.Verification")}
              </Text>

              <Text
                color="gray.passive"
                fontSize="14px"
                position="relative"
                bottom="2"
              >
                {t("page.verification.info")}
              </Text>
            </Box>
          </Center>
          <Center>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w="80dvw"
            >
              {duration.minutes !== 0 && duration.seconds !== 0 && (
                <Text
                  color="#E52625"
                  fontWeight="500"
                  position="relative"
                  top="3"
                >
                  {("0" + duration.minutes.toString()).slice(-2)}:
                  {("0" + duration.seconds.toString()).slice(-2)}
                </Text>
              )}
              <HStack w="full" justifyContent="center" mt="10">
                <PinInput
                  placeholder=""
                  size="lg"
                  onChange={(value) => setPin(value)}
                >
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                  <PinInputField />
                </PinInput>
              </HStack>

              <Button
                mt={12}
                bg="black.active"
                color="white"
                fontWeight="700"
                fontSize="20"
                _hover={{
                  opacity: 0.8,
                }}
                w="70%"
                onClick={() => handleSendButtonClick(pin)}
              >
                {t("button.send-code")}
              </Button>
              <Button
                onClick={() => {
                  setPin("");
                  goToPrevious();
                }}
                mt={6}
                bg="#B7BABF"
                color="black.active"
                fontWeight="700"
                _hover={{
                  opacity: 0.8,
                }}
                w="70%"
              >
                {t("back")}
              </Button>
              <Flex alignItems="baseline">
                <Text
                  color="gray.passive"
                  fontSize="2.8dvw"
                  mt="4"
                  textAlign="center"
                >
                  {t("page.verification.resend-message")}
                </Text>
                <Button
                  ml="2"
                  variant="link"
                  color="black.active"
                  fontSize="14px"
                  mt="5"
                  onClick={() => {
                    if (duration.minutes !== 0 && duration.seconds !== 0) {
                      toast({
                        description: i18n.t("errors.please-wait-timer"),
                        status: "error",
                        isClosable: true,
                      });
                      return;
                    }
                    onSubmitResetPassword();
                    setDuration(() => ({
                      minutes: 3,
                      seconds: 0,
                      endDate: addMinutes(new Date(), 3),
                    }));
                    toast({
                      description: i18n.t("success.new-code-resent"),
                      status: "success",
                      isClosable: true,
                    });
                  }}
                >
                  {t("page.verification.resend")}
                </Button>
              </Flex>
            </Flex>
          </Center>
        </>
      )}

      {activeStep === 3 && (
        <>
          <Center>
            <Box textAlign="center" mt="7">
              <Text
                color="black.active"
                fontWeight="semibold"
                fontSize="22px"
                mb="4"
                whiteSpace="nowrap"
              >
                {t("page.create-new-password.create-new-password")}
              </Text>

              {/* <Text
                color="gray.passive"
                fontSize="11px"
                position="relative"
                bottom="2"
              >
                {t("page.create-new-password.subtitle")}
              </Text> */}
            </Box>
          </Center>
          <Center>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              w="80dvw"
            >
              <Box onSubmit={onSubmitSetPassword} as="form" w="full" mt="3">
                <FormControl isInvalid={!!resetPasswordFormErrors.email}>
                  <FormLabel
                    fontSize="16px"
                    zIndex="9"
                    fontWeight="semibold"
                    bg="white"
                  >
                    {t("input.email-address")}
                  </FormLabel>
                  <Input
                    _placeholder={{ fontSize: "12px", lineHeight: "22px" }}
                    placeholder={t("input.placeholder.email-address")}
                    rounded="full"
                    type="text"
                    {...resetPasswordRegister("email")}
                  />

                  <FormErrorMessage>
                    {resetPasswordFormErrors.email?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt="1"
                  justifyContent="space-between"
                  flexDirection="row"
                  alignItems="center"
                  isInvalid={!!errors.password}
                >
                  <FormLabel
                    fontWeight="semibold"
                    fontSize="md"
                    zIndex="9"
                    bg="white"
                    position="relative"
                    top="1"
                  >
                    {t("input.new-password")}
                  </FormLabel>

                  <Input
                    _placeholder={{
                      fontSize: "14px",
                    }}
                    rounded="full"
                    type={"text"}
                    placeholder={t("input.placeholder.create_password")}
                    {...register("password")}
                  />

                  <FormErrorMessage>
                    {errors.password?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt="1"
                  justifyContent="space-between"
                  flexDirection="row"
                  alignItems="center"
                  isInvalid={!!errors.confirmPassword}
                >
                  <FormLabel
                    position="relative"
                    top="1"
                    fontWeight="semibold"
                    fontSize="md"
                    zIndex="9"
                    bg="white"
                  >
                    {t("input.confirm_password")}
                  </FormLabel>

                  <Input
                    _placeholder={{
                      fontSize: "14px",
                    }}
                    rounded="full"
                    type={"text"}
                    placeholder={t("input.placeholder.confirm_password")}
                    {...register("confirmPassword")}
                  />

                  <FormErrorMessage>
                    {errors.confirmPassword?.message}
                  </FormErrorMessage>
                </FormControl>

                <Center>
                  <Box textAlign="center" mt="4" w="full">
                    <Text color="black.active" fontSize="15px">
                      {t("page.create-new-password.info")}
                    </Text>
                  </Box>
                </Center>

                <Button
                  mt={5}
                  bg="black.active"
                  color="white"
                  fontWeight="700"
                  fontSize="20"
                  type="submit"
                  _hover={{
                    opacity: 0.8,
                  }}
                  w="full"
                >
                  {t("button.create")}
                </Button>
                <Button
                  onClick={() => goToPrevious()}
                  mt={3}
                  bg="#B7BABF"
                  color="black.active"
                  fontWeight="700"
                  fontSize="20"
                  _hover={{
                    opacity: 0.8,
                  }}
                  w="full"
                >
                  {t("back")}
                </Button>
              </Box>
            </Flex>
          </Center>
        </>
      )}
    </Box>
  );
};
