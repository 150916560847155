import { PlusSignIcon } from "@/assets/icons/plus-sign-icon";
import { Box, Text, Flex, IconButton, Divider } from "@chakra-ui/react";
import { FC } from "react";

interface TelegramClickableListItemProps {
  title: string;
  description: string;
  onClick: () => void;
  open: boolean;
}

export const TelegramClickableListItem: FC<TelegramClickableListItemProps> = ({
  title,
  description,
  onClick,
  open,
}) => {
  return (
    <Box>
      <Divider bgColor="white.line" width="full" height="2px" />

      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        onClick={onClick}
        cursor="pointer"
      >
        <Text
          color="black.active"
          lineHeight="20px"
          fontWeight="700"
          size="18px"
          my="4"
        >
          {title}
        </Text>
        <IconButton
          w="30px"
          h="30px"
          aria-label="plus-icon"
          size="none"
          variant="unstyled"
          transform={open ? "rotate(45deg)" : "none"}
          icon={<PlusSignIcon width="30px" height="30px" stroke="#353B48" />}
        />
      </Flex>

      {open && (
        <>
          <Divider bgColor="white.line" width="full" height="2px" />
          <Text
            p="4"
            color="blue.brand"
            lineHeight="20px"
            fontWeight="400"
            size="16px"
            whiteSpace="pre-line"
          >
            {description}
          </Text>
        </>
      )}
    </Box>
  );
};
