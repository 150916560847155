export const UserRevokedIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 35 37"
      fill="none"
      {...props}
    >
      <path
        d="M21.3671 7.54971C21.3671 3.4265 18.0119 0 13.8886 0C9.76543 0 6.41016 3.4265 6.41016 7.54971C6.41016 11.6729 9.76543 15.0282 13.8886 15.0282C18.0119 15.0282 21.3671 11.6729 21.3671 7.54971Z"
        fill="#B7BABF"
      />
      <path
        d="M9.6152 15.0273C4.30483 15.0273 0 19.3322 0 24.6425V27.8476H17.2017C17.7214 23.2282 21.4903 19.6137 26.1825 19.354C24.4621 16.7501 21.5165 15.0273 18.162 15.0273H9.6152Z"
        fill="#B7BABF"
      />
      <path
        d="M26.709 21.4375C22.5857 21.4375 19.2305 24.7928 19.2305 28.916C19.2305 33.0392 22.5857 36.4657 26.709 36.4657C30.8322 36.4657 34.1874 33.0392 34.1874 28.916C34.1874 24.7928 30.8322 21.4375 26.709 21.4375ZM29.7304 30.4267L28.2197 31.9374L26.709 30.4267L25.1982 31.9374L23.6875 30.4267L25.1982 28.916L23.6875 27.4053L25.1982 25.8945L26.709 27.4053L28.2197 25.8945L29.7304 27.4053L28.2197 28.916L29.7304 30.4267Z"
        fill="#B7BABF"
      />
    </svg>
  );
};
