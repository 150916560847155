import { FC, SVGAttributes } from "react";

export const UptimeIcon: FC<SVGAttributes<SVGSVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 128 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M60.25 0.788574C45.7945 1.61832 32.2385 7.23557 21.4182 16.8788L38.1522 33.6128C44.4497 28.3701 52.0962 25.2018 60.25 24.4568V0.788574Z"
        fill={props.fill || "black"}
      />
      <path
        d="M32.8472 38.9144L16.1134 22.1807C6.46194 32.9979 0.837686 46.5549 0.00268555 61.0139H23.6719C24.4219 52.8587 27.5969 45.2114 32.8472 38.9144Z"
        fill={props.fill || "black"}
      />
      <path
        d="M104.328 61.0139H127.997C127.162 46.5549 121.538 32.9979 111.887 22.1807L95.1528 38.9144C100.403 45.2112 103.578 52.8587 104.328 61.0139Z"
        fill={props.fill || "black"}
      />
      <path
        d="M104.323 68.5139C103.797 74.2897 102.149 79.1072 100.199 82.9714C102.101 85.9042 103.687 88.3827 104.734 90.0287C107.587 94.5117 106.955 100.266 103.198 104.023C102.286 104.936 101.244 105.671 100.115 106.214L104.012 110.111C104.745 110.845 105.707 111.212 106.668 111.212C107.629 111.212 108.591 110.845 109.324 110.111C120.555 98.8817 127.104 84.2279 128 68.5139H104.323Z"
        fill={props.fill || "black"}
      />
      <path
        d="M23.6775 68.5139H0C0.89625 84.2279 7.445 98.8817 18.6758 110.112C19.4093 110.845 20.3705 111.212 21.3318 111.212C22.293 111.212 23.2545 110.845 23.988 110.112L35.366 98.7329C36.8328 97.2659 36.8328 94.8874 35.366 93.4204C32.3968 90.4512 24.9175 82.1382 23.6775 68.5139Z"
        fill={props.fill || "black"}
      />
      <path
        d="M106.582 16.8788C95.7615 7.23532 82.2055 1.61832 67.75 0.788574V24.4568C75.9035 25.2018 83.5502 28.3701 89.8477 33.6128L106.582 16.8788Z"
        fill={props.fill || "black"}
      />
      <path
        d="M72.3326 56.4943C67.7383 51.9 60.2628 51.9 55.6686 56.4943C51.0743 61.0885 51.0743 68.564 55.6686 73.1583C61.4726 78.9623 89.9963 97.1748 93.2298 99.2323C94.7126 100.176 96.6518 99.963 97.8948 98.7203C99.1376 97.4775 99.3506 95.5383 98.4068 94.0553C96.3491 90.822 78.1366 62.298 72.3326 56.4943Z"
        fill={props.fill || "black"}
      />
    </svg>
  );
};
