import React from "react";

export const EditSquareIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.06641"
        y="1"
        width="50.4351"
        height="50.4351"
        rx="2.5"
        fill="#FAFAFA"
        stroke="#EBEDF2"
      />
      <path
        d="M30.1557 18.0905L35.5214 23.4823L21.9394 37.1308L16.5768 31.739L30.1557 18.0905ZM39.5929 16.79L37.2 14.3854C36.2753 13.4562 34.7736 13.4562 33.8457 14.3854L31.5536 16.6888L36.9193 22.0808L39.5929 19.394C40.3102 18.6732 40.3102 17.5108 39.5929 16.79ZM13.7688 39.3177C13.6712 39.7593 14.068 40.155 14.5075 40.0476L20.4866 38.5908L15.124 33.1989L13.7688 39.3177Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
