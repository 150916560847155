import React from "react";

export const ImageUploadIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 50 37"
      fill="none"
      width="47.815px"
      height="34.705px"
      {...props}
    >
      <path
        d="M12.4717 35.7054L28.1851 19.2207L33.1016 24.4264"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.6904 35.7044L40.9103 15.8457L48.8152 24.329"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.0991 19.9903C18.9626 19.9903 20.4732 18.4797 20.4732 16.6162C20.4732 14.7528 18.9626 13.2422 17.0991 13.2422C15.2357 13.2422 13.7251 14.7528 13.7251 16.6162C13.7251 18.4797 15.2357 19.9903 17.0991 19.9903Z"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M48.8149 8.9043H8.71191V35.7039H48.8149V8.9043Z"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 25.0996V27.9917H3.89204"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M3.89204 1H1V3.89204"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M8.56734 1H5.67529"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M13.2431 1H10.3511"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M17.9184 1H15.0264"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M22.5937 1H19.7017"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M27.2695 1H24.3774"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.9448 1H29.0527"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M36.6206 1H33.7285"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 5.82031V8.71236"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 10.6406V13.5327"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 15.4609V18.353"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 20.2812V23.1733"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.392 3.89204V1H38.5"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M5.82031 27.9922H8.71236"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M41.3921 8.90572V6.01367"
        stroke="#E2E4E6"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};
