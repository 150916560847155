import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { FC } from "react";
import { ClipIcon } from "@/assets/icons/clip-icon";

export const TicketInfo: FC<{
  isOpen: boolean;
  onClose: () => void;
}> = ({ onClose, isOpen }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box w="full" h="full" opacity="0.1">
        <ModalOverlay />
      </Box>
      <ModalContent borderRadius="0px" maxH="780px" maxW="780px">
        <ModalBody p="24px">
          <Text fontWeight="bold" mb="16px">
            I need some information
          </Text>
          <Text>
            A social media planning tool helps users create, schedule, manage,
            and analyze content across multiple social media platforms, ensuring
            a consistent and effective online presence. Key features include
            content calendars, multi-platform management, post scheduling,
            collaboration tools, and analytics. These tools improve time
            efficiency, consistency, collaboration, and data-driven
            decision-making. Popular examples include Hootsuite, Buffer, and
            Sprout Social, which streamline social media efforts and enhance
            audience engagement.
          </Text>
          <Box w="full" h="300px" bg="pink.100"></Box>
          <Flex>
            <Box w="full" h="100px" bg="purple.100">
              {" "}
            </Box>
            <Box w="full" h="100px" bg="purple.200"></Box>
            <Box w="full" h="100px" bg="purple.300"></Box>
            <Box w="full" h="100px" bg="purple.400"></Box>
            <Box w="full" h="100px" bg="purple.500"></Box>
          </Flex>
          <Button
            w="full"
            h="30px"
            variant="unstyled"
            display="flex"
            justifyContent="left"
            pl="20px"
            p="20px"
            textAlign="left"
            bg="#E2EEF8"
            borderRadius="3px"
            border="1px solid #57AAEC"
            mt="20px"
            onClick={() => alert("You clicked on the button")}
          >
            <ClipIcon width="19px" /> <Text ml="10px">File Name:</Text>
            <Text ml="10px" fontWeight="100">
              posteffect-ai-compose-screen-problem-exp.doc
            </Text>
          </Button>

          {/* //avatarlar gelcek// */}
        </ModalBody>

        <ModalFooter pt="0">
          <Button
            bg="red.main"
            w="95px"
            h="36px"
            color="white"
            px="32px"
            onClick={onClose}
          >
            delete
          </Button>
          <Button
            variant="ghost"
            h="36px"
            bg="blue.shiny"
            color="white"
            px="32px"
            ml="20px"
          >
            back
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
