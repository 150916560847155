import { FC } from "react";
import { Box, Text, Flex } from "@chakra-ui/react";
import { ChevronFilledIcon } from "@/assets/icons/chevron-filled-icon";

interface TextChevronGroupProps {
  title: string;
  chevronUp: boolean;
}

export const TextChevronGroup: FC<TextChevronGroupProps> = ({
  title,
  chevronUp,
}) => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <Text
        color="gray.smoke"
        fontSize="14px"
        lineHeight="14px"
        fontWeight="600"
        mr="2"
      >
        {title}
      </Text>
      {chevronUp ? (
        <Box>
          <ChevronFilledIcon fill="#B7BABF" />
        </Box>
      ) : (
        <Box>
          <ChevronFilledIcon fill="#B7BABF" />
        </Box>
      )}
    </Flex>
  );
};
