import { CheckIcon, CloseIcon, EditIcon } from "@chakra-ui/icons";
import {
  ButtonGroup,
  Editable,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  useEditableControls,
  EditableInput as ChakraEditableInput,
  Tooltip,
} from "@chakra-ui/react";

import type { EditableProps as ChakraEditableProps } from "@chakra-ui/react";
import { FC } from "react";

interface CustomEditableProps {
  tooltipCondition?: boolean;
  tooltipMessage?: string;
}

export type EditableInputProps = ChakraEditableProps & CustomEditableProps;

export const EditableInput: FC<EditableInputProps> = ({
  tooltipCondition,
  tooltipMessage,
  ...props
}) => {
  return (
    <Editable {...props}>
      <Flex alignItems="center" flexDir={{ base: "column", xl: "row" }}>
        <EditablePreview />

        <Input as={ChakraEditableInput} />

        <EditableControls
          tooltipMessage={tooltipMessage}
          tooltipCondition={tooltipCondition}
        />
      </Flex>
    </Editable>
  );
};

interface EditableControlProps {
  tooltipMessage?: string;
  tooltipCondition?: boolean;
}

const EditableControls: FC<EditableControlProps> = ({
  tooltipCondition,
  tooltipMessage,
}) => {
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup justifyContent="center" size="sm" ml="2">
      <IconButton
        aria-label="submit"
        icon={<CheckIcon />}
        {...getSubmitButtonProps()}
      />
      <IconButton
        aria-label="cancel"
        icon={<CloseIcon />}
        {...getCancelButtonProps()}
      />
    </ButtonGroup>
  ) : (
    <Tooltip
      label={tooltipMessage}
      visibility={tooltipCondition ? "visible" : "hidden"}
    >
      <ButtonGroup justifyContent="center" size="sm" ml="2">
        <IconButton
          aria-label="edit"
          size="sm"
          icon={<EditIcon />}
          {...getEditButtonProps()}
        />
      </ButtonGroup>
    </Tooltip>
  );
};
