import {
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { AccountSettings } from "./account-settings";
import Members from "./organizations/members";
import { Profile } from "./profile";
import { useLocation } from "react-router-dom";
import { PaymentMethod } from "./payment-method";

export const SettingsPage: FC = () => {
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(
    location.state && location.state.tab ? location.state.tab : 0
  );

  const { t } = useTranslation();

  return (
    <Tabs
      orientation="vertical"
      variant="unstyled"
      minH="70vh"
      index={tabIndex}
      onChange={setTabIndex}
      p="24px"
    >
      <TabList
        shadow="sm"
        gap="4"
        mt="4"
        minH="full"
        bg="white"
        borderRight="1px solid"
        borderColor="gray.smooth"
        mb="4"
        px="4"
        pt="24px"
        whiteSpace="nowrap"
        minW="20ch"
      >
        <Tab justifyContent="left">
          <Flex
            fontSize="16px"
            lineHeight="20px"
            opacity={tabIndex === 0 ? "1" : "0.63"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 0 ? "bold" : undefined}
          >
            {t("profile")}
          </Flex>
        </Tab>
        <Tab justifyContent="left">
          <Flex
            fontSize="16px"
            lineHeight="20px"
            opacity={tabIndex === 1 ? "1" : "0.63"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 1 ? "bold" : undefined}
          >
            {t("payment_method")}
          </Flex>
        </Tab>
        {/* <Tab justifyContent="left">
          <Flex
            fontSize="14px"
            lineHeight="20px"
            opacity={tabIndex === 2 ? "1" : "0.33"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 2 ? "bold" : undefined}
          >
            {t("organizations")}
          </Flex>
        </Tab> */}
        <Tab justifyContent="left">
          <Flex
            fontSize="16px"
            lineHeight="20px"
            opacity={tabIndex === 2 ? "1" : "0.63"}
            color="black.active"
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 2 ? "bold" : undefined}
          >
            {t("members")}
          </Flex>
        </Tab>
        {/* <Tab justifyContent="left">
          <Flex
            fontSize="14px"
            lineHeight="20px"
            opacity={tabIndex === 4 ? "1" : "0.33"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            fontWeight={tabIndex === 4 ? "bold" : undefined}
          >
            {t("roles")}
          </Flex>
        </Tab> */}

        <Tab justifyContent="left">
          <Flex
            fontSize="16px"
            lineHeight="20px"
            opacity={tabIndex === 3 ? "1" : "0.63"}
            color={"black.active"}
            flexDir="column"
            alignItems="end"
            whiteSpace="nowrap"
            fontWeight={tabIndex === 3 ? "bold" : undefined}
          >
            {t("account_settings")}
          </Flex>
        </Tab>
      </TabList>

      <TabPanels>
        <TabPanel h="full">
          <Profile />
        </TabPanel>
        <TabPanel h="full">
          <PaymentMethod />
        </TabPanel>
        {/* <TabPanel h="full">
          <Organizations />
        </TabPanel> */}
        <TabPanel h="full">
          <Members />
        </TabPanel>
        {/* <TabPanel h="full">
          <Roles userData={userData} />
        </TabPanel> */}
        <TabPanel h="full">
          <AccountSettings />
        </TabPanel>
        {/* <TabPanel h="full">
          <PrivacyPolicy />
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
};
