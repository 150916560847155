import React from "react";

export const EyeIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 27 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0 9.79241C2.36526 5.92838 5.62462 3.18397 10.1205 2.06922C13.2164 1.3017 16.2574 1.58909 19.1947 2.83465C19.4019 2.92233 19.5146 2.8938 19.6647 2.7428C20.4904 1.91335 21.3268 1.09434 22.1596 0.271852C22.4034 0.0310894 22.6908 -0.0600664 23.0262 0.0401354C23.3397 0.133379 23.5342 0.347003 23.6047 0.658046C23.6807 0.994836 23.5455 1.26204 23.3038 1.49932C22.1631 2.61964 21.0273 3.74412 19.8901 4.86791C14.906 9.79033 9.92041 14.7127 4.93836 19.6379C4.69528 19.8787 4.43459 20.052 4.07808 19.9873C3.75045 19.9281 3.52146 19.7381 3.4186 19.4194C3.3122 19.0896 3.39816 18.8029 3.64195 18.5608C4.343 17.8656 5.04546 17.1726 5.74862 16.4802C5.81697 16.4127 5.89729 16.3577 6.00297 16.2728C3.4961 14.6981 1.46904 12.7198 0 10.2092V9.79241ZM18.1195 4.22634C18.09 4.20686 18.0695 4.18877 18.0463 4.17972C15.1596 3.09559 12.2455 3.02671 9.33491 4.04125C6.2073 5.13164 3.83359 7.18647 1.94533 9.83417C1.85092 9.96707 1.85937 10.0596 1.9566 10.173C2.64215 10.9733 3.29036 11.809 4.0203 12.5674C4.95598 13.5388 6.04736 14.3314 7.22118 14.9862C7.93633 14.2764 8.6409 13.5764 9.34829 12.8743C7.794 10.6031 8.42671 8.07159 9.73863 6.65763C10.4665 5.87341 11.3507 5.34735 12.4019 5.12051C13.8583 4.80598 15.2026 5.10241 16.4138 5.91029C16.9922 5.339 17.5502 4.78789 18.1195 4.22565V4.22634ZM15.1582 7.14611C14.1429 6.428 12.2208 6.41756 10.9759 7.79325C9.83797 9.05064 9.97114 10.8306 10.6208 11.6274C12.1328 10.1341 13.6462 8.6394 15.1582 7.14611Z"
        fill="#B7BABF"
      />
      <path
        d="M21.2503 5.93915C21.6632 5.52791 22.0577 5.13545 22.465 4.73047C23.2576 5.31637 23.9932 5.98369 24.6773 6.70737C25.489 7.56534 26.2154 8.48804 26.8355 9.49214C27.0433 9.82893 27.0602 10.1588 26.8439 10.4949C24.5963 13.9894 21.6005 16.5605 17.5308 17.7664C14.8373 18.5646 12.1275 18.5221 9.42823 17.7518C9.39512 17.7421 9.36482 17.724 9.28027 17.6864C9.59451 17.3823 9.91932 17.1193 10.18 16.8041C10.4858 16.4346 10.7965 16.3587 11.2848 16.4478C14.7203 17.0748 17.8726 16.3288 20.7543 14.4173C22.4586 13.2873 23.8643 11.8545 25.0395 10.1949C25.1431 10.0488 25.1402 9.95557 25.0381 9.81153C24.0348 8.40384 22.8539 7.16384 21.4722 6.11242C21.3961 6.05466 21.3222 5.99552 21.2503 5.93915Z"
        fill="#B7BABF"
      />
      <path
        d="M12.2097 14.8083C12.7135 14.3122 13.189 13.839 13.6731 13.3749C13.7288 13.3213 13.8295 13.3011 13.9126 13.2907C15.4444 13.1035 16.6443 11.9198 16.8324 10.4119C16.8437 10.3229 16.8733 10.2171 16.9332 10.1559C17.3961 9.6827 17.8688 9.21857 18.3444 8.74609C18.7925 9.81004 18.5868 11.7793 17.4102 13.1793C16.1532 14.674 13.9352 15.3914 12.209 14.809L12.2097 14.8083Z"
        fill="#B7BABF"
      />
    </svg>
  );
};
