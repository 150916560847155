import React from "react";

export const SendMessageIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.9908 9.65622L4.86581 2.16482C4.64457 2.04071 4.39081 1.98686 4.13823 2.0104C3.88566 2.03395 3.64622 2.13379 3.45174 2.29665C3.25725 2.45951 3.11692 2.67769 3.04938 2.92221C2.98185 3.16673 2.9903 3.426 3.07363 3.6656L5.4955 10.7336C5.49519 10.7362 5.49519 10.7388 5.4955 10.7414C5.49506 10.744 5.49506 10.7466 5.4955 10.7492L3.07363 17.8328C3.0069 18.0213 2.98635 18.223 3.01372 18.4211C3.04108 18.6191 3.11555 18.8077 3.23089 18.971C3.34622 19.1344 3.49905 19.2676 3.67655 19.3597C3.85405 19.4517 4.05103 19.4998 4.25097 19.5C4.4679 19.4994 4.68102 19.4429 4.86972 19.3359L17.9877 11.832C18.1812 11.7236 18.3424 11.5657 18.4547 11.3744C18.567 11.1832 18.6264 10.9655 18.6268 10.7437C18.6272 10.5219 18.5686 10.304 18.4569 10.1123C18.3453 9.92069 18.1847 9.76219 17.9916 9.6531L17.9908 9.65622ZM4.25097 18.25V18.2429L6.60566 11.375H11.126C11.2917 11.375 11.4507 11.3091 11.5679 11.1919C11.6851 11.0747 11.751 10.9157 11.751 10.75C11.751 10.5842 11.6851 10.4252 11.5679 10.308C11.4507 10.1908 11.2917 10.125 11.126 10.125H6.61191L4.25566 3.25935L4.25097 3.24997L17.376 10.7367L4.25097 18.25Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
