import { Flex, Image, Center } from "@chakra-ui/react";
import { useState } from "react";

export const SmartImage = ({
  imageUrl,
  fallbackUrl,
  h,
  borderRadius,
}: {
  imageUrl: string;
  fallbackUrl?: string;
  h: string;
  borderRadius?: string;
}) => {
  const [imageInfo, setImageInfo] = useState<{
    width: number;
    height: number;
    orientation: "portrait" | "landscape";
  }>({
    width: 0,
    height: 0,
    orientation: "landscape",
  });

  return (
    <Flex
      w="full"
      pos="relative"
      h={h}
      overflow="hidden"
      borderRadius={borderRadius}
    >
      <Image
        src={imageUrl}
        objectFit="cover"
        objectPosition="center"
        h="full"
        w="full"
        filter="blur(5px)"
        display={imageInfo.orientation === "portrait" ? undefined : "none"}
        pos="absolute"
    
      />
      <Center w="full">
        <Image
          objectPosition="center"
          objectFit={imageInfo.orientation === "portrait" ? "contain" : "cover"}
          onLoad={(e) => {
            setImageInfo({
              width: e.currentTarget.naturalWidth,
              height: e.currentTarget.naturalHeight,
              orientation:
                e.currentTarget.naturalWidth > e.currentTarget.naturalHeight
                  ? "landscape"
                  : "portrait",
            });
          }}
          alt="Compose Image"
          src={imageUrl}
          fallbackSrc={fallbackUrl}
          w={imageInfo.orientation === "portrait" ? "auto" : "full"}
          h="full"
          filter="blur(0px)"
        />
      </Center>
    </Flex>
  );
};
