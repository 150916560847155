export const LikeBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.983276"
        width="43.0104"
        height="44.4719"
        rx="3"
        fill="#F8EBEC"
      />
      <path
        d="M32.1683 14.0034C30.9771 12.7115 29.3427 12 27.5657 12C26.2375 12 25.0211 12.4199 23.9502 13.248C23.4098 13.666 22.9202 14.1774 22.4885 14.7743C22.057 14.1776 21.5672 13.666 21.0266 13.248C19.9559 12.4199 18.7395 12 17.4112 12C15.6343 12 13.9997 12.7115 12.8085 14.0034C11.6316 15.2803 10.9833 17.0246 10.9833 18.9153C10.9833 20.8613 11.7085 22.6427 13.2655 24.5215C14.6584 26.2021 16.6602 27.9081 18.9784 29.8837C19.77 30.5583 20.6673 31.3231 21.5989 32.1376C21.8451 32.3532 22.1609 32.4719 22.4885 32.4719C22.8159 32.4719 23.1319 32.3532 23.3777 32.138C24.3094 31.3232 25.2071 30.5582 25.9991 29.8832C28.3169 27.908 30.3188 26.2021 31.7117 24.5213C33.2687 22.6427 33.9937 20.8613 33.9937 18.9151C33.9937 17.0246 33.3454 15.2803 32.1683 14.0034Z"
        fill="#F13232"
      />
    </svg>
  );
};
