export const ChevronFilledIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="10"
      height="7"
      viewBox="0 0 10 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.58578 0.5C9.47669 0.5 9.92285 1.57714 9.29289 2.20711L5.70709 5.79289C5.31657 6.18342 4.6834 6.18342 4.29288 5.79289L0.707102 2.20711C0.0771379 1.57714 0.523305 0.5 1.41421 0.5H8.58578Z"
        fill={props.fill || "#F13232"}
      />
    </svg>
  );
};
