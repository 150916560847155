import React from "react";

export const BlackCrossIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18" cy="18" r="18" fill="#57AAEB" />
      <path
        d="M11.4918 27.0983C11.1635 27.1006 10.838 27.038 10.5339 26.9141C10.2298 26.7902 9.95323 26.6075 9.71995 26.3764C9.2591 25.915 9.00024 25.2895 9.00024 24.6374C9.00024 23.9852 9.2591 23.3597 9.71995 22.8983L22.845 10.0686C23.0533 9.76491 23.3273 9.51184 23.6465 9.32808C23.9657 9.14433 24.3222 9.03458 24.6895 9.00694C25.0568 8.9793 25.4256 9.03446 25.7688 9.16836C26.1119 9.30226 26.4206 9.51148 26.6721 9.7806C26.9236 10.0497 27.1115 10.3719 27.2219 10.7233C27.3324 11.0747 27.3625 11.4464 27.3101 11.811C27.2577 12.1756 27.1241 12.5238 26.9192 12.8299C26.7143 13.136 26.4433 13.3922 26.1262 13.5796L13.0012 26.4093C12.59 26.8023 12.0582 27.0451 11.4918 27.0983Z"
        fill="#EEF6FF"
      />
      <path
        d="M24.8434 27.0983C25.1717 27.1006 25.4972 27.038 25.8013 26.9141C26.1054 26.7902 26.382 26.6075 26.6153 26.3764C27.0761 25.915 27.335 25.2895 27.335 24.6374C27.335 23.9852 27.0761 23.3597 26.6153 22.8983L13.4903 10.0686C13.2819 9.76491 13.0079 9.51184 12.6887 9.32808C12.3695 9.14433 12.013 9.03458 11.6457 9.00694C11.2784 8.9793 10.9096 9.03446 10.5664 9.16836C10.2233 9.30226 9.91458 9.51148 9.66307 9.7806C9.41156 10.0497 9.22368 10.3719 9.11326 10.7233C9.00285 11.0747 8.97273 11.4464 9.02512 11.811C9.07752 12.1756 9.21109 12.5238 9.41599 12.8299C9.62089 13.136 9.89189 13.3922 10.209 13.5796L23.334 26.4093C23.7452 26.8023 24.277 27.0451 24.8434 27.0983Z"
        fill="#EEF6FF"
      />
    </svg>
  );
};
