export const RefreshCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 33 27"
      fill="none"
      {...props}
    >
      <path
        d="M32.7807 10.7199C32.4885 10.4271 32.0144 10.4271 31.7225 10.7199L29.9552 12.4908C29.5263 6.67212 25.4111 1.79002 19.758 0.39317C14.1052 -1.00395 8.19671 1.40139 5.11721 6.35298C4.97277 6.58012 4.96099 6.86767 5.08651 7.10606C5.21204 7.34445 5.45542 7.49716 5.72429 7.50567C5.99315 7.51446 6.24612 7.37769 6.38645 7.14781C9.10335 2.78012 14.2993 0.637056 19.297 1.82298C24.295 3.0089 27.9803 7.25877 28.4544 12.3837L26.7932 10.7199C26.4997 10.4359 26.033 10.4397 25.7441 10.7292C25.4555 11.0184 25.4514 11.4861 25.735 11.7805L28.7288 14.7802C29.0209 15.0733 29.4948 15.0733 29.787 14.7802L32.7807 11.7805C33.0731 11.4878 33.0731 11.0126 32.7807 10.7199Z"
        fill={props.fill || "currentColor"}
      />
      <path
        d="M27.6495 19.6138C27.2987 19.3949 26.837 19.5015 26.6171 19.8519C23.899 24.2201 18.7007 26.3632 13.7001 25.1773C8.69979 23.9914 5.01291 19.7412 4.53882 14.6161L6.20019 16.2801C6.49386 16.5641 6.96082 16.5603 7.24983 16.2708C7.53856 15.9816 7.54267 15.5139 7.25888 15.2195L4.2638 12.2198C3.9715 11.9267 3.49741 11.9267 3.20511 12.2198L0.210304 15.2195C-0.0734926 15.5139 -0.0696538 15.9816 0.219353 16.2708C0.508085 16.5603 0.975046 16.5641 1.26899 16.2801L3.03675 14.5092C3.46615 20.3279 7.58298 25.21 13.2386 26.6068C18.8943 28.0039 24.8052 25.5989 27.8861 20.647C28.1049 20.2963 27.9991 19.8341 27.6495 19.6138Z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
};
