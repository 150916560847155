import { FC, useCallback, useEffect } from "react";
import { Text, Flex, Divider, Box, Center } from "@chakra-ui/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { NotificationItem } from "./notification-item";
import { useNotification } from "@/hooks/use-notification";
import { useQuery } from "react-query";
import { APISchemas, GET } from "@/api";
import {
  endOfMonth,
  getMonth,
  getYear,
  isToday,
  isYesterday,
  parseISO,
  subMonths,
} from "date-fns";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18nformat } from "@/utils/misc";
import { mapPostType } from "@/utils/map-post-type";
import { EmptyNotifications } from "@/assets/icons/empty-notifications";
import { useTriggerStore } from "@/context/trigger-store/trigger-store";

interface Notifcaitions {
  onPress: (compose: APISchemas["Compose"]) => void;
}
export const Notifications: FC<Notifcaitions> = ({ onPress }) => {
  const [activeOrganization] = useAuthStore((s) => [s.activeOrganization]);
  const notificationChanges = useTriggerStore((s) => s.notificationChanges);
  const { data: Notifications, refetch } = useQuery(
    ["notifications"],
    async () => {
      const { data } = await GET("/notifications/", {
        params: {
          query: {
            page: 1,
            page_size: 30,
          },
        },
      });

      return data;
    },
    {
      select(data) {
        return data?.results
          ?.filter((d) => d.state.read !== true)
          .map((d) => {
            const haveMedia = "media" in (d.given_object ?? {});

            if (haveMedia) {
              const media = d.given_object?.media as Array<APISchemas["Media"]>;

              if (!media) {
                return { ...d, image: undefined };
              }

              const firstMedia = media.at(0);

              return {
                ...d,
                image:
                  firstMedia?.type === "image"
                    ? firstMedia.download_url
                    : firstMedia?.type === "video"
                      ? firstMedia.download_url.replace(
                          "original",
                          "preview.webp"
                        )
                      : undefined,
              };
            }

            return { ...d, image: undefined };
          });
      },
    }
  );
  const { t } = useTranslation();

  const { data: Composes } = useQuery(
    ["posts", activeOrganization?.id],
    async () => {
      if (!activeOrganization) {
        return;
      }

      const today = new Date();
      const lastMonth = subMonths(today, 1);
      const lastDayOfLastMonth = endOfMonth(lastMonth);

      const { data, error } = await GET("/common/{org_pk}/compose/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
          },
          query: {
            start_date: i18nformat(
              parseISO(lastDayOfLastMonth.toISOString()),
              "dd-MM-yyyy"
            ),

            end_date: i18nformat(parseISO(today.toISOString()), "dd-MM-yyyy"),
          },
        },
      });

      if (error) console.error("Get compose data error", error);
      return data;
    }
  );

  const todaysNotifications =
    Notifications?.filter((tweet) => {
      const tweetDate = new Date(tweet.created_at);
      return isToday(tweetDate);
    }) ?? [];
  const yesterdaysNotifications =
    Notifications?.filter((tweet) => {
      const tweetDate = new Date(tweet.created_at);
      return isYesterday(tweetDate);
    }) ?? [];

  const thisMonthNotifications =
    Notifications?.filter((notification) => {
      const today = new Date();
      const currentMonth = getMonth(today);
      const currentYear = getYear(today);
      const notificationDate = new Date(notification.created_at);
      const notificationMonth = getMonth(notificationDate);
      const notificationYear = getYear(notificationDate);

      return (
        notificationMonth === currentMonth && notificationYear === currentYear
      );
    }) ?? [];
  useNotification(() => {
    refetch();
  });

  const findClickedCompose = useCallback(
    (id: string, model_type?: string | null) => {
      if (!model_type) return;
      const formattedModelType = mapPostType(model_type);
      let result;

      if (formattedModelType === "compose") {
        result = Composes?.find((item) => item.id === Number(id));
      } else if (
        formattedModelType === "instagram_posts" ||
        formattedModelType === "twitter_posts" ||
        formattedModelType === "facebook_posts" ||
        formattedModelType === "telegram_posts" ||
        formattedModelType === "tiktok_posts" ||
        formattedModelType === "linkedin_posts" ||
        formattedModelType === "youtube_posts"
      ) {
        result = Composes?.find(
          (item) =>
            typeof item[formattedModelType] !== "undefined" &&
            item[formattedModelType]?.find((post) => post.id === Number(id))
        );
      }

      return result;
    },
    [Composes]
  );

  const handleClick = (notification: APISchemas["Notification"]) => {
    const result = findClickedCompose(
      notification?.given_object?.id as string,
      notification.model_type
    );
    if (result) {
      onPress(result);
    }
  };

  useEffect(() => {
    if (notificationChanges) {
      refetch();
    }
  }, [notificationChanges, refetch]);

  return (
    <Flex
      flexDirection="column"
      h="738px"
      w="full"
      p="3"
      px="4"
      borderRadius="3px"
      border="1px solid"
      borderColor="gray.athens"
      bg="white"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
    >
      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        h="40px"
      >
        <Flex w="full" alignItems="center">
          <Text fontSize="16px" fontWeight="600" color="black.medium">
            {t("notifications")}
          </Text>
        </Flex>

        {/*
        <Flex
          h="full"
          justifyContent="space-between"
          alignItems="center"
          gap="3"
        >
          <IconButton
            size="none"
            aria-label="Search"
            icon={<SearchDIcon width="30px" height="30px" fill="#2AABEE" />}
            width="30px"
            height="30px"
            cursor="pointer"
          />
          <IconButton
            size="none"
            aria-label="Filter"
            icon={<FilterDIcon width="30px" height="30px" fill="#2AABEE" />}
            width="30px"
            height="30px"
            cursor="pointer"
          />
          <IconButton
            size="none"
            aria-label="Refreshs"
            icon={<RefreshDIcon width="30px" height="30px" fill="#2AABEE" />}
            width="30px"
            height="30px"
            cursor="pointer"
            isLoading={isLoading || isRefetching}
            onClick={() => refetch()}
  />
        </Flex>
        */}
      </Flex>
      <Center>
        <Divider borderColor="gray.light" w="full" mt="1" />
      </Center>{" "}
      {Notifications?.length === 0 && (
        <Flex w="full" overflow="hidden" flexDir="column" alignItems="center">
          <Text fontSize="18px" color="black.active" mt="10">
            {t("page.notifications.empty-notifications")}
          </Text>
          <EmptyNotifications width="100%" height="fit-content" />
        </Flex>
      )}
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            autoHide: "scroll",
          },
        }}
      >
        <Flex flexDirection="column" w="full" height="full">
          {todaysNotifications.length !== 0 && (
            <Text
              color="gray.passive"
              fontSize="14px"
              fontWeight="600"
              mt="3"
              mb="2"
            >
              {t("today_b")}
            </Text>
          )}
          {todaysNotifications?.map((notification) => (
            <Box
              cursor="pointer"
              onClick={() => {
                handleClick(notification);
              }}
              key={notification.id}
            >
              <NotificationItem notification={notification} />
            </Box>
          ))}
          {yesterdaysNotifications.length !== 0 && (
            <Text
              color="gray.passive"
              fontSize="14px"
              fontWeight="600"
              mt="3"
              mb="2"
            >
              {t("yesterday")}
            </Text>
          )}
          {yesterdaysNotifications?.map((notification) => (
            <Box
              cursor="pointer"
              onClick={() => {
                handleClick(notification);
              }}
              key={notification.id}
            >
              <NotificationItem notification={notification} />
            </Box>
          ))}

          {thisMonthNotifications.length !== 0 && (
            <Text
              color="gray.passive"
              fontSize="14px"
              fontWeight="600"
              mt="3"
              mb="2"
            >
              {t("this_month")}
            </Text>
          )}
          {thisMonthNotifications?.map((notification) => (
            <Box
              key={notification.id}
              cursor="pointer"
              onClick={() => {
                handleClick(notification);
              }}
            >
              <NotificationItem notification={notification} />
            </Box>
          ))}
        </Flex>
      </OverlayScrollbarsComponent>
    </Flex>
  );
};
