import {
  Button,
  Flex,
  VStack,
  Heading,
  Text,
  FormControl,
  FormLabel,
  IconButton,
  Box,
  useSteps,
  useToast,
  Input,
  InputGroup,
  FormErrorMessage,
  InputRightElement,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import {
  useState,
  FC,
  useCallback,
  useEffect,
  ChangeEvent,
  useRef,
  FormEvent,
} from "react";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { UseFormReturn } from "react-hook-form";
import { resetPasswordSchema, setPasswordSchema } from ".";
import { useTranslation } from "react-i18next";
import { addMinutes, intervalToDuration, isAfter } from "date-fns";
import { useNavigate } from "react-router-dom";
import { BackCircleIcon } from "@/assets/icons/back-circle-icon";
import { ImmediateSetInterval } from "@/utils/misc";
import { i18n } from "@/i18n";

export const ForgotPasswordDesktop: FC<{
  handleVerificationCode: (verification_code: string) => Promise<boolean>;
  onSubmitResetPassword: () => void;
  onSubmitSetPassword: () => void;
  resetPasswordForm: UseFormReturn<resetPasswordSchema>;
  setPasswordForm: UseFormReturn<setPasswordSchema>;
}> = ({
  handleVerificationCode,
  onSubmitResetPassword,
  onSubmitSetPassword,
  resetPasswordForm,
  setPasswordForm,
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState<{
    pass: boolean;
    rePass: boolean;
  }>({
    pass: false,
    rePass: false,
  });
  const { activeStep, goToNext, goToPrevious } = useSteps({
    index: 1,
    count: 3,
  });
  const toast = useToast();
  const [duration, setDuration] = useState<{
    minutes: number;
    seconds: number;
    endDate: Date;
  }>({ minutes: 3, seconds: 0, endDate: new Date() });
  const { t } = useTranslation();

  const [pin, setPin] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
  const navigate = useNavigate();

  useEffect(() => {
    inputRefs.current[0]?.focus();
  }, []);

  const handleChange = (index: number, value: string) => {
    const newPin = [...pin];
    newPin[index] = value;

    setPin(newPin);

    if (value !== "" && index < pin.length - 1) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  useEffect(() => {
    if (activeStep !== 2) return;

    const timer = ImmediateSetInterval(() => {
      setDuration((duration) => {
        const difference = intervalToDuration({
          start: Date.now(),
          end: duration.endDate,
        });
        if (isAfter(Date.now(), duration.endDate)) {
          clearInterval(timer);
          return {
            ...duration,
            minutes: 0,
            seconds: 0,
          };
        }
        return {
          ...duration,
          minutes: difference.minutes ?? 0,
          seconds: difference.seconds ?? 0,
        };
      });
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, [activeStep]);

  const {
    register: resetPasswordRegister,
    getValues,
    getFieldState,
    formState: { errors: resetPasswordFormErrors },
  } = resetPasswordForm;

  const {
    register: setPasswordRegister,
    setValue,
    formState: { errors: setPasswordErrors },
  } = setPasswordForm;

  const handleSendButtonClick = useCallback(
    async (pin: string[]) => {
      if (!pin.every((value) => value != "")) {
        toast({
          description: i18n.t("errors.please-write-6-digits"),
          status: "error",
          isClosable: true,
        });
        return;
      }
      const success = await handleVerificationCode(
        pin.toString().replace(/,/g, "")
      );
      if (!success) return;
      setValue("verification_code", pin.toString().replace(/,/g, ""));
      goToNext();
    },
    [goToNext, setValue, toast, handleVerificationCode]
  );

  return (
    <>
      {activeStep === 1 && (
        <VStack
          flexDir="column"
          h="full"
          w="full"
          textAlign="center"
          bg="white"
        >
          <IconButton
            aria-label="back"
            icon={<BackCircleIcon />}
            variant="unstyled"
            alignSelf="flex-end"
            onClick={() => navigate("/auth/login")}
            mr="51px"
            mt="57px"
          />

          <VStack>
            <Heading
              mt="114px"
              alignSelf="center"
              lineHeight="60px"
              fontWeight="500"
              fontSize="48px"
            >
              {t("page.forgot-password.reset-password")}
            </Heading>

            <Flex
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mt="11px"
            >
              <Text
                color="blue.brand"
                fontSize={["16px", "16px", "20px", "20px"]}
                lineHeight="25px"
              >
                {t("page.forgot-password.remember-your-password")}
              </Text>
              <Text
                ml="8px"
                fontSize={["16px", "16px", "20px", "20px"]}
                fontWeight="600"
                color="red.main"
                lineHeight="25px"
                borderColor="red.main"
                borderBottom="1px"
              >
                <Link to="/auth/login"> {t("page.login.LOGIN")}</Link>
              </Text>
            </Flex>
          </VStack>

          <Box
            onSubmit={(e: FormEvent<HTMLDivElement>) => {
              onSubmitResetPassword();

              e.preventDefault();

              if (!getValues("email")) {
                toast({
                  description: i18n.t("errors.please-enter-a-valid-email"),
                  status: "error",
                  isClosable: true,
                });

                return;
              } else {
                setDuration((duration) => ({
                  ...duration,
                  endDate: addMinutes(new Date(), 3),
                }));

                setTimeout(() => {
                  if (getFieldState("email").invalid) {
                    return;
                  }
                  goToNext();
                }, 200);
              }
            }}
            as="form"
            w="60%"
            mt="45px"
            sx={{
              "& input": {
                color: "gray.passive",
              },
            }}
          >
            <FormControl isInvalid={!!resetPasswordFormErrors.email} w="full">
              <FormLabel
                pos="absolute"
                top="-10px"
                fontSize="xs"
                zIndex="9"
                ml="2.5"
                px="3"
                fontWeight="400"
                lineHeight="20px"
                pr="6"
                bg="white"
              >
                {t("input.email-address")}
              </FormLabel>

              <Input
                height="72px"
                type="text"
                {...resetPasswordRegister("email")}
              />

              <FormErrorMessage>
                {resetPasswordFormErrors.email?.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              height="60px"
              mt="34px"
              bg="blue.shiny"
              color="white"
              fontWeight="700"
              fontSize="20"
              type="submit"
              _hover={{
                opacity: 0.8,
              }}
              w="full"
            >
              {t("button.send-verification-code")}
            </Button>
          </Box>
        </VStack>
      )}

      {activeStep === 2 && (
        <VStack
          flexDir="column"
          h="full"
          w="full"
          textAlign="center"
          bg="white"
        >
          <IconButton
            aria-label="back"
            icon={<BackCircleIcon />}
            variant="unstyled"
            alignSelf="flex-end"
            onClick={() => {
              setPin(["", "", "", "", "", ""]);
              goToPrevious();
            }}
            mr="51px"
            mt="57px"
          />
          <VStack>
            <Heading
              mt="114px"
              alignSelf="center"
              lineHeight="60px"
              fontWeight="500"
              fontSize="48px"
            >
              {t("page.forgot-password.reset-password")}
            </Heading>

            <Flex
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mt="11px"
            >
              <Text
                color="blue.brand"
                fontSize={["16px", "16px", "20px", "20px"]}
                lineHeight="25px"
              >
                {t("page.forgot-password.remember-your-password")}
              </Text>
              <Text
                ml="8px"
                fontSize={["16px", "16px", "20px", "20px"]}
                fontWeight="600"
                color="red.main"
                lineHeight="25px"
              >
                <Link to="/auth/login"> {t("page.login.login")}</Link>
              </Text>
            </Flex>
          </VStack>

          <Box w="60%" mt="45px" position="relative">
            <FormLabel
              pos="absolute"
              top="-10px"
              fontSize="16px"
              color="black.active"
              zIndex="9"
              ml="2.5"
              px="4"
              fontWeight="400"
              lineHeight="20px"
              pr="8"
              bg="white"
            >
              {t("input.email-address")}
            </FormLabel>
            <Input
              disabled
              height="72px"
              type="text"
              {...resetPasswordRegister("email")}
            />

            <Text mt="34px" textAlign="start" fontSize="16px">
              {t("page.verification.verification-code")}
            </Text>
            <HStack w="full" justifyContent="space-between" mt="12px">
              {pin.map((value, index) => (
                <Input
                  key={index}
                  type="text"
                  value={value}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    handleChange(index, e.target.value)
                  }
                  maxLength={1}
                  // width="85px"
                  height="94px"
                  fontSize="lg"
                  textAlign="center"
                  id={`pin-input-${index}`}
                  ref={(input) => (inputRefs.current[index] = input)}
                />
              ))}
              {/* <PinInput
                placeholder=""
                size="lg"
                onChange={(value) => setPin(value)}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput> */}
            </HStack>
            {(duration.minutes !== 0 || duration.seconds !== 0) && (
              <Text
                color="#E52625"
                fontWeight="500"
                position="relative"
                top="3"
              >
                {("0" + duration.minutes.toString()).slice(-2)}:
                {("0" + duration.seconds.toString()).slice(-2)}
              </Text>
            )}

            <Button
              height="60px"
              mt="34px"
              bg="blue.shiny"
              color="white"
              fontWeight="700"
              fontSize="20"
              _hover={{
                opacity: 0.8,
              }}
              w="full"
              onClick={() => handleSendButtonClick(pin)}

              // onClick={() => {
              //   if (duration.minutes !== 0 && duration.seconds !== 0) {
              //     toast({
              //       description: "Please wait the timer to be over!",
              //       status: "error",
              //       isClosable: true,
              //     });
              //     return;
              //   }
              //   onSubmitResetPassword();
              //   setDuration(() => ({
              //     minutes: 3,
              //     seconds: 0,
              //     endDate: addMinutes(new Date(), 3),
              //   }));
              //   toast({
              //     description: "New code resent!",
              //     status: "success",
              //     isClosable: true,
              //   });
              // }}
            >
              {t("button.confirm-verification-code")}
            </Button>
            <Flex gap="24px" h="min-content" p="0" mt="24px">
              <Button
                onClick={() => {
                  if (duration.minutes !== 0 && duration.seconds !== 0) {
                    toast({
                      description: t("errors.please-wait-the-timer-to-be-over"),
                      status: "error",
                      isClosable: true,
                    });
                    return;
                  }
                  onSubmitResetPassword();
                  setDuration(() => ({
                    minutes: 3,
                    seconds: 0,
                    endDate: addMinutes(new Date(), 3),
                  }));
                }}
                height="60px"
                bg="blue.shiny"
                color="white"
                fontWeight="700"
                fontSize="20"
                _hover={{
                  opacity: 0.8,
                }}
                w="full"
              >
                {t("page.verification.resend")}
              </Button>
            </Flex>
          </Box>
        </VStack>
      )}
      {activeStep === 3 && (
        <VStack
          flexDir="column"
          h="full"
          w="full"
          textAlign="center"
          bg="white"
        >
          <IconButton
            aria-label="back"
            icon={<BackCircleIcon />}
            variant="unstyled"
            alignSelf="flex-end"
            onClick={goToPrevious}
            mr="51px"
            mt="57px"
          />
          <VStack>
            <Heading
              mt="114px"
              alignSelf="center"
              lineHeight="60px"
              fontWeight="500"
              fontSize="48px"
            >
              {t("page.forgot-password.reset-password")}
            </Heading>

            <Flex
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              mt="11px"
            >
              <Text
                color="blue.brand"
                fontSize={["16px", "16px", "20px", "20px"]}
                lineHeight="25px"
              >
                {t("page.forgot-password.remember-your-password")}
              </Text>
              <Text
                ml="8px"
                fontSize={["16px", "16px", "20px", "20px"]}
                fontWeight="600"
                color="red.main"
                lineHeight="25px"
                textDecor="underline"
              >
                <Link to="/auth/login"> {t("page.login.LOGIN")}</Link>
              </Text>
            </Flex>
          </VStack>

          <Box
            onSubmit={onSubmitSetPassword}
            as="form"
            w="60%"
            mt="45px"
            sx={{
              "& input": {
                color: "gray.passive",
              },
            }}
          >
            <FormControl isInvalid={!!setPasswordErrors.password} w="full">
              <FormLabel
                pos="absolute"
                top="-10px"
                fontSize="xs"
                zIndex="9"
                ml="2.5"
                px="3"
                fontWeight="400"
                lineHeight="20px"
                pr="6"
                bg="white"
              >
                {t("input.new-password")}
              </FormLabel>
              <InputGroup>
                <Input
                  height="72px"
                  type={passwordVisibility.pass ? "text" : "password"}
                  placeholder={t("input.placeholder.create_password")}
                  {...setPasswordRegister("password")}
                />
                <InputRightElement
                  h="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    size="xs"
                    variant="unstyled"
                    position="absolute"
                    right="12px"
                    aria-label="password"
                    onClick={() =>
                      setPasswordVisibility((prevVisibility) => ({
                        ...prevVisibility,
                        pass: !prevVisibility.pass,
                      }))
                    }
                    icon={
                      !passwordVisibility.pass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {setPasswordErrors.password?.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={!!setPasswordErrors.confirmPassword}
              w="full"
              mt="20px"
            >
              <FormLabel
                pos="absolute"
                top="-10px"
                fontSize="xs"
                zIndex="9"
                ml="2.5"
                px="3"
                fontWeight="400"
                lineHeight="20px"
                pr="6"
                bg="white"
              >
                {t("input.confirm_password")}
              </FormLabel>
              <InputGroup>
                <Input
                  height="72px"
                  type={passwordVisibility.rePass ? "text" : "password"}
                  placeholder={t("input.placeholder.confirm_password")}
                  {...setPasswordRegister("confirmPassword")}
                />
                <InputRightElement
                  h="full"
                  justifyContent="center"
                  alignItems="center"
                >
                  <IconButton
                    size="xs"
                    variant="unstyled"
                    position="absolute"
                    right="12px"
                    aria-label="password"
                    onClick={() =>
                      setPasswordVisibility((prevVisibility) => ({
                        ...prevVisibility,
                        rePass: !prevVisibility.rePass,
                      }))
                    }
                    icon={
                      !passwordVisibility.rePass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>
                {setPasswordErrors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>

            <Button
              height="60px"
              mt="34px"
              bg="blue.shiny"
              color="white"
              fontWeight="700"
              fontSize="20"
              type="submit"
              _hover={{
                opacity: 0.8,
              }}
              w="full"
            >
              {t("button.create")}
            </Button>
          </Box>
        </VStack>
      )}
    </>
  );
};
