import {
  Divider,
  Flex,
  FormControl,
  VStack,
  Text,
  Input,
  Box,
  Popover,
  Button,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  FormErrorMessage,
  IconButton,
} from "@chakra-ui/react";
import React, { FC, useCallback, useState } from "react";
import {
  Controller,
  useFormContext,
  useForm,
  SubmitHandler,
} from "react-hook-form";
import { youtubeSchema } from "../form-containers/youtube-form-container";
import { ContentEditor } from "../content-editor";
import { Dropzone } from "@/components/Dropzone";
import { StatusCard } from "./_status-card";
import { useFormStore } from "../form-store";
import { APISchemas, GET, POST } from "@/api";
import { SetToMainManipulator } from "./_content-manipulator";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useQuery } from "react-query";
import { RefreshCircleIcon } from "@/assets/icons/refresh-circle-icon";
import { AddPlaylistIcon } from "@/assets/icons/add-playlist-icon";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";
import { SingleChevronLeftIcon } from "@/assets/icons/single-chevron-left-icon";

type newPlaylistSchema = z.infer<typeof newPlaylistSchema>;

const newPlaylistSchema = z.object({
  title: z.string().min(1),
  description: z.string().min(1),
});

export const YoutubeFormPresent: FC<{
  channel?: APISchemas["Channel"];
  playlist: (e: string | undefined) => void;
}> = ({ channel, playlist }) => {
  const setProgressBlock = useFormStore((f) => f.setProgressBlock);
  const globalError = useFormStore((f) =>
    f.getGobalError(channel?.id.toString() ?? "")
  );

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<youtubeSchema>();

  const {
    register: registerNewPlaylist,
    handleSubmit: handleNewPlaylistSubmit,
    formState: { errors: newPlayListErrors },
  } = useForm<newPlaylistSchema>({
    resolver: zodResolver(newPlaylistSchema),
  });

  const organization = useAuthStore((s) => s.activeOrganization);
  const { t } = useTranslation();
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [privateList, setPrivate] = useState<boolean>(true);
  const [addPlaylist, setAddPlaylist] = useState<boolean>(false);
  const [choosenPlaylist, setChoosenPlaylist] = useState<string | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [refreshPlaylist, setRefreshPlaylsit] = useState<boolean>(false);

  const { data: Playlist = { data: [] }, refetch } = useQuery(
    ["playlists"],
    async () => {
      if (!channel) return;

      const { data } = await GET("/youtube/{id}/playlists/", {
        params: {
          path: {
            id: channel.id.toString(),
          },
          query: {
            refresh: refreshPlaylist,
          },
        },
      });
      return data;
    }
  );

  const RefreshPlaylist = () => {
    setRefreshPlaylsit(true);
    refetch();
  };

  const onSubmit: SubmitHandler<newPlaylistSchema> = (data) => {
    postPlaylistTitle(data);
    setAddPlaylist(false);
    RefreshPlaylist();
  };

  const postPlaylistTitle = useCallback(
    async (inputs: newPlaylistSchema) => {
      if (!channel || !organization) return;

      const { data } = await POST("/youtube/{org_pk}/playlist/", {
        params: {
          path: {
            org_pk: organization?.id?.toString(),
          },
        },
        body: {
          channel_id: channel.id.toString(),
          playlist_name: inputs.title,
          playlist_privacy_status: privateList ? "private" : "public",
          playlist_description: inputs.description,
          tags: [],
        },
      });
      return data;
    },
    [channel, organization, privateList]
  );

  const playlistPerPage = 4;
  const totalPages = Playlist?.data
    ? Math.ceil(Playlist.data.length / playlistPerPage)
    : 0;
  const startIndex = (currentPage - 1) * playlistPerPage;
  const endIndex = startIndex + playlistPerPage;
  const currentPageData = Playlist?.data?.slice(startIndex, endIndex);

  return (
    <>
      <FormControl pos="relative">
        <Box mb="2">
          <Text
            fontWeight="400"
            lineHeight="20px"
            color="black.active"
            fontSize="16px"
            px="2"
            w="min-content"
            whiteSpace="nowrap"
            bgColor="white"
            zIndex="5"
            borderColor="gray.smoke"
            position="relative"
            top="2"
            left="1"
          >
            {t("headline")}
          </Text>
          <Input
            w="full"
            p="3"
            h="45px"
            placeholder={t("input.placeholder.enter-your-headline")}
            {...register("headline")}
            _placeholder={{ color: "gray.smoke" }}
          />
        </Box>
      </FormControl>
      <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
        <Flex>
          <Box w="full">
            <Controller
              control={control}
              name="files"
              render={({ field }) => (
                <Dropzone
                  value={field.value ?? []}
                  onChange={field.onChange}
                  onBusy={setProgressBlock}
                  placeholder={t("add-video")}
                  mediaType="video"
                  limit={1}
                />
              )}
            />
          </Box>
          <Flex mt="4">
            <Popover isOpen={addPlaylist} onClose={() => setAddPlaylist(false)}>
              <PopoverTrigger>
                <Button
                  variant="unstyled"
                  position="relative"
                  bottom="1"
                  onClick={() => setAddPlaylist(true)}
                >
                  <AddPlaylistIcon />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                borderRadius="6px"
                px="1"
                width="220px"
                border="1px solid #C9C9C9"
                bgColor="white"
              >
                <PopoverBody p="2" zIndex="3" bgColor="white">
                  <Text fontSize="16px" color="gray.primary" py="1">
                    {t("add-new-list")}
                  </Text>
                  <Divider mb="1" />
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleNewPlaylistSubmit(onSubmit)();
                    }}
                  >
                    <FormControl w="full" isInvalid={!!newPlayListErrors.title}>
                      <Text
                        fontWeight="400"
                        lineHeight="20px"
                        color="black.active"
                        fontSize="14px"
                        position="relative"
                        top="2"
                        zIndex="5"
                        bgColor="white"
                        w="min-content"
                        whiteSpace="nowrap"
                        px="2"
                        left="1px"
                      >
                        {t("playlist-headline")}
                      </Text>
                      <Input
                        borderRadius="0px"
                        maxLength={30}
                        w="full"
                        pl="2"
                        borderColor="gray.smoke"
                        color="balck.active"
                        type="text"
                        _placeholder={{ color: "gray.primary" }}
                        // onChange={(e) => setPlaylistTitle(e.target.value)}
                        {...registerNewPlaylist("title")}
                      />
                      <FormErrorMessage>
                        {newPlayListErrors?.title?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      w="full"
                      isInvalid={!!newPlayListErrors.description}
                    >
                      <Text
                        fontWeight="400"
                        lineHeight="20px"
                        color="black.active"
                        fontSize="14px"
                        position="relative"
                        top="2"
                        zIndex="5"
                        bgColor="white"
                        w="min-content"
                        whiteSpace="nowrap"
                        px="2"
                        left="1px"
                      >
                        {t("description")}
                      </Text>
                      <Input
                        borderRadius="0px"
                        maxLength={50}
                        w="full"
                        pl="2"
                        borderColor="gray.smoke"
                        color="balck.active"
                        type="text"
                        _placeholder={{ color: "gray.primary" }}
                        // onChange={(e) => setPlaylistTitle(e.target.value)}
                        {...registerNewPlaylist("description")}
                      />
                      <FormErrorMessage>
                        {newPlayListErrors.description?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <Flex mt="3" mb="5">
                      <Flex alignItems="center" justifyContent="center" px="2">
                        <Box
                          width="18px"
                          height="18px"
                          borderRadius="full"
                          border="1.5px solid #33373D"
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          onClick={() => setPrivate(true)}
                          cursor="pointer"
                        >
                          {privateList && (
                            <Box
                              width="14px"
                              height="14px"
                              borderRadius="full"
                              bg="black.active"
                            />
                          )}
                        </Box>
                        <Text ml="5px" fontSize="14px" lineHeight="16px">
                          {t("private")}
                        </Text>
                      </Flex>
                      <Flex
                        ml="2"
                        alignItems="center"
                        justifyContent="center"
                        px="2"
                      >
                        <Box
                          width="18px"
                          height="18px"
                          borderRadius="full"
                          border="1.5px solid #33373D"
                          alignItems="center"
                          display="flex"
                          justifyContent="center"
                          onClick={() => setPrivate(false)}
                          cursor="pointer"
                        >
                          {!privateList && (
                            <Box
                              width="14px"
                              height="14px"
                              borderRadius="full"
                              bg="black.active"
                            />
                          )}
                        </Box>
                        <Text
                          ml="5px"
                          fontSize="14px"
                          lineHeight="16px"
                          whiteSpace="nowrap"
                        >
                          {t("public")}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex mt="6" alignItems="center" justifyContent="end">
                      <Button
                        w="60px"
                        h="30px"
                        bg="gray.smoke"
                        cursor="pointer"
                        borderRadius="4px"
                        color="white"
                        type="button"
                        onClick={() => setAddPlaylist(false)}
                        _hover={{
                          opacity: "0.6",
                        }}
                        mr="2"
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Text
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="14px"
                          >
                            {t("cancel")}
                          </Text>
                        </Flex>
                      </Button>
                      <Button
                        w="60px"
                        h="30px"
                        bg="blue.shiny"
                        borderRadius="4px"
                        color="white"
                        cursor="pointer"
                        type="submit"
                        //onClick={() => handleNewPlaylistSubmit(onSubmit)()}
                        //   onClick={() => postPlaylistTitle()}
                        _hover={{
                          opacity: "0.6",
                        }}
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Text
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="14px"
                          >
                            {t("create")}
                          </Text>
                        </Flex>
                      </Button>
                    </Flex>
                  </form>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Popover>
              <PopoverTrigger>
                <Button
                  ml="4"
                  px="2"
                  width="220px"
                  h="32px"
                  bg="white"
                  borderRadius="6px"
                  border="1px solid #C9C9C9"
                  onClick={() => setPopoverOpen(!popoverOpen)}
                >
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    w="full"
                    overflow="hidden"
                  >
                    <Text
                      textAlign="center"
                      as="span"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontSize="14px"
                      color="black.active"
                      fontWeight="500"
                    >
                      {choosenPlaylist || t("choose-playlist")}
                    </Text>
                    <Flex alignItems="center" justifyContent="center">
                      {popoverOpen ? (
                        choosenPlaylist ? (
                          <Flex alignItems="center" justifyContent="center">
                            <Box
                              width="3"
                              height="3"
                              top="2px"
                              position="relative"
                              mr="3"
                              cursor="pointer"
                              onClick={RefreshPlaylist}
                            >
                              <RefreshCircleIcon fill="#57AAEB" />
                            </Box>
                            <Box
                              width="3"
                              height="3"
                              position="relative"
                              top="1px"
                              mr="2"
                              onClick={() => {
                                setChoosenPlaylist(undefined);
                                playlist(undefined);
                              }}
                              cursor="pointer"
                            >
                              <BlackCrossIcon width="full" height="full" />
                            </Box>
                          </Flex>
                        ) : (
                          <Box
                            width="3"
                            height="3"
                            position="relative"
                            mr="2"
                            top="2px"
                            cursor="pointer"
                            onClick={RefreshPlaylist}
                          >
                            <RefreshCircleIcon fill="#57AAEB" />
                          </Box>
                        )
                      ) : !choosenPlaylist ? (
                        <></>
                      ) : (
                        <Box
                          width="3"
                          height="3"
                          position="relative"
                          mr="2"
                          top="1px"
                          onClick={() => {
                            setChoosenPlaylist(undefined);
                            playlist(undefined);
                          }}
                          cursor="pointer"
                        >
                          <BlackCrossIcon width="full" height="full" />
                        </Box>
                      )}

                      {popoverOpen ? (
                        <Box>
                          <ChevronUpIcon color="black.active" />
                        </Box>
                      ) : (
                        <Box>
                          <ChevronDownIcon color="black.active" />
                        </Box>
                      )}
                    </Flex>
                  </Flex>
                </Button>
              </PopoverTrigger>
              {Playlist && (
                <PopoverContent
                  borderRadius="6px"
                  width="220px"
                  border="1px solid #C9C9C9"
                  zIndex="3"
                  bgColor="white"
                >
                  <PopoverBody p="0px">
                    {currentPageData?.map((item, index) => (
                      <React.Fragment key={index}>
                        <Flex
                          py="2"
                          alignItems="center"
                          cursor="pointer"
                          _hover={{
                            opacity: 0.8,
                            bgColor: "blue.shiny",
                          }}
                        >
                          <Text
                            pl="2"
                            fontSize="14px"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            overflow="hidden"
                            w="full"
                            onClick={() => {
                              setChoosenPlaylist(item.title);
                              playlist(item.playlist_id);
                            }}
                          >
                            {item.title}
                          </Text>
                          <Flex
                            alignItems="center"
                            justifyContent="center"
                            position="relative"
                            w="20"
                            mr="1"
                          >
                            <Flex
                              borderRadius="15px"
                              bgColor="gray.smoke"
                              w="16"
                              alignItems="center"
                              justifyContent="center"
                            >
                              <Text
                                p="3px"
                                fontSize="12px"
                                color="black.active"
                                fontWeight="500"
                              >
                                {item.privacy_status}
                              </Text>
                            </Flex>
                          </Flex>
                        </Flex>
                        {index !== Playlist?.data.length - 1 && <Divider />}
                      </React.Fragment>
                    ))}
                    {totalPages > 1 && (
                      <Flex alignItems="center" justifyContent="center">
                        <IconButton
                          position="relative"
                          top="2px"
                          disabled={currentPage === 1}
                          onClick={() => {
                            currentPage > 1 && setCurrentPage(currentPage - 1);
                          }}
                          variant="unstyled"
                          icon={
                            <SingleChevronLeftIcon
                              width="25px"
                              height="25px"
                              active={currentPage !== 1 ? true : false}
                            />
                          }
                          aria-label="icon"
                        />
                        <IconButton
                          position="relative"
                          top="3px"
                          disabled={currentPage === totalPages}
                          onClick={() => {
                            currentPage !== totalPages &&
                              setCurrentPage(currentPage + 1);
                          }}
                          variant="unstyled"
                          icon={
                            <SingleChevronLeftIcon
                              width="25px"
                              height="25px"
                              active={currentPage !== totalPages ? true : false}
                            />
                          }
                          aria-label="icon"
                          style={{ transform: "rotate(180deg)" }}
                        />
                      </Flex>
                    )}{" "}
                  </PopoverBody>
                </PopoverContent>
              )}
            </Popover>
          </Flex>
        </Flex>

        <Divider borderColor="#B7BABF" />
        <FormControl pos="relative">
          <ContentEditor {...register("content")} type="sm" />
          <Flex w="full">
            <Flex justifyContent="flex-end" w="full">
              {channel && <SetToMainManipulator channel={channel} />}
            </Flex>
          </Flex>
        </FormControl>

        <VStack rowGap="2" mb="3">
          {(Object.keys(errors) as Array<"root" | "content" | "files">).map(
            (key) => (
              <StatusCard
                key={key}
                title={key === "root" ? undefined : key}
                description={errors[key]?.message}
              />
            )
          )}
          <StatusCard
            title={globalError?.field}
            description={globalError?.message}
          />
        </VStack>
      </Box>
    </>
  );
};
