import { Flex, Menu, MenuButton, Text, Icon } from "@chakra-ui/react";
import { ReactElement, useCallback, useState } from "react";
import { useNavigate, useMatch } from "react-router-dom";

export const SidebarItem = ({
  navIcon,
  title,
  navigateTo,
  onClick,
  boxSize = "16px",
  id,
}: {
  navIcon: ReactElement;
  title?: string;
  navigateTo?: string;
  onClick?: () => void;
  boxSize?: string;
  id?: string;
}) => {
  const navigate = useNavigate();
  const [hover, setHover] = useState<boolean>(false);
  const match = useMatch({
    path: navigateTo || "",
    end: navigateTo === "/" ? true : false,
  });

  const handleClick = useCallback(() => {
    if (!navigateTo) {
      return;
    }

    if (navigateTo) {
      navigate(navigateTo);
    }
  }, [navigate, navigateTo]);

  return (
    <Menu>
      <MenuButton
        onClick={!onClick ? handleClick : onClick}
        w="full"
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        _hover={{
          textDecor: "none",
          backgroundColor: "blue.shiny",
          color: "white",
        }}
        color="black.active"
        px={title ? "40px" : "25px"}
        py="13px"
        justifyContent="center"
        alignItems="center"
      >
        <Flex opacity={match || hover || id === "active" ? "1" : "0.50"}>
          <Icon
            opacity={match || hover || id === "active" ? "1" : "0.50"}
            boxSize={boxSize}
            sx={{
              "&>svg": {
                fill: hover ? "white" : "black.active",
              },
            }}
          >
            {navIcon}
          </Icon>
          {title && (
            <Text
              ml="20px"
              fontSize="16px"
              fontWeight="600"
              lineHeight="1"
              whiteSpace="nowrap"
            >
              {title}
            </Text>
          )}
        </Flex>
      </MenuButton>
    </Menu>
  );
};
