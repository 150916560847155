import { APISchemas, GET, DELETE } from "@/api";
import { ChevronLeftIcon } from "@/assets/icons/checron-left";
import { PostCard } from "./PostCard";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { PostModal } from "../Homepage/post-modal";

import {
  Box,
  Text,
  VStack,
  Flex,
  IconButton,
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Grid,
  GridItem,
  Show,
  PlacementWithLogical,
  useToast,
} from "@chakra-ui/react";
import {
  addDays,
  addHours,
  addMonths,
  addWeeks,
  startOfDay,
  startOfHour,
  startOfMonth,
  parseISO,
  getWeeksInMonth,
  endOfMonth,
  isAfter,
  getDay,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
  isBefore,
} from "date-fns";

import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useQuery } from "react-query";
import { useConfigStore } from "@/context/config-store/config-store";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useNotification } from "@/hooks/use-notification";
import { VideoPlayIcon } from "@/assets/icons/video-play-icon";
import { ImageIcon } from "@/assets/icons/image-icon";
import { TextIcon } from "@/assets/icons/text-icon";
import { CircleAddIcon } from "@/assets/icons/circle-add-icon";
import { useNavigate } from "react-router-dom";
import { ListCalendarIcon } from "@/assets/icons/list-calendar-icon";
import { useAuthStore } from "@/context/auth-store/auth-store";

export const Calendar: FC = () => {
  const [calendarMode, setCalendarMode] = useState<"weekly" | "monthly">(
    "monthly"
  );
  const [viewType, setViewType] = useState<"calendar" | "list">("calendar");

  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [modalItem, setModalItem] = useState<APISchemas["Compose"]>();
  const [today, setToday] = useState<Date>(startOfDay(new Date()));

  const indexRef = useRef<number>(0);

  const { organization } = useAuthStore((state) => ({
    organization: state.activeOrganization,
    setActiveOrg: state.setActiveOrganization,
  }));

  const clockNotation = useConfigStore((c) => c.clockNotation);
  const notifications = useNotification();
  const {
    data: composes,
    refetch: refetchCompose,
    isLoading,
  } = useQuery(
    ["compose", today.toISOString(), calendarMode, organization?.id],
    async () => {
      if (!organization) {
        return;
      }
      const { data: Composes } = await GET("/common/{org_pk}/compose/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
          },
          query: {
            start_date: i18nformat(
              calendarMode === "monthly"
                ? startOfMonth(today)
                : addDays(startOfWeek(today), 1),
              "dd-MM-yyyy"
            ),
            end_date: i18nformat(
              calendarMode === "monthly"
                ? addDays(endOfMonth(today), 1)
                : addDays(endOfWeek(today), 1),
              "dd-MM-yyyy"
            ),
          },
        },
      });

      const comp = Promise.all(
        Composes?.map(async (d) => {
          if (d.body || d.media.length > 0) {
            return d;
          }
          const posts = d.twitter_posts
            .concat(d.facebook_posts)
            .concat(d.instagram_posts)
            .concat(d.linkedin_posts)
            .concat(d.telegram_posts)
            .concat(d.tiktok_posts)
            .concat(d.youtube_posts);

          const existingPost = posts.at(0);
          if (existingPost) {
            d.body = existingPost.body;
            d.media = existingPost.media;

            return d;
          }

          return d;
        }) ?? []
      );

      return comp;
    }
  );

  useEffect(() => {
    refetchCompose();
  }, [refetchCompose]);

  const handleDelete = useCallback(
    async (id: number) => {
      if (!organization) {
        return;
      }

      const { data, error } = await DELETE("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
            id: id,
          },
        },
      });

      if (error) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });

        return;
      }
      toast({
        title: t("alert.success.post-deleted-successfully"),
        status: "success",
      });

      refetchCompose();
      return data;
    },
    [organization, refetchCompose, toast, t]
  );

  const { composeByHour, composeByDay } = useMemo(() => {
    if (!composes)
      return {
        composeByHour: {},
        countByDay: {},
        composeByDay: {},
      };

    const byHour: Record<string, Array<(typeof composes)[number]>> = {};
    const countByDay: Record<string, number> = {};
    const byDay: Record<string, Array<(typeof composes)[number]>> = {};

    composes.forEach((item) => {
      const startHour = startOfHour(
        new Date(item.scheduled_at ?? item.updated_at ?? item.created_at)
      );
      const day = startOfDay(startHour).toISOString();
      const hourKey = startHour.toISOString();
      if (!byHour[hourKey]) {
        byHour[hourKey] = [];
      }
      byHour[hourKey].push(item);

      const dayKey = day;
      if (!byDay[dayKey]) {
        byDay[dayKey] = [];
      }
      byDay[dayKey].push(item);
    });

    const sortedByDay: typeof byDay = {};

    const keys = Object.keys(byDay).sort((a, b) => {
      return isBefore(parseISO(a), parseISO(b)) ? -1 : 1;
    });

    keys.forEach((key) => {
      sortedByDay[key] = byDay[key];
    });

    return {
      composeByHour: byHour,
      countByDay,
      composeByDay: sortedByDay,
    } as const;
  }, [composes]);

  useEffect(() => {
    refetchCompose();
  }, [notifications, refetchCompose]);

  indexRef.current = 0;

  return (
    <Box userSelect="none">
      <Box bg="white.bg" px="3" w="full">
        <Grid
          py="5"
          bg="white.bg"
          boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
          templateColumns="repeat(3,1fr)"
          rounded="6px"
          mb="20px"
          px="21px"
        >
          <GridItem>
            <Flex
              justifyContent="center"
              alignItems="center"
              w="300px"
              h="48px"
            >
              <Box alignItems="flex-start">
                <IconButton
                  variant="unstyled"
                  aria-label="previous-week"
                  size="none"
                  onClick={() => {
                    calendarMode === "weekly"
                      ? setToday(addWeeks(today, -1))
                      : setToday(addMonths(today, -1));
                  }}
                >
                  <ChevronLeftIcon />
                </IconButton>
              </Box>
              <Box w="270px" textAlign="center" justifyContent="center">
                <Text
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="500"
                  fontSize="20px"
                >
                  <Text as="span" fontWeight="700" fontSize="20px">
                    {i18nformat(today, "MMMM")}
                  </Text>
                  {calendarMode == "weekly"
                    ? ` ${i18nformat(
                        addDays(startOfWeek(today), 1),
                        "dd"
                      )}-${i18nformat(addDays(endOfWeek(today), 1), "dd , y")}`
                    : ` ${i18nformat(today, "y")}`}
                </Text>
              </Box>

              <IconButton
                variant="unstyled"
                aria-label="next-week"
                size="none"
                onClick={() => {
                  calendarMode === "weekly"
                    ? setToday(addWeeks(today, 1))
                    : setToday(addMonths(today, 1));
                }}
              >
                <ChevronLeftIcon
                  style={{
                    transform: "rotate(180deg)",
                  }}
                />
              </IconButton>
            </Flex>
          </GridItem>
          <GridItem />

          <GridItem>
            <Flex gap="17px" justifyContent="flex-end">
              <Flex
                borderRadius="3px"
                h="48px"
                border="1px solid"
                borderColor="gray.athens"
                alignItems="center"
                justifyContent="center"
                px="21px"
                fontWeight="600"
              >
                <Box onClick={() => setCalendarMode("weekly")} cursor="pointer">
                  <Text
                    color={
                      calendarMode == "weekly" ? "blue.brand" : "gray.passive"
                    }
                    fontSize="20px"
                    mr="4"
                  >
                    {t("weekly")}
                  </Text>
                </Box>
                <Box
                  onClick={() => setCalendarMode("monthly")}
                  cursor="pointer"
                >
                  <Text
                    color={
                      calendarMode == "monthly" ? "blue.brand" : "gray.passive"
                    }
                    fontSize="20px"
                    ml="4"
                  >
                    {t("monthly")}
                  </Text>
                </Box>
              </Flex>
              <Flex
                borderRadius="3px"
                alignItems="center"
                justifyContent="center"
              >
                <IconButton
                  aria-label="toggle-view"
                  variant="unstyled"
                  size="none"
                  border="1px solid"
                  borderColor="gray.athens"
                  bg="gray.bg"
                  rounded="full"
                  w="48px"
                  h="48px"
                  transform={viewType === "list" ? "rotate(90deg)" : undefined}
                  onClick={() => {
                    return viewType === "list"
                      ? setViewType("calendar")
                      : setViewType("list");
                  }}
                >
                  <Flex justifyContent="center">
                    <ListCalendarIcon />
                  </Flex>
                </IconButton>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
        <OverlayScrollbarsComponent
          options={{
            overflow: {
              x: "hidden",
              y: "scroll",
            },
            scrollbars: {
              autoHide: "scroll",
            },
          }}
          style={{
            height: "70vh",
          }}
        >
          <Box>
            {viewType === "list" ? (
              <VStack minH="60vw">
                {!isLoading && Object.entries(composeByDay).length === 0 && (
                  <Flex
                    justifyContent="center"
                    alignItems="center"
                    w="full"
                    h="full"
                  >
                    <Text color="black.dark" fontSize="20px">
                      {t("no-post")}
                    </Text>
                  </Flex>
                )}
                {Object.entries(composeByDay).map(([day, composes]) => {
                  return (
                    <Box key={day} w="full">
                      <Flex
                        px="21px"
                        pos="sticky"
                        top="0"
                        zIndex="2"
                        py="9px"
                        justifyContent="space-between"
                        alignItems="center"
                        boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
                        border="1px solid"
                        borderColor="gray.athens"
                        bg="gray.bg"
                        rounded="6px"
                      >
                        <Text
                          color="black.active"
                          fontWeight="extrabold"
                          fontSize="20px"
                          lineHeight="1"
                        >
                          {i18nformat(parseISO(day), "MMM dd,yyyy")}
                        </Text>
                        <Text
                          color="black.active"
                          fontWeight="extrabold"
                          fontSize="20px"
                          lineHeight="1"
                        >
                          {i18nformat(parseISO(day), "EEEE")}
                        </Text>
                      </Flex>
                      <Flex flexDir="column" gap="14px">
                        {composes
                          .sort((a, b) =>
                            isBefore(
                              parseISO(a.scheduled_at ?? ""),
                              parseISO(b.scheduled_at ?? "")
                            )
                              ? -1
                              : 1
                          )
                          .map((compose) => {
                            return (
                              <Box
                                key={compose.id}
                                boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
                                //fontSize="24px"
                              >
                                <SimplePostCard
                                  compose={compose}
                                  setModalItem={setModalItem}
                                  setModalOpen={setModalOpen}
                                  placement="bottom"
                                  bg="gray.soft"
                                  showIcons="sm"
                                />
                              </Box>
                            );
                          })}
                      </Flex>
                    </Box>
                  );
                })}
              </VStack>
            ) : (
              <Flex flexDir="column">
                <Grid
                  templateColumns="repeat(7, 1fr)"
                  bg="white"
                  boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
                  borderRadius="3px"
                  border="1px solid"
                  borderColor="gray.athens"
                  mb="-0.5"
                  zIndex="1"
                  ml={calendarMode === "weekly" ? "53px" : undefined}
                  position="sticky"
                  top="0"
                >
                  <>
                    {calendarMode === "weekly" ? (
                      <>
                        {Array(7)
                          .fill(0)
                          .map((_, i) => {
                            const target = addDays(startOfWeek(today), i + 1);

                            return (
                              <React.Fragment key={target.toISOString()}>
                                <GridItem
                                  textTransform="capitalize"
                                  textColor="black.dark"
                                  fontWeight="extrabold"
                                  fontSize="16px"
                                >
                                  <VStack>
                                    <Text fontSize="1rem">
                                      {i18nformat(target, "E dd")}
                                    </Text>
                                  </VStack>
                                </GridItem>
                              </React.Fragment>
                            );
                          })}
                      </>
                    ) : (
                      eachDayOfInterval({
                        start: addDays(startOfWeek(today), 1),
                        end: addDays(endOfWeek(today), 1),
                      })
                        .map((d) => i18nformat(d, "E"))
                        .map((title, i) => (
                          <GridItem
                            textTransform="capitalize"
                            textColor="black.dark"
                            key={"th" + i}
                            fontWeight="600"
                          >
                            <VStack>
                              <Text fontSize="1rem">{title}</Text>
                            </VStack>
                          </GridItem>
                        ))
                    )}
                  </>
                </Grid>

                {calendarMode == "weekly"
                  ? Array(24)
                      .fill(0)
                      .map((_, i) => {
                        const hour = addHours(
                          addDays(startOfWeek(today), 1),
                          i
                        );

                        return (
                          <Grid
                            key={hour.toISOString() + i}
                            templateColumns="repeat(7,1fr)"
                            ml="53px"
                            pos="relative"
                          >
                            <Flex
                              alignSelf="stretch"
                              textAlign="center"
                              w="53px"
                              pos="absolute"
                              left="-48px"
                              top={i === 0 ? "-28px" : undefined}
                              zIndex="2"
                              h={i === 0 ? "calc(100% + 28px)" : "100%"}
                              roundedTopLeft={i === 0 ? "3px" : undefined}
                              roundedBottomLeft={i === 23 ? "3px" : undefined}
                              alignItems="center"
                              justifyContent="center"
                              bg="white"
                              border="3px solid #F2F2F2"
                              boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
                            >
                              <Text
                                color="black.dark"
                                fontSize="14px"
                                fontWeight="bold"
                              >
                                {i18nformat(
                                  hour,
                                  clockNotation === "12" ? "hh:mm a" : "HH:mm"
                                )}
                              </Text>
                            </Flex>

                            {Array(7)
                              .fill(0)
                              .map((_, i) => {
                                const day = addDays(hour, i);

                                return (
                                  <GridItem
                                    key={hour.toISOString() + i}
                                    id={hour.toISOString() + i}
                                    sx={{
                                      "& > div": {
                                        w: "full",
                                        minH: "132px",
                                        h: "100%",
                                        py: "3",
                                        bg: "white",
                                        border: "3px solid #F2F2F2",
                                        boxShadow:
                                          "0px 0px 5px 2px rgba(52, 90, 121, 0.03)",
                                      },
                                    }}
                                  >
                                    <Flex
                                      flexDir="column"
                                      rowGap="2"
                                      alignItems="center"
                                      justifyContent="start"
                                      pos="relative"
                                    >
                                      {!isBefore(
                                        day,
                                        addHours(Date.now(), -1)
                                      ) && (
                                        <IconButton
                                          pos="absolute"
                                          right="9px"
                                          top="9px"
                                          w="14px"
                                          h="14px"
                                          variant="unstyled"
                                          size="none"
                                          aria-label="add-post"
                                          onClick={() =>
                                            navigate(
                                              `/post?schedule=${day.toISOString()}`
                                            )
                                          }
                                        >
                                          <CircleAddIcon />
                                        </IconButton>
                                      )}
                                      <Box mt="3" />
                                      {composeByHour?.[day.toISOString()]
                                        ?.sort((a, b) =>
                                          isBefore(
                                            parseISO(a.scheduled_at ?? ""),
                                            parseISO(b.scheduled_at ?? "")
                                          )
                                            ? -1
                                            : 1
                                        )
                                        ?.map((compose) => {
                                          return (
                                            <React.Fragment key={compose.id}>
                                              <SimplePostCard
                                                compose={compose}
                                                setModalItem={setModalItem}
                                                setModalOpen={setModalOpen}
                                              />
                                            </React.Fragment>
                                          );
                                        })}
                                    </Flex>
                                  </GridItem>
                                );
                              })}
                          </Grid>
                        );
                      })
                  : Array(getWeeksInMonth(today))
                      .fill(0)
                      .map((_, i) => {
                        const month = startOfMonth(today);
                        const dayOfWeek = getDay(month);
                        return (
                          <Grid
                            templateColumns="repeat(7, 1fr)"
                            key={addWeeks(month, i).toISOString()}
                            sx={{
                              "& > div": {
                                w: "full",
                                minH: "132px",
                                pb: "3",
                                bg: "white",
                                border: "3px solid #F2F2F2",
                                boxShadow:
                                  "0px 0px 5px 2px rgba(52, 90, 121, 0.03)",
                              },
                            }}
                          >
                            {Array(7)
                              .fill(0)
                              .map((_, k) => {
                                if (i === 0 && k < dayOfWeek - 1) {
                                  return (
                                    <Box
                                      key={i + k + dayOfWeek}
                                      bg="blue.brand-light !important"
                                    />
                                  );
                                }

                                const day = addDays(month, indexRef.current);
                                indexRef.current += 1;

                                if (isAfter(day, endOfMonth(today))) {
                                  return (
                                    <Box
                                      p="0"
                                      bg="blue.brand-light !important"
                                      key={
                                        day.toISOString() + month.toISOString()
                                      }
                                    ></Box>
                                  );
                                }
                                return (
                                  <Box
                                    p="0"
                                    key={
                                      day.toISOString() + month.toISOString()
                                    }
                                    pos="relative"
                                    verticalAlign="top"
                                  >
                                    <Text
                                      pos="absolute"
                                      top="10px"
                                      left="9px"
                                      lineHeight="1"
                                      fontSize="11px"
                                      fontWeight="600"
                                      color="black.dark"
                                    >
                                      {i18nformat(day, "dd")}
                                    </Text>
                                    {!isBefore(
                                      day,
                                      addDays(Date.now(), -1)
                                    ) && (
                                      <IconButton
                                        pos="absolute"
                                        right="9px"
                                        top="9px"
                                        w="14px"
                                        h="14px"
                                        variant="unstyled"
                                        size="none"
                                        aria-label="add-post"
                                        onClick={() =>
                                          navigate(
                                            `/post?schedule=${day.toISOString()}`
                                          )
                                        }
                                      >
                                        <CircleAddIcon />
                                      </IconButton>
                                    )}
                                    <Flex
                                      mt="32px"
                                      flexDir="column"
                                      rowGap="1"
                                      alignItems="center"
                                    >
                                      {composeByDay?.[day.toISOString()]
                                        ?.sort((a, b) =>
                                          isBefore(
                                            parseISO(a.scheduled_at ?? ""),
                                            parseISO(b.scheduled_at ?? "")
                                          )
                                            ? -1
                                            : 1
                                        )
                                        .map((compose) => {
                                          return (
                                            <React.Fragment key={compose.id}>
                                              <SimplePostCard
                                                compose={compose}
                                                setModalItem={setModalItem}
                                                setModalOpen={setModalOpen}
                                              />
                                            </React.Fragment>
                                          );
                                        })}
                                    </Flex>
                                  </Box>
                                );
                              })}
                          </Grid>
                        );
                      })}
              </Flex>
            )}
          </Box>
          {!!modalItem && (
            <PostModal
              onDelete={(id) => handleDelete(id)}
              data={modalItem}
              isOpen={modalOpen}
              close={() => setModalOpen(false)}
            />
          )}
        </OverlayScrollbarsComponent>
      </Box>
    </Box>
  );
};

export const SimplePostCard: FC<{
  compose: APISchemas["Compose"];
  setModalItem: (item: APISchemas["Compose"]) => void;
  setModalOpen: (open: boolean) => void;
  placement?: PlacementWithLogical;
  showIcons?: "sm" | "md" | "lg" | "xl" | "2xl";
  bg?: "gray.soft" | "blue.brand-light";
}> = ({
  compose,
  setModalItem,
  setModalOpen,
  placement = "right",
  showIcons = "lg",
  bg = "blue.brand-light",
}) => {
  const clockNotation = useConfigStore((c) => c.clockNotation);
  const haveVideo = !!compose.media.find((m) => m.type === "video");
  const haveImage = !!compose.media.find((m) => m.type === "image");
  return (
    <Popover key={compose.id} trigger="hover" placement={placement} isLazy>
      <PopoverTrigger>
        <Button
          w="full"
          h="min-content"
          key={compose.id}
          variant="unstyled"
          onClick={() => {
            setModalItem(compose);
            setModalOpen(true);
          }}
          _focusVisible={{}}
          px="2"
        >
          <Flex
            key={compose.id}
            bg={bg}
            w="full"
            h="8"
            gap="2"
            alignItems="center"
            justifyContent="center"
            pos="relative"
            rounded="3px"
          >
            <Show above={showIcons}>
              <Box left="1" w="full" pos="absolute">
                {haveImage ? (
                  <ImageIcon />
                ) : haveVideo ? (
                  <VideoPlayIcon />
                ) : (
                  <TextIcon />
                )}
              </Box>
            </Show>
            {compose.scheduled_at && (
              <Text fontSize="16px" fontWeight="600">
                {i18nformat(
                  parseISO(compose.scheduled_at),
                  clockNotation === "12" ? "hh:mm a" : "HH:mm"
                )}
              </Text>
            )}

            {compose.status === "draft" && (
              <Show above={showIcons}>
                <Box
                  position="absolute"
                  bgColor="yellow.label"
                  width="10px"
                  height="10px"
                  rounded="full"
                  right="2"
                />
              </Show>
            )}
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent p="0" w="min-content" m="0">
        <PopoverBody p="0" w="min-content" m="0">
          <PostCard compose={compose} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
