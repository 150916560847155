import { FC } from "react";

export const Exclamation: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2352_13883)">
        <path
          d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
          fill="#50CD89"
        />
        <path
          d="M18 21.6C17.3481 21.6 16.7216 21.3477 16.2516 20.896C15.7815 20.4444 15.5045 19.8284 15.4786 19.177L15.146 10.7789C15.1326 10.4394 15.188 10.1008 15.3087 9.78322C15.4294 9.46566 15.6129 9.17573 15.8484 8.93083C16.0838 8.68593 16.3663 8.49109 16.6789 8.35799C16.9914 8.22489 17.3277 8.15627 17.6674 8.15625H18.3326C18.6723 8.15627 19.0085 8.22489 19.3211 8.35799C19.6336 8.49109 19.9161 8.68593 20.1516 8.93083C20.387 9.17573 20.5706 9.46566 20.6913 9.78322C20.812 10.1008 20.8673 10.4394 20.854 10.7789L20.5214 19.177C20.4954 19.8284 20.2184 20.4444 19.7484 20.896C19.2784 21.3477 18.6518 21.6 18 21.6Z"
          fill="white"
        />
        <path
          d="M20.5193 25.324C20.5193 23.9326 19.3914 22.8047 18 22.8047C16.6086 22.8047 15.4807 23.9326 15.4807 25.324C15.4807 26.7154 16.6086 27.8433 18 27.8433C19.3914 27.8433 20.5193 26.7154 20.5193 25.324Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2352_13883">
          <rect fill="white" width="36px" height="36px" />
        </clipPath>
      </defs>
    </svg>
  );
};
