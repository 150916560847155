import React, { FC } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  TooltipProps,
  ResponsiveContainer,
} from "recharts";
import { Box, Text, Flex } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface CustomTooltipProps extends TooltipProps<number, string> {
  active?: boolean;
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  const { t } = useTranslation();

  if (active && payload && payload.length) {
    return (
      <Box
        p="10px"
        bg="white"
        border="1px"
        borderColor="gray.300"
        boxShadow="md"
      >
        <Flex>
          <Text fontSize="14px" color="blue.shiny" fontWeight="600">
            {t("date")}:
          </Text>
          <Text
            ml="1"
            fontSize="14px"
            fontWeight="450"
            color="black.active"
          >{`${label}`}</Text>
        </Flex>

        <Flex>
          <Text fontSize="14px" color="blue.shiny" fontWeight="600">
            {t("activity")}:
          </Text>
          <Text
            ml="1"
            fontSize="14px"
            fontWeight="450"
            color="black.active"
          >{`${payload[0].value}`}</Text>
        </Flex>
      </Box>
    );
  }

  return null;
};

interface AnalyticsChartProps {
  data: {
    name: string;
    data: number;
  }[];
}

export const AnalyticsChart: FC<AnalyticsChartProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart height={60} data={data}>
        <CartesianGrid strokeDasharray="2 2" />
        <XAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="name"
          scale="point"
          padding={{ left: 0, right: 0 }}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <YAxis
          axisLine={{ stroke: "#B7BABF", strokeWidth: 1 }}
          dataKey="data"
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
        />
        <Tooltip content={<CustomTooltip />} />

        <Line
          type="monotone"
          dataKey="data"
          stroke="#57AAEC"
          strokeWidth={2}
          dot={false}
          activeDot={{ r: 5 }}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};
