import { GET } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useTriggerStore } from "@/context/trigger-store/trigger-store";
import { useEffect } from "react";
import { useQuery } from "react-query";

export const useOrganization = () => {
  const [activeOrganization, setActiveOrganization] = useAuthStore((s) => [
    s.activeOrganization,
    s.setActiveOrganization,
  ]);
  const organizationChanges = useTriggerStore(
    (state) => state.organizationChanges
  );

  const { data: OrganizationList, refetch } = useQuery(
    ["organizations", organizationChanges],
    async () => {
      const { data, error } = await GET("/org/", {});
      if (error) {
        console.error("organization data error", error);
      }

      const activeOrganizationCheck = activeOrganization
        ? data?.find((o) => o.id === activeOrganization.id)
        : data?.[0];
      if (activeOrganizationCheck) {
        setActiveOrganization(activeOrganizationCheck);
      }

      return data;
    }
  );

  useEffect(() => {
    refetch();
  }, [organizationChanges, refetch]);

  return { data: OrganizationList, refetch };
};
