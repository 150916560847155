export const RefreshBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="38"
        height="38"
        rx="2.5"
        fill="#FAFAFA"
        stroke="#EBEDF2"
      />
      <path
        d="M30.1817 10.173C29.6874 10.1279 29.2504 10.4921 29.2053 10.9862L29.0863 12.2922C27.0665 9.12019 23.5604 7 19.4978 7C15.1275 7 11.276 9.49432 9.36413 13.238C9.13843 13.6799 9.31373 14.2211 9.75565 14.4468C10.1977 14.6725 10.7388 14.4973 10.9645 14.0553C12.5826 10.887 15.8318 8.797 19.4978 8.797C22.8392 8.797 25.8547 10.537 27.5822 13.2629L26.3011 12.3479C25.8973 12.0595 25.3361 12.1531 25.0477 12.5569C24.7594 12.9607 24.8529 13.5218 25.2567 13.8102L29.1416 16.5848C29.7109 16.9907 30.4963 16.6191 30.5585 15.9353L30.9948 11.1494C31.0399 10.6552 30.6758 10.2181 30.1817 10.173Z"
        fill="#57AAEC"
      />
      <path
        d="M29.2429 22.5519C28.801 22.3262 28.2598 22.5015 28.0341 22.9434C26.416 26.1117 23.1667 28.2017 19.5007 28.2017C16.1593 28.2017 13.1439 26.4617 11.4164 23.7358L12.6975 24.6508C13.1013 24.9392 13.6624 24.8457 13.9508 24.4419C14.2392 24.038 14.1457 23.4769 13.7419 23.1885L9.85701 20.4139C9.2946 20.0121 8.50293 20.3725 8.44004 21.0635L8.00377 25.8493C7.95871 26.3435 8.32283 26.7806 8.81696 26.8257C9.31176 26.8706 9.74829 26.5062 9.79331 26.0124L9.91236 24.7065C11.9321 27.8785 15.4382 29.9987 19.5007 29.9987C23.8711 29.9987 27.7225 27.5044 29.6344 23.7607C29.8601 23.3188 29.6848 22.7776 29.2429 22.5519Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
