import { StandartFile } from "../common";

interface InstagramSchemaObject {
  id?: number;
  content?: string;
  files?: StandartFile[];
}

export const checkInstagramVideoDuration = (
  o: InstagramSchemaObject
): boolean => {
  const videoFiles = o?.files?.filter(
    (file) =>
      file.analyzed && file.analyzed.some((track) => track["@type"] === "Video")
  );

  if (videoFiles?.length === 0) return true;
  return (
    videoFiles?.every((file) =>
      file.analyzed?.some(
        (track) =>
          track["@type"] === "Video" &&
          typeof track.Duration === "number" &&
          track?.Duration <= 900
      )
    ) ?? false
  );
};

export const checkInstagramVideoSize = (o: InstagramSchemaObject): boolean => {
  const videoFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Video")
    ) ?? [];

  const imageFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Image")
    ) ?? [];

  const reelsVideos =
    imageFiles.length === 0 ? (videoFiles.length === 1 ? videoFiles : []) : [];

  const carouselVideos = videoFiles.filter(
    (file) => file?.analyzed?.length && file.analyzed?.length > 1
  );

  if (reelsVideos.length === 0 && carouselVideos.length === 0) return true;

  const reelsSizeCheck = reelsVideos.every((file) => {
    const fileSize = file.api.size;
    return fileSize <= 1 * 1024 * 1024 * 1024;
  });

  const carouselSizeCheck = carouselVideos.every((file) => {
    const fileSize = file.api.size;
    return fileSize <= 100 * 1024 * 1024;
  });

  return reelsSizeCheck && carouselSizeCheck;
};

export const checkCaruselReelsVideoDuration = (
  o: InstagramSchemaObject
): boolean => {
  const videoFiles =
    o.files?.filter(
      (file) =>
        file.analyzed && file.analyzed.some((track) => track.Type === "Video")
    ) ?? [];
  const imageFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Image")
    ) ?? [];

  const reelsVideos =
    imageFiles.length === 0 ? (videoFiles.length === 1 ? videoFiles : []) : [];

  const carouselVideos = videoFiles.filter(
    (file) => file?.analyzed?.length && file?.analyzed?.length > 1
  );

  if (reelsVideos.length === 0 && carouselVideos.length === 0) return true;

  const reelsDurationCheck = reelsVideos.every((file) => {
    const duration = file.analyzed?.[0]?.Duration;
    return duration !== undefined && duration <= 900 && duration >= 3;
  });

  const carouselDurationCheck = carouselVideos.every((file) => {
    const duration = file.analyzed?.[0]?.Duration;
    return duration !== undefined && duration <= 60 && duration >= 3;
  });

  return reelsDurationCheck && carouselDurationCheck;
};

export const checkInstagramVideoAspectRatio = (
  o: InstagramSchemaObject
): boolean => {
  const checkAspectRatio = (
    width: number | undefined,
    height: number | undefined,
    minRatio: number,
    maxRatio: number
  ): boolean => {
    if (!height || !width) return false;
    const calculatedRatio = width / height;
    return calculatedRatio >= minRatio && calculatedRatio <= maxRatio;
  };

  const videoFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Video")
    ) ?? [];
  const imageFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Image")
    ) ?? [];

  if (videoFiles.length === 0 && imageFiles.length === 0) return true;
  const reelsVideos =
    imageFiles.length === 0 ? (videoFiles.length === 1 ? videoFiles : []) : [];

  if (reelsVideos.length > 0) {
    return reelsVideos.every((file) => {
      const video = file.analyzed?.find((track) => track["@type"] === "Video");
      return checkAspectRatio(video?.Width, video?.Height, 0.01, 10);
    });
  }

  const carouselVideos = videoFiles.filter(
    (file) => file?.analyzed?.length && file.analyzed?.length > 1
  );

  return carouselVideos.every((file) => {
    const video = file.analyzed?.find((track) => track["@type"] === "Video");

    return checkAspectRatio(video?.Width, video?.Height, 4 / 5, 16 / 9);
  });
};
