import { Register } from "./Register";
import { Login } from "./Login";
import { Welcome } from "./Welcome";
import { Box, Grid, GridItem, Show } from "@chakra-ui/react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { Activate } from "./Activate";
import { ForgotPassword } from "./Forgot-Password";
import { EnterMail } from "./Register/enter-mail/enter-mail";
import { ConfirmMail } from "./Register/confirm-mail/confirm-mail";
import { useAuthStore } from "@/context/auth-store/auth-store";

export const Auth = () => {
  const navigate = useNavigate();

  //const py = useBreakpointValue({ base: 0, sm: 0, md: "0", lg: "5vh" });
  const auth = useAuthStore((s) => s.user);
  if (auth) {
    navigate("/");
  }

  return (
    <Grid
      templateColumns={{
        base: "repeat(1, 1fr)",
        lg: "35% 65%",
      }}
      w="100vw"
      h="100vh"
    >
      <Show above="lg">
        <GridItem>
          <Box h="100vh" w="full">
            <Welcome />
          </Box>
        </GridItem>
      </Show>
      <GridItem>
        <Box h="100dvh" w="full">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/activate" element={<Activate />} />
            <Route path="/confirm-mail" element={<ConfirmMail />} />
            <Route path="/verify-mail" element={<EnterMail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/enter-mail" element={<EnterMail />} />
            <Route path="/" element={<Navigate to="/auth/login" replace />} />
          </Routes>
        </Box>
      </GridItem>
    </Grid>
  );
};
