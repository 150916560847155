export const RefreshDIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="2.5"
        fill="#F7F8FA"
        stroke="#EBEDF2"
      />
      <path
        d="M14.6451 6.37951C14.4302 6.35992 14.2402 6.51822 14.2206 6.73307L14.1688 7.30084C13.2907 5.92178 11.7664 5 10.0001 5C8.10005 5 6.42557 6.08444 5.59434 7.71206C5.49621 7.9042 5.57243 8.1395 5.76456 8.23762C5.95673 8.33575 6.19199 8.25956 6.29012 8.06741C6.9936 6.68995 8.40627 5.78127 10.0001 5.78127C11.4528 5.78127 12.7638 6.53776 13.5149 7.72288L12.9579 7.32508C12.7824 7.19971 12.5384 7.24037 12.413 7.41592C12.2876 7.59149 12.3283 7.83545 12.5039 7.96084L14.1929 9.16714C14.4404 9.34361 14.7818 9.18206 14.8089 8.88473L14.9986 6.80401C15.0182 6.58914 14.8599 6.3991 14.6451 6.37951Z"
        fill={props.fill || "#33373D"}
      />
      <path
        d="M14.2357 11.7613C14.0435 11.6632 13.8082 11.7393 13.7101 11.9315C13.0066 13.3089 11.594 14.2176 10.0001 14.2176C8.5474 14.2176 7.23639 13.4611 6.48531 12.276L7.0423 12.6738C7.21787 12.7992 7.46182 12.7585 7.58721 12.583C7.71259 12.4074 7.67192 12.1634 7.49637 12.038L5.80736 10.8317C5.56285 10.6571 5.21866 10.8138 5.19131 11.1142L5.00164 13.1949C4.98205 13.4097 5.14035 13.5998 5.35518 13.6194C5.57031 13.6389 5.7601 13.4804 5.77967 13.2658L5.83143 12.698C6.70954 14.0771 8.23387 14.9989 10.0001 14.9989C11.9002 14.9989 13.5747 13.9144 14.4059 12.2868C14.504 12.0947 14.4278 11.8594 14.2357 11.7613Z"
        fill={props.fill || "#33373D"}
      />
    </svg>
  );
};
