import {
  Icon,
  Avatar,
  AvatarBadge,
  Tooltip,
  Text,
  Box,
  Button,
} from "@chakra-ui/react";
import {
  TwitterIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  FacebookIcon,
  TelegramIcon,
  YoutubeIcon,
  TiktokCircleIcon,
} from "@/assets/brands";
import { useNavigate } from "react-router-dom";
import { forwardRef, useCallback } from "react";

export interface AvatarWithBadgeProps {
  picture: string | undefined;
  channel: string | undefined;
  channel_id?: string;
  w?: string;
  h?: string;
  badgeSize?: string;
  tooltip?: boolean;
  addCompose?: boolean;
  email?: string;
  shadow?: string;
  name?: string;
  border?: string;
  borderColor?: string;
  status?: string | undefined;
  opacity?: string;

  onClick?: () => void;
}

export type Ref = HTMLButtonElement;

export const AvatarWithBadge = forwardRef<Ref, AvatarWithBadgeProps>(
  (
    {
      picture,
      channel,
      channel_id,
      w,
      h,
      name,
      shadow,
      badgeSize,
      tooltip,
      addCompose,
      opacity,
      email,
      status,
      onClick,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const onClickFn = useCallback(() => {
      if (addCompose) {
        navigate(`/post?channel=${channel_id}`);
      }

      if (onClick) {
        onClick();
      }
    }, [addCompose, channel_id, navigate, onClick]);

    return (
      <Tooltip
        label={
          tooltip && (
            <Box>
              {/* {addCompose && (
              <Text fontWeight="600" color="red.label" fontSize="14px">
                Add Compose
              </Text>
            )} */}
              <Text>{email}</Text>
            </Box>
          )
        }
        pos="relative"
        right="40px"
        bg="white"
        color="black"
        fontSize="13px"
        p="7px"
        borderRadius="4px"
        shadow={shadow || "0 0 10px rgba(0, 0, 0, 0.3)"}
      >
        <Button ref={ref} variant="unstyled" onClick={onClickFn}>
          <Avatar
            opacity={opacity}
            border={
              status === "active"
                ? "2px solid #50CD89"
                : status === "revoked"
                  ? "2px solid #E52625"
                  : status === "posted"
                    ? "2px solid #50CD89"
                    : status === "draft"
                      ? "2px solid #FBC531"
                      : status === "failed"
                        ? "2px solid #E52625"
                        : status === "neutral"
                          ? "1px solid white"
                          : "0.5px solid transparent"
            }
            name={name}
            w={w}
            h={h}
            cursor="pointer"
            shadow={shadow}
            src={picture}
          >
            {channel && (
              <AvatarBadge border="none" w={badgeSize} h={badgeSize}>
                <Icon rounded="full">
                  {channel.startsWith("twitter") ? (
                    <TwitterIcon width={badgeSize} height={badgeSize} />
                  ) : channel.startsWith("facebook") ? (
                    <FacebookIcon width={badgeSize} height={badgeSize} />
                  ) : channel.startsWith("instagram") ? (
                    <InstagramColorfulIcon
                      width={badgeSize}
                      height={badgeSize}
                    />
                  ) : channel.startsWith("tiktok") ? (
                    <TiktokCircleIcon width={badgeSize} height={badgeSize} />
                  ) : channel.startsWith("linkedin") ? (
                    <LinkedinIcon width={badgeSize} height={badgeSize} />
                  ) : channel.startsWith("telegram") ? (
                    <TelegramIcon
                      inverse={false}
                      width={badgeSize}
                      height={badgeSize}
                    />
                  ) : channel.startsWith("youtube") ? (
                    <YoutubeIcon width={badgeSize} height={badgeSize} />
                  ) : null}
                </Icon>
              </AvatarBadge>
            )}
          </Avatar>
        </Button>
      </Tooltip>
    );
  }
);
