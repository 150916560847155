import {
  IconButton,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { TermsCard } from "./terms-card";

export interface TermsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const TermsModal: React.FC<TermsModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        style={{
          width: "1092px",
          maxWidth: "100%",
          position: "relative",
        }}
      >
        <IconButton
          aria-label="Close modal"
          zIndex={1}
          icon={
            <svg
              width="37"
              height="38"
              viewBox="0 0 37 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="18.5" cy="18.5654" r="18.5" fill="#57AAEC" />
              <path
                d="M11.8108 27.9165C11.4734 27.9188 11.1388 27.8544 10.8263 27.7271C10.5138 27.5998 10.2295 27.412 9.9897 27.1746C9.51605 26.7003 9.25 26.0575 9.25 25.3872C9.25 24.7169 9.51605 24.0741 9.9897 23.5998L23.4793 10.4138C23.6935 10.1016 23.975 9.84148 24.3031 9.65263C24.6312 9.46377 24.9975 9.35098 25.375 9.32256C25.7526 9.29415 26.1316 9.35085 26.4843 9.48847C26.837 9.62609 27.1543 9.84112 27.4128 10.1177C27.6713 10.3943 27.8644 10.7254 27.9779 11.0866C28.0913 11.4477 28.1223 11.8298 28.0684 12.2045C28.0146 12.5792 27.8773 12.9371 27.6667 13.2517C27.4561 13.5663 27.1776 13.8296 26.8517 14.0222L13.3621 27.2083C12.9395 27.6122 12.3929 27.8618 11.8108 27.9165Z"
                fill="#EEF6FF"
              />
              <path
                d="M25.533 27.9165C25.8704 27.9188 26.205 27.8544 26.5175 27.7271C26.83 27.5998 27.1143 27.412 27.354 27.1746C27.8277 26.7003 28.0938 26.0575 28.0938 25.3872C28.0938 24.7169 27.8277 24.0741 27.354 23.5998L13.8645 10.4138C13.6503 10.1016 13.3688 9.84148 13.0406 9.65263C12.7125 9.46377 12.3462 9.35098 11.9687 9.32256C11.5912 9.29415 11.2121 9.35085 10.8594 9.48847C10.5068 9.62609 10.1895 9.84112 9.93098 10.1177C9.67248 10.3943 9.47937 10.7254 9.36589 11.0866C9.25241 11.4477 9.22146 11.8298 9.27531 12.2045C9.32916 12.5792 9.46644 12.9371 9.67703 13.2517C9.88762 13.5663 10.1661 13.8296 10.4921 14.0222L23.9817 27.2083C24.4043 27.6122 24.9509 27.8618 25.533 27.9165Z"
                fill="#EEF6FF"
              />
            </svg>
          }
          onClick={onClose}
          variant="ghost"
          position="absolute"
          right="30px"
          top="29px"
        />

        <TermsCard removeBackdrop />
      </ModalContent>
    </Modal>
  );
};
