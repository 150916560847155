import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  Input,
  InputGroup,
  List,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  Spinner,
  Text,
  useDisclosure,
  useOutsideClick,
} from "@chakra-ui/react";
import { APISchemas, GET } from "@/api";
import { useQuery } from "react-query";
import { GETCHANNELNAME } from "@/constant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  FacebookFormContainer,
  InstagramFormContainer,
  LinkedinFormContainer,
  MainForm,
  TwitterFormContainer,
  facebookSchema,
  instagramSchema,
  linkedinSchema,
  twitterSchema,
  youtubeSchema,
  YoutubeFormContainer,
  TelegramFormContainer,
  telegramSchema,
  TiktokFormContainer,
  tiktokSchema,
} from "../form-containers";

import { useFormStore } from "../form-store";
import { z } from "@/i18n";
import { StandartFile } from "../common";
import { ChannelSelect } from "./_channel-select";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  TelegramIcon,
  TiktokCircleIcon,
  YoutubeIcon,
} from "@/assets/brands";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { HexagonErrorIcon } from "@/assets/icons/hexagon-error-icon";
import { colors } from "@/theme/colors";

const platformForms = {
  twitter: TwitterFormContainer,
  linkedin: LinkedinFormContainer,
  instagram: InstagramFormContainer,
  facebook: FacebookFormContainer,
  telegram: TelegramFormContainer,
  tiktok: TiktokFormContainer,
  youtube: YoutubeFormContainer,
};

type Channel = APISchemas["Channel"];

export const StepOne: FC<{ id?: string }> = ({ id }) => {
  const { t } = useTranslation();
  // get channel_id from query params
  const [searchParams] = useSearchParams();

  const channel_id = searchParams.get("channel");

  const isQueryParamsConsumed = useRef<Record<string, boolean>>({});

  const organization = useAuthStore((s) => s.activeOrganization);
  const {
    platforms,
    setPlatforms,
    selectedPlatform: selectedChannel,
    setSelectedPlatform: setSelectedChannel,
    setPreview,
    setAllFormStates,
  } = useFormStore((s) => ({
    platforms: s.platforms,
    setPlatforms: s.setPlatforms,
    setPreview: s.setPreview,
    selectedPlatform: s.selectedPlatform,
    setSelectedPlatform: s.setSelectedPlatform,
    setAllFormStates: s.setAllFormStates,
  }));

  const [selectedForm, setForm] = useState<Channel>();
  const hideMainForm = useRef<boolean>(false);
  const defaultValueUsedRef = useRef<boolean>(false);
  const channelsRef = useRef<typeof channels>();

  const [selectedMainForm, setMainForm] = useState<
    | "main"
    | "twitter"
    | "linkedin"
    | "instagram"
    | "facebook"
    | "telegram"
    | "tiktok"
    | "youtube"
    | null
  >();
  const [search, setSearch] = useState<string>();

  const { data: channels, isLoading: isChannelLoading } = useQuery({
    queryKey: ["channels", organization?.id, "post-page"],
    queryFn: async () => {
      if (!organization) {
        return;
      }
      const res = await GET("/org/{org_pk}/channels/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
      });

      return res;
    },
    select(res) {
      if (!res) {
        return [];
      }

      if (
        res.data &&
        channel_id &&
        !isQueryParamsConsumed.current[channel_id]
      ) {
        isQueryParamsConsumed.current[channel_id] = true;
        const channel = res.data.find((c) => c.id.toString() === channel_id);
        if (channel) {
          setPlatforms([channel]);
        }
      }

      return res.data?.filter(
        (d) => d.status === "active" && d.channel_type !== "facebook_account"
      );
    },
  });

  const {
    data: Compose,
    refetch: fetchCompose,
    isLoading: isComposeLoading,
  } = useQuery(
    ["compose", id, organization?.id],
    async () => {
      if (!id || !organization) {
        return;
      }
      if (!channelsRef.current) {
        return;
      }

      const { data } = await GET("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            id: Number(id),
            org_pk: organization?.id.toString(),
          },
        },
      });

      if (!data) {
        return;
      }

      const mediaToFiles = (medias: readonly APISchemas["Media"][]) => {
        const files: Array<StandartFile> = [];
        for (const media of medias) {
          if (media.type === "image") {
            files.push({
              api: new File([], ""),
              data: {
                image: {
                  id: media.id.toString(),
                  uploadUrl: "",
                  downloadUrl: media.download_url,
                },
              },
            });
          }

          if (media.type === "video") {
            files.push({
              api: new File([], ""),
              data: {
                video: {
                  id: media.id.toString(),
                  url: media.url,
                  thumbnail_url: media.download_url.replace(
                    "original",
                    "thumbnail.jpg"
                  ),
                  presigned_signature: "",
                  expiration: 0,
                  library_id: "",
                  video_id: media.uid,
                },
              },
            });
          }
        }

        return files;
      };

      const tweets: Array<
        z.infer<typeof twitterSchema> & { channel_id: number }
      > = [];
      const facebook_posts: Array<
        z.infer<typeof facebookSchema> & { channel_id: number }
      > = [];
      const instagram_posts: Array<
        z.infer<typeof instagramSchema> & { channel_id: number }
      > = [];
      const linkedin_posts: Array<
        z.infer<typeof linkedinSchema> & { channel_id: number }
      > = [];
      const telegram_posts: Array<
        z.infer<typeof telegramSchema> & { channel_id: number }
      > = [];
      const tiktok_posts: Array<
        z.infer<typeof tiktokSchema> & { channel_id: number }
      > = [];
      const youtube_posts: Array<
        z.infer<typeof youtubeSchema> & { channel_id: number }
      > = [];

      const channelIds: number[] = [];

      for (const twitter of data.twitter_posts ?? []) {
        const files = await mediaToFiles(twitter.media ?? []);
        tweets.push({
          ...twitter,
          channel_id: twitter.channel,
          content: twitter.body ?? undefined,
          files: files,
        });

        channelIds.push(twitter.channel);
      }

      for (const facebook of data.facebook_posts ?? []) {
        const files = await mediaToFiles(facebook.media ?? []);
        facebook_posts.push({
          ...facebook,
          channel_id: facebook.channel,
          content: facebook.body ?? "",
          files: files,
        });

        channelIds.push(facebook.channel);
      }
      for (const tiktok of data.tiktok_posts ?? []) {
        const files = await mediaToFiles(tiktok.media ?? []);
        tiktok_posts.push({
          ...tiktok,
          channel_id: tiktok.channel,
          content: tiktok.body ?? "",
          files: files,
        });

        channelIds.push(tiktok.channel);
      }

      for (const instagram of data.instagram_posts ?? []) {
        const files = await mediaToFiles(instagram.media ?? []);
        instagram_posts.push({
          ...instagram,
          channel_id: instagram.channel,
          content: instagram.body ?? undefined,
          files: files,
        });

        channelIds.push(instagram.channel);
      }

      for (const linkedin of data.linkedin_posts ?? []) {
        const files = await mediaToFiles(linkedin.media ?? []);
        linkedin_posts.push({
          ...linkedin,
          channel_id: linkedin.channel,
          content: linkedin.body ?? "",
          files: files,
        });

        channelIds.push(linkedin.channel);
      }

      for (const telegram of data.telegram_posts ?? []) {
        const files = await mediaToFiles(telegram.media ?? []);
        telegram_posts.push({
          ...telegram,
          channel_id: telegram.channel,
          content: telegram.body ?? "",
          files: files,
        });

        channelIds.push(telegram.channel);
      }

      for (const youtube of data.youtube_posts ?? []) {
        const files = await mediaToFiles(youtube.media ?? []);
        youtube_posts.push({
          ...youtube,
          channel_id: youtube.channel,
          content: youtube.body ?? "",
          files: files,
          headline: youtube.headline ?? "",
        });

        channelIds.push(youtube.channel);
      }

      setPlatforms(
        channelsRef.current
          .filter((c) => channelIds.includes(c.id))
          .map((b) => ({ ...b, name: `@${b.name}` }))
      );
      return {
        ...{
          ...data,
          content: data.body ?? undefined,
          files: [...mediaToFiles(data.media ?? [])],
        },
        twitter: tweets,
        facebook: facebook_posts,
        instagram: instagram_posts,
        linkedin: linkedin_posts,
        telegram: telegram_posts,
        tiktok: tiktok_posts,
        youtube: youtube_posts,
        channelIds,
      };
    },
    {
      enabled: false,
    }
  );

  if (!defaultValueUsedRef.current && id && channels) {
    channelsRef.current = channels;
    fetchCompose();
    defaultValueUsedRef.current = true;
  }

  const setMainFormWrap = useCallback(
    (value: typeof selectedMainForm) => {
      setForm(undefined);
      setSelectedChannel(undefined);

      hideMainForm.current = false;

      setAllFormStates({
        sync: true,
      });

      setPreview({});

      setMainForm(value);
    },
    [setAllFormStates, setPreview, setSelectedChannel]
  );

  const results = useMemo(() => {
    const buckets = channels ?? [];

    return buckets.filter((bucket) => {
      const containsName = search
        ? bucket.name.toLowerCase().search(search) > -1 ||
          bucket.channel_type.toLowerCase().search(search) > -1
        : false;

      return containsName;
    });
  }, [channels, search]);

  const PlatformForms = useMemo(() => {
    return platforms.map((p) => {
      const name = GETCHANNELNAME(p.channel_type);

      if (!name) {
        console.error("channel cannot recognized value: ", p.channel_type);
        return;
      }

      const Form = platformForms[name];

      if (!Form) {
        return;
      }

      const defaultValue = Compose?.[name]?.find((c) => c.channel_id === p.id);

      return (
        <Box hidden={p.id !== selectedForm?.id} key={p.id}>
          <Form
            channel={p}
            defaultValue={
              defaultValue
                ? { ...defaultValue, content: defaultValue.content ?? "" }
                : undefined
            }
          />
        </Box>
      );
    });
  }, [platforms, Compose, selectedForm]);

  useEffect(() => {
    if (id && !selectedChannel) {
      return;
    }

    if (!selectedChannel) {
      setMainFormWrap("main");
      return;
    }
    hideMainForm.current = true;
    setMainForm(null);
    setForm(selectedChannel);

    const channelName = GETCHANNELNAME(selectedChannel.channel_type);
    if (channelName) {
      setPreview({
        [channelName]: {
          channel: selectedChannel,
          formType: "normal",
        },
      });
    }
  }, [id, selectedChannel, setMainFormWrap, setPreview]);

  useEffect(() => {
    if (platforms.length > 0) {
      return;
    }

    if (id) {
      return;
    }

    setMainFormWrap("main");
  }, [id, platforms, setAllFormStates, setMainFormWrap]);

  useEffect(() => {
    if (!id) {
      setPlatforms([]);
    }
  }, [id, setPlatforms]);

  return (
    <Flex flexDir="column" minH="60vh">
      <SearchableDropdown
        channels={results.length === 0 ? channels ?? [] : results}
        selectedChannels={platforms}
        onSelect={setPlatforms}
        onSearch={setSearch}
      />

      <Box>
        <Flex justifyContent="space-between">
          <Text> {t("content_details")}</Text>

          <PlatformButton
            width="180px"
            height="32px"
            active={selectedMainForm === "main"}
            accessKey="main"
            onClick={setMainFormWrap}
          >
            {t("apply_all_channels")}
          </PlatformButton>
        </Flex>
      </Box>

      <Box h="10" my="3.5">
        <ChannelSelect
          value={selectedChannel}
          channels={platforms}
          onSelected={setSelectedChannel}
        />
      </Box>
      {!selectedMainForm && !selectedChannel && (
        <Flex
          border="1px solid"
          borderColor="blue.brand-light"
          h="full"
          rounded="6px"
          bg="white.alabaster"
          py="12px"
          px="13px"
          justifyContent="flex-start"
          alignItems="center"
          mb="3"
        >
          <Icon w="24px" h="30px">
            <HexagonErrorIcon color={colors.blue.shiny} />
          </Icon>
          <Text fontSize="16px" color="blue.shiny" textAlign="center" w="full">
            {t("page.post-page.please-select-an-account")}
          </Text>
        </Flex>
      )}

      <Box position="relative">
        <Box
          w="full"
          height="full"
          position="absolute"
          zIndex="99"
          id="laoding-overlay"
          display={isChannelLoading || isComposeLoading ? undefined : "none"}
        >
          <Center w="full" height="full">
            <Spinner />
          </Center>
        </Box>

        {PlatformForms}

        <Box
          display={
            !hideMainForm.current && selectedMainForm === "main"
              ? undefined
              : "none"
          }
        >
          <MainForm defaultValue={Compose} />
        </Box>
      </Box>
    </Flex>
  );
};

const SearchableDropdown: FC<{
  channels: Array<APISchemas["Channel"]>;
  selectedChannels: Array<APISchemas["Channel"]>;
  onSearch: (v: string) => void;
  onSelect: (v: Array<APISchemas["Channel"]>) => void;
}> = ({ channels, selectedChannels, onSearch, onSelect }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const focusRef = useRef<HTMLInputElement | null>(null);
  const outsideRef = useRef<HTMLDivElement | null>(null);
  useOutsideClick({
    ref: outsideRef,
    handler: onClose,
  });

  const [hover, setHover] = useState<string>();

  const updateSelected = useCallback(
    (t: APISchemas["Channel"]) => {
      return () => {
        const index = selectedChannels.findIndex((s) => s.id === t.id);

        if (index > -1) {
          //selectedChannels.splice(index, 1);
          //onSelect(selectedChannels);
          return;
        }

        selectedChannels.push(t);
        onSelect(selectedChannels);
      };
    },
    [onSelect, selectedChannels]
  );

  const removeSelected = useCallback(
    (t: APISchemas["Channel"]) => {
      return () => {
        const index = selectedChannels.findIndex((s) => s.id === t.id);

        if (index > -1) {
          selectedChannels.splice(index, 1);
          onSelect(selectedChannels);
          return;
        }
      };
    },
    [onSelect, selectedChannels]
  );

  return (
    <Box w="full" ref={outsideRef}>
      <Popover
        //offset={[12, 12]}
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={focusRef}
        matchWidth
        placement="bottom-end"
        eventListeners={{
          resize: true,
        }}
      >
        <PopoverAnchor>
          <InputGroup mb="4">
            <Input
              ref={focusRef}
              type="text"
              placeholder={t("choose_your_account")}
              onChange={(v) => onSearch(v.target.value)}
              onFocus={onOpen}
              //onBlur={onClose}
            />
          </InputGroup>
        </PopoverAnchor>

        <PopoverContent w="full" bg="white.bg" zIndex="99">
          <PopoverBody w="full" px="0">
            <List maxH="52" overflowY="auto">
              {channels?.map((c) => {
                const selected = !!selectedChannels?.find((s) => s.id === c.id);
                return (
                  <ListItem
                    px="3.5"
                    h="9"
                    key={c.id}
                    onMouseOver={() => setHover(c.id.toString())}
                    onMouseLeave={() => setHover(undefined)}
                    sx={
                      c.id.toString() === hover
                        ? {
                            bg: "#345A79FC",
                            color: "white",
                          }
                        : selected
                          ? {
                              bg: "blue.shiny",
                              color: "white",
                            }
                          : undefined
                    }
                  >
                    <Flex alignItems="center" gap="2" h="9">
                      <Icon boxSize="6" display="none">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="23px"
                          height="24px"
                          viewBox="0 0 23 24"
                          fill="none"
                        >
                          <path
                            d="M19.6317 3.44643C17.4597 1.27437 14.5717 0.0781921 11.5 0.0781921C9.43332 0.0781921 7.42662 0.626823 5.66173 1.66879L4.26848 0.275534C4.07576 0.0827742 3.78593 0.0251394 3.53414 0.129448C3.28235 0.233757 3.11816 0.479434 3.11816 0.751975V3.97611C3.11816 4.34824 3.41986 4.64994 3.79199 4.64994H7.01617C7.28871 4.64994 7.53443 4.48579 7.6387 4.23396C7.74296 3.98217 7.68532 3.69234 7.49261 3.49962L6.65019 2.6572C8.13252 1.84964 9.79292 1.4258 11.5 1.4258C17.098 1.4258 21.6523 5.98012 21.6523 11.5781C21.6523 17.1762 17.098 21.7305 11.5 21.7305C5.90197 21.7305 1.34766 17.1762 1.34766 11.5781C1.34766 11.206 1.04596 10.9043 0.673828 10.9043C0.301695 10.9043 0 11.206 0 11.5781C0 14.6499 1.19618 17.5378 3.36824 19.7098C5.54035 21.8819 8.42824 23.0781 11.5 23.0781C14.5717 23.0781 17.4596 21.8819 19.6317 19.7098C21.8038 17.5378 23 14.6499 23 11.5781C23 8.50643 21.8038 5.6185 19.6317 3.44643Z"
                            fill={
                              c.id.toString() === hover ? "white" : "#E2E4E6"
                            }
                          />
                          <path
                            d="M15.8305 10.9008H12.1738V7.24414C12.1738 6.87196 11.8721 6.57031 11.5 6.57031C11.1279 6.57031 10.8262 6.87201 10.8262 7.24414V11.5746C10.8262 11.9467 11.1279 12.2484 11.5 12.2484H15.8305C16.2026 12.2484 16.5043 11.9467 16.5043 11.5746C16.5043 11.2025 16.2026 10.9008 15.8305 10.9008Z"
                            fill={
                              c.id.toString() === hover ? "white" : "#E2E4E6"
                            }
                          />
                        </svg>
                      </Icon>
                      <Button
                        variant="unstyled"
                        w="full"
                        h="full"
                        onClick={
                          selected ? removeSelected(c) : updateSelected(c)
                        }
                      >
                        <Flex gap="2">
                          <Box w="7" h="7" pos="relative">
                            <Avatar
                              src={c.picture ?? undefined}
                              name={c.name}
                              w="7"
                              h="7"
                              size="xs"
                            />
                            <Icon
                              pos="absolute"
                              bottom="0"
                              right="0"
                              w="3"
                              h="3"
                              rounded="full"
                            >
                              {
                                {
                                  twitter: <TwitterIcon />,
                                  facebook: <FacebookIcon />,
                                  linkedin: <LinkedinIcon />,
                                  youtube: <YoutubeIcon />,
                                  instagram: <InstagramIcon />,
                                  tiktok: <TiktokCircleIcon />,
                                  telegram: <TelegramIcon inverse={false} />,
                                }[c.channel_type.split("_")[0]]
                              }
                            </Icon>
                          </Box>
                          <Text
                            fontWeight="semibold"
                            lineHeight="25px"
                            color="#353B48"
                            w="min-content"
                            whiteSpace="nowrap"
                            sx={
                              c.id.toString() === hover || selected
                                ? {
                                    color: "white",
                                  }
                                : undefined
                            }
                          >
                            {c.name}
                          </Text>
                          <Text
                            visibility={c.username ? "visible" : "hidden"}
                            fontSize="xs"
                            lineHeight="25px"
                            color="#353B48"
                            sx={
                              c.id.toString() === hover || selected
                                ? {
                                    color: "white",
                                  }
                                : undefined
                            }
                          >
                            {c.username?.startsWith("@")
                              ? c.username
                              : `@${c.username}`}
                          </Text>
                        </Flex>
                      </Button>

                      <Icon
                        visibility={!selected ? "hidden" : "visible"}
                        sx={{
                          fill: "white",
                        }}
                        w="11px"
                        h="12px"
                      >
                        <svg
                          viewBox="0 0 11 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M6.41488 5.57808L10.8102 1.18277C11.0632 0.929778 11.0632 0.520859 10.8102 0.267868C10.5572 0.0148773 10.1483 0.0148773 9.89528 0.267868L5.49998 4.66318L1.10464 0.267868C0.851653 0.0148773 0.442734 0.0148773 0.189743 0.267868C-0.0632477 0.520859 -0.0632477 0.929778 0.189743 1.18277L4.58505 5.57808L0.189743 9.97339C-0.0632477 10.2264 -0.0632477 10.6353 0.189743 10.8883C0.315916 11.0145 0.481555 11.0779 0.647194 11.0779C0.812832 11.0779 0.978471 11.0144 1.10464 10.8883L5.49995 6.49298L9.89526 10.8883C10.0214 11.0145 10.1871 11.0779 10.3527 11.0779C10.5184 11.0779 10.684 11.0144 10.8102 10.8883C11.0632 10.6353 11.0632 10.2264 10.8102 9.97339L6.41488 5.57808Z"
                              fill="white"
                            />
                          </g>
                        </svg>
                      </Icon>
                    </Flex>
                  </ListItem>
                );
              })}
            </List>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

const PlatformButton: FC<
  PropsWithChildren<{
    accessKey:
      | "main"
      | "twitter"
      | "linkedin"
      | "instagram"
      | "facebook"
      | "youtube";
    active?: boolean;
    width: string;
    height: string;
    onClick: (
      key:
        | "main"
        | "twitter"
        | "linkedin"
        | "instagram"
        | "facebook"
        | "youtube"
    ) => void;
  }>
> = ({ children, active, accessKey, onClick, width, height }) => {
  const onSelect = useCallback(() => {
    onClick(accessKey);
  }, [accessKey, onClick]);

  return (
    <Button
      variant="unstyled"
      w={width}
      h={height}
      size="none"
      fontSize="12px"
      fontWeight="bold"
      rounded="full"
      lineHeight="1"
      boxShadow="0px 0px 0px 1px #E7E8EA"
      _hover={{
        textDecor: "none",
      }}
      color={!active ? "black" : "white"}
      bg={active ? "green.label" : undefined}
      onClick={onSelect}
    >
      {children}
    </Button>
  );
};
