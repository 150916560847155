export const YoutubeIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="9.95117" cy="9.22754" r="9" fill={props.fill || "#F61C0D"} />
      <path
        d="M13.8945 8.79453C14.2279 8.98698 14.2279 9.4681 13.8945 9.66055L8.35449 12.8591C8.02116 13.0515 7.60449 12.811 7.60449 12.4261L7.60449 6.029C7.60449 5.6441 8.02116 5.40353 8.35449 5.59598L13.8945 8.79453Z"
        fill="white"
      />
    </svg>
  );
};
