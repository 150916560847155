export const FacebookIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39 78C60.5391 78 78 60.5391 78 39C78 17.4609 60.5391 0 39 0C17.4609 0 0 17.4609 0 39C0 60.5391 17.4609 78 39 78Z"
        fill="#3A559F"
      />
      <path
        d="M51.3556 34.382L50.5711 41.176C50.5386 41.486 50.3933 41.7733 50.1629 41.9833C49.9325 42.1932 49.6331 42.3113 49.3214 42.315H42.2305L42.195 62.4576C42.1974 62.6963 42.1058 62.9264 41.9399 63.098C41.774 63.2697 41.5473 63.3692 41.3086 63.375H34.1247C34.0052 63.3751 33.887 63.3511 33.7771 63.3043C33.6672 63.2576 33.5678 63.1891 33.485 63.103C33.4022 63.0169 33.3377 62.9149 33.2953 62.8033C33.2529 62.6916 33.2335 62.5725 33.2383 62.4532V42.315H27.9201C27.7531 42.3133 27.588 42.2786 27.4344 42.2131C27.2807 42.1476 27.1415 42.0524 27.0246 41.9331C26.9077 41.8137 26.8155 41.6725 26.7532 41.5175C26.6909 41.3626 26.6597 41.1968 26.6615 41.0298L26.6349 34.2358C26.6325 34.0682 26.6632 33.9017 26.7252 33.746C26.7872 33.5902 26.8793 33.4482 26.9962 33.328C27.113 33.2079 27.2524 33.1119 27.4064 33.0456C27.5604 32.9793 27.7259 32.944 27.8935 32.9417H33.2383V26.3826C33.2383 18.7687 37.7588 14.625 44.3888 14.625H49.8222C49.9902 14.6267 50.1562 14.6616 50.3106 14.7276C50.4651 14.7937 50.6051 14.8896 50.7224 15.0098C50.8398 15.13 50.9322 15.2722 50.9945 15.4283C51.0567 15.5843 51.0876 15.7511 51.0852 15.9191V21.6361C51.0876 21.8041 51.0567 21.9709 50.9945 22.127C50.9322 22.283 50.8398 22.4252 50.7224 22.5454C50.6051 22.6657 50.4651 22.7616 50.3106 22.8276C50.1562 22.8936 49.9902 22.9285 49.8222 22.9302H46.4894C42.8864 22.9302 42.1861 24.703 42.1861 27.2645V32.9417H50.1058C50.2859 32.944 50.4635 32.9843 50.6269 33.0601C50.7903 33.1359 50.9358 33.2454 51.0538 33.3815C51.1719 33.5175 51.2598 33.677 51.3118 33.8495C51.3638 34.0219 51.3787 34.2034 51.3556 34.382Z"
        fill="#F6F7F9"
      />
    </svg>
  );
};
