import { useOutsideStore } from "@/context/outside-store/outside-store";
import { FC } from "react";

export const PaymentCallback: FC = () => {
  const endPayment = useOutsideStore((c) => c.endPayment);

  const url = window.location.href;
  endPayment({ url });
  window.close();

  return null;
};
