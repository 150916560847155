import { APISchemas } from "@/api";
import { Flex, Text, Avatar, Box, Switch } from "@chakra-ui/react";
import { useState } from "react";

export const CallbackAccountItem = ({
  item,
  onCheck,
  onCancel,
}: {
  item: APISchemas["Channel"];
  navigateTo?: string;
  onClick?: () => void;
  onCheck: () => void;
  onCancel: () => void;
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const handleSwitch = (item: APISchemas["Channel"]) => {
    if (isDisabled) return;
    setIsDisabled(true);
    Promise.resolve(item.status === "active" ? onCancel() : onCheck())
      .then(() => setIsDisabled(false))
      .catch(() => setIsDisabled(false));
  };

  return (
    <Flex alignItems="center" overflow="hidden" mt="3" w="full" py="2">
      <Flex alignItems="center" w="96%">
        <Avatar boxSize="35px" src={item.picture ? item.picture : ""} />
        <Box ml="3">
          <Text fontSize="16px" fontWeight="600" color="black.active">
            {item.name}
          </Text>
          <Text fontSize="12px" fontWeight="500" color="#B7BABF">
            {item.username ? `@${item.username}` : `@${item.name}`}
          </Text>
        </Box>
      </Flex>
      <Flex>
        <Switch
          isDisabled={isDisabled}
          position="relative"
          right="3"
          isChecked={item.status === "active" ? true : false}
          onChange={() => handleSwitch(item)}
        />
      </Flex>
    </Flex>
  );
};
