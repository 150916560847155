export const InstagramColorfulIcon = (
  props: React.SVGAttributes<SVGElement>
) => {
  return (
    <svg
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M101 51C101 23.3858 78.6142 1 51 1C23.3858 1 1 23.3858 1 51C1 78.6142 23.3858 101 51 101C78.6142 101 101 78.6142 101 51Z"
        fill="url(#paint0_linear_2352_13803)"
      />
      <path
        opacity="0.1"
        d="M88.6316 18.1066C81.3421 31.4882 71.0329 45.2908 58.1513 58.1724C45.2697 71.054 31.4671 81.3434 18.1053 88.6329C17.2675 87.9048 16.4539 87.1461 15.6645 86.3566C10.8925 81.7439 7.08656 76.2273 4.46865 70.1285C1.85074 64.0297 0.473233 57.4708 0.416442 50.8341C0.359652 44.1974 1.62472 37.6158 4.13787 31.4731C6.65103 25.3304 10.362 19.7495 15.0543 15.0558C19.7467 10.3622 25.3266 6.64967 31.4686 4.1348C37.6106 1.61993 44.1918 0.353025 50.8285 0.407963C57.4652 0.462901 64.0245 1.83858 70.1241 4.45478C76.2236 7.07099 81.7412 10.8754 86.3553 15.6461C87.1447 16.4355 87.9035 17.2557 88.6316 18.1066Z"
        fill="#F6F7F9"
      />
      <path
        d="M62.8421 24.6836H39.1579C35.3192 24.6836 31.6378 26.2085 28.9234 28.9228C26.2091 31.6372 24.6842 35.3186 24.6842 39.1573V62.8415C24.6842 66.6802 26.2091 70.3616 28.9234 73.0759C31.6378 75.7903 35.3192 77.3152 39.1579 77.3152H62.8421C66.6808 77.3152 70.3622 75.7903 73.0765 73.0759C75.7909 70.3616 77.3158 66.6802 77.3158 62.8415V39.1573C77.3158 35.3186 75.7909 31.6372 73.0765 28.9228C70.3622 26.2085 66.6808 24.6836 62.8421 24.6836ZM72.7105 60.7625C72.7105 63.9312 71.4518 66.97 69.2112 69.2106C66.9707 71.4512 63.9318 72.7099 60.7632 72.7099H41.2368C38.0682 72.7099 35.0293 71.4512 32.7888 69.2106C30.5482 66.97 29.2895 63.9312 29.2895 60.7625V41.2362C29.2895 38.0676 30.5482 35.0287 32.7888 32.7882C35.0293 30.5476 38.0682 29.2889 41.2368 29.2889H60.7632C63.9318 29.2889 66.9707 30.5476 69.2112 32.7882C71.4518 35.0287 72.7105 38.0676 72.7105 41.2362V60.7625Z"
        fill="#F6F7F9"
      />
      <path
        d="M60.5987 41.501L60.4737 41.376L60.3684 41.2708C57.8809 38.7919 54.5118 37.4007 51 37.4023C49.2266 37.4144 47.4729 37.7757 45.8392 38.4658C44.2055 39.1558 42.7237 40.1609 41.4786 41.4238C40.2335 42.6866 39.2494 44.1824 38.5825 45.8257C37.9156 47.469 37.5791 49.2276 37.5921 51.001C37.5894 54.6001 39.0081 58.0545 41.5395 60.6129C42.7794 61.8679 44.2569 62.8633 45.8857 63.5411C47.5146 64.2188 49.2621 64.5653 51.0263 64.5602C53.6726 64.5047 56.2456 63.6804 58.4314 62.1877C60.6173 60.695 62.3215 58.5985 63.3363 56.1539C64.3511 53.7093 64.6327 51.0222 64.1465 48.4204C63.6604 45.8185 62.4277 43.4143 60.5987 41.501ZM51 59.9221C49.2305 59.9469 47.4935 59.4448 46.0101 58.4798C44.5267 57.5147 43.3639 56.1302 42.6694 54.5025C41.975 52.8747 41.7804 51.0772 42.1104 49.3385C42.4404 47.5998 43.28 45.9986 44.5225 44.7384C45.765 43.4783 47.3543 42.6161 49.0881 42.2616C50.8219 41.9072 52.622 42.0764 54.2594 42.7478C55.8968 43.4192 57.2976 44.5624 58.2835 46.032C59.2694 47.5017 59.7959 49.2313 59.7961 51.001C59.8047 52.1645 59.584 53.3182 59.1466 54.3963C58.7092 55.4745 58.0635 56.4558 57.2467 57.2843C56.4298 58.1128 55.4576 58.7722 54.3858 59.2248C53.314 59.6774 52.1635 59.9143 51 59.9221Z"
        fill="#F6F7F9"
      />
      <path
        d="M68.3882 36.7745C68.3916 37.1966 68.3118 37.6153 68.1533 38.0066C67.9947 38.3979 67.7605 38.754 67.4641 39.0547C67.1678 39.3554 66.815 39.5946 66.426 39.7588C66.0371 39.9229 65.6196 40.0087 65.1974 40.0113C64.7788 40.0111 64.3643 39.9279 63.9781 39.7664C63.5919 39.6049 63.2416 39.3684 62.9474 39.0705C62.5017 38.6183 62.1985 38.0452 62.0755 37.4223C61.9524 36.7994 62.0149 36.1541 62.2552 35.5664C62.4954 34.9787 62.9029 34.4744 63.4271 34.1161C63.9513 33.7578 64.5691 33.5612 65.204 33.5508C65.947 33.5506 66.6665 33.8114 67.2369 34.2876L67.3026 34.3534C67.4088 34.4398 67.5058 34.5368 67.5921 34.6429L67.6645 34.7218C68.1354 35.302 68.3911 36.0272 68.3882 36.7745Z"
        fill="#F6F7F9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2352_13803"
          x1="15.6447"
          y1="15.6447"
          x2="86.3553"
          y2="86.3553"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FAE100" />
          <stop offset="0.15" stopColor="#FCB720" />
          <stop offset="0.3" stopColor="#FF7950" />
          <stop offset="0.5" stopColor="#FF1C74" />
          <stop offset="1" stopColor="#6C1CD1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
