import { Divider, Flex, FormControl, VStack } from "@chakra-ui/react";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContentEditor } from "../content-editor";
import { Dropzone } from "@/components/Dropzone";
import { StatusCard } from "./_status-card";
import { useFormStore } from "../form-store";
import { APISchemas } from "@/api";
import { SetToMainManipulator } from "./_content-manipulator";
import { telegramSchema } from "../form-containers/telegram-form-container";

export const TelegramFormPresent: FC<{ channel?: APISchemas["Channel"] }> = ({
  channel,
}) => {
  const setProgressBlock = useFormStore((f) => f.setProgressBlock);
  const globalError = useFormStore((f) =>
    f.getGobalError(channel?.id.toString() ?? "")
  );
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<telegramSchema>();

  return (
    <>
      <Flex>
        <Controller
          control={control}
          name="files"
          render={({ field }) => (
            <Dropzone
              value={field.value ?? []}
              onChange={field.onChange}
              onBusy={setProgressBlock}
            />
          )}
        />
      </Flex>
      <Divider borderColor="#B7BABF" />
      <FormControl pos="relative">
        <ContentEditor {...register("content")} />
        <Flex w="full">
          <Flex justifyContent="flex-end" w="full">
            {channel && <SetToMainManipulator channel={channel} />}
          </Flex>
        </Flex>
      </FormControl>

      <VStack rowGap="2" mb="3">
        {(Object.keys(errors) as Array<"root" | "content" | "files">).map(
          (key) => (
            <StatusCard
              key={key}
              title={key === "root" ? undefined : key}
              description={errors[key]?.message}
            />
          )
        )}
        <StatusCard
          title={globalError?.field}
          description={globalError?.message}
        />
      </VStack>
    </>
  );
};
