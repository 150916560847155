import {
  Box,
  HStack,
  VStack,
  Text,
  Image,
  AspectRatio,
  Avatar,
} from "@chakra-ui/react";
import { FC, useRef } from "react";
import { Forms, useFormStore } from "../form-store";
import { mainSchema } from "../form-containers";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import React from "react";
import Slider from "react-slick";
import { colors } from "@/theme/colors";
import { formatDistanceToNow } from "date-fns";

export const TiktokPreview: FC = () => {
  const { tiktok } = useFormStore((s) => s.previews);
  const { forms, mainForms } = useFormStore((s) => ({
    forms: s.forms,
    mainForms: s.mainForms,
  }));

  const dataRef = useRef<Forms | mainSchema>();

  const scheduledAt = useFormStore((s) => s.scheduledAt);

  if (!tiktok) {
    return null;
  }

  if (tiktok.formType !== "normal") {
    dataRef.current = mainForms[tiktok.formType];
  }

  if (tiktok.channel) {
    dataRef.current = forms[tiktok.channel.id.toString()];
  }

  if (!dataRef.current) {
    return null;
  }

  const name = tiktok.channel?.name ?? "Tiktok";

  return (
    <Box
      aspectRatio={1 / 1}
      bgColor="black"
      w="468px"
      bg="white"
      rounded="md"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      p="3"
    >
      <HStack gap="2" mb="3.5">
        <Avatar
          w="6"
          h="6"
          name={name.replace("@", "")}
          src={tiktok.channel?.picture ?? undefined}
        />
        <VStack gap="1.5" alignItems="flex-start">
          <Text
            fontSize="md"
            lineHeight="5"
            fontWeight="normal"
            color="#353B48"
          >
            {name.replace("@", "")}
          </Text>
        </VStack>
      </HStack>
      <Text
        ml="1"
        my="4"
        fontWeight="600"
        fontSize="16px"
        lineHeight="20px"
        color="black.active"
      >
        {"title" in dataRef.current && dataRef.current.title}
      </Text>
      <Box
        w="full"
        h="20rem"
        sx={{
          "& .slick-active button:before": {
            color: `${colors.blue.hashtag} !important`,
            opacity: "1 !important",
          },

          "& .slick-dots button:before": {
            color: colors.gray.passive,
            opacity: "1",
          },
          "& .slick-dots button": {
            width: "12px",
            height: "12px",
          },
        }}
      >
        <Slider infinite={false} dots arrows={false}>
          {dataRef.current.files?.slice(0, 4).map((file, index) => (
            <React.Fragment key={index}>
              {file.data.image ? (
                <AspectRatio h="80" ratio={1 / 1}>
                  <Image src={file.data.image.downloadUrl} />
                </AspectRatio>
              ) : file.data.video ? (
                <Box h="80" w="full">
                  <BunnyPlayer src={file.data.video.url} />
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </Slider>
      </Box>
      <Box my="3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="84"
          height="25"
          viewBox="0 0 84 25"
          fill="none"
        >
          <path
            d="M9.5 23.4336C9.22951 23.4336 8.96873 23.335 8.7655 23.156C7.99794 22.4811 7.25793 21.8468 6.60503 21.2872L6.6017 21.2843C4.68752 19.6438 3.03456 18.2271 1.88446 16.8315C0.598824 15.2714 0 13.7921 0 12.1761C0 10.606 0.535332 9.15754 1.50728 8.09726C2.49082 7.02445 3.84039 6.43359 5.3078 6.43359C6.40456 6.43359 7.40897 6.78231 8.29308 7.46996C8.73926 7.81707 9.14369 8.24188 9.5 8.7374C9.85645 8.24188 10.2607 7.81707 10.7071 7.46996C11.5912 6.78231 12.5956 6.43359 13.6923 6.43359C15.1596 6.43359 16.5093 7.02445 17.4929 8.09726C18.4648 9.15754 19 10.606 19 12.1761C19 13.7921 18.4013 15.2714 17.1157 16.8314C15.9656 18.2271 14.3128 19.6437 12.3989 21.284C11.7448 21.8444 11.0037 22.4797 10.2344 23.1563C10.0313 23.335 9.77035 23.4336 9.5 23.4336ZM5.3078 7.55291C4.15495 7.55291 3.09588 8.01563 2.32542 8.85592C1.54352 9.70889 1.11285 10.888 1.11285 12.1761C1.11285 13.5352 1.61513 14.7508 2.74131 16.1173C3.8298 17.4383 5.44884 18.8258 7.32345 20.4325L7.32693 20.4354C7.98228 20.9971 8.7252 21.6339 9.49841 22.3138C10.2763 21.6326 11.0203 20.9948 11.677 20.4322C13.5514 18.8256 15.1703 17.4383 16.2588 16.1173C17.3849 14.7508 17.8872 13.5352 17.8872 12.1761C17.8872 10.888 17.4565 9.70889 16.6746 8.85592C15.9043 8.01563 14.8451 7.55291 13.6923 7.55291C12.8478 7.55291 12.0724 7.8229 11.3878 8.3553C10.7777 8.82997 10.3526 9.43001 10.1035 9.84986C9.97532 10.0658 9.74976 10.1946 9.5 10.1946C9.25024 10.1946 9.02468 10.0658 8.89654 9.84986C8.6475 9.43001 8.22248 8.82997 7.61221 8.3553C6.92757 7.8229 6.15218 7.55291 5.3078 7.55291Z"
            fill="#353B48"
          />
          <path
            d="M80.9146 19.8411C82.5708 18.3793 83.5142 16.3229 83.5142 14.1493C83.5142 9.89484 79.9254 6.43359 75.5142 6.43359C71.1029 6.43359 67.5142 9.89484 67.5142 14.1493C67.5142 19.233 72.565 22.9648 77.6829 21.5774C81.6965 22.4668 81.4865 22.4334 81.5773 22.4334C81.8897 22.4334 82.0939 22.1158 81.9539 21.8457L80.9146 19.8411ZM77.6712 20.7519C77.6308 20.7519 77.5905 20.7575 77.5515 20.7686C72.9422 22.0863 68.3563 18.7305 68.3563 14.1492C68.3563 10.3426 71.5673 7.24573 75.5142 7.24573C79.461 7.24573 82.6721 10.3426 82.6721 14.1492C82.6721 16.19 81.7425 18.1155 80.1216 19.432C79.9728 19.5529 79.9292 19.7563 80.0162 19.9242L80.8009 21.4377C77.6074 20.7307 77.7614 20.7519 77.6712 20.7519Z"
            fill="#353B48"
          />
          <path
            d="M55.3037 7.00083C55.2078 6.79764 54.9999 6.67122 54.7755 6.67942L35.3511 7.38601C35.1288 7.39409 34.9323 7.53256 34.8498 7.73914C34.7674 7.9457 34.8146 8.18143 34.9703 8.34029L40.505 13.9904L41.3531 21.8541C41.3762 22.0686 41.5199 22.2502 41.7219 22.3226C41.7281 22.3248 41.7345 22.327 41.7409 22.329C41.9527 22.3965 42.1846 22.3327 42.332 22.1662L55.2163 7.61298C55.3653 7.44474 55.3996 7.20398 55.3037 7.00083ZM36.6622 8.46233L52.0819 7.9014L41.1641 13.058L36.6622 8.46233ZM42.3336 20.4699L41.6438 14.0736L52.5616 8.91704L42.3336 20.4699Z"
            fill="#353B48"
          />
        </svg>
      </Box>

      <Text
        fontSize="14px"
        lineHeight="5"
        color="#353B48"
        my="3"
        wordBreak="break-word"
        whiteSpace="pre-wrap"
      >
        <Text as="span" textTransform="capitalize" fontWeight="semibold" mr="2">
          {name.replace("@", "")}
        </Text>
        {dataRef.current.content}
      </Text>

      <Text my="3" color="#A9AAAD" fontSize="14px" fontWeight="light">
        {formatDistanceToNow(scheduledAt, {
          addSuffix: true,
        })}
      </Text>
    </Box>
  );
};
