import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Flex,
  Card,
  IconButton,
  Button,
  CardBody,
  Text,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useMatch, useNavigate } from "react-router-dom";
import { FC } from "react";
import { InfoIcon } from "@/assets/icons/info-icon";

export const UserInfo: FC = () => {
  const user = useAuthStore((state) => state.user);
  const match = useMatch("membership/plans");

  const fullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;

  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card
      w="full"
      bgColor="white.normal"
      h="100px"
      px="4"
      variant="unstyled"
      border="1px solid"
      borderColor="gray.athens"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
    >
      <CardBody p="0px">
        <Flex alignItems="center" h="full">
          <Flex h="full" alignItems="center">
            <Flex flexDir="column" justifyContent="center" gap="1" flex="1">
              <Text
                fontWeight="600"
                fontSize="24px"
                lineHeight="30px"
                color="black.active"
              >
                {fullName.charAt(0).toUpperCase() + fullName.slice(1)}
              </Text>
              <Text color="gray.smoke" fontSize="16px" lineHeight="20px" mt="2">
                {user?.email}
              </Text>
            </Flex>
          </Flex>
          <Flex justifyContent="flex-end" flex="1" h="full">
            <Flex justifyContent="flex-end" gap="6" alignItems="center">
              <Text color="gray.smoke" fontSize="16px" lineHeight="20px">
                {t("member_from")}{" "}
                {i18nformat(
                  new Date(user?.date_joined ?? Date.now()),
                  "dd MMM, yyyy"
                )}
              </Text>

              <Button
                fontWeight="600"
                borderRadius="6px"
                fontSize="16px"
                variant="unstyled"
                bg="green.label"
                color="white"
                w="171px"
                h="44px"
                _hover={{
                  opacity: "0.6",
                }}
                onClick={() => navigate("/membership/upgrade")}
              >
                {t("button.upgrade-plan")}
              </Button>

              <IconButton
                variant="unstyled"
                icon={<InfoIcon active={!!match} />}
                aria-label="Information"
                onClick={() =>
                  match
                    ? navigate("/membership")
                    : navigate("/membership/plans")
                }
              />
            </Flex>
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
