export const AttachIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="50"
      height="51"
      viewBox="0 0 50 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.33">
        <path
          d="M18.0223 22.5925C14.4874 22.5925 11.627 19.732 11.627 16.1971C11.627 12.6622 14.4874 9.80176 18.0223 9.80176C21.5572 9.80176 24.4177 12.6622 24.4177 16.1971C24.4177 19.732 21.5572 22.5925 18.0223 22.5925ZM18.0223 13.2901C16.4177 13.2901 15.1153 14.5925 15.1153 16.1971C15.1153 17.8018 16.4177 19.1041 18.0223 19.1041C19.627 19.1041 20.9293 17.8018 20.9293 16.1971C20.9293 14.5925 19.627 13.2901 18.0223 13.2901Z"
          fill="#353B48"
        />
        <path
          d="M31.9767 50.5H18.0233C5.39535 50.5 0 45.1046 0 32.4767V18.5233C0 5.89535 5.39535 0.5 18.0233 0.5H27.3256C28.2791 0.5 29.0698 1.2907 29.0698 2.24419C29.0698 3.19767 28.2791 3.98837 27.3256 3.98837H18.0233C7.30232 3.98837 3.48837 7.80232 3.48837 18.5233V32.4767C3.48837 43.1977 7.30232 47.0116 18.0233 47.0116H31.9767C42.6977 47.0116 46.5116 43.1977 46.5116 32.4767V20.8488C46.5116 19.8953 47.3023 19.1047 48.2558 19.1047C49.2093 19.1047 50 19.8953 50 20.8488V32.4767C50 45.1046 44.6046 50.5 31.9767 50.5Z"
          fill="#353B48"
        />
        <path
          d="M38.9532 17.9447C37.9997 17.9447 37.209 17.154 37.209 16.2005V2.24702C37.209 1.54935 37.6276 0.898182 38.2787 0.642368C38.9299 0.386554 39.6741 0.526089 40.1857 1.01446L44.8369 5.66562C45.5113 6.34004 45.5113 7.45632 44.8369 8.13074C44.1625 8.80516 43.0462 8.80516 42.3718 8.13074L40.6974 6.45632V16.2005C40.6974 17.154 39.9067 17.9447 38.9532 17.9447Z"
          fill="#353B48"
        />
        <path
          d="M34.3009 8.63909C33.859 8.63909 33.4172 8.4763 33.0683 8.12746C32.3939 7.45305 32.3939 6.33677 33.0683 5.66235L37.7195 1.01119C38.3939 0.336766 39.5102 0.336766 40.1846 1.01119C40.859 1.6856 40.859 2.80188 40.1846 3.4763L35.5334 8.12746C35.1846 8.4763 34.7427 8.63909 34.3009 8.63909Z"
          fill="#353B48"
        />
        <path
          d="M3.30269 43.407C2.74455 43.407 2.18641 43.1279 1.86083 42.6395C1.32594 41.8488 1.53525 40.7558 2.32594 40.2209L13.7911 32.5233C16.3027 30.8488 19.7678 31.0349 22.0469 32.9651L22.8143 33.6395C23.9771 34.6395 25.9539 34.6395 27.0934 33.6395L36.7678 25.3372C39.2329 23.2209 43.1166 23.2209 45.605 25.3372L49.3957 28.593C50.1166 29.2209 50.2097 30.314 49.5818 31.0581C48.9538 31.7791 47.8376 31.8721 47.1166 31.2442L43.3259 27.9884C42.1632 26.9884 40.2097 26.9884 39.0469 27.9884L29.3725 36.2907C26.9073 38.407 23.0236 38.407 20.5352 36.2907L19.7678 35.6163C18.698 34.7093 16.9306 34.6163 15.7446 35.4302L4.30269 43.1279C3.97711 43.3139 3.62827 43.407 3.30269 43.407Z"
          fill="#353B48"
        />
      </g>
    </svg>
  );
};
