import {
  Card,
  CardBody,
  Flex,
  Text,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FC, useCallback, useState } from "react";
import { useQuery } from "react-query";
import { GET } from "@/api";
import { Pagination } from "@/components/pagination";
import { parseISO } from "date-fns";
import { i18nformat } from "@/utils/misc";
import { useConfigStore } from "@/context/config-store/config-store";
import { useAuthStore } from "@/context/auth-store/auth-store";
interface InvoiceTableProps {
  icon?: React.ReactElement;
  text?: string;
  numericValue?: number;
}

export const InvoiceTable: FC<InvoiceTableProps> = () => {
  const itemsPerPage = 6;
  const [currentPage, setCurrentPage] = useState<number>(1);

  const language = useConfigStore((state) => state.language);

  const { t } = useTranslation();

  const { data } = useQuery("invoices", async () => {
    const { data } = await GET("/payment/payments/");
    return data;
  });

  const { token } = useAuthStore.getState();
  const totalPages = Math.ceil((data?.length ?? 0) / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = data?.slice(startIndex, endIndex) ?? [];

  const handlePageChange = useCallback((page: number) => {
    setCurrentPage(page);
  }, []);
  return (
    <Card
      border-radius="6px"
      border="1px solid"
      borderColor="gray.athens"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
      h="full"
      overflow="hidden"
      variant="elevated"
      w="full"
      bgColor="white.normal"
      pos="relative"
    >
      <CardBody px="2px">
        <Flex mb="4">
          <Flex w="full" px="5">
            <Text color="black.active" fontSize="20px" fontWeight="600">
              {t("invoices")}
            </Text>
          </Flex>
          {/* <Flex gap="5" px="2">
            <Text
              fontSize="14px"
              fontWeight="600"
              color="black.active"
              borderBottom="1px solid rgba(28, 142, 235, 0.6)"
            >
              {" "}
              {t("month")}
            </Text>
            <Text fontSize="14px" fontWeight="600" color="gray.passive">
              2023
            </Text>
            <Text fontSize="14px" fontWeight="600" color="gray.passive">
              2022
            </Text>
          </Flex> */}
        </Flex>
        <Divider borderColor="#EBEDF2" my="2" />
        <TableContainer
          justifyContent="space-between"
          alignItems="center"
          h="320px"
        >
          <Table variant="simple" justifyContent="space-between">
            <thead>
              <Tr justifyContent="space-between">
                <Th borderWidth="0" w="25%" style={{ textTransform: "none" }}>
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("order_id")}
                  </Text>
                </Th>
                <Th
                  borderWidth="0"
                  textAlign="center"
                  w="25%"
                  style={{ textTransform: "none" }}
                >
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("user")}
                  </Text>
                </Th>
                <Th
                  borderWidth="0"
                  textAlign="center"
                  w="25%"
                  style={{ textTransform: "none" }}
                >
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("date")}
                  </Text>
                </Th>
                <Th
                  borderWidth="0"
                  textAlign="center"
                  w="25%"
                  style={{ textTransform: "none" }}
                >
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("payed")}
                  </Text>
                </Th>
                <Th
                  borderWidth="0"
                  textAlign="center"
                  w="25%"
                  style={{ textTransform: "none" }}
                >
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("Status")}
                  </Text>
                </Th>
                <Th borderWidth="0" textAlign="center" w="20%">
                  <Text
                    color="black.active"
                    fontWeight="600"
                    fontSize="14px"
                    textAlign="start"
                  >
                    {t("invoice")}
                  </Text>
                </Th>
              </Tr>
            </thead>
            <Tbody mt="2">
              {currentPageData?.map((item) => (
                <Tr
                  key={item.id}
                  alignItems="center"
                  borderBottom="1px solid rgba(144, 123, 123, 0.08)"
                >
                  <Td borderWidth="0">
                    <Text
                      color="#A1A5B7"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="14px"
                    >
                      {item.merchant_oid}
                    </Text>
                  </Td>
                  <Td borderWidth="0">
                    <Text
                      color="#A1A5B7"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="14px"
                    >
                      {item.user}
                    </Text>
                  </Td>
                  <Td borderWidth="0">
                    <Text
                      color="#A1A5B7"
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="14px"
                    >
                      {i18nformat(
                        parseISO(item.created_at),
                        language === "tr" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                      )}
                    </Text>
                  </Td>

                  <Td borderWidth="0">
                    <Text
                      color={
                        item.status === "completed"
                          ? "green.label"
                          : item.status === "cancelled" ||
                              item.status === "failed"
                            ? "red.main"
                            : item.status === "pending"
                              ? "yellow.label"
                              : "gray.passive"
                      }
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="1"
                    >
                      {item.payed_price
                        ? `${item.payed_currency}${item.payed_price}`
                        : "-"}
                    </Text>
                  </Td>

                  <Td borderWidth="0">
                    <Text
                      color={
                        item.status === "completed"
                          ? "green.label"
                          : item.status === "cancelled"
                            ? "black.active"
                            : item.status === "failed"
                              ? "red.main"
                              : item.status === "pending"
                                ? "yellow.label"
                                : "gray.passive"
                      }
                      fontSize="16px"
                      fontWeight="600"
                      lineHeight="1"
                    >
                      {t(`payment-status.${item.status}`)}
                    </Text>
                  </Td>
                  <Td borderWidth="0">
                    {item.invoice_url ? (
                      <Text
                        as="a"
                        cursor="pointer"
                        href={item.invoice_url + token + "/"}
                        target="_blank"
                        color="blue.hashtag"
                        fontSize="16px"
                        fontWeight="600"
                        lineHeight="1"
                        textDecoration="underline"
                      >
                        {t("invoice")}
                      </Text>
                    ) : (
                      <Text
                        fontSize="16px"
                        height="20px"
                        fontWeight="600"
                        lineHeight="1"
                      >
                        -
                      </Text>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {data?.length !== 0 && (
          <Flex p={4} justifyContent="center" w="full">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={handlePageChange}
            />
          </Flex>
        )}
      </CardBody>
    </Card>
  );
};
