import { useToast, Show } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import { POST } from "@/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ForgotPasswordDesktop } from "./desktop";
import { ForgotPasswordMobile } from "./mobile/mobile";
import { z } from "@/i18n";
import { i18n } from "@/i18n";

export type resetPasswordSchema = z.infer<typeof resetPasswordSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const resetPasswordSchema = z.object({
  email: z.string().email(),
});

export type setPasswordSchema = z.infer<typeof setPasswordSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const setPasswordSchema = z
  .object({
    password: z
      .string()
      .min(8, i18n.t("errors.min-character", { num: 8 }))
      .max(16, i18n.t("errors.max-character", { num: 16 }))
      .refine((password) => password.trim() === password, {
        message: i18n.t("errors.password-should-not-start-or-end-with-space"),
      }),
    confirmPassword: z.string(),
    verification_code: z.string(),
  })
  .refine((input) => input.password === input.confirmPassword, {
    path: ["confirmPassword"],
  });

export const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { t } = useTranslation();
  const resetPasswordMethods = useForm<resetPasswordSchema>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const setPasswordMethods = useForm<setPasswordSchema>({
    resolver: zodResolver(setPasswordSchema),
  });

  const onSubmitSendEmail: SubmitHandler<resetPasswordSchema> = useCallback(
    async ({ email }) => {
      const { response, error } = await POST("/auth/reset-password/", {
        body: { email },
      });

      if (response.ok) {
        toast({
          description: t("alert.success.verification-code-sent"),
          status: "success",
          isClosable: true,
        });
        return;
      }
      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
        navigate("/auth/signup");
        return;
      }
    },
    [toast, t, navigate]
  );

  const handleVerificationCode = useCallback(
    async (verification_code: string) => {
      const { response, error } = await POST("/auth/check-verification-code/", {
        body: { verification_code },
      });
      if (response.status === 204) {
        toast({
          description: t("page.forgot-password.verification-code-approved"),
          status: "success",
          isClosable: true,
        });
      } else {
        toast({
          description: error?.detail,
          status: "error",
          isClosable: true,
        });
      }
      return response.ok;
    },
    [toast, t]
  );

  const setPassword: SubmitHandler<setPasswordSchema> = useCallback(
    async ({ password, verification_code }) => {
      const email = resetPasswordMethods.getValues("email");

      if (!email) {
        toast({
          description: t("errors.email-can-not-be-empty"),
          status: "error",
          isClosable: true,
        });
        return;
      }

      const { response, error } = await POST("/auth/set-password/", {
        body: {
          email,
          verification_code,
          password,
        },
      });

      if (response.ok) {
        toast({
          status: "success",
          title: t("alert.success.password-changed-successfully"),
        });
        navigate("/auth/login");

        return;
      }

      if (error) {
        if (error.issues) {
          error.issues.forEach((issue) => {
            if (!issue.path) return;
            setPasswordMethods.setError(issue.path as any, {
              message: issue.message,
            });
          });
          return;
        }
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
        });

        return;
      }
    },
    [navigate, toast, resetPasswordMethods, t]
  );

  return (
    <>
      <Show above="md">
        <ForgotPasswordDesktop
          handleVerificationCode={handleVerificationCode}
          resetPasswordForm={resetPasswordMethods}
          setPasswordForm={setPasswordMethods}
          onSubmitResetPassword={resetPasswordMethods.handleSubmit(
            onSubmitSendEmail
          )}
          onSubmitSetPassword={setPasswordMethods.handleSubmit(setPassword)}
        />
      </Show>
      <Show below="md">
        <ForgotPasswordMobile
          handleVerificationCode={handleVerificationCode}
          resetPasswordForm={resetPasswordMethods}
          setPasswordForm={setPasswordMethods}
          onSubmitResetPassword={resetPasswordMethods.handleSubmit(
            onSubmitSendEmail
          )}
          onSubmitSetPassword={setPasswordMethods.handleSubmit(setPassword)}
        />
      </Show>
    </>
  );
};
