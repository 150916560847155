import React from "react";

export const EyeIconInvisible = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_3156_7702"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="26"
        height="26"
      >
        <path d="M0 0H27V27H0V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_3156_7702)">
        <path
          d="M25.8926 13.5C25.8926 13.5 21.8848 21.9375 13.4473 21.9375C5.00977 21.9375 1.00195 13.5 1.00195 13.5C1.00195 13.5 5.00977 5.0625 13.4473 5.0625C21.8848 5.0625 25.8926 13.5 25.8926 13.5Z"
          stroke="#B7BABF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.666 13.5C17.666 11.17 15.7772 9.28125 13.4473 9.28125C11.1173 9.28125 9.22852 11.17 9.22852 13.5C9.22852 15.83 11.1173 17.7188 13.4473 17.7188C15.7772 17.7188 17.666 15.83 17.666 13.5Z"
          stroke="#B7BABF"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
