import { Box, Button, Flex, IconButton, Input, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { HelpCenterAccordion } from "../components/accordion/accordion";
import { TicketIcon } from "@/assets/icons/ticket-icon";
import { SearchGuideIcon } from "@/assets/icons/search-guide-icon";

import { InfoIcon } from "@/assets/icons/info-icon";
import { useTranslation } from "react-i18next";
import { SearchArrowIcon } from "@/assets/icons/search-arrow-icon";
import { colors } from "@/theme/colors";

type TicketAnswers = {
  id: string;
  title: string;
  message: string;
};

interface TicketsComponentProps {
  ticketAnswers: TicketAnswers[];
}

export const Tickets: React.FC<TicketsComponentProps> = ({ ticketAnswers }) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedTitle, setSelectedTitle] = useState<string>("");

  const { t } = useTranslation();

  // const groupedTicketList = useMemo(() => {
  //   return ticketAnswers.reduce(
  //     (acc, ticket) => {
  //       if (!acc[ticket.title]) {
  //         acc[ticket.title] = [];
  //       }
  //       acc[ticket.title].push(ticket);
  //       return acc;
  //     },
  //     {} as Record<string, TicketAnswers[]>
  //   );
  // }, [ticketAnswers]);

  // if (
  //   groupedTicketList &&
  //   Object.keys(groupedTicketList).length > 0 &&
  //   selectedTitle === ""
  // ) {
  //   setSelectedTitle(Object.keys(groupedTicketList)[0]);
  // }

  // const filteredListTitle = ({
  //   ticketAnswers,
  //   selectedTitle,
  //   searchInput,
  // }: {
  //   ticketAnswers: TicketAnswers[];
  //   selectedTitle: string;
  //   searchInput: string;
  // }) => {
  //   if (selectedTitle) {
  //     return groupedTicketList[selectedTitle] || [];
  //   } else {
  //     return ticketAnswers.filter((ticket) =>
  //       ticket.title.toLowerCase().includes(searchInput.toLowerCase())
  //     );
  //   }
  // };

  const groupedTicketList = useMemo(() => {
    return ticketAnswers.reduce(
      (acc, ticket) => {
        if (!acc[ticket.title]) {
          acc[ticket.title] = [];
        }
        acc[ticket.title].push(ticket);
        return acc;
      },
      {} as Record<string, TicketAnswers[]>
    );
  }, [ticketAnswers]);

  useEffect(() => {
    if (Object.keys(groupedTicketList).length > 0 && !selectedTitle) {
      setSelectedTitle(Object.keys(groupedTicketList)[0]);
    }
  }, [groupedTicketList, selectedTitle]);

  const filteredListTitle = useMemo(() => {
    if (searchInput) {
      const lowerSearchInput = searchInput.toLowerCase();
      return ticketAnswers.filter(
        (ticket) => ticket.title.toLowerCase().includes(lowerSearchInput)
        // ||
        // faq.question.toLowerCase().includes(lowerSearchInput) ||
        // faq.answer.toLowerCase().includes(lowerSearchInput)
      );
    } else if (selectedTitle) {
      return groupedTicketList[selectedTitle] || [];
    } else {
      return [];
    }
  }, [ticketAnswers, searchInput, selectedTitle, groupedTicketList]);

  return (
    <Flex w="full" columnGap="12px">
      <Box flex="1" pos="relative" h="80vh">
        <Flex
          w="full"
          justifyContent="center"
          alignItems="center"
          borderRadius="6px"
          mb="20px"
          bg="white"
          p="8px"
        >
          <IconButton
            variant="unstyled"
            size="sm"
            aria-label="search-button"
            icon={<SearchGuideIcon />}
            ml="5px"
          />
          <Input
            variant="unstyled"
            placeholder={t("page.help-center.search")}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSelectedTitle("");
            }}
            value={searchInput}
          />
        </Flex>

        {groupedTicketList &&
          Object.keys(groupedTicketList)
            .filter((title) =>
              title.toLowerCase().includes(searchInput.toLowerCase())
            )
            .map((title, index) => (
              <Box key={index}>
                <Button
                  w="full"
                  h="48px"
                  variant="unstyled"
                  textAlign="left"
                  bg={selectedTitle === title ? "purple.light" : "none"}
                  borderRadius="3px"
                  border={selectedTitle === title ? "1px solid " : "none"}
                  borderColor={
                    selectedTitle === title ? "purple.normal" : "none"
                  }
                  px={selectedTitle === title ? "6px" : "3px"}
                  onClick={() => {
                    setSelectedTitle(title);
                  }}
                >
                  <Flex justifyContent="space-between" alignItems="center">
                    <Text
                      color={
                        selectedTitle === title
                          ? "purple.normal"
                          : "black.active"
                      }
                      opacity={selectedTitle === title ? "1" : "0.4"}
                      fontWeight="400"
                    >
                      {title}
                    </Text>
                    <Box display={selectedTitle === title ? "block" : "none"}>
                      <SearchArrowIcon fill={colors.purple.normal} />
                    </Box>
                  </Flex>
                </Button>
              </Box>
            ))}
        {/* <ContactUsComponent color="#9631FB" /> */}
      </Box>

      <Flex flex="3" borderRadius="3px" h="80vh" overflowY="auto">
        <HelpCenterAccordion
          children={<TicketIcon />}
          accordionData={filteredListTitle}
          infoIcon={<InfoIcon />}
        />
      </Flex>
    </Flex>
  );
};
