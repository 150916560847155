import {
  Text,
  Heading,
  VStack,
  HStack,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Box,
  Center,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { FC, Ref, useState } from "react";
import { useFormContext } from "react-hook-form";
import { LoginSchema } from ".";
import { useTranslation } from "react-i18next";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { TURNSTILE_TOKEN } from "@/constant";

export const LoginDesktop: FC<{
  onSubmit: () => void;
  disabled: boolean;
  turnstileRef: Ref<TurnstileInstance | undefined>;
}> = ({ onSubmit, disabled, turnstileRef }) => {
  const { t } = useTranslation();
  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const [captcha, setCaptcha] = useState<boolean>(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<LoginSchema>();

  return (
    <VStack flexDir="column" h="full" w="full" textAlign="center" bg="white">
      <VStack>
        <Heading
          mt="114px"
          alignSelf="center"
          lineHeight="60px"
          fontWeight="500"
          fontSize={["32px", "36px", "40px", "48px"]}
          mb="11px"
        >
          {t("page.login.welcome")}
        </Heading>
        <Flex justifyContent="center" alignItems="center" textAlign="center">
          <Text
            color="blue.brand"
            fontSize={["16px", "16px", "20px", "20px"]}
            lineHeight="25px"
          >
            {t("page.login.message.subtitle")}
          </Text>
          <Text
            ml="8px"
            fontSize={["16px", "16px", "20px", "20px"]}
            fontWeight="600"
            color="red.main"
            lineHeight="25px"
            borderColor="red.main"
            borderBottom="1px"
          >
            <Link to="/auth/signup">{t("page.register.create-now")}</Link>
          </Text>
        </Flex>
      </VStack>

      <Box
        as="form"
        w="60%"
        mt="45px"
        onSubmit={onSubmit}
        sx={{
          "& input": {
            color: "gray.passive",
          },
        }}
      >
        <FormControl mb="46px" isInvalid={!!errors.email}>
          <FormLabel
            pos="absolute"
            top="-10px"
            fontSize="16px"
            zIndex="9"
            ml="2.5"
            px="4"
            fontWeight="400"
            lineHeight="20px"
            pr="8"
            bg="white"
          >
            {t("input.email-address")}
          </FormLabel>
          <Input height="72px" type="text" {...register("email")} />

          <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
        </FormControl>

        <FormControl
          justifyContent="space-between"
          flexDirection="row"
          alignItems="center"
          isInvalid={!!errors.password}
        >
          <FormLabel
            fontWeight="400"
            lineHeight="20px"
            pos="absolute"
            top="-10px"
            fontSize="16px"
            zIndex="9"
            ml="2.5"
            px="4"
            pr="8"
            bg="white"
          >
            {t("input.password")}
          </FormLabel>
          <InputGroup>
            <Input
              height="72px"
              type={passwordVisible ? "text" : "password"}
              {...register("password")}
            />

            <InputRightElement
              justifyContent="center"
              alignItems="center"
              h="full"
              mr="3"
            >
              <IconButton
                cursor="pointer"
                size="xs"
                variant="unstyled"
                aria-label="password"
                onClick={() => setPasswordVisible((p) => !p)}
                icon={!passwordVisible ? <EyeIcon /> : <EyeIconInvisible />}
              />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>

        <Center mt="3">
          <Turnstile
            ref={turnstileRef}
            siteKey={TURNSTILE_TOKEN}
            options={{
              theme: "light",
            }}
            onSuccess={(token) => {
              setCaptcha(true);
              setValue("captchaToken", token);
            }}
          />
        </Center>

        <HStack
          my="34px"
          w="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box />
          {/* <Checkbox
            sx={{
              "& .chakra-checkbox__control": {
                rounded: "3px",
                borderColor: "blue.shiny",
                borderWidth: "1px solid",
              },
            }}
            size="md"
          >
            <Text
              color="black.active"
              fontWeight="400"
              lineHeight="20px"
              fontSize="16px"
              display="flex"
              flexWrap="nowrap"
            >
              {t("page.login.remember_me")}
            </Text>
          </Checkbox> */}

          <Link to="/auth/forgot-password">
            <Text fontSize="16px" textColor="blue.shiny" fontWeight="600">
              {t("page.login.forgot_password")}?
            </Text>
          </Link>
        </HStack>

        <Button
          fontWeight="700"
          bg="blue.shiny"
          textColor="#fff"
          borderRadius="6px"
          lineHeight="40px"
          _hover={{
            opacity: 0.8,
          }}
          fontSize="28px"
          h="60px"
          w="full"
          type="submit"
          isDisabled={!captcha || disabled}
        >
          {t("continue")}
        </Button>
      </Box>
    </VStack>
  );
};
