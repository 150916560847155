export const YoutubeCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.7694 0.628662H6.23062C2.78954 0.628662 0 3.40274 0 6.82475V15.5437C0 18.9657 2.78954 21.7398 6.23062 21.7398H23.7694C27.2105 21.7398 30 18.9657 30 15.5437V6.82475C30 3.40274 27.2105 0.628662 23.7694 0.628662ZM19.5557 11.6084L11.3522 15.4993C11.1336 15.603 10.8811 15.4445 10.8811 15.2037V7.17874C10.8811 6.9345 11.1403 6.77621 11.3593 6.88662L19.5628 11.0207C19.8067 11.1436 19.8024 11.4914 19.5557 11.6084Z"
        fill={props.fill || "#F61C0D"}
      />
    </svg>
  );
};
