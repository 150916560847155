import { POST } from "@/api";
import { useInvitationStore } from "@/context/invitation-store/invitation-store";
import {
  CircularProgress,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";

const InviteCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");
  const { t } = useTranslation();
  const toast = useToast();

  const { setInvitation } = useInvitationStore((state) => ({
    setInvitation: state.setInvitation,
  }));

  useEffect(() => {
    if (token) {
      POST("/invitation/token-control/", { body: { token } }).then(
        ({ data, error }) => {
          if (error?.detail || error?.issues) {
            navigate("/auth/signup");
            toast({
              status: "error",
              title:
                error?.detail ||
                error.issues?.at(0)?.message ||
                t("errors.an-error-occurred"),
            });
            return;
          }
          setInvitation({ token, action: "accept" });

          toast({
            status: "success",
            title: t("alert.invitation.please-register-to-accept-invitation"),
          });

          if (data?.email) {
            navigate(`/auth/signup?email=${data?.email}`);
            return;
          }

          navigate("/auth/signup");
        }
      );
    } else {
      toast({
        status: "success",
        title: t("alert.invitation.please-register-to-accept-invitation"),
      });
      navigate("/auth/signup");
    }
  }, [navigate, setInvitation, t, toast, token]);

  return (
    <Flex
      height="100vh"
      width="100%"
      flexDirection="column"
      justifyContent="center"
    >
      <VStack spacing={4}>
        <CircularProgress isIndeterminate color="green.300" />
        <Text fontSize="xl">{t("invite-process")}...</Text>
      </VStack>
    </Flex>
  );
};

export default InviteCallbackPage;
