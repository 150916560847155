import {
  Text,
  HStack,
  Checkbox,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Icon,
  Box,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Center,
} from "@chakra-ui/react";
import { useState, FC, Ref } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import { Link as RouteLink } from "react-router-dom";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { RegisterSchema } from ".";
import { PostEffectIcon } from "../../../assets/icons/post-effect-icon";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { TURNSTILE_TOKEN } from "@/constant";

export const RegisterMobile: FC<{
  onSubmit: () => void;
  disabled: boolean;
  turnstileRef: Ref<TurnstileInstance | undefined>;
}> = ({ onSubmit, disabled, turnstileRef }) => {
  const { t } = useTranslation();
  const [terms, checkTerms] = useState<boolean>();

  const [passwordVisibility, setPasswordVisibility] = useState<{
    pass: boolean;
    rePass: boolean;
  }>({
    pass: false,
    rePass: false,
  });

  const [captcha, setCaptcha] = useState<boolean>(false);

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<RegisterSchema>();

  return (
    <>
      <Flex direction="row" alignItems="center" position="absolute" m="4">
        <Icon w="8" h="8" mr="3">
          <PostEffectIcon />
        </Icon>
      </Flex>
      <Box
        pt="9"
        bg="white"
        h="100dvh"
        w="100dvw"
        px="8"
        pb="10"
        alignItems="center"
        justifyContent="center"
      >
        <Box textAlign="center" mt="6">
          <Text
            color="black.active"
            fontWeight="semibold"
            fontSize="28px"
            mb="4"
          >
            {t("page.register.create_an_account")}
          </Text>

          <Flex
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            position="relative"
            bottom="3"
          >
            <Text
              color="gray.passive"
              fontSize="13px"
              textAlign="center"
              flexDirection="row"
            >
              {t("page.register.message.subtitle")}
            </Text>
            <Link
              fontSize="13px"
              ml="2"
              as={RouteLink}
              textDecor="underline"
              color="gray.antrasit"
              to="/auth/login/"
            >
              {t("link.login")}
            </Link>
          </Flex>
        </Box>

        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box as="form" onSubmit={onSubmit} mt="0" w="80dvw">
            <FormControl isInvalid={!!errors.first_name}>
              <FormLabel
                position="relative"
                top="1"
                fontSize="md"
                zIndex="9"
                fontWeight="semibold"
                bg="white"
              >
                {t("input.name")}
              </FormLabel>
              <Input
                type="text"
                rounded="full"
                {...register("first_name")}
                placeholder={t("input.placeholder.first_name")}
                _placeholder={{
                  fontSize: "14px",
                }}
              />

              <FormErrorMessage>
                {" "}
                {errors.first_name?.message}{" "}
              </FormErrorMessage>
            </FormControl>

            <FormControl mt="1" isInvalid={!!errors.last_name}>
              <FormLabel
                position="relative"
                top="1"
                fontSize="md"
                zIndex="9"
                fontWeight="semibold"
                bg="white"
              >
                {t("input.surname")}
              </FormLabel>
              <Input
                _placeholder={{
                  fontSize: "14px",
                }}
                type="text"
                rounded="full"
                {...register("last_name")}
                placeholder={t("input.placeholder.last_name")}
              />

              <FormErrorMessage> {errors.last_name?.message} </FormErrorMessage>
            </FormControl>

            <FormControl mt="1" isInvalid={!!errors.email}>
              <FormLabel
                position="relative"
                top="1"
                fontSize="md"
                zIndex="9"
                fontWeight="semibold"
                bg="white"
              >
                {t("input.email-address")}
              </FormLabel>
              <Input
                _placeholder={{
                  fontSize: "14px",
                }}
                type="text"
                rounded="full"
                {...register("email")}
                placeholder={t("input.placeholder.email-address")}
              />

              <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
            </FormControl>

            <FormControl
              mt="1"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              isInvalid={!!errors.password}
            >
              <FormLabel
                fontWeight="semibold"
                fontSize="md"
                zIndex="9"
                bg="white"
                position="relative"
                top="1"
              >
                {t("input.create_password")}
              </FormLabel>
              <InputGroup>
                <Input
                  _placeholder={{
                    fontSize: "14px",
                  }}
                  rounded="full"
                  type={passwordVisibility.pass ? "text" : "password"}
                  placeholder={t("input.placeholder.create_password")}
                  {...register("password")}
                />

                <InputRightElement>
                  <IconButton
                    cursor="pointer"
                    size="xs"
                    variant="unstyled"
                    aria-label={
                      passwordVisibility.rePass
                        ? "hide-password"
                        : "show-password"
                    }
                    onClick={() =>
                      setPasswordVisibility((p) => ({ ...p, pass: !p.pass }))
                    }
                    icon={
                      !passwordVisibility.pass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>

            <FormControl
              mt="1"
              justifyContent="space-between"
              flexDirection="row"
              alignItems="center"
              isInvalid={!!errors.confirmPassword}
            >
              <FormLabel
                position="relative"
                top="1"
                fontWeight="semibold"
                fontSize="md"
                zIndex="9"
                bg="white"
              >
                {t("input.confirm_password")}
              </FormLabel>
              <InputGroup>
                <Input
                  _placeholder={{
                    fontSize: "14px",
                  }}
                  rounded="full"
                  type={passwordVisibility.rePass ? "text" : "password"}
                  placeholder={t("input.placeholder.confirm_password")}
                  {...register("confirmPassword")}
                />

                <InputRightElement>
                  <IconButton
                    cursor="pointer"
                    size="xs"
                    variant="unstyled"
                    aria-label={
                      passwordVisibility.rePass
                        ? "hide-password"
                        : "show-password"
                    }
                    onClick={() =>
                      setPasswordVisibility((p) => ({
                        ...p,
                        rePass: !p.rePass,
                      }))
                    }
                    icon={
                      !passwordVisibility.rePass ? (
                        <EyeIcon />
                      ) : (
                        <EyeIconInvisible />
                      )
                    }
                  />
                </InputRightElement>
              </InputGroup>

              <FormErrorMessage>
                {errors.confirmPassword?.message}
              </FormErrorMessage>
            </FormControl>

            <Center mt="3">
              <Turnstile
                ref={turnstileRef}
                siteKey={TURNSTILE_TOKEN}
                onSuccess={(token) => {
                  setCaptcha(true);
                  setValue("captchaToken", token);
                }}
              />
            </Center>

            <HStack
              mt="2.5"
              w="full"
              justifyContent="space-between"
              alignItems="center"
            >
              <Checkbox
                variant="circular"
                color="#345A79"
                borderRadius="6px"
                size="md"
                onChange={(e) => checkTerms(e.currentTarget.checked)}
              >
                <Text
                  ml={1}
                  fontSize={{ base: "10px", sm: "12px", md: "1px", lg: "14px" }}
                  alignSelf="center"
                  lineHeight="20px"
                  fontWeight="400px"
                  flexWrap="wrap"
                  display="flex"
                  color="#000000"
                >
                  {t("page.register.message.terms.by-creating-an-account")}
                  <Link
                    style={{
                      borderBottom: "1px solid black",
                      display: "inline-block",
                    }}
                    _hover={{
                      borderBottom: "none",
                    }}
                    as={RouteLink}
                    to="/terms"
                    target="_blank"
                    ml="1"
                    mr="1"
                  >
                    {t("page.register.message.terms.terms-and-conditions")}
                  </Link>
                  {t("page.register.message.terms.and-our")}
                  <Link
                    ml="1"
                    _hover={{
                      borderBottom: "none",
                    }}
                    style={{
                      borderBottom: "1px solid black",
                      display: "inline-block",
                    }}
                    as={RouteLink}
                    to={"/privacy"}
                    target="_blank"
                  >
                    {t("page.register.message.terms.privacy-policy")}
                  </Link>
                </Text>
              </Checkbox>
            </HStack>

            <Flex w="full" flexDir="column" mt="4" px="3">
              <Button
                variant="unstyled"
                type="submit"
                bg="gray.antrasit"
                fontWeight="semibold"
                fontSize="xl"
                w="full"
                color="white.normal"
                isDisabled={!terms || !captcha || disabled}
              >
                {t("button.signup")}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};
