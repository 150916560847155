import React, { SVGAttributes } from "react";

interface CustomSVGProps extends SVGAttributes<SVGElement> {
  active?: boolean;
}

export const SingleChevronLeftIcon: React.FC<CustomSVGProps> = ({
  active,
  ...rest
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#EBEDF2" />
      <path
        d="M18.06 20L19 19.06L15.9467 16L19 12.94L18.06 12L14.06 16L18.06 20Z"
        fill={active ? "#57AAEB" : "#A1A5B7"}
      />
    </svg>
  );
};
