import {
  FacebookBlueIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/assets/brands";
import { TelegramCircleIcon } from "@/assets/brands/circle/telegram-circle";
import { TiktokCircleIcon } from "@/assets/brands/circle/tiktok-circle";
import {
  Flex,
  Text,
  Box,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Button,
  PopoverBody,
} from "@chakra-ui/react";
import React, { FC, useState } from "react";
import { useChannel } from "@/hooks/use-channels";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon } from "@chakra-ui/icons";
import { ChevronDownIcon } from "@chakra-ui/icons";

export const AnalyticsHeader: FC = () => {
  const [active, setActive] = useState<string>("Twitter");
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [activeChannel, setActiveChannel] = useState<string | undefined>();
  const { data: Channels } = useChannel();
  const { t } = useTranslation();

  return (
    <Flex w="full" height="60px" bgColor="white" p="16px">
      <Flex
        bgColor="gray.whiteSmoke"
        h="30px"
        px="16px"
        alignItems="center"
        width="max-content"
        gap="24px"
        pr="0px"
        borderRadius="6px"
      >
        <IconButton
          opacity={active === "Twitter" ? "1" : "0.40"}
          aria-label="account"
          icon={<TwitterIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Twitter")}
        />
        <IconButton
          opacity={active === "Linkedin" ? "1" : "0.40"}
          aria-label="account"
          icon={<LinkedinIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Linkedin")}
        />
        <IconButton
          opacity={active === "Facebook" ? "1" : "0.40"}
          aria-label="account"
          icon={<FacebookBlueIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Facebook")}
        />
        <IconButton
          opacity={active === "Instagram" ? "1" : "0.40"}
          aria-label="account"
          icon={<InstagramColorfulIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Instagram")}
        />
        <IconButton
          opacity={active === "Youtube" ? "1" : "0.40"}
          aria-label="account"
          icon={<YoutubeIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Youtube")}
        />
        <IconButton
          opacity={active === "Telegram" ? "1" : "0.40"}
          aria-label="account"
          icon={<TelegramCircleIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Telegram")}
        />
        <IconButton
          opacity={active === "Tiktok" ? "1" : "0.40"}
          aria-label="account"
          icon={<TiktokCircleIcon width="21px" height="21px" />}
          variant="unstyled"
          onClick={() => setActive("Tiktok")}
        />
      </Flex>

      <Flex ml="20px">
        <Popover isOpen={popoverOpen} onClose={() => setPopoverOpen(false)}>
          <PopoverTrigger>
            <Button
              bgColor="gray.whiteSmoke"
              width="170px"
              height="30px"
              onClick={() => setPopoverOpen(!popoverOpen)}
            >
              <Flex justifyContent="space-between" alignItems="center" w="full">
                <Text
                  textAlign="center"
                  as="span"
                  textOverflow="ellipsis"
                  overflow="hidden"
                  fontSize="13px"
                  lineHeight="14px"
                  color="black.active"
                  fontWeight="450"
                >
                  {activeChannel
                    ? activeChannel
                    : t("page.dashboard.all-accounts")}
                </Text>
                {popoverOpen ? (
                  <Box>
                    <ChevronUpIcon color="black.active" />
                  </Box>
                ) : (
                  <Box>
                    <ChevronDownIcon color="black.active" />
                  </Box>
                )}
              </Flex>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bgColor="gray.whiteSmoke"
            width="170px"
            height="100px"
          >
            <PopoverBody p="0px">
              {Channels?.map((channel) => (
                <React.Fragment key={channel.id}>
                  <Button
                    px="2"
                    _hover={{ bg: "gray:soft" }}
                    variant="unstyled"
                    textOverflow="ellipsis"
                    fontSize="13px"
                    w="full"
                    fontWeight="450"
                    color="black.active"
                    onClick={() => {
                      setActiveChannel(channel.name);
                      setPopoverOpen(false);
                    }}
                  >
                    <Text>{channel.name}</Text>
                  </Button>
                </React.Fragment>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
    </Flex>
  );
};
