export const CrossCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="37"
      height="37"
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="18.5" cy="18.5" r="18.5" fill="#57AAEC" />
      <path
        d="M11.811 27.8511C11.4735 27.8534 11.139 27.789 10.8264 27.6617C10.5139 27.5344 10.2296 27.3466 9.98989 27.1091C9.51623 26.6349 9.25018 25.992 9.25018 25.3218C9.25018 24.6515 9.51623 24.0086 9.98989 23.5344L23.4795 10.3483C23.6937 10.0362 23.9752 9.77605 24.3033 9.5872C24.6314 9.39834 24.9977 9.28555 25.3752 9.25713C25.7527 9.22872 26.1318 9.28542 26.4845 9.42304C26.8372 9.56066 27.1545 9.77569 27.413 10.0523C27.6715 10.3289 27.8646 10.66 27.978 11.0211C28.0915 11.3823 28.1225 11.7643 28.0686 12.1391C28.0148 12.5138 27.8775 12.8717 27.6669 13.1863C27.4563 13.5009 27.1778 13.7642 26.8519 13.9568L13.3623 27.1428C12.9396 27.5468 12.3931 27.7964 11.811 27.8511Z"
        fill="#EEF6FF"
      />
      <path
        d="M25.5334 27.8511C25.8708 27.8534 26.2054 27.789 26.5179 27.6617C26.8304 27.5344 27.1147 27.3466 27.3545 27.1091C27.8281 26.6349 28.0942 25.992 28.0942 25.3218C28.0942 24.6515 27.8281 24.0086 27.3545 23.5344L13.8649 10.3483C13.6507 10.0362 13.3692 9.77605 13.0411 9.5872C12.713 9.39834 12.3466 9.28555 11.9691 9.25713C11.5916 9.22872 11.2125 9.28542 10.8599 9.42304C10.5072 9.56066 10.1899 9.77569 9.9314 10.0523C9.67291 10.3289 9.4798 10.66 9.36632 11.0211C9.25284 11.3823 9.22188 11.7643 9.27573 12.1391C9.32958 12.5138 9.46687 12.8717 9.67746 13.1863C9.88805 13.5009 10.1666 13.7642 10.4925 13.9568L23.9821 27.1428C24.4047 27.5468 24.9513 27.7964 25.5334 27.8511Z"
        fill="#EEF6FF"
      />
    </svg>
  );
};
