export const ReachBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.631836" width="43" height="45" rx="3" fill="#C9DBFA" />
      <path
        d="M33.4601 21.9461C33.2548 21.6639 28.3168 15.026 22.1319 15.026C15.9469 15.026 11.0089 21.6639 10.8036 21.9461C10.5746 22.2616 10.5746 22.7384 10.8036 23.0539C11.0089 23.3361 15.947 29.974 22.1319 29.974C28.3167 29.974 33.2548 23.3361 33.4601 23.0539C33.6891 22.7384 33.6891 22.2616 33.4601 21.9461ZM22.1319 28.3131C19.5313 28.3131 17.4151 25.705 17.4151 22.5C17.4151 19.295 19.5313 16.6869 22.1319 16.6869C24.7324 16.6869 26.8486 19.295 26.8486 22.5C26.8486 25.705 24.7324 28.3131 22.1319 28.3131Z"
        fill="#57AAEC"
      />
      <path
        d="M22.8059 20.8391C22.8059 20.0037 23.1424 19.2687 23.6543 18.8167C23.195 18.5269 22.6826 18.3478 22.1321 18.3478C20.2744 18.3478 18.7629 20.2106 18.7629 22.5C18.7629 24.7894 20.2744 26.6522 22.1321 26.6522C23.7953 26.6522 25.1719 25.1555 25.4438 23.1999C24.0869 23.7383 22.8059 22.4735 22.8059 20.8391Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
