import { create } from "zustand";

interface TriggerState {
  organizationChanges: number;
  notificationChanges: number;
  triggerOrganization: () => void;
  triggerNotification: () => void;
}

export const useTriggerStore = create<TriggerState>()((set) => ({
  organizationChanges: 0,

  triggerOrganization: () => {
    set({ organizationChanges: Math.random() });
  },

  notificationChanges: 0,
  triggerNotification() {
    set({ notificationChanges: Math.random() });
  },
}));
