import {
  Avatar,
  Box,
  HStack,
  VStack,
  Text,
  Image,
  AspectRatio,
  Flex,
} from "@chakra-ui/react";
import { FC, useMemo, useRef } from "react";
import { Forms, useFormStore } from "../form-store";
import { mainSchema } from "../form-containers";
import { formatDistanceToNow } from "date-fns";
import { randInt } from "@/utils/misc";
import { useTranslation } from "react-i18next";

import React from "react";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";

export const FacebookPreview: FC = () => {
  const { facebook } = useFormStore((s) => s.previews);
  const { t } = useTranslation();
  const { forms, mainForms } = useFormStore((s) => ({
    forms: s.forms,
    mainForms: s.mainForms,
  }));

  const scheduledAt = useFormStore((s) => s.scheduledAt);

  const dataRef = useRef<Forms | mainSchema>();

  const [others, comments] = useMemo(
    () => [randInt(100, 10000), randInt(100, 1000)],
    []
  );

  if (!facebook) {
    return null;
  }

  if (facebook.formType !== "normal") {
    dataRef.current = mainForms[facebook.formType];
  }

  if (facebook.channel) {
    dataRef.current = forms[facebook.channel.id.toString()];
  }

  if (!dataRef.current) {
    return null;
  }

  const name = facebook.channel?.name ?? "Facebook";

  return (
    <Box
      w="full"
      maxW="468px"
      h="min-content"
      bg="white"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      p="3"
    >
      <HStack gap="2">
        <Avatar
          w="8"
          h="8"
          name={name.replace("@", "")}
          src={facebook.channel?.picture ?? undefined}
        />
        <VStack gap="1.5" alignItems="flex-start">
          <Text
            fontSize="md"
            lineHeight="5"
            fontWeight="semibold"
            color="#353B48"
          >
            {name.replace("@", "")}
          </Text>
          <Text color="#A9AAAD" fontSize="xs" fontWeight="light">
            {formatDistanceToNow(scheduledAt)}
          </Text>
        </VStack>
      </HStack>
      <Text
        fontSize="sm"
        lineHeight="5"
        color="#353B48"
        my="6"
        whiteSpace="pre-wrap"
      >
        {dataRef.current.content}
      </Text>
      <Box h="56" w="full">
        {dataRef.current.files?.slice(0, 1).map((file, index) => (
          <React.Fragment key={index}>
            {file.data.image ? (
              <AspectRatio h="56">
                <Image src={file.data.image.downloadUrl} />
              </AspectRatio>
            ) : file.data.video ? (
              <Box h="56" w="full">
                <BunnyPlayer src={file.data.video.url} />
              </Box>
            ) : null}
          </React.Fragment>
        ))}
      </Box>
      <Flex my="6" flexDir="row" alignItems="center">
        <svg
          width="73"
          height="28"
          viewBox="0 0 73 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
            fill="#FFE17D"
          />
          <path
            d="M16.0322 25.9687C8.30025 25.9687 2.03224 19.7007 2.03224 11.9687C2.03224 8.62033 3.20917 5.54777 5.16999 3.13867C2.01595 5.7057 0 9.61728 0 14.0009C0 21.7329 6.26801 28.0009 14 28.0009C18.3837 28.0009 22.2953 25.9849 24.8624 22.8309C22.4532 24.7917 19.3806 25.9687 16.0322 25.9687Z"
            fill="#FFD164"
          />
          <path
            d="M9.93479 28.0001C10.4316 28.0001 10.838 27.5937 10.838 27.0969C10.838 26.6001 10.4316 26.1937 9.93479 26.1937H10.3864C10.8832 26.1937 11.2896 25.7872 11.2896 25.2904C11.2896 24.7937 10.8832 24.3872 10.3864 24.3872H10.838C11.3348 24.3872 11.7412 23.9808 11.7412 23.484C11.7412 22.9872 11.3348 22.5808 10.838 22.5808H15.8058C16.3046 22.5808 16.709 22.1764 16.709 21.6776C16.709 21.1787 16.3046 20.7743 15.8058 20.7743H7.67674C6.92851 20.7743 6.32191 20.1677 6.32191 19.4195V16.7099C6.32191 16.211 5.9175 15.8066 5.4187 15.8066C4.91984 15.8066 4.51548 16.2111 4.51548 16.7099V18.9207C4.51548 19.5359 4.30608 20.1329 3.92168 20.6134L3.5007 21.1396C2.98822 21.7802 2.70898 22.5762 2.70898 23.3966V25.2905C2.70898 26.787 3.92217 28.0001 5.41864 28.0001L9.93479 28.0001Z"
            fill="#FFD164"
          />
          <path
            d="M10.84 27.0969H9.93674H5.42059C4.42452 27.0969 3.61416 26.2866 3.61416 25.2905V23.3965C3.61416 22.7836 3.82503 22.1824 4.2079 21.7038L4.62894 21.1776C5.13945 20.5395 5.42059 19.7379 5.42059 18.9207V15.8066C4.92179 15.8066 4.51738 16.2111 4.51738 16.7099V18.9207C4.51738 19.5359 4.30798 20.1329 3.92363 20.6134L3.50265 21.1396C2.99017 21.7802 2.71094 22.5762 2.71094 23.3966V25.2905C2.71094 26.7871 3.92407 28.0002 5.42059 28.0002H9.93674C10.4335 28.0001 10.84 27.5937 10.84 27.0969Z"
            fill="#FFC350"
          />
          <path
            d="M16.7117 21.6777H10.3891C10.1397 21.6777 9.9375 21.8799 9.9375 22.1293C9.9375 22.3787 10.1397 22.581 10.3891 22.581H10.8407H15.8085C16.3073 22.581 16.7117 22.1766 16.7117 21.6777Z"
            fill="#FFC350"
          />
          <path
            d="M9.48438 23.936C9.48438 24.1854 9.68655 24.3876 9.93598 24.3876H10.3876H10.8392C11.336 24.3876 11.7424 23.9812 11.7424 23.4844H9.93598C9.68655 23.4844 9.48438 23.6866 9.48438 23.936Z"
            fill="#FFC350"
          />
          <path
            d="M9.0332 25.7426C9.0332 25.992 9.23538 26.1942 9.48481 26.1942H9.93642H10.388C10.8848 26.1942 11.2912 25.7878 11.2912 25.291H9.48481C9.23538 25.291 9.0332 25.4932 9.0332 25.7426Z"
            fill="#FFC350"
          />
          <path
            d="M16.2556 18.6278C16.1705 18.6278 16.084 18.6084 16.0024 18.5673C15.9715 18.5519 12.8751 17.0127 9.45228 16.9342C9.14045 16.9272 8.89348 16.6687 8.90054 16.3569C8.90759 16.0453 9.16648 15.7871 9.47782 15.8056C13.163 15.8901 16.3759 17.4913 16.5109 17.5594C16.7892 17.6997 16.9008 18.039 16.7606 18.3173C16.6614 18.5142 16.4624 18.6278 16.2556 18.6278Z"
            fill="#AA7346"
          />
          <path
            d="M10.3855 7.33796C10.2624 7.33796 10.1385 7.29782 10.0349 7.21535C9.94976 7.14852 8.00786 5.65828 5.67046 6.82679C5.3926 6.96635 5.05217 6.85282 4.91321 6.5743C4.77386 6.29534 4.88674 5.95644 5.16592 5.81682C8.16405 4.31647 10.6351 6.24946 10.7387 6.33258C10.9822 6.52727 11.0214 6.88252 10.8269 7.12599C10.7154 7.26539 10.5513 7.33796 10.3855 7.33796Z"
            fill="#AA7346"
          />
          <path
            d="M16.7087 8.24146C16.4449 8.24146 16.2095 8.0558 16.1556 7.78745C16.0943 7.4818 16.2928 7.18435 16.5985 7.12327L21.1146 6.22005C21.422 6.15896 21.7175 6.35698 21.7788 6.66285C21.8401 6.9685 21.6416 7.26595 21.336 7.32703L16.8198 8.23025C16.7824 8.23769 16.7453 8.24146 16.7087 8.24146Z"
            fill="#AA7346"
          />
          <path
            d="M8.31327 11.3766C7.69443 11.3003 7.25457 10.7368 7.33086 10.1179L7.44139 9.22147C7.51768 8.60263 8.08123 8.16278 8.70008 8.23907C9.31892 8.31536 9.75877 8.87891 9.68248 9.49776L9.57196 10.3942C9.49567 11.013 8.93211 11.4529 8.31327 11.3766Z"
            fill="#7D5046"
          />
          <path
            d="M8.70004 8.23896C8.62326 8.2295 8.54741 8.22813 8.47314 8.23393L8.2825 9.78011C8.23673 10.1514 8.5006 10.4896 8.87193 10.5353C9.24325 10.5811 9.58139 10.3172 9.62716 9.94592L9.68245 9.4977C9.75874 8.8788 9.31894 8.31525 8.70004 8.23896Z"
            fill="#9C6846"
          />
          <path
            d="M17.7683 12.3629C17.1495 12.2866 16.7097 11.7231 16.7859 11.1042L16.8965 10.2078C16.9728 9.58896 17.5363 9.14911 18.1552 9.2254C18.774 9.30168 19.2138 9.86524 19.1376 10.4841L19.027 11.3805C18.9507 11.9994 18.3872 12.4393 17.7683 12.3629Z"
            fill="#7D5046"
          />
          <path
            d="M18.1551 9.22724C18.0783 9.21778 18.0025 9.21641 17.9282 9.22221L17.7376 10.7684C17.6918 11.1397 17.9557 11.4778 18.327 11.5236C18.6983 11.5694 19.0365 11.3055 19.0822 10.9342L19.1375 10.486C19.2138 9.86708 18.774 9.30358 18.1551 9.22724Z"
            fill="#9C6846"
          />
          <path
            d="M58 0C50.2685 0 44 6.2685 44 14C44 21.7315 50.2685 28 58 28C65.7315 28 72 21.7315 72 14C72 6.2685 65.7315 0 58 0ZM63.5195 15.3685C62.6865 16.373 61.486 17.395 60.1 18.578C59.6275 18.9805 59.0885 19.439 58.532 19.9255C58.385 20.055 58.196 20.125 58 20.125C57.804 20.125 57.615 20.055 57.468 19.9255C56.9115 19.439 56.3725 18.9805 55.9 18.578C54.514 17.395 53.3135 16.373 52.4805 15.3685C51.5495 14.245 51.1155 13.1775 51.1155 12.0155C51.1155 10.8815 51.504 9.8385 52.2075 9.0755C52.9215 8.302 53.898 7.875 54.962 7.875C55.7565 7.875 56.4845 8.127 57.125 8.624C57.4505 8.8725 57.741 9.1805 58 9.5375C58.259 9.1805 58.553 8.8725 58.875 8.624C59.5155 8.127 60.2435 7.875 61.038 7.875C62.102 7.875 63.0785 8.302 63.7925 9.0755C64.496 9.8385 64.8845 10.8815 64.8845 12.0155C64.8845 13.1775 64.4505 14.245 63.5195 15.3685Z"
            fill="#F43756"
          />
          <path
            d="M36 28C43.732 28 50 21.732 50 14C50 6.26801 43.732 0 36 0C28.268 0 22 6.26801 22 14C22 21.732 28.268 28 36 28Z"
            fill="#4A6EA9"
          />
          <path
            d="M32.4216 20.3988C32.4197 20.6356 32.3244 20.8621 32.1564 21.029C31.9884 21.1959 31.7613 21.2899 31.5245 21.2903H29.0671C28.8305 21.2899 28.6036 21.1959 28.4359 21.0289C28.2682 20.8619 28.1733 20.6354 28.1719 20.3988V13.3988C28.1733 13.1619 28.2682 12.9353 28.4358 12.768C28.6034 12.6007 28.8303 12.5063 29.0671 12.5053H31.5245C31.7616 12.5058 31.989 12.6 32.157 12.7673C32.3251 12.9347 32.4202 13.1616 32.4216 13.3988V20.3988ZM42.8829 14.9074C43.0617 15.051 43.1965 15.242 43.2718 15.4586C43.3471 15.6751 43.36 15.9086 43.3089 16.1321C43.2577 16.3556 43.1447 16.5602 42.9827 16.7225C42.8208 16.8848 42.6164 16.9982 42.3929 17.0498C42.5724 17.1927 42.7079 17.3834 42.7839 17.5999C42.8599 17.8164 42.8732 18.0499 42.8225 18.2737C42.7717 18.4974 42.6588 18.7023 42.4968 18.8648C42.3348 19.0273 42.1302 19.1407 41.9066 19.1922C42.1032 19.3506 42.246 19.566 42.3153 19.8088C42.3846 20.0517 42.377 20.31 42.2937 20.5483C42.2103 20.7867 42.0553 20.9934 41.8497 21.1401C41.6442 21.2868 41.3983 21.3663 41.1458 21.3677L34.2158 21.2903C33.9797 21.2894 33.7534 21.1951 33.5865 21.0281C33.4195 20.8611 33.3252 20.6349 33.3242 20.3988V13.3988C33.3242 12.2935 36.8592 10.2524 37.049 9.16191C37.1669 8.43981 37.0103 6.63086 37.4966 6.63086C38.3219 6.63086 39.3811 6.9477 39.3811 8.7806C39.3811 10.398 38.3219 12.4961 38.3219 12.4961H42.6085C42.9084 12.4962 43.1978 12.6067 43.4216 12.8064C43.6453 13.0062 43.7877 13.2813 43.8216 13.5793C43.8555 13.8773 43.7786 14.1774 43.6055 14.4223C43.4324 14.6673 43.1752 14.84 42.8829 14.9074Z"
            fill="white"
          />
        </svg>
        <Text
          ml="2"
          fontSize="xs"
          fontWeight="medium"
          color="#353B48"
          lineHeight="5"
        >
          {others} {t("others")}
        </Text>
        <Text
          ml="9"
          fontSize="xs"
          fontWeight="medium"
          color="#353B48"
          lineHeight="5"
        >
          {comments} {t("comments")}
        </Text>
      </Flex>
    </Box>
  );
};
