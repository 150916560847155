import { APISchemas } from "@/api";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedinIcon,
  InstagramIcon,
  TelegramIcon,
  YoutubeIcon,
  TiktokCircleIcon,
} from "@/assets/brands";
import {
  Flex,
  Avatar,
  Icon,
  Button,
  Popover,
  PopoverAnchor,
  useDisclosure,
  PopoverContent,
  PopoverBody,
  Box,
  Text,
} from "@chakra-ui/react";
import { FC, useState, useCallback, useEffect, ReactNode } from "react";
import { useFormStore } from "../form-store";

type Channel = APISchemas["Channel"] & { hasError?: boolean };
const icons: Record<string, ReactNode | null> = {
  twitter: <TwitterIcon />,
  facebook: <FacebookIcon />,
  linkedin: <LinkedinIcon />,
  instagram: <InstagramIcon />,
  youtube: <YoutubeIcon />,
  tiktok: <TiktokCircleIcon />,
  telegram: <TelegramIcon inverse={false} />,
};
const CHANNEL_COLORS: Record<string, string> = {
  twitter: "#2d2d2d ",
  instagram: "#FA1D78",
  facebook: "#3A559F",
  linkedin: "#0077B5",
  telegram: "#039BE5",
  youtube: "#F00000",
  tiktok: "#000",
};

export const ChannelSelect: FC<{
  value?: Channel & { hasError?: boolean };
  channels: Array<Channel & { hasError?: boolean }>;
  onSelected?: (channel?: Channel & { hasError?: boolean }) => void;
}> = ({ channels, value, onSelected }) => {
  const [selected, setSelected] = useState<Channel>();

  const onChange = useCallback(
    (value?: Channel & { hasError?: boolean }) => {
      onSelected?.(value);
      setSelected(value);
    },
    [onSelected]
  );

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Flex columnGap="3" rowGap="3" wrap="wrap" justifyContent="flex-end">
      {channels.map((p) => {
        return (
          <ChannelButton
            key={p.id}
            channel={p}
            selected={selected}
            onClick={onChange}
          />
        );
      })}
    </Flex>
  );
};

const ChannelButton: FC<{
  channel: Channel;
  selected?: Channel;
  onClick?: (
    value?: Channel & {
      hasError?: boolean;
    }
  ) => void;
}> = ({ channel, selected, onClick }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const formState = useFormStore((s) => s.fromStates[channel.id.toString()]);

  const globalError = useFormStore((s) =>
    s.getGobalError(channel.id.toString())
  );

  const channelColor = CHANNEL_COLORS[channel.channel_type.split("_")[0]];

  return (
    <Flex pos="relative">
      <Button
        variant="unstyled"
        w="4"
        h="4"
        size="none"
        zIndex="1"
        rounded="full"
        justifyContent="center"
        alignItems="center"
        pos="absolute"
        top="0"
        right="0"
        lineHeight="16px"
        bg="white.bg"
        fontWeight="extrabold"
        fontSize="xs"
        onClick={() => {
          const platforms = useFormStore.getState().platforms;
          const platform = platforms.find((p) => p.id === channel.id);

          if (platform) {
            useFormStore.setState({
              platforms: platforms.filter((p) => p.id !== channel.id),
            });
          }
        }}
      >
        X
      </Button>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        eventListeners={{
          resize: true,
        }}
        isLazy
      >
        <PopoverAnchor>
          <Button
            variant="unstyled"
            pos="relative"
            key={channel.id}
            onMouseOver={onOpen}
            onMouseOut={onClose}
            onClick={() => onClick?.(channel)}
            opacity={
              selected
                ? channel.id !== selected?.id
                  ? formState?.sync === false
                    ? "1"
                    : "0.3"
                  : "1"
                : "1"
            }
          >
            <Avatar
              name={channel.name}
              outline={
                typeof selected !== "string"
                  ? channel.id === selected?.id
                    ? "2px solid #50CD89"
                    : channel.hasError || !!globalError
                      ? "2px solid #A40000"
                      : formState?.sync === false
                        ? `2px solid ${channelColor}`
                        : undefined
                  : undefined
              }
              src={channel.picture ?? undefined}
              w="9"
              h="9"
            />
            <Icon
              id="asdasd"
              pos="absolute"
              bottom="0"
              right="0"
              w="4"
              h="4"
              rounded="full"
            >
              {icons[channel.channel_type.split("_")[0]]}
            </Icon>
          </Button>
        </PopoverAnchor>
        <PopoverContent w="min-content" bg="white.bg">
          <PopoverBody px="2" py="3">
            <Flex alignItems="center" gap="3">
              <Box pos="relative" w="4" h="4">
                <Avatar
                  name={channel.name}
                  src={channel.picture ?? undefined}
                  size="xs"
                  w="4"
                  h="4"
                />
                <Icon
                  pos="absolute"
                  bottom="0"
                  right="0"
                  w="1.5"
                  h="1.5"
                  rounded="full"
                >
                  {icons[channel.channel_type.split("_")[0]]}
                </Icon>
              </Box>
              <Text fontSize="xs" whiteSpace="nowrap">
                {channel.username
                  ? channel.username.startsWith("@")
                    ? channel.username
                    : `@${channel.username}`
                  : channel.name}
              </Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
