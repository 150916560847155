import { PACKAGE_VERSION_N } from "@/constant";
import { create } from "zustand";
import { persist } from "zustand/middleware";

interface OutsideState {
  state: {
    started?: boolean;
    error?: string;
    success?: string;
    url?: string;
  };
  startPayment: () => void;
  endPayment: ({ url }: { url: string }) => void;

  clearState: () => void;

  intervalId: NodeJS.Timer | null;
}

export const useOutsideStore = create<OutsideState>()(
  persist<OutsideState>(
    (set, get) => ({
      intervalId: null,
      state: {},

      startPayment() {
        const intervalId = setInterval(async () => {
          await useOutsideStore.persist.rehydrate();
        }, 250);

        set({
          state: {
            started: true,
          },
          intervalId,
        });
      },
      endPayment({ url }) {
        const { state, intervalId } = get();

        if (intervalId) {
          clearInterval(intervalId);
        }

        set({
          state: {
            ...state,
            url,
            started: false,
          },
          intervalId: null,
        });
      },
      clearState() {
        set({ state: {} });
      },
    }),
    {
      name: "outside-storage",
      version: PACKAGE_VERSION_N,
    }
  )
);
