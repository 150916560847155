import React from "react";

export const StatusRejectIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.9998 0C6.53801 0 4.30708 0.988416 2.68217 2.59C2.29856 3.03269 2.01175 3.45462 1.68923 3.92114C0.685421 5.37307 0.254395 7.10134 0.254395 9.00009C0.254395 13.9707 4.02918 17.7455 8.9998 17.7455C11.4598 17.7455 13.6663 16.9871 15.2909 15.3875C15.4553 15.2256 15.7883 14.9095 15.7883 14.9095C17.1657 13.3284 17.9999 11.2617 17.9999 9.00009C17.9999 4.02947 13.9704 0 8.9998 0ZM13.7459 3.22964L9.05105 7.92446C9.02276 7.95275 8.97691 7.95275 8.94863 7.92446L4.25373 3.2296C4.22331 3.19918 4.2257 3.14887 4.25916 3.12185C5.55518 2.07534 7.20431 1.44871 8.9998 1.44871C10.7953 1.44871 12.4444 2.07534 13.7404 3.12185C13.7739 3.14887 13.7763 3.19922 13.7459 3.22964ZM1.44842 9.00009C1.44842 7.2046 2.07506 5.55546 3.12157 4.25945C3.14858 4.22599 3.19893 4.2236 3.22931 4.25402L7.92417 8.94888C7.95246 8.97716 7.95246 9.02302 7.92417 9.0513L3.22935 13.7462C3.19893 13.7766 3.14862 13.7742 3.1216 13.7407C2.07506 12.4447 1.44842 10.7956 1.44842 9.00009ZM4.25373 14.7705L8.94855 10.0757C8.97684 10.0474 9.02269 10.0474 9.05098 10.0757L13.7458 14.7705C13.7763 14.801 13.7739 14.8513 13.7404 14.8783C12.4444 15.9248 10.7953 16.5515 8.9998 16.5515C7.20431 16.5515 5.55518 15.9248 4.25916 14.8783C4.2257 14.8513 4.22331 14.801 4.25373 14.7705ZM14.7703 13.7462L10.0754 9.0513C10.0471 9.02302 10.0471 8.97716 10.0754 8.94888L14.7703 4.25405C14.8007 4.22363 14.851 4.22602 14.878 4.25949C15.9245 5.55546 16.5512 7.2046 16.5512 9.00009C16.5512 10.7956 15.9245 12.4447 14.878 13.7407C14.851 13.7742 14.8007 13.7765 14.7703 13.7462Z"
      fill="#E52625"
    />
    <path
      d="M15.8373 14.8525C14.1868 16.7789 11.736 17.9998 9.00009 17.9998C4.02958 17.9998 0 13.9702 0 8.99972C0 6.46087 1.0514 4.16756 2.7424 2.53125C1.39402 4.10491 0.579483 6.14939 0.579483 8.38402C0.579483 13.3545 4.60906 17.3841 9.57957 17.3841C12.0112 17.3841 14.2176 16.4196 15.8373 14.8525Z"
      fill="#E52625"
    />
  </svg>
  );
};
