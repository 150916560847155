export const InstagramIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15Z"
        fill="white"
      />
      <path
        d="M17.875 8H11.125C10.031 8 8.98177 8.4346 8.20818 9.20818C7.4346 9.98177 7 11.031 7 12.125V18.875C7 19.969 7.4346 21.0182 8.20818 21.7918C8.98177 22.5654 10.031 23 11.125 23H17.875C18.969 23 20.0182 22.5654 20.7918 21.7918C21.5654 21.0182 22 19.969 22 18.875V12.125C22 11.031 21.5654 9.98177 20.7918 9.20818C20.0182 8.4346 18.969 8 17.875 8ZM20.6875 18.2825C20.6875 19.1856 20.3288 20.0516 19.6902 20.6902C19.0516 21.3288 18.1856 21.6875 17.2825 21.6875H11.7175C10.8144 21.6875 9.94836 21.3288 9.3098 20.6902C8.67124 20.0516 8.3125 19.1856 8.3125 18.2825V12.7175C8.3125 11.8144 8.67124 10.9484 9.3098 10.3098C9.94836 9.67124 10.8144 9.3125 11.7175 9.3125H17.2825C18.1856 9.3125 19.0516 9.67124 19.6902 10.3098C20.3288 10.9484 20.6875 11.8144 20.6875 12.7175V18.2825Z"
        fill="#AC25AF"
      />
      <path
        d="M17.2357 12.7931L17.2001 12.7575L17.1701 12.7275C16.4611 12.021 15.5009 11.6245 14.5001 11.625C13.9946 11.6284 13.4948 11.7314 13.0292 11.9281C12.5636 12.1247 12.1413 12.4112 11.7865 12.7711C11.4316 13.131 11.1511 13.5573 10.9611 14.0257C10.771 14.494 10.6751 14.9952 10.6788 15.5006C10.678 16.5263 11.0824 17.5109 11.8038 18.24C12.1572 18.5977 12.5783 18.8814 13.0425 19.0745C13.5067 19.2677 14.0048 19.3664 14.5076 19.365C15.2618 19.3492 15.9951 19.1142 16.618 18.6888C17.241 18.2634 17.7267 17.6659 18.0159 16.9692C18.3051 16.2725 18.3854 15.5067 18.2468 14.7651C18.1083 14.0236 17.757 13.3384 17.2357 12.7931ZM14.5001 18.0431C13.9957 18.0502 13.5007 17.9071 13.078 17.6321C12.6552 17.357 12.3238 16.9625 12.1259 16.4985C11.9279 16.0346 11.8725 15.5223 11.9665 15.0268C12.0606 14.5313 12.2999 14.0749 12.654 13.7158C13.0081 13.3566 13.461 13.1109 13.9552 13.0099C14.4493 12.9089 14.9623 12.9571 15.429 13.1484C15.8957 13.3398 16.2949 13.6656 16.5759 14.0845C16.8568 14.5033 17.0069 14.9963 17.0069 15.5006C17.0094 15.8322 16.9465 16.161 16.8218 16.4683C16.6972 16.7756 16.5132 17.0552 16.2804 17.2914C16.0476 17.5275 15.7705 17.7154 15.465 17.8444C15.1595 17.9734 14.8316 18.0409 14.5001 18.0431Z"
        fill="#AC25AF"
      />
      <path
        d="M19.4553 11.4461C19.4563 11.5664 19.4336 11.6857 19.3884 11.7973C19.3432 11.9088 19.2764 12.0103 19.192 12.096C19.1075 12.1816 19.007 12.2498 18.8961 12.2966C18.7853 12.3434 18.6663 12.3679 18.5459 12.3686C18.4266 12.3685 18.3085 12.3448 18.1985 12.2988C18.0884 12.2528 17.9885 12.1854 17.9047 12.1005C17.7777 11.9716 17.6913 11.8083 17.6562 11.6307C17.6211 11.4532 17.6389 11.2693 17.7074 11.1018C17.7759 10.9343 17.892 10.7906 18.0414 10.6885C18.1908 10.5863 18.3669 10.5303 18.5478 10.5273C18.7596 10.5273 18.9646 10.6016 19.1272 10.7373L19.1459 10.7561C19.1762 10.7807 19.2038 10.8084 19.2284 10.8386L19.2491 10.8611C19.3833 11.0264 19.4562 11.2331 19.4553 11.4461Z"
        fill="#AC25AF"
      />
    </svg>
  );
};
