import {
  Box,
  Text,
  Image,
  AspectRatio,
  VStack,
  HStack,
  Flex,
  Icon,
} from "@chakra-ui/react";
import { FC, useMemo, useRef } from "react";
import { Forms, useFormStore } from "../form-store";
import { mainSchema } from "../form-containers";

import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import React from "react";

import { i18nformat } from "@/utils/misc";
import { TelegramEyeIcon } from "@/assets/icons/telegram-eye-icon";
import { useConfigStore } from "@/context/config-store/config-store";

export const TelegramPreview: FC = () => {
  const { telegram } = useFormStore((s) => s.previews);

  const { forms, scheduledAt } = useFormStore((s) => ({
    forms: s.forms,
    scheduledAt: s.scheduledAt,
  }));

  const clockNotation = useConfigStore((s) => s.clockNotation);

  const dataRef = useRef<Forms | mainSchema>();
  const viewCount = useMemo(() => Math.floor(Math.random() * 1000) * 2, []);

  if (!telegram) {
    return null;
  }

  if (telegram.channel) {
    dataRef.current = forms[telegram.channel.id.toString()];
  }

  if (!dataRef.current) {
    return null;
  }

  const name = telegram.channel?.name ?? "Telegram";
  const username = telegram.channel?.username ?? "Telegram";

  const pyramidRows = Math.floor(
    (-1 + Math.sqrt(1 + 8 * (dataRef.current?.files?.length ?? 0))) / 2
  );
  const filesPyramid: Array<typeof dataRef.current.files> = [];

  let prev = 0;
  for (let i = 1; i <= pyramidRows; i++) {
    const files = dataRef.current.files?.slice(prev, prev + i) ?? [];
    prev += i;
    filesPyramid.push(files);

    if (i === pyramidRows) {
      const remaining = dataRef.current.files?.slice(prev) ?? [];
      filesPyramid.at(-1)?.push(...remaining);
    }
  }

  return (
    <Box
      w="full"
      maxW="md"
      h="min-content"
      minH="16"
      bg="white"
      rounded="2xl"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      py="2"
    >
      <Text
        fontSize="md"
        lineHeight="5"
        fontWeight="bold"
        color="black.active"
        px="3"
        mb="1"
      >
        {name || `@${username}`}
      </Text>
      {filesPyramid.map((files, i) => (
        <VStack key={i} mb="1">
          <HStack w="full" gap="1">
            {files?.map((file, j) => (
              <React.Fragment key={i + j}>
                {file?.data.image ? (
                  <AspectRatio w="full">
                    <Image src={file?.data.image?.downloadUrl} />
                  </AspectRatio>
                ) : file?.data.video ? (
                  <Box h="full" w="full">
                    <BunnyPlayer src={file?.data.video.url} absolute={false} />
                  </Box>
                ) : null}
              </React.Fragment>
            ))}
          </HStack>
        </VStack>
      ))}

      <Text
        fontSize="sm"
        lineHeight="5"
        color="#353B48"
        mt="1.5"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        px="3"
        fontWeight="semibold"
      >
        {dataRef.current.content}
      </Text>
      <Flex justifyContent="flex-end" alignItems="center" gap="1" px="3">
        <Flex alignItems="center" justifyContent="center" gap="1">
          <Icon boxSize="5" fill="gray.primary">
            <TelegramEyeIcon />
          </Icon>
          <Text fontSize="12px" color="gray.primary" fontWeight="bold">
            {viewCount}
          </Text>
        </Flex>
        <Text fontSize="12px" color="gray.primary" fontWeight="bold">
          {i18nformat(
            scheduledAt,
            clockNotation === "12" ? "hh:mm a" : "HH:mm"
          )}
        </Text>
      </Flex>
    </Box>
  );
};
