export const XCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
        fill="#343434"
      />
      <path
        d="M16.4759 14.9044L22.2182 9.02612H20.8575L15.8714 14.1302L11.8891 9.02612H7.2959L13.318 16.7443L7.2959 22.9087H8.65672L13.9221 17.5186L18.1277 22.9087H22.7209L16.4759 14.9044ZM14.6121 16.8123L14.0019 16.0437L9.14705 9.92826H11.2372L15.1551 14.8637L15.7653 15.6322L20.8581 22.0476H18.768L14.6121 16.8123Z"
        fill="white"
      />
    </svg>
  );
};
