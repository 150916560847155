import { GET } from "@/api";
import { ChevronDown } from "@/assets/icons/chevron-down-icon";
import { MasterCardIcon } from "@/assets/icons/mastercard-logo";
import { VisaLogoIcon } from "@/assets/icons/visa-logo-icon";
import * as react from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import creditCardType from "credit-card-type";
import React, { FC, useCallback, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery } from "react-query";
import { z } from "@/i18n";
import { useTranslation } from "react-i18next";
import {
  Input,
  Box,
  Popover,
  Icon,
  FormErrorMessage,
  FormControl,
  GridItem,
  FormLabel,
  Grid,
  Button,
  Flex,
  List,
  ListItem,
  Divider,
  PopoverBody,
  PopoverContent,
  Text,
  PopoverTrigger,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from "@chakra-ui/react";
import { AmericanExpressIcon } from "@/assets/icons/american-express-icon";
import { i18n } from "@/i18n";

export type CreditCard = z.infer<typeof CreditCardSchema>;

const CreditCardSchema = z.object({
  name: z.string().min(1),
  cardNumber: z.string().refine(
    (v) => {
      const cardType = creditCardType(v)[0];
      return cardType.lengths[0] === v.length;
    },
    {
      message: i18n.t("errors.card-number-is-invalid"),
    }
  ),
  expirationDate: z.string(),
  expiryYear: z
    .string()
    .length(2)
    .refine((v) => Number(v) > 0 && Number(v) < 99, {
      message: i18n.t("errors.expiry-year-is-invalid"),
    }),
  expiryMonth: z
    .string()
    .length(2)
    .refine((v) => Number(v) > 0 && Number(v) < 13, {
      message: i18n.t("errors.expiry-month-is-invalid"),
    }),
  CVV: z
    .string()
    .min(3, i18n.t("errors.cvv-is-invalid"))
    .max(4, i18n.t("errors.cvv-is-invalid")),
  ctoken: z.string().optional(),
  bankCode: z.string(),
});

export const PaymentSelect: FC<{
  setSelected: (item: CreditCard | undefined) => void;
  selected?: CreditCard;
  hardValidation?: boolean;
  onChange: (value?: CreditCard) => void;
  onValid: (isValid: boolean) => void;
  formError: { path?: string; message: string }[];
}> = ({
  onChange,
  onValid,
  selected,
  hardValidation,
  setSelected,
  formError,
}) => {
  const { isOpen, onOpen, onClose } = react.useDisclosure();
  const selectedisDirtyRef = useRef<boolean>(false);
  const formTraceRef = useRef<NodeJS.Timeout>();
  const { t } = useTranslation();
  const {
    register,
    formState,
    reset,
    setValue,
    control,
    getValues,
    trigger,
    clearErrors,
  } = useForm<z.infer<typeof CreditCardSchema>>({
    resolver: zodResolver(CreditCardSchema),
  });

  const updateSelected = useCallback(
    (creditCard: typeof selected) => {
      selectedisDirtyRef.current = true;
      setSelected(creditCard);
      onChange(creditCard);
      onClose();
      reset({
        name: creditCard?.name ?? "",
        cardNumber: creditCard?.cardNumber ?? "",
        expiryYear: creditCard?.expiryYear ?? "",
        expiryMonth: creditCard?.expiryMonth ?? "",
        CVV: "",
        ctoken: creditCard?.ctoken,
        bankCode: creditCard?.bankCode,
      });
    },
    [onChange, onClose, reset, setSelected]
  );

  const { data: CreditCards } = useQuery(["credit-cards"], async () => {
    const { data } = await GET("/payment/card-list/", {});
    const { CVV } = getValues();
    const mapped = data?.cardList?.map((d) => ({
      name: d.cardHolderName || "",
      cardNumber: d?.maskedCardNumber ?? "**** **** **** ****",
      expiryYear: "**",
      expiryMonth: "**",
      expirationDate: "**/**",
      CVV: CVV,
      ctoken: d.cardToken,
      bankCode: d.bankCode,
    }));

    if (!selectedisDirtyRef.current) {
      updateSelected(mapped?.at(0));
    }

    return mapped;
  });

  useEffect(() => {
    if (formTraceRef.current) {
      clearInterval(formTraceRef.current);
    }

    formTraceRef.current = setInterval(async () => {
      let a: undefined | Awaited<ReturnType<typeof trigger>>;

      const value = getValues();

      if (selected) {
        a = await trigger("CVV");
      } else if (hardValidation) {
        a = await trigger();
      } else {
        const nonEmpty: Array<keyof CreditCard> = [];

        if (value.name) {
          nonEmpty.push("name");
        }

        if (value.cardNumber) {
          nonEmpty.push("cardNumber");
        }

        if (value.expirationDate) {
          nonEmpty.push("expirationDate");
        }

        if (value.expiryMonth) {
          nonEmpty.push("expiryMonth");
        }

        if (value.CVV) {
          nonEmpty.push("CVV");
        }

        a = await trigger(nonEmpty);
      }

      if (a) {
        onValid(true);
      } else {
        onValid(false);
      }

      if (selected) {
        value.name = selected.name;
      }

      onChange(value);
    }, 250);
  }, [
    formError,
    selected,
    getValues,
    onChange,
    trigger,
    clearErrors,
    onValid,
    formState,
    hardValidation,
  ]);

  return (
    <Box px="2" py="3" rounded="md">
      <Popover
        isLazy
        isOpen={isOpen}
        onClose={onClose}
        matchWidth
        placement="bottom-end"
        eventListeners={{
          resize: true,
        }}
      >
        <PopoverTrigger>
          <Button
            variant="link"
            _hover={{
              textDecor: "none",
            }}
            px="2"
            py="3"
            border="1px solid #DCDDE1"
            w="full"
            overflow="hidden"
            justifyContent="flex-start"
            onClick={onOpen}
            mb="5"
          >
            <Flex w="full" justifyContent="space-between" alignItems="center">
              <Text color="#959697" fontSize="md" fontWeight="medium">
                {selected
                  ? `${selected.name}`
                  : t("page.membership.add-new-payment-method")}
              </Text>
              <Icon
                transform={isOpen ? "rotate(180deg)" : undefined}
                h="4"
                w="5"
              >
                <ChevronDown />
              </Icon>
            </Flex>
          </Button>
        </PopoverTrigger>
        <PopoverContent w="full" bgColor="white">
          <PopoverBody w="full" px="0">
            <List maxH="52" overflowY="auto">
              {CreditCards?.map((card) => {
                const cardType = creditCardType(card.cardNumber)?.[0]?.type;

                return (
                  <React.Fragment key={card.cardNumber}>
                    <ListItem px="4" py="3">
                      <Button
                        variant="link"
                        color="#959697"
                        fontSize="md"
                        fontWeight="medium"
                        onClick={() => updateSelected(card)}
                        w="full"
                      >
                        <Flex
                          w="full"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Text fontSize="md" color="#000000">
                            {card.name}
                          </Text>
                          <Flex
                            gap="3.5"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Text
                              color="#161616"
                              fontWeight="medium"
                              fontSize="md"
                            >
                              {card.cardNumber}
                            </Text>
                            {cardType === "mastercard" ? (
                              <Box
                                bg="#353A3D"
                                rounded="base"
                                width="27px"
                                height="20px"
                                p="2px"
                              >
                                <MasterCardIcon />
                              </Box>
                            ) : cardType === "visa" ? (
                              <Box width="27px" height="29px">
                                <VisaLogoIcon />
                              </Box>
                            ) : null}
                          </Flex>
                        </Flex>
                      </Button>
                    </ListItem>
                    <Divider borderColor="#DCDDE1" />
                  </React.Fragment>
                );
              })}
            </List>
            <Flex w="full" justifyContent="flex-end" mt="5">
              <Button
                variant="unstyled"
                bg="#487EB0"
                color="white"
                px="10"
                fontWeight="medium"
                rounded="base"
                h="7"
                _hover={{
                  opacity: "0.6",
                }}
                onClick={() => updateSelected(undefined)}
                mr="1.125rem"
              >
                {t("page.membership.New_Method")}
              </Button>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>

      <form>
        <Grid templateColumns="repeat(3, 1fr)" gap="5">
          <GridItem colSpan={3}>
            <FormControl isInvalid={!!formState.errors.name}>
              <FormLabel>{t("page.membership.Name")}</FormLabel>
              <Controller
                name="name"
                control={control}
                render={({ field }) => {
                  return (
                    <Input
                      type="text"
                      {...register("name", { disabled: !!selected })}
                      isDisabled={!!selected}
                      onChange={field.onChange}
                      value={field.value}
                    />
                  );
                }}
              />
              <FormErrorMessage>
                <Box>
                  <Text>
                    {formError.find((item) => item.path === "name")?.message}
                  </Text>
                  {formState.errors.name?.message}{" "}
                </Box>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!formState.errors.cardNumber}>
              <FormLabel>{t("page.membership.Card_Number")}</FormLabel>
              <Controller
                name="cardNumber"
                control={control}
                render={({ field }) => {
                  const formattedValue = field.value
                    ?.replace(/([^0-9*])/g, "")
                    ?.replace(/([0-9*]{4})/g, (a: string) => {
                      return a + " ";
                    })
                    .trim();

                  const cardType = creditCardType(field.value)?.[0]?.type;

                  return (
                    <InputGroup>
                      <InputLeftElement bg="none" borderRight="none">
                        {cardType === "mastercard" ? (
                          <Box
                            bg="#353A3D"
                            rounded="base"
                            width="27px"
                            height="20px"
                            p="2px"
                          >
                            <MasterCardIcon />
                          </Box>
                        ) : cardType === "visa" ? (
                          <Box width="27px" height="29px">
                            <VisaLogoIcon />
                          </Box>
                        ) : cardType === "american-express" ? (
                          <Box width="30px" height="29px">
                            <AmericanExpressIcon />
                          </Box>
                        ) : null}
                      </InputLeftElement>
                      <Input
                        type="tel"
                        placeholder={selected?.cardNumber}
                        maxLength={19}
                        disabled={!!selected}
                        onChange={(e) =>
                          field.onChange(
                            e.target.value
                              ?.replace(/([^0-9*])/g, "")
                              .replace(/  +/g, " ")
                          )
                        }
                        value={formattedValue?.replace(/  +/g, " ")}
                      />
                    </InputGroup>
                  );
                }}
              />

              <FormErrorMessage>
                <Box>
                  <Text>
                    {
                      formError.find((item) => item.path === "cardNumber")
                        ?.message
                    }
                  </Text>
                  {formState.errors.cardNumber?.message}
                </Box>
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <SimpleGrid columns={2} gap="6" pr="35px">
              <FormControl isInvalid={!!formState.errors.expirationDate}>
                <FormLabel whiteSpace="nowrap">
                  {t("page.membership.expiration-date")}
                </FormLabel>
                <Controller
                  name="expirationDate"
                  control={control}
                  render={({ field }) => {
                    const value = field.value?.replace(/([^0-9*])/g, "");
                    const month = value?.slice(0, 2);
                    const year = value?.slice(2, 4);

                    const formattedValue = year ? `${month}/${year}` : month;

                    setValue("expiryMonth", month);
                    setValue("expiryYear", year);

                    return (
                      <Input
                        type="tel"
                        placeholder={selected?.expirationDate ?? "MM/YY"}
                        maxLength={5}
                        disabled={!!selected}
                        onChange={field.onChange}
                        value={formattedValue}
                      />
                    );
                  }}
                />

                <FormErrorMessage>
                  <Box>
                    <Text>
                      {
                        formError.find((item) => item.path === "expiryMonth")
                          ?.message
                      }
                    </Text>
                    {formState.errors.expiryMonth?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.CVV}>
                <FormLabel>CVV</FormLabel>
                <Controller
                  name="CVV"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Input
                        type="tel"
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value}
                        min={0}
                        max={999}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  <Box>
                    <Text>
                      {formError.find((item) => item.path === "cvv")?.message}
                    </Text>
                    {formState.errors.CVV?.message}
                  </Box>
                </FormErrorMessage>
              </FormControl>
            </SimpleGrid>
          </GridItem>
        </Grid>
      </form>
    </Box>
  );
};
