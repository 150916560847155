import { SessionModal } from "@/components/session-modal/session-modal";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useConfigStore } from "@/context/config-store/config-store";
import { useInvitationStore } from "@/context/invitation-store/invitation-store";
import { useSessionStore } from "@/context/time-store/session-store";
import { Sidebar } from "@/pages/dashboard/Sidebar";
import { Topbar } from "@/pages/dashboard/Topbar";
import { Flex, Box, useToast } from "@chakra-ui/react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoutes = () => {
  const { user, refetchUser } = useAuthStore((state) => ({
    user: state.user,
    refetchUser: state.refetchUser,
  }));

  const { t } = useTranslation();

  const toast = useToast();
  const { flushInvitationToken } = useInvitationStore((state) => ({
    flushInvitationToken: state.flushInvitationToken,
  }));

  // useEffect(() => {
  //   useSessionStore.getState().startSession();

  //   return () => {
  //     useSessionStore.getState().stopSession();
  //   };
  // }, []);

  useEffect(() => {
    if (user) {
      refetchUser();

      flushInvitationToken();
    }
  }, [refetchUser, t, toast, user, flushInvitationToken]);

  const { modalOpen, closeModal } = useSessionStore((state) => ({
    modalOpen: state.modalOpen,
    closeModal: state.closeModal,
  }));

  const sidebar = useConfigStore((state) => state.sidebar);

  return user ? (
    <Flex w="100%" minH="100vh">
      <Sidebar w={sidebar ? "211px" : "70px"} zIndex="banner" />

      <Box minH="100vh" w="full" ml={sidebar ? "211px" : "70px"}>
        <Topbar />
        <SessionModal isOpen={modalOpen} close={closeModal} />

        <Box
          w={sidebar ? "calc(100vw - 211px)" : "calc(100vw - 70px)"}
          minHeight="calc(100vh - 110px)"
        >
          <Outlet />
        </Box>
      </Box>

      {/* <Flex w="100%" bg="white">
        <HStack w="full" gap="0">
          <Sidebar />

          <Container
            p="0"
            maxWidth="100vw"
            minHeight="calc(100vh - 75px)"
            bg="white.bg"
          >
            <Outlet />
          </Container>
        </HStack>
      </Flex> */}
    </Flex>
  ) : (
    <Navigate to="/auth/login" replace />
  );
};

export default PrivateRoutes;
