export const BackCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.0013 56.0034C43.4412 56.0034 56.0023 43.4417 56.0023 28.0016C56.0023 12.5617 43.4412 0 28.0013 0C12.5614 0 0 12.5617 0 28.0016C0 43.4417 12.5614 56.0034 28.0013 56.0034ZM28.0013 3.8332C41.3276 3.8332 52.1691 14.675 52.1694 28.0016C52.1694 41.3281 41.3278 52.1699 28.0013 52.1702C14.675 52.1699 3.83346 41.3281 3.83346 28.0013C3.83346 14.6753 14.675 3.8332 28.0013 3.8332Z"
        fill="#57AAEC"
      />
      <path
        d="M24.2408 39.9948C24.9893 40.7431 26.2029 40.7428 26.9512 39.9948C27.6999 39.2461 27.6999 38.0327 26.9509 37.284L19.5861 29.9194L40.9132 29.9173C41.9717 29.9171 42.8296 29.0592 42.8296 28.0002C42.8293 26.9417 41.9715 26.0841 40.913 26.0841L19.585 26.0862L26.9517 18.72C27.7002 17.9715 27.7002 16.7577 26.9517 16.0094C26.5773 15.6353 26.0869 15.448 25.5963 15.448C25.1059 15.448 24.6155 15.6353 24.2411 16.0092L13.6029 26.6471C13.2434 27.0064 13.0415 27.4937 13.0415 28.0023C13.0418 28.5108 13.2436 28.9979 13.6032 29.3579L24.2408 39.9948Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
