export const RetweetBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.675903" width="43" height="43.3164" rx="3" fill="#FAC9E6" />
      <path
        d="M24.9339 27.5736C24.809 27.3862 24.5985 27.2736 24.3733 27.2736H16.9661V18.7384H18.5383C19.0765 18.7384 19.3975 18.1386 19.099 17.6908L15.5053 12.3002C15.2386 11.9001 14.6507 11.9001 14.384 12.3002L10.7902 17.6908C10.4917 18.1386 10.8127 18.7384 11.3509 18.7384H12.9232V27.9474C12.9232 29.8081 14.4316 31.3165 16.2923 31.3165H26.1701C26.7083 31.3165 27.0293 30.7167 26.7308 30.2689L24.9339 27.5736Z"
        fill="#EC57A7"
      />
      <path
        d="M33.0009 24.5781H31.4287V15.3691C31.4287 13.5084 29.9202 12 28.0595 12H18.1817C17.6435 12 17.3225 12.5998 17.621 13.0476L19.4178 15.7429C19.5428 15.9304 19.7532 16.043 19.9785 16.043H27.3857V24.5781H25.8134C25.2752 24.5781 24.9542 25.1779 25.2527 25.6257L28.8465 31.0163C29.1132 31.4164 29.7011 31.4164 29.9678 31.0163L33.5615 25.6257C33.8601 25.1779 33.5391 24.5781 33.0009 24.5781Z"
        fill="#EC57A7"
      />
    </svg>
  );
};
