export const ThreeDotsCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17" cy="17" r="16.5" fill="#F7F8FA" stroke="#EBEDF2" />
      <path
        d="M11.6656 16C10.7472 16 10 16.7477 10 17.6667C10 18.5857 10.7472 19.3333 11.6656 19.3333C12.5841 19.3333 13.3313 18.5857 13.3313 17.6667C13.3313 16.7477 12.5841 16 11.6656 16Z"
        fill="#33373D"
      />
      <path
        d="M16.9957 16C16.0773 16 15.3301 16.7477 15.3301 17.6667C15.3301 18.5857 16.0773 19.3333 16.9957 19.3333C17.9141 19.3333 18.6613 18.5857 18.6613 17.6667C18.6613 16.7477 17.9141 16 16.9957 16Z"
        fill="#33373D"
      />
      <path
        d="M22.3255 16C21.4071 16 20.6599 16.7477 20.6599 17.6667C20.6599 18.5857 21.4071 19.3333 22.3255 19.3333C23.244 19.3333 23.9912 18.5857 23.9912 17.6667C23.9912 16.7477 23.244 16 22.3255 16Z"
        fill="#33373D"
      />
    </svg>
  );
};
