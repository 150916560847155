export const CreateTicketIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M19.5744 5.25917C19.792 5.25917 20.0097 5.04153 20.0097 4.82388V2.21294C20.0097 1.4731 19.444 0.907471 18.7042 0.907471H15.2227V13.092H18.7038C19.4436 13.092 20.0092 12.5264 20.0092 11.7865V9.17558C20.009 9.0602 19.9631 8.94961 19.8815 8.86803C19.7999 8.78644 19.6893 8.74051 19.5739 8.74029C19.117 8.73321 18.6811 8.54671 18.3605 8.22107C18.0399 7.89542 17.8601 7.45674 17.8601 6.99973C17.8601 6.54272 18.0399 6.10404 18.3605 5.7784C18.6811 5.45275 19.117 5.26625 19.5739 5.25917H19.5744Z" />
      <path d="M-0.0078125 2.21367V4.82461C-0.0078125 5.08571 0.166389 5.25991 0.427483 5.25991C0.658325 5.25633 0.887573 5.29871 1.10188 5.38458C1.31619 5.47044 1.51128 5.59808 1.67578 5.76006C1.84029 5.92204 1.97094 6.11513 2.06011 6.32808C2.14929 6.54103 2.19521 6.76959 2.19521 7.00046C2.19521 7.23133 2.14929 7.4599 2.06011 7.67285C1.97094 7.8858 1.84029 8.07889 1.67578 8.24087C1.51128 8.40285 1.31619 8.53049 1.10188 8.61635C0.887573 8.70222 0.658325 8.7446 0.427483 8.74102C0.166389 8.74102 -0.0078125 8.91522 -0.0078125 9.17632V11.7873C-0.0078125 12.5271 0.557821 13.0927 1.29766 13.0927H14.3524V0.908203H1.29766C0.559492 0.908203 -0.0078125 1.47384 -0.0078125 2.21367ZM5.21407 4.38932H7.825C8.0861 4.38932 8.2603 4.56352 8.2603 4.82461C8.2603 5.08571 8.0861 5.25991 7.825 5.25991H5.21407C4.95297 5.25991 4.77877 5.08571 4.77877 4.82461C4.77877 4.56352 4.95297 4.38932 5.21407 4.38932ZM5.21407 6.56496H10.8708C11.1319 6.56496 11.3061 6.73916 11.3061 7.00025C11.3061 7.26135 11.1319 7.43555 10.8708 7.43555H5.21407C4.95297 7.43555 4.77877 7.26135 4.77877 7.00025C4.77877 6.73916 4.95297 6.56496 5.21407 6.56496ZM5.21407 8.7406H10.8708C11.1319 8.7406 11.3061 8.9148 11.3061 9.1759C11.3061 9.43699 11.1319 9.61119 10.8708 9.61119H5.21407C4.95297 9.61119 4.77877 9.43699 4.77877 9.1759C4.77877 8.9148 4.95297 8.74102 5.21407 8.74102V8.7406Z" />
    </svg>
  );
};
