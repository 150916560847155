import { FC } from "react";

export const CircleAddIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M6.45312 9.4604V7.54634H4.53906C4.237 7.54634 3.99219 7.30153 3.99219 6.99947C3.99219 6.69751 4.237 6.45259 4.53906 6.45259H6.45312V4.53853C6.45312 4.23657 6.69794 3.99165 7 3.99165C7.30206 3.99165 7.54688 4.23657 7.54688 4.53853V6.45259H9.46094C9.763 6.45259 10.0078 6.69751 10.0078 6.99947C10.0078 7.30153 9.763 7.54634 9.46094 7.54634H7.54688V9.4604C7.54688 9.76247 7.30206 10.0073 7 10.0073C6.69794 10.0073 6.45312 9.76247 6.45312 9.4604ZM11.9498 2.05025C10.6276 0.728134 8.86974 0 7 0C5.13026 0 3.37236 0.728134 2.05025 2.05025C0.728134 3.37236 0 5.13026 0 7C0 8.86974 0.728134 10.6276 2.05025 11.9498C3.37236 13.2719 5.13026 14 7 14C8.28003 14 9.5325 13.6512 10.622 12.9912C10.8804 12.8347 10.9629 12.4984 10.8064 12.2402C10.65 11.9818 10.3136 11.8992 10.0553 12.0557C9.13676 12.6121 8.08018 12.9062 7 12.9062C3.74332 12.9062 1.09375 10.2567 1.09375 7C1.09375 3.74332 3.74332 1.09375 7 1.09375C10.2567 1.09375 12.9062 3.74332 12.9062 7C12.9062 8.16457 12.56 9.29805 11.9048 10.2782C11.7369 10.5292 11.8044 10.8688 12.0555 11.0367C12.3065 11.2045 12.6462 11.137 12.8141 10.886C13.5898 9.72551 14 8.38171 14 7C14 5.13026 13.2719 3.37236 11.9498 2.05025Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
