import { Flex } from "@chakra-ui/react";
import { TermsCard } from "./dashboard/Privacy/components/terms-card";

export const Terms = () => {
  return (
    <Flex maxH="100vh" p="5" bg="gray.thin">
      <TermsCard removeBackdrop />
    </Flex>
  );
};
