import { FC } from "react";

export const LoadingIllustration: FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" width="600px">
      <g id="freepik--background-complete--inject-103">
        <rect
          y="382.4"
          width="500"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="410.77"
          y="391.92"
          width="33.12"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="131.47"
          y="395.31"
          width="49.86"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="380.24"
          y="401.21"
          width="29.53"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="52.46"
          y="399.53"
          width="21.6"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="82.96"
          y="399.53"
          width="38.37"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="237.54"
          y="389.21"
          width="93.68"
          height="0.25"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <path
          d="M237,337.8H43.91a5.71,5.71,0,0,1-5.7-5.71V60.66A5.71,5.71,0,0,1,43.91,55H237a5.71,5.71,0,0,1,5.71,5.71V332.09A5.71,5.71,0,0,1,237,337.8ZM43.91,55.2a5.46,5.46,0,0,0-5.45,5.46V332.09a5.46,5.46,0,0,0,5.45,5.46H237a5.47,5.47,0,0,0,5.46-5.46V60.66A5.47,5.47,0,0,0,237,55.2Z"
          style={{ fill: "#ebebeb" }}
        ></path>
        <path
          d="M453.31,337.8H260.21a5.72,5.72,0,0,1-5.71-5.71V60.66A5.72,5.72,0,0,1,260.21,55h193.1A5.71,5.71,0,0,1,459,60.66V332.09A5.71,5.71,0,0,1,453.31,337.8ZM260.21,55.2a5.47,5.47,0,0,0-5.46,5.46V332.09a5.47,5.47,0,0,0,5.46,5.46h193.1a5.47,5.47,0,0,0,5.46-5.46V60.66a5.47,5.47,0,0,0-5.46-5.46Z"
          style={{ fill: "#ebebeb" }}
        ></path>
        <rect
          x="132.95"
          y="213.1"
          width="13.01"
          height="163.63"
          transform="translate(278.91 589.84) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="70.44"
          y="376.74"
          width="72.09"
          height="5.65"
          transform="translate(212.97 759.12) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="67.01"
          y="213.1"
          width="65.94"
          height="163.63"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="74.04"
          y="298.31"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="74.04"
          y="336.55"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="91.15"
          y="297.55"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(199.95 599.11) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="91.15"
          y="335.79"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(199.95 675.6) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="74.04"
          y="222.13"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="74.04"
          y="260.07"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="91.15"
          y="221.37"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(199.95 446.74) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="91.15"
          y="259.31"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(199.95 522.63) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="205.11"
          y="213.1"
          width="21.53"
          height="163.63"
          transform="translate(431.75 589.84) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="142.6"
          y="376.74"
          width="80.04"
          height="5.65"
          transform="translate(365.24 759.12) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="139.17"
          y="213.1"
          width="65.94"
          height="163.63"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="146.2"
          y="298.31"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="146.2"
          y="336.55"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="163.31"
          y="297.55"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(344.28 599.11) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="163.31"
          y="335.79"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(344.28 675.6) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="146.2"
          y="222.13"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="146.2"
          y="260.07"
          width="51.88"
          height="29.22"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="163.31"
          y="221.37"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(344.28 446.74) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="163.31"
          y="259.31"
          width="17.66"
          height="4.01"
          rx="1.58"
          transform="translate(344.28 522.63) rotate(180)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <rect
          x="65.01"
          y="90.11"
          width="169.17"
          height="70.66"
          transform="translate(299.2 250.89) rotate(180)"
          style={{ fill: "#e6e6e6" }}
        ></rect>
        <rect
          x="57.74"
          y="90.11"
          width="174.51"
          height="70.66"
          transform="translate(289.99 250.89) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="118.88"
          y="47.41"
          width="52.22"
          height="156.07"
          transform="translate(270.44 -19.55) rotate(90)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <polygon
          points="146.59 151.56 131.09 99.33 110.18 99.33 125.68 151.56 146.59 151.56"
          style={{ fill: "#fff" }}
        ></polygon>
        <polygon
          points="203.86 151.56 188.36 99.33 167.45 99.33 182.95 151.56 203.86 151.56"
          style={{ fill: "#fff" }}
        ></polygon>
        <polygon
          points="121.73 151.56 106.23 99.33 85.32 99.33 100.82 151.56 121.73 151.56"
          style={{ fill: "#fff" }}
        ></polygon>
        <rect
          x="289.48"
          y="70.88"
          width="93.22"
          height="146.77"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="269.3"
          y="104.26"
          width="133.58"
          height="80.02"
          transform="translate(480.36 -191.82) rotate(90)"
          style={{ fill: "#fafafa" }}
        ></rect>
        <polygon
          points="333.61 211.06 330.92 211.06 338.57 77.35 341.26 77.35 333.61 211.06"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <polygon
          points="338.62 107.32 338.62 111.18 376.1 101.3 376.1 99.94 338.62 107.32"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <polygon
          points="336.09 151.14 336.09 155 376.1 139.16 376.1 137.8 336.09 151.14"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <polygon
          points="332.63 196.38 332.63 201.7 376.1 177.65 376.1 176.29 332.63 196.38"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <polygon
          points="337.09 127.07 337.09 130.93 297.08 115.09 297.08 113.73 337.09 127.07"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <polygon
          points="333.85 173.63 333.85 178.95 297.08 159.32 297.08 157.96 333.85 173.63"
          style={{ fill: "#e0e0e0" }}
        ></polygon>
        <rect
          x="229.79"
          y="143.77"
          width="133.58"
          height="0.99"
          transform="translate(440.85 -152.31) rotate(90)"
          style={{ fill: "#e0e0e0" }}
        ></rect>
        <rect
          x="382.7"
          y="70.88"
          width="3.07"
          height="146.77"
          transform="translate(768.47 288.54) rotate(180)"
          style={{ fill: "#e0e0e0" }}
        ></rect>
        <path
          d="M419.35,206.88c0-6.09,2.36-15.21,2.36-15.21H403s2.37,9.12,2.37,15.21-7.4,17-7.4,25.72,7.46,19.57,7.46,19.57h13.84s7.46-10.86,7.46-19.57S419.35,213,419.35,206.88Z"
          style={{ fill: "#e0e0e0" }}
        ></path>
        <path
          d="M431.34,215.42c0-4.94.9-12.35.9-12.35h-7.09s.9,7.41.9,12.35-2.8,13.8-2.8,20.87,2.82,15.88,2.82,15.88h5.25s2.82-8.81,2.82-15.88S431.34,220.35,431.34,215.42Z"
          style={{ fill: "#ebebeb" }}
        ></path>
        <rect
          x="258.35"
          y="252.17"
          width="10.63"
          height="130.23"
          transform="translate(527.34 634.56) rotate(180)"
          style={{ fill: "#e6e6e6" }}
        ></rect>
        <rect
          x="255.51"
          y="252.17"
          width="6.26"
          height="130.23"
          transform="translate(517.28 634.56) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="253.57"
          y="252.17"
          width="150.96"
          height="6.67"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="455.78"
          y="252.17"
          width="10.63"
          height="130.23"
          transform="translate(922.19 634.56) rotate(180)"
          style={{ fill: "#e6e6e6" }}
        ></rect>
        <rect
          x="453.79"
          y="252.17"
          width="6.26"
          height="130.23"
          transform="translate(913.83 634.56) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <rect
          x="409.08"
          y="252.17"
          width="59.23"
          height="6.67"
          style={{ fill: "#e6e6e6" }}
        ></rect>
        <rect
          x="405.43"
          y="252.17"
          width="10.63"
          height="130.23"
          transform="translate(821.49 634.56) rotate(180)"
          style={{ fill: "#e6e6e6" }}
        ></rect>
        <rect
          x="402.59"
          y="252.17"
          width="6.26"
          height="130.23"
          transform="translate(811.44 634.56) rotate(180)"
          style={{ fill: "#ebebeb" }}
        ></rect>
        <polygon
          points="257.36 252.17 329.05 295.22 400.74 252.17 257.36 252.17"
          style={{ fill: "#fafafa" }}
        ></polygon>
        <polygon
          points="264.05 252.17 329.05 291.2 394.05 252.17 264.05 252.17"
          style={{ fill: "#ebebeb" }}
        ></polygon>
        <polygon
          points="414.19 252.17 439.96 291.2 465.74 252.17 414.19 252.17"
          style={{ fill: "#fafafa" }}
        ></polygon>
        <polygon
          points="417.26 252.17 440.63 287.19 464 252.17 417.26 252.17"
          style={{ fill: "#ebebeb" }}
        ></polygon>
      </g>
      <g id="freepik--Shadow--inject-103">
        <ellipse
          id="freepik--path--inject-103"
          cx="250"
          cy="416.24"
          rx="193.89"
          ry="11.32"
          style={{ fill: "#f5f5f5" }}
        ></ellipse>
      </g>
      <g id="freepik--Character--inject-103">
        <polygon
          points="201.78 407.02 193.74 404.38 197.71 385.06 205.75 387.7 201.78 407.02"
          style={{ fill: "#ffb573" }}
        ></polygon>
        <polygon
          points="271.71 407.12 263.24 407.12 261.14 387.53 269.6 387.53 271.71 407.12"
          style={{ fill: "#ffb573" }}
        ></polygon>
        <path
          d="M262.55,406.14h10.31a.75.75,0,0,1,.73.58l1.67,7.52a1.25,1.25,0,0,1-1.24,1.51c-3.32-.06-5.74-.25-9.92-.25-2.58,0-10.33.26-13.88.26s-4-3.51-2.57-3.83c6.52-1.42,11.44-3.39,13.52-5.27A2.11,2.11,0,0,1,262.55,406.14Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M193.88,403.11l8.91,2.45a.75.75,0,0,1,.54.75l-.39,7.7a1.23,1.23,0,0,1-1.57,1.13c-3.2-.94-7.79-2.41-11.83-3.53-4.72-1.3-5.6-1.75-11.14-3.27-3.35-.93-3.35-4.57-1.86-4.49,6.77.36,8.75,1.23,15.2-.65A3.9,3.9,0,0,1,193.88,403.11Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M235.56,150c-.54.7-.95,1.22-1.43,1.81s-.94,1.11-1.42,1.66c-1,1.09-2,2.15-3,3.19a77.11,77.11,0,0,1-6.4,6A52,52,0,0,1,207.61,172a28.7,28.7,0,0,1-4.87,1.25c-.42.06-.85.14-1.28.19l-1.31.12a13.76,13.76,0,0,1-1.66,0,15.62,15.62,0,0,1-1.63-.18,21.42,21.42,0,0,1-5.1-1.6,34.31,34.31,0,0,1-4.1-2.13,61.57,61.57,0,0,1-7.11-5,66.39,66.39,0,0,1-6.31-5.7l3.83-5.06c4.7,2.33,9.51,4.79,14.11,6.56,1.15.43,2.28.82,3.34,1.12a11.59,11.59,0,0,0,2.75.47,3.84,3.84,0,0,0,.39,0c.07,0,.06,0,.37-.08l.7-.12.72-.17c.47-.1,1-.3,1.45-.43s1-.35,1.48-.58a31.51,31.51,0,0,0,5.93-3.33,55.42,55.42,0,0,0,5.73-4.5c1.87-1.62,3.66-3.39,5.42-5.19.88-.9,1.74-1.83,2.57-2.77l1.24-1.41,1.14-1.34Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <path
          d="M240.21,145.84c.62,8.06-17.54,24.52-17.54,24.52l-16.06-13.92s6.76-7.16,14.36-14.37C226.19,137.12,239.53,137.11,240.21,145.84Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M240.21,145.84c.62,8.06-17.54,24.52-17.54,24.52l-16.06-13.92s6.76-7.16,14.36-14.37C226.19,137.12,239.53,137.11,240.21,145.84Z"
          style={{ opacity: "0.2", fill: "#33373D" }}
        ></path>
        <path
          d="M232,150c-7.46.68-12.23,6.68-14.32,13.31-.23.74-.43,1.5-.59,2.25l5.56,4.82s8.27-7.49,13.45-14.84C236.38,152.05,235.39,149.68,232,150Z"
          style={{ opacity: "0.2" }}
        ></path>
        <path
          d="M180,157.54l-2.75-7-9.38,2.88a45,45,0,0,0,6.72,5.85Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <polygon
          points="172.55 145.11 164.81 148.21 167.9 153.44 177.28 150.56 172.55 145.11"
          style={{ fill: "#ffb573" }}
        ></polygon>
        <polygon
          points="197.71 385.07 195.32 396.67 203.48 398.75 205.75 387.71 197.71 385.07"
          style={{ opacity: "0.2" }}
        ></polygon>
        <polygon
          points="269.6 387.53 261.14 387.53 262.23 397.63 270.69 397.63 269.6 387.53"
          style={{ opacity: "0.2" }}
        ></polygon>
        <path
          d="M274.6,141.93a190.22,190.22,0,0,0-5.38,27.84c-.8,6.55-1.28,12.81-1.58,18.49-.7,13.3-.41,23.44-.81,26.87L221.68,212c-6.14-48.14,5.6-72.94,5.6-72.94a89,89,0,0,1,10.8-1.33q1.15-.08,2.34-.09A167.93,167.93,0,0,1,259.61,139c1.21.15,2.43.32,3.62.51C269.28,140.46,274.6,141.93,274.6,141.93Z"
          style={{ fill: "#fff" }}
        ></path>
        <path style={{ opacity: "0.4", fill: "#33373D" }}></path>
        <path
          d="M263.19,154.11a35.67,35.67,0,0,0,5.59,19.56c.13-1.28.28-2.59.44-3.9.6-4.93,1.38-10,2.41-15.18Z"
          style={{ opacity: "0.2" }}
        ></path>
        <path
          d="M259.22,117.22c-1.65,6.09-3.64,17.32.39,21.74a19.26,19.26,0,0,1-15.21,4.86c-5.3-.49-5.31-4.22-4-6.2,7-1.16,7.18-6.28,6.31-11.16Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <path
          d="M254.13,121l-7.39,5.46a19.5,19.5,0,0,1,.35,3.45c2.66-.2,6.47-2.83,6.95-5.57A9.35,9.35,0,0,0,254.13,121Z"
          style={{ opacity: "0.2" }}
        ></path>
        <path
          d="M245.8,108.11c-2,2.83-7.25-2.38-7.05-7,7.6-1,4-6.57,10.9-5.14C255.39,97.1,248,105,245.8,108.11Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M264.74,109.94c-2.54,8-3.62,12.82-8.78,16-7.76,4.72-16.85-1.53-16.75-10.13.09-7.74,4.24-19.53,13-20.76A11.45,11.45,0,0,1,264.74,109.94Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <path
          d="M259.94,117.22a21.56,21.56,0,0,1-.33-6,18,18,0,0,1,1.47-4.53s-4.79-2.83-4.9-6.24c0,0-8.56,2.52-13.13-1.33s-.49-8.3-2.83-10c5.6-.54,7.3,2.86,10.84,2.12s.41-3.23.88-6c10.67,9.19,15.31,4.88,20.49,10.14,3.31,3.36-.17,9.66-.17,9.66S277.54,114,259.94,117.22Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M270.56,104.85s5.24,1.08,5.38-4.41C278.64,106,275.26,106.87,270.56,104.85Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M246.92,117.57h0a6.15,6.15,0,0,0,5.55-1.18.22.22,0,0,0,0-.31.22.22,0,0,0-.3,0,5.82,5.82,0,0,1-5.15,1.08.24.24,0,0,0-.27.16A.23.23,0,0,0,246.92,117.57Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M266.16,118.78a7.94,7.94,0,0,1-5.68,2.2c-2.8-.08-3.37-2.78-1.9-5,1.33-2,4.38-4.33,6.86-3.17S268,117,266.16,118.78Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <path
          d="M193.08,392.5l13.81,3.8s17.3-54.53,21.55-84.48c1.13-7.95,2.87-19.11,5.52-31,3.36-15,7.56-31.21,11-43.91,3.64-13.38,6.4-22.84,6.4-22.84L221.68,212s-12.15,66.49-15,92.16C203.74,330.84,193.08,392.5,193.08,392.5Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M193.08,392.5l13.81,3.8s17.3-54.53,21.55-84.48c1.13-7.95,2.87-19.11,5.52-31,3.36-15,7.56-31.21,11-43.91,3.64-13.38,6.4-22.84,6.4-22.84L221.68,212s-12.15,66.49-15,92.16C203.74,330.84,193.08,392.5,193.08,392.5Z"
          style={{ opacity: "0.30000000000000004" }}
        ></path>
        <path
          d="M234,280.81c3.36-15,7.56-31.21,11-43.91l-3.08-6.64C234.52,239,232.54,266.09,234,280.81Z"
          style={{ opacity: "0.2" }}
        ></path>
        <path
          d="M237.69,213.11s3.21,72.44,6.78,97c3.91,26.87,13.85,85.59,13.85,85.59h15.49s-5.12-61.79-7.39-88.17c-2.58-30,.41-92.37.41-92.37Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M237.69,213.11s3.21,72.44,6.78,97c3.91,26.87,13.85,85.59,13.85,85.59h15.49s-5.12-61.79-7.39-88.17c-2.58-30,.41-92.37.41-92.37Z"
          style={{ opacity: "0.30000000000000004" }}
        ></path>
        <polygon
          points="256.46 396.01 274.71 396.01 274.71 390.56 254.31 390.21 256.46 396.01"
          style={{ fill: "#33373D" }}
        ></polygon>
        <polygon
          points="192.12 392.58 207.72 396.88 209.96 391.51 192.19 386.58 192.12 392.58"
          style={{ fill: "#33373D" }}
        ></polygon>
        <path
          d="M251.84,109.11c-.16.66-.65,1.11-1.08,1s-.65-.73-.48-1.39.65-1.11,1.08-1S252,108.45,251.84,109.11Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M244.36,107.23c-.17.66-.65,1.11-1.08,1s-.65-.73-.49-1.4.66-1.11,1.09-1S244.53,106.57,244.36,107.23Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M246.66,108.16a24.38,24.38,0,0,1-4.59,4.86c.9,1.39,3.14,1.25,3.14,1.25Z"
          style={{ fill: "#ed893e" }}
        ></path>
        <path
          d="M254.77,107.28a.4.4,0,0,1-.34-.25,3.2,3.2,0,0,0-2.16-1.92.41.41,0,0,1-.33-.47.4.4,0,0,1,.47-.32,4,4,0,0,1,2.76,2.4.41.41,0,0,1-.21.53A.63.63,0,0,1,254.77,107.28Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M241.8,103.85a.43.43,0,0,1-.23-.1.4.4,0,0,1,0-.56,4,4,0,0,1,3.35-1.49.41.41,0,0,1,.35.45.42.42,0,0,1-.45.35h0a3.16,3.16,0,0,0-2.63,1.2A.45.45,0,0,1,241.8,103.85Z"
          style={{ fill: "#263238" }}
        ></path>
        <path
          d="M259.75,407.62a2.58,2.58,0,0,1-1.63-.43,1.2,1.2,0,0,1-.41-1.06.64.64,0,0,1,.34-.57c.94-.48,3.67,1.2,4,1.39a.2.2,0,0,1,.09.21.19.19,0,0,1-.15.16A10,10,0,0,1,259.75,407.62Zm-1.26-1.75a.67.67,0,0,0-.26.05c-.05,0-.11.08-.12.24a.81.81,0,0,0,.27.72c.46.39,1.57.45,3,.16A7.94,7.94,0,0,0,258.49,405.87Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M261.92,407.32a.16.16,0,0,1-.1,0c-.87-.48-2.57-2.33-2.39-3.27a.66.66,0,0,1,.64-.53,1.07,1.07,0,0,1,.85.26c1,.8,1.19,3.24,1.2,3.35a.21.21,0,0,1-.09.18A.23.23,0,0,1,261.92,407.32Zm-1.71-3.43h-.09c-.26,0-.28.15-.29.2-.11.56,1,2,1.84,2.62a4.83,4.83,0,0,0-1-2.65A.7.7,0,0,0,260.21,403.89Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M192.77,404h0c-1.39-.11-3.82-.43-4.49-1.47a1.12,1.12,0,0,1-.1-1,.63.63,0,0,1,.46-.42c1.13-.27,4,2.28,4.28,2.58a.21.21,0,0,1,.05.22A.19.19,0,0,1,192.77,404Zm-4-2.54h-.06a.24.24,0,0,0-.18.17.74.74,0,0,0,.06.68c.38.59,1.7,1,3.59,1.23C191,402.58,189.42,401.4,188.76,401.47Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M192.62,404l0,0c-.76-1.16-2-3.29-1.57-4.45a1.12,1.12,0,0,1,.76-.72.65.65,0,0,1,.62.11c.9.74.6,4.54.56,5a.2.2,0,0,1-.15.17A.19.19,0,0,1,192.62,404Zm-.43-4.74a.08.08,0,0,0,0,0,.23.23,0,0,0-.24,0,.75.75,0,0,0-.51.47c-.23.66.22,2,1.21,3.59C192.7,401.7,192.65,399.7,192.19,399.22Z"
          style={{ fill: "#33373D" }}
        ></path>
        <path
          d="M275.85,146.8c1.95,2.75,3.61,5.41,5.31,8.18s3.25,5.57,4.77,8.44a100.63,100.63,0,0,1,7.64,18.36c.23.84.45,1.68.65,2.55.1.43.19.87.28,1.32l.13.66.06.34.08.54a17,17,0,0,1-.61,6.77,43.33,43.33,0,0,1-4.17,9.69,83.78,83.78,0,0,1-5.31,8.26,88.6,88.6,0,0,1-6.07,7.57l-5.2-3.65c2.71-5.43,5.47-11,7.59-16.46.53-1.36,1-2.72,1.41-4.05a24.54,24.54,0,0,0,.92-3.8,6.26,6.26,0,0,0,0-2.52s0,0,0,0l-.07-.23-.13-.47c-.08-.32-.17-.64-.28-1-.19-.64-.41-1.3-.64-1.95a108,108,0,0,0-7.54-15.84c-1.46-2.63-3-5.21-4.58-7.81s-3.24-5.18-4.81-7.59Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <path
          d="M274.6,141.93c7.93,1.6,14.88,21,14.88,21l-16.55,13.48s-5.42-9.86-9.76-19.37C258.44,146.68,265,140,274.6,141.93Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M274.6,141.93c7.93,1.6,14.88,21,14.88,21l-16.55,13.48s-5.42-9.86-9.76-19.37C258.44,146.68,265,140,274.6,141.93Z"
          style={{ fill: "#33373D", opacity: "0.4" }}
        ></path>
        <rect
          x="154.89"
          y="176.25"
          width="14.96"
          height="0.99"
          transform="translate(-51.33 285.98) rotate(-74.5)"
          style={{ fill: "#33373D" }}
        ></rect>
        <rect
          x="154.81"
          y="191.89"
          width="6.47"
          height="0.99"
          transform="translate(-69.58 293.27) rotate(-74.5)"
          style={{ fill: "#33373D" }}
        ></rect>
        <rect
          x="159.93"
          y="149.19"
          width="110"
          height="110"
          rx="11.87"
          transform="matrix(0.96, 0.27, -0.27, 0.96, 62.29, -49.95)"
          style={{ fill: "#33373D" }}
        ></rect>
        <path
          d="M221.55,185.21a4.66,4.66,0,0,1-1.32-.18,5,5,0,0,1-3.45-6.08l4-14.41a4.95,4.95,0,0,1,9.54,2.64l-4,14.41A5,5,0,0,1,221.55,185.21Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M204.32,245.48a2.76,2.76,0,0,1-.8-.11,3,3,0,0,1-2.07-3.65l4-14.4a3,3,0,1,1,5.72,1.58l-4,14.4A3,3,0,0,1,204.32,245.48Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M236.53,193.44a1.48,1.48,0,0,1-.74-2.77l13-7.37a1.49,1.49,0,0,1,2,.56,1.47,1.47,0,0,1-.56,2l-13,7.37A1.53,1.53,0,0,1,236.53,193.44Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M180.33,227.25a3.46,3.46,0,0,1-1.71-6.48l13-7.37a3.47,3.47,0,0,1,3.42,6l-13,7.37A3.52,3.52,0,0,1,180.33,227.25Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M253.25,216.78a2,2,0,0,1-.53-.07l-14.4-4a2,2,0,0,1,1.05-3.82l14.41,4a2,2,0,0,1-.53,3.89Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M191,201.52a3.91,3.91,0,0,1-1.06-.14l-14.4-4a4,4,0,0,1,2.11-7.63l14.4,4a4,4,0,0,1-1.05,7.77Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M234.52,241.26a2.48,2.48,0,0,1-2.15-1.25L225,227a2.47,2.47,0,1,1,4.3-2.43l7.36,13a2.46,2.46,0,0,1-.93,3.37A2.42,2.42,0,0,1,234.52,241.26Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M202.7,187a4.45,4.45,0,0,1-3.88-2.26l-7.36-13a4.45,4.45,0,1,1,7.75-4.39l7.36,13a4.44,4.44,0,0,1-1.68,6.06A4.36,4.36,0,0,1,202.7,187Z"
          style={{ fill: "#fff" }}
        ></path>
        <path
          d="M276.41,213.89l-8.58-.31,4.15,10s7.37-1.36,7.09-5.34Z"
          style={{ fill: "#ffb573" }}
        ></path>
        <polygon
          points="262.08 217.7 264.15 225.33 271.99 223.56 267.83 213.58 262.08 217.7"
          style={{ fill: "#ffb573" }}
        ></polygon>
      </g>
    </svg>
  );
};
