import { FilterStyledIcon } from "@/assets/icons/filter-styled-icon";
import { SearchStyledIcon } from "@/assets/icons/search-styled-icon";
import {
  Text,
  Box,
  IconButton,
  Flex,
  Icon,
  InputGroup,
  Input,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PostTable } from "./post-table";
import { useEffect, useRef, useState, useMemo } from "react";
import { SearchLeftIcon } from "@/assets/icons/search-left-icon";
import { useChannel } from "@/hooks/use-channels";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export const PostReports = () => {
  const { t } = useTranslation();
  const [searchClicked, setSearchClicked] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchInput, setSearchInput] = useState<string>("");
  const [filter, setFilter] = useState<string[]>([]);

  const { data: Channels } = useChannel();
  const choosedChannels = Channels?.filter((channel) =>
    channel.channel_type.includes("twitter")
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setSearchClicked(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const filteredData = useMemo(() => {
    const data = [
      {
        id: 1,
        content:
          "Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet.",
        like: undefined,
        previous_like: undefined,
        comment: 59853,
        previous_comment: 21240,
        share: undefined,
        previous_share: undefined,
        click: undefined,
        previous_click: 60281,
        impression: 62989,
        previous_impression: 10821,
        replies: 93323,
        previous_replies: 66498,
        video_plays: 50421,
        previous_video_plays: 66066,
        image: undefined,
      },
      {
        id: 2,
        content:
          "Integer ac leo. Pellentesque ultrices mattis odio. Donec vitae nisi. Nam ultrices, libero non mattis pulvinar, nulla pede ullamcorper augue, a suscipit nulla elit ac nulla. Sed vel enim sit amet nunc viverra dapibus. Nulla suscipit ligula in lacus. Curabitur at ipsum ac tellus semper interdum. Mauris ullamcorper purus sit amet nulla. Quisque arcu libero, rutrum ac, lobortis vel, dapibus at, diam. Nam tristique tortor eu pede.",
        like: 19214,
        previous_like: 33134,
        comment: 57505,
        previous_comment: 14072,
        share: 39308,
        previous_share: 60155,
        click: 61054,
        previous_click: 23957,
        impression: 40505,
        previous_impression: 4382,
        replies: 37556,
        previous_replies: 15348,
        video_plays: 13440,
        previous_video_plays: 39859,
        image: "http://dummyimage.com/884x395.png/ff4444/ffffff",
      },
      {
        id: 3,
        content:
          "Nullam porttitor lacus at turpis. Donec posuere metus vitae ipsum. Aliquam non mauris. Morbi non lectus. Aliquam sit amet diam in magna bibendum imperdiet. Nullam orci pede, venenatis non, sodales sed, tincidunt eu, felis.",
        like: 14750,
        previous_like: 82514,
        comment: 2772,
        previous_comment: 91623,
        share: 4293,
        previous_share: 95497,
        click: 52828,
        previos_click: 51960,
        impression: 1345,
        previous_impression: 76411,
        replies: 43353,
        previos_replies: 83450,
        video_plays: 89811,
        previous_video_plays: 94690,
        image: "http://dummyimage.com/560x396.png/cc0000/ffffff",
      },
      {
        id: 4,
        content: "Sed ante. Vivamus tortor. Duis mattis egestas metus.",
        like: 36281,
        previous_like: 39306,
        comment: 28379,
        previous_comment: 83881,
        share: 25931,
        previous_share: 53206,
        click: 72050,
        previous_click: 28598,
        impression: 39233,
        previous_impression: 55131,
        replies: 51995,
        previous_replies: 57634,
        video_plays: 74140,
        previous_video_plays: 35643,
        image: undefined,
      },
      {
        id: 5,
        content:
          "Sed sagittis. Nam congue, risus semper porta volutpat, quam pede lobortis ligula, sit amet eleifend pede libero quis orci. Nullam molestie nibh in lectus.",
        like: 70799,
        previous_like: 36770,
        comment: 30115,
        previous_comment: 59880,
        share: 12536,
        previous_share: 1454,
        click: 15943,
        previous_click: 85959,
        impression: 45065,
        previous_impression: 18179,
        replies: 27805,
        previous_replies: 4443,
        video_plays: 37649,
        previous_video_plays: 74016,
        image: undefined,
      },
      {
        id: 6,
        content: "Vivamus tortor. Duis mattis egestas metus. Aenean fermentum.",
        like: 89472,
        previous_like: 24339,
        comment: 93735,
        previous_comment: 25635,
        share: 12723,
        previous_share: 22310,
        click: 91413,
        previous_click: 78403,
        impression: 45306,
        previous_impression: 43508,
        replies: 37549,
        previous_replies: 98495,
        video_plays: 96574,
        previous_video_plays: 22282,
        image: "http://dummyimage.com/462x706.png/ff4444/ffffff",
      },
      {
        id: 7,
        content:
          "Vivamus vel nulla eget eros elementum pellentesque. Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus. Phasellus in felis. Donec semper sapien a libero. Nam dui. Proin leo odio, porttitor id, consequat in, consequat ut, nulla. Sed accumsan felis.",
        like: 55213,
        previous_like: 78523,
        comment: 14241,
        previous_comment: 45436,
        share: 54500,
        previous_share: 4519,
        click: 33646,
        previous_click: 60879,
        impression: 96709,
        previous_impression: 33766,
        replies: 72993,
        previous_replies: 36978,
        video_plays: 7015,
        previous_video_plays: 61933,
        image: "http://dummyimage.com/908x1015.png/cc0000/ffffff",
      },
    ];

    return data?.filter((item: { content: string }) => {
      if (!searchInput && !filter.length) return true;

      const contentMatchesSearch =
        !searchInput ||
        item.content.toLowerCase().includes(searchInput.toLowerCase());
      const contentMatchesFilter =
        !filter.length || filter.includes(item.content.toLowerCase());
      return contentMatchesSearch && contentMatchesFilter;
    });
  }, [searchInput, filter]);

  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.post-reports")}{" "}
          </Text>
          <Text
            fontWeight="400"
            lineHeight="14px"
            fontSize="12px"
            color="gray.smoke"
            mt="12px"
          >
            {t("page.analytics.post-based-reporting-option")}
          </Text>
        </Box>
        <Box>
          {searchClicked ? (
            <InputGroup>
              <InputLeftElement h="30px">
                <Icon mx="8px" h="full">
                  <SearchLeftIcon width="23px" height="23px" fill="#57AAEB" />
                </Icon>
              </InputLeftElement>
              <Input
                ref={inputRef}
                width="200px"
                height="30px"
                border="1px solid #57AAEB"
                borderRadius="3px"
                type="search"
                _placeholder={{
                  color: "blue.shiny",
                  fontSize: "14px",
                  lineHeight: "14px",
                  fontWeight: "400",
                }}
                placeholder={t("page.analytics.search-keywords")}
                onChange={(e) => setSearchInput(e.target.value)}
                value={searchInput}
              />
            </InputGroup>
          ) : (
            <IconButton
              size="none"
              variant="unstyled"
              cursor="pointer"
              aria-label="search"
              onClick={() => setSearchClicked(true)}
            >
              <SearchStyledIcon width="30px" height="30px" />
            </IconButton>
          )}
        </Box>
        <Menu
          closeOnSelect={false}
          placement="bottom-end"
          strategy="fixed"
          eventListeners={{ resize: true }}
        >
          <MenuButton cursor="pointer" ml="4">
            <FilterStyledIcon width="30px" height="30px" />
          </MenuButton>

          <MenuList borderRadius="2px">
            {choosedChannels?.length === 0 ? (
              <Text ml="2" color="gray.smoke" fontSize="14px" fontWeight="450">
                {t("errors.no-account-found-for-filtering")}
              </Text>
            ) : (
              <OverlayScrollbarsComponent
                options={{
                  scrollbars: {
                    autoHide: "scroll",
                  },
                }}
              >
                <Box maxH="218px" overflowY="auto" maxW="400px">
                  <CheckboxGroup
                    onChange={(values) => setFilter(values.map(String))}
                  >
                    {choosedChannels?.map((channel) => (
                      <MenuItem>
                        <Checkbox value={channel.name}>
                          <Flex alignItems="center">
                            <AvatarWithBadge
                              badgeSize="20px"
                              channel={channel.channel_type}
                              picture={channel.picture ?? undefined}
                              name={channel.name}
                              w="30px"
                              h="30px"
                              border="1px solid transparent"
                            />
                            <Text
                              fontSize="14px"
                              color="black.active"
                              fontWeight="450"
                              ml="1"
                              overflow="ellipsis"
                            >
                              {channel.name}
                            </Text>
                          </Flex>
                        </Checkbox>
                      </MenuItem>
                    ))}
                  </CheckboxGroup>
                </Box>
              </OverlayScrollbarsComponent>
            )}
          </MenuList>
        </Menu>
      </Flex>

      <Flex w="full" height="800px" mt="5">
        <PostTable data={filteredData} />
      </Flex>
    </Box>
  );
};
