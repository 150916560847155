import { useSearchParams } from "react-router-dom";
import { POST } from "@/api";
import { Center, useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { t } from "i18next";
import { useRef } from "react";

export const TwitterCallback = () => {
  const [params] = useSearchParams();
  const toast = useToast();
  const activeOrganization = useAuthStore((s) => s.activeOrganization);

  const requestSent = useRef<boolean>(false);

  if (!activeOrganization?.id) {
    toast({
      status: "error",
      title: t("errors.user-has-no-active-organization"),
    });
    return null;
  }

  if (!requestSent.current) {
    requestSent.current = true;
    POST("/twitter/auth/post-auth-verifier/", {
      body: {
        org_id: activeOrganization?.id,
        oauth_token: params.get("oauth_token") ?? "",
        oauth_verifier: params.get("oauth_verifier") ?? "",
      },
    }).then(({ error, response }) => {
      if (response.status === 200) {
        toast({
          status: "success",
          title: t("alert.success.account-successfully-added"),
          description: t("alert.success.window-closed-successfully"),
        });

        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        toast({
          status: "error",
          title: t("errors.an-error-occurred"),
          description: error?.detail,
        });
      }
    });
  }

  return (
    <Center w="100vw" h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};
