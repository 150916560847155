export const mapPostType = (input: string): string => {
  switch (input.toLowerCase()) {
    case "instagrampost":
      return "instagram_posts";
    case "twitterpost":
      return "twitter_posts";
    case "youtubepost":
      return "youtube_posts";
    case "facebookpost":
      return "facebook_posts";
    case "linkedinpost":
      return "linkedin_posts";
    case "telegrampost":
      return "telegram_posts";
    case "tiktokpost":
      return "tiktok_posts";
    default:
      return "compose";
  }
};
