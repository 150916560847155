import {
  Avatar,
  Box,
  HStack,
  VStack,
  Text,
  Image,
  Divider,
  AspectRatio,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FC, useMemo, useRef } from "react";
import { Forms, useFormStore } from "../form-store";
import { mainSchema } from "../form-containers";
import { i18nformat, randInt } from "@/utils/misc";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import { useTranslation } from "react-i18next";

export const TwitterPreview: FC = () => {
  const { twitter } = useFormStore((s) => s.previews);
  const { t } = useTranslation();
  const { forms, mainForms } = useFormStore((s) => ({
    forms: s.forms,
    mainForms: s.mainForms,
  }));

  const scheduledAt = useFormStore((s) => s.scheduledAt);

  const dataRef = useRef<Forms | mainSchema>();

  const [tweet, likes, retweets] = useMemo(
    () => [randInt(100, 10000), randInt(100, 10000), randInt(100, 10000)],
    []
  );

  if (!twitter) {
    return null;
  }

  if (twitter.formType !== "normal") {
    dataRef.current = mainForms[twitter.formType];
  }

  if (twitter.channel) {
    dataRef.current = forms[twitter.channel.id.toString()];
  }

  if (!dataRef.current) {
    return null;
  }

  const name = twitter.channel?.name ?? "Twitter";
  const username = twitter.channel?.username ?? "Twitter";

  return (
    <Box
      w="468px"
      h="min-content"
      bg="white"
      //rounded="md"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      pb="5"
      pt="4"
      px="8"
    >
      <HStack gap="2" my="0.5">
        <Avatar name={name} src={twitter.channel?.picture ?? undefined} />
        <VStack gap="1.5" alignItems="flex-start">
          <Text
            fontSize="md"
            lineHeight="5"
            fontWeight="semibold"
            color="#353B48"
          >
            {name}
          </Text>
          <Text fontSize="xs" lineHeight="5" fontWeight="light" color="#353B48">
            @{username}
          </Text>
        </VStack>
      </HStack>
      <Text
        fontSize="15px"
        lineHeight="5"
        color="#353B48"
        my="5"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
      >
        {dataRef.current.content}
      </Text>
      <Grid
        display={dataRef.current.files?.length === 0 ? "none" : undefined}
        minH="3xs"
        gap="1"
        templateColumns={`repeat(${
          (dataRef.current.files?.length ?? 0) > 1 ? 2 : 1
        }, 1fr)`}
      >
        {dataRef.current.files?.slice(0, 4).map((file, index) => (
          <GridItem
            rowSpan={
              (dataRef.current?.files?.length ?? 0) === 3 && index === 0 ? 2 : 1
            }
            key={index}
          >
            {file.data.image ? (
              <AspectRatio h="full">
                <Image src={file.data.image.downloadUrl} rounded="xl" />
              </AspectRatio>
            ) : file.data.video ? (
              <Box h="full" w="full">
                <BunnyPlayer src={file.data.video.url} />
              </Box>
            ) : null}
          </GridItem>
        ))}
      </Grid>
      <Text my="4" fontSize="13px" color="#DCDDE1" lineHeight="5">
        {i18nformat(scheduledAt, "hh:mm a - dd/MM/yyyy")}
      </Text>
      <Divider mb="4" />
      <Text
        fontSize="13px"
        lineHeight="5"
        color="#353B48"
        fontWeight="semibold"
        mb="0.5"
      >
        {retweets.toLocaleString("tr-TR")}
        <Text as="span" color="#DCDDE1" mx="1">
          {t("retweets")}
        </Text>
        {tweet.toLocaleString("tr-TR")}
        <Text as="span" color="#DCDDE1" mx="1">
          {t("quote-tweets")}
        </Text>
        {likes.toLocaleString("tr-TR")}
        <Text as="span" color="#DCDDE1" mx="1">
          {t("likes")}
        </Text>
      </Text>
    </Box>
  );
};
