import React, { FC, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
  RectangleProps,
} from "recharts";
import { colors } from "@/theme/colors";

interface CustomBarProps extends RectangleProps {
  active?: boolean;
}

// const CustomLabel: FC = ()=> {

// }
interface NotableDatesProps {
  data: {
    name: string;
    data: number;
  }[];
}

export const NotableDatesBarChart: FC<NotableDatesProps> = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          axisLine={false}
          tick={{ fontSize: 12, fontWeight: "400", fill: "#B7BABF" }}
          tickLine={{ stroke: "gray.smoke" }}
          //tick={<CustomLabel />}
        />
        <Tooltip cursor={{ fill: "#fff" }} />
        <Bar
          dataKey="data"
          fill={colors.blue.shiny}
          opacity="0.33"
          activeBar={<Rectangle opacity="1" />}
          radius={[5, 5, 5, 5]}
          label={{
            position: "bottom",
            content: ({ value }) => value,
            fill: "#666",
            fontSize: "12px",
          }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
