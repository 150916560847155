export const LanguageIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 18.5703C13.9706 18.5703 18 14.5409 18 9.57031C18 4.59975 13.9706 0.570312 9 0.570312C4.02944 0.570312 0 4.59975 0 9.57031C0 14.5409 4.02944 18.5703 9 18.5703Z"
        fill="#F0F0F0"
      />
      <path
        d="M1.86048 4.08984C1.15353 5.00964 0.620453 6.06977 0.310059 7.22146H4.9921L1.86048 4.08984Z"
        fill="#0052B4"
      />
      <path
        d="M17.6899 7.22142C17.3795 6.06977 16.8464 5.00964 16.1395 4.08984L13.0079 7.22142H17.6899Z"
        fill="#0052B4"
      />
      <path
        d="M0.310059 11.918C0.620488 13.0696 1.15356 14.1298 1.86048 15.0495L4.99199 11.918H0.310059Z"
        fill="#0052B4"
      />
      <path
        d="M14.4794 2.42937C13.5596 1.72241 12.4995 1.18934 11.3478 0.878906V5.56091L14.4794 2.42937Z"
        fill="#0052B4"
      />
      <path
        d="M3.52063 16.7096C4.44042 17.4166 5.50056 17.9497 6.65221 18.2601V13.5781L3.52063 16.7096Z"
        fill="#0052B4"
      />
      <path
        d="M6.65217 0.878906C5.50052 1.18934 4.44039 1.72241 3.52063 2.42933L6.65217 5.56087V0.878906Z"
        fill="#0052B4"
      />
      <path
        d="M11.3479 18.2601C12.4995 17.9497 13.5597 17.4166 14.4794 16.7097L11.3479 13.5781V18.2601Z"
        fill="#0052B4"
      />
      <path
        d="M13.0079 11.918L16.1395 15.0495C16.8464 14.1298 17.3795 13.0696 17.6899 11.918H13.0079Z"
        fill="#0052B4"
      />
      <path
        d="M17.9238 8.39641H10.174H10.1739V0.646496C9.78964 0.596469 9.39786 0.570312 9 0.570312C8.60207 0.570312 8.21035 0.596469 7.8261 0.646496V8.39634V8.39638H0.0761836C0.0261563 8.78067 0 9.17245 0 9.57031C0 9.96825 0.0261563 10.36 0.0761836 10.7442H7.82603H7.82606V18.4941C8.21036 18.5442 8.60207 18.5703 9 18.5703C9.39786 18.5703 9.78964 18.5442 10.1739 18.4941V10.7443V10.7443H17.9238C17.9738 10.36 18 9.96825 18 9.57031C18 9.17245 17.9738 8.78067 17.9238 8.39641Z"
        fill="#D80027"
      />
      <path
        d="M11.3479 11.918L15.364 15.9341C15.5487 15.7495 15.7249 15.5564 15.893 15.3563L12.4547 11.918H11.3479V11.918Z"
        fill="#D80027"
      />
      <path
        d="M6.65213 11.918H6.65206L2.63599 15.934C2.82063 16.1188 3.01367 16.295 3.21378 16.4631L6.65213 13.0247V11.918Z"
        fill="#D80027"
      />
      <path
        d="M6.6522 7.22325V7.22318L2.63609 3.20703C2.45138 3.39167 2.27517 3.58471 2.10706 3.78482L5.54544 7.22321L6.6522 7.22325Z"
        fill="#D80027"
      />
      <path
        d="M11.3479 7.22096L15.364 3.20478C15.1794 3.02007 14.9864 2.84386 14.7863 2.67578L11.3479 6.11417V7.22096Z"
        fill="#D80027"
      />
    </svg>
  );
};
