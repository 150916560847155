import React, { ReactNode, useCallback } from "react";
import {
  Box,
  Flex,
  Text,
  Icon,
  Button,
  IconButton,
  useToast,
} from "@chakra-ui/react";
import { GET, APISchemas } from "@/api";
import { useNavigate } from "react-router-dom";
import { ChannelItem } from "./channel-account-item";
import { useTranslation } from "react-i18next";
import { PlusCircleIcon } from "@/assets/icons/plus-circle-icon";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useAuthStore } from "@/context/auth-store/auth-store";

interface ConnectChannelItemProps {
  title: string;
  titleBg: string;
  children?: ReactNode;
  accountList?: APISchemas["Channel"][];
  onDelete?: (channel: APISchemas["Channel"]) => void;
}

export const ConnectChannelItem: React.FC<ConnectChannelItemProps> = ({
  title,
  accountList,
  titleBg,
  children,
  onDelete,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const activeOrganization = useAuthStore((s) => s.activeOrganization);
  const toast = useToast();

  const handleConnect = useCallback(
    (accountType: string) => {
      if (!activeOrganization) {
        return;
      }

      let w: Window | null = null;
      if (accountType === "Facebook") {
        GET("/facebook/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data, error }) => {
          if (error?.detail) {
            toast({
              description: error.detail,
              status: "error",
              isClosable: true,
            });
            return;
          }
          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      } else if (accountType === "Twitter") {
        GET("/twitter/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data, error }) => {
          if (error?.detail) {
            toast({
              description: error.detail,
              status: "error",
              isClosable: true,
            });
            return;
          }
          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      } else if (accountType === "LinkedIn") {
        GET("/linkedin/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data, error }) => {
          if (error?.detail) {
            toast({
              description: error.detail,
              status: "error",
              isClosable: true,
            });
            return;
          }
          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      } else if (accountType === "Telegram") {
        GET("/telegram/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data, error }) => {
          if (error?.detail) {
            toast({
              description: error.detail,
              status: "error",
              isClosable: true,
            });
            return;
          }

          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      } else if (accountType === "Youtube") {
        GET("/youtube/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data }) => {
          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      } else if (accountType === "Tiktok") {
        GET("/tiktok/auth/{org_pk}/get-url/", {
          params: {
            path: {
              org_pk: activeOrganization.id.toString(),
            },
          },
        }).then(({ data, error }) => {
          if (error?.detail) {
            toast({
              description: error.detail,
              status: "error",
              isClosable: true,
            });
            return;
          }

          w = window.open(
            data?.url,
            "_blank",
            "width=800,height=600,top=100,left=300"
          );
        });
      }

      useAuthStore.getState().cloneToWindow(w);
    },
    [activeOrganization, toast]
  );

  return (
    <Box
      borderRadius="15px"
      boxShadow="0 0 10px rgba(0, 0, 0, 0.2)"
      w="280px"
      h="280px"
      mb="5"
      bg="white"
      mr="5"
    >
      <Box>
        <Flex
          bg={titleBg}
          w="full"
          h="48px"
          alignItems="center"
          bgRepeat="no-repeat"
          bgSize="cover"
          justifyContent="space-between"
          borderRadius="10px"
          px="4"
        >
          <Flex w="full" alignItems="center">
            <Icon w="8" h="8" mr="2">
              {children}
            </Icon>

            <Text color="white" fontWeight="600" fontSize="16px">
              {title}
            </Text>
          </Flex>
          <Flex justifyContent="flex-end">
            <IconButton
              size="none"
              variant="unstyled"
              aria-label="plus-sign"
              onClick={() => {
                title === "Instagram"
                  ? navigate("/channels/info/instagram")
                  : title === "Telegram"
                    ? navigate("/channels/info/telegram")
                    : handleConnect(title);
              }}
              icon={<PlusCircleIcon width="26px" height="26px" />}
            />
          </Flex>
        </Flex>
      </Box>
      <OverlayScrollbarsComponent
        options={{
          scrollbars: {
            autoHide: "scroll",
          },
        }}
      >
        <Box
          h="218px"
          justifyContent="center"
          alignItems="center"
          mt="5px"
          textAlign="center"
          px="5"
          overflowY="auto"
        >
          {accountList?.length == 0 ? (
            <>
              <Text
                fontWeight="600"
                fontSize="16px"
                py="2"
                px="3"
                color="black.dark"
              >
                {t("you_dont_have_any_account_connected_with_your_channel")}{" "}
              </Text>

              <Text
                mt="3px"
                fontWeight="400"
                fontSize="13px"
                px="8"
                color="blue.primary"
              >
                {t(
                  "connect_your_accounts_with_social_media_channels_to_schedule_post"
                )}
              </Text>
              <Box mt="3">
                <Button
                  w="130px"
                  h="30px"
                  color="white"
                  bg="green.label"
                  border-radius="6px"
                  fontSize="14px"
                  fontWeight="600"
                  onClick={() =>
                    title == "Instagram"
                      ? navigate("/channels/info/instagram")
                      : handleConnect(title)
                  }
                >
                  {t("connect-account")}
                </Button>
              </Box>
            </>
          ) : (
            accountList?.map((item: APISchemas["Channel"]) => (
              <React.Fragment key={item.id}>
                <ChannelItem
                  userPhoto={item.picture}
                  mail={
                    item.username
                      ? item.username.startsWith("@")
                        ? item.username
                        : `@${item.username}`
                      : item.name
                  }
                  onDelete={() => onDelete?.(item)}
                />
              </React.Fragment>
            ))
          )}
        </Box>
      </OverlayScrollbarsComponent>
    </Box>
  );
};
