import { useToast, Show } from "@chakra-ui/react";
import { z } from "@/i18n";
import { useForm, SubmitHandler, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { POST } from "@/api";
import { RegisterDesktop } from "./desktop";
import { RegisterMobile } from "./mobile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useInvitationStore } from "@/context/invitation-store/invitation-store";
export type RegisterSchema = z.infer<typeof RegisterSchema>;
import { i18n } from "@/i18n";
import { TurnstileInstance } from "@marsidev/react-turnstile";

export const RegisterSchema = z
  .object({
    email: z
      .string()
      .email(i18n.t("errors.please-enter-a-valid-email"))
      .min(1, i18n.t("errors.email-is-required")),
    password: z
      .string()
      .min(8, i18n.t("errors.min-character", { num: 8 }))
      .max(16, i18n.t("errors.max-character", { num: 16 }))
      .refine((password) => password.trim() === password, {
        message: i18n.t("errors.password-should-not-start-or-end-with-space"),
      }),
    confirmPassword: z.string(),
    first_name: z.string().min(1, i18n.t("errors.min-character", { num: 1 })),
    last_name: z.string().min(1, i18n.t("errors.min-character", { num: 1 })),
    captchaToken: z.string(),
  })
  .refine((input) => input.confirmPassword === input.password, {
    message: i18n.t("errors.passwords-dont-match"),
    path: ["confirmPassword"],
  });

export const Register = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const [searchParams] = useSearchParams();
  const [invitation, clearInvitation] = useInvitationStore((s) => [
    s.invitation,
    s.clearInvitation,
  ]);
  const turnstileRef = useRef<TurnstileInstance | undefined>();

  const emailRef = useRef<{
    email: string | null;
    used: boolean;
  }>({
    email: searchParams.get("email"),
    used: false,
  });
  const { t } = useTranslation();

  const methods = useForm<RegisterSchema>({
    resolver: zodResolver(RegisterSchema),
  });

  if (emailRef.current.email && !emailRef.current.used) {
    emailRef.current.used = true;
    methods.setValue("email", emailRef.current.email);
  }

  const register: SubmitHandler<RegisterSchema> = useCallback(
    async ({ email, password, first_name, last_name, captchaToken }) => {
      const { data, error } = await POST("/auth/register/", {
        body: {
          email,
          password,
          first_name,
          last_name,
          phone: "90",
          organization_name: "My Workspace",
          invite_token: invitation?.token,
          captchaToken,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (error) {
        if (turnstileRef.current) {
          turnstileRef.current.reset();
        }
        if (error.issues) {
          error.issues.forEach((issue) => {
            if (!issue.path) return;
            methods.setError(issue.path as any, {
              message: issue.message,
            });
          });
          return;
        }
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
        });

        return;
      }
      if (!data) {
        toast({
          title: t("errors.unexpected_error"),
          status: "error",
          isClosable: true,
        });

        return;
      }
      if (invitation?.token) {
        toast({
          title: t("alert.invitation.organization-invitation-success"),
          status: "success",
        });

        navigate("/login");
      }
      clearInvitation();

      navigate("/auth/confirm-mail", { state: { email } });
    },
    [invitation, navigate, toast, t, clearInvitation]
  );

  return (
    <FormProvider {...methods}>
      <Show above="md">
        <RegisterDesktop
          onSubmit={methods.handleSubmit(register)}
          disabled={methods.formState.isSubmitting}
          turnstileRef={turnstileRef}
        />
      </Show>
      <Show below="md">
        <RegisterMobile
          onSubmit={methods.handleSubmit(register)}
          disabled={methods.formState.isSubmitting}
          turnstileRef={turnstileRef}
        />
      </Show>
    </FormProvider>
  );
};
