import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Flex,
  Image,
  Text,
  Box,
  useToast,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { FC, useState, useCallback } from "react";
import { useQuery } from "react-query";
import { EditPersonalInfo } from "./edit-personal-info";
import { EditBillingInfo } from "./edit-billing-info";
import { APISchemas, PATCH, GET, POST } from "@/api";
import { EditIcon } from "@chakra-ui/icons";
import { useDropzone } from "react-dropzone";
import axios, { AxiosError } from "axios";
import MediaInfoFactory from "mediainfo.js";
import { useTranslation } from "react-i18next";
import { EditPassword } from "./edit-password";

interface editPersonalProps {
  name: string;
  surname: string;
  phone: string;
}
interface editBillingProps {
  name: string;
  street: string;
  county: string;
  city: string;
  postal_code: string;
  country: string;
  detail: string;
  phone: string;
  contact_name: string;
  tin?: string;
  address_type?: "P" | "B";
  tax_office?: string;
}

interface editPassword {
  oldPassword: string;
  newPassword: string;
}

export const Profile: FC = () => {
  const { user, setUser } = useAuthStore((state) => ({
    user: state.user,
    setUser: state.setUser,
  }));
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const fullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
  const { t } = useTranslation();
  const toast = useToast();

  const { data: address, refetch } = useQuery(
    ["address", user?.id],
    async () => {
      const { data } = await GET("/auth/address/", {});

      return data;
    }
  );

  const editPersonalInfo = useCallback(
    async (data: editPersonalProps, user: APISchemas["User"]) => {
      if (!user) {
        return;
      }

      const { response } = await PATCH("/auth/user/{id}/", {
        params: {
          path: {
            id: user.id,
          },
        },
        body: {
          first_name: data.name,
          last_name: data.surname,
          phone: data.phone.replaceAll(" ", ""),
          biography: "test",
          description: "test",
        },
      });

      if (response.ok) {
        setUser();
        toast({
          status: "success",
          title: t("alert.success.profile-edited-successfully"),
          duration: 3000,
        });

        return;
      }

      toast({
        status: "error",
        description: t(
          "errors.an-unexpected-error-occures-editing-profile-info"
        ),
        duration: 3000,
      });
    },
    [toast, setUser, t]
  );

  const editPasswordInfo = useCallback(
    async (data: editPassword, user: APISchemas["User"]) => {
      if (!user) {
        return;
      }

      const { response, error } = await POST("/auth/change-password/", {
        params: {
          query: {
            id: user.id,
          },
        },
        body: {
          old_password: data.oldPassword,
          new_password: data.newPassword,
        },
      });

      if (response.ok) {
        setUser();
        toast({
          status: "success",
          title: t("alert.success.password-changed-successfully"),
          duration: 3000,
        });

        return;
      }

      return error?.issues;
    },
    [setUser, toast, t]
  );

  const editBillingInfo = (
    data: editBillingProps,
    individual: boolean,
    user: APISchemas["User"]
  ) => {
    if (address && address.length > 0) {
      patchBillingInfo(data, individual);
    } else postBillingInfo(data, individual, user);
  };

  const postBillingInfo = useCallback(
    async (
      data: editBillingProps,
      individual: boolean,
      user: APISchemas["User"]
    ) => {
      if (!user) {
        return;
      }
      const address_type = individual ? "P" : "B";
      const { response, error } = await POST("/auth/address/", {
        body: {
          name: data.name,
          country: data.country,
          city: data.city,
          street: data.street,
          county: data.county,
          postal_code: data.postal_code,
          detail: data.detail,
          phone: data.phone.replaceAll(" ", ""),
          contact_name: data.contact_name,
          tin: individual ? undefined : data.tin,
          tax_office: individual ? undefined : data.tax_office,
          address_type: address_type,
        },
      });
      if (response.ok) {
        refetch();
        toast({
          status: "success",
          title: t("alert.success.billing-info-edited-successfully"),
          duration: 3000,
        });

        return;
      }
      if (error) {
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
          duration: 3000,
        });
      }
      return error?.issues;
    },
    [toast, refetch, t]
  );

  const patchBillingInfo = useCallback(
    async (data: editBillingProps, individual: boolean) => {
      if (!address) {
        return;
      }
      const address_type = individual ? "P" : "B";
      const { response, error } = await PATCH("/auth/address/{id}/", {
        params: {
          path: {
            id: address[0].id,
          },
        },
        body: {
          name: data.name,
          country: data.country,
          city: data.city,
          street: data.street,
          county: data.county,
          postal_code: data.postal_code,
          detail: data.detail,
          phone: data.phone.replaceAll(" ", ""),
          contact_name: data.contact_name,
          tin: individual ? undefined : data.tin,
          tax_office: individual ? undefined : data.tax_office,
          address_type: address_type,
        },
      });
      if (response.ok) {
        refetch();
        toast({
          status: "success",
          title: t("alert.success.billing-info-edited-successfully"),
          duration: 3000,
        });

        return;
      }

      if (error) {
        toast({
          status: "error",
          description: error.detail ?? t("errors.an-error-occurred"),
          duration: 3000,
        });
      }
      return error?.issues;
    },

    [toast, refetch, address, t]
  );

  const onImageDrop = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        const mediaInfo = await MediaInfoFactory();

        const info = await mediaInfo.analyzeData(
          () => file.size,
          async (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.error) {
                  reject(event.target.error);
                  return;
                }

                if (event.target?.result) {
                  if (typeof event.target.result === "string") {
                    const encoder = new TextEncoder();
                    resolve(
                      new Uint8Array(encoder.encode(event.target.result).buffer)
                    );
                    return;
                  }
                  resolve(new Uint8Array(event.target.result));
                }

                resolve(new Uint8Array());
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            })
        );

        const file_type = file.type || `video/${file.name.split(".").pop()}`;

        const type: "image" | "video" | null = file_type.startsWith("image")
          ? "image"
          : file_type.startsWith("video")
            ? "video"
            : null;

        if (!type) {
          toast({
            status: "error",
            title: t("errors.could-not-recognized-media"),
          });
          return;
        }
        const general = info.media?.track.find((t) => t["@type"] === "General");

        if (type === "video") {
          toast({
            title: t("errors.can-not-add-video-to-your-profile-picture"),
            status: "error",
          });
          return;
        }

        const img = info.media?.track.find((t) => t["@type"] === "Image");

        if (!general || !img || !info.media?.track) {
          toast({
            title: t("errors.error-reading-its-image-informations", {
              file: file.name,
            }),
            status: "error",
          });
          return;
        }

        for (const key of ["Height", "Width"]) {
          if (!(key in img)) {
            toast({
              title: t("errors.error-reading-its-image-informations", {
                file: file.name,
              }),
              status: "error",
            });
            console.error(`key: ${key} not found in file meta`);
            return;
          }
        }

        const { data, error } = await POST("/media/upload/image/", {
          body: {
            height: img?.Height?.toString() ?? "",
            width: img?.Width?.toString() ?? "",
            size: file.size.toString(),
            raw: JSON.parse(JSON.stringify(info?.media?.track)),
          },
        });

        if (error) {
          toast({
            status: "error",
            title: t("errors.an-error-occurred"),
          });
          return;
        }

        if (!data) {
          console.error("Unexpected error data is undefined");
          return;
        }

        const formData = new FormData();
        formData.append("file", file);

        const toastId = toast({
          status: "info",
          title: t("alert.success.user-picture-is-uploading"),
          isClosable: true,
          duration: null,
        });

        axios
          .request({
            url: data.uploadUrl,
            method: "POST",
            data: formData,
          })
          .then(async () => {
            if (!user) {
              console.error("Unexpected error user is undefined");
              return;
            }

            PATCH("/auth/user/{id}/", {
              params: {
                path: {
                  id: user.id,
                },
              },
              body: {
                picture: data.downloadUrl,
              },
            }).then(() => {
              toast.update(toastId, {
                status: "success",
                title: t("alert.success.user-picture-is-updated"),
                duration: 3000,
              });

              setUser();
            });
          })
          .catch((reason: AxiosError) => {
            toast.update(toastId, {
              duration: 3000,
              status: "error",
              title:
                reason.cause?.message ??
                reason.message ??
                JSON.stringify(
                  reason.response?.data ?? t("errors.an-error-occurred")
                ),
            });
          });

        return;
      }
    },
    [setUser, toast, user, t]
  );

  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onImageDrop,
    accept: {
      "image/jpg": [".png", ".jpg", ".jpeg"],
    },
  });

  return (
    <Flex flexDir="column" rowGap="20px" h="full" ml="24px">
      <Flex
        shadow="sm"
        border="1px solid  #E1E3EA"
        p="3"
        alignItems="center"
        w="full"
        borderRadius="6px"
        bg="white"
        h="133px"
        box-shadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
      >
        <Flex w="full" alignItems="center">
          <Box
            position="relative"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box
              borderRadius="3px"
              w="90px"
              h="90px"
              //bg="blue.shiny"
              transition="opacity 0.5s ease"
              color="white"
              justifyContent="center"
              alignItems="center"
              display="flex"
              fontSize="20px"
              fontWeight="600"
            >
              {user?.picture && (
                <Image
                  pos="absolute"
                  top="0px"
                  left="0px"
                  src={user?.picture ?? undefined}
                  w="90px"
                  h="90px"
                  transition="opacity 0.5s ease"
                  border="0.5px solid white"
                  shadow="md"
                  objectFit="cover"
                  objectPosition="center"
                  borderRadius="3px"
                />
              )}
              <Flex>
                <Text textTransform="capitalize">
                  {user?.first_name?.slice(0, 1) ?? ""}
                </Text>
                <Text textTransform="capitalize">
                  {user?.last_name?.slice(0, 1) ?? ""}
                </Text>
              </Flex>

              {/* <Avatar
                color="black.active"
                bg="white.bg"
                name={fullName}
                src={user?.picture ?? undefined}
                w="80px"
                h="80px"
                border="0.5px solid white"
                shadow="md"
              /> */}
            </Box>

            {isHovered && (
              <Box
                borderRadius="3px"
                position="absolute"
                top="0"
                left="0"
                bg="gray.200"
                w="90px"
                h="90px"
                transition="opacity 0.3s ease"
              >
                <input {...fileInput()} />
                <IconButton
                  {...fileRoot()}
                  aria-label="edit"
                  icon={<EditIcon w="18px" h="18px" color="black.active" />}
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  bg="transparent"
                />
              </Box>
            )}
          </Box>

          <Box ml="6" w="full">
            <Text
              color="#002D6F"
              fontSize="22px"
              fontWeight="600"
              lineHeight="20px"
            >
              {fullName}
            </Text>
            <Text
              color="black.active"
              fontSize="13px"
              lineHeight="20px"
              mt="1.5"
            >
              {user?.username || user?.email}
            </Text>
          </Box>
          <Button
            hidden={true}
            w="180px"
            h="43px"
            bg="red.main"
            border-radius="6px"
            color="white"
            _hover={{
              opacity: "0.6",
            }}
          >
            <Flex alignItems="center" justifyContent="center">
              <Text fontWeight="600" fontSize="15px" lineHeight="14px">
                {t("button.delete-profile")}
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>

      <>
        <Flex
          borderRadius="6px"
          shadow="sm"
          border="1px solid #E1E3EA"
          px="3"
          flexDir="column"
          bg="white"
        >
          {user && (
            <EditPersonalInfo
              handleEdit={(data) => editPersonalInfo(data, user)}
              userInfo={user}
            />
          )}
        </Flex>
        <Flex
          border="1px solid #E1E3EA"
          px="3"
          flexDir="column"
          bg="white"
          borderRadius="6px"
          shadow="sm"
        >
          {user && (
            <EditPassword handleEdit={(data) => editPasswordInfo(data, user)} />
          )}
        </Flex>
        <Flex
          border="1px solid #E1E3EA"
          px="3"
          flexDir="column"
          bg="white"
          borderRadius="6px"
          shadow="sm"
        >
          {user && (
            <EditBillingInfo
              handleEdit={(data, individual) =>
                editBillingInfo(data, individual, user)
              }
              billingInfo={address ? address[0] : undefined}
            />
          )}
        </Flex>
        {/* <Flex
          border="1px solid #E1E3EA"
          px="3"
          flexDir="column"
          bg="white"
          borderRadius="6px"
          shadow="sm"
        >
          {user && (
            <EditSubscriptionInfo
            //handleEdit={(data) => editBillingInfo(data, user)}
            />
          )}
        </Flex> */}
      </>
    </Flex>
  );
};
