import {
  Flex,
  VStack,
  Text,
  Box,
  SimpleGrid,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Checkbox,
  Button,
  CheckboxGroup,
  useToast,
} from "@chakra-ui/react";
import { useState, useMemo, FC, useCallback, useEffect, useRef } from "react";
import { parseISO, isAfter, endOfDay, startOfDay } from "date-fns";
import { useQuery } from "react-query";
import { APISchemas, GET, DELETE, POST } from "@/api";
import { Calendar } from "./components/calendar";
import { PostItem, PostItemHorizontal } from "@/components/post-item";
import { VerticalDirection } from "@/assets/icons/vertical-direction";
import { HorizontalDirection } from "@/assets/icons/horizontal-direction";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { PostModal } from "./post-modal";
import { Messages } from "./components/messages";
import { Notifications } from "./components/notifications";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useSearchParams } from "react-router-dom";
import { OrganizationSummary } from "./components/organization-summary";
import { AreaChart } from "./components/area-chart";
import { RefreshBoxIcon } from "@/assets/icons/refresh-box-icon";
import { FilterBoxIcon } from "@/assets/icons/filter-box-icon";
import { AccountSummaryItem } from "./account-container/account-summary-item";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { Pagination } from "@/components/pagination";
import {
  XCircleIcon,
  FacebookCircleIcon,
  InstagramColorfulIcon,
  LinkedinCircleIcon,
  TelegramCircleIcon,
  //YoutubeCircleIcon,
  TiktokCircleIcon,
} from "@/assets/brands";
interface FilterState {
  posted: boolean;
  planned: boolean;
  draft: boolean;
  cancelled: boolean;
  all: boolean;
  platforms: Array<string>;
  date?: Date | null;
}

interface ModalData {
  postModal?: APISchemas["Compose"];
  notificationModal?: APISchemas["Compose"];
}

export const Homepage: FC = () => {
  const [activeOrganization, setActiveOrganization] = useAuthStore((s) => [
    s.activeOrganization,
    s.setActiveOrganization,
  ]);

  const { t } = useTranslation();
  const [today, setToday] = useState<Date>(startOfDay(new Date()));
  const [currentPage, setCurrentPage] = useState<number>(1);
  const prevToday = useRef<Date>();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData>({});

  const [notificationModalOpen, setNotificationModalOpen] =
    useState<boolean>(false);
  const [layout, setLayout] = useState<"row" | "column">("column");

  const [filter, setFilter] = useState<FilterState>({
    planned: false,
    posted: false,
    draft: false,
    cancelled: false,
    all: true,
    platforms: [],
    date: today,
  });
  const [searchParams] = useSearchParams();
  const inviteResponseToken = searchParams.get("token");
  const inviteResponseAction = searchParams.get("action");
  const toast = useToast();
  const InviteResponseRequest = useCallback(async () => {
    if (!inviteResponseToken) {
      return;
    }
    try {
      const { response } = await POST(
        inviteResponseAction === "accept"
          ? "/invitation/accept/"
          : "/invitation/reject/",
        {
          body: {
            token: inviteResponseToken,
          },
        }
      );

      if (response.ok) {
        toast({
          status: "success",
          title: t("alert.invitation.organization-invitation-success"),
        });
      } else {
        toast({
          status: "error",
          title: t("alert.invitation.organization-invitation-error"),
        });
      }
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        title: t("errors.an-error-occurred"),
      });
    }
  }, [inviteResponseAction, inviteResponseToken, toast, t]);

  const { data: Membership } = useQuery(
    ["membership", activeOrganization?.id],
    async () => {
      if (!activeOrganization) return;

      const { data } = await GET("/membership/{org_pk}/get-membership/", {
        params: {
          path: {
            org_pk: activeOrganization.id.toString(),
          },
        },
      });

      return {
        ...data,
        start_date: data?.start_date ? parseISO(data?.start_date) : undefined,
        end_date: data?.end_date ? parseISO(data?.end_date) : undefined,
      };
    }
  );

  useQuery(["active-organization", activeOrganization?.id], async () => {
    if (!activeOrganization?.id) {
      return;
    }

    const { data } = await GET("/org/{id}/", {
      params: {
        path: {
          id: activeOrganization.id,
        },
      },
    });

    if (data) {
      setActiveOrganization(data);
    }

    return data;
  });

  const handleNotificationClick = (result: APISchemas["Compose"]) => {
    setModalData((prevState) => ({
      ...prevState,
      notificationModal: result,
    }));
    setNotificationModalOpen(true);
  };

  const {
    data: Composes,
    refetch,

    isRefetching,
  } = useQuery(
    ["posts", today.toISOString(), activeOrganization?.id],
    async () => {
      if (!activeOrganization) {
        return;
      }
      const { data, error } = await GET("/common/{org_pk}/compose/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
          },
          query: {
            start_date: i18nformat(startOfDay(today), "dd-MM-yyyy"),

            end_date: i18nformat(endOfDay(today), "dd-MM-yyyy"),
          },
        },
      });

      if (error) console.error("Get compose data error", error);
      return data;
    }
  );

  const handleDelete = useCallback(
    async (id: number) => {
      if (!activeOrganization) {
        return;
      }
      const { data, error } = await DELETE("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
            id: id,
          },
        },
      });
      if (error?.issues) {
        error.issues.map((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }
      toast({
        title: t("alert.success.post-deleted-successfully"),
        status: "success",
      });
      refetch();
      return data;
    },
    [activeOrganization, refetch, toast, t]
  );

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      date: today,
    }));
    refetch();
    InviteResponseRequest();
    setCurrentPage(1);
  }, [today, refetch, InviteResponseRequest]);

  const filteredComposes = useMemo(() => {
    const filtered: typeof Composes = [];
    let dateFiltered: typeof Composes = Composes;

    if (filter.platforms.length > 0) {
      dateFiltered = dateFiltered?.filter((f) => {
        let havePlatform = false;
        filter.platforms.forEach((p) => {
          if (p.startsWith("twitter")) {
            havePlatform = (f.twitter_posts?.length ?? 0) > 0;
          }

          if (p.startsWith("facebook")) {
            havePlatform = (f.facebook_posts?.length ?? 0) > 0;
          }

          if (p.startsWith("instagram")) {
            havePlatform = (f.instagram_posts?.length ?? 0) > 0;
          }

          if (p.startsWith("linkedin")) {
            havePlatform = (f.linkedin_posts?.length ?? 0) > 0;
          }
          if (p.startsWith("youtube")) {
            havePlatform = (f.youtube_posts?.length ?? 0) > 0;
          }
          if (p.startsWith("telegram")) {
            havePlatform = (f.telegram_posts?.length ?? 0) > 0;
          }
          if (p.startsWith("tiktok")) {
            havePlatform = (f.tiktok_posts?.length ?? 0) > 0;
          }

          if (havePlatform) {
            return;
          }
        });

        return havePlatform;
      });
    }

    const checkAllPlatformsStatus = (
      compose: NonNullable<typeof dateFiltered>[number],
      status: NonNullable<
        typeof dateFiltered
      >[number]["twitter_posts"][number]["status"]
    ) => {
      for (const post of compose.twitter_posts) {
        if (post.status === status) {
          return true;
        }
      }

      for (const post of compose.facebook_posts) {
        if (post.status === status) {
          return true;
        }
      }

      for (const post of compose.linkedin_posts) {
        if (post.status === status) {
          return true;
        }
      }
      for (const post of compose.telegram_posts) {
        if (post.status === status) {
          return true;
        }
      }
      for (const post of compose.youtube_posts) {
        if (post.status === status) {
          return true;
        }
      }
      for (const post of compose.tiktok_posts) {
        if (post.status === status) {
          return true;
        }
      }
      for (const post of compose.instagram_posts) {
        if (post.status === status) {
          return true;
        }
      }
    };

    dateFiltered?.forEach((post) => {
      if (filter.all) {
        filtered.push(post);

        return;
      }

      if (filter.posted) {
        if (!checkAllPlatformsStatus(post, "posted")) {
          return;
        }

        filtered.push(post);
        return;
      }
      if (filter.draft) {
        if (!checkAllPlatformsStatus(post, "draft")) {
          return;
        }

        filtered.push(post);
        return;
      }

      if (filter.cancelled) {
        if (!checkAllPlatformsStatus(post, "cancelled")) {
          return;
        }

        filtered.push(post);
        return;
      }

      if (filter.planned) {
        const now = new Date();
        if (
          !isAfter(post.scheduled_at ? parseISO(post.scheduled_at) : now, now)
        ) {
          return;
        }

        filtered.push(post);
        return;
      }

      filtered.push(post);
    });

    return filtered;
  }, [Composes, filter]);

  const totalShareSum =
    (activeOrganization?.summary.facebook.shared_posts ?? 0) +
    (activeOrganization?.summary.instagram.shared_posts ?? 0) +
    (activeOrganization?.summary.linkedin.shared_posts ?? 0) +
    (activeOrganization?.summary.twitter.shared_posts ?? 0) +
    (activeOrganization?.summary.youtube.shared_posts ?? 0) +
    (activeOrganization?.summary.tiktok?.shared_posts ?? 0) +
    (activeOrganization?.summary.telegram.shared_posts ?? 0);

  const resultData = useMemo(() => {
    if (!activeOrganization?.summary) {
      return [];
    }

    const stats = Object.values(activeOrganization.summary).reduce(
      (acc, value) => {
        const stat = value.stats as Array<{
          day: string;
          count: number;
        }>;

        stat.forEach((s) => {
          const index = acc.findIndex((a) => a.day === s.day);

          if (index === -1) {
            acc.push(s);
            return;
          }

          acc[index].count += s.count;
        });

        return acc;
      },
      [] as Array<{ day: string; count: number }>
    );

    return stats;
  }, [activeOrganization?.summary]);

  const postPerPage = 8;
  const totalPages = Math.ceil(filteredComposes?.length / postPerPage);
  const startIndex = (currentPage - 1) * postPerPage;
  const endIndex = startIndex + postPerPage;
  const currentPageData = filteredComposes?.slice(startIndex, endIndex);

  const sortedCurrentPageDate = currentPageData.sort((a, b) => {
    const dateA = new Date(a.scheduled_at ?? 0);
    const dateB = new Date(b.scheduled_at ?? 0);

    return dateA.getTime() - dateB.getTime();
  });

  return (
    <VStack
      overflow="hidden"
      pos="relative"
      maxWidth="100vw"
      bg="gray.bg"
      p="20px"
    >
      <Flex w="full" gap="15px" h="full">
        <Box w="25%">
          <Flex
            h="233px"
            w="full"
            mb="20px"
            borderRadius="3px"
            boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
          >
            <OrganizationSummary
              plan={Membership?.plan_kind?.plan?.name}
              data={[
                {
                  text: t("page.dashboard.connected-channels"),
                  num1: Membership?.connected_channels,
                  num2: Membership?.plan_kind?.plan.channel_limit,
                },
                {
                  text: t("page.dashboard.team-members"),
                  num1: Membership?.team_members,
                  num2: Membership?.plan_kind?.plan?.user_limit,
                },
                {
                  text: t("page.dashboard.daily-post-limit"),
                  num1: Membership?.daily_post_total,
                  num2: Membership?.daily_post_limit
                    ? Membership?.daily_post_limit === 0
                      ? "∞"
                      : Membership?.daily_post_limit
                    : "∞",
                },
              ]}
            />
          </Flex>
          <Flex h="485px" w="full">
            <Box
              as={OverlayScrollbarsComponent}
              w="full"
              options={{
                overflow: {
                  x: "hidden",
                  y: "scroll",
                },
                scrollbars: {
                  autoHide: "leave",
                  autoHideDelay: 300,
                },
              }}
            >
              <SimpleGrid
                columns={{
                  base: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 2,
                  "2xl": 2,
                }}
                gap="10px"
                w="full"
              >
                <AccountSummaryItem
                  title="X"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.twitter.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.twitter.queued ?? 0}
                  children={<XCircleIcon />}
                />
                <AccountSummaryItem
                  title="Instagram"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.instagram.shared_posts ?? 0
                  }
                  queueNumber={
                    activeOrganization?.summary.instagram.queued ?? 0
                  }
                  children={<InstagramColorfulIcon />}
                />
                <AccountSummaryItem
                  title="Linkedin"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.linkedin.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.linkedin.queued ?? 0}
                  children={<LinkedinCircleIcon />}
                />
                <AccountSummaryItem
                  title="Telegram"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.telegram.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.telegram.queued ?? 0}
                  children={<TelegramCircleIcon />}
                />
                {/* <AccountSummaryItem
                  title="Youtube"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.youtube.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.facebook.queued ?? 0}
                  children={<YoutubeCircleIcon />}
                /> */}
                <AccountSummaryItem
                  title="Facebook"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.facebook.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.facebook.queued ?? 0}
                  children={<FacebookCircleIcon />}
                />
                <AccountSummaryItem
                  title="Tiktok"
                  accountNumber={10}
                  sharedPostNumber={
                    activeOrganization?.summary.tiktok?.shared_posts ?? 0
                  }
                  queueNumber={activeOrganization?.summary.tiktok?.queued ?? 0}
                  children={<TiktokCircleIcon />}
                />
              </SimpleGrid>
            </Box>
          </Flex>
        </Box>

        <Flex flexDir="column" w="50%" gap="16px">
          {/* <Flex w="full" h="122px" mb="20px" gap="20px">
            <Flex w="full" h="full" gap="20px" flexDir="column">
              <Flex w="full" h="full" gap="20px">
                <PostSummaryItem
                  icon={<DraftPostIcon />}
                  text={t("page.dashboard.draft-post")}
                  num={draftSum}
                />
              </Flex>
              <Flex w="full" h="full" gap="20px">
                <PostSummaryItem
                  icon={<WaitingPostIcon />}
                  text={t("page.dashboard.waiting-post")}
                  num={waitingSum}
                />
              </Flex>
            </Flex>
            <Flex w="full" h="full" gap="20px" flexDir="column">
              <Flex w="full" h="full" gap="20px">
                <PostSummaryItem
                  icon={<PlannedPostIcon />}
                  text={t("page.dashboard.planned-post")}
                  num={plannedSum}
                />
              </Flex>
              <Flex w="full" h="full" gap="20px">
                <PostSummaryItem
                  icon={<TotalShareIcon />}
                  text={t("page.dashboard.total-share")}
                  num={totalShareSum}
                />
              </Flex>
            </Flex>
          </Flex> */}
          <Flex w="full" h="50%">
            <AreaChart data={resultData} totalSharing={totalShareSum} />
          </Flex>
          <Flex w="full" h="50%">
            <Messages />
          </Flex>
        </Flex>

        <Box w="25%" h="738px">
          <Notifications onPress={handleNotificationClick} />
        </Box>
        {!!modalData.notificationModal && (
          <PostModal
            onDelete={(id) => handleDelete(id)}
            data={modalData.notificationModal}
            isOpen={notificationModalOpen}
            close={() => setNotificationModalOpen(false)}
          />
        )}
      </Flex>

      <Box w="full" mt="24px">
        <Calendar
          active={today}
          calendarMode={"days"}
          selectedDate={today}
          prevSelected={prevToday.current}
          onPress={(item: Date) => {
            setToday((curr) => {
              if (!prevToday.current) {
                prevToday.current = curr;
              }
              return item;
            });
          }}
        />
      </Box>
      <Flex
        w="full"
        my="16px"
        alignItems="center"
        justifyContent="space-between"
        bg="white"
        height="44px"
        borderRadius="3px"
        border="1px solid"
        borderColor="gray.athens"
        px="4"
      >
        <Flex
          justifyContent="space-between"
          h="30px"
          alignItems="center"
          w="max-content"
          gap="47px"
        >
          <Button
            opacity={filter.all == true ? "1" : "0.33"}
            variant="unstyled"
            size="none"
            cursor="pointer"
            onClick={() =>
              setFilter((state) => ({
                ...state,
                all: !state.all,
                posted: false,
                planned: false,
                draft: false,
                cancelled: false,
              }))
            }
          >
            <Text
              color={filter.all == true ? "blue.shiny" : "black.active"}
              textDecor={filter.all === true ? "underline" : undefined}
              fontSize="16px"
              fontWeight="700"
            >
              {t("page.dashboard.all")}
            </Text>
          </Button>

          <Button
            opacity={filter.planned == true ? "1" : "0.33"}
            variant="unstyled"
            size="none"
            cursor="pointer"
            onClick={() =>
              setFilter((state) => ({
                ...state,
                all: false,
                posted: false,
                planned: !state.planned,
                draft: false,
                cancelled: false,
              }))
            }
          >
            <Text
              color={filter.planned == true ? "blue.shiny" : "black.active"}
              textDecor={filter.planned === true ? "underline" : undefined}
              fontSize="16px"
              fontWeight="700"
            >
              {t("planned")}
            </Text>
          </Button>
          <Button
            opacity={filter.posted == true ? "1" : "0.33"}
            variant="unstyled"
            size="none"
            cursor="pointer"
            onClick={() =>
              setFilter((state) => ({
                ...state,
                all: false,
                posted: !state.posted,
                planned: false,
                draft: false,
                cancelled: false,
              }))
            }
          >
            <Text
              color={filter.posted == true ? "blue.shiny" : "black.active"}
              textDecor={filter.posted === true ? "underline" : undefined}
              fontSize="16px"
              fontWeight="700"
            >
              {t("posted")}
            </Text>
          </Button>

          <Button
            variant="unstyled"
            cursor="pointer"
            size="none"
            opacity={filter.draft == true ? "1" : "0.33"}
            onClick={() =>
              setFilter((state) => ({
                ...state,
                all: false,
                posted: false,
                planned: false,
                draft: !state.draft,
                cancelled: false,
              }))
            }
          >
            <Text
              color={filter.draft == true ? "blue.shiny" : "black.active"}
              textDecor={filter.draft === true ? "underline" : undefined}
              fontSize="16px"
              fontWeight="700"
            >
              {t("drafts")}
            </Text>
          </Button>
          <Button
            variant="unstyled"
            cursor="pointer"
            size="none"
            opacity={filter.cancelled == true ? "1" : "0.33"}
          >
            <Text
              color={filter.cancelled == true ? "blue.shiny" : "black.active"}
              textDecor={filter.cancelled === true ? "underline" : undefined}
              fontSize="16px"
              fontWeight="600"
              onClick={() =>
                setFilter((state) => ({
                  ...state,
                  posted: false,
                  planned: false,
                  draft: false,
                  cancelled: !state.cancelled,
                }))
              }
            >
              {t("cancelled")}
            </Text>
          </Button>
        </Flex>

        <Flex h="30px" w="max-content" gap="16px">
          <IconButton
            variant="unstyled"
            cursor="pointer"
            aria-label="refresh"
            pl="3"
            width="15px"
            height="30px"
            bg="white"
            px="1"
            alignItems="center"
            justifyContent="center"
            isLoading={isRefetching}
            onClick={() => {
              refetch();
            }}
          >
            <RefreshBoxIcon width="30px" height="30px" />
          </IconButton>

          <Menu
            closeOnSelect={false}
            placement="bottom-end"
            strategy="fixed"
            eventListeners={{
              resize: true,
            }}
          >
            <MenuButton px="3px" ml="1" cursor="pointer">
              <FilterBoxIcon width="30px" height="30px" />
            </MenuButton>

            <MenuList borderRadius="2px">
              <CheckboxGroup
                onChange={(value) =>
                  setFilter((state) => ({
                    ...state,
                    platforms: value.map((v) => v.toString()),
                  }))
                }
              >
                <MenuItem>
                  <Checkbox value="twitter">Twitter</Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox value="facebook">Facebook</Checkbox>
                </MenuItem>

                <MenuItem>
                  <Checkbox value="instagram">Instagram</Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox value="linkedIn">LinkedIn</Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox value="telegram">Telegram</Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox value="tiktok">Tiktok</Checkbox>
                </MenuItem>
              </CheckboxGroup>
            </MenuList>
          </Menu>

          <Flex
            ml="1"
            justifyContent="center"
            alignItems="center"
            px="2"
            h="30px"
            borderRadius="4px"
            bg="white.bg"
            border="1px solid #EBEDF2"
          >
            <Button mr="6" size="none" variant="unstyled" cursor="pointer">
              <HorizontalDirection
                width="20px"
                height="20px"
                color={layout == "column" ? "#57AAEC" : "lightgray"}
                onClick={() => setLayout("column")}
              />
            </Button>
            <Button size="none" variant="unstyled" cursor="pointer">
              <VerticalDirection
                width="20px"
                height="20px"
                color={layout == "row" ? "#57AAEC" : "lightgray"}
                onClick={() => setLayout("row")}
              />
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {/* <Spinner hidden={!isLoading} /> */}

      {layout === "column" ? (
        <Box h="790px" w="full">
          {sortedCurrentPageDate.length === 0 ? (
            <Box
              w="full"
              minH="40vh"
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Text
                fontWeight="500"
                fontSize="22px"
                color="black.active"
                textAlign="center"
              >
                {t("page.dashboard.no-post")}
              </Text>
            </Box>
          ) : (
            <SimpleGrid
              w="full"
              gap="24px"
              columns={4}
              justifyContent="space-between"
              pb="15"
            >
              {sortedCurrentPageDate.map((compose) => (
                <Box
                  key={compose.id}
                  onClick={() => {
                    setModalData((prevState) => ({
                      ...prevState,
                      postModal: compose,
                    }));
                    setModalOpen(true);
                  }}
                >
                  <PostItem compose={compose} />
                </Box>
              ))}
            </SimpleGrid>
          )}
          <Box w="full">
            {totalPages > 1 && (
              <Box p={4} justifyContent="center" display="flex">
                <Pagination
                  totalPages={totalPages}
                  currentPage={currentPage}
                  onPageChange={setCurrentPage}
                />
              </Box>
            )}{" "}
          </Box>
        </Box>
      ) : (
        <>
          {sortedCurrentPageDate.length === 0 ? (
            <Box
              w="full"
              h="40vh"
              alignItems="center"
              justifyContent="center"
              display="flex"
              flexDirection="column"
            >
              <Text
                fontWeight="500"
                fontSize="22px"
                color="black.active"
                textAlign="center"
              >
                {t("page.dashboard.no-post")}
              </Text>
            </Box>
          ) : (
            sortedCurrentPageDate.map((compose) => (
              <Box
                w="full"
                key={compose.id}
                onClick={() => {
                  setModalData((prevState) => ({
                    ...prevState,
                    postModal: compose,
                  }));
                  setModalOpen(true);
                }}
              >
                <PostItemHorizontal compose={compose} />
              </Box>
            ))
          )}
          {totalPages > 1 && (
            <Box p={4}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </Box>
          )}{" "}
        </>
      )}
      {!!modalData.postModal && (
        <PostModal
          onDelete={(id) => handleDelete(id)}
          data={modalData.postModal}
          isOpen={modalOpen}
          close={() => setModalOpen(false)}
        />
      )}
    </VStack>
  );
};
