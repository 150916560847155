import { Icon } from "@chakra-ui/react";
import { FC } from "react";

export const TrashIconRed: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <Icon width="8" height="8">
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <rect
          x="0.5"
          y="0.5"
          width="15"
          height="15"
          rx="2.5"
          fill="#F7F8FA"
          stroke="#EBEDF2"
        />
        <path
          d="M11.0549 4.49881H9.77956V4.24373C9.77956 3.82179 9.43629 3.47852 9.01435 3.47852H6.97377C6.55183 3.47852 6.20855 3.82179 6.20855 4.24373V4.49881H4.93319C4.51125 4.49881 4.16797 4.84208 4.16797 5.26402C4.16797 5.6029 4.38946 5.89084 4.6952 5.99117L5.15017 11.4833C5.18294 11.8768 5.5179 12.185 5.91273 12.185H10.0754C10.4702 12.185 10.8052 11.8768 10.838 11.4832L11.2929 5.99115C11.5987 5.89084 11.8201 5.6029 11.8201 5.26402C11.8201 4.84208 11.4769 4.49881 11.0549 4.49881ZM6.71869 4.24373C6.71869 4.10309 6.83312 3.98866 6.97377 3.98866H9.01435C9.15499 3.98866 9.26942 4.10309 9.26942 4.24373V4.49881H6.71869V4.24373ZM10.3296 11.441C10.3186 11.5721 10.207 11.6748 10.0754 11.6748H5.91273C5.78113 11.6748 5.66948 11.5721 5.65858 11.4411L5.21026 6.02924H10.7778L10.3296 11.441ZM11.0549 5.5191H4.93319C4.79254 5.5191 4.67811 5.40467 4.67811 5.26402C4.67811 5.12338 4.79254 5.00895 4.93319 5.00895H11.0549C11.1956 5.00895 11.31 5.12338 11.31 5.26402C11.31 5.40467 11.1956 5.5191 11.0549 5.5191Z"
          fill="#E52625"
        />
        <path
          d="M6.97568 10.8955L6.72061 6.78032C6.71189 6.6397 6.59018 6.53276 6.45025 6.54152C6.30964 6.55024 6.20273 6.67128 6.21143 6.81188L6.46651 10.9271C6.47489 11.0623 6.58719 11.1664 6.72085 11.1664C6.86857 11.1664 6.98475 11.0419 6.97568 10.8955Z"
          fill="#E52625"
        />
        <path
          d="M7.98945 6.54102C7.84858 6.54102 7.73438 6.65522 7.73438 6.79609V10.9113C7.73438 11.0521 7.84858 11.1663 7.98945 11.1663C8.13032 11.1663 8.24452 11.0521 8.24452 10.9113V6.79609C8.24452 6.65522 8.13032 6.54102 7.98945 6.54102Z"
          fill="#E52625"
        />
        <path
          d="M9.54155 6.54151C9.40126 6.53279 9.2799 6.6397 9.27119 6.78031L9.01612 10.8955C9.00743 11.0361 9.11434 11.1571 9.25493 11.1658C9.39562 11.1745 9.51659 11.0676 9.52529 10.927L9.78037 6.81187C9.78907 6.67126 9.68216 6.55022 9.54155 6.54151Z"
          fill="#E52625"
        />
      </svg>
    </Icon>
  );
};
