import { FC } from "react";

export const AddAccountIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="17" cy="17" r="17" fill={props.fill || "white"}></circle>
      <circle cx="17" cy="17.0013" r="14.3333" fill="white"></circle>
      <circle cx="17" cy="17.0013" r="14.3333" fill="white"></circle>
      <circle cx="17" cy="17" r="12" fill="#33373D80"></circle>
      <path
        d="M22.5312 16.5312H17.4688V11.4688C17.4688 11.2099 17.2589 11 17 11C16.7411 11 16.5312 11.2099 16.5312 11.4688V16.5312H11.4688C11.2099 16.5312 11 16.7411 11 17C11 17.2589 11.2099 17.4688 11.4688 17.4688H16.5312V22.5312C16.5312 22.7901 16.7411 23 17 23C17.2589 23 17.4688 22.7901 17.4688 22.5312V17.4688H22.5312C22.7901 17.4688 23 17.2589 23 17C23 16.7411 22.7901 16.5312 22.5312 16.5312Z"
        fill="white"
      ></path>
    </svg>
  );
};
