export const TrashBoxIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="39"
      height="39"
      viewBox="0 0 39 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="0.5"
        width="38"
        height="38"
        rx="2.5"
        fill="#FAFAFA"
        stroke="#EBEDF2"
      />
      <path
        d="M23.1315 16.4082C22.7956 16.4082 22.5234 16.6804 22.5234 17.0163V28.5088C22.5234 28.8444 22.7956 29.1169 23.1315 29.1169C23.4674 29.1169 23.7396 28.8444 23.7396 28.5088V17.0163C23.7396 16.6804 23.4674 16.4082 23.1315 16.4082Z"
        fill="#E52625"
      />
      <path
        d="M15.9557 16.4082C15.6199 16.4082 15.3477 16.6804 15.3477 17.0163V28.5088C15.3477 28.8444 15.6199 29.1169 15.9557 29.1169C16.2916 29.1169 16.5638 28.8444 16.5638 28.5088V17.0163C16.5638 16.6804 16.2916 16.4082 15.9557 16.4082Z"
        fill="#E52625"
      />
      <path
        d="M10.727 14.7299V29.7115C10.727 30.597 11.0517 31.4286 11.6189 32.0252C12.1835 32.6236 12.9693 32.9632 13.7916 32.9647H25.2965C26.119 32.9632 26.9048 32.6236 27.4691 32.0252C28.0363 31.4286 28.361 30.597 28.361 29.7115V14.7299C29.4886 14.4306 30.2192 13.3413 30.0684 12.1844C29.9173 11.0276 28.9318 10.1623 27.7651 10.162H24.6518V9.40196C24.6554 8.76277 24.4027 8.149 23.9502 7.69746C23.4977 7.24616 22.883 6.99485 22.2438 7.00008H16.8443C16.2051 6.99485 15.5904 7.24616 15.1379 7.69746C14.6854 8.149 14.4327 8.76277 14.4362 9.40196V10.162H11.323C10.1562 10.1623 9.17074 11.0276 9.01967 12.1844C8.86884 13.3413 9.59948 14.4306 10.727 14.7299ZM25.2965 31.7485H13.7916C12.7519 31.7485 11.9432 30.8554 11.9432 29.7115V14.7834H27.1449V29.7115C27.1449 30.8554 26.3361 31.7485 25.2965 31.7485ZM15.6524 9.40196C15.6483 9.08533 15.7728 8.78058 15.9975 8.55707C16.222 8.33356 16.5274 8.21076 16.8443 8.21622H22.2438C22.5606 8.21076 22.8661 8.33356 23.0906 8.55707C23.3153 8.78035 23.4397 9.08533 23.4357 9.40196V10.162H15.6524V9.40196ZM11.323 11.3782H27.7651C28.3696 11.3782 28.8596 11.8682 28.8596 12.4727C28.8596 13.0772 28.3696 13.5672 27.7651 13.5672H11.323C10.7185 13.5672 10.2284 13.0772 10.2284 12.4727C10.2284 11.8682 10.7185 11.3782 11.323 11.3782Z"
        fill="#E52625"
      />
      <path
        d="M19.5436 16.4082C19.2078 16.4082 18.9355 16.6804 18.9355 17.0163V28.5088C18.9355 28.8444 19.2078 29.1169 19.5436 29.1169C19.8795 29.1169 20.1517 28.8444 20.1517 28.5088V17.0163C20.1517 16.6804 19.8795 16.4082 19.5436 16.4082Z"
        fill="#E52625"
      />
    </svg>
  );
};
