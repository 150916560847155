import { Auth } from "./pages/auth";
import { Routes, Route, Navigate } from "react-router-dom";
import { MembershipPage } from "./pages/dashboard/Membership";
import { PlannerPage } from "./pages/dashboard/Schedule";
import { SettingsPage } from "./pages/dashboard/Settings";
import { Homepage } from "./pages/dashboard/Homepage";
import PrivateRoutes from "./utils/PrivateRoute";
import { Terms } from "./pages/Terms";
import { Privacy } from "./pages/Privacy";
import { TwitterCallback } from "./pages/TwitterCallback";
import { LinkedinCallback } from "./pages/LinkedinCallback";
import { FacebookCallback } from "./pages/FacebookCallback";
import { TiktokCallback } from "./pages/TiktokCallback";
import { PostPage } from "./pages/dashboard/Post/post-page";
import { MembershipPlans } from "./pages/dashboard/Membership/membership-plans";
import { MembershipReview } from "./pages/dashboard/Membership/_membership_review";
import { ChannelConnectRoutes } from "./pages/channel/";
import { MembershipPackages } from "./pages/dashboard/Membership/membership-packages";
import { useConfigStore } from "./context/config-store/config-store";
import { InstagramCallback } from "./pages/InstagramCallback";
import { PaymentCallback } from "./pages/dashboard/Membership/components/payment-callback";
import { Notifications } from "./pages/dashboard/Notifications";
import InviteCallback from "./pages/InviteCallback";
import { AcceptInviteCallbackPage } from "./pages/AcceptInviteCallback";
import RejectInviteCallbackPage from "./pages/RejectInviteCallback";
import { brandTitle, PACKAGE_VERSION } from "./constant";

import { PrivateWithoutLayout } from "./utils/PrivateWithoutLayoutRoute";

import { Analytics } from "./pages/dashboard/Analytics";

import { TicketModal } from "./components/ticket-modal";
import { Box } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";
import { HelpCenterPage } from "./pages/dashboard/Help-Center/help-center";
function App() {
  const [language, changeLanguage, version, updateVersion] = useConfigStore(
    (c) => [c.language, c.changeLanguage, c.version, c.updateVersion]
  );

  if (brandTitle) {
    document.title = brandTitle;
  }

  if (version !== PACKAGE_VERSION) {
    updateVersion(PACKAGE_VERSION);
  }

  if (!language) {
    changeLanguage();
  }

  // useEffect(() => {
  //   if (auth) {
  //     navigate("/");
  //   }
  // }, [auth, navigate]);

  // useEffect(() => {
  //   const id = sessionStorage.getItem("id");

  //   if (!id) {
  //     if (useBrowserStore.getState().tabs.length === 0) {
  //       useAuthStore.getState().logout();
  //     }
  //     const id = Date.now().toString();
  //     sessionStorage.setItem("id", Date.now().toString());
  //     useBrowserStore.getState().addTab(id);
  //   } else {
  //     useBrowserStore.getState().addTab(id);
  //   }

  //   const logoutOnUnload = async () => {
  //     const id = sessionStorage.getItem("id");
  //     if (!id) {
  //       return;
  //     }

  //     await useBrowserStore.getState().removeTab(id);
  //   };

  //   window.addEventListener("beforeunload", logoutOnUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", logoutOnUnload);
  //   };
  // }, []);

  return (
    <Sentry.ErrorBoundary showDialog>
      <Routes>
        <Route path="auth/*" element={<Auth />} />
        <Route element={<PrivateWithoutLayout />}>
          <Route path="channels/*" element={<ChannelConnectRoutes />} />
        </Route>
        <Route element={<PrivateRoutes />}>
          <Route path="" element={<Homepage />} />
          <Route path="notifications" element={<Notifications />} />
          <Route path="planner" element={<PlannerPage />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="membership" element={<MembershipPage />} />
          <Route path="membership/plans" element={<MembershipPackages />} />
          <Route path="membership/upgrade" element={<MembershipPlans />} />
          <Route path="membership/payment/:id" element={<MembershipReview />} />
          <Route path="payment/callback" element={<PaymentCallback />} />
          <Route path="settings" element={<SettingsPage />} />
          <Route path="post/:id" element={<PostPage />} />
          <Route path="post/" element={<PostPage />} />
          <Route path="help" element={<HelpCenterPage />} />
        </Route>
        <Route path="/invite/callback" element={<InviteCallback />} />
        <Route
          path="/invite/accept/callback"
          element={<AcceptInviteCallbackPage />}
        />
        <Route
          path="/invite/reject/callback"
          element={<RejectInviteCallbackPage />}
        />
        <Route path="twitter/auth/callback" element={<TwitterCallback />} />
        <Route path="tiktok/auth/callback" element={<TiktokCallback />} />
        <Route path="linkedin/auth/callback" element={<LinkedinCallback />} />
        <Route path="facebook/auth/callback" element={<FacebookCallback />} />
        <Route path="instagram/auth/callback" element={<InstagramCallback />} />
        {/* <Route path="youtube/auth/callback" element={<YoutubeCallback />} /> */}
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
      <Box pos="fixed" bottom="5" right="5">
        <TicketModal />
      </Box>
    </Sentry.ErrorBoundary>
  );
}

export default App;
