import { GET } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { PlaySignIcon } from "@/assets/icons/play-sign-icon";
import {
  Button,
  Flex,
  Tooltip,
  IconButton,
  Text,
  VStack,
  Box,
  Divider,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useMatch, useNavigate } from "react-router-dom";
import React, { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useConfigStore } from "@/context/config-store/config-store";
import { HamburgerMenuIcon } from "@/assets/icons/hamburger-menu-icon";
import { ThreeDotsCircleIcon } from "@/assets/icons/three-dots-circle";
import { NotificationsSquareIcon } from "@/assets/icons/notifications-square-icons";
import { LineIcon } from "@/assets/icons/line-icon";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useChannel } from "@/hooks/use-channels";
import { useTriggerStore } from "@/context/trigger-store/trigger-store";
import { useOrganization } from "@/hooks/use-organization";

export const Topbar: FC = () => {
  const { t } = useTranslation();

  const postPage = useMatch({
    path: "/post",
    end: false,
  });
  const notificationChanges = useTriggerStore((state) => state);
  const [organization, setActiveOrg] = useAuthStore((s) => [
    s.activeOrganization,
    s.setActiveOrganization,
  ]);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  const [notificationOpen, setNotificationOpen] = useState<boolean>(false);
  const [redDotVisible, setRedDotVisible] = useState<boolean>(false);

  const [maxAvatars, setMaxAvatars] = useState<number>(8);
  // const organizationChanges = useTriggerStore(
  //   (state) => state.organizationChanges
  // );
  // const { data: OrganizationList } = useQuery(
  //   ["organizations", organizationChanges],
  //   async () => {
  //     const { data, error } = await GET("/org/", {});

  //     if (error) console.error("organizationList error", error);
  //     return data;
  //   }
  // );

  const { data: OrganizationList } = useOrganization();

  const { sidebar, toggleSidebarState } = useConfigStore((state) => ({
    sidebar: state.sidebar,
    toggleSidebarState: state.toggleSidebarState,
  }));
  const navigate = useNavigate();

  const { data: Channels } = useChannel();
  const activeChannels = Channels?.filter((channel) => channel.status).sort(
    (a, b) => {
      const statusOrder = { active: 1, revoked: 0 };
      return (
        (statusOrder[a.status as keyof typeof statusOrder] || Infinity) -
        (statusOrder[b.status as keyof typeof statusOrder] || Infinity)
      );
    }
  );

  const { data: Notifications } = useQuery(
    ["notifications", notificationChanges],
    async () => {
      const { data, error } = await GET("/notifications/", {
        params: {
          query: {
            page: 1,
            page_size: 10,
          },
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + localStorage.token,
        },
      });

      if (error) console.error("notification data error", error);
      return data;
    },
    {
      select(data) {
        if (data && data.results) {
          data.results = data?.results?.filter((d) => d.state.read === false);
        }
        return data;
      },
    }
  );

  const avatarsToDisplay = activeChannels?.slice(0, maxAvatars);
  const hiddenCount = Math.max((activeChannels?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = activeChannels?.slice(
    maxAvatars,
    activeChannels.length
  );

  useEffect(() => {
    const resize = async () => {
      setMaxAvatars(
        Math.max(3, Math.min(8, Math.floor(window.innerWidth / 160)))
      );
    };

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  return (
    <VStack
      justifyContent="center"
      alignItems="flex-start"
      w="full"
      bgColor="white"
      h="75px"
      maxHeight="75px"
      pos="sticky"
      top="0"
      zIndex="sticky"
    >
      <Flex justifyContent="space-between" w="full">
        <Flex alignItems="center">
          <IconButton
            transform={sidebar ? undefined : "rotate(270deg)"}
            m="20px"
            cursor="pointer"
            variant="unstyled"
            onClick={() => {
              toggleSidebarState();
            }}
            aria-label="Sidebar Menu"
            icon={<HamburgerMenuIcon />}
          />

          <Flex alignItems="center" justifyContent="space-between" gap="12px">
            {avatarsToDisplay?.map((avatar) => (
              <AvatarWithBadge
                status={avatar.status}
                w="36px"
                h="36px"
                key={avatar.id}
                tooltip
                addCompose={avatar.channel_type !== "facebook_account"}
                email={
                  avatar.username
                    ? avatar.username.startsWith("@")
                      ? avatar.username
                      : `@${avatar.username}`
                    : avatar.name
                }
                picture={avatar.picture ?? undefined}
                channel={avatar.channel_type}
                channel_id={avatar.id.toString()}
              />
            ))}
            {hiddenCount > 0 && (
              <Tooltip
                key="tooltip"
                shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                borderRadius="4px"
                bg="white"
                color="black"
                p="4px"
                label={
                  <ul key="list">
                    {hiddenAvatars?.map((avatar) => (
                      <React.Fragment key={avatar.id}>
                        <Text
                          fontWeight="500"
                          key={avatar.id}
                          fontSize="12px"
                          p="6px"
                          color={
                            avatar.status === "active" ? "#50CD89" : "#E52625"
                          }
                        >
                          {avatar.username
                            ? avatar.username.startsWith("@")
                              ? avatar.username
                              : `@${avatar.username}`
                            : avatar.name}
                        </Text>
                      </React.Fragment>
                    ))}
                  </ul>
                }
              >
                <Button cursor="pointer" variant="unstyled">
                  <ThreeDotsCircleIcon />
                </Button>
              </Tooltip>
            )}
            <Button
              variant="unstyled"
              cursor="pointer"
              w="full"
              h="full"
              onClick={() => {
                navigate("/channels");
              }}
            >
              <PlaySignIcon width="34px" height="34px" />
            </Button>
          </Flex>
        </Flex>

        <Flex mr="20px" alignItems="center">
          <Popover isOpen={popoverOpen} onClose={() => setPopoverOpen(false)}>
            <PopoverTrigger>
              <Button
                isDisabled={!!postPage}
                mr="34px"
                px="2"
                width="170px"
                h="36px"
                bg="white"
                border="1px solid #3B5998 "
                borderRadius="6px"
                onClick={() => setPopoverOpen(!popoverOpen)}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                >
                  <Text
                    textAlign="center"
                    as="span"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontSize="14px"
                    color="blue.main"
                  >
                    {organization?.name}
                  </Text>
                  {popoverOpen ? (
                    <Box>
                      <ChevronUpIcon color="blue.main" />
                    </Box>
                  ) : (
                    <Box>
                      <ChevronDownIcon color="blue.main" />
                    </Box>
                  )}
                </Flex>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              bg="white"
              borderRadius="6px"
              w="170px"
              border="1px solid #3B5998"
              p="0px"
            >
              <PopoverBody p="0px">
                {OrganizationList?.map((item, index) => (
                  <React.Fragment key={item.id}>
                    <Button
                      px="2"
                      _hover={{ bg: "gray.soft" }}
                      variant="unstyled"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontSize="14px"
                      w="full"
                      textAlign="left"
                      color="blue.main"
                      fontWeight="600"
                      key={index}
                      onClick={() => {
                        setActiveOrg(item);
                        setPopoverOpen(false);
                      }}
                    >
                      {item?.name}
                    </Button>
                    {index !== OrganizationList?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Box mr="25px">
            <LineIcon />
          </Box>
          {/* <Button
            //onClick={onOpen}
            variant="unstyled"
            cursor="pointer"
            mr="16px"
          >
            <PlaneSquareIcon />
          </Button> */}
          <Button
            onClick={() => {
              setNotificationOpen((state) => {
                if (!state) {
                  navigate("/notifications");
                } else {
                  navigate(-1);
                }

                return !state;
              });
            }}
            variant="unstyled"
            cursor="pointer"
            mr="25px"
          >
            <NotificationsSquareIcon
              reddot={
                redDotVisible &&
                Notifications?.results?.length &&
                Notifications?.results?.length > 0
                  ? true
                  : false
              }
            />
          </Button>
          <Box mr="34px">
            <LineIcon />
          </Box>

          <Button
            _hover={{
              color: "green.label",
              border: "2px solid",
              borderColor: "green.label",
              bgColor: "white",
            }}
            onClick={() => navigate("/post")}
            w="168px"
            h="36px"
            color="white"
            bg="green.label"
            border-radius="4px"
            fontSize="14px"
            lineHeight="14px"
            fontWeight="600"
          >
            {t("create_new_post")}
          </Button>
        </Flex>
      </Flex>
    </VStack>
  );
};
