import { Box } from "@chakra-ui/react";
import { Route, Routes } from "react-router-dom";
import { ConnectChannel } from "./connect-channel";
import { InfoInstagram } from "./info-instagram";
import { SelectInstagram } from "./select-instagram";
import { InfoTelegram } from "./info-telegram";

export const ChannelConnectRoutes = () => {
  return (
    <Box p="8" minH="100vh" w="100vw">
      <Routes>
        <Route path="/" element={<ConnectChannel />} />
        <Route path="/info/telegram" element={<InfoTelegram />} />
        <Route path="/info/instagram" element={<InfoInstagram />} />
        <Route path="/selection/instagram" element={<SelectInstagram />} />
      </Routes>
    </Box>
  );
};
