import React from "react";

export const SearchStyledIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.46387"
        y="1"
        width="29"
        height="29"
        rx="2.5"
        stroke="#57AAEC"
      />
      <path
        d="M18.6739 9.27248C16.2102 6.80875 12.2003 6.80875 9.73656 9.27248C7.27336 11.7367 7.27336 15.7461 9.73656 18.2104C11.9306 20.4039 15.3476 20.6389 17.8098 18.926C17.8616 19.1712 17.9801 19.4051 18.1708 19.5958L21.7588 23.1838C22.2816 23.7056 23.1266 23.7056 23.6468 23.1838C24.1691 22.6614 24.1691 21.8165 23.6468 21.2958L20.0588 17.7067C19.8692 17.5177 19.6347 17.3986 19.3896 17.3468C21.1035 14.8841 20.8685 11.4676 18.6739 9.27248ZM17.5411 17.0776C15.7017 18.917 12.7082 18.917 10.8693 17.0776C9.03103 15.2382 9.03103 12.2452 10.8693 10.4058C12.7082 8.56695 15.7017 8.56695 17.5411 10.4058C19.3805 12.2452 19.3805 15.2382 17.5411 17.0776Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
