import { useConfigStore } from "@/context/config-store/config-store";
import {
  Card,
  CardBody,
  Box,
  Flex,
  Text,
  IconButton,
  Progress,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
interface SummarCardProps {
  icon: React.ReactElement;
  text: string;
  firstData?: number;
  secondData?: number;
  onPress?: () => void;
}

export const SummaryCard: FC<SummarCardProps> = ({
  icon,
  text,
  firstData = 0,
  secondData = 0,
  onPress,
}) => {
  const language = useConfigStore((c) => c.language);

  const calculateNumberPlaceholder = useCallback(
    (number: number) => {
      if (!language) return undefined;
      if (number > 1000000) {
        return number / 1000000 + "M";
      } else if (number > 1000) {
        return number / 1000 + (language === "tr" ? "B" : "K");
      } else return number;
    },
    [language]
  );
  return (
    <Card
      onClick={onPress}
      overflow="hidden"
      w="full"
      bgColor="white.normal"
      h="78px"
      cursor="pointer"
      variant="unstyled"
      border="1px solid"
      borderColor="gray.athens"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
    >
      <CardBody alignItems="center" px="2">
        <Flex alignItems="center" h="full">
          <Flex w="full" alignItems="center" h="full">
            <IconButton
              h="57px"
              w="57px"
              right="5px"
              variant="unstyled"
              icon={icon}
              aria-label="icon"
            />

            <Text
              fontSize="14px"
              fontWeight="500"
              alignItems="center"
              color="black.active"
            >
              {text}
            </Text>
          </Flex>
          <Box>
            <Text
              color={
                firstData > secondData
                  ? "red.normal"
                  : firstData == secondData
                    ? "yellow.label"
                    : "blue.shiny"
              }
              fontWeight="500"
              fontSize="22px"
              whiteSpace="nowrap"
            >
              {`${calculateNumberPlaceholder(
                firstData
              )} / ${secondData === 0 ? "∞" : calculateNumberPlaceholder(secondData)}`}
            </Text>
          </Box>
        </Flex>
      </CardBody>

      {secondData !== 0 && (
        <Progress
          h="6px"
          value={(firstData * 100) / (Number(secondData) || 100)}
          sx={{
            "&>div": {
              bgColor:
                firstData > secondData
                  ? "red.normal"
                  : firstData == secondData
                    ? "yellow.label"
                    : "blue.shiny",
            },
          }}
        />
      )}
    </Card>
  );
};
