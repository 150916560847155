export const HamburgerMenuIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.3647 17.6472H8.70588C8.31602 17.6472 8 17.9632 8 18.353C8 18.7429 8.31602 19.0589 8.70588 19.0589H25.3647C25.7546 19.0589 26.0706 18.7429 26.0706 18.353C26.0706 17.9632 25.7546 17.6472 25.3647 17.6472Z"
        fill="#3B5998"
      />
      <path
        d="M25.3647 12H8.70588C8.31602 12 8 12.316 8 12.7059C8 13.0957 8.31602 13.4118 8.70588 13.4118H25.3647C25.7546 13.4118 26.0706 13.0957 26.0706 12.7059C26.0706 12.316 25.7546 12 25.3647 12Z"
        fill="#3B5998"
      />
      <path
        d="M25.3647 23.2942H8.70588C8.31602 23.2942 8 23.6102 8 24.0001C8 24.3899 8.31602 24.706 8.70588 24.706H25.3647C25.7546 24.706 26.0706 24.3899 26.0706 24.0001C26.0706 23.6102 25.7546 23.2942 25.3647 23.2942Z"
        fill="#3B5998"
      />
      <rect x="0.5" y="0.5" width="34" height="35" rx="2.5" stroke="#3B5998" />
    </svg>
  );
};
