import { APISchemas } from "@/api";
import { TrackType } from "mediainfo.js";
import { z } from "@/i18n";
export type StandartFile = z.infer<typeof StandartFile>;
export const StandartFile = z.object({
  data: z.object({
    image: z.custom<APISchemas["ImageUploadResponse"]>().optional(),
    video: z.custom<APISchemas["VideoUploadResponse"]>().optional(),
  }),
  api: z.custom<File>(),
  analyzed: z.custom<TrackType>().array().optional(),
});
