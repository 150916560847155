import React from "react";
import {
  Box,
  Flex,
  Text,
  Divider,
  Grid,
  GridItem,
  useBreakpointValue,
  FlexProps,
} from "@chakra-ui/react";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { BadgeIcon } from "@/assets/icons/badge-icon";
import { useTranslation } from "react-i18next";

//import { MemberIcon } from "@/assets/icons/member-icon";

interface OrganizationSummaryProps {
  data: Array<{
    text?: string;
    num1: number | undefined;
    num2: number | string | undefined;
  }>;
  plan?: string;
}

export const OrganizationSummary: React.FC<OrganizationSummaryProps> = ({
  data,
  plan,
}) => {
  const { t } = useTranslation();
  const flexDir =
    useBreakpointValue<FlexProps["flexDir"]>({
      base: "column",
      lg: "column",
      xl: "row",
    }) ?? "column";

  const planName = plan ? t(`membership-packages.${plan?.toLowerCase()}`) : "";

  return (
    <OverlayScrollbarsComponent
      options={{
        scrollbars: {
          autoHide: "leave",
          autoHideDelay: 300,
        },
      }}
      style={{
        width: "100%",
        backgroundColor: "white",
        borderRadius: "3px",
        boxShadow: "0px 0px 5px 2px rgba(52, 90, 121, 0.03)",
      }}
    >
      <Box
        w="full"
        h="full"
        p="4"
        borderRadius="3px"
        border="1px solid"
        borderColor="gray.athens"
      >
        <Flex gap="2" w="full" alignItems="center" mb="10px">
          <BadgeIcon />

          <Text
            fontWeight="600"
            textAlign="center"
            color="black.medium"
            fontSize="16px"
            textTransform="capitalize"
          >
            {planName}
          </Text>
        </Flex>
        <Divider borderColor="gray.light" />
        <Grid templateRows="repeat(3,1fr)" w="full">
          {data.map((row, index) => (
            <GridItem
              key={
                (row?.num1 ?? 0).toString() +
                (row?.num2 ?? 0).toString() +
                row.text
              }
            >
              <Flex
                flexDir={flexDir}
                alignItems="center"
                justifyContent="space-between"
                mt="15px"
                w="full"
              >
                <Box overflow="hidden">
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color="black.active"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                  >
                    {row.text}
                  </Text>
                </Box>

                <Flex>
                  <Text
                    fontSize="14px"
                    fontWeight="500"
                    color={row.num1 === row.num2 ? "red.label" : "blue.shiny"}
                  >
                    {row.num1}
                  </Text>
                  <Text
                    ml="2px"
                    fontSize="14px"
                    fontWeight="500"
                    color="gray.border"
                  >
                    /
                  </Text>

                  <Text
                    ml="2px"
                    fontSize="14px"
                    fontWeight="500"
                    color="gray.light"
                  >
                    {row.num2}
                  </Text>
                </Flex>
              </Flex>
              {index !== data.length - 1 && (
                <Divider
                  borderStyle="dashed"
                  mt="15px"
                  borderColor="gray.light"
                />
              )}
            </GridItem>
          ))}
        </Grid>
      </Box>
    </OverlayScrollbarsComponent>
  );
};
