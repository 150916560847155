export const PostEffectBlackIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
        fill="#33373D"
      />
      <path
        d="M38.3434 68.9993V71.0665C38.3434 72.4127 38.3305 73.7615 38.353 75.1085C38.3599 75.5343 38.2156 75.7277 37.7968 75.7795C34.1322 76.2285 30.7991 75.485 27.9228 73.0612C26.2312 71.6364 24.9549 69.9103 24.2322 67.8206C22.9171 64.0212 23.3557 60.4187 25.5412 57.0286C26.7614 55.135 28.4106 53.7404 30.4096 52.7379C32.0814 51.8995 33.8645 51.5195 35.7262 51.5161C42.1748 51.5031 48.6268 51.5066 55.0746 51.4962C56.5995 51.4962 58.127 51.529 59.6451 51.4306C62.4618 51.2475 64.801 50.0257 66.705 47.9559C68.2688 46.2557 69.1651 44.2256 69.4207 41.9589C69.7022 39.4565 69.2178 37.062 67.8042 34.943C65.9425 32.1521 63.3711 30.4364 59.9948 30.0297C57.2109 29.6955 54.6454 30.3146 52.3917 31.9622C50.0361 33.6814 48.5146 35.9904 48.0699 38.9125C47.9196 39.9012 47.9067 40.9166 47.8929 41.9192C47.867 43.8905 47.886 45.8662 47.886 47.841C47.886 48.3324 47.8333 48.385 47.3592 48.385C45.5502 48.385 43.7403 48.3781 41.9313 48.3945C41.598 48.3945 41.5194 48.2866 41.522 47.9723C41.5349 45.0312 41.5289 42.0927 41.5349 39.1517C41.5384 37.5464 42.0384 36.0465 42.585 34.5665C43.6289 31.7299 45.3663 29.3492 47.6891 27.4521C49.8349 25.7001 52.2665 24.4523 55.0245 23.885C57.3084 23.417 59.5915 23.3514 61.872 23.7736C64.4047 24.2416 66.7335 25.2407 68.8111 26.7838C71.3532 28.6705 73.2995 31.0451 74.5499 33.9629C75.6457 36.5206 76.048 39.1966 75.8555 41.9779C75.6785 44.5494 74.9885 46.9629 73.7028 49.1803C71.2651 53.3795 67.6859 56.1272 62.9877 57.3879C61.5482 57.7738 60.082 57.9483 58.5907 57.9448C55.1359 57.9344 51.6776 57.9284 48.2227 57.9319C46.2401 57.9319 44.2575 57.9647 42.2715 57.9681C40.0825 57.9716 37.891 58.0268 35.7055 57.9422C33.2454 57.8438 30.8146 59.7141 30.0979 62.1146C29.1489 65.2914 31.2101 69.0062 35.0319 69.5165C35.8367 69.6245 36.5992 69.526 37.3617 69.2869C37.6561 69.1953 37.9575 69.1167 38.353 69.0019L38.3434 68.9984V68.9993Z"
        fill="#F6F7F9"
      />
      <path
        d="M47.9074 68.4816C47.9074 68.4816 47.9048 68.4816 47.9048 68.4825V75.3905C47.9048 75.8991 47.8686 75.906 47.366 75.9025C45.595 75.8896 43.8248 75.8922 42.0538 75.9025C41.4295 75.9025 41.4493 75.9094 41.4856 75.3015C41.5218 74.6781 41.496 74.0477 41.496 73.42C41.496 69.4539 41.5055 65.4836 41.496 61.5176C41.496 61.1298 41.5918 61.0089 41.9976 61.015C43.8075 61.0349 45.6217 61.0383 47.4351 61.0081C47.8383 61.0012 47.9238 61.1298 47.9204 61.4873C47.9109 63.817 47.9135 66.1467 47.9135 68.4765C47.9135 68.4791 47.9117 68.4808 47.9091 68.4808H47.9083H47.9074V68.4816Z"
        fill="#F6F7F9"
      />
    </svg>
  );
};
