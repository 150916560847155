import {
  Box,
  Text,
  Flex,
  Icon,
  Button,
  useToast,
  Divider,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GET } from "@/api";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useQuery } from "react-query";
import { LogoBlue } from "@/assets/logo/logo-blue";
import { useCallback, useMemo, useState } from "react";
import { brandTitle } from "@/constant";
import { TelegramClickableListItem } from "./telegram-clickable-list-item";

export const InfoTelegram = () => {
  const navigate = useNavigate();
  const activeOrganization = useAuthStore((s) => s.activeOrganization);
  const toast = useToast();
  const [detailOpen, setDetailOpen] = useState<number | undefined>();
  const { t } = useTranslation();

  useQuery(
    ["channels", activeOrganization],

    async () => {
      if (!activeOrganization?.id) {
        return;
      }
      const { data, error } = await GET("/org/{org_pk}/channels/", {
        params: {
          path: {
            org_pk: activeOrganization?.id.toString(),
          },
        },
      });
      if (error) console.error("Get channels error", error);
      return data;
    }
  );

  const telegramStepsFirstRow = useMemo(
    () => [
      {
        title: t("page.connect-channels.telegram-info.steps.step1.title"),
        description: `${t(
          "page.connect-channels.telegram-info.steps.step1.description"
        )}\n\n${t("page.connect-channels.telegram-info.steps.step2.description")}
        `,
      },
      // {
      //   title: t("page.connect-channels.telegram-info.steps.step2.title"),
      //   description: t(
      //     "page.connect-channels.telegram-info.steps.step2.description"
      //   ),
      // },
      {
        title: t("page.connect-channels.telegram-info.steps.step3.title"),
        description: `${t(
          "page.connect-channels.telegram-info.steps.step3.description"
        )}\n\n${t(
          "page.connect-channels.telegram-info.steps.step4.description"
        )}`,
      },
      // {
      //   title: t("page.connect-channels.telegram-info.steps.step4.title"),
      //   description: t(
      //     "page.connect-channels.telegram-info.steps.step4.description"
      //   ),
      // },
      {
        title: t("page.connect-channels.telegram-info.steps.step5.title"),
        description: `${t(
          "page.connect-channels.telegram-info.steps.step5.description"
        )}`,
      },
    ],
    [t]
  );

  const telegramStepsSecondRow = useMemo(
    () => [
      {
        title: t("page.connect-channels.telegram-info.steps.step6.title"),
        description: `${t(
          "page.connect-channels.telegram-info.steps.step6.description"
        )}\n\n${t(
          "page.connect-channels.telegram-info.steps.step7.description"
        )}`,
      },
      {
        title: t("page.connect-channels.telegram-info.steps.step7.title"),
        description: t(
          "page.connect-channels.telegram-info.steps.step7.description"
        ),
      },
    ],
    [t]
  );

  const handleConnect = useCallback(() => {
    if (!activeOrganization?.id) {
      return;
    }
    GET("/telegram/auth/{org_pk}/get-url/", {
      params: {
        path: {
          org_pk: activeOrganization.id.toString(),
        },
      },
    }).then(({ data, error }) => {
      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
        return;
      }

      window.open(data?.url, "_blank", "width=800,height=600,top=100,left=300");
      //navigate("/channels");
    });
  }, [activeOrganization, toast]);

  return (
    <Flex
      alignSelf="stretch"
      h="full"
      bg="white"
      borderRadius="6px"
      flexDirection="column"
      p="4"
    >
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          <Icon w="10" h="10" mr="3">
            <LogoBlue />
          </Icon>
          <Text
            fontSize="18px"
            lineHeight="22.5px"
            fontWeight="700"
            color="blue.shiny"
          >
            {brandTitle}
          </Text>
        </Flex>

        <Flex>
          <Button onClick={() => navigate("/")} variant="unstyled">
            <BlackCrossIcon width="30px" height="30px" />
          </Button>
        </Flex>
      </Flex>

      <Box mt="8" mx="12">
        <Text
          fontWeight="500"
          fontSize="30px"
          lineHeight="45px"
          color="black.active"
        >
          {t("page.connect-channels.telegram-info.title")}
        </Text>
        <Text
          mt="2"
          fontSize="20px"
          color="black.active"
          fontWeight="400"
          lineHeight="30px"
        >
          {t("page.connect-channels.telegram-info.subtitle")}
        </Text>
      </Box>

      <Box bgColor="white.bg" minH="500" mx="12" mt="8" borderRadius="12px">
        <Flex p="12" h="full">
          <Box w="full" height="full" p="4" mr="16">
            {telegramStepsFirstRow.map((step, index) => (
              <TelegramClickableListItem
                title={step.title}
                description={step.description}
                open={index === detailOpen ? true : false}
                onClick={() => {
                  index === detailOpen
                    ? setDetailOpen(undefined)
                    : setDetailOpen(index);
                }}
              />
            ))}
            <Divider bgColor="white.line" width="full" height="2px" />
          </Box>
          <Box w="full" height="full" p="4">
            {telegramStepsSecondRow.map((step, index) => (
              <TelegramClickableListItem
                title={step.title}
                description={step.description}
                open={index + 4 === detailOpen ? true : false}
                onClick={() => {
                  index + 4 === detailOpen
                    ? setDetailOpen(undefined)
                    : setDetailOpen(index + 4);
                }}
              />
            ))}
            <Divider bgColor="white.line" width="full" height="2px" />
          </Box>
        </Flex>
      </Box>

      <Flex justifyContent="flex-end" alignItems="flex-end" mx="12" mt="8">
        <Button
          bgColor="blue.shiny"
          borderRadius="3px"
          h="50px"
          w="220px"
          color="white"
          fontSize="20px"
          fontWeight="600"
          lineHeight="16px"
          cursor="pointer"
          _hover={{ opacity: 0.8 }}
          onClick={handleConnect}
        >
          {t("connect_telegram")}
        </Button>
      </Flex>
    </Flex>
  );
};
