import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import zodEnTranslation from "zod-i18n-map/locales/en/zod.json";
import zodTrTranslation from "zod-i18n-map/locales/tr/zod.json";
import enTranslation from "./locales/en/en.json";
import trTranslation from "./locales/tr/tr.json";
import { zodI18nMap } from "zod-i18n-map";
import { z } from "zod";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    resources: {
      en: { translation: enTranslation, zod: zodEnTranslation },
      tr: { translation: trTranslation, zod: zodTrTranslation },
    },

    interpolation: {
      escapeValue: false,
    },
  });
z.setErrorMap(zodI18nMap);

export { i18n, z };
