export const ComposeCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.8416 24.5C19.469 24.5 24.8416 19.1274 24.8416 12.5C24.8416 5.87258 19.469 0.5 12.8416 0.5C6.21414 0.5 0.841553 5.87258 0.841553 12.5C0.841553 19.1274 6.21414 24.5 12.8416 24.5Z"
        fill="#57AAEC"
      />
      <path
        d="M9.3247 17.6771V18.2313C9.3247 18.5923 9.32117 18.9541 9.32746 19.3155C9.32903 19.4298 9.29052 19.4816 9.17818 19.4954C8.1954 19.6156 7.301 19.4164 6.52994 18.7663C6.07626 18.3841 5.73412 17.921 5.54008 17.3609C5.18734 16.342 5.30481 15.3753 5.89126 14.4664C6.21846 13.9585 6.66074 13.5841 7.19691 13.3155C7.64549 13.0904 8.12351 12.9887 8.62276 12.9879C10.3523 12.9843 12.0829 12.9851 13.8124 12.9828C14.2213 12.9828 14.6314 12.9914 15.0383 12.9651C15.7937 12.916 16.4214 12.5884 16.932 12.033C17.3515 11.5769 17.5919 11.0325 17.6603 10.4245C17.7357 9.75318 17.6057 9.11096 17.2266 8.54258C16.7274 7.7939 16.0376 7.33393 15.1318 7.22474C14.3851 7.13518 13.6969 7.30133 13.0924 7.74323C12.4604 8.20438 12.0527 8.82382 11.9333 9.60745C11.8928 9.87259 11.8893 10.1452 11.8858 10.4139C11.8787 10.9426 11.8842 11.4725 11.8842 12.0023C11.8842 12.1339 11.87 12.1481 11.7428 12.1481C11.2577 12.1481 10.7722 12.1465 10.2871 12.1508C10.1975 12.1508 10.1767 12.1218 10.1775 12.0373C10.181 11.2486 10.179 10.4602 10.181 9.67148C10.1818 9.24097 10.3161 8.83875 10.4626 8.44163C10.7427 7.68078 11.2086 7.04209 11.8316 6.53341C12.4074 6.06323 13.059 5.72857 13.7991 5.57655C14.4114 5.45086 15.0242 5.43318 15.6358 5.5467C16.3149 5.6724 16.9399 5.94028 17.4969 6.3539C18.1788 6.85983 18.7008 7.49695 19.0359 8.2794C19.3301 8.96562 19.4377 9.68326 19.3862 10.4292C19.3387 11.1189 19.1537 11.7663 18.8088 12.361C18.1552 13.4871 17.1952 14.2244 15.9347 14.5626C15.5486 14.6663 15.1554 14.7131 14.7555 14.7119C13.8289 14.7091 12.9011 14.7076 11.9745 14.7083C11.4427 14.7083 10.9108 14.717 10.3782 14.7182C9.79096 14.7189 9.20332 14.7339 8.61687 14.7111C7.95697 14.6848 7.30492 15.1864 7.11284 15.8302C6.85831 16.6822 7.41137 17.6787 8.43618 17.8158C8.65222 17.8448 8.85648 17.8185 9.06113 17.7541C9.14008 17.7294 9.221 17.7085 9.32706 17.6775L9.32431 17.6767L9.3247 17.6771Z"
        fill="white"
      />
      <path
        d="M11.8903 17.538H11.8895V19.3908C11.8895 19.5271 11.8797 19.5291 11.7449 19.5283C11.27 19.5248 10.7951 19.5256 10.3203 19.5283C10.1529 19.5283 10.158 19.5299 10.1678 19.3673C10.1776 19.1999 10.1706 19.031 10.1706 18.8625C10.1706 17.7988 10.1733 16.734 10.1706 15.6703C10.1706 15.5666 10.1961 15.534 10.3053 15.5355C10.7908 15.5406 11.2775 15.5418 11.7638 15.534C11.8718 15.5324 11.895 15.5666 11.8942 15.6624C11.8914 16.2873 11.8922 16.9123 11.8922 17.5368C11.8922 17.5376 11.8918 17.538 11.891 17.538H11.8903Z"
        fill="white"
      />
    </svg>
  );
};
