import {
  FacebookIcon,
  InstagramColorfulIcon,
  InstagramIcon,
  LinkedinIcon,
  TelegramCircleIcon,
  TelegramIcon,
  TiktokCircleIcon,
  TwitterIcon,
  YoutubeCircleIcon,
} from "@/assets/brands";
import { InstagramCircleIcon } from "@/assets/brands/circle/instagram-circle-icon";

export const platformIcons = (channelName: string) => {
  if (channelName.toLocaleLowerCase() === "instagram") {
    return <InstagramCircleIcon width="24px" />;
  }
  if (channelName.toLocaleLowerCase() === "twitter") {
    return <TwitterIcon width="24px" />;
  }
  if (channelName.toLocaleLowerCase() === "linkedin") {
    return <LinkedinIcon width="24px" />;
  }

  if (channelName.toLocaleLowerCase() === "facebook") {
    return <FacebookIcon width="24px" />;
  }

  if (channelName.toLocaleLowerCase() === "telegram") {
    return <TelegramIcon inverse={false} width="24px" />;
  }
  if (channelName.toLocaleLowerCase() === "youtube") {
    return <YoutubeCircleIcon width="24px" />;
  }

  if (channelName.toLocaleLowerCase() === "tiktok") {
    return <TiktokCircleIcon width="24px" />;
  }
};
