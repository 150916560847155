export const TrashSlimIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.9215 2.50951H12.9753V1.92028C12.9753 0.945576 12.1823 0.152588 11.2076 0.152588H6.49376C5.51905 0.152588 4.72606 0.945576 4.72606 1.92028V2.50951H1.7799C0.805195 2.50951 0.012207 3.3025 0.012207 4.27721C0.012207 5.06002 0.523857 5.72519 1.23015 5.95695L2.28114 18.6441C2.35684 19.553 3.13062 20.265 4.04271 20.265H13.6587C14.5708 20.265 15.3446 19.553 15.4203 18.6438L16.4712 5.95691C17.1775 5.72519 17.6892 5.06002 17.6892 4.27721C17.6892 3.3025 16.8962 2.50951 15.9215 2.50951ZM5.90452 1.92028C5.90452 1.59538 6.16885 1.33105 6.49376 1.33105H11.2076C11.5325 1.33105 11.7968 1.59538 11.7968 1.92028V2.50951H5.90452V1.92028ZM14.2458 18.5463C14.2206 18.8492 13.9627 19.0866 13.6587 19.0866H4.04271C3.7387 19.0866 3.48078 18.8492 3.4556 18.5466L2.41996 6.0449H15.2814L14.2458 18.5463ZM15.9215 4.86644H1.7799C1.455 4.86644 1.19067 4.60211 1.19067 4.27721C1.19067 3.95231 1.455 3.68798 1.7799 3.68798H15.9215C16.2464 3.68798 16.5107 3.95231 16.5107 4.27721C16.5107 4.60211 16.2464 4.86644 15.9215 4.86644Z"
        fill="#57AAEC"
      />
      <path
        d="M6.49268 17.2824L5.90344 7.77613C5.88329 7.4513 5.60215 7.20426 5.2789 7.22449C4.95407 7.24464 4.70711 7.52425 4.72722 7.84903L5.31645 17.3553C5.33582 17.6678 5.59524 17.9081 5.90399 17.9081C6.24524 17.9081 6.51361 17.6207 6.49268 17.2824Z"
        fill="#57AAEC"
      />
      <path
        d="M8.85095 7.22339C8.52554 7.22339 8.26172 7.48721 8.26172 7.81262V17.3189C8.26172 17.6443 8.52554 17.9081 8.85095 17.9081C9.17636 17.9081 9.44018 17.6443 9.44018 17.3189V7.81262C9.44018 7.48721 9.17636 7.22339 8.85095 7.22339Z"
        fill="#57AAEC"
      />
      <path
        d="M12.4224 7.22448C12.0984 7.20433 11.818 7.45129 11.7979 7.77612L11.2087 17.2824C11.1886 17.6072 11.4356 17.8868 11.7603 17.9069C12.0853 17.927 12.3648 17.68 12.3849 17.3553L12.9741 7.84902C12.9942 7.5242 12.7473 7.24459 12.4224 7.22448Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
