import { FC } from "react";

export const BunnyPlayer: FC<{ src: string; absolute?: boolean }> = ({
  src,
  absolute = true,
}) => {
  return (
    <div
      style={{
        position: "relative",
        //paddingTop: "56.25%",
        width: "100%",
        height: "100%",
      }}
    >
      <iframe
        src={src?.replace("play", "embed")}
        loading="lazy"
        style={{
          border: "0",
          position: absolute ? "absolute" : "relative",
          top: "0",
          height: "100%",
          width: "100%",
        }}
        allow="accelerometer;gyroscope;encrypted-media;picture-in-picture;"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};
