import React, { SVGAttributes } from "react";

interface CustomSVGProps extends SVGAttributes<SVGElement> {
  active?: boolean;
}

export const DoubleChevronLeftIcon: React.FC<CustomSVGProps> = ({
  active,
  ...rest
}) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />

      <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="#EBEDF2" />
      <path
        d="M19.7267 20L20.6667 19.06L17.6134 16L20.6667 12.94L19.7267 12L15.7267 16L19.7267 20Z"
        fill={active ? "#57AAEB" : "#A1A5B7"}
      />
      <path
        d="M15.3334 20L16.2734 19.06L13.2201 16L16.2734 12.94L15.3334 12L11.3334 16L15.3334 20Z"
        fill={active ? "#57AAEB" : "#A1A5B7"}
      />
    </svg>
  );
};
