import { Box, Divider, Flex, FormControl, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { i18n, z } from "@/i18n";
import { useFormStore } from "../form-store";
import { ContentEditor } from "../content-editor";
import { StandartFile } from "../common";
import { Dropzone } from "@/components/Dropzone";
import { PATCH, POST } from "@/api";
import { StatusCard } from "../forms-presents/_status-card";
import { handleError } from "./_handle-errors";
import { mainFormUpdateMS } from "../contant";
import isEqual from "lodash.isequal";
import { useAuthStore } from "@/context/auth-store/auth-store";

export type mainSchema = z.infer<typeof mainSchema>;
const mainSchema = z.object({
  id: z.number().optional(),
  content: z.string().optional(),
  files: StandartFile.array().optional(),
});

export const MainForm: FC<{ defaultValue?: mainSchema }> = ({
  defaultValue,
}) => {
  const formTimer = useRef<NodeJS.Timeout>();
  const defaultValueRef = useRef<typeof defaultValue>();
  const toast = useToast();

  const {
    setMainForm,
    twitter_post,
    instagram_post,
    linkedin_post,
    facebook_post,
    telegram_post,
    tiktok_post,
    youtube_post,
    scheduled_at,
    subscribe,
    unsubscribe,
    clearSubscribes,
    goToStep,
    blockProgress,
    setGlobalError,
  } = useFormStore((s) => ({
    setMainForm: s.setMainForm,
    twitter_post: s.twitter_post,
    facebook_post: s.facebook_post,
    instagram_post: s.instagram_post,
    linkedin_post: s.linkedin_post,
    telegram_post: s.telegram_post,
    tiktok_post: s.tiktok_post,
    youtube_post: s.youtube_post,
    scheduled_at: s.scheduledAt,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    clearSubscribes: s.clearSubscribes,
    goToStep: s.goToStep,
    blockProgress: s.setProgressBlock,
    setGlobalError: s.setGlobalError,
  }));

  const {
    register,
    control,
    getValues,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm<mainSchema>({
    resolver: zodResolver(mainSchema),
  });

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset(defaultValue);

    defaultValueRef.current = defaultValue;
  }

  const content = watch("content");
  const files = watch("files");

  const { organization } = useAuthStore((state) => ({
    organization: state.activeOrganization,
    setActiveOrg: state.setActiveOrganization,
  }));

  const createCompose = useCallback(
    async (
      externalStatus?: "cancelled" | "planned" | "posted" | "deleted" | "draft"
    ) => {
      if (!organization) {
        return;
      }
      const medias = files
        ?.map((f) => f.data.image?.id || f.data.video?.id)
        .filter((m): m is string => !!m);
      const body = {
        body: content,
        media: medias?.map((m) => Number(m)),
        status:
          externalStatus ||
          ("planned" as
            | "cancelled"
            | "planned"
            | "posted"
            | "deleted"
            | "draft"),
        facebook_posts: facebook_post.map((p) => p.id),
        instagram_posts: instagram_post.map((p) => p.id),
        twitter_posts: twitter_post.map((p) => p.id),
        youtube_posts: youtube_post.map((p) => p.id),
        linkedin_posts: linkedin_post.map((p) => p.id),
        telegram_posts: telegram_post.map((p) => p.id),
        tiktok_posts: tiktok_post.map((p) => p.id),
        scheduled_at: scheduled_at.toISOString(),
      };

      const { data, error, response } = defaultValueRef.current?.id
        ? await PATCH("/common/{org_pk}/compose/{id}/", {
            params: {
              path: {
                org_pk: organization?.id.toString(),
                id: defaultValueRef.current.id,
              },
            },
            body,
          })
        : await POST("/common/{org_pk}/compose/", {
            params: {
              path: {
                org_pk: organization?.id.toString(),
              },
            },
            body,
          });
      if (error) {
        if (response.status === 402) {
          toast({
            status: "error",
            description:
              error.detail ||
              i18n.t("errors.unexpected-error-contact-customer-service"),
          });
          handleError("main", error, setGlobalError);
          goToStep?.(1);

          return;
        }
        blockProgress(true);
        toast({
          status: "error",
          description:
            error.detail ||
            i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        goToStep?.(1);
        return;
      }

      if (!data) {
        toast({
          status: "error",
          title: i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }
      if (externalStatus === "draft") {
        toast({
          status: "success",
          title: i18n.t("page.post-page.draft-created"),
          isClosable: true,
        });
        return;
      }
      toast({
        status: "success",
        title: i18n.t("page.post-page.compose-created"),
        isClosable: true,
      });
    },
    [
      blockProgress,
      content,
      facebook_post,
      files,
      goToStep,
      instagram_post,
      linkedin_post,
      organization,
      scheduled_at,
      setGlobalError,
      telegram_post,
      tiktok_post,
      toast,
      twitter_post,
      youtube_post,
    ]
  );

  const setMainFormValues = useCallback(() => {
    const main = useFormStore.getState().mainForms.main;

    if (!main) {
      return;
    }

    setValue("content", main.content);
    setValue("files", main.files);
  }, [setValue]);

  useEffect(() => {
    clearSubscribes("setMain");
    subscribe("setMain", setMainFormValues);
    return () => {
      unsubscribe("setMain", setMainFormValues);
    };
  }, [clearSubscribes, setMainFormValues, subscribe, unsubscribe]);

  useEffect(() => {
    if (formTimer.current) {
      clearTimeout(formTimer.current);
    }

    formTimer.current = setTimeout(() => {
      setMainForm({
        main: {
          content,
          files,
        },
      });
    }, mainFormUpdateMS);
  }, [getValues, setMainForm, content, files]);

  useEffect(() => {
    clearSubscribes("final");
    const composeCreate = () => createCompose("planned");
    subscribe("final", composeCreate);
    const draftCreate = () => createCompose("draft");
    subscribe("finalDraft", draftCreate);

    return () => {
      unsubscribe("final", composeCreate);
      unsubscribe("finalDraft", draftCreate);
    };
  }, [clearSubscribes, createCompose, subscribe, unsubscribe]);

  return (
    <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
      <form>
        <Flex>
          <Controller
            control={control}
            name="files"
            render={({ field }) => (
              <Dropzone
                value={field.value ?? []}
                onChange={field.onChange}
                onBusy={blockProgress}
              />
            )}
          />
        </Flex>

        <Divider borderColor="#B7BABF" />
        <FormControl mb="3">
          <ContentEditor {...register("content")} />
        </FormControl>

        {errors.root?.message && (
          <StatusCard description={errors.root.message} />
        )}
        {errors.content?.message && (
          <StatusCard description={errors.content.message} />
        )}
        {errors.files?.message && (
          <StatusCard description={errors.files.message} />
        )}
      </form>
    </Box>
  );
};
