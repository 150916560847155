import React from "react";

export const NewPostIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.4998 6.70007H9.29983V1.50007C9.29983 1.15529 9.16286 0.824632 8.91907 0.580835C8.67527 0.337037 8.34461 0.200073 7.99983 0.200073C7.65505 0.200073 7.32439 0.337037 7.08059 0.580835C6.83679 0.824632 6.69983 1.15529 6.69983 1.50007V6.70007H1.49983C1.15505 6.70007 0.824388 6.83704 0.58059 7.08083C0.336793 7.32463 0.199829 7.65529 0.199829 8.00007C0.199829 8.34485 0.336793 8.67551 0.58059 8.91931C0.824388 9.16311 1.15505 9.30007 1.49983 9.30007H6.69983V14.5001C6.69983 14.8449 6.83679 15.1755 7.08059 15.4193C7.32439 15.6631 7.65505 15.8001 7.99983 15.8001C8.34461 15.8001 8.67527 15.6631 8.91907 15.4193C9.16286 15.1755 9.29983 14.8449 9.29983 14.5001V9.30007H14.4998C14.8446 9.30007 15.1753 9.16311 15.4191 8.91931C15.6629 8.67551 15.7998 8.34485 15.7998 8.00007C15.7998 7.65529 15.6629 7.32463 15.4191 7.08083C15.1753 6.83704 14.8446 6.70007 14.4998 6.70007Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
