export const NotificationsOutlineIcon = (
  props: React.SVGAttributes<SVGElement>
) => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.560187 18.8691L0.674194 18.8179L0.560187 18.8691C0.68271 19.1417 0.962088 19.314 1.26635 19.314H7.02549C7.37644 20.7171 8.70522 21.7569 10.2787 21.7569C11.8522 21.7569 13.1809 20.7171 13.5319 19.314H19.291C19.5953 19.314 19.8746 19.1416 19.9971 18.8691C20.1202 18.5954 20.0583 18.2792 19.8436 18.0667L19.7557 18.1556L19.8436 18.0667C18.0449 16.2871 17.0556 13.951 17.0556 11.4883V9.53955C17.0556 6.66724 15.0657 4.23451 12.3349 3.40691V2.618C12.3349 1.53259 11.4063 0.660828 10.2787 0.660828C9.15105 0.660828 8.22247 1.53259 8.22247 2.618V3.40691C5.49166 4.23451 3.50172 6.6672 3.50172 9.53955V11.4883C3.50172 13.951 2.51249 16.2871 0.713782 18.0667C0.49905 18.2792 0.437098 18.5954 0.560187 18.8691ZM9.75994 2.618C9.75994 2.3564 9.98647 2.13228 10.2787 2.13228C10.5709 2.13228 10.7974 2.3564 10.7974 2.618V3.12269C10.6262 3.11024 10.4533 3.10373 10.2787 3.10373C10.1041 3.10373 9.93111 3.11024 9.75994 3.12269V2.618ZM10.2787 20.2855C9.559 20.2855 8.93983 19.886 8.64942 19.314H11.9079C11.6175 19.886 10.9984 20.2855 10.2787 20.2855ZM2.90738 17.8426C4.29115 15.9966 5.0392 13.7907 5.0392 11.4883V9.53955C5.0392 6.80842 7.38346 4.57518 10.2787 4.57518C13.1739 4.57518 15.5182 6.80842 15.5182 9.53955V11.4883C15.5182 13.7907 16.2662 15.9966 17.65 17.8426H2.90738Z"
        fill="#57AAEC"
        stroke="#57AAEC"
        strokeWidth="0.25"
      />
    </svg>
  );
};
