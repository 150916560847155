import { Textarea, TextareaProps } from "@chakra-ui/react";
import { forwardRef, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface ContentEditorProps extends TextareaProps {
  type?: string;
}

export const ContentEditor = forwardRef<
  HTMLTextAreaElement,
  ContentEditorProps
>(({ type, ...props }, ref) => {
  const [, setTextAreaRef] = useState<HTMLTextAreaElement | null>(null);
  const { t } = useTranslation();
  const setRef = useCallback(
    (node: HTMLTextAreaElement | null) => {
      setTextAreaRef(node);

      if (typeof ref === "function") {
        ref(node);
        return;
      }

      if (ref) {
        ref.current = node;
      }
    },
    [ref]
  );

  return (
    <Textarea
      ref={setRef}
      resize="none"
      border="none"
      placeholder={t("enter_your_content_text_here")}
      _focusVisible={{}}
      fontSize="sm"
      pl="0"
      pb="5"
      lineHeight="19px"
      _placeholder={{
        color: "#B7BABF",
      }}
      color="#353B48"
      rows={type == "sm" ? 7 : 10}
      {...props}
    />
  );
});
