import {
  Box,
  Flex,
  Text,
  Card,
  CardBody,
  Stack,
  Avatar,
  Tooltip,
  Button,
} from "@chakra-ui/react";
import React, { useCallback, useMemo } from "react";
import { AvatarWithBadge } from "../avatar/avatar-with-badge";
import { APISchemas } from "@/api";
import { parseISO } from "date-fns";
import { SmartImage } from "../smart-image";
import { i18nformat } from "@/utils/misc";
import { DraftIcon } from "@/assets/icons/draft-icon";
import { useTranslation } from "react-i18next";
import { useChannel } from "@/hooks/use-channels";

interface Props {
  compose: APISchemas["Compose"];
}

export const PostItemHorizontal: React.FC<Props> = ({ compose }) => {
  const formatDatetime = useCallback((time: string): string => {
    const parsedDate = parseISO(time);
    const formattedDate = i18nformat(parsedDate, "MMM d, yyyy h:mm a");
    return formattedDate;
  }, []);
  const { t } = useTranslation();

  const { data: Channels } = useChannel();
  const mediaType = compose?.media.at(0)?.type;
  const imageUrl = compose?.media[0]?.url;
  const plannedDate =
    compose?.scheduled_at && formatDatetime(compose.scheduled_at);
  const description = compose.body;

  const [postList, draft] = useMemo(() => {
    const facebook = compose.facebook_posts?.map((item) => item.channel);
    const linkedin = compose.linkedin_posts?.map((item) => item.channel);
    const twitter = compose.twitter_posts?.map((item) => item.channel);
    const instagram = compose.instagram_posts?.map((item) => item.channel);
    const telegram = compose.telegram_posts?.map((item) => item.channel);
    const youtube = compose.youtube_posts?.map((item) => item.channel);
    const tiktok = compose.tiktok_posts?.map((item) => item.channel);

    const draft =
      compose.facebook_posts.every((p) => p.status === "draft") &&
      compose.instagram_posts.every((p) => p.status === "draft") &&
      compose.linkedin_posts.every((p) => p.status === "draft") &&
      compose.tiktok_posts.every((p) => p.status === "draft") &&
      compose.youtube_posts.every((p) => p.status === "draft") &&
      compose.telegram_posts.every((p) => p.status === "draft") &&
      compose.twitter_posts.every((p) => p.status === "draft");

    return [
      facebook
        .concat(linkedin)
        .concat(twitter)
        .concat(instagram)
        .concat(telegram)
        .concat(tiktok)
        .concat(youtube),
      draft,
    ];
  }, [compose]);

  const avatars = Channels?.filter((channel) => postList.includes(channel.id));
  const maxAvatars = 9;
  const avatarsToDisplay = avatars?.slice(0, maxAvatars);
  const hiddenCount = Math.max((avatars?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = avatars?.slice(maxAvatars, avatars.length);
  return (
    <Card
      overflow="hidden"
      width="full"
      direction="row"
      mb="2"
      h="230px"
      boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
      py="0px"
      border={draft ? "1px solid #FBC531" : undefined}
    >
      {mediaType == "image" && imageUrl && (
        <Flex pos="relative" w="411px" h="95%" overflow="hidden">
          {draft && (
            <Flex pos="absolute" top="0" left="345px" zIndex="99">
              <DraftIcon />
            </Flex>
          )}
          <SmartImage imageUrl={imageUrl} h="full" />
        </Flex>
      )}
      {mediaType == "video" && imageUrl && (
        <Flex pos="relative" w="411px" h="95%" overflow="hidden">
          <SmartImage
            imageUrl={compose?.media[0]?.download_url.replace(
              "original",
              "preview.webp"
            )}
            fallbackUrl={compose?.media[0]?.download_url.replace(
              "original",
              "thumbnail.jpg"
            )}
            h="full"
          />
        </Flex>
      )}

      <Stack justifyContent="center" flex="1" mb="4">
        <CardBody>
          <Text fontSize="15px" fontWeight="400" color="black.active">
            {description && description.length > 400
              ? description.slice(0, 400) + "..."
              : description}
          </Text>
        </CardBody>

        <Flex justifyContent="space-between" ml="5">
          <Box w="100%">
            <Text
              color="rgba(53, 59, 72, 0.33)"
              fontSize="12px"
              fontWeight="400"
              mb="3"
            >
              {t("platforms")}
            </Text>

            <Flex columnGap="2">
              {avatarsToDisplay?.map((avatar) => (
                <Button variant="unstyled" cursor="pointer" key={avatar.id}>
                  <AvatarWithBadge
                    badgeSize="20px"
                    picture={avatar.picture ?? ""}
                    channel={avatar.channel_type}
                    channel_id={avatar.id.toString()}
                    w="34px"
                    h="34px"
                    key={avatar.id}
                    tooltip={true}
                    email={
                      avatar.username ? `@${avatar.username}` : avatar.name
                    }
                  />
                </Button>
              ))}
              {hiddenCount > 0 && (
                <Tooltip
                  shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                  borderRadius="4px"
                  bg="white"
                  color="black"
                  p="4px"
                  label={
                    <ul>
                      {hiddenAvatars?.map((avatar) => (
                        <>
                          <Text key={avatar.id} fontSize="12px">
                            {avatar.username
                              ? `@${avatar.username}`
                              : avatar.name}
                          </Text>
                        </>
                      ))}
                    </ul>
                  }
                >
                  <Button cursor="pointer" variant="unstyled">
                    <Avatar
                      w="35px"
                      h="35px"
                      fontWeight="600"
                      color="#487EB0"
                      shadow="0 0 10px rgba(0, 0, 0, 0.2)"
                      bg="white.bg"
                      border="1px solid #487EB0"
                      name={"+ " + hiddenCount}
                    />
                  </Button>
                </Tooltip>
              )}
            </Flex>
          </Box>
          <Box alignSelf="flex-start" mr="2">
            <Text
              mb="3"
              textAlign="center"
              color="black.active"
              fontSize="12px"
              fontWeight="400"
            >
              {t("planned-date")}
            </Text>
            <Text
              color="black.active"
              fontSize="12px"
              fontWeight="600"
              whiteSpace="nowrap"
            >
              {plannedDate}
            </Text>
          </Box>
        </Flex>
      </Stack>
    </Card>
  );
};
