import React from "react";
import { Button, HStack, IconButton } from "@chakra-ui/react";
import { DoubleChevronLeftIcon } from "@/assets/icons/double-chevron-left-icon";
import { SingleChevronLeftIcon } from "@/assets/icons/single-chevron-left-icon";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (pageNumber: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  totalPages,
  currentPage,
  onPageChange,
}) => {
  const renderPageNumbers = () => {
    const pageNumbers: (number | string)[] = [];

    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 2) {
        pageNumbers.push(1, 2, 3, "...");
      } else if (totalPages - currentPage > 1) {
        pageNumbers.push(currentPage - 1, currentPage, currentPage + 1, "...");
      } else {
        pageNumbers.push("...", totalPages - 2, totalPages - 1, totalPages);
      }
    }

    return pageNumbers.map((number, index) => (
      <Button
        border="1px solid #EBEDF2"
        shadow="sm"
        w="30px"
        h="30px"
        key={index}
        bgColor={currentPage === number ? "blue.shiny" : "white"}
        color={currentPage === number ? "white" : "#A1A5B7"}
        onClick={() => {
          if (typeof number === "number") {
            onPageChange(number);
          }
        }}
      >
        {number}
      </Button>
    ));
  };

  return (
    <HStack spacing={1} align="center" mt="2">
      <IconButton
        disabled={currentPage === 1}
        onClick={() => onPageChange(1)}
        variant="unstyled"
        icon={
          <DoubleChevronLeftIcon active={currentPage !== 1 ? true : false} />
        }
        aria-label="icon"
      />
      <IconButton
        disabled={currentPage === 1}
        onClick={() => {
          currentPage > 1 && onPageChange(currentPage - 1);
        }}
        variant="unstyled"
        icon={
          <SingleChevronLeftIcon active={currentPage !== 1 ? true : false} />
        }
        aria-label="icon"
      />

      {renderPageNumbers()}
      <IconButton
        disabled={currentPage === totalPages}
        onClick={() => {
          currentPage !== totalPages && onPageChange(currentPage + 1);
        }}
        variant="unstyled"
        icon={
          <SingleChevronLeftIcon
            active={currentPage !== totalPages ? true : false}
          />
        }
        aria-label="icon"
        style={{ transform: "rotate(180deg)" }}
      />
      <IconButton
        disabled={currentPage === totalPages}
        onClick={() => {
          currentPage !== totalPages && onPageChange(totalPages);
        }}
        variant="unstyled"
        icon={
          <DoubleChevronLeftIcon
            active={currentPage !== totalPages ? true : false}
          />
        }
        aria-label="icon"
        style={{ transform: "rotate(180deg)" }}
      />
    </HStack>
  );
};
