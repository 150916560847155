import { POST } from "@/api";
import { globalToast } from "@/constant";
import { i18n } from "@/i18n";
import { create } from "zustand";

type Invitation = {
  token: string;
  action: "accept" | "reject";
};
interface State {
  invitation?: Invitation;
}

interface Action {
  clearInvitation: () => void;
  setInvitation: (invitation: Invitation) => void;
  flushInvitationToken: (invitation?: Invitation) => void;
}

export const useInvitationStore = create<State & Action>()((set, get) => ({
  clearInvitation() {
    set({ invitation: undefined });
  },
  setInvitation(invitation) {
    set({ invitation });
  },
  flushInvitationToken(invitationPayload) {
    const invitation = invitationPayload ? invitationPayload : get().invitation;
    if (invitation) {
      POST(
        invitation.action === "accept"
          ? "/invitation/accept/"
          : "/invitation/reject/",
        {
          body: {
            token: invitation.token,
          },
        }
      ).then(({ response }) => {
        if (response.ok) {
          globalToast.toast({
            status: "success",
            title: i18n.t("alert.invitation.organization-invitation-success"),
          });
        } else {
          globalToast.toast({
            status: "error",
            title: i18n.t("alert.invitation.organization-invitation-error"),
          });
        }

        set({ invitation: undefined });
      });
    }
  },
}));
