export const InstagramCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30 15.1842C30 6.89993 23.2843 0.184204 15 0.184204C6.71573 0.184204 0 6.89993 0 15.1842C0 23.4685 6.71573 30.1842 15 30.1842C23.2843 30.1842 30 23.4685 30 15.1842Z"
        fill="#AC25AF"
      />
      <path
        d="M18.3751 7.68414H11.6251C10.531 7.68414 9.48183 8.11874 8.70825 8.89233C7.93466 9.66591 7.50006 10.7151 7.50006 11.8091V18.5591C7.50006 19.6532 7.93466 20.7024 8.70825 21.476C9.48183 22.2495 10.531 22.6841 11.6251 22.6841H18.3751C19.4691 22.6841 20.5183 22.2495 21.2919 21.476C22.0655 20.7024 22.5001 19.6532 22.5001 18.5591V11.8091C22.5001 10.7151 22.0655 9.66591 21.2919 8.89233C20.5183 8.11874 19.4691 7.68414 18.3751 7.68414ZM21.1876 17.9666C21.1876 18.8697 20.8288 19.7358 20.1903 20.3743C19.5517 21.0129 18.6856 21.3716 17.7826 21.3716H12.2176C11.3145 21.3716 10.4484 21.0129 9.80986 20.3743C9.1713 19.7358 8.81256 18.8697 8.81256 17.9666V12.4016C8.81256 11.4986 9.1713 10.6325 9.80986 9.99394C10.4484 9.35538 11.3145 8.99664 12.2176 8.99664H17.7826C18.6856 8.99664 19.5517 9.35538 20.1903 9.99394C20.8288 10.6325 21.1876 11.4986 21.1876 12.4016V17.9666Z"
        fill="white"
      />
      <path
        d="M18.2301 12.0424L18.1881 12.001L18.1527 11.9661C17.3169 11.1446 16.185 10.6836 15.0051 10.6841C14.4092 10.6881 13.82 10.8079 13.2711 11.0365C12.7222 11.2652 12.2243 11.5983 11.806 12.0168C11.3876 12.4353 11.057 12.931 10.8329 13.4756C10.6089 14.0202 10.4958 14.6029 10.5002 15.1906C10.4993 16.3833 10.9759 17.5281 11.8264 18.376C12.243 18.7919 12.7395 19.1217 13.2867 19.3463C13.834 19.5709 14.4211 19.6858 15.0139 19.6841C15.903 19.6657 16.7675 19.3925 17.5019 18.8979C18.2363 18.4032 18.8089 17.7084 19.1499 16.8983C19.4909 16.0882 19.5854 15.1977 19.4221 14.3354C19.2588 13.4732 18.8446 12.6765 18.2301 12.0424ZM15.0051 18.147C14.4105 18.1553 13.8269 17.9889 13.3285 17.6691C12.8301 17.3492 12.4394 16.8904 12.2061 16.351C11.9728 15.8116 11.9074 15.2159 12.0183 14.6397C12.1291 14.0635 12.4112 13.5329 12.8287 13.1153C13.2462 12.6976 13.7801 12.4119 14.3627 12.2945C14.9452 12.177 15.55 12.2331 16.1002 12.4556C16.6503 12.6781 17.121 13.0569 17.4522 13.5439C17.7835 14.031 17.9604 14.6042 17.9604 15.1906C17.9633 15.5762 17.8892 15.9586 17.7422 16.3158C17.5952 16.6731 17.3783 16.9983 17.1039 17.2729C16.8294 17.5474 16.5028 17.766 16.1426 17.916C15.7825 18.0659 15.396 18.1445 15.0051 18.147Z"
        fill="white"
      />
      <path
        d="M19.4515 10.586C19.4523 10.6808 19.4341 10.7749 19.398 10.8628C19.3619 10.9507 19.3086 11.0308 19.2411 11.0983C19.1736 11.1659 19.0933 11.2196 19.0047 11.2565C18.9161 11.2934 18.821 11.3127 18.7249 11.3132C18.6296 11.3132 18.5352 11.2945 18.4472 11.2582C18.3593 11.2219 18.2795 11.1688 18.2125 11.1019C18.111 11.0003 18.042 10.8715 18.0139 10.7315C17.9859 10.5916 18.0002 10.4466 18.0549 10.3145C18.1096 10.1825 18.2024 10.0692 18.3218 9.98866C18.4411 9.90815 18.5818 9.86398 18.7264 9.86163C18.8956 9.8616 19.0594 9.9202 19.1893 10.0272L19.2043 10.042C19.2285 10.0614 19.2506 10.0832 19.2702 10.107L19.2867 10.1248C19.394 10.2551 19.4522 10.4181 19.4515 10.586Z"
        fill="white"
      />
    </svg>
  );
};
