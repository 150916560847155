import { Center, Spinner, Text, Image as ChakraImage } from "@chakra-ui/react";
import axios from "axios";
import { FC, useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const DelayedImage: FC<{ src: string; fallbackUrl?: string }> = ({
  src,
  fallbackUrl,
}) => {
  const ref = useRef<HTMLImageElement | null>(null);
  const [loaded, setLoaded] = useState<{ state: boolean; imageSrc?: string }>({
    state: false,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const tryImages = async () => {
      let found = false;
      while (!found) {
        try {
          await axios.head(src);

          found = true;
          setLoaded({ state: true, imageSrc: src });
          break;
        } catch (AxiosError) {
          /* empty */
        }

        try {
          if (fallbackUrl && !found) {
            await axios.head(fallbackUrl);

            setLoaded({ state: true, imageSrc: fallbackUrl });
          }
        } catch (AxiosError) {
          /* empty */
        }

        await new Promise((r) => setTimeout(r, 500));
      }
    };

    tryImages();
  }, [fallbackUrl, src]);

  return (
    <>
      <Center
        display={loaded.state ? "none" : undefined}
        w="28"
        h="28"
        flexDir="column"
      >
        <Spinner size="xs" />
        <Text fontSize="8px" mt="3">
          {t("page.post-page.generating-thumbnail")}
        </Text>
      </Center>
      <ChakraImage
        hidden={!loaded.state}
        objectFit="contain"
        bgColor="#FAFAFA"
        ref={ref}
        onLoad={() => setLoaded((s) => ({ ...s, state: true }))}
        h="28"
        w="48"
        src={loaded.imageSrc}
        draggable="false"
      />
    </>
  );
};
