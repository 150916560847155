export const LogoWhite = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="122"
      height="122"
      viewBox="0 0 122 122"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M61 122C94.6894 122 122 94.6894 122 61C122 27.3106 94.6894 0 61 0C27.3106 0 0 27.3106 0 61C0 94.6894 27.3106 122 61 122Z"
        fill="white"
      />
      <path
        d="M43.1227 87.321V90.1383C43.1227 91.9733 43.1047 93.8123 43.1367 95.6493C43.1447 96.2304 42.9489 96.4939 42.3779 96.5638C37.3821 97.1748 32.8355 96.1625 28.916 92.8579C26.6097 90.9151 24.8705 88.5609 23.8842 85.7136C22.0911 80.5341 22.6882 75.6201 25.6693 70.9997C27.3326 68.418 29.5809 66.5151 32.3064 65.1493C34.5867 64.0052 37.0166 63.488 39.5545 63.484C48.3461 63.4661 57.1437 63.4701 65.9353 63.4581C68.0138 63.4581 70.0984 63.502 72.167 63.3682C76.0067 63.1186 79.1975 61.4534 81.7932 58.63C83.9257 56.3118 85.1478 53.5443 85.4952 50.4534C85.8786 47.041 85.2176 43.7764 83.2908 40.8871C80.7529 37.0813 77.2468 34.7432 72.6423 34.1881C68.8465 33.7328 65.3482 34.5774 62.2752 36.8237C59.0625 39.1679 56.9899 42.3167 56.3829 46.3002C56.1773 47.648 56.1593 49.0337 56.1413 50.3995C56.1054 53.0871 56.1334 55.7807 56.1334 58.4743C56.1334 59.1432 56.0615 59.215 55.4145 59.215C52.9486 59.215 50.4806 59.2071 48.0146 59.229C47.5594 59.229 47.4535 59.0813 47.4575 58.652C47.4755 54.6425 47.4656 50.6351 47.4756 46.6257C47.4796 44.4373 48.1624 42.3926 48.9072 40.3739C50.3309 36.5063 52.699 33.2596 55.8658 30.6738C58.793 28.2837 62.1056 26.5825 65.8674 25.8098C68.9803 25.1709 72.0951 25.081 75.204 25.658C78.6564 26.297 81.8333 27.6588 84.6646 29.7613C88.1309 32.3331 90.7845 35.5718 92.4877 39.5493C93.9833 43.0376 94.5304 46.6856 94.2688 50.4774C94.0272 53.9836 93.0868 57.2742 91.3337 60.2973C88.0111 66.0219 83.1311 69.7697 76.7236 71.4889C74.7608 72.0161 72.7621 72.2537 70.7294 72.2477C66.0192 72.2337 61.3028 72.2257 56.5926 72.2297C53.889 72.2297 51.1855 72.2736 48.4779 72.2796C45.4928 72.2836 42.5057 72.3595 39.5245 72.2437C36.17 72.1099 32.8555 74.6597 31.8791 77.9324C30.5852 82.2633 33.3966 87.329 38.606 88.0258C39.7042 88.1736 40.7426 88.0398 41.7829 87.7123C42.1842 87.5865 42.5955 87.4807 43.1346 87.323L43.1207 87.319L43.1227 87.321Z"
        fill="#57AAEC"
      />
      <path
        d="M56.1647 86.6094H56.1607V96.028C56.1607 96.7209 56.1109 96.7308 55.426 96.7269C53.012 96.7089 50.5979 96.7129 48.1839 96.7269C47.3333 96.7269 47.3591 96.7348 47.4091 95.9082C47.459 95.0576 47.4231 94.199 47.4231 93.3424C47.4231 87.9353 47.4371 82.5221 47.4231 77.115C47.4231 76.5879 47.5528 76.4221 48.1079 76.4301C50.5759 76.4561 53.0499 76.4621 55.5218 76.4221C56.0709 76.4142 56.1887 76.5879 56.1847 77.0751C56.1707 80.2519 56.1748 83.4286 56.1748 86.6034C56.1748 86.6074 56.1727 86.6094 56.1687 86.6094H56.1647Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
