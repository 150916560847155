import { APISchemas, DELETE, GET, PATCH } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Box,
  Popover,
  PopoverTrigger,
  Table,
  Input,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  Checkbox,
  CheckboxGroup,
  MenuButton,
  MenuList,
  MenuItem,
  PopoverContent,
  PopoverBody,
  Button,
  Flex,
  IconButton,
  Text,
  useToast,
  Switch,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback, useMemo, useState, useRef, useEffect } from "react";
import { useQuery } from "react-query";
import { SearchIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { useTranslation } from "react-i18next";
import { FilterBox } from "@/assets/icons/filter-box";
import { SearchBoxIcon } from "@/assets/icons/search-box-icon";
import { UserRevokedIcon } from "@/assets/icons/user-revoked-icon";
// import { RefreshBoxIcon } from "@/assets/icons/refresh-box-icon";
import { TrashBoxIcon } from "@/assets/icons/trash-box-icon";
import { Pagination } from "@/components/pagination";
import { useChannel } from "@/hooks/use-channels";

export const AccountSettings: FC = () => {
  const { user, organization } = useAuthStore((state) => ({
    user: state.user,
    organization: state.activeOrganization,
  }));
  const fullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();
  const toast = useToast();
  const [searchInput, setSearchInput] = useState<string>("");

  const { data: channels, refetch } = useQuery(
    ["channels", organization?.id],
    async () => {
      if (!organization) {
        return;
      }
      const { data } = await GET("/org/{org_pk}/channels/", {
        params: {
          path: {
            org_pk: organization.id.toString(),
          },
        },
      });

      return data;
    }
  );

  const handleSwitch = useCallback(
    async (item: APISchemas["Channel"]) => {
      const updatedStatus = item.status === "active" ? "inactive" : "active";

      if (!organization) return;

      const { response, error } = await PATCH("/org/{org_pk}/channels/{id}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
            id: item.id,
          },
        },
        body: {
          organization: organization?.id,
          status: updatedStatus,
        },
      });
      if (response.status === 200) {
        toast({
          status: "success",
          description: t("alert.success.channel-updated-succesfully"),
          duration: 3000,
        });
        refetch();

        return;
      }
      if (error?.issues) {
        error.issues.map((err) => {
          toast({
            description: err.message,
            status: "error",
            isClosable: true,
          });
        });

        return;
      }

      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });

        return;
      }
      //  else {
      //   toast({
      //     status: "error",
      //     description: "An unexpected error ocurred while updating channel",
      //     duration: 3000,
      //   });
      // }
    },
    [organization, toast, refetch, t]
  );

  const navigate = useNavigate();

  const removeChannel = useCallback(
    async (channel: APISchemas["Channel"]) => {
      if (!organization) {
        return;
      }

      const { response, error } = await DELETE("/org/{org_pk}/channels/{id}/", {
        params: {
          path: {
            id: channel.id,
            org_pk: organization.id.toString(),
          },
        },
      });

      if (error) {
        toast({
          status: "error",
          description:
            error.detail ||
            t("errors.an-unexpected-error-occurred-removing-channel"),
          duration: 3000,
        });
        return;
      }

      if (response.ok) {
        toast({
          status: "success",
          title: t("alert.success.channel-removed-succesfully"),
          duration: 3000,
        });
        refetch();
        return;
      }

      toast({
        status: "error",
        description: t("errors.an-unexpected-error-occurred-removing-channel"),
        duration: 3000,
      });
    },
    [organization, toast, refetch, t]
  );

  const filteredChannels = useMemo(
    () =>
      channels?.filter((item) => {
        if (
          selectedChannels.length > 0 &&
          !selectedChannels.includes(
            item.channel_type.toLowerCase().split("_")[0]
          )
        ) {
          return false;
        }
        const nameMatch = item.name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
        const channelMatch = item.channel_type
          .toLowerCase()
          .includes(searchInput.toLowerCase());

        return nameMatch || channelMatch;
      }),
    [channels, searchInput, selectedChannels]
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, selectedChannels]);

  const handleCheckboxChange = useCallback((selected: string[]) => {
    setSelectedChannels(selected);
  }, []);
  const accountPerPage = 5;

  const totalPages = filteredChannels
    ? Math.ceil(filteredChannels?.length / accountPerPage)
    : 0;
  const startIndex = (currentPage - 1) * accountPerPage;
  const endIndex = startIndex + accountPerPage;
  const currentPageAccounts = filteredChannels?.slice(startIndex, endIndex);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let activeCount = 0;
  let revokedCount = 0;
  let inactiveCount = 0;

  const { data: ValidChannels } = useChannel();

  ValidChannels?.forEach((channel) => {
    if (channel.status === "active") {
      activeCount++;
    }
  });

  channels?.forEach((channel) => {
    // if (channel.status === "active") {
    //   activeCount++;
    // } else
    if (channel.status === "revoked") {
      revokedCount++;
    } else if (channel.status === "inactive") {
      inactiveCount++;
    }
  });

  return (
    <Flex flexDir="column" rowGap="20px" h="full" ml="24px">
      <Flex
        border="1px solid  #E1E3EA"
        p="3"
        alignItems="center"
        w="full"
        bg="white"
        shadow="sm"
        borderRadius="6px"
        h="110px"
      >
        <Flex w="full" alignItems="center" h="80px">
          <Box ml="0">
            <Text
              color="#002D6F"
              fontSize="22px"
              fontWeight="600"
              lineHeight="20px"
              whiteSpace="nowrap"
            >
              {fullName}
            </Text>
            <Text
              color="black.active"
              fontSize="13px"
              lineHeight="20px"
              mt="1.5"
            >
              {user?.email}
            </Text>
          </Box>
        </Flex>
        <Flex alignItems="center" w="full" gap="16px" justifyContent="flex-end">
          <Flex>
            <Button
              fontSize="15px"
              bg="green.label"
              textColor="#fff"
              onClick={() => navigate("/channels")} //  ("/channel/")
              _hover={{
                opacity: 0.8,
              }}
              borderRadius="3px"
              h="36px"
              w="202px"
            >
              {t("connect_channel")}
            </Button>
          </Flex>

          <Menu
            closeOnSelect={false}
            placement="bottom-end"
            strategy="fixed"
            eventListeners={{
              resize: true,
            }}
          >
            <MenuButton mr="0" borderRadius="2px">
              <FilterBox />
            </MenuButton>

            <MenuList borderRadius="6px">
              <CheckboxGroup
                value={selectedChannels}
                onChange={handleCheckboxChange}
              >
                <MenuItem>
                  <Checkbox w="full" h="full" value="twitter">
                    Twitter
                  </Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox w="full" h="full" value="facebook">
                    Facebook
                  </Checkbox>
                </MenuItem>

                <MenuItem>
                  <Checkbox w="full" h="full" value="instagram">
                    Instagram
                  </Checkbox>
                </MenuItem>
                <MenuItem>
                  <Checkbox w="full" h="full" value="linkedin">
                    LinkedIn
                  </Checkbox>
                </MenuItem>
              </CheckboxGroup>
            </MenuList>
          </Menu>

          <Flex>
            <Popover offset={[-275, 0]} eventListeners={{ resize: true }}>
              <PopoverTrigger>
                <Button size="none" variant="unstyled" borderRadius="2px">
                  <SearchBoxIcon />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                w="600px"
                pos="relative"
                top="4"
                bg="white"
                border="none"
                shadow="sm"
              >
                <PopoverBody w="600px" h="40px" p="0px">
                  <Flex
                    w="full"
                    h="full"
                    shadow="md"
                    justifyContent="center"
                    alignItems="center"
                    border="1px solid #497EB026"
                  >
                    <IconButton
                      variant="unstyled"
                      aria-label="search-button"
                      icon={<SearchIcon />}
                    />
                    <Input
                      variant="unstyled"
                      placeholder={t("search-keywords")}
                      onChange={(e) => setSearchInput(e.target.value)}
                      value={searchInput}
                    />
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        border="1px solid #497EB026"
        px="4"
        pt="5"
        pb="5"
        w="full"
        flexDir="column"
        gap="6"
        h="full"
        //overflowY="auto"
        bg="white"
        borderRadius="6px"
        shadow="sm"
      >
        <Flex justifyContent="space-between">
          <Text
            color="black.active"
            fontSize="20px"
            fontWeight="700"
            lineHeight="20px"
          >
            {t("connected_accounts")}
          </Text>
          <Flex>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.active")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {activeCount}
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.inactive")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {inactiveCount}{" "}
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              mr="1"
            >
              {t("page.settings.account-settings.revoked")}:
            </Text>
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              mr="3"
            >
              {revokedCount}
            </Text>
          </Flex>
        </Flex>

        <Box h="420px">
          <TableContainer>
            <Table variant="simple" h="max-content">
              <Thead>
                <Tr>
                  <Th borderWidth="0" w="5px"></Th>
                  <Th borderWidth="0" textAlign="center" w="30%">
                    <Text
                      fontWeight="700"
                      fontSize="16px"
                      opacity="0.33"
                      textAlign="start"
                    >
                      {t("user")}
                    </Text>
                  </Th>
                  <Th borderWidth="0">
                    <Text
                      fontWeight="700"
                      fontSize="16px"
                      opacity="0.33"
                      textAlign="center"
                    >
                      {t("platform")}
                    </Text>
                  </Th>

                  <Th borderWidth="0">
                    <Text
                      fontWeight="700"
                      fontSize="16px"
                      opacity="0.33"
                      textAlign="center"
                    >
                      {t("page.settings.account-settings.channel-status")}
                    </Text>
                  </Th>

                  <Th borderWidth="0"></Th>
                </Tr>
              </Thead>
              <Tbody mt="2">
                {currentPageAccounts?.map((c) => (
                  <Tr
                    key={c.id}
                    alignItems="center"
                    borderBottom="1px solid rgba(144, 123, 123, 0.08)"
                  >
                    <Td borderWidth="0">
                      <Flex>
                        <AvatarWithBadge
                          channel={c.channel_type}
                          picture={c.picture ?? undefined}
                          name={c.name}
                          w="45px"
                          h="45px"
                          border="1px solid transparent"
                        />
                      </Flex>
                    </Td>
                    <Td borderWidth="0">
                      <Flex alignItems="center">
                        <Text
                          color="black.active"
                          fontSize="16px"
                          fontWeight="600"
                          lineHeight="20px"
                        >
                          {c?.username
                            ? c.username.startsWith("@")
                              ? c.username
                              : `@${c.username}`
                            : c?.name}
                          <Text
                            mt="4px"
                            as="span"
                            display="block"
                            fontSize="13px"
                            color="black.active"
                            fontWeight="500"
                          >
                            {c?.username
                              ? c.username.startsWith("@")
                                ? c.username
                                : `@${c.username}`
                              : `@${c.name}`}
                          </Text>
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderWidth="0">
                      <Text
                        color="black.active"
                        fontSize="16px"
                        fontWeight="500"
                        lineHeight="20px"
                        textAlign="center"
                        textTransform="capitalize"
                      >
                        {c.channel_type.split("_")[0]}
                      </Text>
                    </Td>

                    <Td borderWidth="0" textAlign="center" fontSize="16px">
                      {c.status === "revoked" ? (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="6px"
                        >
                          <Box width="30px" height="30px">
                            <UserRevokedIcon />
                          </Box>
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            color="black.active"
                            lineHeight="20px"
                          >
                            {t("page.settings.account-settings.revoked")}
                          </Text>
                        </Flex>
                      ) : c.status === "active" || c.status === "inactive" ? (
                        <Flex
                          justifyContent="center"
                          alignItems="center"
                          gap="1"
                        >
                          <Text
                            fontSize="16spx"
                            fontWeight="500"
                            lineHeight="20px"
                            color="black.active"
                            mr="1"
                          >
                            {t("page.settings.account-settings.inactive")}
                          </Text>
                          <DeleteAlert
                            status={c.status}
                            type="updateStatus"
                            trigger={(onOpen) => (
                              <Switch
                                isChecked={c.status === "active" ? true : false}
                                onChange={
                                  c.status === "active"
                                    ? () => onOpen()
                                    : () => handleSwitch(c)
                                }
                              />
                            )}
                            removeFn={() => handleSwitch(c)}
                            channel={c}
                          />

                          {/* <Switch isChecked={c.status === "active"} /> */}
                          <Text
                            fontSize="16px"
                            fontWeight="500"
                            lineHeight="20px"
                            color="black.active"
                            ml="1"
                          >
                            {t("page.settings.account-settings.active")}
                          </Text>
                        </Flex>
                      ) : (
                        <Text>
                          {t(`page.settings.account-settings.${c.status}`)}
                        </Text>
                      )}
                    </Td>
                    <Td borderWidth="0">
                      <Flex justifyContent="flex-end" gap="16px">
                        {/* <Button
                          cursor="pointer"
                          size="none"
                          variant="unstyled"
                          onClick={() => refetch()}
                        >
                          <RefreshBoxIcon width="33px" height="33px" />
                        </Button> */}
                        <DeleteAlert
                          type="delete"
                          trigger={(onOpen) => (
                            <Button
                              size="none"
                              cursor="pointer"
                              variant="unstyled"
                              onClick={onOpen}
                            >
                              <TrashBoxIcon width="33px" height="33px" />
                            </Button>
                          )}
                          removeFn={() => removeChannel(c)}
                          channel={c}
                        />
                      </Flex>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>

        <Flex
          justifyContent="center"
          alignItems="flex-end"
          position="relative"
          top="5"
        >
          {filteredChannels?.length !== 0 && (
            <Box p={4}>
              <Pagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const DeleteAlert: FC<{
  type: "delete" | "updateStatus";
  trigger: (onOpen: () => void) => React.ReactNode;
  removeFn: () => void;
  channel?: APISchemas["Channel"];
  status?: string;
}> = ({ trigger, removeFn, status, type }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();

  return (
    <>
      {trigger(onOpen)}

      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="white.bg">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {type === "delete"
                ? t("alert.remove-modal.you-are-deleting-c")
                : status === "inactive"
                  ? t("alert.account-status-update.title-active")
                  : t("alert.account-status-update.title-deactive")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {type === "delete"
                ? t("alert.remove-modal.are-you-sure")
                : status === "inactive"
                  ? t("alert.account-status-update.description-active")
                  : t("alert.account-status-update.description-inactive")}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}>
                {t("alert.remove-modal.cancel")}
              </Button>
              <Button
                colorScheme="red"
                onClick={() => {
                  removeFn();
                  onClose();
                }}
                ml={3}
              >
                {type === "delete"
                  ? t("alert.remove-modal.delete")
                  : t("update")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
