import React from "react";

export const VerticalDirection = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 21 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.2151 5.33203H1.69444C1.26101 5.33203 0.909668 5.6305 0.909668 5.9987C0.909668 6.3669 1.26101 6.66536 1.69444 6.66536H20.2151C20.6486 6.66536 20.9999 6.3669 20.9999 5.9987C20.9999 5.6305 20.6486 5.33203 20.2151 5.33203Z"
        fill={props.color}
      />
      <path
        d="M20.2151 0H1.69444C1.26101 0 0.909668 0.298467 0.909668 0.666667C0.909668 1.03487 1.26101 1.33333 1.69444 1.33333H20.2151C20.6486 1.33333 20.9999 1.03487 20.9999 0.666667C20.9999 0.298467 20.6486 0 20.2151 0Z"
        fill={props.color}
      />
      <path
        d="M20.2151 10.668H1.69444C1.26101 10.668 0.909668 10.9664 0.909668 11.3346C0.909668 11.7028 1.26101 12.0013 1.69444 12.0013H20.2151C20.6486 12.0013 20.9999 11.7028 20.9999 11.3346C20.9999 10.9664 20.6486 10.668 20.2151 10.668Z"
        fill={props.color}
      />
    </svg>
  );
};
