import { useEffect, useState } from "react";
import Pusher from "pusher-js";
import { APISchemas } from "@/api";
const ENV = import.meta.env;

const pusher = new Pusher(ENV.VITE_WEB_SOCKET_KEY, {
  cluster: ENV.VITE_WEB_SOCKET_CLUSTER,
});

export const useNotification = (
  notify?: (notification: APISchemas["Notification"]) => void
) => {
  const [liveNotifications, setLiveNotifications] =
    useState<APISchemas["Notification"]>();

  useEffect(() => {
    Pusher.logToConsole = false;

    const channel = pusher.subscribe("notification");
    channel.bind(
      "new-notification",
      function (data: APISchemas["Notification"]) {
        const now = new Date();

        data.created_at ||= now.toISOString();
        setLiveNotifications(data);
        notify?.(data);
      }
    );

    return () => {
      channel.unbind("new-notification");
      pusher.unsubscribe("notification");
    };
  }, [notify]);

  return liveNotifications;
};
