export const LinkedinWhiteIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15" cy="15" r="15" fill="white" />
      <path
        d="M10.9432 6.96772C10.9432 7.45656 10.7983 7.93442 10.5267 8.34088C10.2551 8.74733 9.8691 9.06413 9.41747 9.2512C8.96584 9.43827 8.46888 9.48722 7.98943 9.39185C7.50999 9.29648 7.06959 9.06108 6.72392 8.71542C6.37826 8.36976 6.14286 7.92935 6.04749 7.44991C5.95212 6.97046 6.00107 6.4735 6.18814 6.02187C6.37521 5.57024 6.69201 5.18422 7.09846 4.91264C7.50492 4.64105 7.98278 4.49609 8.47162 4.49609C9.12714 4.49609 9.75581 4.7565 10.2193 5.22002C10.6828 5.68353 10.9432 6.3122 10.9432 6.96772Z"
        fill="#0077B5"
      />
      <path
        d="M10.383 11.0934V22.0131C10.3834 22.0936 10.3679 22.1735 10.3374 22.248C10.3068 22.3226 10.2619 22.3903 10.2051 22.4475C10.1483 22.5046 10.0807 22.5499 10.0063 22.5808C9.93196 22.6118 9.8522 22.6277 9.77164 22.6277H7.16655C7.086 22.6279 7.00619 22.6122 6.93173 22.5815C6.85726 22.5507 6.7896 22.5056 6.73264 22.4486C6.67568 22.3917 6.63053 22.324 6.59981 22.2495C6.56908 22.1751 6.55337 22.0953 6.55359 22.0147V11.0934C6.55359 10.9309 6.61817 10.775 6.73312 10.66C6.84808 10.545 7.00399 10.4805 7.16655 10.4805H9.77164C9.93393 10.4809 10.0894 10.5457 10.204 10.6606C10.3186 10.7755 10.383 10.9311 10.383 11.0934Z"
        fill="#0077B5"
      />
      <path
        d="M24.0001 16.7758V22.0618C24.0003 22.1359 23.9859 22.2092 23.9576 22.2777C23.9294 22.3462 23.8879 22.4084 23.8355 22.4608C23.7831 22.5131 23.7209 22.5546 23.6525 22.5829C23.584 22.6111 23.5106 22.6256 23.4366 22.6253H20.6354C20.5613 22.6256 20.4879 22.6111 20.4195 22.5829C20.351 22.5546 20.2888 22.5131 20.2364 22.4608C20.184 22.4084 20.1425 22.3462 20.1143 22.2777C20.0861 22.2092 20.0716 22.1359 20.0718 22.0618V16.939C20.0718 16.1744 20.2959 13.5907 18.0731 13.5907C16.3512 13.5907 16.0003 15.3588 15.9311 16.153V22.0618C15.9311 22.2098 15.8728 22.3519 15.7689 22.4574C15.665 22.5628 15.5238 22.6232 15.3758 22.6253H12.6702C12.5962 22.6253 12.523 22.6107 12.4548 22.5824C12.3865 22.5541 12.3245 22.5125 12.2723 22.4602C12.2201 22.4078 12.1787 22.3457 12.1506 22.2773C12.1224 22.209 12.1081 22.1357 12.1083 22.0618V11.0433C12.1081 10.9694 12.1224 10.8961 12.1506 10.8278C12.1787 10.7594 12.2201 10.6973 12.2723 10.6449C12.3245 10.5926 12.3865 10.551 12.4548 10.5227C12.523 10.4944 12.5962 10.4798 12.6702 10.4798H15.3758C15.5252 10.4798 15.6686 10.5391 15.7742 10.6448C15.8799 10.7505 15.9393 10.8938 15.9393 11.0433C15.9393 11.3286 16.3402 11.4613 16.5498 11.2677C17.1783 10.6869 18.0799 10.2969 19.5479 10.2969C24.0264 10.2969 24.0001 14.4789 24.0001 16.7758Z"
        fill="#0077B5"
      />
    </svg>
  );
};
