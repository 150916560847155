import { randInt } from "@/utils/misc";
import {
  HStack,
  Avatar,
  VStack,
  AspectRatio,
  Box,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";
import { formatDistanceToNow } from "date-fns";
import React, { FC, useMemo, useRef } from "react";
import { mainSchema } from "../form-containers";
import { useFormStore, Forms } from "../form-store";
import Slider from "react-slick";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";

export const LinkedinPreview: FC = () => {
  const { linkedin } = useFormStore((s) => s.previews);

  const { forms, mainForms } = useFormStore((s) => ({
    forms: s.forms,
    mainForms: s.mainForms,
  }));

  const scheduledAt = useFormStore((s) => s.scheduledAt);

  const dataRef = useRef<Forms | mainSchema>();

  const [randN] = useMemo(() => [randInt(100, 1000000)], []);

  if (!linkedin) {
    return null;
  }

  if (linkedin.formType !== "normal") {
    dataRef.current = mainForms[linkedin.formType];
  }

  if (linkedin.channel) {
    dataRef.current = forms[linkedin.channel.id.toString()];
  }

  if (!dataRef.current) {
    return null;
  }

  const name = linkedin.channel?.name ?? "Linkedin";
  const username = linkedin.channel?.username ?? "Linkedin";

  return (
    <Box
      w="full"
      maxW="468px"
      h="min-content"
      bg="white"
      boxShadow="0px 13px 29px 0px rgba(0, 0, 0, 0.22)"
      py="6"
    >
      <HStack gap="2" my="0.5" px="3">
        <Avatar
          name={name}
          src={linkedin.channel?.picture ?? undefined}
          rounded="none"
        />
        <VStack gap="0" alignItems="flex-start">
          <Text fontSize="md" fontWeight="semibold" color="#353B48">
            {name.replace("@", "")}
          </Text>
          <Text fontSize="xs" fontWeight="light" color="#353B48">
            {username}
          </Text>
          <Text fontSize="xs" fontWeight="light" color="#353B48">
            {formatDistanceToNow(scheduledAt)}
          </Text>
        </VStack>
      </HStack>
      <Text
        fontSize="sm"
        lineHeight="5"
        fontWeight="semibold"
        color="#353B48"
        my="2.5"
        mx="3"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
      >
        {dataRef.current.content}
      </Text>
      <Box
        w="full"
        h="max-content"
        maxH="xs"
        minH="56"
        sx={{
          ".slick-trac div": {
            margin: "auto",
          },
          ".slick-disabled": {
            display: "none !important",
          },
        }}
      >
        <Slider
          infinite={false}
          nextArrow={<Arrow />}
          prevArrow={<Arrow direction="prev" />}
        >
          {dataRef.current.files?.slice(0, 4).map((file, index) => (
            <React.Fragment key={index}>
              {file.data.image ? (
                <AspectRatio h="56">
                  <Image src={file.data.image.downloadUrl} />
                </AspectRatio>
              ) : file.data.video ? (
                <Box h="56" w="full">
                  <BunnyPlayer src={file.data.video.url} />
                </Box>
              ) : null}
            </React.Fragment>
          ))}
        </Slider>
      </Box>
      <HStack gap="1.5" mt="3" px="3">
        <Box rounded="full" p="3.5" bg="#D9D9D9" />
        <Box rounded="full" p="3.5" bg="#D9D9D9" />
        <Box rounded="full" p="3.5" bg="#D9D9D9" />
        <Text fontSize="xs" lineHeight="5" color="#353B48" fontWeight="light">
          {randN > 1000 ? `${Math.floor(randN / 1000)}K` : randN}
        </Text>
      </HStack>
    </Box>
  );
};

const Arrow: FC<{
  className?: string;
  style?: React.StyleHTMLAttributes<HTMLDivElement>;
  onClick?: () => void;
  direction?: "next" | "prev";
}> = ({ onClick, direction = "next", style, className }) => {
  return (
    <Box
      style={{ ...style, zIndex: 1 }}
      _before={{ content: "none" }}
      left={direction === "prev" ? "1.5" : undefined}
      right={direction === "next" ? "7" : undefined}
      className={className}
    >
      <Button
        variant="unstyled"
        rounded="full"
        bg="#345A79"
        w="42px"
        h="42px"
        px="4"
        maxW="min-content"
        transform={direction === "prev" ? "rotate(180deg);" : undefined}
        onClick={onClick}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="23"
          viewBox="0 0 10 23"
          fill="none"
        >
          <path
            d="M0.244055 0.306206C-0.0813336 0.71442 -0.0813892 1.3764 0.244111 1.78469L7.98817 11.4998L0.244056 21.2153C-0.0813333 21.6235 -0.0813889 22.2855 0.244111 22.6938C0.569556 23.1021 1.09717 23.1021 1.42261 22.6938L9.75594 12.239C9.91222 12.0429 10 11.777 10 11.4998C10 11.2225 9.91217 10.9565 9.75589 10.7605L1.42256 0.306276C1.09717 -0.102079 0.5695 -0.102079 0.244055 0.306206Z"
            fill="white"
          />
        </svg>
      </Button>
    </Box>
  );
};
