import { EditSquareIcon } from "@/assets/icons/edit-square-icon";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import {
  Flex,
  Text,
  Box,
  Button,
  FormLabel,
  Input,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  IconButton,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import React, { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

interface EditPasswordProps {
  handleEdit: (
    data: ValidationSchemaType
  ) => Promise<{ path?: string | undefined; message: string }[] | undefined>;
}

type EditErrorItem = {
  path?: string | undefined;
  message: string;
};

const useTranslatedValidation = () => {
  const { t } = useTranslation();

  const validationSchema = z
    .object({
      oldPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
      newPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
      confirmPassword: z
        .string()
        .min(8, t("errors.password-must-be-at-least-8-characters")),
    })
    .refine((input) => input.confirmPassword === input.newPassword, {
      message: t("errors.passwords-dont-match"),
      path: ["confirmPassword"],
    });
  return validationSchema;
};

type ValidationSchemaType = z.infer<ReturnType<typeof useTranslatedValidation>>;

type PasswordError = EditErrorItem[] | undefined;

export const EditPassword: React.FC<EditPasswordProps> = ({ handleEdit }) => {
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [passwordVisible, setPasswordVisible] = useState<{
    old_pass: boolean;
    new_pass: boolean;
    confirm_pass: boolean;
  }>({
    old_pass: false,
    new_pass: false,
    confirm_pass: false,
  });
  const [passwordError, setPasswordError] = useState<PasswordError>();
  const { t } = useTranslation();
  const validationSchema = useTranslatedValidation();
  type validationSchema = z.infer<typeof validationSchema>;
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<validationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  useEffect(() => {
    if (passwordError?.some((error) => error !== null)) {
      const timeoutId = setTimeout(() => {
        setPasswordError(undefined);
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [passwordError]);

  const handleReset = useCallback(() => {
    reset({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
  }, [reset]);

  const onSubmit: SubmitHandler<validationSchema> = useCallback(
    async (data) => {
      const errors = await handleEdit(data);
      if (!errors) {
        setEditPassword(false);
        handleReset();
        return;
      }
      setPasswordError(errors);
    },
    [handleEdit, handleReset]
  );

  return (
    <Flex w="full" flexDir="column" pb="2">
      <Flex my="3" justifyContent="space-between" alignItems="center" w="full">
        <Text color="black.active" fontSize="20px" fontWeight="700">
          {t("password")}
        </Text>

        {!editPassword ? (
          <Button
            cursor="pointer"
            variant="unstyled"
            type="submit"
            onClick={() => {
              setEditPassword(!editPassword);
            }}
          >
            <Flex alignItems="center" justifyContent="center">
              <EditSquareIcon width="40px" height="40px" />
            </Flex>
          </Button>
        ) : (
          <Flex>
            <Button
              w="128px"
              h="36px"
              bg="red.main"
              border-radius="3px"
              color="white"
              onClick={() => {
                setPasswordVisible(() => ({
                  old_pass: false,
                  new_pass: false,
                  confirm_pass: false,
                }));
                setEditPassword(false);
                handleReset();
              }}
              _hover={{
                opacity: "0.6",
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="600" fontSize="15px" lineHeight="14px">
                  {t("cancel")}
                </Text>
              </Flex>
            </Button>
            <Button
              w="128px"
              h="36px"
              bg="green.label"
              border-radius="3px"
              color="white"
              onClick={() => {
                handleSubmit(onSubmit)();
              }}
              _hover={{
                opacity: "0.6",
              }}
              ml="2"
              type="submit"
            >
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="600" fontSize="15px" lineHeight="14px">
                  {t("save")}
                </Text>
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>
      <Box my="2" mb="3">
        <form
          style={{
            border: "none",
            width: "90%",
            display: "flex",
            height: "min-content",
            position: "relative",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            w="full"
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(3, 1fr)"
            rowGap="20px"
            columnGap="40px"
          >
            <GridItem colSpan={1}>
              <FormControl
                isInvalid={!!errors.oldPassword}
                borderRadius="6px"
                width="full"
                h="100px"
              >
                <FormLabel
                  pos="absolute"
                  top="-2px"
                  fontSize="16px"
                  zIndex="9"
                  ml="2.5"
                  px="4"
                  fontWeight="400"
                  lineHeight="1"
                  pr="8"
                  bg="white"
                >
                  {t("page.settings.profile.old-password")}
                </FormLabel>

                <InputGroup>
                  <Input
                    transition="borderColor 0.6s ease"
                    border={
                      editPassword ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                    }
                    maxLength={20}
                    w="full"
                    mt="2"
                    p="4"
                    color="gray.passive"
                    disabled={!editPassword}
                    variant="unstyled"
                    _disabled={{
                      color: "gray.passive",
                    }}
                    _placeholder={{
                      color: "gray.passive",
                    }}
                    {...register("oldPassword")}
                    type={passwordVisible.old_pass ? "text" : "password"}
                  />
                  {editPassword && (
                    <InputRightElement
                      justifyContent="center"
                      alignItems="center"
                      h="full"
                      mr="3"
                      mt="1"
                    >
                      <IconButton
                        cursor="pointer"
                        size="xs"
                        variant="unstyled"
                        aria-label="password"
                        onClick={() =>
                          setPasswordVisible((prevVisibility) => ({
                            ...prevVisibility,
                            old_pass: !prevVisibility.old_pass,
                          }))
                        }
                        icon={
                          !passwordVisible.old_pass ? (
                            <EyeIcon />
                          ) : (
                            <EyeIconInvisible />
                          )
                        }
                      />
                    </InputRightElement>
                  )}
                </InputGroup>

                {editPassword &&
                  passwordError?.find(
                    (item: EditErrorItem) => item.path === "old_password"
                  ) && (
                    <Text
                      mt="2px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        passwordError?.find(
                          (item: EditErrorItem) => item.path === "old_password"
                        )?.message
                      }
                    </Text>
                  )}
                {editPassword && (
                  <FormErrorMessage>
                    {errors.oldPassword?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>

            <GridItem colSpan={1}>
              <FormControl
                borderRadius="6px"
                isInvalid={!!errors.newPassword}
                width="full"
                h="100px"
              >
                <FormLabel
                  pos="absolute"
                  top="-2px"
                  fontSize="16px"
                  zIndex="9"
                  ml="2.5"
                  px="4"
                  fontWeight="400"
                  lineHeight="1"
                  pr="8"
                  bg="white"
                >
                  {t("page.settings.profile.new-password")}
                </FormLabel>
                <InputGroup>
                  <Input
                    transition="borderColor 0.6s ease"
                    border={
                      editPassword ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                    }
                    maxLength={20}
                    w="full"
                    mt="2"
                    p="4"
                    color="gray.passive"
                    disabled={!editPassword}
                    variant="unstyled"
                    _disabled={{
                      color: "gray.passive",
                    }}
                    _placeholder={{
                      color: "gray.passive",
                    }}
                    {...register("newPassword")}
                    type={passwordVisible.new_pass ? "text" : "password"}
                  />
                  {editPassword && (
                    <InputRightElement
                      justifyContent="center"
                      alignItems="center"
                      h="full"
                      mr="3"
                      mt="1"
                    >
                      <IconButton
                        cursor="pointer"
                        size="xs"
                        variant="unstyled"
                        aria-label="password"
                        onClick={() =>
                          setPasswordVisible((prevVisibility) => ({
                            ...prevVisibility,
                            new_pass: !prevVisibility.new_pass,
                          }))
                        }
                        icon={
                          !passwordVisible.new_pass ? (
                            <EyeIcon />
                          ) : (
                            <EyeIconInvisible />
                          )
                        }
                      />
                    </InputRightElement>
                  )}
                </InputGroup>

                {editPassword &&
                  passwordError?.find(
                    (item: EditErrorItem) => item.path === "new_password"
                  ) && (
                    <Text
                      mt="5px"
                      fontSize="14px"
                      fontWeight="500"
                      color="red.main"
                      ml="1"
                    >
                      {
                        passwordError?.find(
                          (item: EditErrorItem) => item.path === "new_password"
                        )?.message
                      }
                    </Text>
                  )}
                {editPassword && (
                  <FormErrorMessage>
                    {errors.newPassword?.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
            {editPassword && (
              <GridItem colSpan={1}>
                <FormControl
                  borderRadius="6px"
                  isInvalid={!!errors.confirmPassword}
                  width="full"
                  h="100px"
                >
                  <FormLabel
                    pos="absolute"
                    top="-2px"
                    fontSize="16px"
                    zIndex="9"
                    ml="2.5"
                    px="4"
                    fontWeight="400"
                    lineHeight="1"
                    pr="8"
                    bg="white"
                  >
                    {t("input.confirm-new-password")}
                  </FormLabel>
                  <InputGroup>
                    <Input
                      transition="borderColor 0.6s ease"
                      border={
                        editPassword ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                      }
                      maxLength={20}
                      w="full"
                      mt="2"
                      p="4"
                      color="gray.passive"
                      disabled={!editPassword}
                      variant="unstyled"
                      _disabled={{
                        color: "gray.passive",
                      }}
                      _placeholder={{
                        color: "gray.passive",
                      }}
                      {...register("confirmPassword")}
                      type={passwordVisible.confirm_pass ? "text" : "password"}
                    />
                    {editPassword && (
                      <InputRightElement
                        justifyContent="center"
                        alignItems="center"
                        h="full"
                        mr="3"
                        mt="1"
                      >
                        <IconButton
                          cursor="pointer"
                          size="xs"
                          variant="unstyled"
                          aria-label="password"
                          onClick={() =>
                            setPasswordVisible((prevVisibility) => ({
                              ...prevVisibility,
                              confirm_pass: !prevVisibility.confirm_pass,
                            }))
                          }
                          icon={
                            !passwordVisible.confirm_pass ? (
                              <EyeIcon />
                            ) : (
                              <EyeIconInvisible />
                            )
                          }
                        />
                      </InputRightElement>
                    )}
                  </InputGroup>

                  <FormErrorMessage>
                    {errors.confirmPassword?.message}
                  </FormErrorMessage>
                </FormControl>
              </GridItem>
            )}
          </Grid>
        </form>
      </Box>
    </Flex>
  );
};
