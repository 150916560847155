import {
  CircularProgress,
  Flex,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import successAnimData from "@/assets/animations/success-anim-two.json";
import Lottie from "react-lottie";
import { useTranslation } from "react-i18next";
import { POST } from "@/api";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useInvitationStore } from "@/context/invitation-store/invitation-store";
import { globalToast } from "@/constant";

export const AcceptInviteCallbackPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [setInvitation, flushInvitationToken] = useInvitationStore((s) => [
    s.setInvitation,
    s.flushInvitationToken,
  ]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isValidInvitation, setIsValidInvitation] = useState<boolean>();
  const toast = useToast();
  const user = useAuthStore((s) => s.user);
  const successAnimOptions = {
    loop: false,
    autoplay: true,
    animationData: successAnimData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const inviteResponseToken = searchParams.get("token");

  const InviteResponseRequest = useCallback(async () => {
    if (!inviteResponseToken) {
      navigate("/auth/login");
      return;
    }
    try {
      const { data, error: controlError } = await POST(
        "/invitation/token-control/",
        {
          body: { token: inviteResponseToken },
        }
      );

      if (controlError?.detail || controlError?.issues) {
        setIsValidInvitation(false);
        globalToast.toast({
          status: "error",
          title:
            controlError?.detail ||
            controlError.issues?.at(0)?.message ||
            t("errors.an-error-occurred"),
        });
        setTimeout(() => {
          navigate(`/`);
        }, 3000);

        return;
      }

      setIsValidInvitation(true);

      if (!user) {
        globalToast.toast({
          status: "success",
          title: t("alert.invitation.please-login-to-accept-invitation"),
        });

        setInvitation({
          token: inviteResponseToken,
          action: "accept",
        });

        navigate(`/auth/login`);
        return;
      }

      if (data?.email === user.email) {
        flushInvitationToken({
          token: inviteResponseToken,
          action: "accept",
        });

        useAuthStore.getState().refetchUser(true);

        return;
      }

      setInvitation({
        token: inviteResponseToken,
        action: "accept",
      });

      toast({
        status: "warning",
        title: t("alert.invitation.login-with-which-the-invitation-was-sent"),
      });
      navigate("/");
    } catch (error) {
      console.error(error);
      toast({
        status: "error",
        title: t("errors.an-error-occurred"),
      });
    }
  }, [
    inviteResponseToken,
    navigate,
    setInvitation,
    user,
    flushInvitationToken,
    toast,
    t,
  ]);

  useEffect(() => {
    InviteResponseRequest();
  }, [InviteResponseRequest]);

  return (
    <Flex
      height="100vh"
      width="100%"
      flexDirection="column"
      justifyContent="center"
    >
      <VStack spacing={4}>
        {isValidInvitation ? (
          <>
            <Lottie options={successAnimOptions} height={200} width={200} />
            <Text fontSize="xl">{t("invite-accepted-redirect")}...</Text>
          </>
        ) : (
          <>
            <CircularProgress isIndeterminate color="green.300" />
            <Text fontSize="xl">{t("invite-is-not-valid")}...</Text>
          </>
        )}
      </VStack>
    </Flex>
  );
};
