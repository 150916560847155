import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "@/i18n";
import { useFormStore } from "../form-store";
import { TwitterFormPresent } from "../forms-presents/twitter-form-present";
import { StandartFile } from "../common";
import { APISchemas, PATCH, POST } from "@/api";
import { Box, useToast } from "@chakra-ui/react";
import { handleError } from "./_handle-errors";
import isEqual from "lodash.isequal";
import { formUpdateMS } from "../contant";
import { useAuthStore } from "@/context/auth-store/auth-store";

import {
  checkVideoSize,
  checkGeneralImageFormat,
  checkMPEGVideoFormat,
  checkVideoDuration,
  checkImageSize,
  checkVideoAspectRatio,
  checkVideoFrameRate,
} from "../../Post/platform-validations";

import { i18n } from "@/i18n";

export type twitterSchema = z.infer<typeof twitterSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const twitterSchema = z
  .object({
    id: z.number().optional(),
    content: z
      .string()
      .max(280, i18n.t("post-validations.twitter.character-limit"))
      .optional(),
    files: StandartFile.array().max(4).optional(),
  })
  .refine(checkMPEGVideoFormat, {
    path: ["root"],
    message: i18n.t("post-validations.twitter.video-format"),
  })
  .refine(checkGeneralImageFormat, {
    path: ["root"],
    message: i18n.t("post-validations.twitter.image-format"),
  })
  .refine((o) => checkVideoDuration(o, 140, 0.5), {
    path: ["root"],
    message: i18n.t("post-validations.twitter.video-duration"),
  })
  .refine((o) => checkVideoSize(o, 500 * 1024 * 1024), {
    path: ["root"],
    message: i18n.t("post-validations.twitter.video-size"),
  })
  .refine((o) => checkImageSize(o, 5 * 1024 * 1024), {
    path: ["root"],
    message: i18n.t("post-validations.twitter.image-size"),
  })
  .refine((o) => checkVideoFrameRate(o, 0, 60), {
    path: ["root"],
    message: i18n.t("post-validations.twitter.video-frameRate"),
  })
  .refine((o) => checkVideoAspectRatio(o, 1 % 3, 3), {
    path: ["root"],
    message: i18n.t("post-validations.twitter.video-aspectRatio"),
  });

export const TwitterFormContainer: FC<{
  channel: APISchemas["Channel"] & { hasError?: boolean };
  defaultValue?: twitterSchema;
}> = ({ channel, defaultValue }) => {
  const toast = useToast();
  const defaultValueRef = useRef<typeof defaultValue>();

  const activeOrganization = useAuthStore((f) => f.activeOrganization);

  const formSync = useFormStore((f) => f.formSync);

  const [formState, setFormState] = useFormStore((f) => [
    f.fromStates[channel.id.toString()] ?? {
      sync: true,
    },
    f.setFormState,
  ]);

  const {
    main,
    addTwitterPost,
    scheduledAt,
    subscribe,
    unsubscribe,
    setForm,
    setProgressBlock,
    goToStep,
    setPlatformHasError,
    setGlobalError,
  } = useFormStore((s) => ({
    main: s.mainForms.main,
    setForm: s.setForm,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    scheduledAt: s.scheduledAt,
    addTwitterPost: s.addTwitterPost,
    setProgressBlock: s.setProgressBlock,
    setPlatformHasError: s.setPlatformHasError,
    goToStep: s.goToStep,
    setGlobalError: s.setGlobalError,
  }));

  const methods = useForm<twitterSchema>({
    resolver: zodResolver(twitterSchema),
  });
  const {
    getValues,
    trigger,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset(defaultValue);
    setFormState(channel.id.toString(), {
      sync: false,
    });
    defaultValueRef.current = defaultValue;
  }

  const draftForm = useCallback(async () => {
    const result = await trigger();

    if (!result) {
      setPlatformHasError(channel, true);
      return;
    }

    setPlatformHasError(channel, false);
    setForm(channel.id, getValues());
  }, [channel, getValues, setForm, setPlatformHasError, trigger]);

  const onSubmit: (
    status: APISchemas["StatusCf9Enum"]
  ) => SubmitHandler<twitterSchema> = useCallback(
    (status) => async (data) => {
      if (!activeOrganization) {
        return;
      }

      const coverUrls: Record<string, string> = {};
      if (!activeOrganization) return;
      data.files?.forEach((file) => {
        if (!file.data.video) {
          return;
        }
        if (!("coverUrl" in file.data.video)) {
          return;
        }
        if (
          !file.data.video.coverUrl ||
          typeof file.data.video.coverUrl !== "string"
        ) {
          return;
        }
        coverUrls[file.data.video.id] = file.data.video.coverUrl;
      });
      const body: APISchemas["TwitterPostCreateRequest"] = {
        body: data.content,
        channel: channel.id,
        status,
        metadata: {
          cover_url: coverUrls,
        },
        scheduled_at: scheduledAt.toISOString(),
        media: data.files
          ?.map((f) => f.data.image?.id || f.data.video?.id)
          .filter((m): m is string => !!m)
          .map((m) => Number(m)),
      };

      const { data: post, error } = defaultValueRef.current?.id
        ? await PATCH("/twitter/{org_pk}/post/{id}/", {
            params: {
              path: {
                id: defaultValueRef.current.id,
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          })
        : await POST("/twitter/{org_pk}/post/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          });

      if (error) {
        setProgressBlock(true);
        handleError(channel.id.toString(), error, setGlobalError);
        setPlatformHasError(channel, true);
        goToStep?.(1);
        return;
      }

      if (!post) {
        toast({
          status: "error",
          title: i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }

      setPlatformHasError(channel, false);
      addTwitterPost(post);
    },
    [
      activeOrganization,
      addTwitterPost,
      channel,
      goToStep,
      scheduledAt,
      setGlobalError,
      setPlatformHasError,
      setProgressBlock,
      toast,
    ]
  );

  useEffect(() => {
    if (main && formState.sync) {
      reset({
        content: main.content,
        files: main.files,
      });
    }
  }, [formState.sync, main, reset]);

  useEffect(() => {
    if (isDirty) {
      setFormState(channel.id.toString(), {
        sync: false,
      });
    }
  }, [channel.id, isDirty, setFormState]);

  useEffect(() => {
    const submitFn = handleSubmit(onSubmit("planned"));
    const draftFn = handleSubmit(onSubmit("draft"));
    subscribe("submit", submitFn);
    subscribe("check", draftForm);
    subscribe("draft", draftFn);

    return () => {
      unsubscribe("submit", submitFn);
      unsubscribe("check", draftForm);
      unsubscribe("draft", draftFn);
    };
  }, [handleSubmit, onSubmit, draftForm, subscribe, unsubscribe]);

  useEffect(() => {
    if (!formSync) {
      return;
    }

    let prev: twitterSchema = {};

    const interval = setInterval(() => {
      const values = getValues();

      if (isEqual(prev, values)) {
        return;
      }

      if (channel.hasError) {
        draftForm();
      }

      setForm(channel.id, values);
      prev = values;
    }, formUpdateMS);

    return () => {
      clearInterval(interval);
    };
  }, [channel, draftForm, formSync, getValues, setForm]);

  return (
    <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
      <form>
        <FormProvider {...methods}>
          <TwitterFormPresent channel={channel} />
        </FormProvider>
      </form>
    </Box>
  );
};
