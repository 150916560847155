import { FeedbackIcon } from "@/assets/icons/feedback-icon";
import { z } from "@/i18n";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Text,
  Input,
  Textarea,
  useDisclosure,
  VStack,
  FormErrorMessage,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";

import { FC, useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import type { UserFeedback } from "@sentry/types";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { colors } from "@/theme/colors";
import uuid4 from "uuid4";

export const FeedbackSchema = z.object({
  name: z.string().trim().min(1),
  email: z.string().trim().email(),
  description: z.string().trim().min(1),
});
export type FeedbackSchema = z.infer<typeof FeedbackSchema>;

export const TicketModal: FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const user = useAuthStore((state) => state.user);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<FeedbackSchema>({
    resolver: zodResolver(FeedbackSchema),
  });

  const onSubmit: SubmitHandler<FeedbackSchema> = useCallback(
    (data) => {
      const eventId =
        Sentry.lastEventId() ||
        Sentry.captureMessage("User Feedback - No Event ID - " + uuid4());

      const userFeedback: UserFeedback = {
        event_id: eventId,

        name: data.name,
        email: data.email,
        comments: data.description,
      };

      Sentry.captureUserFeedback(userFeedback);
      onClose();
      setValue("description", "");

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    },
    [onClose, setValue]
  );

  useEffect(() => {
    if (user) {
      const fullName =
        !user.first_name || !user.last_name
          ? undefined
          : user.first_name + " " + user.last_name;

      setValue("name", fullName?.trim() || user.username);
      setValue("email", user.email);
    } else {
      setValue("name", "");
      setValue("email", "");
    }
  }, [user, setValue]);

  return (
    <>
      <Button
        onClick={onOpen}
        hidden={isOpen}
        py="6"
        rounded="8"
        fontSize="sm"
        bg={showSuccess ? "#50cd8954" : "blue.brand-light"}
        border={showSuccess ? "1px solid" : undefined}
        borderColor={showSuccess ? "#50cd89" : undefined}
        color={showSuccess ? "#50cd89" : "black.dark"}
      >
        {showSuccess ? (
          t("feedback.successMessageText")
        ) : (
          <>
            <FeedbackIcon
              fill={colors.black.dark}
              width={20}
              height={20}
              style={{
                marginRight: "6px",
              }}
            />
            {t("feedback.buttonLabel")}
          </>
        )}
      </Button>

      <Box
        shadow="0px 0px 0.5px #BFBFBF"
        rounded="lg"
        border="1px solid"
        borderColor="gray.athens"
        p={8}
        w="300px"
        bg="white.normal"
        as="form"
        hidden={!isOpen}
        onSubmit={handleSubmit(onSubmit)}
        color="black.dark"
      >
        <VStack gap="4">
          <Text w="full" fontSize="xl" fontWeight="semibold">
            {t("feedback.formTitle")}
          </Text>
          <FormControl isInvalid={!!errors.name} hidden={!!user}>
            <FormLabel>
              {`${t("feedback.nameLabel")}`}{" "}
              <Text as="span" fontSize="sm">
                {t("feedback.isRequiredText")}
              </Text>
            </FormLabel>
            <Input
              border="1px solid"
              borderColor="gray.athens"
              placeholder={t("feedback.namePlaceholder")}
              _placeholder={{
                fontSize: "sm",
              }}
              {...register("name")}
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.email} hidden={!!user}>
            <FormLabel>
              {`${t("feedback.emailLabel")}`}{" "}
              <Text as="span" fontSize="sm">
                {t("feedback.isRequiredText")}
              </Text>
            </FormLabel>
            <Input
              border="1px solid"
              borderColor="gray.athens"
              placeholder={t("feedback.emailPlaceholder")}
              _placeholder={{
                fontSize: "sm",
              }}
              {...register("email")}
            />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.description}>
            <FormLabel>
              {`${t("feedback.messageLabel")}`}{" "}
              <Text as="span" fontSize="sm">
                {t("feedback.isRequiredText")}
              </Text>
            </FormLabel>
            <Textarea
              border="1px solid"
              borderColor="gray.athens"
              placeholder={t("feedback.messagePlaceholder")}
              _placeholder={{
                fontSize: "sm",
              }}
              {...register("description")}
            />
            <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
          </FormControl>
        </VStack>
        <VStack mt="4" gap="4">
          <Button type="submit" bg="red.main" color="white.normal" w="full">
            {t("feedback.submitButtonLabel")}
          </Button>
          <Button
            variant="outline"
            bg="white.normal"
            onClick={() => {
              onClose();
              clearErrors();
            }}
            w="full"
          >
            {t("feedback.cancelButtonLabel")}
          </Button>
        </VStack>
      </Box>
    </>
  );
};
