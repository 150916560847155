import { Heading, VStack } from "@chakra-ui/react";
import { WelcomeCardCompactIcon } from "@/assets/icons/welcome-card-compact-icon";
import { LogoWhite } from "@/assets/logo/logo-white";
import { useTranslation } from "react-i18next";
import { brandTitle } from "@/constant";

export const Welcome = () => {
  const { t } = useTranslation();
  return (
    <VStack
      w="full"
      h="full"
      textColor="white"
      textAlign="center"
      // px={{ base: 4, sm: 6, md: 6 }}
      //  py={{ base: 8, sm: 1, md: 12 }}
      bg="blue.shiny"
      //ml={{ base: 0, sm: 0, md: 0, lg: "0" }}
      overflow="hidden"
      transform="scale(1vw)"
    >
      <VStack mt="8.375rem" mb="5.875rem" gap="1.5rem">
        <LogoWhite width="7.625rem" height="7.625rem" />

        <Heading
          fontSize={{
            base: "1.875rem",
            sm: "1.875rem",
            md: "2.25rem",
            lg: "2.25rem",
          }}
          lineHeight="2.875rem"
          fontWeight="600"
        >
          {brandTitle}.io
        </Heading>
        <Heading
          fontSize={{
            base: "1.25rem",
            sm: "1.25rem",
            md: "1.375rem",
            lg: "1.375rem",
          }}
          lineHeight="25px"
          fontWeight="400"
        >
          {t("page.login.message.banner")}
        </Heading>
      </VStack>

      <WelcomeCardCompactIcon />
    </VStack>
  );
};
