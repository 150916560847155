import { FC } from "react";

export const ListCalendarIcon: FC = (
  props: React.SVGAttributes<SVGElement>
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="26"
      viewBox="0 0 20 26"
      fill="none"
      {...props}
    >
      <path
        d="M0.491698 23.3391L0.491699 8.97644C0.491699 7.68633 1.54131 6.63672 2.83301 6.63672C4.12312 6.63672 5.17274 7.68633 5.17274 8.97644L5.17273 23.339C5.17273 24.6292 4.12312 25.6788 2.83301 25.6788C1.54126 25.6788 0.491698 24.6292 0.491698 23.3391Z"
        fill="#3B5998"
      />
      <path
        d="M0.491699 2.59447C0.491699 1.30392 1.54166 0.253906 2.83222 0.253906C4.12277 0.253906 5.17274 1.30392 5.17274 2.59447C5.17274 3.88503 4.12277 4.93499 2.83222 4.93499C1.54166 4.93499 0.491699 3.88503 0.491699 2.59447Z"
        fill="#3B5998"
      />
      <path
        d="M7.67285 2.59447C7.67285 1.30392 8.72281 0.253906 10.0134 0.253906C11.3039 0.253906 12.3539 1.30392 12.3539 2.59447C12.3539 3.88503 11.3039 4.93499 10.0134 4.93499C8.72281 4.93499 7.67285 3.88503 7.67285 2.59447Z"
        fill="#3B5998"
      />
      <path
        d="M14.8525 2.59447C14.8525 1.30392 15.9025 0.253906 17.1931 0.253906C18.4836 0.253906 19.5336 1.30392 19.5336 2.59447C19.5336 3.88503 18.4836 4.93499 17.1931 4.93499C15.9025 4.93499 14.8525 3.88503 14.8525 2.59447Z"
        fill="#3B5998"
      />
      <path
        d="M7.67285 23.3391L7.67285 8.97644C7.67285 7.68633 8.72247 6.63672 10.0142 6.63672C11.3043 6.63672 12.3539 7.68633 12.3539 8.97644L12.3539 23.339C12.3539 24.6292 11.3043 25.6788 10.0142 25.6788C8.72242 25.6788 7.67285 24.6292 7.67285 23.3391Z"
        fill="#3B5998"
      />
      <path
        d="M14.8525 23.3391L14.8525 8.97644C14.8525 7.68633 15.9022 6.63672 17.1939 6.63672C18.484 6.63672 19.5336 7.68633 19.5336 8.97644L19.5336 23.339C19.5336 24.6292 18.484 25.6788 17.1938 25.6788C15.9022 25.6788 14.8525 24.6292 14.8525 23.3391Z"
        fill="#3B5998"
      />
    </svg>
  );
};
