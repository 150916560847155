import { ChevronFilledIcon } from "@/assets/icons/chevron-filled-icon";
import { TargetBoxIcon } from "@/assets/icons/target-box-icon";
import { Box, Text, Flex, IconButton } from "@chakra-ui/react";
import { ReactNode, useState, FC } from "react";
import { useTranslation } from "react-i18next";

interface DataStatsProps {
  icon?: ReactNode;
  title: string;
  data: number;
  goal: string;
  account: string;
  variation: number;
  increase?: boolean;
}

export const DataStats: FC<DataStatsProps> = ({
  icon,
  title,
  data,
  goal,
  account,
  variation,
  increase,
}) => {
  const [targetClicked, setTargetClicked] = useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <Box w="full" height="full" bgColor="white" p="10px" borderRadius="6px">
      <Flex w="full" alignItems="center" justifyContent="space-between">
        <Flex position="relative" right="1px" alignItems="center">
          {icon}
          <Text
            ml="12px"
            fontSize="16px"
            lineHeight="14px"
            fontWeight="600"
            color="black.active"
          >
            {title}
          </Text>
        </Flex>

        <IconButton
          size="none"
          variant="unstyled"
          cursor="pointer"
          aria-label="target"
          onClick={() => {
            setTargetClicked(!targetClicked);
          }}
        >
          <TargetBoxIcon reverse={!targetClicked} />
        </IconButton>
      </Flex>
      <Box mt="20px">
        <Flex>
          <Text
            fontWeight={600}
            fontSize="24px"
            lineHeight="14px"
            color="black.active"
          >
            {data}
          </Text>
          {targetClicked && (
            <Text
              fontWeight={600}
              fontSize="24px"
              lineHeight="14px"
              color="gray.smoke"
            >
              /{goal}
            </Text>
          )}
        </Flex>
        <Flex mt="4" alignItems="center">
          {targetClicked ? (
            <Text
              fontWeight="400"
              lineHeight="14px"
              fontSize="14px"
              color="gray.smoke"
            >
              {t("page.analytics.data-stats-subtitle")}
            </Text>
          ) : (
            <>
              <Text
                fontWeight="400"
                lineHeight="14px"
                fontSize="14px"
                color="gray.smoke"
              >
                {title}
              </Text>
              <Text
                ml="4px"
                fontWeight="400"
                lineHeight="14px"
                fontSize="14px"
                color="gray.smoke"
              >
                {t("page.analytics.you-get-from")}
              </Text>
            </>
          )}
        </Flex>
      </Box>

      <Flex alignItems="center" mt="9">
        {increase ? (
          <Box transform="rotate(180deg)">
            <ChevronFilledIcon fill="#50CD89" />
          </Box>
        ) : (
          <Box>
            <ChevronFilledIcon fill="#F13232" />
          </Box>
        )}
        <Text
          ml="2"
          fontWeight="600"
          lineHeight="14px"
          fontSize="16px"
          color={increase ? "green.label" : "red.main"}
        >
          {variation}%
        </Text>
        <Text
          ml="6px"
          fontWeight="400"
          lineHeight="14px"
          fontSize="12px"
          color="gray.smoke"
        >
          {t("page.analytics.than-last-update")}
        </Text>
      </Flex>
    </Box>
  );
};
