import { extendTheme } from "@chakra-ui/react";
import { colors } from "./colors";
import { components } from "./components";
import { typography } from "./typography";

const breakpoints = {
  sm: "30em",
  md: "48em",
  lg: "62em",
  "2lg": "70em",
  "3lg": "75em",
  xl: "80em",
  "mac-14": "87.5em",
  "2xl": "96em",
  standart: "120em",
  "3xl": "140em",
  "4xl": "150em",
  "4k": "256em",
};

export const theme = extendTheme({
  fonts: typography,
  components: components,

  semanticTokens: { colors },

  breakpoints: breakpoints,
});
