export const FacebookCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.0001 30.1842C23.2843 30.1842 30.0001 23.4685 30.0001 15.1842C30.0001 6.89993 23.2843 0.184204 15.0001 0.184204C6.71579 0.184204 6.10352e-05 6.89993 6.10352e-05 15.1842C6.10352e-05 23.4685 6.71579 30.1842 15.0001 30.1842Z"
        fill="#3A559F"
      />
      <path
        d="M19.4962 13.479L19.2107 15.9876C19.1989 16.102 19.146 16.2081 19.0622 16.2856C18.9783 16.3631 18.8694 16.4067 18.7559 16.4081H16.1755L16.1626 23.8454C16.1635 23.9335 16.1301 24.0185 16.0698 24.0818C16.0094 24.1452 15.9269 24.182 15.84 24.1841H13.2257C13.1822 24.1841 13.1392 24.1753 13.0992 24.158C13.0592 24.1408 13.0231 24.1155 12.9929 24.0837C12.9628 24.0519 12.9393 24.0142 12.9239 23.973C12.9085 23.9318 12.9014 23.8878 12.9032 23.8438V16.4081H10.9678C10.907 16.4075 10.847 16.3947 10.791 16.3705C10.7351 16.3463 10.6845 16.3112 10.6419 16.2671C10.5994 16.223 10.5658 16.1709 10.5432 16.1137C10.5205 16.0564 10.5091 15.9952 10.5098 15.9336L10.5001 13.425C10.4993 13.3631 10.5104 13.3017 10.533 13.2442C10.5555 13.1867 10.589 13.1342 10.6316 13.0899C10.6741 13.0455 10.7248 13.01 10.7809 12.9856C10.8369 12.9611 10.8971 12.9481 10.9581 12.9472H12.9032V10.5254C12.9032 7.71411 14.5482 6.18411 16.9609 6.18411H18.9382C18.9993 6.18475 19.0597 6.19763 19.1159 6.22201C19.1722 6.24639 19.2231 6.28179 19.2658 6.32619C19.3085 6.37058 19.3421 6.42309 19.3648 6.4807C19.3875 6.53832 19.3987 6.5999 19.3978 6.66193V8.77284C19.3987 8.83487 19.3875 8.89645 19.3648 8.95407C19.3421 9.01168 19.3085 9.06419 19.2658 9.10858C19.2231 9.15298 19.1722 9.18838 19.1159 9.21276C19.0597 9.23714 18.9993 9.25002 18.9382 9.25066H17.7254C16.4142 9.25066 16.1594 9.9052 16.1594 10.851V12.9472H19.0414C19.1069 12.948 19.1716 12.9629 19.231 12.9909C19.2905 13.0189 19.3434 13.0593 19.3864 13.1096C19.4294 13.1598 19.4614 13.2187 19.4803 13.2824C19.4992 13.3461 19.5046 13.4131 19.4962 13.479Z"
        fill="white"
      />
    </svg>
  );
};
