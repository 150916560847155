export const FilterBox = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.619141"
        y="0.5"
        width="31.0336"
        height="31.0336"
        rx="2.5"
        fill="#F7F8FA"
        stroke="#EBEDF2"
      />
      <path
        d="M25.0581 8.00842H8.81594C8.43579 8.00842 8.12769 8.31651 8.12769 8.69665C8.12769 10.6132 8.94929 12.4429 10.3819 13.7162L13.3961 16.3952C13.9187 16.8598 14.2185 17.5273 14.2185 18.2267V24.9379C14.2185 25.4864 14.8315 25.8151 15.2884 25.5104L19.349 22.8036C19.5404 22.6759 19.6555 22.4611 19.6555 22.231V18.2267C19.6555 17.5273 19.9553 16.8598 20.4779 16.3952L23.492 13.7162C24.9246 12.4429 25.7462 10.6132 25.7462 8.69665C25.7462 8.31651 25.4381 8.00842 25.0581 8.00842ZM22.5775 12.6874L19.5634 15.3665C18.7472 16.0921 18.279 17.1345 18.279 18.2265V21.8627L15.5949 23.6519V18.2267C15.5949 17.1345 15.1267 16.0921 14.3105 15.3665L11.2964 12.6875C10.3304 11.8287 9.71377 10.652 9.5487 9.38474H24.3252C24.1601 10.652 23.5436 11.8287 22.5775 12.6874Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
