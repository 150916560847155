import { Center, Spinner, useToast } from "@chakra-ui/react";
import { FC } from "react";
import { useQuery } from "react-query";
import { POST } from "@/api";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Activate: FC = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const toast = useToast();
  const token = searchParams.get("token");
  const { t } = useTranslation();
  useQuery("activate", async () => {
    if (!token) {
      toast({
        status: "error",
        title: t("errors.unexpected-error-contact-customer-service"),
      });
      navigate("/auth/login");
      return;
    }

    const { error, response } = await POST("/auth/activate/", {
      body: {
        token,
      },
    });

    if (response.status === 200) {
      toast({
        status: "success",
        title: t("errors.your-account-has-been-activated-successfully"),
      });
      navigate("/auth/login");
      return;
    }

    if (error?.detail) {
      toast({
        status: "error",
        title: error.detail,
      });
      navigate("/auth/login");
      return;
    }

    if (error?.issues) {
      const expected = error.issues.find((e) => e.path === "token");
      if (expected) {
        toast({
          status: "error",
          title: expected.message,
        });
      }
      navigate("/auth/login");
      return;
    }

    toast({
      status: "error",
      title: t(
        "errors.unexpected-error-occurred-please-contact-with-customer-service"
      ),
    });
    navigate("/auth/login");
  });

  return (
    <Center>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};
