export const LineIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="4"
      height="36"
      viewBox="0 0 4 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="4" height="36" rx="2" fill="#F6F8FA" />
    </svg>
  );
};
