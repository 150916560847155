import React, { useCallback, useMemo } from "react";
import { Flex, Box, Text, Image } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { APISchemas } from "@/api";
import { ComposeCircleIcon } from "@/assets/brands/circle/compose-circle-icon";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { useChannel } from "@/hooks/use-channels";

interface NotificationItemProps {
  data?: APISchemas["Notification"];
  modalItem?: APISchemas["Compose"];
  handleDelete?: (id: number) => void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({ data }) => {
  const date = data?.created_at;
  const hour = date && format(parseISO(date), "HH:mm");

  const parseChannelName = useCallback(
    (model_type: string | null | undefined) => {
      if (!model_type) {
        return;
      }

      if (model_type.includes("instagram")) {
        return "instagram";
      }

      if (model_type.includes("twitter")) {
        return "twitter";
      }

      if (model_type.includes("facebook")) {
        return "facebook";
      }

      if (model_type.includes("linkedin")) {
        return "linkedin";
      }
      if (model_type.includes("youtube")) {
        return "youtube";
      }
      if (model_type.includes("telegram")) {
        return "telegram";
      }
      if (model_type.includes("tiktok")) {
        return "tiktok";
      }
    },
    []
  );
  const notificationMessage = data?.given_object?.body?.toString() || "";

  const channels = useChannel();
  const channel_type = data?.given_object?.channel_type
    ? parseChannelName(data?.given_object?.channel_type.toString())
    : parseChannelName(data?.model_type);

  const channel = channels?.data?.find(
    (channel: { id: number }) => channel.id === data?.given_object?.channel
  );

  const image = useMemo(() => {
    const media = Array.isArray(data?.given_object?.media)
      ? data?.given_object?.media
      : undefined;

    if (!media) {
      return;
    }

    const firstMedia = media.at(0);

    if (firstMedia?.type === "image") {
      return firstMedia.url;
    }

    return firstMedia?.download_url?.endsWith("original")
      ? firstMedia?.download_url?.replace("original", "preview.webp")
      : undefined;
  }, [data]);

  return (
    <Flex
      alignItems="center"
      w="full"
      h="96px"
      justifyContent="space-between"
      borderRadius="3px"
      border="1px solid"
      borderColor="gray.athens"
      bg="white"
      cursor="pointer"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
      mx="6px"
    >
      <Flex alignItems="center" w="full" overflow="hidden" h="full">
        <Flex
          w="50px"
          h="50px"
          alignItems="center"
          justifyContent="center"
          ml="2"
        >
          {channel_type !== "instagram" &&
          channel_type !== "twitter" &&
          channel_type !== "facebook" &&
          channel_type !== "youtube" &&
          channel_type !== "telegram" &&
          channel_type !== "tiktok" &&
          channel_type !== "linkedin" ? (
            <Box justifyContent="center" alignItems="center">
              <ComposeCircleIcon width="40px" height="40px" />
              <Text
                fontSize="13px"
                color="black.active"
                position="relative"
                top="5px"
              >
                {hour}
              </Text>
            </Box>
          ) : (
            <Box justifyContent="center" alignItems="center">
              <AvatarWithBadge
                badgeSize="20px"
                picture={channel?.picture ?? undefined}
                name={channel?.username ?? undefined}
                channel={channel_type}
                w="40px"
                h="40px"
              />
              <Text
                fontSize="13px"
                color="black.active"
                position="relative"
                top="5px"
              >
                {hour}
              </Text>
            </Box>
          )}
        </Flex>

        <Box
          h="100%"
          ml="10px"
          position="relative"
          display="flex"
          flexDir="column"
        >
          <Text
            textAlign="start"
            color="black.active"
            fontSize="12px"
            fontWeight="400"
            noOfLines={1}
            textOverflow="ellipsis"
            pos="relative"
            top="6px"
          >
            {channel
              ? channel?.username
                ? channel.username.startsWith("@")
                  ? channel.username
                  : `@${channel.username}`
                : channel?.name
              : "@postEffect"}
          </Text>
          <Flex flexDir="column" h="full" justifyContent="center">
            <Text
              textAlign="start"
              color="black.active"
              fontSize="16px"
              fontWeight="500"
              noOfLines={1}
              textOverflow="ellipsis"
            >
              {data?.message}
            </Text>
            <Text
              position="relative"
              top="2px"
              textAlign="start"
              color="gray.smoke"
              fontSize="16px"
              fontWeight="600"
              noOfLines={2}
              textOverflow="ellipsis"
            >
              {notificationMessage}
            </Text>
          </Flex>
        </Box>
      </Flex>

      {image && (
        <Box
          display="flex"
          h="full"
          alignItems="center"
          justifyContent="center"
          mx="3"
        >
          <Image
            w="80px"
            h="80px"
            objectFit="cover"
            borderRadius="3px"
            src={image}
          />
        </Box>
      )}
    </Flex>
  );
};
