import {
  Box,
  Flex,
  Text,
  Button,
  Avatar,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  Link,
  AlertDialogHeader,
  AlertDialogContent,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  AlertDialogOverlay,
  Tooltip,
  IconButton,
  Badge,
  Image,
} from "@chakra-ui/react";
import React, { useCallback, FC, useState, useRef, useMemo } from "react";
import { LinkIcon } from "@/assets/icons/link-icon";
import Slider from "react-slick";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Card,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { APISchemas } from "@/api";
import { parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { SmartImage } from "@/components/smart-image";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import { AvatarInfoBox } from "@/components/avatar/avatar-with-infoBox";
import { i18nformat } from "@/utils/misc";
import { useTranslation } from "react-i18next";
import { useChannel } from "@/hooks/use-channels";
import { toPng } from "html-to-image";
import { YoutubeIcon } from "@/assets/brands";

interface PostModalProps {
  data: APISchemas["Compose"];
  isOpen: boolean;
  close: () => void;
  onDelete: (id: number) => void;
}

export const PostModal: React.FC<PostModalProps> = ({
  data,
  close,
  isOpen,
  onDelete,
}) => {
  const [activeChannelData, setActiveChannelData] = useState<
    | APISchemas["Compose"]
    | APISchemas["TwitterPost"]
    | APISchemas["FacebookPost"]
    | APISchemas["LinkedinPost"]
    | APISchemas["InstagramPost"]
    | APISchemas["TelegramPost"]
    | APISchemas["YoutubePost"]
    | APISchemas["TiktokPost"]
    | undefined
  >(data);
  const propData = useRef<APISchemas["Compose"]>(data);
  if (propData.current !== data) {
    setActiveChannelData(data);
    propData.current = data;
  }
  const [channelSelected, setChannelSelected] = useState<boolean>(false);
  const { isOpen: open, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const formatDatetime = useCallback((time: string): string => {
    const parsedDate = parseISO(time);
    const formattedDate = i18nformat(parsedDate, "MMM d, yyyy h:mm a");
    return formattedDate;
  }, []);
  const downloadRef = useRef<HTMLDivElement | null>(null);

  const { data: Channels } = useChannel();

  const postCount =
    data.facebook_posts?.length +
    data.instagram_posts?.length +
    data.linkedin_posts?.length +
    data.twitter_posts?.length +
    data.youtube_posts?.length +
    data.tiktok_posts?.length +
    data.telegram_posts?.length;

  const postedCount =
    data.facebook_posts.filter((item) => item.status === "posted").length +
      data.linkedin_posts.filter((item) => item.status === "posted").length +
      data.instagram_posts.filter((item) => item.status === "posted").length +
      data.twitter_posts.filter((item) => item.status === "posted").length +
      data.youtube_posts.filter((item) => item.status === "posted").length +
      data.tiktok_posts?.filter((item) => item.status === "posted").length ??
    0 + data.telegram_posts.filter((item) => item.status === "posted").length;

  const failedCount =
    data.facebook_posts.filter((item) => item.status === "failed").length +
      data.linkedin_posts.filter((item) => item.status === "failed").length +
      data.instagram_posts.filter((item) => item.status === "failed").length +
      data.twitter_posts.filter((item) => item.status === "failed").length +
      data.youtube_posts.filter((item) => item.status === "failed").length +
      data.tiktok_posts?.filter((item) => item.status === "failed").length ??
    0 + data.telegram_posts.filter((item) => item.status === "failed").length;

  const facebook = data.facebook_posts?.map((item) => item.channel);
  const linkedin = data.linkedin_posts?.map((item) => item.channel);
  const twitter = data.twitter_posts?.map((item) => item.channel);
  const instagram = data.instagram_posts?.map((item) => item.channel);
  const youtube = data.youtube_posts?.map((item) => item.channel);
  const tiktok = data.tiktok_posts?.map((item) => item.channel);
  const telegram = data.telegram_posts?.map((item) => item.channel);

  const postList = twitter
    .concat(facebook)
    .concat(instagram)
    .concat(linkedin)
    .concat(youtube)
    .concat(tiktok)
    .concat(telegram);

  const composePost = useMemo(() => {
    return [
      ...data.twitter_posts.map((t) => ({
        ...t,
        href: `https://twitter.com/${
          Channels?.find((c) => c.id === t.channel)?.username
        }/status/${t.post_id}`,
      })),
      ...data.facebook_posts.map((f) => ({
        ...f,
        href: `https://www.facebook.com/${f.post_id}`,
      })),
      ...data.instagram_posts.map((i) => ({ ...i, href: i.permalink })),
      ...data.linkedin_posts.map((l) => ({
        ...l,
        href: `https://www.linkedin.com/feed/update/${l.post_id}`,
      })),
      ...data.telegram_posts.map((te) => ({
        ...te,
        href: `https://t.me/${Channels?.find((c) => c.id === te.channel)?.username}/${te.post_id}`,
      })),
      ...data.youtube_posts.map((yt) => ({
        ...yt,
        href: `https://www.youtube.com/watch?v=${yt.post_id}`,
      })),
    ];
  }, [data, Channels]);

  const posted = useMemo(() => {
    return !!composePost.find((c) => c.status === "posted");
  }, [composePost]);

  const draft = useMemo(() => {
    return data.status === "draft";
  }, [data]);

  const avatars = Channels?.filter((channel) => postList.includes(channel.id));

  const maxAvatars = 9;
  const avatarsToDisplay = avatars?.slice(0, maxAvatars);

  const hiddenCount = Math.max((avatars?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = avatars?.slice(maxAvatars, avatars.length);

  const handleChannelChange = useCallback(
    (item: number) => {
      const choosenPost = composePost.find((post) => post.channel === item);
      setActiveChannelData(choosenPost);

      if (
        activeChannelData &&
        "channel" in activeChannelData &&
        activeChannelData.channel === item
      ) {
        setChannelSelected((prev) => !prev);
      } else {
        setChannelSelected(true);
      }
    },
    [activeChannelData, setActiveChannelData, setChannelSelected, composePost]
  );

  const avatarStatus = avatars?.map((avatar) => {
    const correspondingItem = composePost.find(
      (item) => item.channel === avatar.id
    );

    return correspondingItem?.status;
  });

  const settings = {
    dots: true,
    nextArrow: <Arrow />,
    prevArrow: <Arrow direction="prev" />,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const captureScreenshot = useCallback(() => {
    if (downloadRef.current === null) {
      return;
    }

    toPng(downloadRef.current, { cacheBust: true }).then((dataUrl) => {
      const link = document.createElement("a");
      const postText = data?.body
        ?.substring(0, 15)
        .replace(/[^a-z0-9]/gi, "_")
        .toLowerCase();
      link.download = `post${postText ? "-" : ""}${postText}.png`;
      link.href = dataUrl;
      link.click();
    });
  }, [downloadRef]);

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      onClose={close}
      isCentered
      closeOnOverlayClick={true}
      blockScrollOnMount={false}
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent boxShadow="md" w="full" bgColor="white.normal">
        <ModalHeader
          pos="relative"
          justifyContent="flex-start"
          fontWeight="600"
          fontSize="14px"
        >
          {!draft ? (
            <Badge
              borderRadius="3px"
              overflow="hidden"
              zIndex="99"
              pos="absolute"
              w="140px"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              fontSize="15px"
              fontWeight="500"
              h="32px"
              top="0"
              left="0"
              bg={
                failedCount > 0
                  ? "#E52625"
                  : postedCount > 0
                    ? "#4CD137"
                    : "#FBC531"
              }
              color="white"
              textTransform="none"
            >
              <Text
                position="relative"
                top="4px"
                textAlign="center"
                w="full"
                height="full"
              >
                {failedCount > 0
                  ? t("rejected")
                  : `${postedCount} / ${postCount} ${t("posted")} `}
              </Text>
            </Badge>
          ) : (
            <Box
              top="0"
              left="0"
              pos="absolute"
              w="100px"
              height="32px"
              bgColor="yellow.label"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              borderRadius="3px"
            >
              <Text
                color="white"
                fontSize="16px"
                textAlign="center"
                pos="relative"
                top="3px"
              >
                {t("draft")}
              </Text>
            </Box>
          )}
        </ModalHeader>

        <ModalCloseButton
          onClick={close}
          pos="absolute"
          right="10px"
          top="1px"
          size="md"
          variant="unstyled"
        />

        <ModalBody p="0" ref={downloadRef}>
          <Card w="full" borderRadius="0px" variant="unstyled" p="5">
            <Slider {...settings}>
              {activeChannelData?.media?.map((file, index) => (
                <React.Fragment key={index}>
                  {file.type === "image" ? (
                    <Flex w="full" height="full" position="relative">
                      <SmartImage imageUrl={file.url} h="324px" />
                    </Flex>
                  ) : file.type === "video" ? (
                    <>
                      <Flex w="full" height="324px" position="relative">
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          zIndex="1"
                        >
                          <BunnyPlayer src={data.media[0]?.url} />
                        </Box>
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          width="100%"
                          height="100%"
                          zIndex="0"
                          overflow="hidden"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <IconButton
                            position="absolute"
                            variant="unstyled"
                            aria-label="video-play"
                            icon={
                              <YoutubeIcon
                                fill="#fe8563"
                                width="50px"
                                height="50px"
                              />
                            }
                          />
                          <Image
                            objectFit="cover"
                            h="324px"
                            w="full"
                            src={data.media[0]?.download_url.replace(
                              "original",
                              "/thumbnail.jpg"
                            )}
                          />
                        </Box>
                      </Flex>
                    </>
                  ) : null}
                </React.Fragment>
              ))}
            </Slider>
            <CardBody mt="7">
              <Stack mt="0" spacing="3">
                <Box
                  pb="10"
                  px="3"
                  pt="2"
                  bg="#F7F8FA"
                  borderRadius="8px"
                  borderTopLeftRadius="0px"
                  color="black.active"
                >
                  <Text
                    fontSize="15px"
                    fontWeight="500"
                    color="black.active"
                    lineHeight="28px"
                  >
                    {activeChannelData?.body}
                  </Text>
                </Box>

                <Text
                  color="black.active"
                  fontSize="13px"
                  fontWeight="700"
                  lineHeight="28px"
                >
                  {t("post-modal.accounts")}
                </Text>

                <Flex columnGap="2">
                  {avatarsToDisplay?.map((avatar, index) => (
                    <AvatarInfoBox
                      active={
                        !channelSelected ||
                        (activeChannelData &&
                          "channel" in activeChannelData &&
                          avatar.id === activeChannelData?.channel) ||
                        false
                      }
                      href={
                        composePost.find((p) => p.channel === avatar.id)
                          ?.href ?? undefined
                      }
                      key={avatar.id}
                      status={avatarStatus && avatarStatus[index]}
                      avatar={avatar}
                      onPress={() => handleChannelChange(avatar.id)}
                    />
                  ))}

                  {hiddenCount > 0 && (
                    <Popover placement="bottom-start" trigger="hover">
                      <PopoverTrigger>
                        <Button size="none" variant="unstyled" cursor="pointer">
                          <Avatar
                            w="35px"
                            h="35px"
                            fontWeight="600"
                            color="#487EB0"
                            shadow="0 0 10px rgba(0, 0, 0, 0.1)"
                            bg="white.bg"
                            border="1px solid #487EB0"
                            name={"+ " + hiddenCount}
                          />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent w="270px">
                        <PopoverBody p="0px" bgColor="white.bg">
                          {hiddenAvatars?.map((avatar, index) => (
                            <Flex
                              key={avatar.id}
                              pr="3"
                              py="1"
                              w="full"
                              h="full"
                              alignItems="center"
                              onClick={() => handleChannelChange(avatar.id)}
                            >
                              <AvatarWithBadge
                                opacity={
                                  !channelSelected ||
                                  (activeChannelData &&
                                    "channel" in activeChannelData &&
                                    avatar.id === activeChannelData?.channel)
                                    ? "1"
                                    : "0.5"
                                }
                                status={avatarStatus && avatarStatus[index]}
                                badgeSize="15px"
                                picture={avatar.picture ?? undefined}
                                channel={avatar.channel_type}
                                channel_id={avatar.id.toString()}
                                tooltip={false}
                                w="30px"
                                h="30px"
                                key={avatar.id}
                                email={
                                  avatar.username
                                    ? avatar.username.startsWith("@")
                                      ? avatar.username
                                      : `@${avatar.username}`
                                    : avatar.name
                                }
                              />
                              <Text
                                w="full"
                                key={avatar.id}
                                fontSize="12px"
                                ml="2"
                                whiteSpace="nowrap"
                                textOverflow="ellipsis"
                                overflow="hidden"
                              >
                                {avatar.username
                                  ? avatar.username.startsWith("@")
                                    ? avatar.username
                                    : `@${avatar.username}`
                                  : avatar.name}
                              </Text>
                              <Flex
                                justifyContent="flex-end"
                                alignItems="flex-end"
                                pos="relative"
                                left="2"
                              >
                                {avatarStatus &&
                                  avatarStatus[index] === "posted" &&
                                  !!composePost.find(
                                    (p) => p.channel === avatar.id
                                  )?.href && (
                                    <Link
                                      alignItems="flex-end"
                                      justifyContent="flex-end"
                                      href={
                                        composePost.find(
                                          (p) => p.channel === avatar.id
                                        )?.href ?? undefined
                                      }
                                      isExternal
                                    >
                                      <LinkIcon />
                                    </Link>
                                  )}
                                <Box
                                  w="65px"
                                  h="17px"
                                  ml="3"
                                  color="white"
                                  fontSize="13px"
                                  fontWeight="300"
                                  borderRadius="2px"
                                  bgColor={
                                    avatarStatus?.[index] === "posted"
                                      ? "#50CD89"
                                      : avatarStatus?.[index] === "draft"
                                        ? "#FBC531"
                                        : avatarStatus?.[index] === "failed"
                                          ? "#E52625"
                                          : avatarStatus?.[index] === "planned"
                                            ? "#50CD89"
                                            : avatarStatus?.[index] ===
                                                "cancelled"
                                              ? "#E52625"
                                              : avatarStatus?.[index] ===
                                                  "deleted"
                                                ? "#E52625"
                                                : avatarStatus?.[index] ===
                                                    "prepared"
                                                  ? "#E52625"
                                                  : "orange"
                                  }
                                  textTransform="capitalize"
                                >
                                  {avatarStatus?.[index]}
                                </Box>
                              </Flex>
                            </Flex>
                          ))}
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  )}
                </Flex>

                <Text
                  color="black.active"
                  fontSize="13px"
                  fontWeight="700"
                  lineHeight="28px"
                >
                  {t("post-modal.planned-date")}
                </Text>
                <Text color="black.active" fontSize="13px" fontWeight="400">
                  {data?.scheduled_at && formatDatetime(data?.scheduled_at)}
                </Text>
                <Text
                  color="black.active"
                  fontSize="13px"
                  fontWeight="700"
                  lineHeight="28px"
                >
                  {t("post-modal.created-by")}
                </Text>
                <Text color="black.active" fontSize="13px" fontWeight="400">
                  {data.owner.email}
                </Text>
              </Stack>
            </CardBody>
            <CardFooter alignItems="flex-end" justifyContent="flex-end">
              <Flex mt="8" gap="3">
                <Button
                  w="130px"
                  h="35px"
                  bg="black.active"
                  color="white"
                  fontSize="13px"
                  borderRadius="4px"
                  _hover={{ opacity: "0.8" }}
                  onClick={captureScreenshot}
                >
                  {t("download")}
                </Button>
                {!posted && (
                  <Tooltip
                    label={!posted ? "" : t("dev.read-only")}
                    placement="top"
                    width="120px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      w="130px"
                      h="35px"
                      bg="black.active"
                      color="white"
                      fontSize="13px"
                      borderRadius="4px"
                      _hover={{ bgColor: "black.active" }}
                      onClick={onOpen}
                      isDisabled={posted}
                    >
                      {t("post-modal.delete-posted")}
                    </Button>
                  </Tooltip>
                )}
                <AlertDialog
                  leastDestructiveRef={cancelRef}
                  onClose={onClose}
                  isOpen={open}
                  isCentered
                >
                  <AlertDialogOverlay>
                    <AlertDialogContent bg="white.bg">
                      <AlertDialogHeader fontSize="lg" fontWeight="bold">
                        {t("alert.remove-modal.you-are-deleting-this-post")}
                      </AlertDialogHeader>

                      <AlertDialogBody>
                        {t("alert.account-status-update.description-active")}{" "}
                        {t("you_wont_be_able_to_revert_this")}
                      </AlertDialogBody>

                      <AlertDialogFooter>
                        <Button onClick={onClose}>{t("cancel")}</Button>
                        <Button
                          colorScheme="red"
                          onClick={() => {
                            onDelete(data.id);
                            onClose();
                            close();
                          }}
                          ml={3}
                        >
                          {t("delete")}
                        </Button>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialogOverlay>
                </AlertDialog>
                {!posted && (
                  <Tooltip
                    label={!posted ? "" : t("dev.read-only")}
                    placement="top"
                    width="120px"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Button
                      w="130px"
                      h="35px"
                      bg="green.label"
                      color="white"
                      fontSize="13px"
                      borderRadius="4px"
                      onClick={() => navigate(`/post/${data.id}`)}
                    >
                      {t("post-modal.update-post")}
                    </Button>
                  </Tooltip>
                )}
              </Flex>
            </CardFooter>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const Arrow: FC<{
  className?: string;
  style?: React.StyleHTMLAttributes<HTMLDivElement>;
  onClick?: () => void;
  direction?: "next" | "prev";
}> = ({ direction = "next", onClick, style, className }) => {
  return (
    <Box
      style={{ ...style, zIndex: 1 }}
      _before={{ content: "none" }}
      left={direction === "prev" ? "1.5" : undefined}
      right={direction === "next" ? "7" : undefined}
      className={className}
    >
      <Button
        variant="unstyled"
        rounded="full"
        bg="##B7BABF"
        w="42px"
        h="42px"
        px="2"
        maxW="min-content"
        transform={direction === "prev" ? "rotate(180deg);" : undefined}
        onClick={onClick}
      >
        <svg
          width="35"
          height="35"
          viewBox="0 0 46 46"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="23" cy="23" r="23" fill="#B7BABF" />
          <path
            d="M27.1114 22.893L19.4668 15.2509C19.1313 14.9164 18.5879 14.9164 18.2516 15.2509C17.9161 15.5855 17.9161 16.129 18.2516 16.4636L25.2898 23.4993L18.2524 30.535C17.917 30.8696 17.917 31.4131 18.2524 31.7485C18.5879 32.0831 19.1322 32.0831 19.4676 31.7485L27.1122 24.1064C27.4426 23.7753 27.4426 23.2233 27.1114 22.893Z"
            fill="white"
          />
        </svg>
      </Button>
    </Box>
  );
};
