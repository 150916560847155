import createClient, { Middleware } from "openapi-fetch";
import { paths, components } from "./pub.schema";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useConfigStore } from "@/context/config-store/config-store";
import { useSessionStore } from "@/context/time-store/session-store";
import { addMilliseconds, isBefore } from "date-fns";

const env = import.meta.env;

export const { GET, POST, PATCH, DELETE, use } = createClient<paths>({
  baseUrl: env.VITE_API_BASE_URL,
});

export type APISchemas = components["schemas"];

const requests: Array<{ url: string; method: string; timestamp: Date }> = [];

const add = (url: string, method: string) => {
  requests.push({ url, method, timestamp: addMilliseconds(Date.now(), 1000) });

  if (requests.length > 10) {
    requests.shift();
  }
};

const remove = (url: string) => {
  const reqIndex = requests.findIndex((req) => req.url === url);
  if (reqIndex === -1) {
    return;
  }

  requests.splice(reqIndex, 1);
};

const get = (url: string, method: string) => {
  const reqIndex = requests.findIndex(
    (req) => req.url === url && req.method === method
  );
  if (reqIndex === -1) {
    return;
  }

  const req = requests[reqIndex];

  if (isBefore(Date.now(), req.timestamp)) {
    return req;
  }

  requests.splice(reqIndex, 1);
};

const authMiddleware: Middleware = {
  onRequest(req) {
    // if (get(req.url, req.method)) {
    //   throw new Error("Request already made");
    // }

    // add(req.url, req.method);

    const { token } = useAuthStore.getState();
    const { language } = useConfigStore.getState();
    if (token) {
      req.headers.set("Authorization", `Token ${token}`);
    }

    if (language) {
      req.headers.set("Accept-Language", language);
    }

    return req;
  },
  onResponse(res) {
    // remove(res.url);
    if (res.status === 401 && !res.url.includes("logout")) {
      useAuthStore.getState().logout();
      return res;
    }

    useSessionStore.getState().madeAction();

    return res;
  },
};

use(authMiddleware);
