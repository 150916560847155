import React from "react";

export const HorizontalDirection = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.6953 0C0.784416 0 0.043457 0.629489 0.043457 1.40324C0.043457 2.17699 0.784109 2.80643 1.6953 2.80643C2.60648 2.80643 3.34713 2.17699 3.34713 1.40324C3.34713 0.629489 2.60612 0 1.6953 0Z"
        fill={props.color}
      />
      <path
        d="M7.04149 0C6.13066 0 5.38965 0.629489 5.38965 1.40324C5.38965 2.17699 6.13066 2.80643 7.04149 2.80643C7.95237 2.80643 8.69348 2.17699 8.69348 1.40324C8.69348 0.629489 7.95267 0 7.04149 0Z"
        fill={props.color}
      />
      <path
        d="M12.4741 2.80648C13.3848 2.80648 14.1259 2.17703 14.1259 1.40328C14.1259 0.629532 13.3854 0 12.4741 0C11.5628 0 10.8223 0.629489 10.8223 1.40324C10.8223 2.17699 11.5634 2.80648 12.4741 2.80648Z"
        fill={props.color}
      />
      <path
        d="M1.65214 7.30266C2.56297 7.30266 3.30414 6.67334 3.30414 5.89946C3.30414 5.12554 2.56297 4.49609 1.65214 4.49609C0.741317 4.49609 0 5.12558 0 5.89946C0 6.67334 0.741317 7.30266 1.65214 7.30266Z"
        fill={props.color}
      />
      <path
        d="M6.99901 7.30266C7.90968 7.30266 8.65084 6.67334 8.65084 5.89946C8.65084 5.12554 7.91029 4.49609 6.99901 4.49609C6.08787 4.49609 5.34717 5.12554 5.34717 5.89946C5.34717 6.67334 6.08818 7.30266 6.99901 7.30266Z"
        fill={props.color}
      />
      <path
        d="M12.4308 7.30266C13.3418 7.30266 14.0826 6.67334 14.0826 5.89946C14.0826 5.12554 13.3418 4.49609 12.4308 4.49609C11.5197 4.49609 10.7783 5.12554 10.7783 5.89946C10.7783 6.67334 11.5195 7.30266 12.4308 7.30266Z"
        fill={props.color}
      />
      <path
        d="M1.69023 8.98047C0.779403 8.98047 0.0380859 9.60978 0.0380859 10.3837C0.0380859 11.1573 0.779403 11.7869 1.69023 11.7869C2.60106 11.7869 3.34202 11.1576 3.34202 10.3837C3.34202 9.60978 2.60106 8.98047 1.69023 8.98047Z"
        fill={props.color}
      />
      <path
        d="M7.03593 8.98047C6.12505 8.98047 5.38379 9.60978 5.38379 10.3837C5.38379 11.1573 6.1248 11.7869 7.03593 11.7869C7.94661 11.7869 8.68772 11.1576 8.68772 10.3837C8.68772 9.60978 7.94661 8.98047 7.03593 8.98047Z"
        fill={props.color}
      />
      <path
        d="M12.4687 8.98047C11.5581 8.98047 10.8169 9.60978 10.8169 10.3837C10.8169 11.1573 11.5581 11.7869 12.4687 11.7869C13.3798 11.7869 14.1206 11.1576 14.1206 10.3837C14.1206 9.60978 13.3794 8.98047 12.4687 8.98047Z"
        fill={props.color}
      />
    </svg>
  );
};
