import React from "react";

export const LogoBlue = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.9712 36C28.9061 36 36.9599 27.9411 36.9599 18C36.9599 8.05887 28.9061 0 18.9712 0C9.03627 0 0.982422 8.05887 0.982422 18C0.982422 27.9411 9.03627 36 18.9712 36Z"
        fill="#57AAEC"
      />
      <path
        d="M13.6993 25.7656V26.597C13.6993 27.1385 13.694 27.6811 13.7034 28.2232C13.7058 28.3947 13.648 28.4724 13.4796 28.493C12.0064 28.6733 10.6656 28.3746 9.50974 27.3995C8.82964 26.8262 8.31675 26.1315 8.02587 25.2913C7.4971 23.763 7.67319 22.3129 8.55231 20.9495C9.04281 20.1877 9.70582 19.6262 10.5096 19.2232C11.182 18.8856 11.8986 18.733 12.647 18.7318C15.2396 18.7265 17.834 18.7277 20.4266 18.7241C21.0396 18.7241 21.6544 18.7371 22.2644 18.6976C23.3967 18.624 24.3377 18.1326 25.1031 17.2995C25.732 16.6154 26.0924 15.7988 26.1948 14.8867C26.3079 13.8798 26.113 12.9164 25.5448 12.0638C24.7963 10.9408 23.7624 10.2509 22.4045 10.0871C21.2852 9.95275 20.2535 10.202 19.3473 10.8648C18.3999 11.5565 17.7887 12.4857 17.6097 13.6612C17.549 14.0589 17.5437 14.4678 17.5384 14.8708C17.5278 15.6638 17.5361 16.4587 17.5361 17.2535C17.5361 17.4509 17.5149 17.4721 17.3241 17.4721C16.5969 17.4721 15.8691 17.4697 15.1419 17.4762C15.0076 17.4762 14.9764 17.4326 14.9776 17.3059C14.9829 16.1228 14.98 14.9403 14.9829 13.7572C14.9841 13.1114 15.1855 12.5081 15.4051 11.9124C15.8249 10.7711 16.5233 9.81311 17.4572 9.0501C18.3204 8.34483 19.2973 7.84283 20.4066 7.61481C21.3246 7.42627 22.2432 7.39976 23.16 7.57004C24.1781 7.75858 25.1149 8.16041 25.9499 8.78084C26.9721 9.53972 27.7546 10.4954 28.2569 11.6691C28.6979 12.6984 28.8593 13.7749 28.7821 14.8938C28.7109 15.9284 28.4336 16.8994 27.9166 17.7914C26.9368 19.4807 25.4977 20.5866 23.6081 21.0939C23.0293 21.2494 22.4399 21.3196 21.8404 21.3178C20.4514 21.3137 19.0606 21.3113 17.6715 21.3125C16.8742 21.3125 16.077 21.3255 15.2785 21.3272C14.3982 21.3284 13.5173 21.3508 12.6382 21.3166C11.6489 21.2771 10.6715 22.0295 10.3836 22.9952C10.002 24.2732 10.8311 25.768 12.3673 25.9736C12.6912 26.0172 12.9974 25.9778 13.3042 25.8811C13.4225 25.844 13.5438 25.8128 13.7028 25.7662L13.6987 25.7651L13.6993 25.7656Z"
        fill="white"
      />
      <path
        d="M17.5448 25.5571H17.5436V28.3363C17.5436 28.5408 17.5289 28.5437 17.327 28.5425C16.6151 28.5372 15.9032 28.5384 15.1913 28.5425C14.9404 28.5425 14.9481 28.5449 14.9628 28.301C14.9775 28.05 14.9669 27.7966 14.9669 27.5438C14.9669 25.9483 14.9711 24.351 14.9669 22.7554C14.9669 22.5999 15.0052 22.551 15.1689 22.5533C15.8967 22.561 16.6263 22.5628 17.3552 22.551C17.5172 22.5486 17.5519 22.5999 17.5507 22.7436C17.5466 23.6811 17.5478 24.6185 17.5478 25.5553C17.5478 25.5565 17.5472 25.5571 17.546 25.5571H17.5448Z"
        fill="white"
      />
    </svg>
  );
};
