import { colors } from "@/theme/colors";
import { motion } from "framer-motion";
import { FC } from "react";

export const LogoLoader: FC = () => {
  const icon = {
    hidden: {
      opacity: 0,
      pathLength: 0,
    },
    visible: {
      opacity: 1,
      pathLength: 1,
    },
  };

  return (
    <motion.svg
      viewBox="0 0 36 36"
      xmlns="http://www.w3.org/2000/svg"
      stroke={colors.green.label}
      strokeWidth="0.2"
    >
      <path
        d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
        fill="#353B48"
      />
      <motion.path
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: { repeat: Infinity, duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        d="M12.7248 25.7661V26.5975C12.7248 27.139 12.7196 27.6816 12.729 28.2237C12.7313 28.3951 12.6736 28.4729 12.5051 28.4935C11.0309 28.6738 9.6893 28.3751 8.5327 27.4C7.85218 26.8267 7.33897 26.132 7.04791 25.2918C6.51881 23.7635 6.695 22.3134 7.57468 20.95C8.06548 20.1882 8.7289 19.6267 9.53316 19.2237C10.206 18.8861 10.9231 18.7335 11.6719 18.7323C14.2662 18.727 16.8622 18.7282 19.4564 18.7246C20.0698 18.7246 20.6849 18.7376 21.2953 18.6981C22.4283 18.6245 23.3699 18.1331 24.1358 17.3C24.7651 16.6159 25.1257 15.7993 25.2282 14.8872C25.3413 13.8802 25.1463 12.9169 24.5777 12.0643C23.8289 10.9413 22.7943 10.2514 21.4356 10.0876C20.3155 9.95324 19.2832 10.2025 18.3764 10.8653C17.4284 11.557 16.8168 12.4862 16.6377 13.6617C16.577 14.0594 16.5717 14.4683 16.5664 14.8713C16.5558 15.6643 16.5641 16.4592 16.5641 17.254C16.5641 17.4514 16.5428 17.4726 16.3519 17.4726C15.6243 17.4726 14.896 17.4702 14.1684 17.4767C14.034 17.4767 14.0028 17.4331 14.004 17.3064C14.0093 16.1233 14.0064 14.9408 14.0093 13.7577C14.0105 13.1119 14.212 12.5086 14.4318 11.9129C14.8519 10.7716 15.5507 9.8136 16.4851 9.05059C17.3489 8.34532 18.3264 7.84332 19.4364 7.6153C20.355 7.42676 21.2741 7.40025 22.1915 7.57052C23.2102 7.75907 24.1476 8.1609 24.9831 8.78133C26.006 9.54021 26.789 10.4959 27.2916 11.6696C27.7329 12.6989 27.8943 13.7754 27.8171 14.8943C27.7458 15.9289 27.4684 16.8999 26.951 17.7919C25.9706 19.4812 24.5306 20.5871 22.6399 21.0944C22.0607 21.2499 21.4709 21.32 20.8711 21.3183C19.4812 21.3142 18.0895 21.3118 16.6996 21.313C15.9018 21.313 15.104 21.3259 14.3051 21.3277C13.4242 21.3289 12.5428 21.3513 11.6631 21.3171C10.6732 21.2776 9.69518 22.03 9.40706 22.9957C9.02526 24.2737 9.85485 25.7685 11.3921 25.9741C11.7161 26.0177 12.0225 25.9782 12.3295 25.8816C12.4479 25.8445 12.5693 25.8133 12.7284 25.7667L12.7243 25.7655L12.7248 25.7661Z"
        fill="url(none)"
      />
      <motion.path
        variants={icon}
        initial="hidden"
        animate="visible"
        transition={{
          default: { repeat: Infinity, duration: 2, ease: "easeInOut" },
          fill: { duration: 2, ease: [1, 0, 0.8, 1] },
        }}
        d="M16.5728 25.5569H16.5716V28.3362C16.5716 28.5406 16.5569 28.5436 16.3548 28.5424C15.6425 28.5371 14.9301 28.5383 14.2178 28.5424C13.9668 28.5424 13.9744 28.5448 13.9892 28.3008C14.0039 28.0498 13.9933 27.7965 13.9933 27.5437C13.9933 25.9482 13.9974 24.3509 13.9933 22.7553C13.9933 22.5998 14.0316 22.5509 14.1954 22.5532C14.9236 22.5609 15.6537 22.5626 16.3831 22.5509C16.5451 22.5485 16.5799 22.5998 16.5787 22.7435C16.5746 23.6809 16.5758 24.6184 16.5758 25.5552C16.5758 25.5564 16.5752 25.5569 16.574 25.5569H16.5728Z"
        fill="none"
      />
    </motion.svg>
  );
};
