interface NotificationsSquareIconProps extends React.SVGAttributes<SVGElement> {
  reddot?: boolean;
}

export const NotificationsSquareIcon = ({
  reddot = false,
  ...props
}: NotificationsSquareIconProps) => {
  return (
    <svg
      width="35"
      height="36"
      viewBox="0 0 35 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="0.5" width="34" height="35" rx="2.5" stroke="#3B5998" />
      <path
        d="M23.3403 17.268V15.3984C23.3403 12.671 21.485 10.3593 18.9431 9.6043V8.75781C18.9431 7.78855 18.1361 7 17.1442 7C16.1523 7 15.3453 7.78855 15.3453 8.75781V9.6043C12.8033 10.3593 10.9481 12.671 10.9481 15.3984V17.268C10.9481 19.6638 10.0135 21.9355 8.31664 23.6648C8.15035 23.8342 8.10362 24.0843 8.19788 24.3002C8.29214 24.5161 8.5092 24.6562 8.74945 24.6562H14.2064C14.4849 25.9919 15.6963 27 17.1442 27C18.5921 27 19.8034 25.9919 20.082 24.6562H25.5389C25.7792 24.6562 25.9962 24.5161 26.0904 24.3002C26.1847 24.0843 26.138 23.8342 25.9717 23.6648C24.2748 21.9355 23.3403 19.6638 23.3403 17.268ZM16.5446 8.75781C16.5446 8.43473 16.8135 8.17188 17.1442 8.17188C17.4748 8.17188 17.7438 8.43473 17.7438 8.75781V9.37219C17.5464 9.35359 17.3465 9.34375 17.1442 9.34375C16.9419 9.34375 16.7419 9.35359 16.5446 9.37219V8.75781ZM17.1442 25.8281C16.3625 25.8281 15.696 25.3382 15.4484 24.6562H18.8399C18.5924 25.3382 17.9259 25.8281 17.1442 25.8281ZM10.0418 23.4844C11.4074 21.6956 12.1473 19.5309 12.1473 17.268V15.3984C12.1473 12.7061 14.3889 10.5156 17.1442 10.5156C19.8995 10.5156 22.141 12.7061 22.141 15.3984V17.268C22.141 19.5309 22.8809 21.6956 24.2466 23.4844H10.0418Z"
        fill="#3B5998"
      />

      {reddot && (
        <ellipse
          cx="22.2619"
          cy="12.1454"
          rx="3.26193"
          ry="3.14544"
          fill="#E84118"
        />
      )}
    </svg>
  );
};
