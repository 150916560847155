import { i18n } from "@/i18n";
import { format } from "date-fns";
import { enUS, tr } from "date-fns/locale";

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const waitForExist = async (target: unknown) => {
  while (!target) {
    await sleep(100);
  }
};

export const randInt = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min)) + min;

export const i18nformat: typeof format = (...args) => {
  const locale = i18n.language.includes("tr")
    ? tr
    : i18n.language.includes("en")
      ? enUS
      : enUS;

  return format(args[0], args[1], {
    locale,
  });
};

export const ImmediateSetInterval = (
  f: (...args: unknown[]) => void,
  ms: number
): NodeJS.Timeout => {
  f();
  const intervalId = setInterval(f, ms);
  return intervalId;
};
