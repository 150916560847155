import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Grid,
  Tooltip,
  GridItem,
} from "@chakra-ui/react";
import { RefreshBoxIcon } from "@/assets/icons/refresh-box-icon";
import { APISchemas } from "@/api";
import { FC, useMemo, useState, useEffect, Fragment } from "react";
import {
  FacebookIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  TelegramIcon,
  TwitterIcon,
  YoutubeIcon,
} from "@/assets/brands";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { useTranslation } from "react-i18next";
import { ThreeDotsCircleIcon } from "@/assets/icons/three-dots-circle";

interface ChannelStaticsProps {
  title: string;
  postCount: number;
  followers: number;
  following: number;
  Channels?: APISchemas["Channel"][];
}

export const ChannelStatics: FC<ChannelStaticsProps> = ({
  title,
  postCount,
  followers,
  following,
  Channels,
}) => {
  const { t } = useTranslation();
  const [maxAvatars, setMaxAvatars] = useState<number>(7);
  const relevantChannels = useMemo(
    () =>
      Channels?.filter((item) =>
        item.channel_type.includes(title.toLocaleLowerCase())
      ),
    [Channels, title]
  );

  const avatarsToDisplay = relevantChannels?.slice(0, maxAvatars);
  const hiddenCount = Math.max((relevantChannels?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = relevantChannels?.slice(
    maxAvatars,
    relevantChannels.length
  );

  useEffect(() => {
    const resize = async () => {
      setMaxAvatars(
        Math.max(3, Math.min(7, Math.floor(window.innerWidth / 160)))
      );
    };

    window.addEventListener("resize", resize);
    resize();

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.channel-statics")}{" "}
          </Text>
          <Text
            fontWeight="400"
            lineHeight="14px"
            fontSize="12px"
            color="gray.smoke"
            mt="12px"
          >
            {t("page.analytics.specialized-channel-stats")}
          </Text>
        </Box>
        <IconButton
          variant="unstyled"
          cursor="pointer"
          aria-label="refresh"
          pl="3"
          size="none"
          bg="white"
          px="1"
          alignItems="center"
          justifyContent="center"
          // isLoading={isRefetching}
          onClick={() => {
            console.log("refreshCkicked");
          }}
        >
          <RefreshBoxIcon width="30px" height="30px" />
        </IconButton>
      </Flex>

      <Flex mt="6" alignItems="center">
        <Flex
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          {title.toLocaleLowerCase().startsWith("twitter") ? (
            <TwitterIcon width="60px" height="60px" />
          ) : title.toLocaleLowerCase().startsWith("facebook") ? (
            <FacebookIcon width="60px" height="60px" />
          ) : title.toLocaleLowerCase().startsWith("instagram") ? (
            <InstagramColorfulIcon width="60px" height="60px" />
          ) : title.toLocaleLowerCase().startsWith("linkedin") ? (
            <LinkedinIcon width="60px" height="60px" />
          ) : title.toLocaleLowerCase().startsWith("telegram") ? (
            <TelegramIcon inverse={false} width="60px" height="60px" />
          ) : title.toLocaleLowerCase().startsWith("youtube") ? (
            <YoutubeIcon width="60px" height="60px" />
          ) : null}

          <Text
            mt="10px"
            fontWeight="500"
            lineHeight="16px"
            fontSize="16px"
            color="black.active"
          >
            {title}
          </Text>
        </Flex>
        <Grid templateColumns="repeat(3, 1fr)" w="full" h="full">
          <GridItem w="100%">
            <Flex h="full" w="full" alignItems="center">
              <Flex
                h="full"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                w="full"
                gap="10px"
              >
                <Text
                  fontWeight="600"
                  fontSize="20px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {postCount}
                </Text>
                <Text
                  fontWeight="300"
                  fontSize="14px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {t("posts")}
                </Text>
              </Flex>

              <Box height="40px" width="1px" bgColor="blue.shiny" />
            </Flex>
          </GridItem>

          <GridItem w="100%">
            <Flex h="full" w="full" alignItems="center">
              <Flex
                h="full"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                w="full"
                gap="10px"
              >
                <Text
                  fontWeight="600"
                  fontSize="20px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {followers}
                </Text>
                <Text
                  fontWeight="300"
                  fontSize="14px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {t("page.analytics.followers")}
                </Text>
              </Flex>
              <Box height="40px" width="1px" bgColor="blue.shiny" />
            </Flex>
          </GridItem>

          <GridItem w="100%">
            <Flex h="full" w="full" alignItems="center">
              <Flex
                h="full"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                w="full"
                gap="10px"
              >
                <Text
                  fontWeight="600"
                  fontSize="20px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {following}
                </Text>
                <Text
                  fontWeight="300"
                  fontSize="14px"
                  lineHeight="14px"
                  color="black.active"
                >
                  {t("page.analytics.following")}
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
      <Flex alignItems="center" gap="12px" position="relative" top="12px">
        {avatarsToDisplay?.map((avatar) => (
          <Button variant="unstyled" cursor="pointer" key={avatar.id}>
            <AvatarWithBadge
              w="34px"
              h="34px"
              key={avatar.id}
              tooltip
              addCompose={avatar.channel_type !== "facebook_account"}
              email={
                avatar.username
                  ? avatar.username.startsWith("@")
                    ? avatar.username
                    : `@${avatar.username}`
                  : avatar.name
              }
              picture={avatar.picture ?? undefined}
              channel={avatar.channel_type}
              channel_id={avatar.id.toString()}
            />
          </Button>
        ))}
        {hiddenCount > 0 && (
          <Tooltip
            key="tooltip"
            shadow="0 0 10px rgba(0, 0, 0, 0.2)"
            borderRadius="4px"
            bg="white"
            color="black"
            p="4px"
            label={
              <ul key="list">
                {hiddenAvatars?.map((avatar) => (
                  <Fragment key={avatar.id}>
                    <Text
                      fontWeight="500"
                      key={avatar.id}
                      fontSize="12px"
                      p="6px"
                      color={avatar.status === "active" ? "#50CD89" : "#E52625"}
                    >
                      {avatar.username
                        ? avatar.username.startsWith("@")
                          ? avatar.username
                          : `@${avatar.username}`
                        : avatar.name}
                    </Text>
                  </Fragment>
                ))}
              </ul>
            }
          >
            <Button cursor="pointer" variant="unstyled">
              <ThreeDotsCircleIcon />
            </Button>
          </Tooltip>
        )}
      </Flex>
    </Box>
  );
};
