import React from "react";
import { FC } from "react";

export const VerticalLineIcon: FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width="137"
      height="2"
      viewBox="0 0 137 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 1H137" stroke="#353B48" />
    </svg>
  );
};
