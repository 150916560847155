import React from "react";

export const SettingsIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.013 7.0455L15.6029 6.86625C15.4866 6.5085 15.3434 6.1635 15.1761 5.83575L16.0469 4.7145C16.3994 4.26075 16.3582 3.62025 15.9554 3.23025L14.7741 2.049C14.3803 1.6425 13.7398 1.602 13.2853 1.95375L12.1655 2.8245C11.8377 2.65725 11.4927 2.514 11.1342 2.39775L10.955 0.99C10.8875 0.42525 10.4082 0 9.84041 0H8.16034C7.59257 0 7.1133 0.42525 7.04579 0.98775L6.86654 2.39775C6.50802 2.514 6.16301 2.6565 5.83524 2.8245L4.7147 1.95375C4.26168 1.602 3.62115 1.6425 3.23038 2.04525L2.04909 3.22575C1.64257 3.62025 1.60132 4.26075 1.95383 4.71525L2.82462 5.83575C2.65661 6.1635 2.5141 6.5085 2.39785 6.86625L0.990041 7.0455C0.425268 7.113 0 7.59225 0 8.16V9.84C0 10.4078 0.425268 10.887 0.987791 10.9545L2.39785 11.1337C2.5141 11.4915 2.65736 11.8365 2.82462 12.1642L1.95383 13.2855C1.60132 13.7393 1.64257 14.3798 2.04534 14.7698L3.22663 15.951C3.62115 16.3568 4.26093 16.3972 4.71545 16.0455L5.83599 15.1747C6.16376 15.3427 6.50877 15.486 6.86654 15.6015L7.04579 17.0085C7.1133 17.5747 7.59257 18 8.16034 18H9.84041C10.4082 18 10.8875 17.5748 10.955 17.0123L11.1342 15.6022C11.492 15.486 11.837 15.3427 12.1648 15.1755L13.2861 16.0462C13.7398 16.3988 14.3803 16.3575 14.7704 15.9548L15.9517 14.7735C16.3582 14.379 16.3994 13.7392 16.0469 13.2847L15.1761 12.1642C15.3441 11.8365 15.4874 11.4915 15.6029 11.1337L17.01 10.9545C17.5747 10.887 18 10.4078 18 9.84V8.16C18.0007 7.59225 17.5755 7.113 17.013 7.0455ZM9.00037 12.75C6.93254 12.75 5.25022 11.0677 5.25022 9C5.25022 6.93225 6.93254 5.25 9.00037 5.25C11.0682 5.25 12.7505 6.93225 12.7505 9C12.7505 11.0677 11.0682 12.75 9.00037 12.75Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
