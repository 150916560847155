import { createStandaloneToast } from "@chakra-ui/react";

const ENV = import.meta.env;

export const PACKAGE_VERSION = ENV.PACKAGE_VERSION;
export const PACKAGE_VERSION_N = Number(
  ENV.PACKAGE_VERSION.replace(".", "") || "0"
);

export const DEV = ENV.VITE_DEV;

export const brandTitle = DEV ? DEV : "Posteffect";

export const TURNSTILE_TOKEN = "0x4AAAAAAAZusjC8697w9Vgc";

export const blackList = ["facebook_account"];

export const API_BASE_URL = ENV.VITE_API_BASE_URL;

export const globalToast = createStandaloneToast({
  defaultOptions: {
    position: "top-right",
    duration: 3000,
    isClosable: true,
  },
});

export const GETCHANNELNAME = (
  str: string
):
  | "twitter"
  | "instagram"
  | "facebook"
  | "linkedin"
  | "telegram"
  | "youtube"
  | "tiktok"
  | null => {
  const parts = str.split("_");

  const platformName = parts[0];

  if (platformName === "twitter") {
    return "twitter";
  }

  if (platformName === "instagram") {
    return "instagram";
  }

  if (platformName === "facebook") {
    return "facebook";
  }

  if (platformName === "linkedin") {
    return "linkedin";
  }

  if (platformName === "telegram") {
    return "telegram";
  }
  if (platformName === "youtube") {
    return "youtube";
  }
  if (platformName === "tiktok") {
    return "tiktok";
  }

  return null;
};

export const getCurrencySymbol = (currency: string) =>
  ({ TRY: "₺", USD: "$", EUR: "€" })[currency];

export const PLATFORMS = [
  "Twitter",
  "Instagram",
  "Facebook",
  "Linkedin",
  "Telegram",
  "Youtube",
  "Tiktok",
];
