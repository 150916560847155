import {
  Flex,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Icon,
} from "@chakra-ui/react";
import { FC, Fragment, useCallback } from "react";
import { UserInfo } from "./components/user-info";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { CircleIcon } from "@/assets/icons/circle-icon"; // subtask için bırakıldı
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GET } from "@/api";
import { getCurrencySymbol } from "@/constant";

export const MembershipPackages: FC = () => {
  const { t } = useTranslation();

  const { data: Plans } = useQuery("membership-plans", async () => {
    const { data } = await GET("/membership/get-plans/", {});
    return data;
  });

  const Free = Plans?.find((item) => item.name === "Free");
  const Basic = Plans?.find((item) => item.name === "Basic");
  const Enterprise = Plans?.find((item) => item.name === "Enterprise");

  const getPlanByPeriod = useCallback(
    (plan: string, period: string) => {
      return Plans?.find((item) => item.name === plan)?.plan_kind?.find(
        (i) => i.plan_period.toLowerCase() === period
      );
    },
    [Plans]
  );

  const data = [
    {
      mainText: t("membership-packages.price-monthly"),
      free: `${getCurrencySymbol(getPlanByPeriod("Free", "free")?.currency ?? "usd")}${getPlanByPeriod("Free", "free")?.price}`,
      basic: `${getCurrencySymbol(getPlanByPeriod("Basic", "monthly")?.currency ?? "usd")}${getPlanByPeriod("Basic", "monthly")?.price}`,
      enterprise: `${getCurrencySymbol(getPlanByPeriod("Enterprise", "monthly")?.currency ?? "usd")}${getPlanByPeriod("Enterprise", "monthly")?.price}`,
    },

    {
      mainText: t("membership-packages.price-yearly"),
      free: `${getCurrencySymbol(getPlanByPeriod("Free", "free")?.currency ?? "usd")}${getPlanByPeriod("Free", "free")?.price}`,
      basic: `${getCurrencySymbol(getPlanByPeriod("Basic", "yearly")?.currency ?? "usd")}${getPlanByPeriod("Basic", "yearly")?.price}`,
      enterprise: `${getCurrencySymbol(getPlanByPeriod("Enterprise", "yearly")?.currency ?? "usd")}${getPlanByPeriod("Enterprise", "yearly")?.price}`,
    },
    {
      mainText: t("membership-packages.specialized-dashboard"),
      free: Free?.plan_scope?.reporting?.dashboard,
      basic: Basic?.plan_scope?.reporting?.dashboard,
      enterprise: Enterprise?.plan_scope?.reporting?.dashboard,
    },
    {
      mainText: t("membership-packages.social-media-profiles"),
      free: Free?.channel_limit,
      basic: Basic?.channel_limit,
      enterprise: Enterprise?.channel_limit,
    },
    {
      mainText: t("membership-packages.plan-details.share-limit"),
      free: Free?.post_limit,
      basic: Basic?.post_limit,
      enterprise: Enterprise?.post_limit,
    },
    {
      mainText: t("membership-packages.plan-details.user_count"),
      free: Free?.user_limit,
      basic: Basic?.user_limit,
      enterprise: Enterprise?.user_limit,
    },
    {
      mainText: t("membership-packages.plan-details.organization_limit"),
      free: Free?.organization_limit,
      basic: Basic?.organization_limit,
      enterprise: Enterprise?.organization_limit,
    },

    {
      mainText: t("membership-packages.plan-details.inbox"),
      free: Free?.plan_scope?.communication?.inbox,
      basic: Basic?.plan_scope?.communication?.inbox,
      enterprise: Enterprise?.plan_scope?.communication?.inbox,
    },
    {
      mainText: t("membership-packages.plan-details.ai"),
      free: Free?.plan_scope?.tools?.ai,
      basic: Basic?.plan_scope?.tools?.ai,
      enterprise: Enterprise?.plan_scope?.tools?.ai,
    },

    {
      mainText: t("membership-packages.plan-details.url_shortener"),
      free: Free?.plan_scope?.tools?.url_shortener,
      basic: Basic?.plan_scope?.tools?.url_shortener,
      enterprise: Enterprise?.plan_scope?.tools?.url_shortener,
    },

    {
      mainText: t("membership-packages.plan-details.reporting"),
      free: Free?.plan_scope?.reporting?.reporting,
      basic: Basic?.plan_scope?.reporting?.reporting,
      enterprise: Enterprise?.plan_scope?.reporting?.reporting,
    },
    {
      mainText: t("membership-packages.plan-details.exporting_data"),
      free: Free?.plan_scope?.reporting?.exporting_data,
      basic: Basic?.plan_scope?.reporting?.exporting_data,
      enterprise: Enterprise?.plan_scope?.reporting?.exporting_data,
    },

    {
      mainText: t("membership-packages.plan-details.acm"),
      free: Free?.plan_scope?.management?.acm,
      basic: Basic?.plan_scope?.management?.acm,
      enterprise: Enterprise?.plan_scope?.management?.acm,
    },
    {
      mainText: t("membership-packages.plan-details.organization-management"),
      free: Free?.plan_scope?.management?.organization_management,
      basic: Basic?.plan_scope?.management?.organization_management,
      enterprise: Enterprise?.plan_scope?.management?.organization_management,
    },

    // {
    //   mainText: t("membership-packages.plan-details.ai"),
    //   free: Free?.channel_limit,
    //   basic: Basic?.channel_limit,
    //   enterprise: Enterprise?.channel_limit,
    //   subTexts: [
    //     {
    //       mainText: "Instagram",
    //       free: Free?.instagram,
    //       basic: Basic?.instagram,
    //       enterprise: Enterprise?.instagram,
    //     },
    //     {
    //       mainText: "X",
    //       free: Free?.x,
    //       basic: Basic?.x,
    //       enterprise: Enterprise?.x,
    //     },
    //     {
    //       mainText: "LinkedIn",
    //       free: Free?.linkedin,
    //       basic: Basic?.linkedin,
    //       enterprise: Enterprise?.linkedin,
    //     },
    //     {
    //       mainText: "Facebook",
    //       free: Free?.facebook,
    //       basic: Basic?.facebook,
    //       enterprise: Enterprise?.facebook,
    //     },
    //   ],
    // },
  ];

  return (
    <Flex flexDir="column" gap="5" alignItems="center" p="4">
      <UserInfo />
      <Table
        variant="simple"
        border="1px solid #EBEDF2"
        mb="5"
        boxShadow=" 0 1px 3px 0 rgba(0, 0, 0, 0.1),0 1px 2px 0 rgba(0, 0, 0, 0.06)"
        bg="white"
      >
        <Thead>
          <Tr>
            <Th
              style={{ textTransform: "none" }}
              color="black.active"
              fontSize="16px"
            >
              {t("membership-packages.membership-plans")}
            </Th>
            <Th
              w="20%"
              style={{ textTransform: "none" }}
              bgColor="#57AAEC"
              textAlign="center"
            >
              <Text color="white" fontWeight="600" fontSize="18px">
                {t("membership-packages.free")}
              </Text>
            </Th>

            <Th
              textAlign="center"
              w="20%"
              bgColor="#3B5998"
              style={{ textTransform: "none" }}
            >
              <Text color="white" fontWeight="600" fontSize="18px">
                {t("membership-packages.basic")}
              </Text>
            </Th>
            <Th
              textAlign="center"
              w="20%"
              bgColor="#50CD89"
              style={{ textTransform: "none" }}
            >
              <Text
                color="white"
                fontWeight="600"
                fontSize="18px"
                bgColor="rgba(229, 37, 210, 0.06)"
              >
                {t("membership-packages.premium")}
              </Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody
          sx={{
            "&>tr:hover": {
              bg: "gray.smooth",
            },
          }}
        >
          {data.map((item, index) => (
            <Fragment key={index}>
              <Tr>
                <Td textAlign={index == 0 || index == 1 ? "end" : "start"}>
                  <Text color="black.active" fontSize="16px" fontWeight="600">
                    {item.mainText}
                  </Text>
                </Td>
                <Td
                  bgColor={
                    index == 0 || index == 1
                      ? "white"
                      : "rgba(37, 171, 229, 0.06)"
                  }
                  textAlign="center"
                >
                  {item.free === true ? (
                    <Icon as={CheckIcon} boxSize={4} color="black.antrasit" />
                  ) : item.free === false ? (
                    <Icon as={CloseIcon} boxSize={3} />
                  ) : (
                    <Text
                      fontSize="16px"
                      fontWeight="600"
                      color="black.antrasit"
                    >
                      {item.free}
                    </Text>
                  )}
                </Td>
                <Td
                  textAlign="center"
                  bgColor={
                    index == 0 || index == 1
                      ? "white"
                      : "rgba(229, 141, 37, 0.06)"
                  }
                >
                  {item.basic === true ? (
                    <Icon as={CheckIcon} boxSize={4} />
                  ) : item.basic === false ? (
                    <Icon as={CloseIcon} boxSize={3} />
                  ) : (
                    <Text
                      color="black.antrasit"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {item.basic}
                    </Text>
                  )}
                </Td>

                <Td
                  textAlign="center"
                  bgColor={
                    index == 0 || index == 1
                      ? "white"
                      : "rgba(229, 37, 210, 0.06)"
                  }
                >
                  {item.enterprise === true ? (
                    <Icon as={CheckIcon} boxSize={4} />
                  ) : item.enterprise === false ? (
                    <Icon as={CloseIcon} boxSize={3} />
                  ) : (
                    <Text
                      color="black.antrasit"
                      fontSize="16px"
                      fontWeight="600"
                    >
                      {item.enterprise}
                    </Text>
                  )}
                </Td>
              </Tr>

              {/* {item?.subTexts?.map((subText, subIndex) => (
                <Tr key={`${index}-${subIndex}`}>
                  <Td pos="relative" left="30px">
                    <Flex alignItems="center">
                      <CircleIcon width="8px" height="8" />
                      <Text
                        pos="relative"
                        bottom="2px"
                        ml="2"
                        color="black.active"
                        fontSize="14px"
                        fontWeight="500"
                      >
                        {subText.mainText}
                      </Text>
                    </Flex>
                  </Td>

                  <Td textAlign="center" bgColor="rgba(37, 171, 229, 0.06)">
                    {subText.free === true ? (
                      <Icon as={CheckIcon} boxSize={4} />
                    ) : (
                      <Icon as={CloseIcon} boxSize={3} />
                    )}
                    {subText.free !== true && subText.free !== false && (
                      <Text
                        color="gray.passive"
                        fontSize="16px"
                        fontWeight="600"
                      >
                        {item.free}
                      </Text>
                    )}
                  </Td>

                  <Td textAlign="center" bgColor="rgba(229, 141, 37, 0.06)">
                    {subText.basic === true ? (
                      <Icon as={CheckIcon} boxSize={4} />
                    ) : subText.basic === false ? (
                      <Icon as={CloseIcon} boxSize={3} />
                    ) : (
                      <Text
                        color="gray.passive"
                        fontSize="16px"
                        fontWeight="600"
                      >
                        {subText.basic}
                      </Text>
                    )}
                  </Td>
                  <Td textAlign="center" bgColor="rgba(229, 37, 210, 0.06)">
                    {subText.enterprise === true ? (
                      <Icon as={CheckIcon} boxSize={4} />
                    ) : subText.enterprise === false ? (
                      <Icon as={CloseIcon} boxSize={3} />
                    ) : (
                      <Text
                        color="gray.passive"
                        fontSize="16px"
                        fontWeight="600"
                      >
                        {subText.enterprise}
                      </Text>
                    )}
                  </Td>
                </Tr>
              ))} */}
            </Fragment>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
};
