import { forwardRef } from "react";
import { Button, Text } from "@chakra-ui/react";

interface DateBoxProps {
  primary: string;
  secondary: string;
  active: boolean;
  date: Date;
  onPress: () => void;
}

export const DateBox = forwardRef<HTMLButtonElement, DateBoxProps>(
  ({ primary, secondary, onPress, active }, ref) => {
    return (
      <Button
        variant="unstyled"
        outline="none"
        onClick={onPress}
        size="none"
        w="full"
        h="full"
        ref={ref}
        _hover={{ bg: "white" }}
      >
        <Text
          fontWeight={active ? "600" : "500"}
          color={active ? "blue.shiny" : "gray.passive"}
          fontSize="18px"
        >
          {primary}
        </Text>
        <Text
          fontWeight={active ? "600" : "500"}
          color={active ? "blue.shiny" : "gray.passive"}
          fontSize="18px"
        >
          {secondary}
        </Text>
      </Button>
    );
  }
);
