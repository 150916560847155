import React from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";

interface MessageItemProps {
  picture?: string;
  channel_type?: string;
  message?: string;
  userName?: string;
  message_count?: number;
  date?: string;
  message_read?: boolean;
}

export const MessageItem: React.FC<MessageItemProps> = ({
  picture,
  channel_type,
  userName,
  message,
  message_count = 7,
  date,
  message_read = false,
}) => {
  //   const calculateTimeStamp = (date: string): string => {
  //     const eventTimestamp = new Date(Date.parse(date));
  //     const currentTimestamp = new Date();
  //     const timeDifference =
  //       currentTimestamp.getTime() - eventTimestamp.getTime();

  //     const seconds = Math.floor(timeDifference / 1000);
  //     const minutes = Math.floor(seconds / 60);
  //     const hours = Math.floor(minutes / 60);

  //     if (seconds < 60) {
  //       return `${seconds} seconds ago`;
  //     } else if (minutes < 60) {
  //       return `${minutes} minutes ago`;
  //     } else {
  //       return `${hours} hours ago`;
  //     }
  //   };

  return (
    <Flex
      alignItems="center"
      w="full"
      h="73px"
      justifyContent="space-between"
      py="1"
    >
      <Flex alignItems="center" w="full">
        <Flex>
          <AvatarWithBadge
            channel={channel_type ?? ""}
            picture={picture ?? undefined}
            name={userName}
            w="46px"
            h="46px"
            border="1px solid transparent"
          />
        </Flex>
        <Flex direction="column" ml="15px">
          <Flex alignItems="center">
            <Text
              color="black.active"
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
            >
              {userName}
            </Text>
            {!message_read && message_count > 0 && (
              <Box
                ml="15px"
                display="flex"
                w="14px"
                h="14px"
                borderRadius="8px"
                bgColor="#A7194B"
                alignItems="center"
                justifyContent="center"
              >
                <Text color="white" fontSize="9px" fontWeight="700">
                  {message_count}
                </Text>
              </Box>
            )}
          </Flex>

          <Text
            color={message_read ? "gray.passive" : "black.active"}
            fontSize="12px"
            fontWeight="400"
            line-height="15.5px"
          >
            {message}
          </Text>
        </Flex>
      </Flex>

      <Box h="full">
        <Text
          fontWeight="400"
          color="gray.passive"
          fontSize="10px"
          whiteSpace="nowrap"
        >
          {date}
        </Text>
        <Flex
          alignItems="center"
          justifyContent="center"
          pos="relative"
          top="3"
        ></Flex>
      </Box>
    </Flex>
  );
};
