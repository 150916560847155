import { GET } from "@/api";
import { useQuery } from "react-query";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { blackList } from "@/constant";

export const useChannel = () => {
  const [organization] = useAuthStore((s) => [s.activeOrganization]);

  const r = useQuery(
    ["channels", organization?.id],
    async () => {
      if (!organization) {
        return;
      }

      const { data, error } = await GET("/org/{org_pk}/channels/", {
        params: { path: { org_pk: organization.id.toString() } },
      });

      if (error) console.error("channel data error", error);
      return data;
    },
    {
      select(data) {
        return data?.filter((channel) => {
          return (
            channel.status !== "inactive" &&
            !blackList.includes(channel.channel_type)
          );
        });
      },
      staleTime: 1000 * 60,
      refetchOnWindowFocus: "always",
    }
  );

  return r;
};
