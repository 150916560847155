import { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { ChevronFilledIcon } from "@/assets/icons/chevron-filled-icon";
import { useCallback } from "react";
import { useConfigStore } from "@/context/config-store/config-store";
interface StatsPercentageGroupProps {
  currentValue?: number;
  previousValue?: number;
}

export const StatsPercentageGroup: FC<StatsPercentageGroupProps> = ({
  currentValue,
  previousValue,
}) => {
  const language = useConfigStore((c) => c.language);

  const calculateNumberPlaceholder = useCallback(
    (number: number) => {
      if (!language) return undefined;
      if (number > 1000000) {
        return number / 1000000 + "M";
      } else if (number > 1000) {
        return number / 1000 + (language === "tr" ? "B" : "K");
      } else return number;
    },
    [language]
  );

  const calculatePercentege = useCallback(
    (currentValue: number, previousValue: number) => {
      return Math.abs(((currentValue - previousValue) * 100) / currentValue);
    },
    []
  );

  const isIncreased = (currentValue?: number, previousValue?: number) => {
    if (!currentValue || !previousValue) return undefined;
    return currentValue > previousValue;
  };

  return (
    <Flex
      w="full"
      height="full"
      alignItems="center"
      flexDirection="column"
      py="7"
    >
      <Text
        lineHeight="14px"
        color="black.active"
        fontWeight={700}
        fontSize="1.2em"
      >
        {currentValue ? calculateNumberPlaceholder(currentValue) : "-"}
      </Text>
      {currentValue && (
        <Flex justifyContent="center" alignItems="center" mt="3">
          {isIncreased(currentValue, previousValue) === true ? (
            <Box mr="1" transform="rotate(180deg)">
              <ChevronFilledIcon fill="#50CD89" />
            </Box>
          ) : isIncreased(currentValue, previousValue) === false ? (
            <Box mr="1">
              <ChevronFilledIcon fill="#F13232" />
            </Box>
          ) : null}
          <Text
            fontSize="0.9rem"
            lineHeight="14px"
            fontWeight="450"
            color={
              isIncreased(currentValue, previousValue)
                ? "green.label"
                : "red.main"
            }
          >
            {currentValue &&
              previousValue &&
              calculatePercentege(currentValue, previousValue).toFixed(2) + "%"}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
