import React from "react";

export const ClearChatIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.5"
        y="1.25"
        width="32"
        height="32"
        rx="5.5"
        fill="#F13232"
        fill-opacity="0.13"
        stroke="#F13232"
      />
      <path
        d="M20.6144 10.3563C21.0629 9.49041 22.1285 9.15204 22.9944 9.60055C23.8617 10.0498 24.1995 11.1178 23.7483 11.9841L21.9349 15.4659C21.8623 15.6053 21.7724 15.7325 21.6684 15.8453C22.164 16.579 22.3176 17.5225 22.0035 18.4157C21.932 18.6192 21.8564 18.8314 21.7779 19.0483C21.7445 19.1406 21.6373 19.1821 21.5503 19.1366L15.6988 16.0787C15.604 16.0292 15.5821 15.9033 15.6537 15.8239C15.7993 15.6626 15.9411 15.5006 16.0773 15.3413C16.7173 14.5923 17.6658 14.1685 18.6238 14.1999C18.6346 14.1776 18.6461 14.1554 18.6581 14.1333L20.6144 10.3563Z"
        fill="#F13232"
      />
      <path
        d="M14.8418 16.8221C14.7803 16.79 14.7052 16.8006 14.6544 16.8478C14.2942 17.1824 13.9348 17.4746 13.5996 17.6825C12.4675 18.3848 11.5316 18.6379 10.9504 18.7278C10.6105 18.7803 10.3346 18.9992 10.2002 19.283C10.0598 19.5794 10.0704 19.9678 10.3432 20.2684C10.6438 20.5997 11.0891 21.0381 11.7313 21.5655C11.769 21.5964 11.8186 21.6086 11.8664 21.599C12.3156 21.5087 12.7834 21.4234 13.1915 21.2101C13.3103 21.1479 13.4882 21.0373 13.7112 20.846C13.9333 20.6555 14.2714 20.676 14.4663 20.8917C14.6611 21.1074 14.6391 21.4366 14.417 21.6271C14.135 21.869 13.8897 22.0262 13.6953 22.1279C13.5362 22.211 13.3697 22.2801 13.1997 22.339C13.0706 22.3837 13.0404 22.5578 13.1525 22.6359C13.476 22.8613 13.8288 23.0967 14.2133 23.3413C14.41 23.4663 14.6039 23.5839 14.794 23.6943C14.8482 23.7258 14.9159 23.723 14.9669 23.6865C15.2413 23.4902 15.4776 23.2932 15.6702 23.1125C16.1061 22.7036 16.4821 22.2531 16.7862 21.7351C16.9342 21.4829 17.2597 21.3942 17.5132 21.5369C17.7667 21.6796 17.8523 21.9997 17.7043 22.2518C17.3483 22.8583 16.9078 23.3892 16.3972 23.8683C16.3088 23.9512 16.2127 24.0369 16.1093 24.1243C16.0178 24.2015 16.0342 24.3486 16.1427 24.3993C16.7894 24.7013 17.3335 24.9051 17.708 25.0316C18.2512 25.2151 18.8317 25.0052 19.1434 24.5384C19.5374 23.9483 20.2295 22.8353 20.8237 21.4811C20.9779 21.1297 21.1347 20.7466 21.2885 20.354C21.3186 20.2769 21.2858 20.1896 21.2124 20.1513L14.8418 16.8221Z"
        fill="#F13232"
      />
      <path
        d="M11.8471 15.3834C12.2696 15.3834 12.6121 15.0409 12.6121 14.6183C12.6121 14.1958 12.2696 13.8533 11.8471 13.8533C11.4245 13.8533 11.082 14.1958 11.082 14.6183C11.082 15.0409 11.4245 15.3834 11.8471 15.3834Z"
        fill="#F13232"
      />
      <path
        d="M9.76502 17.7349C10.1875 17.7349 10.53 17.3924 10.53 16.9698C10.53 16.5473 10.1875 16.2048 9.76502 16.2048C9.34251 16.2048 9 16.5473 9 16.9698C9 17.3924 9.34251 17.7349 9.76502 17.7349Z"
        fill="#F13232"
      />
    </svg>
  );
};
