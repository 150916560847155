import { StandartFile } from "../common";

interface GeneralSchemaObject {
  id?: number;
  content?: string;
  files?: StandartFile[];
}

export const hasMedia = (o: GeneralSchemaObject): boolean => {
  return o.files !== undefined && o.files.length > 0;
};

export const checkContentMention = (
  o: GeneralSchemaObject,
  maxHashtagWords: number
): boolean => {
  if (!o.content) return true;
  const words = o.content.split(/\s+/);
  const regexPattern = new RegExp(`^@\\w+`);
  const matchingWords = words.filter((word) => regexPattern.test(word));

  return matchingWords.length <= maxHashtagWords;
};

export const checkImageSize = (
  o: GeneralSchemaObject,
  size: number
): boolean => {
  const coverPhotos =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Image")
    ) ?? [];
  return coverPhotos.every((file) => {
    const fileSize = file.api.size;
    return fileSize <= size;
  });
};
export const checkContentHashtag = (
  o: GeneralSchemaObject,
  maxMentionWords: number
): boolean => {
  if (!o.content) return true;
  const words = o.content.split(/\s+/);
  const regexPattern = new RegExp(`^#\\w+`);
  const matchingWords = words.filter((word) => regexPattern.test(word));

  if (matchingWords.length === 0) return true;

  return matchingWords.length <= maxMentionWords;
};

export const checkMaximumMedia = (
  o: GeneralSchemaObject,
  maxMedia: number
): boolean => {
  return o.files !== undefined && o.files.length <= maxMedia;
};

export const checkMPEGVideoFormat = (
  o: GeneralSchemaObject
): boolean | undefined => {
  const videoFiles = o?.files?.filter(
    (file) =>
      file.analyzed && file.analyzed.some((track) => track["@type"] === "Video")
  );

  if (videoFiles?.length === 0) return true;
  return videoFiles?.every((file) => {
    return file.analyzed?.some(
      (track) => track["@type"] === "General" && track.Format === "MPEG-4"
    );
  });
};

export const checkVideoDuration = (
  o: GeneralSchemaObject,
  maxDuration: number,
  minDuration: number
): boolean => {
  const videoFiles = o?.files?.filter(
    (file) =>
      file.analyzed && file.analyzed.some((track) => track["@type"] === "Video")
  );

  if (videoFiles?.length === 0) true;
  return (
    videoFiles?.every((file) =>
      file.analyzed?.some(
        (track) =>
          track["@type"] === "Video" &&
          typeof track.Duration === "number" &&
          track?.Duration <= maxDuration &&
          track?.Duration >= minDuration
      )
    ) ?? false
  );
};

export const checkVideoFrameRate = (
  o: GeneralSchemaObject,
  min: number,
  max: number
): boolean => {
  const videoFiles = o?.files?.filter(
    (file) =>
      file.analyzed && file.analyzed.some((track) => track["@type"] === "Video")
  );
  if (videoFiles?.length === 0) return true;
  return (
    videoFiles?.every((file) =>
      file.analyzed?.some(
        (track) =>
          track["@type"] === "Video" &&
          typeof track.FrameRate === "number" &&
          track?.FrameRate <= max &&
          track?.FrameRate >= min
      )
    ) ?? false
  );
};

export const checkGeneralImageFormat = (
  o: GeneralSchemaObject
): boolean | undefined => {
  const imageFiles = o?.files?.filter(
    (file) =>
      file.analyzed && file.analyzed.some((track) => track["@type"] === "Image")
  );

  if (imageFiles?.length === 0) return true;
  const allowedFormats = ["TIFF", "JPEG", "JPG", "PNG", "GIF"];
  return imageFiles?.every((file) =>
    file.analyzed?.some(
      (track) =>
        track["@type"] === "Image" &&
        allowedFormats.includes(track.Format ?? "")
    )
  );
};

export const checkVideoAspectRatio = (
  o: GeneralSchemaObject,
  min: number,
  max: number
): boolean => {
  const checkAspectRatio = (
    width: number | undefined,
    height: number | undefined,
    minRatio: number,
    maxRatio: number
  ): boolean => {
    if (!height || !width) return false;
    const calculatedRatio = width / height;
    return calculatedRatio >= minRatio && calculatedRatio <= maxRatio;
  };

  const videoFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Video")
    ) ?? [];

  if (videoFiles?.length === 0) return true;

  const videoAspectRatioCheck = videoFiles.every((file) => {
    const video = file.analyzed?.find((track) => track["@type"] === "Video");
    return checkAspectRatio(video?.Width, video?.Height, min, max);
  });

  return videoAspectRatioCheck;
};

export const checkVideoSize = (
  o: GeneralSchemaObject,
  maxSize: number
): boolean => {
  const videoFiles =
    o.files?.filter(
      (file) =>
        file.analyzed &&
        file.analyzed.some((track) => track["@type"] === "Video")
    ) ?? [];
  if (videoFiles.length === 0) return true;

  const sizeCheck = videoFiles.every((file) => {
    const fileSize = file.api.size;
    return fileSize <= maxSize;
  });

  return sizeCheck;
};

export const checkSingleMediaType = (
  o: GeneralSchemaObject
): boolean | undefined => {
  let hasImage = false;
  let hasVideo = false;

  o?.files?.forEach((file) => {
    if (file.analyzed) {
      file.analyzed.forEach((track) => {
        if (track["@type"] === "Video") {
          hasVideo = true;
        } else if (track["@type"] === "Image") {
          hasImage = true;
        }
      });
    }
  });

  return (
    (hasImage && !hasVideo) ||
    (!hasImage && hasVideo) ||
    (!hasImage && !hasVideo)
  );
};
