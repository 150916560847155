import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { UserInfo } from "./components/user-info";
import { SummaryCard } from "./components/summary-card";
import { ConnectedIcon } from "@/assets/icons/connected-icon";
import { TeamMembersIcon } from "@/assets/icons/team-members-icon";
import { MediaIcon } from "@/assets/icons/media-icon";
import { OrganizationsIcon } from "@/assets/icons/organizations-icon";
import { CurrentPackageInfo } from "./components/current-package-info";
import { InvoiceTable } from "./components/invoice-table";
import { useQuery } from "react-query";
import { GET } from "@/api";
import { parseISO } from "date-fns";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useNavigate } from "react-router-dom";

export const MembershipPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const activeOrganization = useAuthStore((state) => state.activeOrganization);

  const { data: Membership } = useQuery(
    ["membership", activeOrganization?.id],
    async () => {
      if (!activeOrganization) return;

      const { data } = await GET("/membership/{org_pk}/get-membership/", {
        params: {
          path: {
            org_pk: activeOrganization?.id.toString(),
          },
        },
      });

      return {
        ...data,
        start_date: data?.start_date ? parseISO(data?.start_date) : undefined,
        end_date: data?.end_date ? parseISO(data?.end_date) : undefined,
      };
    }
  );

  return (
    <Flex flexDir="column" gap="5" alignItems="center" p="4" bgColor="white.bg">
      <UserInfo />

      <Grid templateColumns="repeat(4, 1fr)" gap={6} w="full">
        <GridItem w="full">
          <SummaryCard
            firstData={Membership?.connected_channels}
            secondData={Membership?.plan_kind?.plan?.channel_limit}
            icon={<ConnectedIcon width="57px" height="57px" />}
            text={t("connected_channels")}
            onPress={() => {
              navigate("/settings", { state: { tab: 3 } });
            }}
          />
        </GridItem>
        <GridItem w="full">
          <SummaryCard
            firstData={Membership?.team_members}
            secondData={Membership?.plan_kind?.plan?.user_limit}
            icon={<TeamMembersIcon width="57px" height="57px" />}
            text={t("team_members")}
            onPress={() => {
              navigate("/settings", { state: { tab: 2 } });
            }}
          />
        </GridItem>
        <GridItem w="full">
          <SummaryCard
            firstData={Membership?.daily_post_total}
            secondData={Membership?.daily_post_limit}
            icon={<MediaIcon width="57px" height="57px" />}
            text={t("daily_post_limit")}
          />
        </GridItem>
        <GridItem w="full">
          <SummaryCard
            firstData={Membership?.organizations}
            secondData={Membership?.plan_kind?.plan?.organization_limit}
            icon={<OrganizationsIcon width="57px" height="57px" />}
            text={t("organizations")}
          />
        </GridItem>
      </Grid>
      <Flex w="full" gap="5">
        <Grid templateColumns="repeat(4, 1fr)" gap={6} w="full">
          <GridItem w="full" h="full" colSpan={3}>
            <InvoiceTable />
          </GridItem>
          <GridItem w="full" h="full" colSpan={1}>
            <CurrentPackageInfo
              planPeriod={Membership?.plan_kind?.plan_period}
              currency={Membership?.plan_kind?.currency}
              estimatedValue={Membership?.plan_kind?.price}
              currentPackage={
                Membership?.plan_kind?.plan?.name &&
                t(
                  `membership-packages.${Membership.plan_kind?.plan.name.toLowerCase()}`
                )
              }
              start_date={Membership?.start_date}
              end_date={Membership?.end_date}
            />
          </GridItem>
        </Grid>
      </Flex>
    </Flex>
  );
};
