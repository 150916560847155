import React, { FC } from "react";

export const CommentBoxIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.675903" width="42.7154" height="44" rx="3" fill="#FBE9DD" />
      <path
        d="M13.9832 32H12.3737L13.5118 30.8619C14.1254 30.2483 14.5086 29.4501 14.6116 28.5843C11.9838 26.8598 10.6759 24.3615 10.6759 21.7424C10.6759 16.9104 15.1169 12 22.0721 12C29.4403 12 33.3913 16.5185 33.3913 21.319C33.3913 26.1512 29.3986 30.6667 22.0721 30.6667C20.7887 30.6667 19.4494 30.4953 18.2537 30.181C17.1219 31.3419 15.5883 32 13.9832 32Z"
        fill="#FF8F48"
      />
    </svg>
  );
};
