import { FC } from "react";

export const CheckAllIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 11 11"
      fill="none"
      {...props}
    >
      <path
        d="M0.97492 9.89835V10.0233H1.09992H7.14948C7.28606 10.0233 7.39017 10.0683 7.45981 10.138C7.52946 10.2076 7.57444 10.3117 7.57444 10.4483C7.57444 10.5849 7.52946 10.689 7.45981 10.7586C7.39017 10.8283 7.28606 10.8733 7.14948 10.8733H1.09992C0.564 10.8733 0.125 10.4343 0.125 9.89835V3.84879C0.125 3.71221 0.169982 3.6081 0.239627 3.53846C0.309273 3.46881 0.413378 3.42383 0.54996 3.42383C0.686542 3.42383 0.790647 3.46881 0.860293 3.53846C0.929938 3.6081 0.97492 3.71221 0.97492 3.84879V9.89835Z"
        fill="#353B48"
        stroke="#F4F6F8"
        strokeWidth="0.25"
      />
      <path
        d="M3.29719 0.97492H3.17219V1.09992V7.69944V7.82444H3.29719H9.8967H10.0217V7.69944V1.09992V0.97492H9.8967H3.29719ZM9.8967 8.67436H3.29719C2.76127 8.67436 2.32227 8.23536 2.32227 7.69944V1.09992C2.32227 0.563999 2.76127 0.125 3.29719 0.125H9.8967C10.4326 0.125 10.8716 0.563999 10.8716 1.09992V7.69944C10.8716 8.23536 10.4326 8.67436 9.8967 8.67436Z"
        fill="#353B48"
        stroke="#F4F6F8"
        strokeWidth="0.25"
      />
      <path
        d="M8.63313 3.68493C8.85311 3.46495 8.85311 3.13497 8.63313 2.91499C8.41315 2.695 8.08317 2.695 7.86318 2.91499L8.63313 3.68493ZM6.04832 5.4998L5.66335 5.88477C5.88333 6.10476 6.21331 6.10476 6.43329 5.88477L6.04832 5.4998ZM5.33337 4.01491C5.11339 3.79492 4.78341 3.79492 4.56343 4.01491C4.34344 4.23489 4.34344 4.56487 4.56343 4.78485L5.33337 4.01491ZM7.86318 2.91499L5.66335 5.11483L6.43329 5.88477L8.63313 3.68493L7.86318 2.91499ZM6.43329 5.11483L5.33337 4.01491L4.56343 4.78485L5.66335 5.88477L6.43329 5.11483Z"
        fill="#353B48"
      />
    </svg>
  );
};
