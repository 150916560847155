import React from "react";

export const ClipIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.2827 2.23649C14.9978 -0.177787 11.2798 -0.177787 8.99446 2.23649L1.89 9.74281C0.257933 11.4674 0.25804 14.2734 1.89021 15.9979C2.70637 16.8602 3.77808 17.2913 4.85022 17.2912C5.92208 17.2911 6.99437 16.8601 7.81038 15.9979L14.3226 9.11709C14.797 8.61588 15.0584 7.94945 15.0584 7.24061C15.0584 6.53173 14.7972 5.86535 14.3227 5.36398C13.3433 4.32929 11.7497 4.32933 10.7705 5.36417L6.51179 9.86372C6.18483 10.2091 6.18483 10.7693 6.51172 11.1147C6.83864 11.4603 7.36877 11.4602 7.69576 11.1147L11.9545 6.61516C12.281 6.27023 12.8121 6.27016 13.1386 6.61508C13.2967 6.78215 13.3838 7.00431 13.3838 7.24057C13.3838 7.47684 13.2967 7.69892 13.1386 7.86607L6.62634 14.7468C5.64697 15.7815 4.05355 15.7816 3.07425 14.7469C2.09496 13.7121 2.09488 12.0285 3.07407 10.9937L10.1785 3.48751C11.8108 1.76293 14.4666 1.76293 16.0987 3.48751C16.8894 4.32286 17.3249 5.4336 17.3249 6.61501C17.3249 7.79641 16.8894 8.90715 16.0987 9.74258L8.99435 17.249C8.66743 17.5945 8.66743 18.1545 8.99442 18.5C9.15792 18.6728 9.37219 18.7591 9.58642 18.7591C9.80069 18.7591 10.015 18.6727 10.1785 18.5L17.2827 10.9936C18.3897 9.82411 18.9993 8.2691 18.9993 6.61504C18.9993 4.96102 18.3897 3.40601 17.2827 2.23649Z"
        fill="#353B48"
      />
    </svg>
  );
};
