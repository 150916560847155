import React from "react";

export const CloseShapeIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.3"
        d="M8.53125 28.0396L1.8375 22.5708C1.26512 22.1086 0.803136 21.5243 0.485248 20.8608C0.167359 20.1973 0.0015795 19.4712 0 18.7354V8.01667C0.000540783 7.06444 0.276075 6.13262 0.793484 5.33322C1.31089 4.53382 2.04816 3.90086 2.91667 3.51042L9.625 0.447919C10.2703 0.152767 10.9716 0 11.6812 0C12.3909 0 13.0922 0.152767 13.7375 0.447919L20.4167 3.51042C21.2852 3.90086 22.0224 4.53382 22.5398 5.33322C23.0573 6.13262 23.3328 7.06444 23.3333 8.01667V18.7354C23.3335 19.47 23.17 20.1954 22.8547 20.8588C22.5393 21.5223 22.0801 22.1071 21.5104 22.5708L14.8167 28.0396C13.9327 28.7711 12.8213 29.1714 11.674 29.1714C10.5266 29.1714 9.41517 28.7711 8.53125 28.0396Z"
        fill="#026FA8"
      />
      <path
        d="M14.5834 18.5179C14.4431 18.5194 14.3038 18.4931 14.1737 18.4405C14.0435 18.388 13.9251 18.3102 13.8251 18.2117L7.99174 12.5096C7.78692 12.3045 7.67188 12.0265 7.67188 11.7367C7.67187 11.4468 7.78692 11.1688 7.99174 10.9638C8.20111 10.7615 8.48084 10.6484 8.77195 10.6484C9.06306 10.6484 9.3428 10.7615 9.55216 10.9638L15.3855 16.6658C15.5903 16.8709 15.7054 17.1489 15.7054 17.4388C15.7054 17.7286 15.5903 18.0066 15.3855 18.2117C15.2789 18.3135 15.153 18.393 15.0153 18.4456C14.8776 18.4982 14.7308 18.5228 14.5834 18.5179Z"
        fill="#026FA8"
      />
      <path
        d="M8.74995 18.5164C8.60403 18.5174 8.45935 18.4895 8.32421 18.4345C8.18907 18.3794 8.06613 18.2982 7.96245 18.1955C7.75763 17.9904 7.64258 17.7124 7.64258 17.4226C7.64258 17.1328 7.75763 16.8548 7.96245 16.6497L13.7958 10.9476C13.8884 10.8126 14.0101 10.7001 14.152 10.6185C14.2939 10.5368 14.4523 10.488 14.6156 10.4757C14.7788 10.4635 14.9427 10.488 15.0953 10.5475C15.2478 10.607 15.385 10.7 15.4968 10.8196C15.6085 10.9392 15.692 11.0824 15.7411 11.2386C15.7902 11.3947 15.8036 11.5599 15.7803 11.722C15.757 11.884 15.6976 12.0388 15.6066 12.1748C15.5155 12.3109 15.3951 12.4247 15.2541 12.508L9.42078 18.2101C9.23802 18.3848 9.00166 18.4927 8.74995 18.5164Z"
        fill="#026FA8"
      />
    </svg>
  );
};
