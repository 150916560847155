import { Box, Text, Flex, Icon, Button, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { GET } from "@/api";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";
import { Logo } from "@/assets/logo";
import { FacebookBlueIcon, InstagramColorfulIcon } from "@/assets/brands";
import { FlagIcon } from "@/assets/icons/flag-icon";
import { PostEffectBlackIcon } from "@/assets/icons/posteffect-black";
import { VerticalLineIcon } from "@/assets/icons/vertical-line";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { useQuery } from "react-query";
import { brandTitle } from "@/constant";

export const InfoInstagram = () => {
  const navigate = useNavigate();
  const toast = useToast();

  const activeOrganization = useAuthStore((s) => s.activeOrganization);

  useQuery(
    ["channels", activeOrganization],

    async () => {
      if (!activeOrganization?.id) {
        return;
      }
      const { data, error } = await GET("/org/{org_pk}/channels/", {
        params: {
          path: {
            org_pk: activeOrganization?.id.toString(),
          },
        },
      });
      if (error) console.error("Get channels error", error);
      return data;
    }
  );
  const { t } = useTranslation();
  const handleConnect = () => {
    if (!activeOrganization?.id) {
      return;
    }
    GET("/instagram/auth/{org_pk}/get-url/", {
      params: {
        path: {
          org_pk: activeOrganization?.id.toString(),
        },
      },
    }).then(({ data, error }) => {
      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
        return;
      }
      const opened = window.open(
        data?.url,
        "_blank",
        "width=800,height=600,top=100,left=300"
      );

      useAuthStore.getState().cloneToWindow(opened);

      navigate("/channels");
    });
  };

  return (
    <Box alignSelf="stretch" h="full" bg="white">
      <Flex py="9" px="9" justifyContent="space-between">
        <Flex alignItems="center">
          <Icon w="10" h="10" mr="3">
            <Logo />
          </Icon>
          <Text fontSize="18px" lineHeight="22.5px" fontWeight="700">
            {brandTitle}
          </Text>
        </Flex>

        <Flex>
          <Button
            onClick={() => navigate("/")} //navigate("/channels/selection/instagram")
            variant="unstyled"
          >
            <BlackCrossIcon width="30px" height="30px" />
          </Button>
        </Flex>
      </Flex>

      <Box px="20" py="5">
        <Text fontSize="24px" color="black.active" fontWeight="500">
          {t("lets_get_you_to_finish_line")}
        </Text>
        <Flex mt="5">
          <Text fontSize="16px" color="black.active" fontWeight="400" mr="1.5">
            {t("connect_your_business_profile_to_your")}
          </Text>
          <Text color="blue.facebook" mr="1" fontSize="16px" fontWeight="600">
            {" "}
            Facebook Page{" "}
          </Text>
          <Text> {t("heres_how")}</Text>
        </Flex>

        <Flex justifyContent="space-between" mt="20" py="10">
          <Box alignItems="center" justifyContent="center">
            <Box>
              <FacebookBlueIcon width="80px" height="80px" />
            </Box>
            <Text
              fontSize="20px"
              fontWeight="600"
              color="black.active"
              mt="10"
              pos="relative"
              right="7"
            >
              {t("login_to_facebook")}
            </Text>
          </Box>
          <Box alignSelf="center" mb="20">
            <VerticalLineIcon width="137" height="2" />
          </Box>

          <Box
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            w="20vw"
          >
            <Flex justifyContent="space-between" w="full">
              <Box>
                <InstagramColorfulIcon width="80px" height="80px" />
              </Box>
              <Box>
                <FlagIcon width="80px" height="80px" />
              </Box>
            </Flex>

            <Text fontSize="20px" fontWeight="600" color="black.active" mt="10">
              {t(
                "select_a_business_profile_thats_connected_to_a_facebook_page"
              )}{" "}
            </Text>
          </Box>

          <Box alignSelf="center" mb="20">
            <VerticalLineIcon width="137" height="2" />
          </Box>
          <Box alignItems="center" justifyContent="center" textAlign="center">
            <Box>
              <PostEffectBlackIcon width="80px" height="80px" />
            </Box>

            <Text
              fontSize="20px"
              fontWeight="600"
              color="black.active"
              mt="10"
              pos="relative"
              right="7"
            >
              {t("add_to_posteffect")}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Flex justifyContent="flex-end" mt="26" w="98%">
        <Button
          mr="10"
          borderRadius="3px"
          h="36px"
          w="202px"
          color="white"
          bg="blue.shiny"
          fontSize="14px"
          fontWeight="600"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t("back")}
        </Button>
        <Button
          borderRadius="3px"
          h="36px"
          w="202px"
          color="white"
          bgColor="blue.shiny"
          fontSize="14px"
          fontWeight="600"
          cursor="pointer"
          onClick={() => {
            handleConnect();
          }}
        >
          {t("continue")}
        </Button>
      </Flex>
    </Box>
  );
};
