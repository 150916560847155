import { FC } from "react";

export const HexagonErrorIcon: FC<React.SVGAttributes<SVGElement>> = ({
  ...props
}) => {
  return (
    <svg
      viewBox="0 0 25 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color="#A40000"
      {...props}
    >
      <path
        opacity="0.3"
        d="M9.36475 28.9595L2.671 23.4908C2.09862 23.0285 1.63663 22.4443 1.31874 21.7807C1.00085 21.1172 0.835076 20.3911 0.833496 19.6553V8.93659C0.834037 7.98436 1.10957 7.05254 1.62698 6.25314C2.14439 5.45375 2.88166 4.82078 3.75016 4.43034L10.4585 1.36784C11.1038 1.07269 11.8051 0.919922 12.5147 0.919922C13.2244 0.919922 13.9257 1.07269 14.571 1.36784L21.2502 4.43034C22.1187 4.82078 22.8559 5.45375 23.3733 6.25314C23.8908 7.05254 24.1663 7.98436 24.1668 8.93659V19.6553C24.167 20.3899 24.0035 21.1153 23.6882 21.7787C23.3728 22.4422 22.9136 23.027 22.3439 23.4908L15.6502 28.9595C14.7662 29.691 13.6548 30.0913 12.5075 30.0913C11.3601 30.0913 10.2487 29.691 9.36475 28.9595Z"
        fill="currentColor"
      />
      <path
        d="M15.4169 19.4359C15.2766 19.4374 15.1373 19.411 15.0072 19.3585C14.877 19.3059 14.7585 19.2282 14.6586 19.1296L8.82524 13.4276C8.62042 13.2225 8.50537 12.9445 8.50537 12.6546C8.50537 12.3648 8.62042 12.0868 8.82524 11.8817C9.03461 11.6795 9.31434 11.5664 9.60545 11.5664C9.89656 11.5664 10.1763 11.6795 10.3857 11.8817L16.219 17.5838C16.4238 17.7889 16.5389 18.0669 16.5389 18.3567C16.5389 18.6466 16.4238 18.9246 16.219 19.1296C16.1124 19.2315 15.9865 19.311 15.8488 19.3636C15.7111 19.4162 15.5643 19.4408 15.4169 19.4359Z"
        fill="currentColor"
      />
      <path
        d="M9.58345 19.4363C9.43752 19.4373 9.29284 19.4094 9.1577 19.3544C9.02256 19.2993 8.89963 19.2181 8.79595 19.1154C8.59112 18.9104 8.47607 18.6324 8.47607 18.3425C8.47607 18.0527 8.59112 17.7747 8.79595 17.5696L14.6293 11.8675C14.7219 11.7325 14.8436 11.6201 14.9855 11.5384C15.1274 11.4567 15.2858 11.4079 15.4491 11.3957C15.6123 11.3834 15.7762 11.4079 15.9287 11.4674C16.0813 11.5269 16.2185 11.6199 16.3302 11.7395C16.442 11.8591 16.5255 12.0023 16.5746 12.1585C16.6237 12.3147 16.6371 12.4799 16.6138 12.6419C16.5905 12.804 16.5311 12.9587 16.4401 13.0947C16.349 13.2308 16.2286 13.3447 16.0876 13.4279L10.2543 19.13C10.0715 19.3047 9.83516 19.4126 9.58345 19.4363Z"
        fill="currentColor"
      />
    </svg>
  );
};
