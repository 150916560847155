import {
  Text,
  Heading,
  VStack,
  HStack,
  Checkbox,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link,
  Flex,
  IconButton,
  InputGroup,
  InputRightElement,
  Box,
  useDisclosure,
  Center,
} from "@chakra-ui/react";
import { useState, FC, Ref } from "react";
import { Link as ReachLink } from "react-router-dom";
import { RegisterSchema } from ".";
import { useFormContext } from "react-hook-form";
import { EyeIcon } from "@/assets/icons/eye-icon";
import { EyeIconInvisible } from "@/assets/icons/eye-icon-invisible";
import { useTranslation } from "react-i18next";
import { TermsModal } from "@/pages/dashboard/Privacy/components/terms-modal";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { TURNSTILE_TOKEN } from "@/constant";

export const RegisterDesktop: FC<{
  onSubmit: () => void;
  disabled: boolean;
  turnstileRef: Ref<TurnstileInstance | undefined>;
}> = ({ onSubmit, disabled, turnstileRef }) => {
  const [terms, checkTerms] = useState<boolean>();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [captcha, setCaptcha] = useState<boolean>(false);

  const [passwordVisibility, setPasswordVisibility] = useState<{
    pass: boolean;
    rePass: boolean;
  }>({
    pass: false,
    rePass: false,
  });

  const {
    register,
    setValue,
    formState: { errors },
  } = useFormContext<RegisterSchema>();

  return (
    <VStack
      flexDir="column"
      h="full"
      w="full"
      justifyContent="center"
      textAlign="center"
      //p={["16vw", "10vw", "8vw"]}
      pt="0vw"
      bg="white"
      //spacing={{ base: 5, md: 10 }}
    >
      <Heading
        flexWrap="nowrap"
        alignSelf="center"
        lineHeight="60px"
        fontWeight="500"
        fontSize={{ base: "24px", sm: "26px", md: "30px", lg: "36px" }}
      >
        {t("page.register.create_an_account1")}
      </Heading>
      <Flex
        justifyContent="center"
        alignItems="center"
        textAlign="center"
        mt="11px"
      >
        <Text
          color="blue.brand"
          fontSize={{ base: "18px", sm: "20px", md: "20px", lg: "20px" }}
          alignSelf="center"
          lineHeight="25px"
          fontWeight="400px"
          flexWrap="wrap"
        >
          {t("page.register.message.subtitle")}
        </Text>
        <Text
          color="red.main"
          ml="2"
          fontSize={{ base: "18px", sm: "20px", md: "20px", lg: "20px" }}
          lineHeight="25px"
          fontWeight="600"
          cursor="pointer"
          as="span"
          mr={1}
        >
          <Link
            as={ReachLink}
            to="/auth/login"
            _hover={{
              borderBottom: "none",
            }}
            style={{
              borderBottom: "1px solid #F13232",
              display: "inline-block",
            }}
          >
            {t("page.login.LOGIN")}
          </Link>
        </Text>
      </Flex>

      <Box
        as="form"
        w="60%"
        display="flex"
        flexDirection="column"
        onSubmit={onSubmit}
        sx={{
          "& input": {
            color: "gray.passive",
          },
        }}
      >
        <Flex alignItems="center" gap="3" mt="45px">
          <FormControl w="full" pb="25px" isInvalid={!!errors.first_name}>
            <FormLabel
              pos="absolute"
              top="-10px"
              fontSize="xs"
              zIndex="9"
              ml="2.5"
              px="3"
              fontWeight="400"
              lineHeight="20px"
              pr="6"
              bg="white"
            >
              {t("input.first_name")}
            </FormLabel>
            <Input height="72px" type="text" {...register("first_name")} />
            <FormErrorMessage pos="absolute" bottom="5px">
              {errors.first_name?.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl w="full" pb="25px" isInvalid={!!errors.last_name}>
            <FormLabel
              pos="absolute"
              top="-10px"
              fontSize="xs"
              zIndex="9"
              ml="2.5"
              px="3"
              fontWeight="400"
              lineHeight="20px"
              pr="6"
              bg="white"
            >
              {t("input.last_name")}
            </FormLabel>
            <Input height="72px" type="text" {...register("last_name")} />
            <FormErrorMessage pos="absolute" bottom="5px">
              {errors.last_name?.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>

        <FormControl w="full" pb="25px" isInvalid={!!errors.email} mt="10px">
          <FormLabel
            pos="absolute"
            top="-10px"
            fontSize="xs"
            zIndex="9"
            ml="2.5"
            px="3"
            fontWeight="400"
            lineHeight="20px"
            pr="6"
            bg="white"
          >
            {t("input.email-address")}
          </FormLabel>
          <Input height="72px" {...register("email")} />
          <FormErrorMessage pos="absolute" bottom="5px">
            {errors.email?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          mt="10px"
          justifyContent="space-between"
          alignItems="center"
          isInvalid={!!errors.password}
          pb="25px"
        >
          <FormLabel
            fontWeight="400"
            lineHeight="20px"
            pos="absolute"
            top="-10px"
            fontSize="xs"
            zIndex="9"
            ml="2.5"
            px="3"
            pr="6"
            bg="white"
          >
            {t("input.create_password")}
          </FormLabel>
          <InputGroup>
            <Input
              height="72px"
              {...register("password")}
              type={passwordVisibility.pass ? "text" : "password"}
            />
            <InputRightElement
              h="full"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                size="xs"
                variant="unstyled"
                position="absolute"
                right="12px"
                aria-label="password"
                onClick={() =>
                  setPasswordVisibility((prevVisibility) => ({
                    ...prevVisibility,
                    pass: !prevVisibility.pass,
                  }))
                }
                icon={
                  !passwordVisibility.pass ? <EyeIcon /> : <EyeIconInvisible />
                }
              />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage pos="absolute" bottom="5px">
            {errors.password?.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          mt="5px"
          justifyContent="space-between"
          alignItems="center"
          isInvalid={!!errors.confirmPassword}
          pb="25px"
        >
          <FormLabel
            fontWeight="400"
            lineHeight="20px"
            pos="absolute"
            top="-10px"
            fontSize="xs"
            zIndex="9"
            ml="2.5"
            px="3"
            pr="6"
            bg="white"
          >
            {t("input.confirm_password")}
          </FormLabel>
          <InputGroup>
            <Input
              height="72px"
              {...register("confirmPassword")}
              type={passwordVisibility.rePass ? "text" : "password"}
            />
            <InputRightElement
              h="full"
              justifyContent="center"
              alignItems="center"
            >
              <IconButton
                h="full"
                size="xs"
                variant="unstyled"
                position="absolute"
                right="12px"
                aria-label="password"
                onClick={() =>
                  setPasswordVisibility((prevVisibility) => ({
                    ...prevVisibility,
                    rePass: !prevVisibility.rePass,
                  }))
                }
                icon={
                  !passwordVisibility.rePass ? (
                    <EyeIcon />
                  ) : (
                    <EyeIconInvisible />
                  )
                }
              />
            </InputRightElement>
          </InputGroup>

          <FormErrorMessage pos="absolute" pb="5px">
            {errors.confirmPassword?.message}
          </FormErrorMessage>
        </FormControl>

        <Center mt="3">
          <Turnstile
            ref={turnstileRef}
            siteKey={TURNSTILE_TOKEN}
            onSuccess={(token) => {
              setCaptcha(true);
              setValue("captchaToken", token);
            }}
          />
        </Center>

        <HStack
          mt="9px"
          w="full"
          justifyContent="space-between"
          alignItems="center"
        >
          <Checkbox
            sx={{
              "& .chakra-checkbox__control": {
                rounded: "3px",
                borderColor: "blue.shiny",
                borderWidth: "1px solid",
              },
            }}
            size="md"
            onChange={(e) => checkTerms(e.currentTarget.checked)}
          >
            <Text
              ml={1}
              fontSize={{ base: "12px", sm: "12px", md: "13px", lg: "13px" }}
              alignSelf="center"
              lineHeight="20px"
              fontWeight="400px"
              flexWrap="wrap"
              display="flex"
              color="black.active"
            >
              {t("page.register.message.terms.by-creating-an-account")}
              <Button
                variant="link"
                _hover={{
                  borderBottom: "none",
                }}
                style={{
                  color: "#57AAEC",
                  borderBottom: "1px solid #57AAEC",
                  display: "inline-block",
                }}
                ml="1"
                mr="1"
                onClick={onOpen}
              >
                {t("page.register.message.terms.terms-and-conditions")}{" "}
                {t("page.register.message.terms.and-our")}
                {t("page.register.message.terms.privacy-policy")}
              </Button>
            </Text>
          </Checkbox>
        </HStack>

        <Button
          height="84px"
          mt="34px"
          bg="#345A79"
          textColor="#fff"
          borderRadius="6px"
          _hover={{
            //bg: "#487EB0",
            opacity: 0.8,
          }}
          bgColor="blue.shiny"
          w="full"
          type="submit"
          isDisabled={!terms || !captcha || disabled}
        >
          {t("continue")}
        </Button>
      </Box>
      <TermsModal isOpen={isOpen} onClose={onClose} />
    </VStack>
  );
};
