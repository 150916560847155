import {
  TwitterIcon,
  FacebookIcon,
  InstagramColorfulIcon,
  LinkedinIcon,
  TelegramIcon,
  YoutubeIcon,
} from "@/assets/brands";
import { RefreshBoxIcon } from "@/assets/icons/refresh-box-icon";
import { Box, Flex, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FC } from "react";
import { ChannelActivityChart } from "./channel-activity-chart";

interface ChannelActivityProps {
  title: string;
}

export const ChannelActivity: FC<ChannelActivityProps> = ({ title }) => {
  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.channel-activity")}{" "}
          </Text>
          <Text
            fontWeight="400"
            lineHeight="14px"
            fontSize="12px"
            color="gray.smoke"
            mt="12px"
          >
            {t("page.analytics.posted-activity")}
          </Text>
        </Box>
        <IconButton
          variant="unstyled"
          cursor="pointer"
          aria-label="refresh"
          width="30px"
          height="30px"
          pl="10px"
          alignItems="center"
          justifyContent="center"
          // isLoading={isRefetching}
          onClick={() => {
            console.log("refreshCkicked");
          }}
        >
          <RefreshBoxIcon width="30px" height="30px" />
        </IconButton>
      </Flex>
      <Flex mt="4" h="60px">
        <Box alignItems="center" display="flex" h="full">
          {title.toLocaleLowerCase().startsWith("twitter") ? (
            <TwitterIcon width="35px" height="35px" />
          ) : title.toLocaleLowerCase().startsWith("facebook") ? (
            <FacebookIcon width="35px" height="35px" />
          ) : title.toLocaleLowerCase().startsWith("instagram") ? (
            <InstagramColorfulIcon width="35px" height="35px" />
          ) : title.toLocaleLowerCase().startsWith("linkedin") ? (
            <LinkedinIcon width="35px" height="35px" />
          ) : title.toLocaleLowerCase().startsWith("telegram") ? (
            <TelegramIcon inverse={false} width="35px" height="35px" />
          ) : title.toLocaleLowerCase().startsWith("youtube") ? (
            <YoutubeIcon width="35px" height="35px" />
          ) : null}
        </Box>
        <Box w="full" height="77">
          <ChannelActivityChart data={data} />
        </Box>
      </Flex>
    </Box>
  );
};

const data = [
  {
    name: "19 Feb",
    data: 4000,
  },
  {
    name: "20 Feb",
    data: 3000,
  },
  {
    name: "21 Feb",
    data: 2000,
  },
  {
    name: "22 Feb",
    data: 2780,
  },
  {
    name: "23 Feb",
    data: 1890,
  },
  {
    name: "24 Feb",
    data: 2390,
  },
  {
    name: "25 Feb",
    data: 3490,
  },
];
