import { Box, Text, Flex, Icon, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { BlackCrossIcon } from "@/assets/icons/black-cross-icon";
import { Logo } from "@/assets/logo";
import { Exclamation } from "@/assets/icons/exclamation";
import { FlagIcon } from "@/assets/icons/flag-icon";
import { brandTitle } from "@/constant";

export const SelectInstagram = () => {
  const navigate = useNavigate();

  return (
    <Box w="95vw" h="full" bg="white">
      <Flex py="9" px="9" justifyContent="space-between">
        <Flex alignItems="center">
          <Icon w="10" h="10" mr="3">
            <Logo />
          </Icon>
          <Text fontSize="18px" lineHeight="22.5px" fontWeight="700">
            {brandTitle}
          </Text>
        </Flex>

        <Flex>
          <Button onClick={() => navigate("/")} variant="unstyled">
            <BlackCrossIcon width="30px" height="30px" />
          </Button>
        </Flex>
      </Flex>

      <Box px="20" py="2" bg="">
        <Box>
          <Text fontSize="24px" color="black.active" fontWeight="500">
            No instagram business profiles found{" "}
          </Text>
          <Text fontSize="16px" color="black.active" mt="5" fontWeight="400">
            To add an instagram business profile to Posteffect, it needs to
            connected to a Facebook Page.How to set up Instagram Business.{" "}
          </Text>
        </Box>
        <Box mt="6">
          <Flex alignItems="center">
            <Exclamation width="30px" height="30px" />

            <Text
              ml="4"
              fontSize="18px"
              fontWeight="600"
              color="black.active"
              display="inline"
            >
              Unable to find any instagram profiles linked to your Facebook
              Pages
            </Text>
          </Flex>
        </Box>
        <Box>
          <Box mt="5" bg="#B4DAF8" w="95%" py="5" px="5">
            <Box mb="6" alignItems="center" justifyContent="center">
              <Flex alignItems="center" mr="5">
                <FlagIcon width="36px" height="36px" />

                <Text
                  ml="5"
                  fontSize="18px"
                  fontWeight="600"
                  color="black.active"
                  display="inline"
                >
                  API Test
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center" mr="5">
              <Exclamation width="36px" height="36px" />

              <Text
                fontSize="18px"
                fontWeight="600"
                color="black.active"
                display="inline"
                ml="5"
              >
                Unable to find any instagram profiles linked to your Facebook
                Pages
              </Text>
            </Flex>
          </Box>
          <Box mt="3" bg="#B4DAF8" w="95%" py="5" px="5">
            <Box mb="6" alignItems="center" justifyContent="center">
              <Flex alignItems="center" mr="5">
                <FlagIcon width="36px" height="36px" />

                <Text
                  ml="5"
                  fontSize="18px"
                  fontWeight="600"
                  color="black.active"
                  display="inline"
                >
                  API Test
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center" mr="5">
              <Exclamation width="36px" height="36px" />

              <Text
                fontSize="18px"
                fontWeight="600"
                color="black.active"
                display="inline"
                ml="5"
              >
                Unable to find any instagram profiles linked to your Facebook
                Pages
              </Text>
            </Flex>
          </Box>
        </Box>
        <Flex justifyContent="flex-end" mt="3vh" w="95%">
          <Button
            mr="5"
            w="146px"
            h="32px"
            color="white"
            bg="#345A79"
            border-radius="6px"
            fontSize="14px"
            fontWeight="600"
            onClick={() => {
              navigate(-1);
            }}
          >
            Back
          </Button>
          <Button
            mr="5"
            w="146px"
            h="32px"
            color="white"
            bg="#345A79"
            border-radius="6px"
            fontSize="14px"
            fontWeight="600"
          >
            Try Again
          </Button>
          <Button
            w="146px"
            h="32px"
            color="white"
            bg="#345A79"
            border-radius="6px"
            fontSize="14px"
            fontWeight="600"
            onClick={() => {
              navigate("/");
            }}
          >
            Continue
          </Button>
        </Flex>
      </Box>
    </Box>
  );
};
