import { useSearchParams } from "react-router-dom";
import { POST } from "@/api";
import { Center, useToast } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18n } from "@/i18n";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export const TiktokCallback = () => {
  const [params] = useSearchParams();
  const toast = useToast();
  const activeOrganization = useAuthStore((s) => s.activeOrganization);
  const { t } = useTranslation();
  const requestSent = useRef<boolean>(false);
  if (!activeOrganization?.id) {
    toast({
      status: "error",
      title: i18n.t("errors.has-no-organization"),
    });
    return null;
  }

  if (!requestSent.current) {
    requestSent.current = true;
    POST("/tiktok/auth/callback/", {
      body: {
        org_id: activeOrganization?.id,
        auth_code: params.get("code") ?? "",
      },
    }).then(({ error, response }) => {
      if (response.status === 200) {
        toast({
          status: "success",
          title: t("page.callback.callback-success.title"),
          description: t("page.callback.callback-success.subtitle"),
        });

        setTimeout(() => {
          window.close();
        }, 1000);
      } else {
        toast({
          status: "error",
          title: t("errors.unexpected-error-contact-customer-service"),
          description: error?.detail,
        });
      }
    });
  }

  return (
    <Center w="100vw" h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  );
};
