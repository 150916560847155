import {
  Divider,
  Flex,
  FormControl,
  Input,
  VStack,
  Box,
  Checkbox,
  HStack,
  Stack,
  Text,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { tiktokSchema } from "../form-containers/tiktok-form-container";
import { ContentEditor } from "../content-editor";
import { Dropzone } from "@/components/Dropzone";
import { StatusCard } from "./_status-card";
import { useFormStore } from "../form-store";
import { APISchemas } from "@/api";
import { SetToMainManipulator } from "./_content-manipulator";
import { useTranslation } from "react-i18next";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import React from "react";

export const TiktokFormPresent: FC<{ channel?: APISchemas["Channel"] }> = ({
  channel,
}) => {
  const { t } = useTranslation();
  const privacyLevels: string[] = [
    t("page.post-page.tiktok.privacy-levels.public-to-everyone"),
    t("page.post-page.tiktok.privacy-levels.follower-of-creator"),
    t("page.post-page.tiktok.privacy-levels.mutual-follow-friends"),
    t("page.post-page.tiktok.privacy-levels.self-only"),
  ];
  const setProgressBlock = useFormStore((f) => f.setProgressBlock);
  const globalError = useFormStore((f) =>
    f.getGobalError(channel?.id.toString() ?? "")
  );
  const [privacyPopoverOpen, setPrivacyPopoverOpen] = useState<boolean>(false);

  const {
    getValues,
    register,
    setValue,
    control,
    formState: { errors },
  } = useFormContext<tiktokSchema>();

  return (
    <>
      <FormControl pos="relative">
        <Box mb="2">
          <Text
            fontWeight="400"
            lineHeight="20px"
            color="black.active"
            fontSize="16px"
            px="2"
            w="min-content"
            whiteSpace="nowrap"
            bgColor="white"
            zIndex="5"
            borderColor="gray.smoke"
            position="relative"
            top="2"
            left="1"
          >
            {t("title-b")}
          </Text>
          <Input
            w="full"
            p="3"
            h="45px"
            placeholder={t("input.placeholder.enter-your-headline")}
            {...register("title")}
            _placeholder={{ color: "gray.smoke" }}
          />
        </Box>

        <HStack my={4} spacing={6}>
          <Popover
            isOpen={privacyPopoverOpen}
            onClose={() => setPrivacyPopoverOpen(false)}
          >
            <PopoverTrigger>
              <Button
                px="2"
                width="170px"
                h="36px"
                bg="white"
                border="1px solid #B7BABF"
                borderRadius="6px"
                onClick={() => setPrivacyPopoverOpen(!privacyPopoverOpen)}
              >
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                >
                  <Text
                    textAlign="center"
                    as="span"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    fontSize="14px"
                    color="black.light"
                  >
                    {getValues("privacy_level") || privacyLevels[0]}
                  </Text>
                  {privacyPopoverOpen ? (
                    <Box>
                      <ChevronUpIcon color="blue.main" />
                    </Box>
                  ) : (
                    <Box>
                      <ChevronDownIcon color="blue.main" />
                    </Box>
                  )}
                </Flex>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              bg="white"
              borderRadius="6px"
              w="170px"
              border="1px solid #B7BABF"
              p="0px"
            >
              <PopoverBody p="0px">
                {privacyLevels?.map((item, index) => (
                  <React.Fragment key={item}>
                    <Button
                      px="2"
                      _hover={{ bg: "gray.soft" }}
                      variant="unstyled"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      fontSize="14px"
                      w="full"
                      textAlign="left"
                      color="black.light"
                      fontWeight="600"
                      key={index}
                      {...register("privacy_level")}
                      onClick={() => {
                        setValue("privacy_level", item);

                        setPrivacyPopoverOpen(false);
                      }}
                    >
                      {item}
                    </Button>
                    {index !== privacyLevels?.length - 1 && <Divider />}
                  </React.Fragment>
                ))}
              </PopoverBody>
            </PopoverContent>
          </Popover>

          <Checkbox
            {...(register("comment_disabled"),
            { valueAsTrue: false, valueAsFalse: true })}
          >
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              color="black.active"
            >
              {t("page.post-page.tiktok.comment-enabled")}
            </Text>
          </Checkbox>
          <Checkbox
            {...(register("duet_disabled"),
            { valueAsTrue: false, valueAsFalse: true })}
          >
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              color="black.active"
            >
              {t("page.post-page.tiktok.duet-enabled")}
            </Text>
          </Checkbox>
          <Checkbox
            {...(register("stitch_disabled"),
            { valueAsTrue: false, valueAsFalse: true })}
          >
            <Text
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              color="black.active"
            >
              {t("page.post-page.tiktok.stitch-enabled")}
            </Text>
          </Checkbox>
        </HStack>
      </FormControl>
      <Box border="1px solid #E2E4E6" rounded="md" px="3" pos="relative">
        <Flex>
          <Box w="full">
            <Controller
              control={control}
              name="files"
              render={({ field }) => (
                <Dropzone
                  value={field.value ?? []}
                  onChange={field.onChange}
                  onBusy={setProgressBlock}
                  placeholder={t("add-video")}
                  mediaType="video"
                  limit={1}
                />
              )}
            />
          </Box>
        </Flex>

        <Divider borderColor="#B7BABF" />
        <FormControl pos="relative">
          <ContentEditor {...register("content")} type="sm" />
          <Flex w="full">
            <Flex justifyContent="flex-end" w="full">
              {channel && <SetToMainManipulator channel={channel} />}
            </Flex>
          </Flex>
        </FormControl>

        <VStack rowGap="2" mb="3">
          {(Object.keys(errors) as Array<string>).map((key) => (
            <StatusCard
              key={key}
              title={key === "root" ? undefined : key}
              description={errors[key]?.message}
            />
          ))}
          <StatusCard
            title={globalError?.field}
            description={globalError?.message}
          />
        </VStack>
      </Box>
    </>
  );
};
