export const SearchGuideIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="22"
      height="23"
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.33">
        <path
          d="M1.83333 21.2167C2.01667 21.2167 2.10833 21.125 2.2 21.0333L8.8 14.4333C8.98333 14.25 8.98333 13.8833 8.8 13.7C8.61667 13.5167 8.25 13.5167 8.06667 13.7L1.46667 20.3C1.28333 20.4833 1.28333 20.85 1.46667 21.0333C1.55833 21.125 1.65 21.2167 1.83333 21.2167Z"
          fill="#353B48"
        />
        <path
          d="M13.2918 16.6334C17.4168 16.6334 20.7168 13.3334 20.7168 9.20845C20.7168 5.08345 17.4168 1.78345 13.2918 1.78345C9.1668 1.78345 5.8668 5.08345 5.8668 9.20845C5.8668 13.3334 9.1668 16.6334 13.2918 16.6334ZM13.2918 2.88345C16.7751 2.88345 19.6168 5.72511 19.6168 9.20845C19.6168 12.6918 16.7751 15.5334 13.2918 15.5334C9.80846 15.5334 6.9668 12.6918 6.9668 9.20845C6.9668 5.72511 9.80846 2.88345 13.2918 2.88345Z"
          fill="#353B48"
        />
      </g>
    </svg>
  );
};
