import {
  Text,
  Button,
  FormControl,
  FormErrorMessage,
  Input,
  Box,
  Grid,
  GridItem,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import { z } from "@/i18n";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCallback, useEffect, useState } from "react";
import { APISchemas } from "@/api";
import { useTranslation } from "react-i18next";
import { EditSquareIcon } from "@/assets/icons/edit-square-icon";
import { PhoneInput } from "react-international-phone";
import { PhoneNumberUtil } from "google-libphonenumber";

interface EditPersonalInfoProps {
  userInfo: APISchemas["User"];
  handleEdit: (data: ValidationSchemaType) => void;
}
type EditErrorItem = {
  path: string;
  message: Record<string, string>;
};

const useTranslatedValidation = () => {
  const { t } = useTranslation();

  const validationSchema = z.object({
    phone: z.string().max(19, t("invalid_phone")),
    name: z.string().min(1),
    surname: z.string().min(1),
  });

  return validationSchema;
};

type ValidationSchemaType = z.infer<ReturnType<typeof useTranslatedValidation>>;

export const EditPersonalInfo: React.FC<EditPersonalInfoProps> = ({
  handleEdit,
  userInfo,
}) => {
  const [editPersonalInfo, setEditPersonalInfo] = useState<boolean>(false);
  const [editError, setEditError] = useState<any>();
  const [phone, setPhone] = useState<string>(userInfo?.phone ?? "");
  const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
  const validationSchema = useTranslatedValidation();
  type validationSchema = z.infer<typeof validationSchema>;

  const { t } = useTranslation();
  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors },
  } = useForm<validationSchema>({
    resolver: zodResolver(validationSchema),
    defaultValues: {
      name: userInfo.first_name,
      phone: userInfo.phone ?? undefined,
      surname: userInfo.last_name,
    },
  });
  const phoneUtil = PhoneNumberUtil.getInstance();

  const handleCancel = useCallback(() => {
    reset({
      name: userInfo.first_name,
      phone: userInfo.phone ?? undefined,
      surname: userInfo.last_name,
    });

    setEditPersonalInfo(false);
  }, [reset, userInfo]);

  const validatePhone = useCallback(
    (phone: string) => {
      try {
        return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
      } catch (error) {
        return false;
      }
    },
    [phoneUtil]
  );

  useEffect(() => {
    setIsPhoneValid(validatePhone(phone));
  }, [phone, validatePhone]);
  const onSubmit: SubmitHandler<validationSchema> = useCallback(
    async (data) => {
      if (!isPhoneValid) return;

      const errors = await handleEdit(data);
      setEditError(errors);

      setEditPersonalInfo(false);
    },
    [handleEdit, isPhoneValid]
  );

  useEffect(() => {
    if (editError?.some((error: EditErrorItem) => error !== null)) {
      const timeoutId = setTimeout(() => {
        setEditError(Array(setEditError.length).fill(null));
      }, 10000);
      return () => clearTimeout(timeoutId);
    }
  }, [setEditError, editError]);

  return (
    <Box w="full" h="full">
      <Flex my="3" alignItems="center" justifyContent="space-between" w="full">
        <Flex whiteSpace="nowrap">
          <Text
            fontWeight="600"
            lineHeight="20px"
            color="black.active"
            fontSize="24px"
          >
            {t("personal_information")}
          </Text>
        </Flex>
        {!editPersonalInfo ? (
          <Button
            cursor="pointer"
            variant="unstyled"
            type="submit"
            onClick={() => {
              setEditPersonalInfo(!editPersonalInfo);
            }}
          >
            <Flex alignItems="center" justifyContent="center">
              <EditSquareIcon width="40px" height="40px" />
            </Flex>
          </Button>
        ) : (
          <Flex>
            <Button
              w="128px"
              h="36px"
              bg="red.main"
              border-radius="3px"
              color="white"
              onClick={() => handleCancel()}
              _hover={{
                opacity: "0.6",
              }}
            >
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="600" fontSize="15px" lineHeight="14px">
                  {t("cancel")}
                </Text>
              </Flex>
            </Button>
            <Button
              w="128px"
              h="36px"
              bg="green.label"
              border-radius="3px"
              color="white"
              onClick={() => handleSubmit(onSubmit)()}
              _hover={{
                opacity: "0.6",
              }}
              ml="2"
              type="submit"
            >
              <Flex alignItems="center" justifyContent="center">
                <Text fontWeight="600" fontSize="15px" lineHeight="14px">
                  {t("save")}
                </Text>
              </Flex>
            </Button>
          </Flex>
        )}
      </Flex>

      <Box mt="8" mb="0">
        <form
          style={{
            border: "none",
            width: "90%",
            display: "flex",
            height: "min-content",
            position: "relative",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid
            w="full"
            templateRows="repeat(1, 1fr)"
            templateColumns="repeat(3, 1fr)"
            rowGap="20px"
            columnGap="40px"
          >
            <GridItem colSpan={1}>
              <FormControl
                isInvalid={!!errors.name}
                borderRadius="6px"
                width="full"
                transition="borderColor 0.6s ease"
                h="100px"
              >
                <FormLabel
                  pos="absolute"
                  top="4px"
                  fontSize="16px"
                  zIndex="9"
                  ml="2.5"
                  px="4"
                  fontWeight="400"
                  lineHeight="1"
                  pr="8"
                  bg="white"
                >
                  {t("name")}
                </FormLabel>
                <Input
                  border={
                    editPersonalInfo ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                  }
                  maxLength={20}
                  w="full"
                  mt="3"
                  p="4"
                  color="gray.passive"
                  disabled={!editPersonalInfo}
                  variant="unstyled"
                  _disabled={{
                    color: "gray.passive",
                  }}
                  type="text"
                  _placeholder={{
                    color: "gray.passive",
                  }}
                  {...register("name")}
                  placeholder={userInfo.first_name}
                />

                {editError?.find(
                  (item: EditErrorItem) => item.path === "name"
                ) && (
                  <Text
                    mt="5px"
                    fontSize="14px"
                    fontWeight="500"
                    color="red.main"
                    ml="1"
                  >
                    {
                      editError?.find(
                        (item: EditErrorItem) => item.path === "name"
                      )?.message?.name
                    }
                  </Text>
                )}
                {editPersonalInfo && (
                  <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>

            <GridItem colSpan={1}>
              <FormControl
                borderRadius="6px"
                isInvalid={!!errors.surname}
                width="full"
                h="100px"
              >
                <FormLabel
                  pos="absolute"
                  top="4px"
                  fontSize="16px"
                  zIndex="9"
                  ml="2.5"
                  px="4"
                  fontWeight="400"
                  lineHeight="1"
                  pr="8"
                  bg="white"
                >
                  {t("surname")}
                </FormLabel>

                <Input
                  border={
                    editPersonalInfo ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                  }
                  transition="borderColor 0.6s ease"
                  maxLength={20}
                  variant="unstyled"
                  w="full"
                  mt="3"
                  p="4"
                  disabled={!editPersonalInfo}
                  _disabled={{
                    color: "gray.passive",
                  }}
                  color="gray.passive"
                  type="text"
                  _placeholder={{
                    color: "gray.passive",
                  }}
                  {...register("surname")}
                  placeholder={userInfo.last_name}
                />
                {editError?.find(
                  (item: EditErrorItem) => item.path === "surname"
                ) && (
                  <Text
                    mt="5px"
                    fontSize="14px"
                    fontWeight="500"
                    color="red.main"
                    ml="1"
                  >
                    {
                      editError?.find(
                        (item: EditErrorItem) => item.path === "surname"
                      )?.message?.surname
                    }
                  </Text>
                )}
                {editPersonalInfo && (
                  <FormErrorMessage>{errors.surname?.message}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>

            <GridItem colSpan={1}>
              <FormControl
                mt="3"
                isInvalid={!!errors.phone}
                borderRadius="6px"
                transition="borderColor 0.6s ease"
                w="full"
                h="100px"
                pos="relative"
              >
                <FormLabel
                  pos="absolute"
                  top="-10px"
                  fontSize="16px"
                  zIndex="9"
                  ml="2.5"
                  px="4"
                  fontWeight="400"
                  lineHeight="1"
                  pr="8"
                  bg="white"
                >
                  {t("phone_number")}
                </FormLabel>
                <Box
                  border={
                    editPersonalInfo ? "1px solid #57AAEB" : "1px solid #EBEDF2"
                  }
                  borderRadius="6px"
                  pl="4"
                  py="2.5"
                  sx={{
                    "& .react-international-phone-input": {
                      background: "white",
                      border: "none",
                      width: "full",
                      color: "gray.passive",
                    },
                    "& .react-international-phone-input--disabled": {
                      background: "white",
                      border: "none",
                      width: "full",
                      color: "gray.passive",
                    },
                    "& .react-international-phone-country-selector-button": {
                      background: "white",
                      width: "full",
                      color: "gray.passive",
                      border: "none",
                    },
                  }}
                >
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        hideDropdown={true}
                        disabled={!editPersonalInfo}
                        defaultCountry="tr"
                        onChange={(value) => {
                          field.onChange(value);
                          setPhone(value);
                        }}
                        value={field.value}
                      />
                    )}
                  />
                </Box>
                {editError?.find(
                  (item: EditErrorItem) => item.path === "phone"
                ) && (
                  <Text
                    mt="5px"
                    fontSize="14px"
                    fontWeight="500"
                    color="red.main"
                    ml="1"
                  >
                    {
                      editError?.find(
                        (item: EditErrorItem) => item.path === "phone"
                      )?.message?.phone
                    }
                  </Text>
                )}
                {!isPhoneValid && editPersonalInfo && (
                  <Text
                    whiteSpace="nowrap"
                    color="red.main"
                    fontSize="14px"
                    position="relative"
                    top="2"
                  >
                    {t("page.settings.profile.phone-validation")}
                  </Text>
                )}
                {editPersonalInfo && (
                  <FormErrorMessage>{errors.phone?.message}</FormErrorMessage>
                )}
              </FormControl>
            </GridItem>
          </Grid>
        </form>
      </Box>
    </Box>
  );
};
