import React from "react";

export const SearchRightIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.2594 0C8.88839 0 4.51875 4.36964 4.51875 9.74061C4.51875 12.1734 5.41528 14.4008 6.89527 16.1097L0.205922 22.7997C-0.0686719 23.0743 -0.068625 23.5195 0.205969 23.7941C0.480563 24.0687 0.925734 24.0686 1.20033 23.7941L7.88958 17.1041C9.59859 18.5845 11.8262 19.4812 14.2594 19.4812C19.6304 19.4812 24 15.1116 24 9.74061C24 4.36964 19.6304 0 14.2594 0ZM14.2594 18.075C9.66375 18.075 5.925 14.3362 5.925 9.74061C5.925 5.14504 9.6638 1.40625 14.2594 1.40625C18.855 1.40625 22.5938 5.14504 22.5938 9.74061C22.5938 14.3362 18.855 18.075 14.2594 18.075Z"
        fill="#B7BABF"
      />
    </svg>
  );
};
