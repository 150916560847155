import { APISchemas } from "@/api";
import { Icon, IconButton, Text, Tooltip, useToast } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Forms, useFormStore } from "../form-store";
import { CheckAllIcon } from "@/assets/icons/check-all-icon";
import emojiRegex from "emoji-regex";
import { useTranslation } from "react-i18next";

export const SetToMainManipulator: FC<{
  channel: APISchemas["Channel"];
}> = ({ channel }) => {
  const { t } = useTranslation();
  const [setMainForm, setAllFormStates, setSelectedPlatform, fireEvent] =
    useFormStore((f) => [
      f.setMainForm,
      f.setAllFormStates,
      f.setSelectedPlatform,
      f.fireEvent,
    ]);

  const toast = useToast();

  const targetForm = useRef<Forms>();

  const setAllToMain = useCallback(() => {
    if (!targetForm.current) {
      toast({
        title: t("errors.there-is-no-content-in-form"),
        status: "warning",
      });
      return;
    }

    setAllFormStates({
      sync: true,
    });

    setMainForm({
      main: {
        ...targetForm.current,
      },
    });

    setSelectedPlatform(undefined);
    fireEvent("setMain");
  }, [fireEvent, setAllFormStates, setMainForm, setSelectedPlatform, t, toast]);

  useEffect(() => {
    const unsub = useFormStore.subscribe((state) => {
      targetForm.current = state.forms[channel.id.toString()];
    });

    return () => unsub();
  }, [channel]);
  return (
    <Tooltip label={t("dev.apply-to-all")} placement="top">
      <IconButton
        aria-label="apply to all"
        bg="gray.whiteSmoke"
        color="black.dark"
        fontSize="xs"
        fontWeight="bold"
        onClick={setAllToMain}
        variant="unstyled"
        rounded="full"
        size="none"
        w="9"
        h="9"
        _active={{
          mixBlendMode: "difference",
        }}
        icon={
          <Icon boxSize="5">
            <CheckAllIcon />
          </Icon>
        }
      ></IconButton>
    </Tooltip>
  );
};

const emojiRegx = emojiRegex();
export const TwitterTextLength: FC<{
  textAreaRef: HTMLTextAreaElement | null;
}> = ({ textAreaRef }) => {
  const textAreaInterval = useRef<NodeJS.Timeout>();

  const [charCount, setCharCount] = useState<number>(0);
  const charCountRef = useRef<number>(0);

  useEffect(() => {
    if (!textAreaRef) {
      return;
    }

    if (textAreaInterval.current) {
      clearInterval(textAreaInterval.current);
    }

    textAreaInterval.current = setInterval(() => {
      const original = textAreaRef.value ?? "";

      const text = original.replaceAll(emojiRegx, "");
      const matches = original.matchAll(emojiRegx);

      let emojiCounter = 0;
      while (!matches.next().done) {
        emojiCounter++;
      }
      const count = Math.max(text.length + emojiCounter * 2, 0);

      if (charCountRef.current === count) {
        return;
      }
      if (count > 280) {
        textAreaRef.innerHTML =
          original.slice(0, 280) +
          `<span backgroundColor="red">${original.slice(280)}</span>`;
      }

      charCountRef.current = count;

      setCharCount(count);
    }, 250);

    return () => {
      if (textAreaInterval.current) {
        clearInterval(textAreaInterval.current);
      }
    };
  }, [textAreaRef]);

  return (
    <>
      <Text color={charCount > 280 ? "red" : undefined}>{charCount} </Text>
      <Text ml="1">/280</Text>
    </>
  );
};
