import { Button } from "@chakra-ui/react";
import {
  setSeconds,
  addYears,
  addMonths,
  addWeeks,
  addDays,
  addMinutes,
} from "date-fns";
import { FC, PropsWithChildren, useCallback } from "react";

export const QuickDateButton: FC<
  PropsWithChildren<{
    quickKey: string;
    onClick: (date: Date) => void;
    years?: number;
    months?: number;
    weeks?: number;
    days?: number;
    minutes?: number;
    date?: Date;
  }>
> = ({
  children,
  years,
  months,
  weeks,
  days,
  minutes,
  date,
  quickKey,
  onClick,
}) => {
  const onClickWrap = useCallback(() => {
    const now = setSeconds(Date.now(), 0);
    if (typeof years === "number") {
      return onClick(addYears(now, years));
    }
    if (typeof months === "number") {
      return onClick(addMonths(now, months));
    }
    if (typeof weeks === "number") {
      return onClick(addWeeks(now, weeks));
    }

    if (typeof days === "number") {
      return onClick(addDays(now, days));
    }
    if (typeof minutes === "number") {
      return onClick(addMinutes(now, minutes));
    }

    if (date) {
      return onClick(date);
    }

    console.error(
      "any date specified in quick date button with key:",
      quickKey
    );
  }, [date, days, minutes, months, onClick, quickKey, weeks, years]);

  const [currKey, key] = quickKey.split(":");
  const isActive = currKey === key;
  return (
    <Button
      variant="unstyled"
      bg={isActive ? "#345A79" : "white"}
      color={isActive ? "white" : "#353B48"}
      fontWeight="semibold"
      fontSize="sm"
      boxShadow="0px 4px 20px 2px rgba(191, 191, 191, 0.25)"
      onClick={onClickWrap}
    >
      {children}
    </Button>
  );
};
