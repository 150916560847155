import { APISchemas, POST, PATCH } from "@/api";
import { useSearchParams } from "react-router-dom";
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";

import {
  Center,
  useToast,
  Text,
  InputLeftElement,
  Box,
  Flex,
  Modal,
  Input,
  ModalContent,
  useDisclosure,
  Button,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Divider,
  InputGroup,
} from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/react";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { LinkedinWhiteIcon } from "@/assets/brands";
import { CloseShapeIcon } from "@/assets/icons/close-shape-icon";
import { useNavigate } from "react-router-dom";
import { SearchRightIcon } from "@/assets/icons/search-right-icon";
import { useTranslation } from "react-i18next";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { CallbackAccountItem } from "@/components/callback-account-item";

type FacebookData = {
  total_limit: number;
  channel_count: number;
  channel: APISchemas["Channel"][];
};

export const LinkedinCallback = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [data, setData] = useState<FacebookData>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [params] = useSearchParams();
  const toast = useToast();
  const { onClose } = useDisclosure();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const requestSent = useRef<boolean>(false);

  const filteredData = useMemo(() => {
    return searchInput
      ? data?.channel.filter((item) =>
          item.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      : data?.channel;
  }, [data?.channel, searchInput]);

  const activeOrganization = useAuthStore((s) => s.activeOrganization);

  if (!activeOrganization?.id) {
    toast({
      status: "error",
      title: t("errors.user-has-no-active-organization"),
    });
  }

  const lengthOfChannel = data?.channel.length ?? 0;
  useEffect(() => {
    if (!activeOrganization) return;

    if (requestSent.current) {
      return;
    }

    requestSent.current = true;

    POST("/linkedin/auth/callback/", {
      body: {
        org_id: activeOrganization.id,
        auth_code: params.get("code") ?? "",
      },
    }).then(({ data, error }) => {
      if (error) {
        setError(true);
        setLoading(false);
        setSuccess(false);
        toast({
          status: "error",
          description: error?.detail || t("errors.unexpected_error"),
        });
        return;
      }

      setData(data);

      setLoading(false);
      setSuccess(true);
    });
  }, [activeOrganization, params, t, toast]);

  const editUserStatus = useCallback(
    async (
      itemData: APISchemas["Channel"],
      status: "active" | "revoked" | "inactive" | undefined
    ) => {
      if (!activeOrganization?.id) {
        return;
      }

      if (!status) {
        return;
      }

      const { response, data, error } = await PATCH(
        "/org/{org_pk}/channels/{id}/",
        {
          params: {
            path: {
              org_pk: activeOrganization?.id.toString(),
              id: itemData.id,
            },
          },
          body: {
            status: status,
          },
        }
      );

      if (response.ok) {
        setData((state) => {
          if (!state) return state;
          const targetChannel = state?.channel.findIndex(
            (item) => item.id === itemData.id
          );
          if (targetChannel === -1) return state;

          state.channel[targetChannel].status = status;
          return {
            ...state,
            channel_count: data?.last_channel_count ?? 0,
            channel: [...state.channel],
          };
        });
      }
      if (error) {
        toast({
          description: error.detail || t("errors.unexpected_error"),
          status: "error",
        });
        return;
      }
    },
    [activeOrganization, t, toast]
  );

  return loading ? (
    <Center w="100vw" h="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Center>
  ) : (
    <Box
      bg="white.bg"
      justifyContent="center"
      alignItems="center"
      width="100vw"
      height="100vh"
    >
      <Modal
        closeOnOverlayClick={false}
        isOpen={true}
        onClose={onClose}
        size="4xl"
      >
        <ModalContent bg="white" width="700px" height="700px">
          <ModalHeader>Linkedin {t("page.callback.profiles")}</ModalHeader>
          <ModalCloseButton onClick={() => navigate("/channels/")} />
          <ModalBody>
            {success && (
              <Box w="full" height="full">
                <Flex alignItems="center">
                  <Flex
                    h="51px"
                    w="full"
                    borderRadius="6px"
                    p="2"
                    border="1px solid #026FA8"
                    bg="#E4F6FF"
                    alignItems="center"
                  >
                    <CloseShapeIcon width="24px" height="30px" />

                    <Text fontSize="16px" color="#026FA8" ml="4">
                      {t("page.callback.you-have")}{" "}
                      <Text
                        fontSize="18x"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        as="span"
                      >
                        {Number(data?.total_limit) -
                          Number(data?.channel_count)}
                      </Text>{" "}
                      {t("page.callback.profiles-left-out-of")}{" "}
                    </Text>
                  </Flex>
                  <Button
                    h="51px"
                    w="120px"
                    border="1px solid #50CD89"
                    bgColor="rgba(139, 255, 192, 0.31)"
                    borderRadius="6px"
                    ml="3"
                    fontSize="14px"
                    fontWeight="600"
                    color="#50CD89"
                    onClick={() => navigate("/membership/plans")}
                  >
                    {t("upgrade")}
                  </Button>
                </Flex>

                <InputGroup
                  mt="3"
                  alignItems="center"
                  justifyContent="center"
                  h="51px"
                  border="1px solid #B7BABF"
                  borderRadius="6px"
                >
                  <InputLeftElement h="full">
                    <SearchRightIcon
                      width="24px"
                      height="24px"
                      color="#B7BABF"
                    />
                  </InputLeftElement>

                  <Input
                    h="full"
                    fontSize="15px"
                    placeholder={t("search-profiles")}
                    type="search"
                    bg="#F6F7F9"
                    onChange={(e) => setSearchInput(e.target.value)}
                    value={searchInput}
                  />
                </InputGroup>
                <OverlayScrollbarsComponent
                  options={{
                    scrollbars: {
                      autoHide: "scroll",
                    },
                  }}
                >
                  <Box
                    h="275px"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    px="5"
                    overflowY="auto"
                    my="2"
                  >
                    {success &&
                      lengthOfChannel > 0 &&
                      filteredData?.map(
                        (item: APISchemas["Channel"], index: number) => (
                          <React.Fragment key={item.id}>
                            <CallbackAccountItem
                              item={item}
                              key={item.id}
                              onCheck={() => editUserStatus(item, "active")}
                              onCancel={() => editUserStatus(item, "inactive")}
                            />
                            {index !== lengthOfChannel - 1 && (
                              <Box
                                w="100%"
                                h="1px"
                                border="1px"
                                borderStyle="dotted"
                                borderColor="gray.500"
                              />
                            )}
                          </React.Fragment>
                        )
                      )}
                  </Box>
                </OverlayScrollbarsComponent>

                {success && lengthOfChannel < 0 && (
                  <Box
                    display="flex"
                    w="full"
                    h="60%"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box textAlign="center" py="4">
                      <Text fontSize="20px" fontWeight="500">
                        {t("errors.no-profile-found-linkedin")}{" "}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            )}

            {error && (
              <Box
                display="flex"
                w="full"
                h="100%"
                alignItems="center"
                justifyContent="center"
              >
                <Box textAlign="center" py="4">
                  <Text fontSize="20px" fontWeight="600" color="#E52625">
                    {t("errors.an-error-occurred")}...
                  </Text>
                  <Text fontSize="20px" fontWeight="500">
                    {t("errors.please-try-again-later")}
                  </Text>
                </Box>
              </Box>
            )}
          </ModalBody>

          <ModalFooter>
            <Box>
              <Flex justifyContent="flex-end" mb="4">
                <Button
                  color="white"
                  w="140px"
                  h="35px"
                  bgColor="#345A79"
                  mr={3}
                  borderRadius="3px"
                  onClick={window.close}
                >
                  {t("cancel")}
                </Button>
                <Button
                  color="white"
                  w="140px"
                  h="35px"
                  bg="#50CD89"
                  onClick={window.close}
                  borderRadius="3px"
                >
                  {t("done")}
                </Button>
              </Flex>
              <Box mb="10">
                <Divider bgColor="#B7BABF" h="1px" />
                <Flex px="3">
                  <Box
                    w="70%"
                    justifyContent="center"
                    alignItems="center"
                    my="4"
                  >
                    <Text color="#353B48" fontSize="17px" fontWeight="700">
                      {t("page.callback.linkedin-account-info")}
                    </Text>
                    <Text color="#353B48" fontSize="17px" fontWeight="500">
                      {t("page.callback.linkedin-account-info2")}
                    </Text>
                  </Box>
                  <Box
                    w="40%"
                    alignItems="flex-end"
                    justifyContent="center"
                    display="flex"
                    flexDir="column"
                  >
                    <Box justifyContent="center" alignItems="center">
                      <Button
                        w="170px"
                        height="40px"
                        leftIcon={
                          <Box pos="relative" right="6px">
                            <LinkedinWhiteIcon width="30px" height="30px" />
                          </Box>
                        }
                        fontWeight="600"
                        fontSize="15px"
                        bg="blue.linkedIn"
                        borderRadius="3px"
                        cursor="pointer"
                        color="white"
                        _hover={{
                          bg: "blue.middle",
                        }}
                        onClick={window.close}
                      >
                        {t("reconnect")}
                      </Button>
                    </Box>
                  </Box>
                </Flex>

                <Divider bgColor="#B7BABF" h="1px" />
              </Box>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
