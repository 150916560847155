import { FC, useMemo, useRef, useState, useEffect } from "react";
import { HStack } from "@chakra-ui/react";
import {
  eachDayOfInterval,
  eachMonthOfInterval,
  addDays,
  subDays,
  addWeeks,
  addMonths,
  eachWeekOfInterval,
  subWeeks,
  isSameDay,
  isSameWeek,
  isSameMonth,
  subMonths,
} from "date-fns";
import { DateBox } from "./date-box";
import { OverlayScrollbarsComponentRef } from "overlayscrollbars-react";
import { i18nformat } from "@/utils/misc";
import { useConfigStore } from "@/context/config-store/config-store";

type CalendarProps = {
  active: Date;
  selectedDate: Date;
  prevSelected?: Date;
  calendarMode: "days" | "weeks" | "months";
  onPress: (item: Date) => void;
};

export const Calendar: FC<CalendarProps> = ({
  calendarMode,
  onPress,
  active,
  prevSelected,
  selectedDate,
}) => {
  const [targetBox, setTargetBox] = useState<HTMLButtonElement | null>(null);
  const scrollBarRef = useRef<OverlayScrollbarsComponentRef | null>(null);
  const stackRef = useRef<HTMLDivElement | null>(null);
  const [language] = useConfigStore((c) => [c.language]);

  const dates = useMemo(() => {
    if (calendarMode === "days") {
      const start = subDays(prevSelected ?? selectedDate, 5);
      const end = addDays(prevSelected ?? selectedDate, 5);

      return eachDayOfInterval({ start, end }).map((date) => ({
        first: i18nformat(date, "EEEE"),
        second: i18nformat(date, "d"),
        fullDate: date,
        compare: isSameDay,
      }));
    }

    if (calendarMode === "weeks") {
      const start = subWeeks(prevSelected ?? selectedDate, 7);
      const end = addWeeks(prevSelected ?? selectedDate, 7);
      return eachWeekOfInterval({ start, end }).map((date) => ({
        first: i18nformat(date, "MMMM"),
        second: `${i18nformat(date, "dd")}-${i18nformat(
          addWeeks(date, 1),
          "dd"
        )}`,
        fullDate: date,
        compare: isSameWeek,
      }));
    }

    const start = subMonths(prevSelected ?? selectedDate, 7);
    const end = addMonths(prevSelected ?? selectedDate, 7);

    return eachMonthOfInterval({ start, end }).map((date) => ({
      first: i18nformat(date, "MMMM"),
      second: i18nformat(date, "yyyy"),
      fullDate: date,
      compare: isSameMonth,
    }));
  }, [calendarMode, prevSelected, selectedDate, language]);

  useEffect(() => {
    if (!targetBox) {
      return;
    }
    if (!scrollBarRef.current) {
      return;
    }
    if (!stackRef.current) {
      return;
    }
    const instance = scrollBarRef.current.osInstance();
    if (!instance) {
      return;
    }
    const element = scrollBarRef.current.getElement();
    if (!element) {
      return;
    }
    const { viewport } = instance.elements();
    viewport.scrollTo({
      left:
        targetBox.offsetLeft -
        (viewport.offsetWidth - targetBox.offsetWidth) / 2,
      behavior: "smooth",
    });
  }, [prevSelected, targetBox]);

  return (
    <HStack
      //filter="drop-shadow(0px 0px 5px 2px #345A7908)"
      border="1px solid"
      borderColor="gray.athens"
      bgColor="gray.bg"
      ref={stackRef}
      justifyContent="space-between"
      h="81px"
      pos="relative"
      borderRadius="3px"
      boxShadow="2px 0px 2px 0px rgba(52, 90, 121, 0.05)"
    >
      {dates.map((date) => {
        const isActive = date.compare(active, date.fullDate);
        return (
          <DateBox
            date={date.fullDate}
            ref={isActive ? setTargetBox : undefined}
            active={isActive}
            key={date.fullDate.toISOString()}
            primary={date.first}
            secondary={date.second}
            onPress={() => {
              onPress(date.fullDate);
            }}
          />
        );
      })}
    </HStack>
  );
};
