export const FlagIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="50" cy="50" r="50" fill="#33373D" />
      <path
        d="M50.8929 23.8099C42.9584 19.3085 34.6194 18.8875 26.6 22.4812V20H23V81H26.6V58.7525C33.9883 54.905 41.7471 55.0247 49.1071 59.1999C53.5209 61.7043 58.0596 62.9556 62.6 62.9556C67.1386 62.9556 71.6791 61.7042 76.0929 59.1999L77 58.6851V22.2811L74.3071 23.8099C66.65 28.1554 58.5535 28.1554 50.8929 23.8099Z"
        fill="white"
      />
    </svg>
  );
};
