import { Box, Button, Flex, IconButton, Input, Text } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { HelpCenterAccordion } from "../components/accordion/accordion";
import { PostEffectFaqIcon } from "@/assets/icons/posteffect-faq-icon";
import { SearchGuideIcon } from "@/assets/icons/search-guide-icon";
import { SearchArrowIcon } from "@/assets/icons/search-arrow-icon";
import { useTranslation } from "react-i18next";
import { colors } from "@/theme/colors";

type FAQ = {
  title: string;
  question: string;
  answer: string;
};

interface GuideComponentProps {
  faqList: FAQ[];
}

export const Guide: React.FC<GuideComponentProps> = ({ faqList }) => {
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedTitle, setSelectedTitle] = useState<string>("");

  const { t } = useTranslation();

  const groupedFaqList = useMemo(() => {
    return faqList.reduce(
      (acc, faq) => {
        if (!acc[faq.title]) {
          acc[faq.title] = [];
        }
        acc[faq.title].push(faq);
        return acc;
      },
      {} as Record<string, FAQ[]>
    );
  }, [faqList]);
  useEffect(() => {
    if (Object.keys(groupedFaqList).length > 0 && !selectedTitle) {
      setSelectedTitle(Object.keys(groupedFaqList)[0]);
    }
  }, [groupedFaqList, selectedTitle]);

  const filteredListTitle = useMemo(() => {
    if (searchInput) {
      const lowerSearchInput = searchInput.toLowerCase();
      return faqList.filter(
        (faq) => faq.title.toLowerCase().includes(lowerSearchInput)
        // ||
        // faq.question.toLowerCase().includes(lowerSearchInput) ||
        // faq.answer.toLowerCase().includes(lowerSearchInput)
      );
    } else if (selectedTitle) {
      return groupedFaqList[selectedTitle] || [];
    } else {
      return [];
    }
  }, [faqList, searchInput, selectedTitle, groupedFaqList]);

  return (
    <Flex w="full" columnGap="12px">
      <Box flex="1" pos="relative" h="80vh">
        <Flex
          w="full"
          justifyContent="center"
          alignItems="center"
          borderRadius="6px"
          mb="20px"
          bg="white"
          p="8px"
        >
          <IconButton
            variant="unstyled"
            size="sm"
            aria-label="search-button"
            icon={<SearchGuideIcon />}
            ml="5px"
          />
          <Input
            variant="unstyled"
            placeholder={t("page.help-center.search")}
            _placeholder={{ color: colors.gray.silver }}
            onChange={(e) => {
              setSearchInput(e.target.value);
              setSelectedTitle("");
            }}
            value={searchInput}
            color={colors.black.light}
            fontWeight="400"
          />
        </Flex>

        {Object.keys(groupedFaqList)
          .filter((title) =>
            title.toLowerCase().includes(searchInput.toLowerCase())
          )
          .map((title, index) => (
            <Box key={index}>
              <Button
                w="full"
                h="48px"
                variant="unstyled"
                textAlign="left"
                bg={selectedTitle === title ? "blue.light" : "none"}
                borderRadius="3px"
                border={selectedTitle === title ? "1px solid" : "none"}
                borderColor={selectedTitle === title ? "blue.shiny" : "none"}
                px={selectedTitle === title ? "6px" : "3px"}
                onClick={() => {
                  setSelectedTitle(title);
                }}
              >
                <Flex justifyContent="space-between" alignItems="center">
                  <Text
                    color={
                      selectedTitle === title ? "blue.shiny" : "black.active"
                    }
                    fontWeight="500"
                    opacity={selectedTitle === title ? "1" : "0.4"}
                  >
                    {title}
                  </Text>
                  <Box display={selectedTitle === title ? "block" : "none"}>
                    <SearchArrowIcon fill={colors.blue.shiny} />
                  </Box>
                </Flex>
              </Button>
            </Box>
          ))}
        {/* <ContactUsComponent color={colors.blue.shiny} /> */}
      </Box>

      <Flex flex="3" borderRadius="3px" h="80vh" overflowY="auto">
        <HelpCenterAccordion
          children={<PostEffectFaqIcon />}
          accordionData={filteredListTitle}
        />
      </Flex>
    </Flex>
  );
};
