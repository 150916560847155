import { APISchemas } from "@/api";
import { Action } from "../form-store";

export const handleError = (
  key: string,
  error: APISchemas["DefaultException"],
  setError: Action["setGlobalError"]
) => {
  if (error?.detail) {
    setError(key, {
      message: error.detail,
    });

    return;
  }

  if (error?.issues) {
    for (const issue of error.issues) {
      const resolvedPath = issue.path
        ? issue.path === "body"
          ? "content"
          : issue.path === "media"
            ? "files"
            : "root"
        : "root";

      setError(key, {
        field: resolvedPath,
        message: issue.message,
      });
    }

    return;
  }
};
