import { PACKAGE_VERSION_N } from "@/constant";
import { i18n } from "@/i18n";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { useTriggerStore } from "../trigger-store/trigger-store";

interface ConfigState {
  version: string;
  clockNotation: "12" | "24";
  language?: "tr" | "en";
  sidebar: boolean;
  updateVersion: (version: string) => void;
  updateClockNotation: (notation: "12" | "24") => void;
  changeLanguage: (lang?: "tr" | "en") => Promise<void>;
  toggleSidebarState: () => void;
}

export const useConfigStore = create<ConfigState>()(
  persist<ConfigState>(
    (set) => ({
      version: "0.0.0",
      sidebar: true,
      clockNotation: "24",

      updateClockNotation(notation) {
        set({
          clockNotation: notation,
        });
      },
      changeLanguage: async (lang) => {
        await i18n.changeLanguage(lang);
        await set({
          language: i18n.language.includes("tr") ? "tr" : "en",
        });

        useTriggerStore.getState().triggerNotification();
      },
      toggleSidebarState: () => {
        set((state) => ({ sidebar: !state.sidebar }));
      },
      updateVersion(version) {
        set({ version });
      },
    }),
    {
      name: "config-storage",
      version: PACKAGE_VERSION_N,
    }
  )
);
