import React from "react";

export const ClockIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.11279 1C8.95518 1 11.2594 3.3042 11.2594 6.14659C11.2594 8.98899 8.95518 11.2932 6.11279 11.2932"
        stroke="#353B48"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.11279 2.92969V6.14631L8.68609 8.71961"
        stroke="#353B48"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.25153 1.34636C4.25153 1.34636 4.37567 1.29666 4.51285 1.2538C4.65002 1.21095 4.80908 1.16602 4.80908 1.16602"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M2.68854 2.30364C2.68854 2.30364 2.78742 2.21361 2.90089 2.12542C3.01437 2.03724 3.14763 1.93945 3.14763 1.93945"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M1.55933 3.74605C1.55933 3.74605 1.62041 3.62709 1.69583 3.50475C1.77124 3.38241 1.86182 3.24414 1.86182 3.24414"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M1.00684 5.49208C1.00684 5.49208 1.0224 5.35925 1.05019 5.21826C1.07798 5.07726 1.11439 4.91602 1.11439 4.91602"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M1.101 7.32138C1.101 7.32138 1.06907 7.19152 1.04571 7.04972C1.02235 6.90793 1 6.74414 1 6.74414"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M1.82996 9.00336C1.82996 9.00336 1.75457 8.89291 1.68302 8.76827C1.61149 8.64362 1.5332 8.49805 1.5332 8.49805"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M3.10136 10.3225C3.10136 10.3225 2.99206 10.2455 2.8814 10.1538C2.77074 10.0621 2.64642 9.95312 2.64642 9.95312"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
      <path
        d="M4.75421 11.1125C4.75421 11.1125 4.62485 11.0786 4.48908 11.0314C4.35332 10.9843 4.19873 10.9258 4.19873 10.9258"
        stroke="#50CD89"
        strokeWidth="0.75"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="30 57"
      />
    </svg>
  );
};
