import { FC } from "react";

export const AddPlaylistIcon: FC = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="45"
      height="33"
      viewBox="0 0 45 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="45" height="33" rx="6" fill="#57AAEC" />
      <rect
        x="11"
        y="12.3959"
        width="13.0594"
        height="1.43661"
        fill="#FAFAFA"
      />
      <rect
        x="11"
        y="16.2177"
        width="10.0159"
        height="1.43661"
        fill="#FAFAFA"
      />
      <rect
        x="11"
        y="20.0392"
        width="10.0159"
        height="1.43661"
        fill="#FAFAFA"
      />
      <path
        d="M22.7228 17.6171C22.5282 17.6171 22.3579 17.5522 22.212 17.4225C22.0822 17.2765 22.0173 17.0981 22.0173 16.8873C22.0173 16.6926 22.0822 16.5386 22.212 16.425C22.3579 16.2953 22.5282 16.2304 22.7228 16.2304H32.7701C32.9647 16.2304 33.1269 16.2953 33.2566 16.425C33.4026 16.5548 33.4756 16.7251 33.4756 16.9359C33.4756 17.1143 33.4026 17.2765 33.2566 17.4225C33.1269 17.5522 32.9647 17.6171 32.7701 17.6171H22.7228ZM27.7343 22.8718C27.5072 22.8718 27.3207 22.7988 27.1748 22.6529C27.0288 22.5069 26.9558 22.3204 26.9558 22.0933V11.8028C26.9558 11.5595 27.0288 11.3649 27.1748 11.2189C27.337 11.073 27.5235 11 27.7343 11C27.9776 11 28.1641 11.073 28.2938 11.2189C28.4398 11.3649 28.5128 11.5514 28.5128 11.7785V22.0933C28.5128 22.3204 28.4398 22.5069 28.2938 22.6529C28.1479 22.7988 27.9614 22.8718 27.7343 22.8718Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};
