import { colors } from "@/theme/colors";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import React, { useState } from "react";

//düzenlenecek
interface TicketPopoverProps {
  w: string;
  selected?: string;
  onChange: (value: string) => void;
  data?: Array<{
    key: string;
    value: string;
    Icon?: React.ReactNode;
  }>;
}

export const TicketPopover: React.FC<TicketPopoverProps> = ({
  selected,
  w,
  onChange,
  data,
}) => {
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);

  //silinecek
  // const { data: OrganizationList } = useOrganization();
  // const [organization, setActiveOrg] = useAuthStore((s) => [
  //   s.activeOrganization,
  //   s.setActiveOrganization,
  // ]);list
  //silinecek

  const selectedData = data?.find((item) => item.key == selected);

  return (
    <Popover isOpen={popoverOpen} onClose={() => setPopoverOpen(false)}>
      <PopoverTrigger>
        <Button
          h="56px"
          w="full"
          bg="white"
          color="gray.smoke"
          border="0.05px solid rgba(212, 212, 212, 0.53)"
          borderRadius="4px"
          onClick={() => setPopoverOpen(!popoverOpen)}
        >
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Text
              textAlign="center"
              as="span"
              textOverflow="ellipsis"
              overflow="hidden"
              fontSize="14px"
            >
              {selectedData?.value ?? t("page.help-center.select")}
            </Text>
            {popoverOpen ? (
              <Box>
                <ChevronUpIcon color={colors.blue.shiny} boxSize={6} />
              </Box>
            ) : (
              <Box>
                <ChevronDownIcon color={colors.blue.shiny} boxSize={6} />
              </Box>
            )}
          </Flex>
        </Button>
      </PopoverTrigger>
      <PopoverContent bg="white" borderRadius="4px" p="0px" w={w}>
        <PopoverBody p="0px">
          {data?.map(({ key, value, Icon }) => (
            <React.Fragment key={key}>
              <Button
                px="2"
                _hover={{ bg: "gray.soft" }}
                variant="unstyled"
                textAlign="left"
                textOverflow="ellipsis"
                fontSize="14px"
                w="full"
                fontWeight="450"
                color="black.active"
                onClick={() => {
                  onChange(value);
                  setPopoverOpen(false);
                }}
                display="flex"
                justifyContent="left"
              >
                {Icon && Icon}
                <Text ml="5px">{value}</Text>
              </Button>
            </React.Fragment>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
