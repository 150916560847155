import React, { ReactNode } from "react";
import { Box, Flex, Text, Icon, Center, Divider } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

interface AccountSummaryItemProps {
  title: string;
  accountNumber: number;
  sharedPostNumber: number;
  queueNumber: number;
  titleBg?: string;
  children?: ReactNode;
}

export const AccountSummaryItem: React.FC<AccountSummaryItemProps> = ({
  title,
  // accountNumber,
  sharedPostNumber,
  queueNumber,

  children,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      h="152px"
      w="full"
      borderRadius="3px"
      boxShadow="2px 0px 2px 0px rgba(52, 90, 121, 0.05)"
      p="3"
      px="4"
      border="1px solid"
      borderColor="gray.athens"
      bgColor="white.normal"
    >
      <Flex
        // bgImage="url('/twitterb1.png')"
        //bgRepeat="no-repeat"
        //bgSize="cover"
        w="full"
        alignItems="center"
        borderRadius="3px"
      >
        <Icon w="30px" h="30px" mr="10px">
          {children}
        </Icon>

        <Text color="black.active" fontWeight="600" fontSize="14px">
          {title}
        </Text>
      </Flex>

      {/* <Flex px="4" align="center" justify="space-between">
        <Text fontSize="14px" fontWeight="600" mt="2">
          {t("accounts")}
        </Text>
        <Text fontWeight="bold">10</Text>
      </Flex> */}
      <Center>
        <Divider borderColor="gray.light" my="2" mt="3" w="full" />
      </Center>

      <Flex align="center" justify="space-between">
        <Text color="black.medium" fontSize="12px" fontWeight="500">
          {t("shared_post")}
        </Text>
        <Text color="black.active" textAlign="center" w="3ch">
          {sharedPostNumber}
        </Text>
      </Flex>
      <Center>
        <Divider borderColor="gray.light" w="full" my="2" />
      </Center>
      <Flex align="center" justify="space-between">
        <Text color="black.medium" fontSize="12px" fontWeight="500">
          {t("queue")}
        </Text>
        <Text color="black.active" textAlign="center" w="3ch">
          {queueNumber}
        </Text>
      </Flex>
    </Box>
  );
};
