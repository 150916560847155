import { Show } from "@chakra-ui/react";
import { FC } from "react";
import { Desktop } from "./desktop";

export const Analytics: FC = () => {
  return (
    <Show>
      <Desktop />
    </Show>
  );
};
