import { Box, Flex, useToast, Text } from "@chakra-ui/react";
import { TopbarNotifications } from "./components/topbar-notifications";
import { NotificationContainer } from "./components/notification-container.tsx";
import { useCallback, useMemo, useEffect } from "react";
import { useQuery } from "react-query";
import { PATCH, GET, APISchemas } from "@/api/index.ts";
import { useNotification } from "@/hooks/use-notification.ts";
import { useTranslation } from "react-i18next";
import { EmptyNotifications } from "@/assets/icons/empty-notifications.tsx";
import { useTriggerStore } from "@/context/trigger-store/trigger-store.ts";
import { useConfigStore } from "@/context/config-store/config-store.ts";

export const Notifications = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const language = useConfigStore((c) => c.language);
  const triggerNotification = useTriggerStore(
    (state) => state.triggerOrganization
  );

  const notificationChanges = useTriggerStore((s) => s.notificationChanges);

  const { data: Notifications, refetch } = useQuery(
    "notifications",
    async () => {
      const { data } = await GET("/notifications/", {
        params: {
          query: {
            page: 1,
            page_size: 30,
          },
        },
      });

      return data;
    },
    {
      select(data) {
        return data?.results?.map((d) => {
          const haveMedia = "media" in (d.given_object ?? {});

          if (haveMedia) {
            const media = d.given_object?.media as Array<APISchemas["Media"]>;

            if (!media) {
              return { ...d, image: undefined };
            }

            const firstMedia = media.at(0);

            return {
              ...d,
              image:
                firstMedia?.type === "image"
                  ? firstMedia.download_url
                  : firstMedia?.type === "video"
                    ? firstMedia.download_url.replace(
                        "original",
                        "preview.webp"
                      )
                    : undefined,
            };
          }

          return { ...d, image: undefined };
        });
      },
    }
  );

  useEffect(() => {
    refetch();
  }, [language, refetch]);

  const readNotifications = useCallback(async () => {
    const { response } = await PATCH("/notifications/bulk-read/", {});

    if (response.ok) {
      refetch();
      toast({
        status: "success",
        title: t("alert.success.notifications-deleted"),
      });
    }
    triggerNotification();
  }, [refetch, toast, t, triggerNotification]);

  const notifications = useNotification();
  const combinedNotifications = useMemo(() => {
    const combined = notifications
      ? [notifications, ...(Notifications ?? [])]
      : [...(Notifications ?? [])];
    return combined; //.filter((n) => n?.state.read === false);
  }, [notifications, Notifications]);

  useEffect(() => {
    if (notificationChanges) {
      refetch();
    }
  }, [notificationChanges, refetch]);

  return (
    <Box px="20px" minH="100vh" w="full" py="18px">
      <Flex h="64px" mb="18px">
        <TopbarNotifications
          onDelete={() => readNotifications()}
          onRefetch={() => refetch()}
        />
      </Flex>
      <Flex h="full">
        {/* <Box
          w="18%"
          mr="25px"
          h="740px"
          bg="white"
          borderRadius="6px"
          border="1px solid  #EBEDF2"
        >
          <FilterContainter />
        </Box> */}
        {combinedNotifications.length === 0 ? (
          <Box
            w="full"
            h="full"
            alignItems="center"
            justifyContent="center"
            display="flex"
            flexDirection="column"
          >
            <Text fontSize="30px" color="black.active" mt="10">
              {t("page.notifications.empty-notifications")}
            </Text>
            <EmptyNotifications width="30%" height="30%" />
          </Box>
        ) : (
          <Box w="full" h="full">
            <NotificationContainer Notifications={combinedNotifications} />
          </Box>
        )}
      </Flex>
    </Box>
  );
};
