import { HexagonErrorIcon } from "@/assets/icons/hexagon-error-icon";
import { Flex, Icon, Text } from "@chakra-ui/react";
import { FC } from "react";

export const StatusCard: FC<{ title?: string; description?: string }> = ({
  title,
  description,
}) => {
  if (!title && !description) {
    return null;
  }

  return (
    <Flex rounded="lg" px="3.5" py="4" w="full" bg="#FFF5F8">
      <Icon w="6" h="8" mx="1.5" my="0.5">
        <HexagonErrorIcon />
      </Icon>
      <Flex alignItems="center">
        <Text
          fontWeight="500"
          color="black.active"
          fontSize="14px"
          textTransform="capitalize"
        >
          {title?.replace(/_/g, " ")}
          <Text as="span" fontSize="14px" fontWeight="500" ml="1" color="black">
            {description}
          </Text>
        </Text>
      </Flex>
    </Flex>
  );
};
