import { Icon } from "@chakra-ui/react";
import { FC } from "react";

export const AddCoverLibraryIcon: FC = (
  props: React.SVGAttributes<SVGElement>
) => {
  return (
    <Icon w="21" h="21">
      <svg
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M17.1359 5.96172C17.0908 2.66675 14.3964 0 11.0909 0C9.6205 0 8.20388 0.534887 7.10211 1.50614C6.11878 2.37293 5.44612 3.53126 5.17974 4.80301C5.16028 4.8028 5.14099 4.80263 5.12174 4.80263C2.29767 4.80263 0 7.10026 0 9.92437C0 12.7485 2.29767 15.0461 5.12178 15.0461H8.6988C8.95328 15.0461 9.15969 14.8398 9.15969 14.5852C9.15969 14.3306 8.95328 14.1243 8.6988 14.1243H5.12178C2.80588 14.1243 0.921785 12.2402 0.921785 9.92437C0.921785 7.60851 2.80588 5.72442 5.12178 5.72442C5.24516 5.72442 5.37559 5.73104 5.52058 5.74468C5.76633 5.76768 5.98696 5.59289 6.02043 5.34807C6.18641 4.13157 6.78701 3.01271 7.71167 2.19759C8.64498 1.37483 9.84505 0.921743 11.0908 0.921743C13.9162 0.921743 16.2147 3.2203 16.2147 6.04559C16.2147 6.14732 16.2071 6.25217 16.1992 6.36318L16.1957 6.41037C16.1862 6.54595 16.2369 6.67887 16.3345 6.77355C16.432 6.86823 16.5661 6.91499 16.7016 6.90145C16.8242 6.88908 16.9478 6.88287 17.0692 6.88287C19.0656 6.88287 20.6899 8.50712 20.6899 10.5036C20.6899 12.5 19.0656 14.1242 17.0692 14.1242H13.3078C13.0533 14.1242 12.8469 14.3306 12.8469 14.5851C12.8469 14.8397 13.0533 15.046 13.3078 15.046H17.0692C19.5739 15.046 21.6116 13.0083 21.6116 10.5036C21.6116 8.02132 19.61 5.99756 17.1359 5.96172Z"
          fill="#33373D"
        />
        <path
          d="M11.0879 2.16602C9.16064 2.16602 7.5109 3.60627 7.25038 5.51611C7.21598 5.76832 7.39258 6.00068 7.64475 6.03508C7.66589 6.03795 7.68687 6.03939 7.7076 6.03939C7.93448 6.03939 8.13219 5.87181 8.16364 5.64076C8.36215 4.18535 9.61929 3.0878 11.0878 3.0878C11.3424 3.0878 11.5487 2.88148 11.5487 2.62691C11.5488 2.37238 11.3424 2.16602 11.0879 2.16602Z"
          fill="#33373D"
        />
        <path
          d="M13.189 11.2343L11.5125 9.7367C11.2235 9.47838 10.7875 9.47842 10.4983 9.73666L8.82183 11.2344C8.63196 11.4039 8.61559 11.6953 8.78514 11.8852C8.9547 12.0749 9.24604 12.0914 9.43594 11.9218L10.5445 10.9315V16.6016C10.5445 16.8562 10.7509 17.0625 11.0054 17.0625C11.26 17.0625 11.4663 16.8562 11.4663 16.6016V10.9314L12.575 11.9218C12.6629 12.0003 12.7726 12.039 12.8819 12.039C13.0086 12.039 13.1347 11.9871 13.2258 11.8851C13.3953 11.6953 13.3788 11.4039 13.189 11.2343Z"
          fill="#33373D"
        />
      </svg>
    </Icon>
  );
};
