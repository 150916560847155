export const FacebookWhiteIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z"
        fill="white"
      />
      <path
        d="M19.7521 13.2239L19.4504 15.8369C19.4379 15.9562 19.382 16.0666 19.2934 16.1474C19.2048 16.2282 19.0896 16.2736 18.9698 16.275H16.2425L16.2288 24.0222C16.2298 24.114 16.1945 24.2024 16.1307 24.2685C16.0669 24.3345 15.9797 24.3728 15.8879 24.375H13.1249C13.0789 24.375 13.0335 24.3658 12.9912 24.3478C12.9489 24.3298 12.9107 24.3035 12.8789 24.2704C12.847 24.2373 12.8222 24.1981 12.8059 24.1551C12.7896 24.1122 12.7821 24.0664 12.784 24.0205V16.275H10.7385C10.6743 16.2743 10.6108 16.261 10.5517 16.2358C10.4926 16.2106 10.439 16.174 10.3941 16.1281C10.3491 16.0822 10.3137 16.0279 10.2897 15.9683C10.2657 15.9087 10.2537 15.8449 10.2544 15.7807L10.2442 13.1676C10.2433 13.1031 10.2551 13.0391 10.2789 12.9792C10.3028 12.9193 10.3382 12.8647 10.3831 12.8185C10.4281 12.7723 10.4817 12.7353 10.5409 12.7099C10.6001 12.6844 10.6638 12.6708 10.7283 12.6699H12.784V10.1472C12.784 7.21875 14.5226 5.625 17.0726 5.625H19.1624C19.227 5.62567 19.2908 5.63908 19.3502 5.66448C19.4097 5.68988 19.4635 5.72675 19.5086 5.77299C19.5538 5.81924 19.5893 5.87393 19.6133 5.93395C19.6372 5.99396 19.6491 6.05812 19.6482 6.12273V8.32159C19.6491 8.3862 19.6372 8.45035 19.6133 8.51037C19.5893 8.57038 19.5538 8.62508 19.5086 8.67132C19.4635 8.71757 19.4097 8.75444 19.3502 8.77984C19.2908 8.80524 19.227 8.81865 19.1624 8.81932H17.8806C16.4948 8.81932 16.2254 9.50114 16.2254 10.4864V12.6699H19.2715C19.3407 12.6708 19.409 12.6863 19.4719 12.7154C19.5347 12.7446 19.5907 12.7867 19.6361 12.839C19.6815 12.8914 19.7153 12.9527 19.7353 13.019C19.7553 13.0854 19.7611 13.1552 19.7521 13.2239Z"
        fill="#3A559F"
      />
    </svg>
  );
};
