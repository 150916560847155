export const SearchArrowIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity="0.75"
        d="M5.44812 10.9449C5.29368 10.9458 5.14246 10.9007 5.01375 10.8153C4.88505 10.7299 4.78468 10.6081 4.72546 10.4655C4.66623 10.3228 4.65083 10.1658 4.68121 10.0144C4.71159 9.86293 4.78638 9.72398 4.89604 9.61521L9.01724 5.50178L4.89604 1.38835C4.76865 1.2396 4.70208 1.04825 4.70964 0.852554C4.7172 0.656855 4.79833 0.471214 4.93681 0.33273C5.0753 0.194246 5.26094 0.113119 5.45664 0.10556C5.65234 0.0980013 5.84368 0.164567 5.99243 0.291957L10.658 4.95747C10.8028 5.10316 10.8841 5.30024 10.8841 5.50567C10.8841 5.7111 10.8028 5.90818 10.658 6.05387L5.99243 10.7194C5.8476 10.863 5.65212 10.944 5.44812 10.9449Z"
      />
      <path
        opacity="0.13"
        d="M9.99109 10.9449C9.83664 10.9458 9.68543 10.9007 9.55672 10.8153C9.42802 10.7299 9.32765 10.6081 9.26843 10.4655C9.2092 10.3228 9.1938 10.1658 9.22418 10.0144C9.25456 9.86293 9.32934 9.72398 9.43901 9.61521L13.5602 5.50178L9.43901 1.38835C9.31162 1.2396 9.24505 1.04825 9.25261 0.852554C9.26017 0.656855 9.3413 0.471214 9.47978 0.33273C9.61826 0.194246 9.8039 0.113119 9.9996 0.10556C10.1953 0.0980013 10.3866 0.164567 10.5354 0.291957L15.2009 4.95747C15.3457 5.10316 15.427 5.30024 15.427 5.50567C15.427 5.7111 15.3457 5.90818 15.2009 6.05387L10.5354 10.7194C10.3906 10.863 10.1951 10.944 9.99109 10.9449Z"
      />
      <path d="M0.782108 10.9448C0.627661 10.9457 0.476444 10.9006 0.347738 10.8152C0.219033 10.7298 0.118669 10.6081 0.0594425 10.4654C0.000216259 10.3228 -0.0151889 10.1657 0.0151911 10.0143C0.0455712 9.86285 0.12036 9.7239 0.230022 9.61513L4.35123 5.5017L0.230022 1.38827C0.0835998 1.24185 0.00134058 1.04326 0.00134058 0.836188C0.00134058 0.629115 0.0835998 0.430524 0.230022 0.284102C0.376444 0.137679 0.575036 0.0554199 0.782108 0.0554199C0.989181 0.0554199 1.18777 0.137679 1.33419 0.284102L5.99971 4.94962C6.14454 5.09531 6.22583 5.29239 6.22583 5.49782C6.22583 5.70324 6.14454 5.90032 5.99971 6.04601L1.33419 10.7115C1.26218 10.785 1.1763 10.8435 1.08153 10.8835C0.986761 10.9235 0.884988 10.9444 0.782108 10.9448Z" />
    </svg>
  );
};
