import { FC } from "react";

export interface InfoIconProps extends React.SVGAttributes<SVGElement> {
  active?: boolean;
}

export const InfoIcon: FC<InfoIconProps> = ({ active, ...props }) => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="22"
        cy="22"
        r="21.5"
        fill={active ? "#57AAEC" : "white"}
        stroke="#EBEDF2"
      />
      <path
        d="M22 10C15.3676 10 10 15.367 10 22C10 28.6323 15.367 34 22 34C28.6324 34 34 28.633 34 22C34 15.3677 28.633 10 22 10ZM22 32.3256C16.3065 32.3256 11.6744 27.6936 11.6744 22C11.6744 16.3064 16.3065 11.6744 22 11.6744C27.6935 11.6744 32.3256 16.3064 32.3256 22C32.3256 27.6936 27.6935 32.3256 22 32.3256Z"
        fill={active ? "white" : "#57AAEC"}
      />
      <path
        d="M22.0003 20.0029C21.2895 20.0029 20.784 20.3031 20.784 20.7454V26.7635C20.784 27.1426 21.2895 27.5216 22.0003 27.5216C22.6795 27.5216 23.2323 27.1426 23.2323 26.7635V20.7453C23.2323 20.3031 22.6795 20.0029 22.0003 20.0029Z"
        fill={active ? "white" : "#57AAEC"}
      />
      <path
        d="M22.0003 16.291C21.2737 16.291 20.7051 16.8123 20.7051 17.4125C20.7051 18.0128 21.2737 18.5498 22.0003 18.5498C22.7111 18.5498 23.2798 18.0128 23.2798 17.4125C23.2798 16.8123 22.711 16.291 22.0003 16.291Z"
        fill={active ? "white" : "#57AAEC"}
      />
    </svg>
  );
};
