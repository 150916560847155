import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useCallback, useEffect, useRef } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { z } from "@/i18n";
import { useFormStore } from "../form-store";
import { StandartFile } from "../common";
import { APISchemas, PATCH, POST } from "@/api";
import { useToast } from "@chakra-ui/react";
import { handleError } from "./_handle-errors";
import isEqual from "lodash.isequal";
import { formUpdateMS } from "../contant";
import { useAuthStore } from "@/context/auth-store/auth-store";
import { i18n } from "@/i18n";
import { TiktokFormPresent } from "../forms-presents";

export type tiktokSchema = z.infer<typeof tiktokSchema>;
// eslint-disable-next-line react-refresh/only-export-components
export const tiktokSchema = z.object({
  id: z.number().optional(),
  content: z.string().optional(),
  files: StandartFile.array(),
  privacy_level: z.string(),
  stitch_disabled: z.boolean(),
  duet_disabled: z.boolean(),
  comment_disabled: z.boolean(),
  title: z
    .string()
    .max(150, i18n.t("post-validations.tiktok.title-limit"))
    .optional(),
});

export const TiktokFormContainer: FC<{
  channel: APISchemas["Channel"] & { hasError?: boolean };
  defaultValue?: tiktokSchema;
}> = ({ channel, defaultValue }) => {
  const toast = useToast();
  const defaultValueRef = useRef<typeof defaultValue>();
  const activeOrganization = useAuthStore((f) => f.activeOrganization);
  const formSync = useFormStore((f) => f.formSync);

  const [formState, setFormState] = useFormStore((f) => [
    f.fromStates[channel.id.toString()] ?? {
      sync: true,
    },
    f.setFormState,
  ]);

  const {
    main,
    addTiktokPost,
    scheduledAt,
    subscribe,
    unsubscribe,
    setForm,
    setProgressBlock,
    goToStep,
    setPlatformHasError,
    setGlobalError,
  } = useFormStore((s) => ({
    main: s.mainForms.main,
    setForm: s.setForm,
    subscribe: s.subscribe,
    unsubscribe: s.unsubscribe,
    scheduledAt: s.scheduledAt,
    addTiktokPost: s.addTiktokPost,
    setProgressBlock: s.setProgressBlock,
    setPlatformHasError: s.setPlatformHasError,
    goToStep: s.goToStep,
    setGlobalError: s.setGlobalError,
  }));

  const methods = useForm<tiktokSchema>({
    resolver: zodResolver(tiktokSchema),
  });
  const {
    getValues,
    trigger,
    handleSubmit,
    reset,

    formState: { isDirty },
  } = methods;

  if (!isEqual(defaultValue, defaultValueRef.current)) {
    reset({
      ...{
        duet_disabled: true,
        comment_disabled: true,
        privacy_level: "SELF_ONLY",
        stitch_disabled: true,
      },
      ...defaultValue,
    });
    setFormState(channel.id.toString(), {
      sync: false,
    });
    defaultValueRef.current = defaultValue;
  }

  const draftForm = useCallback(async () => {
    const result = await trigger();

    if (!result) {
      setPlatformHasError(channel, true);
      return;
    }

    setPlatformHasError(channel, false);
    setForm(channel.id, getValues());
  }, [channel, getValues, setForm, setPlatformHasError, trigger]);

  const onSubmit: (
    status: APISchemas["StatusCf9Enum"]
  ) => SubmitHandler<tiktokSchema> = useCallback(
    (status) => async (data) => {
      if (!activeOrganization) {
        return;
      }

      const coverUrls: Record<string, string> = {};
      if (!activeOrganization) return;
      data.files?.forEach((file) => {
        if (!file.data.video) {
          return;
        }
        if (!("coverUrl" in file.data.video)) {
          return;
        }
        if (
          !file.data.video.coverUrl ||
          typeof file.data.video.coverUrl !== "string"
        ) {
          return;
        }
        coverUrls[file.data.video.id] = file.data.video.coverUrl;
      });
      const body: APISchemas["TiktokPostCreateRequest"] = {
        body: data.content,
        channel: channel.id,
        status,
        metadata: {
          cover_url: coverUrls,
          title: data.title,
          comment_disabled: data.comment_disabled,
          duet_disabled: data.duet_disabled,
          privacy_level: "SELF_ONLY",
          stitch_disabled: data.stitch_disabled,
        },
        scheduled_at: scheduledAt.toISOString(),
        media: data.files
          .map((f) => f.data.image?.id || f.data.video?.id)
          .filter((m): m is string => !!m)
          .map((m) => Number(m)),
      };

      const { data: post, error } = defaultValueRef.current?.id
        ? await PATCH("/tiktok/{org_pk}/post/{id}/", {
            params: {
              path: {
                id: defaultValueRef.current.id,
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          })
        : await POST("/tiktok/{org_pk}/post/", {
            params: {
              path: {
                org_pk: activeOrganization.id.toString(),
              },
            },
            body,
          });

      if (error) {
        setProgressBlock(true);
        handleError(channel.id.toString(), error, setGlobalError);
        setPlatformHasError(channel, true);
        goToStep?.(1);
        return;
      }

      if (!post) {
        toast({
          status: "error",
          title: i18n.t("errors.unexpected-error-contact-customer-service"),
        });
        return;
      }

      setPlatformHasError(channel, false);
      addTiktokPost(post);
    },
    [
      activeOrganization,
      addTiktokPost,
      channel,
      goToStep,
      scheduledAt,
      setGlobalError,
      setPlatformHasError,
      setProgressBlock,
      toast,
    ]
  );

  useEffect(() => {
    if (main && formState.sync) {
      const values = getValues();
      reset({
        ...values,
        content: main.content,
        files: main.files,
      });
    }
  }, [formState.sync, main, reset]);

  useEffect(() => {
    if (isDirty) {
      setFormState(channel.id.toString(), {
        sync: false,
      });
    }
  }, [channel.id, isDirty, setFormState]);

  useEffect(() => {
    const submitFn = handleSubmit(onSubmit("planned"));
    const draftFn = handleSubmit(onSubmit("draft"));
    subscribe("submit", submitFn);
    subscribe("check", draftForm);
    subscribe("draft", draftFn);

    return () => {
      unsubscribe("submit", submitFn);
      unsubscribe("check", draftForm);
      unsubscribe("draft", draftFn);
    };
  }, [handleSubmit, onSubmit, draftForm, subscribe, unsubscribe]);

  useEffect(() => {
    if (!formSync) {
      return;
    }

    let prev: tiktokSchema = {};

    const interval = setInterval(() => {
      const values = getValues();

      if (isEqual(prev, values)) {
        return;
      }

      if (channel.hasError) {
        draftForm();
      }

      setForm(channel.id, values);
      prev = values;
    }, formUpdateMS);

    return () => {
      clearInterval(interval);
    };
  }, [channel, draftForm, formSync, getValues, setForm]);

  return (
    <FormProvider {...methods}>
      <TiktokFormPresent channel={channel} />
    </FormProvider>
  );
};
