import {
  Box,
  Progress,
  Step,
  Stepper,
  useSteps,
  Text,
  useToast,
  Button,
  Flex,
  Tooltip,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { StepOne, StepThree, StepTwo } from "./steps";
import { useFormStore } from "./form-store";
import { useNavigate, useParams } from "react-router-dom";
import {
  TwitterPreview,
  LinkedinPreview,
  InstagramPreview,
  FacebookPreview,
  YoutubePreview,
  TelegramPreview,
  TiktokPreview,
  MainPreview,
} from "./previews";
import { useQuery } from "react-query";
import { GET } from "@/api";
import { useTranslation } from "react-i18next";
import { useAuthStore } from "@/context/auth-store/auth-store";

export const PostPage: FC = () => {
  const fireEvent = useFormStore((s) => s.fireEvent);
  const getProgressBlock = useFormStore((s) => s.getProgressBlock);
  const getPlatformsHasError = useFormStore((s) => s.getPlatformsHasError);
  const platformsHasError = useFormStore((s) => s.platformsHasError);
  const progressBlock = useFormStore((s) => s.progressBlock);
  const setProgressBlock = useFormStore((s) => s.setProgressBlock);
  const setGoToStep = useFormStore((s) => s.setGoToStepFn);
  const clearPosts = useFormStore((s) => s.clearPosts);
  const setFormSync = useFormStore((s) => s.setFormSync);
  const resetForm = useFormStore((s) => s.reset);
  const focusOnFormError = useFormStore((s) => s.focusOnFormError);
  const clearGlobalErrors = useFormStore((s) => s.clearGlobalErrors);
  const havePlatforms = useFormStore((s) => s.platforms.length > 0);
  const getGlobalErrors = useFormStore((s) => s.getGobalError);
  const [loading, setLoading] = useState<boolean>(false);
  const [underConstruction, setUnderConstruction] = useState<boolean>(false);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const hydrate = useRef<boolean>(false);
  const { organization } = useAuthStore((state) => ({
    organization: state.activeOrganization,
    setActiveOrg: state.setActiveOrganization,
  }));
  const toast = useToast();

  const { id } = useParams();

  useQuery(
    ["compose", id, "under-construction", organization?.id],
    async () => {
      if (!id || !organization) {
        return;
      }
      const { data } = await GET("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: organization?.id.toString(),
            id: Number(id),
          },
        },
      });

      const isTwitterPosted = !!data?.twitter_posts.find(
        (post) => post.status === "posted"
      );
      const isLinkedinPosted = !!data?.linkedin_posts.find(
        (post) => post.status === "posted"
      );
      const isFacebookPosted = !!data?.facebook_posts.find(
        (post) => post.status === "posted"
      );
      const isInstagramPosted = !!data?.instagram_posts.find(
        (post) => post.status === "posted"
      );
      const isTelegramPosted = !!data?.instagram_posts.find(
        (post) => post.status === "posted"
      );
      const isYoutubePosted = !!data?.youtube_posts.find(
        (post) => post.status === "posted"
      );
      const isTiktokPosted = !!data?.tiktok_posts.find(
        (post) => post.status === "posted"
      );
      if (
        isTwitterPosted ||
        isLinkedinPosted ||
        isFacebookPosted ||
        isInstagramPosted ||
        isYoutubePosted ||
        isTiktokPosted ||
        isTelegramPosted
      ) {
        setUnderConstruction(true);
      } else {
        setUnderConstruction(false);
      }
    }
  );

  if (!hydrate.current && !id) {
    resetForm();
    hydrate.current = true;
  }

  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 1,
    count: 3,
  });

  const stepOneContinue = useCallback(async () => {
    setProgressBlock(false);
    await fireEvent("check");
    const errors = getPlatformsHasError();
    if (errors.length > 0) {
      focusOnFormError();
      toast({
        status: "error",
        title: t("errors.please-check-forms", { errors: errors.join(", ") }),
      });
      return;
    }
    goToNext();
  }, [
    fireEvent,
    focusOnFormError,
    setProgressBlock,
    getPlatformsHasError,
    goToNext,
    toast,
    t,
  ]);

  const stepTwoContinue = useCallback(() => {
    setProgressBlock(false);
    fireEvent("schedule");
    if (getProgressBlock()) {
      return;
    }
    goToNext();
  }, [fireEvent, getProgressBlock, goToNext, setProgressBlock]);

  const SaveAsDraft = useCallback(async () => {
    setLoading(true);
    setProgressBlock(false);
    clearPosts();
    await fireEvent("draft");
    await fireEvent("schedule");

    const errors = getPlatformsHasError();
    if (errors.length > 0) {
      focusOnFormError();
      toast({
        status: "error",
        title: t("errors.please-check-forms", { errors: errors.join(", ") }),
      });
    }

    if (getProgressBlock()) {
      setProgressBlock(false);
      setLoading(false);
      return;
    }
    clearGlobalErrors();
    await fireEvent("finalDraft");
    const globalErrors = getGlobalErrors("main");
    if (globalErrors) {
      return;
    }

    setLoading(false);
    clearGlobalErrors();
    navigate("/planner");
  }, [
    getGlobalErrors,
    clearGlobalErrors,
    clearPosts,
    fireEvent,
    focusOnFormError,
    getPlatformsHasError,
    getProgressBlock,
    navigate,
    setProgressBlock,
    t,
    toast,
  ]);

  const StepThreeContinue = useCallback(async () => {
    setLoading(true);
    setProgressBlock(false);
    clearPosts();
    clearGlobalErrors();

    await fireEvent("submit");

    const errors = getPlatformsHasError();
    if (errors.length > 0) {
      focusOnFormError();
      toast({
        status: "error",
        title: t("errors.please-check-forms", { errors: errors.join(", ") }),
      });
    }

    if (getProgressBlock()) {
      setProgressBlock(false);
      setLoading(false);
      return;
    }

    await fireEvent("final");
    const globalErrors = getGlobalErrors("main");
    if (globalErrors) {
      setLoading(false);

      return;
    }

    setLoading(false);
    clearGlobalErrors();
    navigate("/planner");
  }, [
    getGlobalErrors,
    clearGlobalErrors,
    clearPosts,
    fireEvent,
    focusOnFormError,
    getPlatformsHasError,
    getProgressBlock,
    navigate,
    setProgressBlock,
    t,
    toast,
  ]);

  useEffect(() => {
    setGoToStep(setActiveStep);
  }, [setActiveStep, setGoToStep]);

  useEffect(() => {
    if (activeStep === 1) {
      setFormSync(true);
    } else {
      setFormSync(false);
    }
  }, [activeStep, setFormSync]);

  return (
    <Flex mx="20px" mt="20px" mb="12">
      <Flex
        bg="#B7BABF"
        alignItems="center"
        px="3"
        flex="4"
        justifyContent="center"
      >
        <MainPreview />
        <TwitterPreview />
        <LinkedinPreview />
        <InstagramPreview />
        <FacebookPreview />
        <TelegramPreview />
        <TiktokPreview />
        {/* <YoutubePreview /> */}
      </Flex>
      <Flex flex="6" flexDir="column" bg="white" p="0.75rem">
        <Box pos="relative" mb="6" mx="3.5">
          <Stepper index={activeStep} orientation="horizontal">
            <Step>
              <Text fontWeight="semibold">
                {
                  [t("create_new_post"), t("schedule"), t("confirm")][
                    activeStep - 1
                  ]
                }
              </Text>
            </Step>
            <Step>
              <Text fontWeight="semibold" color="blue.shiny">
                {t("step")} {activeStep}/3
              </Text>
            </Step>
            <Step>
              <Text fontWeight="semibold"> {t("post_details")} </Text>
            </Step>
          </Stepper>
          <Progress
            value={(activeStep / 3) * 100}
            position="absolute"
            height="3px"
            width="full"
            top="8"
            sx={{ "&>div": { backgroundColor: "blue.shiny" } }}
          />
        </Box>
        <Box display={activeStep === 1 ? undefined : "none"} px="3.5">
          <StepOne id={id} />
          <Flex justifyContent="flex-end" gap="3">
            <StepRightButtons
              saveAsDraft={SaveAsDraft}
              next={stepOneContinue}
              disabled={
                platformsHasError.length > 0 || progressBlock || !havePlatforms
              }
            />
          </Flex>
        </Box>

        <Box display={activeStep === 2 ? undefined : "none"} px="3.5">
          <StepTwo id={id} />

          <Flex justifyContent="end">
            <Flex gap="3">
              <GoBackButton goBack={goToPrevious} />
              <StepRightButtons
                saveAsDraft={SaveAsDraft}
                next={stepTwoContinue}
                disabled={progressBlock}
              />
            </Flex>
          </Flex>
        </Box>

        <Box display={activeStep === 3 ? undefined : "none"} px="3.5">
          <StepThree />
          <Flex justifyContent="end">
            <Flex gap="3">
              <GoBackButton goBack={goToPrevious} />
              <StepRightButtons
                saveAsDraft={SaveAsDraft}
                next={StepThreeContinue}
                label={t("confirm")}
                disabled={underConstruction || progressBlock || loading}
                underConst={underConstruction}
              />
            </Flex>
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const GoBackButton: FC<{ goBack: () => void }> = ({ goBack }) => {
  const { t } = useTranslation();

  return (
    <Button
      variant="unstyled"
      w="225px"
      height="50px"
      mt="1"
      size="sm"
      fontSize="16px"
      borderRadius="3px"
      color="white"
      bg="blue.shiny"
      _hover={{
        opacity: 0.8,
      }}
      onClick={goBack}
    >
      {t("back")}
    </Button>
  );
};

const StepRightButtons: FC<{
  next: () => void;
  disabled?: boolean;
  label?: string;
  underConst?: boolean;
  isStepOne?: boolean;
  saveAsDraft?: () => void;
}> = ({ next, label, disabled = false, underConst = false, saveAsDraft }) => {
  const { t } = useTranslation();
  label ??= t("continue");

  return (
    <>
      <Button
        variant="unstyled"
        w="225px"
        height="50px"
        mt="1"
        size="sm"
        borderRadius="3px"
        color="white"
        bg="yellow.label"
        _hover={{
          opacity: 0.8,
        }}
        onClick={saveAsDraft}
      >
        {t("save_as_draft")}
      </Button>
      <Tooltip
        label={underConst ? t("dev.under-development") : undefined}
        placement="top"
      >
        <Button
          variant="unstyled"
          w="225px"
          height="50px"
          mt="1"
          size="sm"
          borderRadius="3px"
          color="white"
          bg="blue.shiny"
          _hover={{
            opacity: 0.8,
          }}
          onClick={next}
          isDisabled={disabled}
        >
          {label}
        </Button>
      </Tooltip>
    </>
  );
};
