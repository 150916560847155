import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Button,
  VStack,
  Center,
} from "@chakra-ui/react";
import {
  addMinutes,
  eachHourOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  endOfHour,
  getHours,
  getMinutes,
  isBefore,
  parse,
  parseISO,
  setHours,
  setMinutes,
  setSeconds,
} from "date-fns";
import {
  FC,
  KeyboardEvent,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { DayPicker } from "react-day-picker";
import { QuickDateButton } from "./_quick-date-button";
import { ChevronDown } from "@/assets/icons/chevron-down-icon";
import { useFormStore } from "../form-store";
import { GET } from "@/api";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";
import { useConfigStore } from "@/context/config-store/config-store";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
import { useSearchParams } from "react-router-dom";
import { useAuthStore } from "@/context/auth-store/auth-store";

export const StepTwo: FC<{ id?: string }> = ({ id }) => {
  const now = setSeconds(Date.now(), 0);
  const dateFormat = "dd MM yyyy";
  const timeFormat = "HH:mm";
  const { t } = useTranslation();
  const [setScheduledAt, subscribe, unsubscribe] = useFormStore((s) => [
    s.setScheduledAt,
    s.subscribe,
    s.unsubscribe,
  ]);

  const [searchParams] = useSearchParams();
  const { organization } = useAuthStore((state) => ({
    organization: state.activeOrganization,
    setActiveOrg: state.setActiveOrganization,
  }));

  const schedule = searchParams.get("schedule");

  const isQueryParamsConsumed = useRef<boolean>(false);

  const { clockNotation } = useConfigStore((c) => ({
    clockNotation: c.clockNotation,
  }));

  const [date, setDate] = useState<Date | undefined>(now);
  const [time, setTime] = useState<Date | undefined>(now);
  const dateTimeRef = useRef<{
    date?: Date;
    time?: Date;
  }>({
    date,
    time,
  });
  const [quickKey, setQuick] = useState<string | undefined>(
    !schedule ? "now" : undefined
  );
  const defaultValueUsedRef = useRef<boolean>(false);

  if (id && !defaultValueUsedRef.current && organization) {
    GET("/common/{org_pk}/compose/{id}/", {
      params: {
        path: {
          org_pk: organization?.id.toString(),
          id: Number(id),
        },
      },
    }).then(({ data }) => {
      if (!data) {
        return;
      }

      updateDateTime({ date: true, time: true })(
        new Date(data.scheduled_at ?? now)
      );

      setQuick(undefined);
    });

    defaultValueUsedRef.current = true;
  }

  const onQuick = useCallback((key: string, fn: (date: Date) => void) => {
    return (date: Date) => {
      setQuick(key);
      fn(date);
    };
  }, []);

  const updateTime = useCallback(
    (minutes: number) => {
      return () =>
        setTime((t) => {
          const next = addMinutes(t ?? now, minutes);
          const datePart = i18nformat(
            dateTimeRef.current.date ?? now,
            dateFormat
          );
          const timePart = i18nformat(next, timeFormat);

          const dateTime = parse(
            `${datePart} ${timePart}`,
            `${dateFormat} ${timeFormat}`,
            new Date()
          );

          if (isBefore(dateTime, setSeconds(Date.now(), 0))) {
            return t;
          }

          dateTimeRef.current.time = next;
          return next;
        });
    },
    [now]
  );

  const updateDateTime = useCallback(
    ({
      date,
      time,
      mergeWithMinutes,
      mergeWithHours,
    }: {
      date?: boolean;
      time?: boolean;
      mergeWithMinutes?: boolean;
      mergeWithHours?: boolean;
    }) => {
      if (date && time) {
        return (date?: Date) => {
          setDate(() => {
            dateTimeRef.current.date = date;
            return date;
          });

          setTime(() => {
            dateTimeRef.current.time = date;
            return date;
          });
        };
      }

      if (date) {
        return (date?: Date) => {
          setDate(() => {
            dateTimeRef.current.date = date;

            return dateTimeRef.current.date;
          });
        };
      }

      if (time) {
        return (time?: Date) => {
          setTime((t) => {
            dateTimeRef.current.time = time;
            if (mergeWithMinutes && t && time) {
              dateTimeRef.current.time = setMinutes(time, getMinutes(t));
            }

            if (mergeWithHours && t && time) {
              dateTimeRef.current.time = setHours(time, getHours(t));
            }

            return dateTimeRef.current.time;
          });
        };
      }

      throw new Error(t("date-or-time-must-be-selected"));
    },
    [t]
  );

  if (schedule && isQueryParamsConsumed.current === false) {
    const date = parseISO(schedule);

    updateDateTime({ date: true, time: true })(date);

    isQueryParamsConsumed.current = true;
  }

  const draftSchedule = useCallback(() => {
    const now = Date.now();

    const datePart = i18nformat(dateTimeRef.current.date ?? now, dateFormat);
    const timePart = i18nformat(dateTimeRef.current.time ?? now, timeFormat);

    setScheduledAt(
      parse(
        `${datePart} ${timePart}`,
        `${dateFormat} ${timeFormat}`,
        new Date()
      )
    );
  }, [setScheduledAt]);

  const dateTime = useMemo(() => {
    const datePart = i18nformat(dateTimeRef.current.date ?? now, dateFormat);
    const timePart = i18nformat(dateTimeRef.current.time ?? now, timeFormat);

    return parse(
      `${datePart} ${timePart}`,
      `${dateFormat} ${timeFormat}`,
      new Date()
    );
  }, [now]);

  const hourInterval = useMemo(() => {
    const start = setHours(dateTime, 0);
    const before = isBefore(start, Date.now());

    return eachHourOfInterval({
      start: isBefore(start, Date.now()) ? Date.now() : start,
      end: endOfDay(before ? Date.now() : dateTime),
    });
  }, [dateTime]);

  const minutesInterval = useMemo(() => {
    const start = setMinutes(dateTime, 0);
    const before = isBefore(start, Date.now());
    return eachMinuteOfInterval({
      start: before ? Date.now() : start,
      end: endOfHour(before ? Date.now() : dateTime),
    });
  }, [dateTime]);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = setSeconds(new Date(), 0);
      const datePart = i18nformat(dateTimeRef.current.date ?? now, dateFormat);
      const timePart = i18nformat(dateTimeRef.current.time ?? now, timeFormat);

      const dateTime = parse(
        `${datePart} ${timePart}`,
        `${dateFormat} ${timeFormat}`,
        setSeconds(now, 0)
      );

      if (isBefore(dateTime, Date.now())) {
        setDate(() => {
          dateTimeRef.current.date = now;
          return now;
        });

        setTime(() => {
          dateTimeRef.current.time = now;
          return now;
        });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    subscribe("schedule", draftSchedule);
    return () => {
      unsubscribe("schedule", draftSchedule);
    };
  }, [draftSchedule, subscribe, unsubscribe]);

  return (
    <Flex flexDir="column" minH="60vh">
      <Box>
        <Text fontSize="md" fontWeight="semibold" color="#353B48">
          {t("pick_a_date")}
        </Text>
      </Box>

      <Flex
        flexDir={{
          base: "column",
          xl: "row",
        }}
        mt="6"
        gap="12"
      >
        <Box
          boxShadow="0px 0px 20px 4px rgba(191, 191, 191, 0.25)"
          rounded="lg"
          w="min-content"
          sx={{
            ".rdp-day_selected": {
              backgroundColor: "blue.shiny",
              color: "white",
            },
          }}
        >
          <DayPicker
            selected={date}
            onSelect={updateDateTime({ date: true })}
            mode="single"
            disabled={{
              before: now,
            }}
            showOutsideDays
          />
        </Box>

        <Box minW="xs" maxW="md" w="full">
          <SimpleGrid columns={3} gap={3}>
            <QuickDateButton
              quickKey={`${quickKey}:now`}
              date={now}
              onClick={onQuick(
                "now",
                updateDateTime({ time: true, date: true })
              )}
            >
              {t("now_b")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:15m`}
              minutes={15}
              onClick={onQuick("15m", updateDateTime({ time: true }))}
            >
              15 {t("minutes")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:30m`}
              minutes={30}
              onClick={onQuick("30m", updateDateTime({ time: true }))}
            >
              30 {t("minutes")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:60m`}
              minutes={60}
              onClick={onQuick("60m", updateDateTime({ time: true }))}
            >
              60 {t("minutes")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:0d`}
              days={0}
              onClick={onQuick("0d", updateDateTime({ date: true }))}
            >
              {t("today_b")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:1d`}
              days={1}
              onClick={onQuick("1d", updateDateTime({ date: true }))}
            >
              {t("tomorrow")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:1w`}
              weeks={1}
              onClick={onQuick("1w", updateDateTime({ date: true }))}
            >
              {t("next_week")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:1mm`}
              months={1}
              onClick={onQuick("1mm", updateDateTime({ date: true }))}
            >
              {t("next_month")}
            </QuickDateButton>
            <QuickDateButton
              quickKey={`${quickKey}:1y`}
              years={1}
              onClick={onQuick("1y", updateDateTime({ date: true }))}
            >
              {t("next_year")}
            </QuickDateButton>
          </SimpleGrid>

          <Flex
            mt="6"
            px="6"
            py="5"
            rounded="lg"
            justifyContent="space-between"
            alignItems="center"
            boxShadow="0px 0px 20px 4px rgba(191, 191, 191, 0.25)"
            pos="relative"
          >
            <Flex alignItems="center" gap="6">
              <Box>
                <Text
                  textAlign="center"
                  fontSize="xs"
                  fontWeight="semibold"
                  color="#345A79"
                >
                  {t("hours_b")}
                </Text>
                <VerticalInput
                  Increment={updateTime(60)}
                  Decrement={updateTime(-60)}
                >
                  <TimeDropdown
                    formatStr={clockNotation === "12" ? "hh a" : "HH"}
                    data={hourInterval}
                    onSelect={updateDateTime({
                      time: true,
                      mergeWithMinutes: true,
                    })}
                  >
                    <Text fontSize="lg" fontWeight="medium">
                      {i18nformat(
                        time ?? now,
                        clockNotation === "12" ? "hh" : "HH"
                      )}
                    </Text>
                  </TimeDropdown>
                </VerticalInput>
              </Box>
              <Text fontSize="lg" fontWeight="medium">
                :
              </Text>
              <Box>
                <Text
                  textAlign="center"
                  fontSize="xs"
                  fontWeight="semibold"
                  color="#345A79"
                >
                  {t("minutes_b")}
                </Text>
                <VerticalInput
                  Increment={updateTime(1)}
                  Decrement={updateTime(-1)}
                >
                  <TimeDropdown
                    formatStr="mm"
                    data={minutesInterval}
                    onSelect={updateDateTime({
                      time: true,
                      mergeWithHours: true,
                    })}
                  >
                    <Text fontSize="lg" fontWeight="medium">
                      {i18nformat(time ?? now, "mm")}
                    </Text>
                  </TimeDropdown>
                </VerticalInput>
              </Box>
            </Flex>
            <Box opacity={clockNotation === "24" ? "0.4" : undefined}>
              <Text
                textAlign="center"
                fontSize="xs"
                fontWeight="semibold"
                color="#345A79"
              >
                {t("time_order")}
              </Text>
              <VerticalInput
                Increment={updateTime(60 * 12)}
                Decrement={updateTime(-60 * 12)}
              >
                <Center fontSize="lg" fontWeight="medium" h="10">
                  {i18nformat(time ?? now, "a")}
                </Center>
              </VerticalInput>
            </Box>

            <ClockNotationSwitch />
          </Flex>
        </Box>
      </Flex>
    </Flex>
  );
};

const VerticalInput: FC<
  PropsWithChildren<{
    Increment: () => void;
    Decrement: () => void;
    hidden?: boolean;
  }>
> = ({ children, Increment, Decrement, hidden = false }) => {
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      visibility={hidden ? "hidden" : undefined}
    >
      <IconButton variant="unstyled" aria-label="increment" onClick={Increment}>
        <Icon boxSize="9" transform="rotate(180deg)">
          <ChevronDown />
        </Icon>
      </IconButton>
      {children}
      <IconButton variant="unstyled" aria-label="decrement" onClick={Decrement}>
        <Icon boxSize="9">
          <ChevronDown />
        </Icon>
      </IconButton>
    </Flex>
  );
};

const TimeDropdown: FC<
  PropsWithChildren<{
    data: Array<Date>;
    formatStr: string;
    onSelect: (date: Date) => void;
  }>
> = ({ children, data, formatStr, onSelect }) => {
  const scrollbar = useRef<OverlayScrollbarsComponentRef | null>(null);

  const stackRef = useRef<HTMLDivElement | null>(null);
  const keyRef = useRef<string>("");
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const handleKeyboard = useCallback((e: KeyboardEvent) => {
    keyRef.current += e.key;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    for (const element of stackRef.current?.children ?? []) {
      if (
        !element?.innerHTML
          ?.toLowerCase()
          .startsWith(keyRef.current.toLowerCase())
      ) {
        continue;
      }

      if (element.tagName === "BUTTON") {
        const button = element as HTMLButtonElement;

        button.click();
        button.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      } else {
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }

    setTimeout(() => {
      keyRef.current = "";
    }, 500);
  }, []);
  return (
    <Popover
      offset={[-4, 0]}
      matchWidth
      placement="bottom-start"
      eventListeners={{
        resize: true,
      }}
    >
      <PopoverTrigger>
        <Button variant="unstyled">{children}</Button>
      </PopoverTrigger>

      <PopoverContent
        w="12"
        border="none"
        onKeyDown={handleKeyboard}
        _focusVisible={{
          outline: "none",
        }}
      >
        <PopoverBody
          bg="white"
          px="0"
          py="0"
          rounded="md"
          boxShadow="0px 0px 20px 4px rgba(191, 191, 191, 0.25)"
        >
          <OverlayScrollbarsComponent
            ref={scrollbar}
            options={{
              scrollbars: {
                autoHide: "scroll",
              },
            }}
          >
            <VStack
              maxH="48"
              w="full"
              gap="0"
              pos="relative"
              pb="24"
              ref={stackRef}
            >
              {data.map((d) => (
                <Button
                  key={d.toISOString()}
                  py="2.5"
                  variant="unstyled"
                  color="#345A79"
                  fontSize="sm"
                  size="none"
                  onClick={() => onSelect(d)}
                >
                  {i18nformat(d, formatStr)}
                </Button>
              ))}
              <Box pt="20" w="full"></Box>
              <Box
                pointerEvents="none"
                h="16"
                w="full"
                pos="fixed"
                bottom="0"
                rounded="md"
                bg="linear-gradient(180deg, rgba(255, 255, 255, 0.50) 0%, #FFF 100%)"
              ></Box>
            </VStack>
          </OverlayScrollbarsComponent>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

const ClockNotationSwitch: FC = () => {
  const { clockNotation, updateClockNotation } = useConfigStore((c) => ({
    clockNotation: c.clockNotation,
    updateClockNotation: c.updateClockNotation,
  }));
  const { t } = useTranslation();
  return (
    <Box
      bg="white"
      color="white"
      boxShadow="0px 4px 20px 4px rgba(191, 191, 191, 0.25)"
      h="min-content"
      rounded="md"
    >
      <Button
        variant="unstyled"
        display="block"
        bg={clockNotation === "12" ? "#345A79" : undefined}
        py="2.5"
        px="2"
        w="full"
        rounded="md"
        onClick={() => updateClockNotation("12")}
      >
        <Text
          fontSize="xs"
          color={clockNotation === "12" ? undefined : "#353B48"}
        >
          12 {t("hours_b")}
        </Text>
      </Button>
      <Button
        variant="unstyled"
        display="block"
        mt="-1"
        bg={clockNotation === "24" ? "#345A79" : undefined}
        py="2.5"
        px="2"
        w="full"
        rounded="md"
        onClick={() => updateClockNotation("24")}
      >
        <Text
          fontSize="xs"
          color={clockNotation === "24" ? undefined : "#353B48"}
        >
          24 {t("hours_b")}
        </Text>
      </Button>
    </Box>
  );
};
