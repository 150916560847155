import React from "react";
import { Flex, Text, IconButton, Box } from "@chakra-ui/react";
import { RefreshDIcon } from "@/assets/icons/refresh-d-icon";
import { useTranslation } from "react-i18next";
import { TrashSlimIcon } from "@/assets/icons/trash-slim-icon";
import { NotificationsOutlineIcon } from "@/assets/icons/notficiations-outline-icon";

interface TopbarNotificationProps {
  onDelete?: () => void;
  onRefetch?: () => void;
}

export const TopbarNotifications: React.FC<TopbarNotificationProps> = ({
  onDelete,
  onRefetch,
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      w="full"
      justifyContent="space-between"
      alignItems="center"
      bg="white"
      borderRadius="6px"
      border="1px solid  #EBEDF2"
    >
      <Flex w="full" alignItems="center" h="full" ml="17px">
        <NotificationsOutlineIcon />
        <Text
          ml="3"
          fontSize="20px"
          fontWeight="600"
          color="blue.shiny"
          lineHeight="25px"
        >
          {t("notifications")}
        </Text>
      </Flex>

      <Flex
        h="full"
        justifyContent="space-between"
        alignItems="center"
        gap="20px"
        mr="16px"
      >
        <Flex
          alignItems="center"
          gap="39px"
          justifyContent="space-between"
          mr="20px"
          h="full"
        >
          {/* <Box justifyContent="center" alignItems="center" textAlign="center">
            <Text
              color="blue.shiny"
              fontSize="20px"
              fontWeight="600"
              lineHeight="25px"
            >
              {t("page.dashboard.all")}
            </Text>
            <Box
              position="relative"
              top="17px"
              w="50px"
              h="3px"
              bgColor="blue.shiny"
              borderTopRadius="6px"
            />
          </Box> */}
          {/* <Text color="gray.passive" fontWeight="500" fontSize="20px">
            Tog1
          </Text>
          <Text color="gray.passive" fontWeight="500" fontSize="20px">
            Tag2
          </Text>
          <Text color="gray.passive" fontWeight="500" fontSize="20px">
            Tag3
          </Text> */}
        </Flex>

        <IconButton
          size="none"
          aria-label="Refreshs"
          icon={<RefreshDIcon width="37px" height="37px" fill="#2AABEE" />}
          width="37px"
          height="37px"
          cursor="pointer"
          //isLoading={isLoading || isRefetching}
          onClick={onRefetch}
        />
        <IconButton
          size="none"
          aria-label="Filter"
          icon={<TrashSlimIcon width="17px" height="20px" fill="#2AABEE" />}
          width="37px"
          height="37px"
          cursor="pointer"
          onClick={onDelete}
        />
      </Flex>
    </Flex>
  );
};
