export const LogoutIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 19 20"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      color="white"
      {...props}
    >
      <path
        d="M12.2201 3.61007L18.0092 0.570801C18.5146 0.760022 19 1.38016 19 2.096V15.0731C19 15.6409 18.7217 16.1531 18.2541 16.4647L13.801 19.29C12.7363 20.0139 11.207 19.2634 11.207 17.8984V5.15753C11.207 4.50066 11.5744 3.93288 12.2201 3.61007ZM13.4336 11.1841C13.4336 11.4918 13.6825 11.7407 13.9902 11.7407C14.2979 11.7407 14.5469 11.4918 14.5469 11.1841V8.95754C14.5469 8.64987 14.2979 8.4009 13.9902 8.4009C13.6825 8.4009 13.4336 8.64987 13.4336 8.95754V11.1841Z"
        fill={props.fill || "currentColor"}
      />
      <path
        d="M0.220146 9.62735L4.13371 6.89513C4.4844 6.54444 5.08397 6.79278 5.08397 7.28867V8.95752H9.53711C9.84452 8.95752 10.0938 9.20668 10.0938 9.51417V10.6274C10.0938 10.9349 9.84452 11.1841 9.53711 11.1841H5.08397V12.8529C5.08397 13.3488 4.48447 13.5971 4.13379 13.2465L0.220146 10.5142C-0.0733519 10.2914 -0.0733519 9.85008 0.220146 9.62735Z"
        fill={props.fill || "currentColor"}
      />
      <path
        d="M10.0938 12.2974V17.3443H8.42382C7.50299 17.3443 6.7539 16.5952 6.7539 15.6743V12.2974C8.70604 12.2974 8.13801 12.2974 10.0938 12.2974Z"
        fill={props.fill || "currentColor"}
      />
      <path
        d="M8.42382 0.570801H15.6143L11.7244 2.61308L11.7223 2.61416C11.7221 2.61423 11.7221 2.61435 11.7221 2.61435L11.7027 2.62448C10.7025 3.12408 10.0937 4.07483 10.0937 5.15768V7.84426C8.13796 7.84426 8.706 7.84426 6.75385 7.84426V2.24073C6.75389 1.31989 7.50294 0.570801 8.42382 0.570801Z"
        fill={props.fill || "currentColor"}
      />
    </svg>
  );
};
