import { useAuthStore } from "@/context/auth-store/auth-store";
import { useSessionStore } from "@/context/time-store/session-store";
import { useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";

export const PrivateWithoutLayout = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));

  // useEffect(() => {
  //   useSessionStore.getState().startSession();

  //   return () => {
  //     useSessionStore.getState().stopSession();
  //   };
  // }, []);

  return user ? <Outlet /> : <Navigate to="/auth/login" replace />;
};
