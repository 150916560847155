import { FC } from "react";
import { colors } from "@/theme/colors";

interface InfoTargetProps extends React.SVGAttributes<SVGElement> {
  reverse?: boolean;
}

export const TargetBoxIcon: FC<InfoTargetProps> = ({ reverse, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="1.13184"
        y="0.983185"
        width="31.0336"
        height="31.0336"
        rx="2.5"
        fill={reverse ? colors.white.normal : colors.blue.shiny}
        stroke={reverse ? colors.gray.athens : colors.blue.shiny}
      />
      <g clipPath="url(#clip0_9762_7215)">
        <path
          d="M16.9862 16.1777C16.9836 16.175 16.9828 16.1713 16.9801 16.1687L14.305 13.4935V11.8126C14.305 11.6881 14.2556 11.5691 14.1677 11.4812L11.3552 8.63744C11.2215 8.50381 11.0196 8.46306 10.8443 8.53584C10.669 8.60815 10.555 8.77937 10.555 8.96887V10.3751H9.11749C8.92802 10.3751 8.7568 10.4891 8.68449 10.6644C8.61171 10.8397 8.65199 11.0412 8.78611 11.1753L11.6299 14.019C11.7178 14.1069 11.8368 14.1563 11.9613 14.1563H13.6422L16.3173 16.8315C16.32 16.8342 16.3236 16.8349 16.3264 16.8375C16.5089 17.0117 16.7861 17.014 16.9711 16.8375C17.1658 16.6517 17.1614 16.3613 16.9862 16.1777Z"
          fill={reverse ? colors.blue.shiny : colors.gray.athens}
        />
        <path
          d="M16.6489 8.5002C15.4495 8.5002 14.3154 8.80535 13.2942 9.28201L14.7322 10.7199C15.3357 10.5193 15.9789 10.4064 16.6489 10.4064C20.0089 10.4064 22.7426 13.1402 22.7426 16.5001C22.7426 19.8601 20.0089 22.5939 16.6489 22.5939C13.2889 22.5939 10.5551 19.8601 10.5551 16.5001C10.5551 15.8301 10.668 15.1869 10.8686 14.5834L9.43071 13.1455C8.95411 14.1667 8.64893 15.3007 8.64893 16.5001C8.64893 20.8942 12.2548 24.5001 16.6489 24.5001C21.0429 24.5001 24.6488 20.8942 24.6488 16.5001C24.6488 12.1061 21.043 8.5002 16.6489 8.5002Z"
          fill={reverse ? colors.blue.shiny : colors.gray.athens}
        />
        <path
          d="M16.6488 12.2813C16.1533 12.2813 15.6846 12.3829 15.2425 12.5405V13.1053L16.3262 14.1889C16.4326 14.1741 16.5383 14.1563 16.6488 14.1563C17.9411 14.1563 18.9925 15.2078 18.9925 16.5001C18.9925 17.7923 17.9411 18.8438 16.6488 18.8438C15.3565 18.8438 14.305 17.7923 14.305 16.5001C14.305 16.3896 14.3228 16.2839 14.3376 16.1775L13.254 15.0938H12.6892C12.5316 15.5358 12.4301 16.0046 12.4301 16.5C12.4301 18.8264 14.3225 20.7187 16.6488 20.7187C18.9751 20.7187 20.8675 18.8264 20.8675 16.5C20.8675 14.1737 18.9751 12.2813 16.6488 12.2813Z"
          fill={reverse ? colors.blue.shiny : colors.gray.athens}
        />
      </g>
      <defs>
        <clipPath id="clip0_9762_7215">
          <rect
            width="16"
            height="16"
            fill={reverse ? colors.gray.athens : colors.blue.shiny}
            transform="translate(8.64868 8.50003)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
