export const TrashIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.7437 9.81396C14.3934 9.81396 14.1094 10.0979 14.1094 10.4483V22.4378C14.1094 22.788 14.3934 23.0722 14.7437 23.0722C15.0941 23.0722 15.3781 22.788 15.3781 22.4378V10.4483C15.3781 10.0979 15.0941 9.81396 14.7437 9.81396Z"
        fill={props.fill || "#353B48"}
      />
      <path
        d="M7.25741 9.81396C6.90702 9.81396 6.62305 10.0979 6.62305 10.4483V22.4378C6.62305 22.788 6.90702 23.0722 7.25741 23.0722C7.6078 23.0722 7.89178 22.788 7.89178 22.4378V10.4483C7.89178 10.0979 7.6078 9.81396 7.25741 9.81396Z"
        fill={props.fill || "#353B48"}
      />
      <path
        d="M1.8017 8.06421V23.6936C1.8017 24.6174 2.14044 25.485 2.73219 26.1074C3.3212 26.7317 4.14092 27.086 4.9988 27.0875H17.0012C17.8593 27.086 18.679 26.7317 19.2678 26.1074C19.8596 25.485 20.1983 24.6174 20.1983 23.6936V8.06421C21.3746 7.75198 22.1368 6.61557 21.9795 5.40855C21.8219 4.20176 20.7938 3.29903 19.5766 3.29878H16.3287V2.50583C16.3324 1.839 16.0687 1.19869 15.5967 0.727621C15.1246 0.256803 14.4833 -0.00536823 13.8165 8.33504e-05H8.18352C7.51669 -0.00536823 6.87539 0.256803 6.40333 0.727621C5.93127 1.19869 5.66761 1.839 5.67133 2.50583V3.29878H2.42343C1.20624 3.29903 0.178122 4.20176 0.0205221 5.40855C-0.13683 6.61557 0.625399 7.75198 1.8017 8.06421ZM17.0012 25.8188H4.9988C3.91419 25.8188 3.07043 24.887 3.07043 23.6936V8.11996H18.9296V23.6936C18.9296 24.887 18.0858 25.8188 17.0012 25.8188ZM6.94006 2.50583C6.93585 2.17551 7.0657 1.85758 7.30011 1.62441C7.53428 1.39123 7.85295 1.26312 8.18352 1.26881H13.8165C14.147 1.26312 14.4657 1.39123 14.6999 1.62441C14.9343 1.85734 15.0642 2.17551 15.0599 2.50583V3.29878H6.94006V2.50583ZM2.42343 4.56751H19.5766C20.2072 4.56751 20.7184 5.07872 20.7184 5.70937C20.7184 6.34002 20.2072 6.85123 19.5766 6.85123H2.42343C1.79278 6.85123 1.28157 6.34002 1.28157 5.70937C1.28157 5.07872 1.79278 4.56751 2.42343 4.56751Z"
        fill={props.fill || "#353B48"}
      />
      <path
        d="M10.9996 9.81396C10.6492 9.81396 10.3652 10.0979 10.3652 10.4483V22.4378C10.3652 22.788 10.6492 23.0722 10.9996 23.0722C11.35 23.0722 11.634 22.788 11.634 22.4378V10.4483C11.634 10.0979 11.35 9.81396 10.9996 9.81396Z"
        fill={props.fill || "#353B48"}
      />
    </svg>
  );
};
