import { TrashIcon } from "@/assets/icons/trash-icon";
import {
  Flex,
  Text,
  Avatar,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  useDisclosure,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface ChannelItemProps {
  userPhoto?: string | null;
  mail?: string | null;
  navigateTo?: string;
  onClick?: () => void;
  onDelete?: () => void;
}

export const ChannelItem = ({
  userPhoto,
  mail,
  onDelete,
}: ChannelItemProps) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);
  return (
    <Flex
      alignItems="center"
      w="full"
      overflow="hidden"
      justifyContent="space-between"
      px="0px"
      py="2"
    >
      <Flex w="full">
        <Avatar
          boxSize="25px"
          src={
            userPhoto
              ? userPhoto
              : "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ-Ne7oVV6Lx9uAnmJDUZrrLcGy8yzo1sXdpQ&usqp=CAU"
          }
        />
        <Flex ml="3" alignItems="center">
          <Text fontSize="12px" fontWeight="600" color="black.active">
            {mail}
          </Text>
        </Flex>
      </Flex>

      <Button
        size="none"
        onClick={onOpen}
        textAlign="center"
        variant="unstyled"
      >
        <TrashIcon width="20px" height="20px" color="black.light" />
      </Button>
      <AlertDialog
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="white.bg">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("alert.remove-modal.you-are-deleting")} {mail}
            </AlertDialogHeader>

            <AlertDialogBody>
              {t("alert.remove-modal.you-are-deleting-this-account")}{" "}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={onClose}> {t("cancel")}</Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t("delete")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
