export const PrivacyIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.00022 6.42822C8.29145 6.42822 7.71454 7.00513 7.71454 7.7139V8.99958H10.2859V7.7139C10.2859 7.00513 9.70899 6.42822 9.00022 6.42822Z"
        fill={props.fill || "white"}
      />
      <path
        d="M17.2699 0.126339C16.8235 -0.0877264 16.2949 -0.0262146 15.9082 0.283915C14.3018 1.57526 11.4102 1.57462 9.80628 0.284558C9.33544 -0.0946369 8.665 -0.0939941 8.19416 0.283915C6.58843 1.57522 3.69565 1.57458 2.09228 0.284518C1.70614 -0.0262146 1.17583 -0.0877665 0.73119 0.126299C0.285019 0.339762 0.000642793 0.791116 0.000642793 1.28582L0 11.5713C0 15.6009 3.23051 18.946 8.64113 20.5199C8.75853 20.5538 8.87907 20.5707 9.00028 20.5707C9.12138 20.5707 9.24199 20.5538 9.35939 20.5199C14.7701 18.946 18 15.6016 18 11.5713V1.28582C18 0.791116 17.7162 0.339762 17.2699 0.126339ZM12.8573 12.8566C12.8573 13.567 12.2817 14.1423 11.5716 14.1423H6.42888C5.71878 14.1423 5.1432 13.567 5.1432 12.8566V10.2853C5.1432 9.57524 5.71878 8.99958 6.42888 8.99958V7.7139C6.42888 6.2964 7.5821 5.14254 9.00024 5.14254C10.4184 5.14254 11.5716 6.2964 11.5716 7.7139V8.99958C12.2817 8.99958 12.8573 9.57524 12.8573 10.2853V12.8566Z"
        fill={props.fill || "white"}
      />
      <path
        d="M9.00026 10.9281C8.64525 10.9281 8.35742 11.2159 8.35742 11.5709C8.35742 11.9263 8.64525 12.2138 9.00026 12.2138C9.35527 12.2138 9.6431 11.9263 9.6431 11.5709C9.6431 11.2159 9.35527 10.9281 9.00026 10.9281Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
