import { FC, ReactNode } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { useConfigStore } from "@/context/config-store/config-store";

interface MediaTextProps {
  icon: ReactNode;
  title: string;
  value: number;
}

export const MediaText: FC<MediaTextProps> = ({ icon, title, value }) => {
  const language = useConfigStore((c) => c.language);

  const calculateNumberPlaceholder = useCallback(
    (number: number) => {
      if (!language) return undefined;
      if (number > 1000000) {
        return number / 1000000 + "M";
      } else if (number > 1000) {
        return number / 1000 + (language === "tr" ? "B" : "K");
      } else return number;
    },
    [language]
  );

  return (
    <Flex
      w="full"
      height="full"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
    >
      <Flex alignItems="center" justifyContent="center" w="full" h="full">
        {icon}
        <Text
          ml="2"
          lineHeight="14px"
          color="black.active"
          fontWeight={500}
          fontSize="14px"
        >
          {title}
        </Text>
      </Flex>
      <Flex h="full">
        <Text
          fontSize="24px"
          lineHeight="14px"
          fontWeight="500"
          color="black.active"
        >
          {calculateNumberPlaceholder(value)}
        </Text>
      </Flex>
    </Flex>
  );
};
