import { APISchemas, GET } from "@/api";
import { LikeBoxIcon } from "@/assets/icons/like-box-icon";
import { RefreshBoxIcon } from "@/assets/icons/refresh-box-icon";
import { BunnyPlayer } from "@/components/bunny-player/bunny-player";
import { SmartImage } from "@/components/smart-image";
import { useAuthStore } from "@/context/auth-store/auth-store";
import {
  Text,
  Flex,
  Box,
  IconButton,
  Avatar,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { FC, useCallback, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MediaText } from "./components/media-text";
import { CommentBoxIcon } from "@/assets/icons/comment-box-icon";
import { ReachBoxIcon } from "@/assets/icons/reach-box-icon";
import { RetweetBoxIcon } from "@/assets/icons/retweet-icon";

export const FeaturedPost = () => {
  const { t } = useTranslation();
  const [activeOrganization] = useAuthStore((s) => [s.activeOrganization]);
  const [modalItem, setModalItem] = useState<APISchemas["Compose"]>();
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const findClickedCompose = useCallback(
    async (id: string | null) => {
      if (!activeOrganization) return;

      const { data } = await GET("/common/{org_pk}/compose/{id}/", {
        params: {
          path: {
            org_pk: activeOrganization?.id?.toString(),
            id: Number(id),
          },
        },
      });
      setModalItem(data);
    },
    [activeOrganization]
  );

  useEffect(() => {
    findClickedCompose("1628");
  }, [findClickedCompose]);

  return (
    <Box w="full" height="full" bgColor="white" p="16px" borderRadius="6px">
      <Flex w="full" alignItems="center">
        <Box w="full">
          <Text
            fontWeight="600"
            lineHeight="14px"
            fontSize="16px"
            color="black.active"
          >
            {t("page.analytics.most-attractive-post")}{" "}
          </Text>
          <Text
            fontWeight="400"
            lineHeight="14px"
            fontSize="12px"
            color="gray.smoke"
            mt="12px"
          >
            {t("page.analytics.specialized-channel-stats")}
          </Text>
        </Box>
        <IconButton
          size="none"
          variant="unstyled"
          cursor="pointer"
          aria-label="refresh"
          bg="white"
          alignItems="center"
          justifyContent="center"
          // isLoading={isRefetching}
          onClick={() => {
            console.log("refreshCkicked");
          }}
        >
          <RefreshBoxIcon width="30px" height="30px" />
        </IconButton>
      </Flex>

      <Box mt="4">
        <Flex alignItems="center">
          <Avatar
            width="30px"
            height="30px"
            src={modalItem?.owner?.picture ?? ""}
          />
          <Text lineHeight="14px" fontSize="12px" fontWeight="600" ml="2">
            {modalItem?.owner.username}
          </Text>
        </Flex>

        <Text
          mt="6"
          fontSize="14px"
          fontWeight="400"
          lineHeight="14px"
          color="black.active"
        >
          {modalItem?.body}
        </Text>
        <Flex w="full" mt="4">
          {modalItem?.twitter_posts[0].media[0].type === "image" ? (
            <Flex
              w="full"
              height="full"
              position="relative"
              borderRadius="6px"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <SmartImage
                imageUrl={modalItem?.twitter_posts[0].media[0].url}
                h="200px"
                borderRadius="6px"
              />
              {isHovered && (
                <Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  backgroundColor="rgba(0, 0, 0, 0.5)"
                  borderRadius="6px"
                  display="flex"
                  color="white"
                >
                  <Box
                    w="full"
                    h="full"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid templateColumns="repeat(2, 1fr)" w="full" h="full">
                      <GridItem w="100%">
                        <MediaText
                          icon={<LikeBoxIcon width="35px" height="35px" />}
                          title={t("page.analytics.like-c")}
                          value={1020}
                        />
                      </GridItem>
                      <GridItem w="100%">
                        <MediaText
                          icon={<CommentBoxIcon width="35px" height="35px" />}
                          title={t("page.analytics.comment-c")}
                          value={1020}
                        />
                      </GridItem>
                      <GridItem w="100%">
                        <MediaText
                          icon={<RetweetBoxIcon width="35px" height="35px" />}
                          title={t("page.analytics.like-c")}
                          value={1020}
                        />
                      </GridItem>
                      <GridItem w="100%">
                        <MediaText
                          icon={<ReachBoxIcon width="35px" height="35px" />}
                          title={t("page.analytics.comment-c")}
                          value={1020}
                        />
                      </GridItem>
                    </Grid>
                  </Box>
                </Box>
              )}
            </Flex>
          ) : modalItem?.twitter_posts[0].media[0].type === "video" ? (
            <Flex w="full" h="190px" position="relative" borderRadius="6px">
              <BunnyPlayer src={modalItem?.twitter_posts[0].media[0].url} />
            </Flex>
          ) : null}
        </Flex>
      </Box>
    </Box>
  );
};
