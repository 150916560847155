import React from "react";

export const ChatbotIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20 40.25C31.0457 40.25 40 31.2957 40 20.25C40 9.2043 31.0457 0.25 20 0.25C8.9543 0.25 0 9.2043 0 20.25C0 31.2957 8.9543 40.25 20 40.25Z"
        fill="#57AAEC"
      />
      <path
        d="M14.1386 28.8784V29.8021C14.1386 30.4037 14.1327 31.0067 14.1432 31.609C14.1458 31.7995 14.0816 31.8859 13.8944 31.9088C12.2564 32.1091 10.7657 31.7772 9.48064 30.6938C8.7245 30.0568 8.15428 29.2849 7.83087 28.3514C7.24299 26.6532 7.43876 25.042 8.41617 23.5271C8.96151 22.6807 9.69864 22.0568 10.5923 21.609C11.3399 21.2338 12.1366 21.0643 12.9687 21.063C15.8512 21.0571 18.7356 21.0584 21.6181 21.0545C22.2996 21.0545 22.9831 21.0689 23.6613 21.025C24.9202 20.9432 25.9664 20.3972 26.8175 19.4715C27.5166 18.7114 27.9173 17.8041 28.0312 16.7906C28.1569 15.6718 27.9402 14.6014 27.3085 13.6541C26.4764 12.4064 25.3268 11.6397 23.8172 11.4577C22.5726 11.3085 21.4256 11.5854 20.4181 12.3219C19.3648 13.0905 18.6852 14.1229 18.4862 15.4289C18.4188 15.8708 18.4129 16.3252 18.407 16.773C18.3952 17.6541 18.4044 18.5373 18.4044 19.4204C18.4044 19.6397 18.3808 19.6633 18.1687 19.6633C17.3602 19.6633 16.551 19.6607 15.7425 19.6679C15.5932 19.6679 15.5585 19.6194 15.5598 19.4787C15.5657 18.1641 15.5625 16.8502 15.5658 15.5356C15.5671 14.8181 15.791 14.1478 16.0352 13.4859C16.5019 12.2178 17.2784 11.1533 18.3167 10.3055C19.2764 9.5219 20.3625 8.96412 21.5959 8.71077C22.6165 8.50128 23.6378 8.47182 24.6571 8.66101C25.789 8.87051 26.8306 9.31699 27.7589 10.0064C28.8954 10.8496 29.7654 11.9114 30.3238 13.2155C30.8142 14.3592 30.9936 15.5553 30.9078 16.7985C30.8286 17.9481 30.5203 19.027 29.9455 20.0181C28.8561 21.8951 27.2561 23.1239 25.1553 23.6875C24.5117 23.8604 23.8564 23.9383 23.19 23.9363C21.6456 23.9317 20.0993 23.9291 18.5549 23.9304C17.6685 23.9304 16.7821 23.9448 15.8944 23.9468C14.9157 23.9481 13.9363 23.973 12.9589 23.935C11.859 23.8911 10.7723 24.7271 10.4522 25.8001C10.0279 27.2201 10.9497 28.881 12.6577 29.1095C13.0178 29.1579 13.3582 29.114 13.6993 29.0067C13.8309 28.9654 13.9657 28.9307 14.1425 28.879L14.1379 28.8777L14.1386 28.8784Z"
        fill="white"
      />
      <path
        d="M18.4145 28.6465H18.4132V31.7345C18.4132 31.9617 18.3968 31.965 18.1723 31.9636C17.3808 31.9578 16.5893 31.9591 15.7978 31.9636C15.5189 31.9636 15.5274 31.9663 15.5438 31.6952C15.5602 31.4163 15.5484 31.1348 15.5484 30.854C15.5484 29.0812 15.553 27.3064 15.5484 25.5335C15.5484 25.3607 15.5909 25.3064 15.7729 25.309C16.5821 25.3175 17.3932 25.3195 18.2037 25.3064C18.3838 25.3037 18.4224 25.3607 18.4211 25.5204C18.4165 26.562 18.4178 27.6036 18.4178 28.6445C18.4178 28.6458 18.4171 28.6465 18.4158 28.6465H18.4145Z"
        fill="white"
      />
    </svg>
  );
};
