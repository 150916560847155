import { create } from "zustand";
import { mainSchema } from "./form-containers/main-form";
import {
  facebookSchema,
  instagramSchema,
  linkedinSchema,
  youtubeSchema,
  telegramSchema,
  twitterSchema,
  tiktokSchema,
} from "./form-containers";
import { APISchemas } from "@/api";
import { Dispatch, SetStateAction } from "react";
import isEqual from "lodash.isequal";

interface MainForms {
  main?: mainSchema;
  twitter?: twitterSchema;
  instagram?: instagramSchema;
  linkedin?: linkedinSchema;
  facebook?: facebookSchema;
  youtube?: youtubeSchema;
  tiktok?: tiktokSchema;
  telegram?: telegramSchema;
}

interface Preview {
  channel?: APISchemas["Channel"];
  formType:
    | "main"
    | "twitter"
    | "linkedin"
    | "facebook"
    | "instagram"
    | "telegram"
    | "youtube"
    | "tiktok"
    | "normal";
}

type Channel = APISchemas["Channel"] & { hasError?: boolean };

export type Forms =
  | twitterSchema
  | linkedinSchema
  | facebookSchema
  | instagramSchema
  | youtubeSchema
  | tiktokSchema
  | telegramSchema;

export type FormState = {
  sync: boolean;
};

type Previews = {
  twitter?: Preview;
  linkedin?: Preview;
  facebook?: Preview;
  instagram?: Preview;
  telegram?: Preview;
  tiktok?: Preview;
  youtube?: Preview;
};

export type GlobalError = {
  message: string;
  field?: string;
};

interface State {
  forms: Record<string, Forms>;
  fromStates: Record<string, FormState>;
  mainForms: MainForms;

  previews: Previews;
  platforms: Array<Channel>;
  platformsHasError: Array<string>;
  globalErrors: Record<string, GlobalError>;
  selectedPlatform?: Channel;
  scheduledAt: Date;

  formSync?: boolean;

  compose?: APISchemas["Compose"];
  twitter_post: Array<APISchemas["TwitterPost"]>;
  instagram_post: Array<APISchemas["InstagramPost"]>;
  facebook_post: Array<APISchemas["FacebookPost"]>;
  linkedin_post: Array<APISchemas["LinkedinPost"]>;
  telegram_post: Array<APISchemas["TelegramPost"]>;
  tiktok_post: Array<APISchemas["TiktokPost"]>;
  youtube_post: Array<APISchemas["YoutubePost"]>;

  progressBlock: boolean;
  goToStep?: Dispatch<SetStateAction<number>>;

  subscribes: Record<string, Array<() => void>>;
}

export interface Action {
  setPreview: (preview: Previews) => void;
  setFormSync: (sync: boolean) => void;
  setFormState: (channelId: string, formState: FormState) => void;
  setAllFormStates: (formState: FormState) => void;

  setPlatformHasError: (
    platform: APISchemas["Channel"],
    hasError: boolean
  ) => void;

  setGlobalError: (key: string, error: GlobalError) => void;
  getGobalError: (key: string) => GlobalError | undefined;
  clearGlobalErrors: () => void;

  getPlatformsHasError: () => Array<string>;

  setScheduledAt: (at: Date) => void;
  setPlatforms: (channels: Array<APISchemas["Channel"]>) => void;
  setSelectedPlatform: (platform?: Channel) => void;
  setMainForm: (form: MainForms) => void;
  setForm: (channel_id: string | number, form: Forms) => void;

  clearPosts: () => void;
  reset: () => void;

  addTwitterPost: (tweet: APISchemas["TwitterPost"]) => void;
  addFacebookPost: (post: APISchemas["FacebookPost"]) => void;
  addInstagramPost: (post: APISchemas["InstagramPost"]) => void;
  addLinkedinPost: (post: APISchemas["LinkedinPost"]) => void;
  addTelegramPost: (post: APISchemas["TelegramPost"]) => void;
  addTiktokPost: (post: APISchemas["TiktokPost"]) => void;
  addYoutubePost: (post: APISchemas["YoutubePost"]) => void;
  setProgressBlock: (block: boolean) => void;
  getProgressBlock: () => boolean;

  setGoToStepFn: (fn: Dispatch<SetStateAction<number>>) => void;

  subscribe: (key: string, fn: () => void) => void;
  unsubscribe: (key: string, fn: () => void) => void;
  clearSubscribes: (key: string) => void;
  fireEvent: (key: string) => void;

  focusOnFormError: () => void;
}

const initialState: State = {
  forms: {},
  fromStates: {},
  mainForms: {},
  previews: {},
  platforms: [],
  selectedPlatform: undefined,
  platformsHasError: [],
  globalErrors: {},
  subscribes: {},
  scheduledAt: new Date(),
  twitter_post: [],
  linkedin_post: [],
  facebook_post: [],
  instagram_post: [],
  telegram_post: [],
  tiktok_post: [],
  youtube_post: [],
  progressBlock: false,
};

export const useFormStore = create<State & Action>()((set, get) => ({
  ...initialState,

  setPreview(preview: Previews) {
    set({
      previews: preview,
    });
  },
  setFormSync(sync) {
    set({
      formSync: sync,
    });
  },

  setPlatformHasError(platform, hasError) {
    const platforms = get().platforms;
    const found = platforms.findIndex((p) => p.id === platform.id);

    if (found === -1) {
      return;
    }

    platforms[found].hasError = hasError;

    set({
      platforms: [...platforms],
    });

    get().getPlatformsHasError();
  },

  getPlatformsHasError() {
    const platforms = get().platforms;

    const errors: Record<string, number> = {};

    for (const platform of platforms) {
      if (platform.hasError) {
        errors[platform.channel_type.split("_")[0]] = 1;
      }
    }

    const hasERrors = Object.keys(errors);

    set({
      platformsHasError: hasERrors,
    });

    return hasERrors;
  },

  setGlobalError(key, error) {
    const globalErrors = get().globalErrors;

    globalErrors[key] = error;
    set({ globalErrors: { ...globalErrors } });
  },

  getGobalError(key) {
    const globalErrors = get().globalErrors;

    return globalErrors[key];
  },

  clearGlobalErrors() {
    set({
      globalErrors: {},
    });
  },

  setSelectedPlatform(platform) {
    set({
      selectedPlatform: platform,
    });
  },

  setGoToStepFn(fn) {
    set({
      goToStep: fn,
    });
  },

  setProgressBlock(block) {
    set({
      progressBlock: block,
    });
  },

  getProgressBlock() {
    return get().progressBlock;
  },

  clearPosts() {
    set({
      twitter_post: [],
      facebook_post: [],
      linkedin_post: [],
      instagram_post: [],
      telegram_post: [],
      tiktok_post: [],
      youtube_post: [],
    });
  },

  addTwitterPost(tweet) {
    const posts = get().twitter_post;

    posts.push(tweet),
      set({
        twitter_post: [...posts],
      });
  },
  addTiktokPost(post) {
    const posts = get().tiktok_post;

    posts.push(post),
      set({
        tiktok_post: [...posts],
      });
  },
  addFacebookPost(post) {
    const posts = get().facebook_post;

    posts.push(post),
      set({
        facebook_post: [...posts],
      });
  },
  addInstagramPost(post) {
    const posts = get().instagram_post;

    posts.push(post),
      set({
        instagram_post: [...posts],
      });
  },
  addLinkedinPost(post) {
    const posts = get().linkedin_post;

    posts.push(post),
      set({
        linkedin_post: [...posts],
      });
  },
  addTelegramPost(post) {
    const posts = get().telegram_post;

    posts.push(post),
      set({
        telegram_post: [...posts],
      });
  },

  addYoutubePost(post) {
    const posts = get().youtube_post;

    posts.push(post),
      set({
        youtube_post: [...posts],
      });
  },
  setScheduledAt(at) {
    set({
      scheduledAt: at,
    });
  },

  setPlatforms(channels) {
    set({
      platforms: [...channels],
    });

    get().getPlatformsHasError();
  },

  setMainForm(form) {
    const keys = Object.keys(form) as Array<keyof typeof form>;
    for (const k of keys) {
      if (form[k] === undefined) {
        delete form[k];
      }
    }

    set({
      mainForms: {
        ...get().mainForms,
        ...form,
      },
    });
  },
  setForm(channel_id: number | string, form: Forms) {
    const forms = get().forms;

    forms[channel_id] = form;

    set({
      forms: { ...forms },
    });
  },

  setFormState(channelId, formState) {
    set((state) => {
      const currFormStates = state.fromStates;

      if (isEqual(currFormStates[channelId], formState)) {
        return {};
      }

      currFormStates[channelId] = {
        ...currFormStates[channelId],
        ...formState,
      };

      return {
        fromStates: { ...currFormStates },
      };
    });
  },

  setAllFormStates(formState) {
    set((state) => {
      const currFormStates = state.fromStates;

      const keys = Object.keys(currFormStates);

      for (const k of keys) {
        currFormStates[k] = {
          ...currFormStates[k],
          ...formState,
        };
      }

      return {
        fromStates: { ...currFormStates },
      };
    });
  },
  subscribe(key, fn) {
    const { subscribes } = get();

    if (!subscribes[key]) {
      subscribes[key] = [];
    }

    if (subscribes[key].findIndex((s) => s === fn) > -1) {
      console.warn("key:", key, "fn:", fn, "is trying to subscribe");
      return;
    }

    subscribes[key].push(fn);

    set({
      subscribes: { ...subscribes },
    });
  },
  unsubscribe(key, fn) {
    const { subscribes } = get();

    if (!subscribes[key]) {
      return;
    }

    subscribes[key] = subscribes[key].filter((s) => {
      return s !== fn;
    });

    set({
      subscribes: { ...subscribes },
    });
  },
  clearSubscribes(key: string) {
    const { subscribes } = get();

    if (!subscribes[key]) {
      return;
    }

    subscribes[key] = [];

    set({
      subscribes: { ...subscribes },
    });
  },
  fireEvent: async (key) => {
    const { subscribes } = get();

    if (!subscribes[key]) {
      return;
    }

    const promises = [];

    for (const sub of subscribes[key]) {
      promises.push(sub());
    }

    await Promise.all(promises);
  },

  focusOnFormError() {
    const platforms = get().platforms;
    const globalErrorsIds = Object.keys(get().globalErrors);
    const target = platforms.find((p) => p.hasError);
    const globalTarget = platforms.find((p) =>
      globalErrorsIds.includes(p.id.toString())
    );

    if (globalTarget) {
      set({
        selectedPlatform: globalTarget,
      });

      return;
    }

    if (!target) {
      return;
    }

    set({
      selectedPlatform: target,
    });
  },

  reset() {
    set({
      ...initialState,
    });
  },
}));
