import {
  Text,
  Heading,
  VStack,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useForm, SubmitHandler } from "react-hook-form";
import { z } from "@/i18n";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { POST } from "@/api";

type validationSchema = z.infer<typeof validationSchema>;
const validationSchema = z.object({
  email: z.string().email("Invalid email format"),
});

export const EnterMail = () => {
  const { t } = useTranslation();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<validationSchema>({ resolver: zodResolver(validationSchema) });

  const onSubmitSendEmail: SubmitHandler<validationSchema> = useCallback(
    async ({ email }) => {
      const { response, error } = await POST("/auth/resend-activation/", {
        body: { email },
      });
      if (response.ok) {
        toast({
          description: t("alert.success.verification-code-sent"),
          status: "success",
          isClosable: true,
        });
        return;
      }
      if (error?.detail) {
        toast({
          description: error.detail,
          status: "error",
          isClosable: true,
        });
        return;
      }
    },
    [toast, t]
  );

  return (
    <VStack flexDir="column" h="full" w="full" textAlign="center" bg="white">
      <VStack>
        <Heading
          mt="114px"
          alignSelf="center"
          lineHeight="60px"
          fontWeight="500"
          fontSize={["32px", "36px", "40px", "48px"]}
          mb="130px"
        >
          {t("page.enter-mail.confirm-your-mail-address")}
        </Heading>

        <Text
          fontSize={{ base: "xs", sm: "sm", md: "md", lg: "md" }}
          textAlign="center"
          lineHeight="25px"
          fontWeight="500px"
          position="relative"
          bottom="5"
          mb="2"
        >
          {t("page.enter-mail.resend-mail-message")}
        </Text>
        <form
          style={{
            width: "100%",
          }}
          onSubmit={handleSubmit(onSubmitSendEmail)}
        >
          <FormControl mb={6} isInvalid={!!errors.email}>
            <FormLabel
              pos="absolute"
              top="-10px"
              fontSize="xs"
              zIndex="9"
              ml="2.5"
              px="3"
              fontWeight="400"
              lineHeight="20px"
              pr="6"
              bg="white"
            >
              {t("input.email-address")}
            </FormLabel>
            <Input type="text" {...register("email")} />

            <FormErrorMessage> {errors.email?.message} </FormErrorMessage>
          </FormControl>

          <Button
            variant="unstyled"
            fontWeight="700"
            bg="blue.shiny"
            textColor="#fff"
            borderRadius="6px"
            lineHeight="40px"
            _hover={{
              opacity: 0.8,
            }}
            fontSize="28px"
            h="60px"
            w="full"
            type="submit"
          >
            {t("button.resend-verification-code")}
          </Button>
        </form>
      </VStack>
    </VStack>
  );
};
