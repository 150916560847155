export const FacebookBlueIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z"
        fill="#3A559F"
      />
      <path
        d="M65.8409 44.0795L64.8352 52.7898C64.7935 53.1872 64.6072 53.5555 64.3119 53.8247C64.0165 54.0939 63.6325 54.2452 63.2329 54.25H54.142L54.0966 80.0739C54.0997 80.3799 53.9822 80.6748 53.7695 80.8949C53.5569 81.115 53.2662 81.2425 52.9602 81.25H43.75C43.5969 81.2501 43.4453 81.2193 43.3044 81.1594C43.1634 81.0994 43.0361 81.0116 42.9299 80.9013C42.8238 80.7909 42.7411 80.6602 42.6867 80.517C42.6324 80.3739 42.6075 80.2212 42.6136 80.0682V54.25H35.7954C35.5813 54.2478 35.3697 54.2034 35.1727 54.1194C34.9757 54.0354 34.7972 53.9134 34.6473 53.7604C34.4975 53.6073 34.3793 53.4263 34.2994 53.2276C34.2195 53.0289 34.1796 52.8164 34.1818 52.6023L34.1477 43.892C34.1447 43.6771 34.1841 43.4638 34.2635 43.2641C34.343 43.0644 34.461 42.8823 34.6109 42.7283C34.7607 42.5742 34.9394 42.4512 35.1368 42.3662C35.3342 42.2812 35.5465 42.2359 35.7613 42.233H42.6136V33.8239C42.6136 24.0625 48.4091 18.75 56.9091 18.75H63.875C64.0904 18.7522 64.3032 18.7969 64.5012 18.8816C64.6993 18.9663 64.8787 19.0892 65.0291 19.2433C65.1796 19.3975 65.2981 19.5798 65.3779 19.7798C65.4578 19.9799 65.4973 20.1937 65.4943 20.4091V27.7386C65.4973 27.954 65.4578 28.1678 65.3779 28.3679C65.2981 28.568 65.1796 28.7503 65.0291 28.9044C64.8787 29.0586 64.6993 29.1815 64.5012 29.2661C64.3032 29.3508 64.0904 29.3955 63.875 29.3977H59.6023C54.9829 29.3977 54.0852 31.6705 54.0852 34.9545V42.233H64.2386C64.4695 42.2358 64.6972 42.2876 64.9067 42.3848C65.1162 42.4819 65.3027 42.6223 65.4541 42.7968C65.6054 42.9712 65.7181 43.1757 65.7848 43.3967C65.8515 43.6178 65.8706 43.8505 65.8409 44.0795Z"
        fill="#F6F7F9"
      />
    </svg>
  );
};
