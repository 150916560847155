export const PostEffectFaqIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="19"
        cy="19.5"
        r="18.5"
        fill="#57AAEC"
        fill-opacity="0.33"
        stroke="#57AAEC"
      />
      <path
        d="M14.9789 25.7671V26.4604C14.9789 26.9119 14.9745 27.3644 14.9824 27.8164C14.9843 27.9593 14.9362 28.0242 14.7958 28.0414C13.5673 28.1917 12.4493 27.9426 11.4855 27.1295C10.9184 26.6515 10.4907 26.0722 10.2482 25.3716C9.80724 24.0972 9.95407 22.8881 10.6871 21.7512C11.0961 21.1159 11.649 20.6477 12.3192 20.3117C12.8799 20.0302 13.4775 19.9029 14.1015 19.9019C16.2634 19.8975 18.4267 19.8985 20.5886 19.8955C21.0997 19.8955 21.6123 19.9063 22.121 19.8734C23.0652 19.812 23.8498 19.4023 24.4881 18.7076C25.0125 18.1372 25.313 17.4562 25.3984 16.6957C25.4927 15.856 25.3302 15.0527 24.8563 14.3418C24.2323 13.4054 23.3701 12.8301 22.2379 12.6935C21.3045 12.5815 20.4442 12.7893 19.6886 13.342C18.8986 13.9188 18.3889 14.6936 18.2397 15.6737C18.1891 16.0054 18.1847 16.3463 18.1802 16.6824C18.1714 17.3437 18.1783 18.0065 18.1783 18.6692C18.1783 18.8338 18.1606 18.8515 18.0015 18.8515C17.3951 18.8515 16.7883 18.8495 16.1819 18.8549C16.0699 18.8549 16.0439 18.8186 16.0449 18.713C16.0493 17.7264 16.0469 16.7404 16.0493 15.7538C16.0503 15.2154 16.2182 14.7123 16.4014 14.2156C16.7514 13.2639 17.3338 12.465 18.1125 11.8288C18.8323 11.2407 19.6469 10.8221 20.5719 10.632C21.3374 10.4748 22.1033 10.4526 22.8678 10.5946C23.7167 10.7519 24.4979 11.0869 25.1942 11.6043C26.0465 12.2371 26.6991 13.034 27.1179 14.0126C27.4856 14.871 27.6202 15.7686 27.5558 16.7016C27.4964 17.5643 27.2652 18.374 26.8341 19.1178C26.0171 20.5264 24.8171 21.4485 23.2415 21.8716C22.7588 22.0013 22.2673 22.0597 21.7675 22.0583C20.6092 22.0548 19.4495 22.0529 18.2912 22.0538C17.6264 22.0538 16.9616 22.0646 16.2958 22.0661C15.5618 22.0671 14.8272 22.0858 14.0942 22.0573C13.2693 22.0244 12.4542 22.6518 12.2141 23.457C11.8959 24.5226 12.5873 25.7691 13.8683 25.9406C14.1383 25.9769 14.3937 25.944 14.6495 25.8634C14.7482 25.8325 14.8493 25.8064 14.9819 25.7676L14.9785 25.7666L14.9789 25.7671Z"
        fill="#57AAEC"
      />
      <path
        d="M18.1854 25.5931H18.1844V27.9106C18.1844 28.0811 18.1721 28.0836 18.0037 28.0826C17.4101 28.0781 16.8165 28.0791 16.2229 28.0826C16.0137 28.0826 16.0201 28.0845 16.0324 27.8811C16.0446 27.6718 16.0358 27.4606 16.0358 27.2498C16.0358 25.9194 16.0392 24.5874 16.0358 23.257C16.0358 23.1273 16.0677 23.0865 16.2042 23.0885C16.8111 23.0948 17.4194 23.0963 18.0273 23.0865C18.1623 23.0845 18.1913 23.1273 18.1903 23.2471C18.1869 24.0288 18.1879 24.8105 18.1879 25.5917C18.1879 25.5926 18.1874 25.5931 18.1864 25.5931H18.1854Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
