export const LinkIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.12436 17C3.03836 17 2.01278 16.5697 1.22152 15.7785C0.39301 14.9499 -0.039758 13.8643 0.00287461 12.7216C0.046935 11.5415 0.580075 10.4078 1.54469 9.44322L3.28147 7.69936C3.54025 7.43948 3.9607 7.43862 4.22058 7.69744C4.48043 7.95622 4.48133 8.37671 4.22251 8.63655L2.4848 10.3814C1.07663 11.7896 0.943748 13.6224 2.1607 14.8393C3.37769 16.0564 5.21046 15.9235 6.61761 14.5162L9.00447 12.1293C9.72793 11.4059 10.1269 10.5798 10.1582 9.74053C10.1869 8.97121 9.89195 8.23672 9.32763 7.6724C9.13854 7.48334 8.92933 7.3229 8.70578 7.19557C8.38709 7.01405 8.2759 6.60854 8.45742 6.28989C8.63897 5.9712 9.04448 5.86004 9.3631 6.04153C9.68975 6.2276 9.99379 6.46035 10.2668 6.73331C11.0953 7.56183 11.5281 8.64741 11.4854 9.7901C11.4414 10.9702 10.9082 12.1039 9.94362 13.0685L7.55675 15.4554C6.59211 16.42 5.45849 16.9531 4.27836 16.9972C4.22696 16.999 4.17553 17 4.12436 17ZM8.54262 10.7101C8.72414 10.3914 8.61291 9.98593 8.29426 9.80444C8.0707 9.67711 7.86146 9.51667 7.6724 9.32764C6.45544 8.11065 6.58832 6.27787 7.99553 4.87065L10.3824 2.48381C11.7896 1.07662 13.6224 0.943711 14.8393 2.16067C16.0563 3.37764 15.9234 5.21042 14.5152 6.6186L12.7775 8.36346C12.5187 8.62331 12.5195 9.04379 12.7794 9.30258C13.0393 9.56139 13.4597 9.56046 13.7185 9.30065L15.4553 7.55675C16.4199 6.59214 16.953 5.45848 16.9971 4.27837C17.0398 3.13569 16.607 2.05007 15.7785 1.22156C14.9499 0.393004 13.8638 -0.0397 12.7217 0.00286645C11.5415 0.0469271 10.4079 0.58007 9.44331 1.54469L7.05642 3.93153C6.0918 4.89615 5.5587 6.02981 5.5146 7.20991C5.47197 8.3526 5.90474 9.43821 6.73325 10.2668C7.00621 10.5397 7.31025 10.7724 7.63694 10.9585C7.74066 11.0176 7.85355 11.0457 7.96498 11.0457C8.19584 11.0456 8.42016 10.925 8.54262 10.7101Z"
        fill="#487EB0"
      />
    </svg>
  );
};
