import { create } from "zustand";
import { persist } from "zustand/middleware";

import { PACKAGE_VERSION_N } from "@/constant";


interface State {
    tabs: Array<string>;
}
const initialState: State = {
    tabs: [],
};

export interface Action {
    addTab: (tab: string) => void;
    removeTab: (tab: string) => Promise<number>;
  clear: () => void;
}

export const useBrowserStore = create<State & Action>()(
  persist(
    (set, get) => ({
      ...initialState,

      addTab: async (tab) => {
        await useBrowserStore.persist.rehydrate();
        const tabs = get().tabs;

        if (tabs.find((t) => t === tab)) {
            return {tabs};
        }
        set({
          tabs: [...tabs, tab],
        });
      },
        removeTab: async (tab) => {
            await useBrowserStore.persist.rehydrate();
            const filtered = get().tabs.filter((t) => t !== tab);

            set({
                tabs: filtered,
            });

            return filtered.length;
        },

      clear() {
        set({
          ...initialState,
        });
      },
    }),
    {
      name: "browser-storage",
      version: PACKAGE_VERSION_N,
    }
  )
);
