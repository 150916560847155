import React, { useState } from "react";
import { Flex, Box, Text, IconButton } from "@chakra-ui/react";
import { TrashIcon } from "@/assets/icons/trash-icon";
import { MasterCardIcon } from "@/assets/icons/mastercard-logo";
import { VisaLogoIcon } from "@/assets/icons/visa-logo-icon";
import CustomAlertDialog from "@/components/alert-modal";
import { useTranslation } from "react-i18next";

interface CardInfo {
  cardHolderName: string | null;
  maskedCardNumber: string;
  cardToken: string;
  finishPaymentRequired: boolean;
  cardNamebyUser: string;
  bankCode: string | null;
  isDebitCard: boolean | null;
  cardProgramName: string | null;
  isBusinessCard: boolean | null;
  cardType: string;
  bin: string | null;
}

interface CardItemProps {
  card: CardInfo;
  image?: string;
  onEdit?: () => void;
  onDelete: () => void;
}

export const CardItem: React.FC<CardItemProps> = ({ card, onDelete }) => {
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  return (
    <Flex
      border="1px solid  #E1E3EA"
      px="3"
      py="3"
      alignItems="center"
      w="full"
      rounded="sm"
      justifyContent="space-between"
      cursor="pointer"
    >
      <Flex alignItems="center">
        <Box pos="relative">
          {card.cardType === "Mastercard" ? (
            <Box bg="#353A3D" rounded="base" width="27px" height="20px" p="2px">
              <MasterCardIcon />
            </Box>
          ) : card.cardType === "Visa" ? (
            <Box width="27px" height="29px">
              <VisaLogoIcon />
            </Box>
          ) : null}
        </Box>
        <Text
          ml="5"
          color="black.active"
          fontSize="16px"
          fontWeight="500"
          lineHeight="20px"
        >
          {card.cardNamebyUser}
          <Text
            as="span"
            display="block"
            fontWeight="500"
            fontSize="16px"
            opacity="0.33"
            mt="2"
          >
            {card.maskedCardNumber}
          </Text>
        </Text>
      </Flex>
      <Flex>
        <IconButton
          aria-label="remove-channel"
          icon={<TrashIcon width="20px" height="25px" color="black.active" />}
          variant="unstyled"
          onClick={() => setAlertOpen(true)}
        />
      </Flex>

      <CustomAlertDialog
        title={t("alert.card-list-alert.title")}
        description={t("alert.card-list-alert.description")}
        isOpen={alertOpen}
        onClose={() => setAlertOpen(false)}
        onCancel={() => setAlertOpen(false)}
        onAction={onDelete}
      />
    </Flex>
  );
};
