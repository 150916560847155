import { useAuthStore } from "@/context/auth-store/auth-store";
import { Box, Flex, Text, useToast } from "@chakra-ui/react";
import { FC, useCallback } from "react";

import { LoadingIllustration } from "@/assets/icons/loading-illustration";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { GET, POST } from "@/api";
import { CardItem } from "./card-item";

export const PaymentMethod: FC = () => {
  const { user } = useAuthStore((state) => ({
    user: state.user,
  }));

  const { t } = useTranslation();
  const toast = useToast();
  const fullName = `${user?.first_name ?? ""} ${user?.last_name ?? ""}`;

  const { data: CardList, refetch } = useQuery(["channels"], async () => {
    const { data } = await GET("/payment/card-list/", {});
    return data?.cardList ?? [];
  });

  const handleDeleteCard = useCallback(
    async (token: string) => {
      const { response, error } = await POST("/payment/delete-card/", {
        body: {
          cardToken: token,
        },
      });
      refetch();
      if (response.ok) {
        toast({
          status: "success",
          title: t("card-info-deleted-successfully"),
        });

        return;
      }

      if (error) {
        toast({
          status: "error",
          title: error.detail ?? t("errors.card-info-not-deleted"),
        });

        return;
      }
    },
    [refetch, t, toast]
  );

  return (
    <Flex flexDir="column" rowGap="20px" h="full" ml="24px">
      <Flex
        border="1px solid  #E1E3EA"
        p="3"
        bg="white"
        alignItems="center"
        w="full"
        shadow="sm"
        borderRadius="6px"
      >
        <Flex w="full" alignItems="center" h="80px">
          <Box ml="0">
            <Text
              color="#002D6F"
              fontSize="22px"
              fontWeight="600"
              lineHeight="20px"
            >
              {fullName}
            </Text>
            <Text
              color="black.active"
              fontSize="13px"
              lineHeight="20px"
              mt="1.5"
            >
              {user?.email}
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Flex
        border="1px solid #497EB026"
        px="4"
        pt="6"
        pb="12"
        w="full"
        flexDir="column"
        gap="4"
        h="full"
        overflowY="auto"
        alignItems="center"
        shadow="sm"
        borderRadius="6px"
        bg="white"
      >
        {CardList ? (
          CardList?.map((item) => (
            <CardItem
              card={item}
              key={item.cardToken}
              onDelete={() => {
                handleDeleteCard(item.cardToken);
              }}
            />
          ))
        ) : (
          <>
            <Text
              color="green.label"
              fontWeight="700"
              fontSize="40px"
              pos="relative"
              top="40px"
            >
              {t("dev.under-development")} ...
            </Text>
            <LoadingIllustration />
          </>
        )}
      </Flex>
    </Flex>
  );
};
