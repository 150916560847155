export const TicketIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx="19"
        cy="19"
        r="18.5"
        fill="#9631FB"
        fill-opacity="0.33"
        stroke="#9631FB"
      />
      <path
        d="M28.5822 17.3517C28.7998 17.3517 29.0175 17.1341 29.0175 16.9164V14.3055C29.0175 13.5656 28.4518 13 27.712 13H24.2305V25.1845H27.7116C28.4514 25.1845 29.017 24.6189 29.017 23.8791V21.2681C29.0168 21.1527 28.9709 21.0421 28.8893 20.9606C28.8077 20.879 28.6971 20.833 28.5818 20.8328C28.1248 20.8257 27.689 20.6392 27.3683 20.3136C27.0477 19.9879 26.868 19.5493 26.868 19.0923C26.868 18.6353 27.0477 18.1966 27.3683 17.8709C27.689 17.5453 28.1248 17.3588 28.5818 17.3517H28.5822Z"
        fill="#9631FB"
      />
      <path
        d="M9 14.3062V16.9171C9 17.1782 9.1742 17.3524 9.4353 17.3524C9.66614 17.3489 9.89539 17.3912 10.1097 17.4771C10.324 17.563 10.5191 17.6906 10.6836 17.8526C10.8481 18.0146 10.9788 18.2077 11.0679 18.4206C11.1571 18.6336 11.203 18.8621 11.203 19.093C11.203 19.3239 11.1571 19.5524 11.0679 19.7654C10.9788 19.9783 10.8481 20.1714 10.6836 20.3334C10.5191 20.4954 10.324 20.623 10.1097 20.7089C9.89539 20.7947 9.66614 20.8371 9.4353 20.8335C9.1742 20.8335 9 21.0078 9 21.2688V23.8798C9 24.6196 9.56563 25.1853 10.3055 25.1853H23.3602V13.0007H10.3055C9.5673 13.0007 9 13.5664 9 14.3062ZM14.2219 16.4818H16.8328C17.0939 16.4818 17.2681 16.656 17.2681 16.9171C17.2681 17.1782 17.0939 17.3524 16.8328 17.3524H14.2219C13.9608 17.3524 13.7866 17.1782 13.7866 16.9171C13.7866 16.656 13.9608 16.4818 14.2219 16.4818ZM14.2219 18.6575H19.8786C20.1397 18.6575 20.3139 18.8317 20.3139 19.0928C20.3139 19.3539 20.1397 19.5281 19.8786 19.5281H14.2219C13.9608 19.5281 13.7866 19.3539 13.7866 19.0928C13.7866 18.8317 13.9608 18.6575 14.2219 18.6575ZM14.2219 20.8331H19.8786C20.1397 20.8331 20.3139 21.0073 20.3139 21.2684C20.3139 21.5295 20.1397 21.7037 19.8786 21.7037H14.2219C13.9608 21.7037 13.7866 21.5295 13.7866 21.2684C13.7866 21.0073 13.9608 20.8335 14.2219 20.8335V20.8331Z"
        fill="#9631FB"
      />
    </svg>
  );
};
