import { POST } from "@/api";
import { AcclerationIcon } from "@/assets/icons/acceleration-icon";
import { AddCoverLibraryIcon } from "@/assets/icons/add-cover-library-icon";
import { AddPersonIcon } from "@/assets/icons/add-person-icon";
import { MusicIcon } from "@/assets/icons/music-icon";
import { ResizeIcon } from "@/assets/icons/resize-icon";
import { ScissorsIcon } from "@/assets/icons/scissors-icon";
import {
  Box,
  Button,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosError } from "axios";
import MediaInfoFactory from "mediainfo.js";
import React, { FC, useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface EditVideoModalProps {
  isOpen: boolean;
  close: () => void;
  data: {
    video: {
      thumbnail_url: string;
      id: string;
      coverUrl?: string | null;
    };
  };
  updateFile: {
    (thumbnail_url?: string): void;
  };
}

const EditVideoModal: React.FC<EditVideoModalProps> = ({
  close,
  isOpen,
  data,
  updateFile,
}) => {
  const toast = useToast();
  const coverUrl = useRef(data.video.coverUrl);
  const [loading, setLoading] = useState<boolean>(false);

  const [newThumbnailData, setNewThumbnailData] = useState<{
    localUrl?: string;
    uploadUrl?: string;
    formData?: FormData;
    downloadUrl?: string;
  }>();
  const defaultThumbnailUrl = data.video.thumbnail_url;
  const [showOverlay, setShowOverlay] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const onImageDrop = useCallback(
    async (files: File[]) => {
      for (const file of files) {
        setLoading(true);
        const mediaInfo = await MediaInfoFactory();

        const info = await mediaInfo.analyzeData(
          () => file.size,
          async (chunkSize, offset) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = (event) => {
                if (event.target?.error) {
                  reject(event.target.error);
                  return;
                }

                if (event.target?.result) {
                  if (typeof event.target.result === "string") {
                    const encoder = new TextEncoder();
                    resolve(
                      new Uint8Array(encoder.encode(event.target.result).buffer)
                    );
                    return;
                  }
                  resolve(new Uint8Array(event.target.result));
                }

                resolve(new Uint8Array());
              };
              reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
            })
        );

        const file_type = file.type || `video/${file.name.split(".").pop()}`;

        const type: "image" | "video" | null = file_type.startsWith("image")
          ? "image"
          : file_type.startsWith("video")
            ? "video"
            : null;

        if (!type) {
          toast({
            status: "error",
            title: t("errors.could-not-recognized-media"),
          });
          return;
        }

        const img = info.media?.track.find((t) => t["@type"] === "Image");

        const { data } = await POST("/media/upload/image/", {
          body: {
            height: img?.Height?.toString() ?? "",
            width: img?.Width?.toString() ?? "",
            size: file.size.toString(),
            raw: JSON.parse(JSON.stringify(info?.media?.track)),
          },
        });
        if (!data) {
          toast({
            status: "error",
            title: t("errors.unexpected_error"),
          });
          return;
        }

        const formData = new FormData();

        formData.append("file", file);
        setNewThumbnailData({
          localUrl: URL.createObjectURL(file),
          uploadUrl: data.uploadUrl,
          formData: formData,
          downloadUrl: data.downloadUrl,
        });
        setLoading(false);
        return;
      }
    },
    [setNewThumbnailData, toast, t]
  );

  const handleThumbnailUpdate = useCallback(
    (
      fileId: string,
      uploadUrl: string,
      formData: FormData,
      downloadUrl: string
    ) => {
      const toastId = toast({
        status: "info",
        title: "Video cover is uploading...",
        isClosable: true,
        duration: null,
      });

      axios
        .request({
          url: uploadUrl,
          method: "POST",
          data: formData,
        })
        .then(async () => {
          if (data.video.id === fileId) {
            updateFile(downloadUrl);
          }
        })
        .then(() => {
          toast.update(toastId, {
            duration: 3000,
            status: "success",
            title: t("alert.success.video-cover-uploaded"),
          });
          close();
        })
        .catch((reason: AxiosError) => {
          toast.update(toastId, {
            duration: 3000,
            status: "error",
            title:
              reason.cause?.message ??
              reason.message ??
              JSON.stringify(
                reason.response?.data ??
                  t("errors.unexpected-error-during-upload")
              ),
          });
        });
    },
    [toast, data, updateFile, close, t]
  );

  const handleCoverDelete = useCallback(async () => {
    const toastId = toast({
      title: t("alert.success.video-cover-deleting"),
      status: "info",
      isClosable: true,
      duration: null,
    });

    await updateFile(undefined);
    setNewThumbnailData({
      localUrl: "",
      uploadUrl: "",
      formData: new FormData(),
      downloadUrl: "",
    });

    toast.update(toastId, {
      duration: 3000,
      status: "success",
      title: t("alert.success.video-cover-deleted"),
    });

    close();
  }, [toast, updateFile, close, t]);

  const { getRootProps: fileRoot, getInputProps: fileInput } = useDropzone({
    onDrop: onImageDrop,
    accept: {
      "image/jpg": [".png", ".jpg", ".jpeg"],
    },
  });
  return (
    <Modal
      size="3xl"
      isOpen={isOpen}
      onClose={close}
      isCentered
      closeOnOverlayClick={true}
      blockScrollOnMount={false}
      scrollBehavior="outside"
    >
      <ModalOverlay />
      <ModalContent bg="white" boxShadow="md" w="full">
        <ModalHeader
          pos="relative"
          fontWeight="500"
          fontSize="18px"
          display="flex"
        >
          {t("edit-video")}
        </ModalHeader>
        <ModalBody px="6">
          <Box
            pos="relative"
            flexShrink="0"
            minH="min-content"
            minW="min-content"
            ref={ref}
            onMouseOver={() => setShowOverlay(true)}
            onMouseLeave={() => setShowOverlay(false)}
          >
            {!showOverlay ||
              (newThumbnailData?.localUrl && (
                <Flex
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  bg="rgba(0, 0, 0, 0.5)"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RemoveButton
                    onClick={() => {
                      setNewThumbnailData({
                        localUrl: "",
                        uploadUrl: "",
                        formData: new FormData(),
                        downloadUrl: "",
                      });
                    }}
                  />
                </Flex>
              ))}
            {!showOverlay ||
              (coverUrl.current && (
                <Flex
                  position="absolute"
                  top="0"
                  right="0"
                  bottom="0"
                  left="0"
                  bg="rgba(0, 0, 0, 0.5)"
                  justifyContent="center"
                  alignItems="center"
                >
                  <RemoveButton
                    onClick={() => {
                      handleCoverDelete();
                      setNewThumbnailData({
                        localUrl: "",
                        uploadUrl: "",
                        formData: new FormData(),
                        downloadUrl: "",
                      });
                    }}
                  />
                </Flex>
              ))}

            <Image
              objectFit="contain"
              bgColor="FAFAFA"
              src={
                coverUrl.current
                  ? coverUrl.current
                  : newThumbnailData?.localUrl
                    ? newThumbnailData?.localUrl
                    : defaultThumbnailUrl
              }
              w="full"
              h="md"
            />
          </Box>
          <Button
            {...fileRoot()}
            mt={3}
            rightIcon={<AddCoverLibraryIcon />}
            bgColor="#E2E4E7"
            variant="outline"
            w="full"
            fontWeight="500"
          >
            <input {...fileInput()} />
            {t("edit-video-modal.add-cover-photo")}
          </Button>

          {/* <HStack justify="flex-start" mt={6} spacing={4}>
            <IconButton
              aria-label="Music"
              rounded="full"
              bgColor="#E2E4E7"
              w={10}
              h={10}
              icon={<MusicIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Add Person"
              rounded="full"
              bgColor="#E2E4E7"
              w={10}
              h={10}
              icon={<AddPersonIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Scissors"
              rounded="full"
              bgColor="#E2E4E7"
              w={10}
              h={10}
              icon={<ScissorsIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Resize"
              rounded="full"
              bgColor="#E2E4E7"
              icon={<ResizeIcon />}
              variant="ghost"
            />
            <IconButton
              aria-label="Acceleration"
              rounded="full"
              bgColor="#E2E4E7"
              icon={<AcclerationIcon />}
              variant="ghost"
            />
          </HStack> */}
        </ModalBody>
        <ModalFooter>
          <Flex width="100%" justifyContent="flex-end">
            <Button
              onClick={() => close()}
              mr={3}
              colorScheme="gray"
              bgColor="##B7BABF"
              fontWeight="500"
            >
              {t("cancel")}
            </Button>
            <Button
              isDisabled={
                !newThumbnailData?.uploadUrl || !newThumbnailData?.downloadUrl
              }
              isLoading={loading}
              colorScheme="teal"
              bgColor="#50CD89"
              onClick={() => {
                if (!newThumbnailData?.uploadUrl) {
                  return;
                }
                if (!newThumbnailData?.formData) {
                  return;
                }
                if (!newThumbnailData?.downloadUrl) {
                  return;
                }

                handleThumbnailUpdate(
                  data.video.id,
                  newThumbnailData?.uploadUrl,
                  newThumbnailData?.formData,
                  newThumbnailData?.downloadUrl
                );
              }}
              fontWeight="500"
            >
              {t("save-and-continue")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
const RemoveButton: FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <Tooltip label="Remove" placement="bottom">
      <IconButton
        aria-label="remove-media"
        display="flex"
        justifyContent="center"
        alignItems="center"
        variant="unstyled"
        bg="#F4F6F8"
        size="none"
        w="30px"
        h="30px"
        rounded="full"
        onClick={onClick}
      >
        <Icon w="16px" h="19px">
          <svg
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.88186 5.24658C7.69454 5.24658 7.54272 5.3984 7.54272 5.58572V11.9954C7.54272 12.1826 7.69454 12.3346 7.88186 12.3346C8.06918 12.3346 8.221 12.1826 8.221 11.9954V5.58572C8.221 5.3984 8.06918 5.24658 7.88186 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M3.87967 5.24658C3.69234 5.24658 3.54053 5.3984 3.54053 5.58572V11.9954C3.54053 12.1826 3.69234 12.3346 3.87967 12.3346C4.06699 12.3346 4.2188 12.1826 4.2188 11.9954V5.58572C4.2188 5.3984 4.06699 5.24658 3.87967 5.24658Z"
              fill="#353B48"
            />
            <path
              d="M0.963207 4.3112V12.6668C0.963207 13.1607 1.1443 13.6245 1.46065 13.9573C1.77555 14.291 2.21378 14.4804 2.67241 14.4812H9.089C9.54776 14.4804 9.98599 14.291 10.3008 13.9573C10.6171 13.6245 10.7982 13.1607 10.7982 12.6668V4.3112C11.4271 4.14428 11.8346 3.53675 11.7504 2.89146C11.6662 2.2463 11.1165 1.76369 10.4658 1.76356H8.72946V1.33964C8.73145 0.983147 8.59049 0.64083 8.33813 0.388993C8.08576 0.137289 7.74291 -0.00286991 7.38642 4.45599e-05H4.37499C4.01849 -0.00286991 3.67565 0.137289 3.42328 0.388993C3.17091 0.64083 3.02996 0.983147 3.03195 1.33964V1.76356H1.29559C0.644867 1.76369 0.0952259 2.2463 0.0109713 2.89146C-0.0731507 3.53675 0.334344 4.14428 0.963207 4.3112ZM9.089 13.803H2.67241C2.09256 13.803 1.64148 13.3048 1.64148 12.6668V4.34101H10.1199V12.6668C10.1199 13.3048 9.66885 13.803 9.089 13.803ZM3.71022 1.33964C3.70797 1.16305 3.77739 0.993082 3.90271 0.868423C4.0279 0.743763 4.19826 0.675273 4.37499 0.67832H7.38642C7.56315 0.675273 7.73351 0.743763 7.8587 0.868423C7.98402 0.99295 8.05344 1.16305 8.05119 1.33964V1.76356H3.71022V1.33964ZM1.29559 2.44184H10.4658C10.803 2.44184 11.0763 2.71513 11.0763 3.05228C11.0763 3.38944 10.803 3.66273 10.4658 3.66273H1.29559C0.958437 3.66273 0.68514 3.38944 0.68514 3.05228C0.68514 2.71513 0.958437 2.44184 1.29559 2.44184Z"
              fill="#353B48"
            />
            <path
              d="M5.88089 5.24658C5.69357 5.24658 5.54175 5.3984 5.54175 5.58572V11.9954C5.54175 12.1826 5.69357 12.3346 5.88089 12.3346C6.06821 12.3346 6.22002 12.1826 6.22002 11.9954V5.58572C6.22002 5.3984 6.06821 5.24658 5.88089 5.24658Z"
              fill="#353B48"
            />
          </svg>
        </Icon>
      </IconButton>
    </Tooltip>
  );
};
export default EditVideoModal;
