import { Box } from "@chakra-ui/react";
import { Calendar } from "./calendar";

export const PlannerPage = () => {
  return (
    <Box w="full" py="4">
      <Calendar />
    </Box>
  );
};
