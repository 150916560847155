import { Box, Text, Flex } from "@chakra-ui/react";
import { FC } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Bar,
  BarChart,
} from "recharts";
import { useTranslation } from "react-i18next";
import { i18nformat } from "@/utils/misc";
interface AreaChartProps {
  //data?: { name: string; value: number; color: string }[];
  centerText?: string;
  width?: number;
  height?: number;
  platforms?: string[];
  data: Array<{
    day: string;
    count: number;
  }>;
}

export const AreaChartGraph: FC<AreaChartProps> = ({ data }) => {
  const { t } = useTranslation();
  return (
    <ResponsiveContainer>
      <BarChart
        data={data}
        margin={{ top: 0, right: 0, left: -25, bottom: 10 }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          vertical={false}
          horizontal={false}
        />
        <XAxis
          interval="preserveStartEnd"
          dataKey="day"
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: "9px",
            fontWeight: "500",

            color: "#838383",
          }}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: "10px",
            fontWeight: "600",

            color: "black",
          }}
        />
        <Tooltip
          cursor={{
            fill: "transparent",
          }}
          content={({ payload, label }) => {
            if (payload && payload.length > 0) {
              const value = payload[0].value;
              const date = label;
              return (
                <Box
                  bg="white"
                  opacity="0.9"
                  p="2"
                  rounded="lg"
                  textAlign="center"
                >
                  <Flex>
                    <Text color="blue.shiny" fontWeight="500" mr="1">
                      {t("date")}:
                    </Text>
                    <Text color="black.active" fontSize="14px" fontWeight="500">
                      {date}{" "}
                    </Text>
                  </Flex>

                  <Text fontWeight="500" color="black.active">
                    {value}
                  </Text>
                </Box>
              );
            }
            return null;
          }}
        />

        <Bar
          dataKey="count"
          fill="#03a9f4cc"
          radius={[100, 100, 100, 100]}
          background={{ fill: "#F2F7FF", radius: 100 }}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
