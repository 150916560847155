export const TurkishFlagCircle = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="BULINE">
        <circle cx="256" cy="256" fill="#fcc8bc" r="256" />
      </g>
      <g id="Icons">
        <circle cx="256" cy="256" fill="#db1b1b" r="256" />
        <path
          d="m300 508.23a257.24 257.24 0 0 1 -44 3.77c-141.39 0-256-114.61-256-256s114.61-256 256-256a257.24 257.24 0 0 1 44 3.77 256 256 0 0 0 0 504.46z"
          fill="#c41c1c"
        />
        <path
          d="m252.3 377.5a120.81 120.81 0 0 0 58.19-14.83 146 146 0 1 1 0-213.34 121.5 121.5 0 1 0 -58.19 228.17z"
          fill="#fff"
        />
        <path
          d="m339.81 194.02 41.02 35.3 46.24-28.11-20.89 49.92 41.02 35.29-53.93-4.44-20.89 49.91-12.44-52.66-53.93-4.44 46.24-28.11z"
          fill="#fff"
        />
        <path
          d="m88 256a255 255 0 0 0 23.3 106.85 146 146 0 0 1 0-213.7 255 255 0 0 0 -23.3 106.85z"
          fill="#dbdbdb"
        />
        <g fill="#e23b3b">
          <circle cx="444.4" cy="139.55" r="16.28" />
          <circle cx="420.42" cy="115.57" r="11.53" />
        </g>
      </g>
    </svg>
  );
};
