export const TwitterIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="black"
      />
      <path
        d="M8.76107 7.96402L11.7393 5H11.0335L8.4476 7.57362L6.38219 5H4L7.12328 8.89175L4 12H4.70577L7.43661 9.28215L9.6178 12H12L8.76107 7.96402ZM7.79443 8.92605L7.47798 8.5385L4.96007 5.45489H6.0441L8.07607 7.94348L8.39253 8.33098L11.0339 11.5658H9.94987L7.79443 8.92605Z"
        fill="white"
      />
    </svg>
  );
};
