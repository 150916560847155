import { create } from "zustand";
import { persist } from "zustand/middleware";
import { APISchemas, GET, POST } from "@/api";
import { PACKAGE_VERSION_N } from "@/constant";
import { useSessionStore } from "../time-store/session-store";
import { useBrowserStore } from "../browser-store/browser.store";
import { addMinutes, isAfter } from "date-fns";

interface State {
  user: APISchemas["User"] | undefined;
  userHomepageBG?: string;
  organizations: Array<APISchemas["Organization"]>;
  activeOrganization?: APISchemas["Organization"];
  token?: string | undefined;
  lastRefresh: number;
}
const initialState: State = {
  user: undefined,
  organizations: [],
  activeOrganization: undefined,
  token: undefined,
  lastRefresh: Date.now(),
};

export interface Action {
  setUser: () => Promise<void>;
  setUserBG: (url: string) => void;
  setToken: (token?: string) => Promise<void>;
  setActiveOrganization: (org: APISchemas["Organization"]) => void;
  refetchUser: (force?: boolean) => void;
  logout: () => void;
  cloneToWindow: (window: Window | null) => Promise<void>;
  clear: () => void;
}

export const useAuthStore = create<State & Action>()(
  persist(
    (set, get) => ({
      ...initialState,
      setToken: async (token?: string) => {
        set({
          token: token,
        });

        await get().setUser();
      },
      setUserBG(url) {
        set({
          userHomepageBG: url,
        });
      },

      setUser: async () => {
        const { data: User } = await GET("/auth/user/me/");

        if (!User) {
          return;
        }
        set({
          user: User,
          lastRefresh: Date.now(),
        });

        const { data: Organizations } = await GET("/org/", {});

        if (!Organizations) {
          return;
        }

        const activeOrganization = get().activeOrganization;

        const updatedActiveOrganization = activeOrganization
          ? Organizations.find((o) => o.id === activeOrganization.id)
          : null;

        set({
          organizations: Organizations,
          activeOrganization: updatedActiveOrganization ?? Organizations[0],
        });

        useSessionStore.getState().startSession();
      },
      setActiveOrganization(org) {
        set({
          activeOrganization: org,
        });
      },
      logout() {
        POST("/auth/logout/", {});

        get().clear();
        useSessionStore.getState().stopSession();
        useBrowserStore.getState().clear();
      },
      refetchUser: async (force = false) => {
        if (force) {
          await get().setUser();
          return;
        }

        const last = get().lastRefresh;

        if (isAfter(Date.now(), addMinutes(last, 5))) {
          await get().setUser();
        }
      },

      cloneToWindow: async (window: Window | null) => {
        // wait for the window to be ready

        let count = 0;
        while (!window && count < 60) {
          await new Promise((resolve) => setTimeout(resolve, 250));
          count++;
        }

        if (!window) {
          return;
        }

        const current = sessionStorage.getItem("auth-storage");

        if (current) {
          window.sessionStorage.setItem("auth-storage", current);
        }
      },

      clear() {
        set({
          ...initialState,
        });
      },
    }),
    {
      name: "auth-storage",
      version: PACKAGE_VERSION_N,
    }
  )
);
