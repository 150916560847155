import { APISchemas } from "@/api";
import { ClockIcon } from "@/assets/icons/clock-icon";
import { VideoPlayIcon } from "@/assets/icons/video-play-icon";
import {
  Box,
  Text,
  Tooltip,
  Button,
  Image,
  Badge,
  Flex,
  Center,
  Divider,
  Card,
  Stack,
  Avatar,
  CardBody,
  CardFooter,
} from "@chakra-ui/react";
import { FC } from "react";
import { useConfigStore } from "@/context/config-store/config-store";
import { i18nformat } from "@/utils/misc";
import { AvatarWithBadge } from "@/components/avatar/avatar-with-badge";
import { TextIcon } from "@/assets/icons/text-icon";
import { ImageIcon } from "@/assets/icons/image-icon";
import { useChannel } from "@/hooks/use-channels";
import { useTranslation } from "react-i18next";
export const PostCard: FC<{
  compose: APISchemas["Compose"];
}> = ({ compose }) => {
  const clockNotation = useConfigStore((c) => c.clockNotation);
  const mediaData = compose.media.length > 0 ? compose.media[0] : undefined;
  const haveVideo = !!compose.media.find((m) => m.type === "video");
  const haveImage = !!compose.media.find((m) => m.type === "image");

  const { t } = useTranslation();

  const postCount =
    compose.facebook_posts?.length +
    compose.instagram_posts?.length +
    compose.linkedin_posts?.length +
    compose.twitter_posts?.length +
    compose.youtube_posts?.length +
    compose.tiktok_posts?.length +
    compose.telegram_posts?.length;

  const postedCount =
    compose.facebook_posts.filter((item) => item.status === "posted").length +
    compose.linkedin_posts.filter((item) => item.status === "posted").length +
    compose.instagram_posts.filter((item) => item.status === "posted").length +
    compose.twitter_posts.filter((item) => item.status === "posted").length +
    compose.youtube_posts.filter((item) => item.status === "posted").length +
    (compose.tiktok_posts?.filter((item) => item.status === "posted").length ??
      0) +
    compose.telegram_posts.filter((item) => item.status === "posted").length;

  const failedCount =
    compose.facebook_posts.filter((item) => item.status === "failed").length +
    compose.linkedin_posts.filter((item) => item.status === "failed").length +
    compose.instagram_posts.filter((item) => item.status === "failed").length +
    compose.twitter_posts.filter((item) => item.status === "failed").length +
    compose.youtube_posts.filter((item) => item.status === "failed").length +
    (compose.tiktok_posts?.filter((item) => item.status === "failed").length ??
      0) +
    compose.telegram_posts.filter((item) => item.status === "failed").length;

  const { data: Channels } = useChannel();

  const facebook = compose.facebook_posts?.map((item) => item.channel);
  const linkedin = compose.linkedin_posts?.map((item) => item.channel);
  const twitter = compose.twitter_posts?.map((item) => item.channel);
  const instagram = compose.instagram_posts?.map((item) => item.channel);
  const youtube = compose.youtube_posts?.map((item) => item.channel);
  const telegram = compose.telegram_posts?.map((item) => item.channel);
  const tiktok = compose.tiktok_posts?.map((item) => item.channel);

  const postList = facebook
    .concat(linkedin)
    .concat(twitter)
    .concat(instagram)
    .concat(youtube)
    .concat(tiktok)
    .concat(telegram);

  const avatars = Channels?.filter((channel) => postList.includes(channel.id));
  const maxAvatars = 4;
  const avatarsToDisplay = avatars?.slice(0, maxAvatars);
  const hiddenCount = Math.max((avatars?.length ?? 0) - maxAvatars, 0);
  const hiddenAvatars = avatars?.slice(maxAvatars, avatars.length);

  const composePost = [
    ...compose.twitter_posts,
    ...compose.facebook_posts,
    ...compose.instagram_posts,
    ...compose.linkedin_posts,
    ...compose.youtube_posts,
    ...(compose.tiktok_posts ?? []),
    ...compose.telegram_posts,
  ];
  const avatarStatus = avatars?.map((avatar) => {
    const correspondingItem = composePost.find(
      (item) => item.channel === avatar.id
    );

    return correspondingItem?.status;
  });

  return (
    <Card
      borderRadius="1"
      bg="white.bg"
      maxW="sm"
      w="210px"
      h="full"
      overflow="hidden"
      pb="2"
    >
      <CardBody py="2" px="2" borderRadius="0px" h="full" overflow="hidden">
        <Badge
          borderRadius="0px"
          overflow="hidden"
          zIndex="99"
          pos="absolute"
          top={mediaData?.url ? "2" : "0"}
          left={mediaData?.url ? "2" : "0"}
          bg={
            failedCount > 0
              ? "#E52625"
              : postedCount > 0
                ? "#4CD137"
                : "#FBC531"
          }
          px="15px"
          color="white"
          fontWeight="normal"
          textTransform="none"
          textAlign="center"
        >
          {failedCount > 0
            ? t("rejected")
            : `${postedCount} / ${postCount} ${t("posted")}`}
        </Badge>
        <Box w="11.125">
          {mediaData?.url && mediaData?.type == "image" && (
            <Center w="full" px="0px">
              <Image
                objectFit="cover"
                objectPosition="center"
                alt="Compose Image"
                src={mediaData.url}
                w="210px"
                h="150px"
                filter="blur(0px)"
              />
            </Center>
          )}
          {mediaData?.url && mediaData?.type == "video" && (
            <Center w="full" px="0px">
              <Image
                objectFit="cover"
                objectPosition="center"
                alt="Compose Image"
                fallbackSrc={mediaData.download_url.replace(
                  "original",
                  "thumbnail.jpg"
                )}
                src={mediaData.download_url.replace("original", "preview.webp")}
                w="210px"
                h="150px"
                filter="blur(0px)"
              />
            </Center>
          )}
        </Box>

        <Stack>
          {compose.body && (
            <Text
              fontSize="13px"
              fontWeight="400"
              color="black.active"
              py={mediaData?.url ? "3" : "5"}
            >
              {compose.body.slice(0, 77)}
              {compose.body.length > 77 ? "..." : ""}
            </Text>
          )}
        </Stack>
        <Flex>
          {avatarsToDisplay?.map((avatar, index) => (
            <Button variant="unstyled" cursor="pointer" key={avatar.id}>
              <AvatarWithBadge
                status={avatarStatus && avatarStatus[index]}
                badgeSize="18px"
                picture={avatar.picture ?? ""}
                channel={avatar.channel_type}
                w="31px"
                h="31px"
                key={avatar.id}
                tooltip={true}
                email={avatar.username ? `@${avatar.username}` : avatar.name}
              />
            </Button>
          ))}
          {hiddenCount > 0 && (
            <Tooltip
              shadow="0 0 10px rgba(0, 0, 0, 0.2)"
              borderRadius="4px"
              bg="white"
              color="black"
              p="4px"
              label={
                <ul>
                  {hiddenAvatars?.map((avatar) => (
                    <>
                      <Text key={avatar.id} fontSize="12px">
                        {avatar.username ? `@${avatar.username}` : avatar.name}
                      </Text>
                    </>
                  ))}
                </ul>
              }
            >
              <Button cursor="pointer" variant="unstyled">
                <Avatar
                  w="31px"
                  h="31px"
                  size="12px"
                  fontWeight="600"
                  color="#487EB0"
                  bg="white.bg"
                  border="1px solid #487EB0"
                  name={"+ " + hiddenCount}
                />
              </Button>
            </Tooltip>
          )}
        </Flex>
      </CardBody>
      <Divider
        color="#B7BABF"
        w="90%"
        opacity="0.33"
        alignSelf="center"
        mb="1px"
      />
      <CardFooter h="full" py="1px" w="full" px="2" mt="4px">
        <Stack w="full" mt="5px">
          <Flex justifyContent="space-between" alignItems="center" w="full">
            <Box justifyContent="flex-start">
              {haveImage ? (
                <ImageIcon color="black.active" width="17" height="17" />
              ) : haveVideo ? (
                <VideoPlayIcon color="black.active" />
              ) : (
                <TextIcon />
              )}
            </Box>
            <Flex alignItems="center">
              <ClockIcon width="15px" height="15px" color="black" />
              <Text
                ml="1.5"
                fontSize="12px"
                whiteSpace="nowrap"
                textAlign="right"
                color="black.active"
                fontWeight="500"
              >
                {i18nformat(
                  new Date(
                    compose.scheduled_at ??
                      compose.updated_at ??
                      compose.created_at
                  ),
                  clockNotation === "12" ? "hh:mm a" : "HH:mm"
                )}
              </Text>
            </Flex>
          </Flex>
        </Stack>
      </CardFooter>
    </Card>
  );
};
