import { FC, Fragment } from "react";
import { Text, Flex, IconButton, Divider, Box, Center } from "@chakra-ui/react";
import { SearchDIcon } from "@/assets/icons/search-d-icon";
import { FilterDIcon } from "@/assets/icons/filter-d-icon";
import { RefreshDIcon } from "@/assets/icons/refresh-d-icon";
import { MessageItem } from "./message-item";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { t } from "i18next";
import { MessageEmptyIcon } from "@/assets/icons/message-empty-icon";

export const Messages: FC = () => {
  const messageData: Array<{
    userName: string;
    channel_type: string;
    message: string;
    date: string;
    message_read: boolean;
  }> = [
    // {
    //   userName: "Smith Taylor",
    //   channel_type: "instagram",
    //   message:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    //   date: "22 minutes ago",
    //   message_read: true,
    // },
    // {
    //   userName: "Smith Taylor",
    //   channel_type: "instagram",
    //   message:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    //   date: "22 minutes ago",
    //   message_read: true,
    // },
    // {
    //   userName: "Smith Taylor",
    //   channel_type: "instagram",
    //   message:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna",
    //   date: "22 minutes ago",
    //   message_read: true,
    // },
  ];

  return (
    <Flex
      flexDirection="column"
      h="full"
      w="full"
      p="3"
      px="4"
      borderRadius="3px"
      bg="white"
      border="1px solid"
      borderColor="gray.athens"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
    >
      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        h="40px"
      >
        <Flex w="full" h="max-content" alignItems="center">
          {/* <PlaneIcon width="19px" height="16px" /> */}
          <Text fontSize="16px" fontWeight="600" color="black.medium">
            {t("page.metrics.messages")}
          </Text>
        </Flex>

        {messageData.length !== 0 && (
          <Flex
            h="full"
            pl="2"
            gap="2"
            justifyContent="space-between"
            alignItems="center"
          >
            <IconButton
              size="none"
              aria-label="Search"
              icon={<SearchDIcon width="30px" height="30px" fill="#2AABEE" />}
              width="30px"
              height="30px"
              cursor="pointer"
            />
            <IconButton
              size="none"
              aria-label="Search"
              icon={<FilterDIcon width="30px" height="30px" fill="#2AABEE" />}
              width="30px"
              height="30px"
              cursor="pointer"
            />
            <IconButton
              size="none"
              aria-label="Search"
              icon={<RefreshDIcon width="30px" height="30px" fill="#2AABEE" />}
              width="30px"
              height="30px"
              cursor="pointer"
            />
          </Flex>
        )}
      </Flex>
      <Center>
        <Divider borderColor="gray.light" w="full" mt="1" />
      </Center>

      {messageData.length === 0 ? (
        <Flex justifyContent="center" alignContent="center" position="relative">
          <MessageEmptyIcon />
          <Box
            top="160px"
            bg="white"
            w="full"
            position="absolute"
            textAlign="center"
            py="4px"
            rounded="12px"
            maxW="460px"
          >
            <Text color="gray.passive" fontSize="24px" fontWeight="500">
              {t("page.dashboard.no-messages")}
            </Text>
          </Box>
        </Flex>
      ) : (
        <OverlayScrollbarsComponent
          options={{
            scrollbars: {
              autoHide: "scroll",
            },
          }}
        >
          <Flex flexDirection="column" w="full" height="full" gap="2px">
            {messageData.map((item, index) => (
              <Fragment key={index}>
                <MessageItem
                  userName={item.userName}
                  channel_type={item.channel_type}
                  message={item.message}
                  date={item.date}
                  message_read={item.message_read}
                />
                {index !== messageData.length - 1 && (
                  <Divider
                    mx="0"
                    borderColor="#EBEDF2"
                    borderWidth="1px"
                    mt="0px"
                  />
                )}
              </Fragment>
            ))}
          </Flex>
        </OverlayScrollbarsComponent>
      )}
    </Flex>
  );
};
