import {
  Box,
  Button,
  Flex,
  VStack,
  Heading,
  Text,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { POST } from "@/api";
import { BackCircleIcon } from "@/assets/icons/back-circle-icon";

export const ConfirmMail: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const { t } = useTranslation();
  const toast = useToast();

  const onSubmitConfirmEmail = useCallback(async () => {
    const { response, error } = await POST("/auth/resend-activation/", {
      body: { email },
    });
    if (response.ok) {
      toast({
        description: t("alert.success.activation-code-sent-to-your-mail"),
        status: "success",
        isClosable: true,
      });
      return;
    }
    if (error?.detail) {
      toast({
        description: error.detail,
        status: "error",
        isClosable: true,
      });
      return;
    }
  }, [toast, email, t]);

  return (
    <VStack flexDir="column" h="full" w="full" textAlign="center" bg="white">
      <IconButton
        aria-label="back"
        icon={<BackCircleIcon />}
        variant="unstyled"
        alignSelf="flex-end"
        onClick={() => navigate("/auth/login")}
        mr="51px"
        mt="57px"
      />
      <Heading
        mt="114px"
        alignSelf="center"
        lineHeight="60px"
        fontWeight="500"
        fontSize={["32px", "36px", "40px", "48px"]}
        mb="130px"
      >
        {t("page.confirm-mail-address.confirm-mail-address")}
      </Heading>

      <Box
        mb="11px"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Flex mb="10px">
          <Text
            fontSize={{ base: "16px", sm: "16px", md: "18px", lg: "20px" }}
            textAlign="center"
            lineHeight="25px"
            fontWeight="400px"
          >
            {t("page.enter-mail.info.we-sent-an-email-to", { mail: email })}
          </Text>
        </Flex>
        <Text
          fontSize={{ base: "16px", sm: "16px", md: "18px", lg: "20px" }}
          textAlign="center"
          lineHeight="25px"
          fontWeight="400px"
        >
          {t("page.confirm-mail-address.subtitle")}
        </Text>
      </Box>

      <Box justifyContent="center" alignItems="center" mt="70px">
        <Button
          mt="5px"
          variant="unstyled"
          textDecor="underline"
          type="submit"
          color="blue.shiny"
          fontWeight="700"
          fontSize="22px"
          _hover={{
            opacity: 0.8,
          }}
          onClick={() => onSubmitConfirmEmail()}
          w="full"
        >
          {t("button.resend-activation-code")}
        </Button>
      </Box>
    </VStack>
  );
};
