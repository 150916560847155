export const LinkedinIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="8" cy="8" r="8" fill="#0077B5" />
      <path
        d="M5.83635 3.71664C5.83635 3.97735 5.75904 4.23221 5.61419 4.44899C5.46935 4.66577 5.26347 4.83472 5.0226 4.93449C4.78173 5.03427 4.51669 5.06037 4.26098 5.00951C4.00528 4.95864 3.7704 4.8331 3.58604 4.64874C3.40169 4.46439 3.27614 4.22951 3.22528 3.9738C3.17442 3.7181 3.20052 3.45305 3.30029 3.21218C3.40006 2.97131 3.56902 2.76544 3.7858 2.62059C4.00258 2.47575 4.25744 2.39844 4.51815 2.39844C4.86776 2.39844 5.20305 2.53732 5.45026 2.78453C5.69747 3.03174 5.83635 3.36703 5.83635 3.71664Z"
        fill="white"
      />
      <path
        d="M5.53757 5.91676V11.7406C5.5378 11.7835 5.52954 11.8261 5.51326 11.8659C5.49698 11.9056 5.473 11.9418 5.4427 11.9722C5.4124 12.0027 5.37638 12.0269 5.33671 12.0434C5.29704 12.0599 5.2545 12.0684 5.21154 12.0684H3.82215C3.77919 12.0685 3.73663 12.0601 3.69691 12.0437C3.6572 12.0273 3.62111 12.0032 3.59073 11.9729C3.56035 11.9425 3.53628 11.9064 3.51989 11.8667C3.5035 11.827 3.49512 11.7844 3.49524 11.7414V5.91676C3.49524 5.83005 3.52968 5.7469 3.59099 5.68559C3.6523 5.62429 3.73545 5.58984 3.82215 5.58984H5.21154C5.29809 5.59008 5.38101 5.62462 5.44213 5.6859C5.50325 5.74719 5.53757 5.83021 5.53757 5.91676Z"
        fill="white"
      />
      <path
        d="M12.8001 8.94763V11.7668C12.8002 11.8063 12.7925 11.8455 12.7774 11.882C12.7624 11.9185 12.7402 11.9517 12.7123 11.9796C12.6844 12.0075 12.6512 12.0297 12.6147 12.0447C12.5781 12.0598 12.539 12.0675 12.4995 12.0674H11.0056C10.9661 12.0675 10.9269 12.0598 10.8904 12.0447C10.8539 12.0297 10.8207 12.0075 10.7928 11.9796C10.7648 11.9517 10.7427 11.9185 10.7276 11.882C10.7126 11.8455 10.7049 11.8063 10.705 11.7668V9.03463C10.705 8.62686 10.8245 7.24891 9.63902 7.24891C8.72067 7.24891 8.53349 8.19186 8.49658 8.61544V11.7668C8.49659 11.8458 8.46553 11.9216 8.41011 11.9778C8.35469 12.034 8.27937 12.0662 8.20042 12.0674H6.75744C6.71801 12.0674 6.67897 12.0596 6.64255 12.0445C6.60614 12.0294 6.57306 12.0072 6.54523 11.9793C6.51739 11.9514 6.49533 11.9182 6.48032 11.8818C6.46531 11.8453 6.45765 11.8062 6.45776 11.7668V5.89028C6.45765 5.85086 6.46531 5.81179 6.48032 5.77533C6.49533 5.73888 6.51739 5.70574 6.54523 5.67782C6.57306 5.6499 6.60614 5.62774 6.64255 5.61263C6.67897 5.59751 6.71801 5.58973 6.75744 5.58973H8.20042C8.28014 5.58973 8.35658 5.6214 8.41294 5.67776C8.46931 5.73413 8.50097 5.81057 8.50097 5.89028C8.50097 6.04245 8.7148 6.11322 8.82656 6.00995C9.16177 5.70021 9.64262 5.49219 10.4255 5.49219C12.8141 5.49219 12.8001 7.72258 12.8001 8.94763Z"
        fill="white"
      />
    </svg>
  );
};
