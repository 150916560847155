import { FC } from "react";

export const TextIcon: FC<React.SVGAttributes<SVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      {...props}
    >
      <path
        d="M0.556641 4.23919C0.864055 4.23919 1.11328 3.98996 1.11328 3.68255V1.11328H3.68255C3.98996 1.11328 4.23919 0.864055 4.23919 0.556641C4.23919 0.249227 3.98996 0 3.68255 0H0.556641C0.249227 0 0 0.249227 0 0.556641V3.68255C0 3.99 0.249227 4.23919 0.556641 4.23919Z"
        fill="#3B5998"
      />
      <path
        d="M18.4433 0H15.3174C15.01 0 14.7607 0.249227 14.7607 0.556641C14.7607 0.864055 15.01 1.11328 15.3174 1.11328H17.8867V3.68255C17.8867 3.98996 18.1359 4.23919 18.4433 4.23919C18.7507 4.23919 18.9999 3.98996 18.9999 3.68255V0.556641C18.9999 0.249227 18.7507 0 18.4433 0Z"
        fill="#3B5998"
      />
      <path
        d="M18.4433 14.7598C18.1359 14.7598 17.8867 15.009 17.8867 15.3164V17.8857H15.3174C15.01 17.8857 14.7607 18.1349 14.7607 18.4423C14.7607 18.7497 15.01 18.999 15.3174 18.999H18.4433C18.7507 18.999 18.9999 18.7497 18.9999 18.4423V15.3164C18.9999 15.009 18.7507 14.7598 18.4433 14.7598Z"
        fill="#3B5998"
      />
      <path
        d="M3.68255 17.8857H1.11328V15.3164C1.11328 15.009 0.864055 14.7598 0.556641 14.7598C0.249227 14.7598 0 15.009 0 15.3164V18.4423C0 18.7497 0.249227 18.999 0.556641 18.999H3.68255C3.98996 18.999 4.23919 18.7497 4.23919 18.4423C4.23919 18.1349 3.99 17.8857 3.68255 17.8857Z"
        fill="#3B5998"
      />
      <path
        d="M15.5734 7.10168V3.58631C15.5734 3.41189 15.432 3.27051 15.2572 3.27051H9.50008H3.74293C3.56814 3.27051 3.42676 3.41189 3.42676 3.58631V7.10168C3.42676 7.27646 3.56814 7.41785 3.74293 7.41785H5.42176C5.59617 7.41785 5.73756 7.27646 5.73756 7.10168V5.89748C5.73756 5.7227 5.87932 5.58131 6.05373 5.58131H8.02832C8.20311 5.58131 8.34449 5.7227 8.34449 5.89748V14.5863C8.34449 14.7611 8.20311 14.9024 8.02832 14.9024H7.16479C6.99 14.9024 6.84861 15.0438 6.84861 15.2186V16.8974C6.84861 17.0719 6.99 17.2132 7.16479 17.2132H9.50008H11.8354C12.0102 17.2132 12.1515 17.0719 12.1515 16.8974V15.2186C12.1515 15.0438 12.0102 14.9024 11.8354 14.9024H10.9718C10.7971 14.9024 10.6557 14.7611 10.6557 14.5863V5.89748C10.6557 5.7227 10.7971 5.58131 10.9718 5.58131H12.9464C13.1208 5.58131 13.2626 5.7227 13.2626 5.89748V7.10168C13.2626 7.27646 13.404 7.41785 13.5784 7.41785H15.2572C15.432 7.41785 15.5734 7.27646 15.5734 7.10168Z"
        fill="#3B5998"
      />
    </svg>
  );
};
