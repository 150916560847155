import { FC } from "react";

export const EmptyNotifications: FC<React.SVGAttributes<SVGElement>> = (
  props
) => {
  return (
    <svg
      width="796"
      height="796"
      viewBox="0 0 796 796"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_8706_2233)">
        <path
          d="M795.891 608.244H0.890625V608.642H795.891V608.244Z"
          fill="#EBEBEB"
        />
        <path
          d="M716.231 633.827H663.57V634.225H716.231V633.827Z"
          fill="#EBEBEB"
        />
        <path
          d="M480.18 634.034H466.363V634.432H480.18V634.034Z"
          fill="#EBEBEB"
        />
        <path
          d="M661.965 619.072H564.275V619.47H661.965V619.072Z"
          fill="#EBEBEB"
        />
        <path
          d="M358.864 628.453H338.687V628.851H358.864V628.453Z"
          fill="#EBEBEB"
        />
        <path
          d="M324.535 628.453H265.976V628.851H324.535V628.453Z"
          fill="#EBEBEB"
        />
        <path
          d="M124.735 621.743H84.3018V622.141H124.735V621.743Z"
          fill="#EBEBEB"
        />
        <path
          d="M253.35 621.743H134.275V622.141H253.35V621.743Z"
          fill="#EBEBEB"
        />
        <path
          d="M377.721 537.33H70.7075C68.3024 537.326 65.9972 536.368 64.2981 534.666C62.5989 532.963 61.6445 530.656 61.6445 528.251V96.6777C61.6655 94.2864 62.629 91.9999 64.326 90.3149C66.0229 88.6298 68.3161 87.6824 70.7075 87.6783H377.721C380.129 87.6783 382.438 88.6348 384.14 90.3374C385.843 92.0401 386.8 94.3493 386.8 96.7572V528.251C386.8 530.659 385.843 532.969 384.14 534.671C382.438 536.374 380.129 537.33 377.721 537.33ZM70.7075 87.9963C68.4078 88.0005 66.2038 88.917 64.5791 90.5446C62.9545 92.1722 62.042 94.378 62.042 96.6777V528.251C62.042 530.551 62.9545 532.757 64.5791 534.384C66.2038 536.012 68.4078 536.929 70.7075 536.933H377.721C380.022 536.929 382.228 536.013 383.855 534.385C385.482 532.758 386.398 530.553 386.402 528.251V96.6777C386.398 94.3765 385.482 92.1708 383.855 90.5436C382.228 88.9165 380.022 88.0005 377.721 87.9963H70.7075Z"
          fill="#EBEBEB"
        />
        <path
          d="M721.654 537.33H414.625C412.218 537.326 409.911 536.368 408.21 534.667C406.508 532.965 405.55 530.658 405.546 528.251V96.6777C405.571 94.2849 406.538 91.9984 408.238 90.3139C409.937 88.6293 412.232 87.6823 414.625 87.6783H721.654C724.042 87.6866 726.332 88.6359 728.025 90.3204C729.719 92.005 730.68 94.2891 730.701 96.6777V528.251C730.701 530.654 729.749 532.958 728.053 534.66C726.357 536.362 724.056 537.322 721.654 537.33ZM414.625 87.9963C412.324 88.0005 410.118 88.9165 408.491 90.5436C406.864 92.1708 405.948 94.3765 405.943 96.6777V528.251C405.948 530.553 406.864 532.758 408.491 534.385C410.118 536.013 412.324 536.929 414.625 536.933H721.654C723.955 536.929 726.161 536.013 727.788 534.385C729.415 532.758 730.331 530.553 730.335 528.251V96.6777C730.331 94.3765 729.415 92.1708 727.788 90.5436C726.161 88.9165 723.955 88.0005 721.654 87.9963H414.625Z"
          fill="#EBEBEB"
        />
        <path
          d="M304.23 396.949L690.664 396.949V114.676L304.23 114.676L304.23 396.949Z"
          fill="#E6E6E6"
        />
        <path
          d="M287.154 396.949L686.705 396.949V114.676L287.154 114.676L287.154 396.949Z"
          fill="#F0F0F0"
        />
        <path
          d="M662.775 373.036V138.59L311.051 138.59V373.036H662.775Z"
          fill="#FAFAFA"
        />
        <path
          d="M577.282 373.02L497.209 138.59H420.746L500.819 373.02H577.282Z"
          fill="white"
        />
        <path
          d="M484.393 373.02L404.321 138.59H374.524L454.597 373.02H484.393Z"
          fill="white"
        />
        <path
          d="M314.104 373.036V138.59H311.068V373.036H314.104Z"
          fill="#E6E6E6"
        />
        <path
          d="M103.557 320.168H240.011L240.011 126.713L103.557 126.713L103.557 320.168Z"
          fill="#E0E0E0"
        />
        <path
          d="M93.9052 320.168H234.557L234.557 126.713L93.9052 126.713L93.9052 320.168Z"
          fill="#F5F5F5"
        />
        <path
          d="M220.677 304.904V141.945H107.787L107.787 304.904H220.677Z"
          fill="white"
        />
        <path
          d="M156.599 455.604C166.727 430.609 190.816 421.308 199.147 405.519C210.039 384.849 209.975 353.828 200.737 342.651C192.66 332.92 165.948 398.682 162.943 406.028C154.699 425.854 149.54 446.825 147.647 468.213C147.87 452.774 148.077 438.591 148.251 427.652C148.824 391.972 149.523 380.493 149.841 376.963C150.954 375.23 152.274 373.226 153.021 372.193C159.493 363.082 173.835 352.222 181.005 338.978C191.468 319.627 189.782 296.477 182.436 287.859C174.757 278.844 161.194 300.15 153.991 321.249C146.789 342.349 149.857 362.589 147.838 380.079C147.425 383.514 146.979 396.568 146.582 414.694C144.388 395.2 140.031 382.146 131.97 369.967C122.048 355.005 113.478 336.784 105.306 339.137C97.1329 341.49 101.521 365.61 107.993 382.528C113.78 397.601 142.162 412.642 146.343 424.758C145.851 449.308 145.405 480.408 145.103 509.394C142.861 494.432 144.404 493.176 135.213 470.264C129.76 456.654 107.42 439.863 104.717 449.212C102.014 458.562 106.101 476.242 114.543 488.644C121.365 498.63 136.485 498.614 139.983 504.815C140.794 506.262 143.497 511.811 145.04 514.991C144.674 550.352 144.515 581.58 144.69 592.217L146.153 592.328C146.153 589.578 146.264 571.658 146.55 547.808C150.223 535.088 174.948 527.758 182.58 514.577C190.53 500.792 196.317 484.701 192.883 473.937C190.021 464.953 167.443 477.117 158.364 492.047C152.274 501.969 148.554 512.542 146.82 526.757C147.027 510.539 147.266 493.112 147.488 476.672C149.349 472.538 153.53 463.093 156.599 455.604Z"
          fill="#E6E6E6"
        />
        <path
          d="M171.116 608.244H119.155C117.782 608.243 116.451 607.767 115.388 606.898C114.325 606.029 113.594 604.82 113.32 603.474L109.631 585.348H180.656L176.967 603.522C176.677 604.859 175.939 606.056 174.874 606.915C173.81 607.774 172.484 608.243 171.116 608.244Z"
          fill="#F0F0F0"
        />
        <path
          d="M182.15 592.328H108.136C107.278 592.326 106.452 592.009 105.813 591.438C105.174 590.866 104.767 590.08 104.669 589.228L103.079 575.792C103.026 575.305 103.076 574.811 103.225 574.344C103.375 573.877 103.621 573.446 103.948 573.08C104.275 572.714 104.675 572.421 105.123 572.22C105.57 572.019 106.055 571.914 106.546 571.913H183.565C184.057 571.912 184.543 572.015 184.991 572.215C185.44 572.415 185.841 572.708 186.169 573.075C186.496 573.441 186.742 573.873 186.891 574.341C187.04 574.81 187.088 575.304 187.031 575.792L185.441 589.228C185.349 590.051 184.967 590.814 184.364 591.381C183.761 591.949 182.977 592.285 182.15 592.328Z"
          fill="#F0F0F0"
        />
        <path
          d="M645.842 596.653H661.727V425.744H645.842V596.653Z"
          fill="#F0F0F0"
        />
        <path
          d="M322.166 608.038H655.637V596.653H322.166V608.038Z"
          fill="#F0F0F0"
        />
        <path
          d="M645.858 425.744H316.076V596.653H645.858V425.744Z"
          fill="#F5F5F5"
        />
        <path
          d="M649.595 425.744H666.004V414.964H649.595V425.744Z"
          fill="#E0E0E0"
        />
        <path
          d="M649.594 414.964H311.799V425.744H649.594V414.964Z"
          fill="#EBEBEB"
        />
        <path
          d="M622.183 499.488H339.736C336.362 499.488 333.127 498.148 330.741 495.763C328.356 493.377 327.016 490.142 327.016 486.768V450.198C327.016 446.825 328.356 443.589 330.741 441.204C333.127 438.818 336.362 437.478 339.736 437.478H622.183C625.557 437.478 628.792 438.818 631.178 441.204C633.563 443.589 634.903 446.825 634.903 450.198V486.768C634.903 490.142 633.563 493.377 631.178 495.763C628.792 498.148 625.557 499.488 622.183 499.488ZM339.736 440.658C337.205 440.658 334.779 441.663 332.99 443.452C331.201 445.242 330.196 447.668 330.196 450.198V486.768C330.196 489.298 331.201 491.725 332.99 493.514C334.779 495.303 337.205 496.308 339.736 496.308H622.183C624.713 496.308 627.14 495.303 628.929 493.514C630.718 491.725 631.723 489.298 631.723 486.768V450.198C631.723 447.668 630.718 445.242 628.929 443.452C627.14 441.663 624.713 440.658 622.183 440.658H339.736Z"
          fill="white"
        />
        <path
          d="M622.183 576.11H339.736C336.362 576.11 333.127 574.77 330.741 572.385C328.356 569.999 327.016 566.764 327.016 563.39V526.82C327.016 523.447 328.356 520.211 330.741 517.826C333.127 515.441 336.362 514.1 339.736 514.1H622.183C625.557 514.1 628.792 515.441 631.178 517.826C633.563 520.211 634.903 523.447 634.903 526.82V563.39C634.903 566.764 633.563 569.999 631.178 572.385C628.792 574.77 625.557 576.11 622.183 576.11ZM339.736 517.153C337.205 517.153 334.779 518.158 332.99 519.947C331.201 521.737 330.196 524.163 330.196 526.693V563.263C330.196 565.793 331.201 568.22 332.99 570.009C334.779 571.798 337.205 572.803 339.736 572.803H622.183C624.713 572.803 627.14 571.798 628.929 570.009C630.718 568.22 631.723 565.793 631.723 563.263V526.693C631.723 524.163 630.718 521.737 628.929 519.947C627.14 518.158 624.713 517.153 622.183 517.153H339.736Z"
          fill="white"
        />
        <path
          d="M557.995 474.048H403.924C402.83 474.048 401.781 473.615 401.006 472.843C400.232 472.071 399.794 471.024 399.79 469.93V466.909C399.794 465.816 400.232 464.768 401.006 463.996C401.781 463.224 402.83 462.791 403.924 462.791H557.995C559.089 462.791 560.138 463.224 560.913 463.996C561.687 464.768 562.125 465.816 562.129 466.909V469.93C562.125 471.024 561.687 472.071 560.913 472.843C560.138 473.615 559.089 474.048 557.995 474.048ZM403.924 466.098C403.675 466.102 403.437 466.202 403.259 466.377C403.082 466.551 402.978 466.787 402.97 467.036V470.057C402.978 470.306 403.082 470.543 403.259 470.717C403.437 470.892 403.675 470.992 403.924 470.995H557.995C558.245 470.995 558.486 470.897 558.664 470.722C558.843 470.546 558.945 470.308 558.949 470.057V467.036C558.945 466.786 558.843 466.548 558.664 466.372C558.486 466.197 558.245 466.098 557.995 466.098H403.924Z"
          fill="white"
        />
        <path
          d="M557.995 550.67H403.924C402.83 550.67 401.781 550.237 401.006 549.465C400.232 548.693 399.794 547.646 399.79 546.552V543.531C399.794 542.438 400.232 541.39 401.006 540.618C401.781 539.847 402.83 539.413 403.924 539.413H557.995C559.089 539.413 560.138 539.847 560.913 540.618C561.687 541.39 562.125 542.438 562.129 543.531V546.552C562.125 547.646 561.687 548.693 560.913 549.465C560.138 550.237 559.089 550.67 557.995 550.67ZM403.924 542.593C403.675 542.597 403.437 542.697 403.259 542.871C403.082 543.046 402.978 543.282 402.97 543.531V546.552C402.978 546.801 403.082 547.038 403.259 547.212C403.437 547.387 403.675 547.486 403.924 547.49H557.995C558.245 547.49 558.486 547.392 558.664 547.217C558.843 547.041 558.945 546.803 558.949 546.552V543.531C558.945 543.281 558.843 543.042 558.664 542.867C558.486 542.692 558.245 542.593 557.995 542.593H403.924Z"
          fill="white"
        />
        <path
          d="M390.44 680.049C560.702 680.049 698.726 671.99 698.726 662.05C698.726 652.109 560.702 644.051 390.44 644.051C220.179 644.051 82.1553 652.109 82.1553 662.05C82.1553 671.99 220.179 680.049 390.44 680.049Z"
          fill="#F5F5F5"
        />
        <path
          d="M596.808 266.955L596.202 276.316L597.789 276.419L598.394 267.058L596.808 266.955Z"
          fill="#57AAEC"
        />
        <path
          d="M599.22 228.934L597.236 259.7L598.823 259.802L600.807 229.036L599.22 228.934Z"
          fill="#57AAEC"
        />
        <path
          d="M580.351 184.255H393.446C388.647 184.339 384.051 186.208 380.556 189.498C377.06 192.788 374.916 197.262 374.541 202.047L350.389 574.902C350.212 577.187 350.513 579.485 351.272 581.648C352.031 583.811 353.231 585.793 354.796 587.467C356.362 589.142 358.258 590.473 360.365 591.375C362.472 592.278 364.744 592.732 367.036 592.71H553.909C558.717 592.63 563.322 590.761 566.827 587.469C570.331 584.176 572.483 579.696 572.862 574.902L596.998 202.047C597.174 199.762 596.874 197.466 596.115 195.303C595.356 193.141 594.156 191.16 592.59 189.487C591.024 187.814 589.128 186.484 587.021 185.584C584.914 184.683 582.642 184.23 580.351 184.255Z"
          fill="#57AAEC"
        />
        <path
          opacity="0.7"
          d="M574.42 184.255H393.446C388.647 184.339 384.051 186.208 380.556 189.498C377.06 192.788 374.916 197.262 374.541 202.047L350.389 574.902C350.212 577.187 350.513 579.485 351.272 581.648C352.031 583.811 353.231 585.793 354.796 587.467C356.362 589.142 358.258 590.473 360.365 591.375C362.472 592.278 364.744 592.732 367.036 592.71H547.978C552.786 592.63 557.392 590.761 560.896 587.469C564.4 584.176 566.552 579.696 566.931 574.902L591.067 202.047C591.244 199.762 590.943 197.466 590.184 195.303C589.425 193.141 588.225 191.16 586.659 189.487C585.094 187.814 583.197 186.484 581.09 185.584C578.983 184.683 576.711 184.23 574.42 184.255Z"
          fill="white"
        />
        <path
          d="M559.156 565.155H360.088C359.343 565.157 358.606 565.005 357.923 564.711C357.239 564.416 356.623 563.984 356.113 563.441C355.604 562.898 355.211 562.257 354.959 561.556C354.707 560.856 354.602 560.111 354.65 559.368L376.497 221.97C376.585 220.592 377.195 219.299 378.202 218.354C379.209 217.409 380.538 216.883 381.918 216.882H581.002C581.748 216.882 582.485 217.036 583.169 217.332C583.852 217.629 584.468 218.063 584.977 218.606C585.487 219.15 585.88 219.793 586.131 220.494C586.383 221.196 586.488 221.941 586.44 222.685L564.594 560.067C564.505 561.448 563.893 562.743 562.882 563.688C561.872 564.633 560.539 565.158 559.156 565.155Z"
          fill="white"
        />
        <path
          d="M514.922 205.847H442.562C442.189 205.85 441.82 205.776 441.478 205.629C441.136 205.483 440.828 205.266 440.574 204.994C440.32 204.722 440.125 204.4 440.002 204.049C439.879 203.698 439.83 203.324 439.859 202.953C439.932 202.182 440.284 201.464 440.849 200.934C441.414 200.404 442.153 200.099 442.927 200.075H515.288C515.66 200.07 516.028 200.142 516.37 200.287C516.713 200.432 517.021 200.647 517.275 200.918C517.529 201.188 517.725 201.509 517.848 201.86C517.971 202.21 518.02 202.583 517.991 202.953C517.922 203.727 517.571 204.449 517.006 204.982C516.44 205.515 515.699 205.823 514.922 205.847Z"
          fill="white"
        />
        <path
          d="M458.446 579.322C458.329 581.228 457.495 583.019 456.112 584.334C454.728 585.65 452.898 586.393 450.988 586.414C450.072 586.422 449.163 586.241 448.319 585.883C447.475 585.525 446.714 584.997 446.083 584.331C445.452 583.666 444.964 582.878 444.651 582.016C444.338 581.154 444.206 580.237 444.263 579.322C444.383 577.419 445.219 575.632 446.602 574.32C447.985 573.008 449.813 572.267 451.72 572.247C452.636 572.236 453.544 572.415 454.388 572.771C455.232 573.128 455.994 573.654 456.625 574.318C457.256 574.982 457.744 575.77 458.057 576.63C458.37 577.491 458.503 578.408 458.446 579.322Z"
          fill="white"
        />
        <path
          opacity="0.2"
          d="M537.468 310.278H367.481L356.669 477.069H537.468C542.186 477.061 546.708 475.184 550.045 471.85C553.383 468.515 555.263 463.995 555.276 459.277V328.134C555.28 323.405 553.407 318.868 550.068 315.52C546.728 312.172 542.197 310.287 537.468 310.278Z"
          fill="#57AAEC"
        />
        <path
          d="M521.632 295.507H313.31C303.475 295.507 295.502 303.48 295.502 313.315V444.506C295.502 454.341 303.475 462.314 313.31 462.314H521.632C531.467 462.314 539.44 454.341 539.44 444.506V313.315C539.44 303.48 531.467 295.507 521.632 295.507Z"
          fill="#57AAEC"
        />
        <path
          d="M289.809 293.583C289.392 293.582 288.992 293.416 288.696 293.122L285.834 290.276C285.534 289.977 285.366 289.571 285.366 289.147C285.366 288.724 285.534 288.318 285.834 288.018C286.133 287.719 286.539 287.551 286.963 287.551C287.386 287.551 287.792 287.719 288.092 288.018L290.938 290.88C291.234 291.178 291.4 291.581 291.4 292.001C291.4 292.421 291.234 292.824 290.938 293.122C290.789 293.27 290.613 293.386 290.419 293.465C290.225 293.544 290.018 293.584 289.809 293.583Z"
          fill="#57AAEC"
        />
        <path
          d="M285.5 303.568H281.478C281.056 303.568 280.652 303.401 280.353 303.103C280.055 302.805 279.888 302.4 279.888 301.978C279.888 301.557 280.055 301.152 280.353 300.854C280.652 300.556 281.056 300.388 281.478 300.388H285.5C285.922 300.388 286.327 300.556 286.625 300.854C286.923 301.152 287.09 301.557 287.09 301.978C287.09 302.4 286.923 302.805 286.625 303.103C286.327 303.401 285.922 303.568 285.5 303.568Z"
          fill="#57AAEC"
        />
        <path
          d="M299.938 289.29C299.535 289.291 299.146 289.139 298.851 288.865C298.556 288.59 298.376 288.214 298.348 287.812L298.062 283.789C298.047 283.58 298.073 283.37 298.139 283.171C298.205 282.973 298.31 282.789 298.448 282.631C298.585 282.473 298.752 282.343 298.94 282.25C299.127 282.157 299.332 282.102 299.54 282.088C299.96 282.061 300.372 282.202 300.688 282.479C301.004 282.756 301.197 283.147 301.226 283.566L301.528 287.589C301.543 287.798 301.517 288.008 301.451 288.206C301.384 288.405 301.28 288.589 301.142 288.747C301.005 288.905 300.838 289.034 300.65 289.128C300.462 289.221 300.258 289.276 300.049 289.29H299.938Z"
          fill="#57AAEC"
        />
        <path
          d="M435.263 445.158L499.228 509.108V445.158H435.263Z"
          fill="#57AAEC"
        />
        <path
          d="M452.8 417.142L449.62 403.643C448.817 400.095 448.817 396.411 449.62 392.863L454.04 373.64C455.849 365.442 454.869 356.876 451.257 349.298C447.646 341.72 441.608 335.565 434.101 331.807C434.242 331.453 434.364 331.092 434.467 330.726C434.728 329.56 434.757 328.353 434.552 327.176C434.347 325.999 433.912 324.874 433.272 323.864C432.632 322.855 431.799 321.982 430.821 321.295C429.844 320.607 428.741 320.119 427.575 319.858C426.408 319.597 425.202 319.568 424.025 319.773C422.848 319.979 421.722 320.414 420.713 321.054C419.704 321.694 418.831 322.526 418.143 323.504C417.456 324.481 416.968 325.585 416.707 326.751C416.628 327.122 416.58 327.5 416.564 327.88C408.172 328.076 400.086 331.067 393.586 336.378C387.086 341.688 382.544 349.016 380.677 357.199L376.369 376.438C375.577 379.98 374.001 383.3 371.758 386.153L363.187 397.045C362.349 398.113 361.795 399.376 361.577 400.716C361.358 402.057 361.482 403.43 361.938 404.71C362.393 405.989 363.165 407.132 364.182 408.033C365.198 408.933 366.426 409.562 367.751 409.86L443.276 426.778C444.596 427.063 445.967 427.011 447.262 426.625C448.556 426.24 449.733 425.535 450.682 424.574C451.632 423.613 452.324 422.429 452.694 421.13C453.064 419.83 453.1 418.459 452.8 417.142Z"
          fill="white"
        />
        <path
          d="M395.767 421.308C395.192 421.181 394.596 421.198 394.029 421.357C393.463 421.516 392.944 421.813 392.52 422.22C392.095 422.627 391.778 423.133 391.595 423.692C391.413 424.251 391.371 424.847 391.474 425.426C391.908 427.981 393.092 430.349 394.876 432.228C396.66 434.108 398.963 435.414 401.492 435.98C404.021 436.546 406.661 436.347 409.076 435.407C411.491 434.468 413.572 432.831 415.054 430.705C415.392 430.226 415.607 429.671 415.681 429.088C415.754 428.506 415.683 427.915 415.474 427.367C415.266 426.819 414.925 426.33 414.483 425.945C414.041 425.559 413.51 425.288 412.939 425.156L395.767 421.308Z"
          fill="white"
        />
        <path
          d="M337.763 394.548C337.369 394.551 336.981 394.455 336.634 394.269C336.286 394.083 335.991 393.813 335.776 393.483C328.266 382.232 325.533 368.459 328.178 355.193C330.823 341.927 338.629 330.255 349.879 322.744C350.406 322.394 351.051 322.268 351.671 322.393C352.291 322.518 352.836 322.885 353.186 323.412C353.536 323.939 353.663 324.583 353.537 325.204C353.412 325.824 353.046 326.369 352.519 326.719C342.334 333.54 335.274 344.125 332.889 356.149C330.504 368.173 332.989 380.651 339.799 390.844C340.15 391.369 340.279 392.012 340.157 392.632C340.035 393.252 339.671 393.798 339.147 394.151C338.737 394.421 338.254 394.559 337.763 394.548Z"
          fill="white"
        />
        <path
          d="M347.86 387.775C347.469 387.772 347.085 387.674 346.741 387.488C346.397 387.302 346.104 387.035 345.888 386.71C340.172 378.139 338.092 367.65 340.105 357.546C342.117 347.443 348.057 338.551 356.621 332.824C356.882 332.65 357.175 332.529 357.482 332.468C357.79 332.407 358.107 332.407 358.415 332.468C358.723 332.529 359.016 332.65 359.277 332.825C359.538 332.999 359.762 333.223 359.936 333.484C360.11 333.745 360.231 334.038 360.293 334.346C360.354 334.654 360.354 334.971 360.293 335.279C360.231 335.586 360.11 335.879 359.936 336.14C359.761 336.401 359.537 336.625 359.276 336.799C351.76 341.816 346.543 349.612 344.775 358.474C343.007 367.336 344.832 376.538 349.847 384.054C350.199 384.579 350.328 385.223 350.206 385.843C350.084 386.463 349.72 387.009 349.195 387.361C348.8 387.626 348.336 387.77 347.86 387.775Z"
          fill="white"
        />
        <path
          d="M357.972 381.017C357.582 381.019 357.198 380.925 356.854 380.742C356.51 380.558 356.217 380.293 356.001 379.968C354.053 377.051 352.699 373.778 352.017 370.338C351.335 366.897 351.338 363.356 352.026 359.916C352.715 356.476 354.074 353.206 356.028 350.293C357.981 347.379 360.49 344.88 363.41 342.937C363.935 342.611 364.566 342.501 365.171 342.631C365.775 342.761 366.305 343.121 366.649 343.634C366.994 344.148 367.125 344.775 367.016 345.383C366.907 345.992 366.566 346.534 366.065 346.896C363.67 348.495 361.613 350.55 360.012 352.945C358.411 355.339 357.298 358.025 356.735 360.849C356.173 363.674 356.172 366.582 356.734 369.407C357.295 372.231 358.408 374.918 360.008 377.313C360.355 377.839 360.482 378.481 360.36 379.1C360.238 379.719 359.877 380.265 359.356 380.62C358.946 380.89 358.463 381.028 357.972 381.017Z"
          fill="white"
        />
        <path
          d="M475.267 428.081C474.734 428.08 474.216 427.9 473.797 427.57C473.378 427.24 473.082 426.779 472.956 426.261C472.829 425.743 472.88 425.198 473.1 424.712C473.32 424.226 473.697 423.828 474.17 423.582C485.063 417.953 493.276 408.229 497.003 396.548C500.73 384.866 499.666 372.183 494.045 361.285C493.874 361.006 493.763 360.694 493.718 360.369C493.673 360.044 493.695 359.714 493.784 359.398C493.872 359.083 494.025 358.789 494.232 358.535C494.439 358.281 494.697 358.072 494.988 357.922C495.28 357.772 495.599 357.683 495.926 357.662C496.253 357.641 496.581 357.688 496.889 357.799C497.198 357.91 497.48 358.084 497.718 358.309C497.956 358.534 498.146 358.806 498.274 359.107C501.345 365.062 503.212 371.563 503.77 378.24C504.327 384.917 503.564 391.638 501.523 398.019C499.483 404.401 496.205 410.318 491.877 415.432C487.549 420.547 482.256 424.758 476.3 427.827C475.978 427.985 475.626 428.071 475.267 428.081Z"
          fill="white"
        />
        <path
          d="M469.686 417.285C469.153 417.284 468.635 417.104 468.216 416.774C467.797 416.444 467.501 415.983 467.375 415.465C467.248 414.947 467.299 414.402 467.519 413.916C467.739 413.43 468.116 413.032 468.589 412.786C472.568 410.734 476.103 407.919 478.993 404.5C481.883 401.081 484.07 397.127 485.43 392.862C486.79 388.597 487.296 384.106 486.919 379.645C486.542 375.185 485.289 370.842 483.233 366.866C482.942 366.303 482.886 365.648 483.079 365.044C483.271 364.44 483.695 363.938 484.258 363.647C484.821 363.356 485.477 363.3 486.081 363.493C486.684 363.685 487.187 364.109 487.478 364.672C489.822 369.205 491.249 374.155 491.678 379.239C492.107 384.324 491.53 389.443 489.979 394.304C488.429 399.165 485.935 403.673 482.642 407.57C479.348 411.467 475.318 414.676 470.783 417.015C470.446 417.195 470.068 417.288 469.686 417.285Z"
          fill="white"
        />
        <path
          d="M464.105 406.489C463.574 406.481 463.06 406.296 462.645 405.964C462.23 405.632 461.937 405.172 461.811 404.655C461.686 404.139 461.735 403.596 461.952 403.11C462.168 402.625 462.54 402.225 463.008 401.974C465.568 400.656 467.843 398.847 469.703 396.649C471.562 394.452 472.97 391.909 473.846 389.166C474.722 386.423 475.048 383.535 474.806 380.666C474.565 377.797 473.759 375.004 472.437 372.447C472.146 371.884 472.09 371.229 472.282 370.625C472.475 370.021 472.899 369.518 473.462 369.228C474.025 368.937 474.681 368.881 475.284 369.073C475.888 369.266 476.391 369.69 476.682 370.253C479.918 376.55 480.523 383.874 478.365 390.617C476.207 397.36 471.461 402.971 465.17 406.219C464.84 406.389 464.476 406.482 464.105 406.489Z"
          fill="white"
        />
        <path
          d="M324.233 200.33H312.086C311.771 200.338 311.468 200.457 311.233 200.667C310.997 200.876 310.843 201.162 310.798 201.475L308.254 225.706C308.236 225.852 308.249 226 308.293 226.141C308.337 226.281 308.41 226.411 308.508 226.521C308.606 226.631 308.726 226.718 308.86 226.778C308.995 226.838 309.14 226.868 309.287 226.867H321.435C321.753 226.858 322.057 226.737 322.293 226.524C322.529 226.311 322.681 226.021 322.723 225.706L325.267 201.475C325.285 201.329 325.272 201.182 325.228 201.042C325.184 200.902 325.111 200.774 325.013 200.665C324.915 200.557 324.794 200.47 324.66 200.412C324.525 200.355 324.38 200.326 324.233 200.33Z"
          fill="#57AAEC"
        />
        <path
          opacity="0.4"
          d="M323.136 200.33H312.086C311.771 200.338 311.468 200.457 311.233 200.667C310.997 200.876 310.843 201.162 310.798 201.475L308.254 225.706C308.236 225.852 308.249 226 308.293 226.141C308.337 226.281 308.41 226.411 308.508 226.521C308.606 226.631 308.726 226.718 308.86 226.778C308.995 226.838 309.14 226.868 309.287 226.867H320.417C320.733 226.858 321.035 226.736 321.269 226.523C321.502 226.31 321.652 226.02 321.689 225.706L324.233 201.475C324.256 201.323 324.244 201.169 324.198 201.023C324.151 200.877 324.072 200.743 323.966 200.633C323.86 200.522 323.73 200.437 323.586 200.385C323.443 200.332 323.289 200.314 323.136 200.33Z"
          fill="white"
        />
        <path
          d="M263.352 648.392C264.942 648.392 266.532 648.153 267.089 647.422C267.224 647.226 267.297 646.993 267.297 646.754C267.297 646.516 267.224 646.283 267.089 646.086C266.857 645.73 266.499 645.474 266.087 645.371C263.925 644.783 259.536 647.358 259.345 647.47C259.296 647.501 259.257 647.547 259.234 647.601C259.212 647.655 259.206 647.714 259.218 647.772C259.23 647.835 259.261 647.893 259.306 647.938C259.351 647.984 259.41 648.014 259.473 648.026C260.753 648.257 262.051 648.379 263.352 648.392ZM265.292 645.895C265.487 645.87 265.685 645.87 265.88 645.895C266.133 645.957 266.354 646.11 266.5 646.325C266.723 646.706 266.659 646.897 266.5 646.993C265.944 647.835 262.684 647.851 260.284 647.517C261.826 646.642 263.53 646.09 265.292 645.895Z"
          fill="#57AAEC"
        />
        <path
          d="M259.441 648.026H259.568C261.158 647.326 264.052 644.544 263.75 643.081C263.75 642.747 263.416 642.318 262.574 642.238C262.275 642.206 261.973 642.234 261.686 642.321C261.399 642.409 261.133 642.554 260.904 642.747C259.314 644.099 259.139 647.517 259.123 647.708C259.121 647.764 259.133 647.819 259.158 647.869C259.183 647.919 259.22 647.962 259.266 647.994C259.32 648.021 259.381 648.032 259.441 648.026ZM262.367 642.811H262.542C263.098 642.811 263.146 643.081 263.146 643.145C263.337 644.019 261.333 646.213 259.791 647.167C259.888 645.708 260.445 644.317 261.381 643.192C261.657 642.957 262.004 642.822 262.367 642.811Z"
          fill="#57AAEC"
        />
        <path
          d="M258.137 617.911H245.258V647.724H258.137V617.911Z"
          fill="#FF8B7B"
        />
        <path
          d="M151.861 633.509L164.724 634.909L173.104 605.971L160.241 604.571L151.861 633.509Z"
          fill="#FF8B7B"
        />
        <path
          d="M170.703 637.818C172.293 638.232 173.883 638.407 174.598 637.818C174.773 637.656 174.892 637.442 174.938 637.208C174.983 636.974 174.954 636.731 174.853 636.514C174.787 636.316 174.683 636.133 174.547 635.974C174.411 635.816 174.244 635.686 174.058 635.592C172.07 634.495 167.141 635.878 166.935 635.926C166.874 635.943 166.82 635.977 166.78 636.026C166.74 636.074 166.716 636.134 166.712 636.196C166.712 636.26 166.73 636.322 166.764 636.375C166.797 636.429 166.846 636.471 166.903 636.499C168.13 637.046 169.401 637.487 170.703 637.818ZM173.215 635.91C173.408 635.957 173.595 636.026 173.772 636.117C174.007 636.229 174.19 636.429 174.28 636.673C174.408 637.103 174.28 637.262 174.28 637.357C173.517 638.009 170.321 637.23 168.048 636.308C169.729 635.845 171.484 635.716 173.215 635.926V635.91Z"
          fill="#57AAEC"
        />
        <path
          d="M166.966 636.53H167.094C168.684 636.228 172.341 634.288 172.404 632.826C172.404 632.476 172.277 631.999 171.466 631.713C171.181 631.61 170.877 631.565 170.574 631.582C170.271 631.598 169.974 631.675 169.701 631.808C167.825 632.714 166.776 635.99 166.728 636.117C166.701 636.163 166.688 636.215 166.688 636.268C166.688 636.321 166.701 636.373 166.728 636.419C166.755 636.456 166.791 636.486 166.833 636.506C166.875 636.525 166.921 636.534 166.966 636.53ZM171.1 632.253H171.291C171.816 632.444 171.8 632.667 171.8 632.73C171.8 633.621 169.256 635.227 167.507 635.751C167.947 634.376 168.817 633.178 169.987 632.333C170.337 632.173 170.732 632.144 171.1 632.253Z"
          fill="#57AAEC"
        />
        <path
          d="M165.408 633.716L151.337 629.678C151.095 629.605 150.836 629.619 150.603 629.718C150.371 629.817 150.18 629.994 150.065 630.218L144.881 640.505C144.76 640.766 144.698 641.05 144.699 641.337C144.7 641.624 144.765 641.908 144.887 642.167C145.01 642.427 145.189 642.656 145.411 642.839C145.632 643.021 145.891 643.153 146.169 643.224C151.098 644.544 153.563 644.957 159.748 646.722C163.548 647.819 171.291 650.459 176.554 651.969C181.817 653.48 183.757 648.535 181.722 647.454C172.595 642.604 169.67 638.693 167.253 635.068C166.819 634.411 166.165 633.932 165.408 633.716Z"
          fill="#263238"
        />
        <path
          d="M257.994 646.229H243.939C243.686 646.227 243.44 646.315 243.248 646.479C243.055 646.642 242.927 646.87 242.889 647.12L240.838 658.488C240.792 658.772 240.808 659.062 240.885 659.339C240.962 659.616 241.098 659.873 241.284 660.092C241.47 660.311 241.701 660.487 241.962 660.609C242.223 660.73 242.506 660.793 242.794 660.794C247.866 660.698 255.18 660.396 261.556 660.396C269.013 660.396 273.083 660.81 281.828 660.81C287.107 660.81 288.347 655.467 286.105 654.974C275.945 652.78 270.078 652.542 261.142 647.168C260.193 646.585 259.108 646.261 257.994 646.229Z"
          fill="#263238"
        />
        <path
          opacity="0.2"
          d="M258.137 617.911H245.258V633.287H258.137V617.911Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M160.241 604.587L173.104 605.971L168.445 622.03L155.916 619.501L160.241 604.587Z"
          fill="black"
        />
        <path
          d="M236.958 321.408C236.958 321.408 229.851 418.621 223.491 455.477C216.479 495.847 175.68 619.454 175.68 619.454L152.307 615.177C152.307 615.177 181.769 499.107 185.029 459.134C188.575 415.759 192.836 326.353 192.836 326.353L236.958 321.408Z"
          fill="#57AAEC"
        />
        <path
          opacity="0.3"
          d="M224.986 361.794C226.258 356.452 229.184 363.178 232.586 374.832C230.01 403.675 226.655 437.081 223.444 455.493C222.728 459.611 221.647 464.604 220.264 470.264C221.488 429.115 221.536 376.247 224.986 361.794Z"
          fill="black"
        />
        <path
          d="M261.238 318.769C261.238 318.769 270.571 416.49 270.889 455.747C271.223 496.594 262.812 630.314 262.812 630.314H240.981C240.981 630.314 234.43 498.98 231.711 458.864C228.738 415.139 216.225 323.714 216.225 323.714L261.238 318.769Z"
          fill="#57AAEC"
        />
        <path
          d="M180.561 611.583C180.657 611.583 176.888 620.058 176.888 620.058L150.59 615.288L152.18 607.418L180.561 611.583Z"
          fill="#263238"
        />
        <path
          d="M266.628 621.616C266.723 621.616 265.785 630.679 265.785 630.679H239.264L238.183 622.602L266.628 621.616Z"
          fill="#263238"
        />
        <path
          d="M193.472 231.669C191.723 235.055 189.799 238.617 188.05 242.131L182.707 252.72C180.974 256.25 179.273 259.796 177.635 263.341C175.998 266.887 174.455 270.417 173.136 273.947L173.008 268.382C173.938 271.239 175.053 274.033 176.347 276.745C176.968 278.144 177.763 279.48 178.446 280.863C179.13 282.247 180.036 283.566 180.847 284.87C182.534 287.565 184.36 290.171 186.317 292.677C187.303 293.933 188.288 295.221 189.338 296.429C190.387 297.638 191.532 298.894 192.518 299.927L192.836 300.277C193.568 301.082 193.99 302.12 194.027 303.208C194.063 304.295 193.711 305.36 193.034 306.211C192.356 307.063 191.398 307.645 190.33 307.854C189.263 308.063 188.156 307.886 187.207 307.353C185.564 306.427 183.971 305.413 182.437 304.316C180.847 303.266 179.448 302.169 178.017 301.009C175.152 298.659 172.463 296.103 169.972 293.361C167.428 290.574 165.115 287.586 163.055 284.425C160.939 281.205 159.098 277.811 157.554 274.281C156.873 272.782 156.755 271.089 157.22 269.511L157.442 268.763C158.651 264.613 160.002 260.591 161.497 256.648C162.991 252.704 164.581 248.841 166.267 245.041C167.952 241.24 169.717 237.488 171.577 233.783C173.438 230.079 175.314 226.453 177.46 222.749C178.647 220.629 180.622 219.062 182.956 218.39C185.291 217.717 187.797 217.993 189.929 219.158C192.061 220.323 193.648 222.282 194.344 224.609C195.04 226.937 194.789 229.445 193.647 231.589L193.472 231.669Z"
          fill="#FF8B7B"
        />
        <path
          d="M202.598 226.008C203.234 238.14 189.465 260.416 189.465 260.416L161.115 246.249C164.56 236.201 169.892 226.902 176.824 218.853C191.818 202.286 202.01 214.878 202.598 226.008Z"
          fill="#263238"
        />
        <path
          d="M183.392 305.079L189.1 314.619L200.977 307.019C198.903 303.301 195.654 300.377 191.739 298.703C190.834 298.414 189.855 298.459 188.98 298.829C188.105 299.2 187.392 299.871 186.969 300.722L183.392 305.079Z"
          fill="#FF8B7B"
        />
        <path
          d="M193.854 318.689L199.371 317.656C199.835 317.57 200.278 317.393 200.674 317.135C201.07 316.877 201.41 316.544 201.677 316.153C201.943 315.763 202.129 315.324 202.224 314.861C202.32 314.399 202.323 313.922 202.233 313.458L200.977 307.098L189.1 314.698L189.895 316.622C190.22 317.367 190.79 317.978 191.51 318.354C192.23 318.73 193.057 318.848 193.854 318.689Z"
          fill="#FF8B7B"
        />
        <path
          d="M227.355 165.048C227.466 166.081 228.118 166.892 228.786 166.828C229.454 166.765 229.899 165.89 229.772 164.857C229.644 163.823 229.008 163.012 228.341 163.06C227.673 163.108 227.228 163.998 227.355 165.048Z"
          fill="#263238"
        />
        <path
          d="M228.022 166.892C229.631 169.98 231.635 172.845 233.985 175.414C232.395 177.275 229.215 176.734 229.215 176.734L228.022 166.892Z"
          fill="#FF5652"
        />
        <path
          d="M222.903 159.562C222.985 159.574 223.07 159.568 223.149 159.543C223.229 159.518 223.302 159.476 223.364 159.419C223.915 158.903 224.585 158.53 225.314 158.333C226.043 158.136 226.809 158.122 227.545 158.29C227.62 158.314 227.7 158.322 227.778 158.314C227.857 158.306 227.933 158.282 228.001 158.243C228.07 158.204 228.13 158.152 228.177 158.089C228.225 158.026 228.259 157.954 228.277 157.877C228.317 157.719 228.294 157.552 228.214 157.41C228.134 157.268 228.003 157.162 227.847 157.114C226.918 156.892 225.948 156.9 225.023 157.139C224.097 157.377 223.244 157.838 222.537 158.481C222.478 158.537 222.431 158.605 222.398 158.68C222.366 158.756 222.35 158.836 222.35 158.918C222.35 159 222.366 159.081 222.398 159.156C222.431 159.231 222.478 159.299 222.537 159.355C222.581 159.413 222.636 159.46 222.699 159.496C222.762 159.531 222.831 159.554 222.903 159.562Z"
          fill="#263238"
        />
        <path
          d="M195.301 176.559C198.338 185.765 202.376 202.731 196.62 209.536C196.62 209.536 200.405 218.806 217.831 217.327C237.022 215.737 225.781 207.071 225.781 207.071C215.001 205.481 214.269 197.706 215.16 190.281L195.301 176.559Z"
          fill="#FF8B7B"
        />
        <path
          d="M192.502 212.891C190.705 210.013 188.702 206.722 192.104 205.497C195.841 204.146 216.829 201.952 224.302 203.796C231.775 205.64 230.249 211.746 230.249 211.746L192.502 212.891Z"
          fill="#57AAEC"
        />
        <path
          d="M253.956 216.77C256.691 219.283 259.648 222.065 262.59 224.609C265.531 227.153 268.504 229.761 271.494 232.273C274.483 234.785 277.536 237.154 280.604 239.46C283.673 241.765 286.789 243.864 289.906 245.82L283.546 245.216C286.189 244.511 288.69 243.354 290.939 241.797C293.567 239.96 296.007 237.867 298.222 235.548C300.608 233.106 302.844 230.52 304.915 227.805C305.981 226.422 307.046 225.023 308.095 223.576C309.145 222.129 310.099 220.602 310.973 219.267L311.148 219.012C311.752 218.09 312.667 217.415 313.727 217.112C314.787 216.808 315.921 216.896 316.921 217.359C317.922 217.821 318.723 218.628 319.178 219.632C319.633 220.637 319.712 221.771 319.4 222.828C318.796 224.895 318.16 226.66 317.429 228.505C316.697 230.349 315.934 232.162 315.076 233.942C313.335 237.575 311.298 241.058 308.986 244.357C306.559 247.855 303.731 251.057 300.559 253.897C297.048 257.031 292.975 259.47 288.554 261.084C287.605 261.418 286.6 261.561 285.595 261.503C284.591 261.446 283.608 261.19 282.703 260.75L282.178 260.48C278.231 258.539 274.398 256.373 270.699 253.992C267.089 251.655 263.591 249.222 260.221 246.615C256.85 244.007 253.59 241.368 250.41 238.665C247.23 235.962 244.177 233.132 241.077 229.967C239.427 228.247 238.508 225.954 238.512 223.57C238.516 221.186 239.443 218.896 241.098 217.181C242.754 215.465 245.01 214.458 247.392 214.37C249.774 214.281 252.098 215.119 253.876 216.707L253.956 216.77Z"
          fill="#FF8B7B"
        />
        <path
          d="M244.24 208.518C244.24 208.518 260.267 224.069 262.128 320.248C236.497 326.465 211.836 328.754 192.788 326.337C191.881 314.269 190.53 272.389 178.287 228.234C177.761 226.375 177.627 224.426 177.895 222.512C178.162 220.598 178.824 218.76 179.839 217.115C180.854 215.471 182.2 214.055 183.792 212.959C185.383 211.862 187.185 211.108 189.083 210.744C191.516 210.267 194.076 209.838 196.62 209.504C206.284 208.122 216.021 207.299 225.78 207.04C231.961 207.09 238.13 207.584 244.24 208.518Z"
          fill="#263238"
        />
        <path
          d="M231.806 225.277C233.396 237.313 254.066 260.448 254.066 260.448L278.6 235.008C271.312 227.356 263.433 220.291 255.036 213.877C237.053 200.441 230.375 214.115 231.806 225.277Z"
          fill="#263238"
        />
        <path
          d="M317.81 227.058L326.015 219.537L316.189 209.44C312.938 212.208 310.701 215.978 309.829 220.157C309.733 221.112 309.986 222.07 310.541 222.854C311.096 223.637 311.916 224.193 312.85 224.418L317.81 227.058Z"
          fill="#FF8B7B"
        />
        <path
          d="M329.035 214.067L326.92 208.868C326.741 208.422 326.474 208.016 326.136 207.675C325.797 207.334 325.393 207.064 324.949 206.882C324.504 206.7 324.027 206.608 323.546 206.614C323.066 206.619 322.591 206.72 322.15 206.912L316.124 209.44L325.95 219.537L327.667 218.376C328.357 217.925 328.869 217.25 329.118 216.465C329.367 215.68 329.338 214.833 329.035 214.067Z"
          fill="#FF8B7B"
        />
        <path
          d="M192.851 165.588C195.157 178.308 195.857 185.845 203.012 191.903C213.776 201.013 228.626 193.922 230.042 180.932C231.377 169.229 227.386 150.388 214.444 146.286C201.501 142.183 190.546 152.868 192.851 165.588Z"
          fill="#FF8B7B"
        />
        <path
          d="M217.926 170.168C222.394 171.185 225.876 166.018 226.353 164.555C224.175 164.762 220.311 163.649 219.548 162.663C221.933 162.869 224.238 159.928 224.525 158.799C221.994 158.31 219.753 156.856 218.276 154.745C222.14 154.665 226.782 147.86 222.537 141.77C218.292 135.68 200.547 140.784 198.051 136.444C194.33 138.606 197.638 141.484 197.638 141.484C197.638 141.484 172.532 146.254 184.107 155.937C175.616 156.7 174.424 166.733 181.499 171.837C188.575 176.941 188.94 180.312 186.126 179.898C187.255 182.061 192.295 182.347 192.581 180.248C193.774 184.652 197.208 190.615 205.127 189.645C213.045 188.675 220.804 177.593 217.926 170.168Z"
          fill="#263238"
        />
        <path
          d="M186.301 154.458C180.323 155.285 177.604 151.914 179.098 148.941C171.689 152.725 179.782 161.089 186.301 154.458Z"
          fill="#263238"
        />
        <path
          d="M211.741 168.228C212.77 170.892 214.59 173.178 216.957 174.778C220.137 176.877 223.015 174.778 222.474 171.598C221.981 168.673 219.739 164.046 216.432 163.219C215.747 162.994 215.012 162.97 214.314 163.149C213.615 163.327 212.983 163.702 212.49 164.228C211.997 164.754 211.665 165.41 211.532 166.119C211.4 166.827 211.472 167.559 211.741 168.228Z"
          fill="#FF8B7B"
        />
        <path
          d="M615.871 616.385C604.105 588.624 599.35 555.965 612.818 557.317C626.635 558.652 625.013 607.879 627.255 613.285C622.946 616.989 615.871 616.385 615.871 616.385Z"
          fill="#57AAEC"
        />
        <path
          d="M619.687 563.708C616.793 574.298 613.724 590.659 611.387 604.524C612.722 608.499 614.233 612.474 615.871 616.385C615.871 616.385 622.93 616.989 627.271 613.285C625.49 609.023 626.126 577.478 619.687 563.708Z"
          fill="#263238"
        />
        <path
          d="M619.75 611.297C616.061 552.308 626.11 497.628 652.568 500.092C675.273 502.223 642.169 601.534 642.805 612.982C632.407 617.021 619.75 611.297 619.75 611.297Z"
          fill="#57AAEC"
        />
        <path
          d="M636.891 610.915C641.041 568.51 663.412 519.029 673.015 535.565C685.211 556.553 653.935 606.543 653.093 614.763C645.27 616.449 636.891 610.915 636.891 610.915Z"
          fill="#57AAEC"
        />
        <path
          d="M632.708 515.849C630.021 515.404 628.526 513.735 628.701 511.366C628.892 508.694 631.086 506.039 633.122 506.007C634.107 506.007 635.761 506.516 636.174 510.189L634.584 510.38C634.393 508.631 633.869 507.597 633.169 507.597C632.088 507.597 630.434 509.569 630.307 511.477C630.196 513.067 631.086 513.957 632.978 514.275L632.708 515.849Z"
          fill="#57AAEC"
        />
        <path
          d="M661.265 526.518C660.109 526.425 659.005 526 658.085 525.294L658.944 523.974C660.422 524.928 661.567 525.183 662.012 524.642C662.68 523.783 662.219 521.271 660.836 519.952C659.723 518.886 658.419 518.966 656.972 520.238L655.938 519.029C656.311 518.61 656.764 518.271 657.271 518.031C657.778 517.792 658.328 517.658 658.888 517.637C659.448 517.616 660.007 517.709 660.53 517.91C661.053 518.111 661.531 518.416 661.933 518.807C663.873 520.651 664.524 524.022 663.252 525.628C663.017 525.925 662.714 526.162 662.368 526.317C662.022 526.472 661.643 526.541 661.265 526.518Z"
          fill="#57AAEC"
        />
        <path
          d="M622.294 550.527C621.331 550.596 620.377 550.311 619.61 549.725C618.842 549.14 618.316 548.294 618.128 547.347C617.492 544.756 618.764 541.56 620.704 540.892C621.61 540.59 623.359 540.558 624.886 543.945L623.423 544.612C622.707 543.022 621.833 542.164 621.213 542.402C620.195 542.752 619.225 545.121 619.623 546.966C619.988 548.556 621.213 549.096 623.025 548.81L623.264 550.4C622.945 550.47 622.62 550.513 622.294 550.527Z"
          fill="#57AAEC"
        />
        <path
          d="M605.361 583.456C604.586 583.474 603.824 583.257 603.174 582.835C602.524 582.412 602.017 581.803 601.72 581.087C600.607 578.654 601.259 575.268 603.024 574.25C603.866 573.773 605.583 573.423 607.714 576.476L606.41 577.382C605.393 575.935 604.423 575.284 603.819 575.633C602.88 576.174 602.372 578.686 603.167 580.403C603.819 581.819 605.059 582.2 606.855 581.564L607.38 583.075C606.734 583.319 606.051 583.448 605.361 583.456Z"
          fill="#57AAEC"
        />
        <path
          d="M633.536 535.454C633.371 535.453 633.211 535.403 633.075 535.311C632.087 534.764 631.33 533.879 630.945 532.818C630.559 531.757 630.57 530.593 630.976 529.539C631.436 528.483 632.224 527.603 633.224 527.031C634.224 526.458 635.381 526.222 636.525 526.359C637.511 526.534 639.816 527.409 639.975 531.638C639.975 531.849 639.892 532.051 639.742 532.2C639.593 532.349 639.391 532.433 639.18 532.433C638.969 532.433 638.767 532.349 638.618 532.2C638.469 532.051 638.385 531.849 638.385 531.638C638.29 529.428 637.543 528.092 636.239 527.854C635.448 527.791 634.656 527.976 633.975 528.383C633.294 528.791 632.757 529.4 632.439 530.128C631.866 531.479 632.439 532.846 634.029 533.975C634.168 534.072 634.272 534.212 634.327 534.372C634.381 534.533 634.383 534.707 634.331 534.869C634.28 535.03 634.178 535.171 634.04 535.271C633.903 535.371 633.737 535.424 633.568 535.422L633.536 535.454Z"
          fill="white"
        />
        <path
          d="M652.377 578.368C652.195 578.371 652.017 578.308 651.878 578.191C651.738 578.075 651.644 577.912 651.614 577.732C651.59 577.631 651.587 577.526 651.605 577.423C651.623 577.321 651.662 577.223 651.718 577.136C651.774 577.048 651.847 576.973 651.933 576.914C652.019 576.856 652.116 576.815 652.218 576.794C654.38 576.349 655.573 575.379 655.604 574.059C655.533 573.268 655.218 572.519 654.702 571.916C654.187 571.312 653.496 570.883 652.727 570.688C651.296 570.339 650.024 571.118 649.181 572.867C649.135 572.961 649.071 573.045 648.993 573.114C648.914 573.183 648.823 573.236 648.724 573.27C648.625 573.304 648.52 573.318 648.416 573.312C648.312 573.305 648.21 573.278 648.116 573.232C648.022 573.187 647.938 573.123 647.868 573.044C647.799 572.966 647.746 572.874 647.712 572.776C647.678 572.677 647.664 572.572 647.67 572.468C647.677 572.363 647.704 572.261 647.75 572.167C648.14 571.112 648.889 570.229 649.867 569.674C650.844 569.118 651.987 568.926 653.092 569.13C654.215 569.407 655.219 570.037 655.958 570.927C656.696 571.817 657.13 572.921 657.194 574.075C657.194 575.077 656.686 577.494 652.552 578.352L652.377 578.368Z"
          fill="white"
        />
        <path
          d="M674.733 564.964C673.81 564.964 672.586 564.424 671.219 562.373L672.538 561.482C673.524 562.945 674.462 563.613 675.082 563.279C676.036 562.77 676.672 560.258 675.83 558.509C675.226 557.094 673.985 556.681 672.173 557.269L671.68 555.758C674.256 554.916 676.354 555.758 677.293 557.889C678.358 560.338 677.642 563.708 675.846 564.678C675.506 564.87 675.122 564.969 674.733 564.964Z"
          fill="#57AAEC"
        />
        <path
          d="M669.994 626.482C670.005 617.997 666.963 609.791 661.424 603.363C661.968 603.283 662.465 603.012 662.827 602.598C663.188 602.184 663.39 601.655 663.396 601.105C663.391 600.484 663.142 599.889 662.701 599.451C662.26 599.014 661.664 598.768 661.042 598.768H607.809C607.229 598.776 606.674 599 606.251 599.397C605.829 599.795 605.57 600.335 605.526 600.913C605.482 601.492 605.656 602.065 606.014 602.521C606.372 602.978 606.887 603.284 607.459 603.379C603.978 607.423 601.453 612.199 600.072 617.354C598.692 622.508 598.492 627.908 599.486 633.15C600.481 638.393 602.645 643.343 605.818 647.634C608.99 651.924 613.089 655.444 617.81 657.932C617.296 658.197 616.886 658.627 616.646 659.154C616.406 659.681 616.351 660.273 616.488 660.835C616.626 661.397 616.949 661.896 617.405 662.252C617.861 662.608 618.424 662.8 619.003 662.797H649.833C650.515 662.793 651.167 662.52 651.65 662.038C652.132 661.556 652.404 660.903 652.409 660.221C652.406 659.75 652.276 659.289 652.033 658.886C651.789 658.483 651.441 658.153 651.025 657.932C656.75 654.913 661.543 650.388 664.885 644.846C668.228 639.304 669.995 632.954 669.994 626.482Z"
          fill="#263238"
        />
      </g>
      <defs>
        <clipPath id="clip0_8706_2233">
          <rect
            width="795"
            height="795"
            fill="white"
            transform="translate(0.890625 0.228271)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
