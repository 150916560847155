import { StandartFile } from "../common";

interface TelegramSchemaObject {
  id?: number;
  content?: string;
  files?: StandartFile[];
}

export const checkTelegramContentSize = (o: TelegramSchemaObject): boolean => {
  const bodyLength = o.content?.length || 0;

  if (o.files && o.files.length > 0) {
    return bodyLength < 1024;
  }
  return bodyLength < 4096;
};
