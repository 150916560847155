import React from "react";

export const FilterStyledIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      width="31"
      height="31"
      viewBox="0 0 31 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x="0.637329"
        y="1"
        width="29"
        height="29"
        rx="2.5"
        stroke="#57AAEC"
      />
      <path
        d="M13.2136 15.0565C13.383 15.2409 13.4761 15.4818 13.4761 15.7311V23.0753C13.4761 23.5173 14.0095 23.7416 14.3252 23.4308L16.3739 21.083C16.648 20.754 16.7992 20.5912 16.7992 20.2655V15.7327C16.7992 15.4835 16.894 15.2426 17.0618 15.0581L22.9404 8.6793C23.3808 8.20076 23.0418 7.4248 22.3905 7.4248H7.88484C7.2335 7.4248 6.89288 8.1991 7.33486 8.6793L13.2136 15.0565Z"
        fill="#57AAEC"
      />
    </svg>
  );
};
