import { FC, useMemo, useRef, useState } from "react";
import {
  Text,
  Flex,
  Button,
  Menu,
  MenuButton,
  Center,
  MenuList,
  MenuItem,
  Divider,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AreaChartGraph } from "./area-chart";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { i18nformat } from "@/utils/misc";
import { parse } from "date-fns";
import { colors } from "@/theme/colors";

interface AreaChartProps {
  data: Array<{
    day: string;
    count: number;
  }>;
  totalSharing?: number;
}

export const AreaChart: FC<AreaChartProps> = ({ data }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<"monthly" | "daily">("daily");
  const defaultSelected = useRef<"monthly" | "daily" | undefined>();

  const monthly = useMemo(() => {
    const r: Array<{
      day: string;
      count: number;
    }> = [];

    data.forEach((item) => {
      const curr = i18nformat(parse(item.day, "yyyy-MM-dd", Date.now()), "MMM");

      const found = r.find((m) => m.day === curr);

      if (found) {
        found.count += item.count;
      } else {
        r.push({ day: curr, count: item.count });
      }
    });

    const barCount = 12;

    if (r.length >= 12) {
      return r;
    }

    const empty = Array.from({ length: barCount - r.length }, () => ({
      day: "",
      count: 0,
    }));

    const middle = Math.floor(empty.length / 2);

    return [
      ...empty.slice(0, middle),
      ...r,
      ...empty.slice(middle, empty.length),
    ];
  }, [data]);

  const daily = useMemo(() => {
    const barCount = 30;
    if (data.length > 20) {
      return data;
    }

    const empty = Array.from({ length: barCount - data.length }, () => ({
      day: "",
      count: 0,
    }));

    const middle = Math.floor(empty.length / 2);

    return [
      ...empty.slice(0, middle),
      ...data,
      ...empty.slice(middle, empty.length),
    ];
  }, [data]);

  if (monthly.length >= 12 && !defaultSelected.current) {
    setSelected("monthly");
    defaultSelected.current = "monthly";
  }

  return (
    <Flex
      flexDirection="column"
      h="full"
      w="full"
      p="3"
      px="4"
      borderRadius="3px"
      border="1px solid"
      borderColor="gray.athens"
      bgColor="white"
      boxShadow="0px 0px 5px 2px rgba(52, 90, 121, 0.03)"
    >
      <Flex
        w="full"
        justifyContent="space-between"
        alignItems="center"
        h="40px"
      >
        <Flex w="full" alignItems="center">
          <Text color="black.medium" fontSize="16px" fontWeight="600">
            {t("page.dashboard.total-sharing")}
          </Text>
        </Flex>

        <Flex h="full" justifyContent="space-between" alignItems="center">
          {/* <IconButton
            size="none"
            aria-label="Filter"
            icon={<FilterDIcon width="30px" height="30px" fill="#2AABEE" />}
            width="30px"
            height="30px"
            cursor="pointer"
          /> */}

          <Menu matchWidth>
            {({ isOpen }) => (
              <>
                <MenuButton
                  isActive={isOpen}
                  as={Button}
                  rightIcon={
                    <ChevronDownIcon
                      color={colors.blue.shiny}
                      width="16px"
                      height="16px"
                    />
                  }
                  fontSize="14px"
                  fontWeight="600"
                  color="black.active"
                  bg="unset"
                  _hover={{}}
                  _active={{}}
                >
                  {selected === "monthly"
                    ? t("date-names.monthly")
                    : selected === "daily"
                      ? t("date-names.daily")
                      : "unexpected"}
                </MenuButton>
                <MenuList p="0" minW="min-content">
                  <MenuItem
                    onClick={() => setSelected("monthly")}
                    fontSize="14px"
                    fontWeight="600"
                    lineHeight="15px"
                    color="blue.zodiac"
                  >
                    {t("date-names.monthly")}
                  </MenuItem>
                  <MenuItem
                    onClick={() => setSelected("daily")}
                    fontSize="14px"
                    fontWeight="600"
                    color="blue.zodiac"
                  >
                    {t("date-names.daily")}
                  </MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
      <Center>
        <Divider borderColor="gray.light" w="full" mt="1" />
      </Center>{" "}
      <Flex h="full" w="full" mt="6">
        <AreaChartGraph data={selected === "monthly" ? monthly : daily} />
      </Flex>
    </Flex>
  );
};
