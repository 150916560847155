export const LinkedinCircleIcon = (props: React.SVGAttributes<SVGElement>) => {
  return (
    <svg
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="15.0001" cy="15.1842" r="15" fill="#0077B5" />
      <path
        d="M10.9433 7.1529C10.9433 7.64174 10.7984 8.1196 10.5268 8.52606C10.2552 8.93251 9.86917 9.24931 9.41754 9.43638C8.9659 9.62345 8.46894 9.6724 7.9895 9.57703C7.51005 9.48166 7.06965 9.24626 6.72398 8.9006C6.37832 8.55494 6.14292 8.11454 6.04755 7.63509C5.95219 7.15564 6.00113 6.65868 6.1882 6.20705C6.37527 5.75542 6.69207 5.3694 7.09853 5.09782C7.50498 4.82623 7.98284 4.68127 8.47169 4.68127C9.1272 4.68127 9.75587 4.94168 10.2194 5.4052C10.6829 5.86872 10.9433 6.49738 10.9433 7.1529Z"
        fill="white"
      />
      <path
        d="M10.383 11.2781V22.1977C10.3835 22.2783 10.368 22.3581 10.3374 22.4327C10.3069 22.5072 10.2619 22.575 10.2051 22.6321C10.1483 22.6892 10.0808 22.7346 10.0064 22.7655C9.93203 22.7964 9.85226 22.8123 9.77171 22.8123H7.16661C7.08606 22.8126 7.00625 22.7968 6.93179 22.7661C6.85732 22.7354 6.78966 22.6902 6.7327 22.6333C6.67574 22.5763 6.6306 22.5087 6.59987 22.4342C6.56914 22.3597 6.55343 22.2799 6.55365 22.1994V11.2781C6.55365 11.1155 6.61823 10.9596 6.73319 10.8447C6.84814 10.7297 7.00405 10.6651 7.16661 10.6651H9.77171C9.93399 10.6656 10.0895 10.7303 10.2041 10.8452C10.3187 10.9602 10.383 11.1158 10.383 11.2781Z"
        fill="white"
      />
      <path
        d="M24.0001 16.961V22.247C24.0004 22.321 23.9859 22.3944 23.9577 22.4629C23.9294 22.5313 23.8879 22.5935 23.8356 22.6459C23.7832 22.6983 23.721 22.7398 23.6525 22.768C23.5841 22.7963 23.5107 22.8107 23.4366 22.8105H20.6354C20.5614 22.8107 20.488 22.7963 20.4195 22.768C20.3511 22.7398 20.2888 22.6983 20.2365 22.6459C20.1841 22.5935 20.1426 22.5313 20.1144 22.4629C20.0861 22.3944 20.0717 22.321 20.0719 22.247V17.1241C20.0719 16.3595 20.296 13.7759 18.0732 13.7759C16.3513 13.7759 16.0003 15.5439 15.9311 16.3381V22.247C15.9311 22.395 15.8729 22.5371 15.769 22.6425C15.6651 22.748 15.5239 22.8083 15.3758 22.8105H12.6702C12.5963 22.8105 12.5231 22.7959 12.4548 22.7676C12.3865 22.7392 12.3245 22.6977 12.2723 22.6453C12.2201 22.593 12.1788 22.5308 12.1506 22.4625C12.1225 22.3941 12.1081 22.3209 12.1083 22.247V11.2285C12.1081 11.1545 12.1225 11.0813 12.1506 11.0129C12.1788 10.9446 12.2201 10.8824 12.2723 10.8301C12.3245 10.7777 12.3865 10.7362 12.4548 10.7079C12.5231 10.6795 12.5963 10.6649 12.6702 10.6649H15.3758C15.5253 10.6649 15.6686 10.7243 15.7743 10.83C15.88 10.9357 15.9394 11.079 15.9394 11.2285C15.9394 11.5138 16.3403 11.6465 16.5498 11.4528C17.1783 10.8721 18.0799 10.482 19.5479 10.482C24.0265 10.482 24.0001 14.664 24.0001 16.961Z"
        fill="white"
      />
    </svg>
  );
};
